import { Api } from "@/service/api.service";
import { KintinCheckFollowUp } from "@kinherit/sdk";

interface UpdateKintinCheckFollowUpMessage {
  kintin: string;
  kintinCheckFollowUp: KintinCheckFollowUp;
}

interface UpdateKintinCheckFollowUpResponse {
  kintinCheckFollowUp: KintinCheckFollowUp;
}

export const UpdateKintinCheckFollowUpHandler = async (
  message: UpdateKintinCheckFollowUpMessage,
): Promise<UpdateKintinCheckFollowUpResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-check-follow-up/{kintin}/check-follow-ups/{kintinCheckFollowUp}",
    {
      kintin: message.kintin,
      kintinCheckFollowUp: message.kintinCheckFollowUp.$data.id,
    },
  )
    .method("patch")
    .body({
      note: {
        completedAt: message.kintinCheckFollowUp.note.$data.completedAt,
        kintin: message.kintin,
        name: message.kintinCheckFollowUp.note.$data.name,
        notes: message.kintinCheckFollowUp.note.$data.notes,
        dueAt: message.kintinCheckFollowUp.note.$data.dueAt,
        pinned: message.kintinCheckFollowUp.note.$data.pinned,
      },
      step: message.kintinCheckFollowUp.$data.step,
      kintin: message.kintin,
    })

    .result();

  const kintinCheckFollowUp = KintinCheckFollowUp.$inflate(
    response.kintinCheckFollowUp,
  ).first();

  if (!kintinCheckFollowUp) {
    throw new Error("Failed to update kintin check");
  }

  return {
    kintinCheckFollowUp,
  };
};
