import { Api } from "@/service/api.service";
import { IntroducerFeePayment } from "@kinherit/sdk";

export interface UpdateIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment;
}

export interface UpdateIntroducerFeePaymentResponse {
  introducerFeePayment: IntroducerFeePayment;
}

export const UpdateIntroducerFeePaymentHandler = async (
  message: UpdateIntroducerFeePaymentMessage,
): Promise<UpdateIntroducerFeePaymentResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment/{introducerFeePayment}",
    message,
  )
    .method("patch")
    .body({
      completedAt: message.introducerFeePayment.$data.completedAt,
      status: message.introducerFeePayment.$data.status,
      xeroContact: message.introducerFeePayment.$data.xeroContact,
      introducerFeePaymentRun:
        message.introducerFeePayment.$data.introducerFeePaymentRun,
    })
    .result();

  const introducerFeePayment = IntroducerFeePayment.$inflate(
    repsonse.introducerFeePayment,
  ).first();

  if (!introducerFeePayment) {
    throw new Error("Failed to Update introducerfeepayment");
  }

  return {
    introducerFeePayment: introducerFeePayment,
  };
};
