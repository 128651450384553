<template>
  <div class="branded-kinvault-invitation-billing">
    <MasterListPage
      title="Invitation Billing"
      subtitle="Monthly billing for new activations and renewals for standalone Kinvaults"
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :persist-state="false"
      :rows="rows"
      @refresh="refresh"
    >
      <template #billing="{ row }">
        <strong class="is-block">{{ row.billingPeriod }}</strong>
        <small
          >{{ row.billingStartDate.formatDate }} -
          {{ row.billingEndDate.formatDate }}</small
        >
      </template>
      <template #actions="{ row }">
        <Button
          :is-disabled="row.sum <= 0"
          @click="download(row)"
          icon-left="PDF"
          >Download</Button
        >
        <Button
          :is-disabled="row.sum <= 0"
          @click="pushToXero(row)"
          icon-left="ChevronUp"
          >Push To Xero</Button
        >
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsInvitationsBillingWrapper",
  "route": "AdminBrandedKinvaultDetailsInvitationsBilling",
  "selector": ".branded-kinvault-invitation-billing",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadBrandedKinvaultInviteBillingFormWrapper": "@/module/admin.branded-kinvault/form/read-branded-kinvault-invite-billing.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadBrandedKinvaultInviteBillingFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadBrandedKinvaultInviteBillingForm } from "@/module/admin.branded-kinvault/form/read-branded-kinvault-invite-billing.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  OpenAlertDialog,
  OpenDangerDialog,
} from "@kinherit/framework/global/dialog";
import { DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { AdminBrandedKinvaultDetailsInvitationsBillingRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

interface Row {
  activeUsers: number;
  newUsers: number;
  renewals: number;
  billingPeriod: string;
  sum: number;
  billingStartDate: DateTime;
  billingEndDate: DateTime;
}

export default defineComponent({
  name: AdminBrandedKinvaultDetailsInvitationsBillingRoute,
  mixins: [BrandedKinvaultDetailsMixin, AuthService.mixin()],
  components: {
    MasterListPage,
    Button,
  },
  data: () => ({
    filters: ReadBrandedKinvaultInviteBillingForm(),
    data: null as null | {
      months: {
        year: number;
        month: number;
        newUsers: number;
        renewedUsers: number;
        activeUsers: number;
        pricePerUnit: number;
        newUserTotalPrice: number;
        renewedUserTotalPrice: number;
        totalPrice: number;
      }[];
    },
    columns: [
      {
        title: "Billing Period",
        slot: "billing",
      },
      {
        title: "Active Users (Sum)",
        map: (row) => (row.activeUsers > 0 ? row.activeUsers : "-"),
      },
      {
        title: "New Users",
        map: (row) => (row.newUsers > 0 ? row.newUsers : "-"),
      },
      {
        title: "Renewals",
        map: (row) => (row.renewals > 0 ? row.renewals : "-"),
      },
      {
        title: "Value (£)",
        map: (row) => (row.sum > 0 ? `£${row.sum.toFixed(2)}` : "-"),
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<Row>>,
  }),
  computed: {
    monthly(): Row[] {
      return (
        this.data?.months.map((month) => ({
          newUsers: month.newUsers,
          renewals: month.renewedUsers,
          activeUsers: month.activeUsers,
          billingPeriod: [
            month.year.toString(),
            month.month.toString().padStart(2, "0"),
          ].join("-"),
          billingStartDate: new DateTime(
            `${month.year}-${month.month.toString().padStart(2, "0")}-01`,
          ),
          billingEndDate: new DateTime(
            `${month.year}-${month.month.toString().padStart(2, "0")}-01`,
          ).moveTo("monthEnd"),
          sum: month.totalPrice,
        })) ?? []
      );
    },
    quarterly(): Row[] {
      return (
        this.data?.months.reduce((acc, month) => {
          const q = Math.floor((month.month - 1) / 3) + 1;
          const currentYearQuarter = `${month.year}-Q${q}`;

          let quarter = acc.find((q) => q.billingPeriod === currentYearQuarter);
          if (!quarter) {
            let qStart = new DateTime();
            let qEnd = new DateTime();
            switch (q) {
              case 1:
                qStart = new DateTime(`${month.year}-01-01`);
                qEnd = new DateTime(`${month.year}-03-31`).setTime(23, 59, 59);
                break;
              case 2:
                qStart = new DateTime(`${month.year}-04-01`);
                qEnd = new DateTime(`${month.year}-06-30`).setTime(23, 59, 59);
                break;
              case 3:
                qStart = new DateTime(`${month.year}-07-01`);
                qEnd = new DateTime(`${month.year}-09-30`).setTime(23, 59, 59);
                break;
              case 4:
                qStart = new DateTime(`${month.year}-10-01`);
                qEnd = new DateTime(`${month.year}-12-31`).setTime(23, 59, 59);
                break;
            }

            quarter = {
              newUsers: 0,
              renewals: 0,
              activeUsers: 0,
              billingPeriod: currentYearQuarter,
              billingStartDate: qStart,
              billingEndDate: qEnd,
              sum: 0,
            };
            acc.push(quarter);
          }

          quarter.newUsers += month.newUsers;
          quarter.renewals += month.renewedUsers;
          quarter.activeUsers = month.activeUsers; // This should reflect the state at the end of the quarter
          quarter.sum += month.totalPrice;

          return acc;
        }, [] as Row[]) ?? []
      );
    },
    rows(): Row[] {
      return this.filters.localData.granularity === "month"
        ? this.monthly
        : this.quarterly;
    },
  },
  methods: {
    async refresh() {
      const brandedKinvault = this.brandedKinvault?.id;

      if (!brandedKinvault) {
        return;
      }

      // const response = await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-fees/compute",
      //   {
      //     brandedKinvault: brandedKinvault,
      //     ...this.filters.localData,
      //   },
      // );

      const response =
        await window.Kernel.ActionBus.adminBrandedKinvault.invitation.kinvaultFees.compute(
          {
            brandedKinvault,
            ...this.filters.localData,
          },
        );

      this.data = response;
    },
    async download(row: Row) {
      const brandedKinvault = this.brandedKinvault?.id;

      if (!brandedKinvault) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-fees/download",
      //   {
      //     brandedKinvault: brandedKinvault,
      //     startDate: row.billingStartDate,
      //     endDate: row.billingEndDate,
      //   },
      // );
      await window.Kernel.ActionBus.adminBrandedKinvault.invitation.kinvaultFees.download(
        {
          brandedKinvault,
          startDate: row.billingStartDate,
          endDate: row.billingEndDate,
        },
      );
    },
    async pushToXero(row: Row) {
      const brandedKinvault = this.brandedKinvault?.id;
      const xeroBillingContact = this.brandedKinvault?.xeroContact;

      if (!brandedKinvault) {
        return;
      }

      if (!xeroBillingContact) {
        await OpenDangerDialog({
          dialog: {
            title: "Push To Xero",
            message: "You must set a Xero Billing Contact ID in settings",
          },
        });

        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: "Push To Xero",
          message: "Are you sure you want to push this invoice to Xero?",
        },
        button: {
          ok: {
            text: "Yes Push To Xero",
          },
          cancel: {
            text: "Cancel",
          },
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-fees/push-to-xero",
      //   {
      //     brandedKinvault,
      //     startDate: row.billingStartDate,
      //     endDate: row.billingEndDate,
      //   },
      // );
      await window.Kernel.ActionBus.adminBrandedKinvault.invitation.kinvaultFees.pushToXero(
        {
          brandedKinvault,
          startDate: row.billingStartDate,
          endDate: row.billingEndDate,
        },
      );
    },
  },
});
</script>
