import { Api } from "@/service/api.service";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface CreateIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export interface CreateIntroducerFeePaymentItemResponse {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export const CreateIntroducerFeePaymentItemHandler = async (
  message: CreateIntroducerFeePaymentItemMessage,
): Promise<CreateIntroducerFeePaymentItemResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-item",
  )
    .method("post")
    .body({
      amount: message.introducerFeePaymentItem.amount,
      feeTypeId: message.introducerFeePaymentItem.feeTypeId,
      introducerFeePayment:
        message.introducerFeePaymentItem.introducerFeePayment.$id,
      name: message.introducerFeePaymentItem.name,
      order: message.introducerFeePaymentItem.order.$id,
      vat: message.introducerFeePaymentItem.vat,
    })
    .result();

  const introducerFeePaymentItem = IntroducerFeePaymentItem.$inflate(
    repsonse.introducerFeePaymentItem,
  ).first();

  if (!introducerFeePaymentItem) {
    throw new Error("Failed to create introducerfeepaymentitem");
  }

  message.introducerFeePaymentItem.$delete();

  return {
    introducerFeePaymentItem: introducerFeePaymentItem,
  };
};
