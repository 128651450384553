import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsBreadCrumb } from "../../../breadcrumbs";

export const LeadDetailsEmailLogRoute = "LeadDetailsEmailLog";
export const LeadDetailsEmailLogURI = "/lead/:lead/email-log";
export type LeadDetailsEmailLogParams = RouteParamObject<
  typeof LeadDetailsEmailLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsEmailLogRoute,
    path: LeadDetailsEmailLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsEmailLog" */ "./LeadDetails.EmailLog.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...LeadDetailsBreadCrumb(params),
        {
          text: "Email Log",
          route: {
            name: LeadDetailsEmailLogRoute,
            params,
          },
        },
      ],
    },
  },
];
