import { Api } from "@/service/api.service";
import {
  AccountReferralCode,
  Address,
  Adviser,
  Attorney,
  Beneficiary,
  CallScript,
  EmailAddress,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Person,
  PhoneNumber,
  Profile,
  Trustee,
  Website,
} from "@kinherit/sdk";

interface RecordKintinAddressBookMessage {
  kintin: string;
}

interface RecordKintinAddressBookResponse {
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  people: Array<Person>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
  advisers: Array<Adviser>;
  attorneys: Array<Attorney>;
  beneficiaries: Array<Beneficiary>;
  executors: Array<Executor>;
  gifts: Array<Gift>;
  guardians: Array<Guardian>;
  trustees: Array<Trustee>;
  callScripts: Array<CallScript>;
  referralCodes: Array<AccountReferralCode>;
  exclusions: Array<Exclusion>;
}

export const RecordKintinAddressBookHandler = async (
  message: RecordKintinAddressBookMessage,
): Promise<RecordKintinAddressBookResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/{kintin}/address-book",
    {
      kintin: message.kintin,
    },
  )
    .method("get")

    .result();

  return {
    kintins: Kintin.$inflate(response.kintin),
    profiles: Profile.$inflate(response.profile),
    people: Person.$inflate(response.person),
    addresses: Address.$inflate(response.address),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    websites: Website.$inflate(response.website),
    advisers: Adviser.$inflate(response.adviser),
    attorneys: Attorney.$inflate(response.attorney),
    referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
    beneficiaries: Beneficiary.$inflate(response.beneficiary),
    executors: Executor.$inflate(response.executor),
    gifts: Gift.$inflate(response.gift),
    guardians: Guardian.$inflate(response.guardian),
    trustees: Trustee.$inflate(response.trustee),
    callScripts: CallScript.$inflate(response.callScript),
    exclusions: Exclusion.$inflate(response.exclusion),
  };
};
