import { CreateIntroducerNetwork } from "@/config/model.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerNetwork } from "@kinherit/sdk";

export const CreateIntroducerNetworkForm = (
  introducerNetwork: IntroducerNetwork = CreateIntroducerNetwork({}),
) => {
  return defineForm({
    name: "create-introducer-network-form",
    data: () => introducerNetwork,
    formAreas: (data) => [
      defineFormArea({
        name: "create-introducer-network-form-area",
        data,
        template: GridLayout([["name"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
