import { Api } from "@/service/api.service";
import { BrandedKinvaultMembership } from "@kinherit/sdk";

interface DeleteBrandedKinvaultMembershipMessage {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

interface DeleteBrandedKinvaultMembershipResponse {
  [key: string]: never;
}

export const DeleteBrandedKinvaultMembershipHandler = async (
  message: DeleteBrandedKinvaultMembershipMessage,
): Promise<DeleteBrandedKinvaultMembershipResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-membership/{brandedKinvaultMembership}",
    message,
  )
    .method("delete")
    .result();

  message.brandedKinvaultMembership.$delete();

  return {};
};
