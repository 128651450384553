import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, IsNotNull, IsNull, Like } from "@kinherit/orm/index";
import {
  Api,
  IWillRevision,
  Kintin,
  Profile,
  QueryMask,
  User,
  WillRevision,
} from "@kinherit/sdk";

export interface ReadWillRevisionMessage {
  search?: string;
  created?: null | [number, number];
  approved?: boolean | null;
  query?: QueryMask<typeof WillRevision>;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IWillRevision;
    direction: "asc" | "desc";
  };
}

interface ReadWillRevisionResponse {
  willRevisions: Array<WillRevision>;
  profiles: Array<Profile>;
  kintins: Array<Kintin>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadWillRevisionHandler = async (
  message: ReadWillRevisionMessage,
): Promise<ReadWillRevisionResponse> => {
  const request = Api.resource("portal", "/v2/portal/will-revision")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  if (message.query) {
    request.buildQuery(WillRevision).where(message.query);
  } else {
    request.buildQuery(WillRevision).where({
      kintin: {
        friendlyName: Like(message.search),
      },
      approvedAt:
        null === message.approved || undefined === message.approved
          ? undefined
          : true === message.approved
            ? IsNotNull()
            : IsNull(),
      createdAt: Between(message.created),
    });
  }

  const response = await request.result();

  return {
    willRevisions: WillRevision.$inflate(
      response.willRevision,
      message.sort,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
    kintins: Kintin.$inflate(response.kintin),
    users: User.$inflate(response.user),
    pagination: getPagination(response),
  };
};
