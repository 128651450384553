import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadEmailTemplateForm = (): FormBuilder<
  () => {
    search: string;
    type: Array<string>;
    created?: [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-email-template-form",
    data: () => ({
      search: "",
      type: Array<string>(),
      created: undefined as undefined | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Search / Subject / Content",
            size: "is-small",
          },
          models: {
            value: "search",
          },
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "emailTemplateType",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
