import { Between, Equal, In, Like } from "@kinherit/orm/index";
import {
  Api,
  CompanyPartnerPortalPermissions,
  IIntroducerCompany,
  IntroducerCompany,
  IntroducerNetwork,
  Profile,
  User,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyMessage {
  search?: string | null;
  tags?: Array<string>;
  status?: Array<string>;
  type?: Array<string>;
  stage?: Array<string>;
  seniorAdviser?: Array<string>;
  assignedUser?: Array<string>;
  network?: Array<string>;
  introducedBy?: Array<string>;

  created?: null | [number, number];
  contracts?: {
    type?: {
      value?: "agentintroducer";
    };
  };
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerCompany;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerCompanyResponse {
  introducerCompanies: Array<IntroducerCompany>;
  introducerNetworks: Array<IntroducerNetwork>;
  companyPartnerPortalPermissions: Array<CompanyPartnerPortalPermissions>;
  profiles: Array<Profile>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerCompanyHandler = async (
  message: ReadIntroducerCompanyMessage,
): Promise<ReadIntroducerCompanyResponse> => {
  const request = Api.resource("portal", "/v2/portal/introducer-company")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(IntroducerCompany).where([
    {
      contracts: {
        type: {
          value: Equal(message.contracts?.type?.value),
        },
      },
      profile: {
        fullName: Like(message.search),
      },
      tags: {
        id: In(message.tags),
      },
      status: {
        id: In(message.status),
      },
      type: {
        id: In(message.type),
      },
      stage: {
        id: In(message.stage),
      },
      assignedTo: {
        id: In(message.assignedUser),
      },
      seniorAdviser: {
        id: In(message.seniorAdviser),
      },
      network: In(message.network),
      introducedBy: {
        id: In(message.introducedBy),
      },
      createdAt: Between(message.created),
    },
    {
      contracts: {
        type: {
          value: Equal(message.contracts?.type?.value),
        },
      },
      profile: {
        organisationName: Like(message.search),
      },
      tags: {
        id: In(message.tags),
      },
      status: {
        id: In(message.status),
      },
      type: {
        id: In(message.type),
      },
      stage: {
        id: In(message.stage),
      },
      assignedTo: {
        id: In(message.assignedUser),
      },
      seniorAdviser: {
        id: In(message.seniorAdviser),
      },
      network: In(message.network),
      introducedBy: {
        id: In(message.introducedBy),
      },
      createdAt: Between(message.created),
    },
  ]);

  const response = await request.result();

  return {
    introducerCompanies: IntroducerCompany.$inflate(
      response.introducerCompany,
      message.sort,
      response.$rootIds,
    ),
    introducerNetworks: IntroducerNetwork.$inflate(response.introducerNetwork),
    companyPartnerPortalPermissions: CompanyPartnerPortalPermissions.$inflate(
      response.companyPartnerPortalPermissions,
    ),
    profiles: Profile.$inflate(response.profile),
    users: User.$inflate(response.user),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
