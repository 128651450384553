import { Api } from "@/service/api.service";
import { EmailCampaign } from "@kinherit/sdk";

export interface UpdateEmailCampaignMessage {
  emailCampaign: EmailCampaign;
}

export interface UpdateEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
}

export const UpdateEmailCampaignHandler = async (
  message: UpdateEmailCampaignMessage,
): Promise<UpdateEmailCampaignResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/email-campaign/{emailCampaign}",
    message,
  )
    .method("patch")

    .body({
      description: message.emailCampaign.$data.description,
      name: message.emailCampaign.$data.name,
    });

  const response = await request.result();

  message.emailCampaign.$persist();

  return {
    emailCampaign: EmailCampaign.$inflate(
      response.emailCampaign,
      undefined,
      response.$rootIds,
    ),
  };
};
