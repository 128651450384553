import { Api } from "@/service/api.service";
import { Order, OrderItem } from "@kinherit/sdk";

export interface UpdateOrdersMessage {
  order: Order;
}

export interface UpdateOrdersResponse {
  order: Order;
  orderItems: Array<OrderItem>;
}

export const UpdateOrderHandler = async (
  message: UpdateOrdersMessage,
): Promise<UpdateOrdersResponse> => {
  await Api.resource("portal", "/v2/portal/order/{order}", {
    order: message.order.id,
  })
    .method("patch")

    .body({
      status: message.order.$data.status,
      paymentType: message.order.$data.paymentType,
      extrasPrice: message.order.$data.extrasPrice,
      discountValue: message.order.$data.discountValue,
      discountPercentage: message.order.$data.discountPercentage,
      coolingOff: message.order.$data.coolingOff,
      paidAt: message.order.$data.paidAt,
      invoiceNumber: message.order.$data.invoiceNumber,
      notes: message.order.$data.notes,
      paymentSignKey: message.order.$data.paymentSignKey,
      transactionId: message.order.$data.transactionId,
      overrideListPrice: message.order.$data.overrideListPrice,
      feesPayOutIntro: message.order.$data.feesPayOutIntro,
      feesPayoutAgent: message.order.$data.feesPayoutAgent,
      feesPayOutIntroDate: message.order.$data.feesPayOutIntroDate,
      feesPayOutAgentDate: message.order.$data.feesPayOutAgentDate,
      feesPayOutParentAgent: message.order.$data.feesPayOutParentAgent,
      feesPayOutParentAgentDate: message.order.$data.feesPayOutParentAgentDate,
      feesConfirmed: message.order.$data.feesConfirmed,
      discountReason: message.order.$data.discountReason,
      xeroIntroducerBillId: message.order.$data.xeroIntroducerBillId,
      xeroAgentBillId: message.order.$data.xeroAgentBillId,
      billingName: message.order.$data.billingName,
      billingAddress1: message.order.$data.billingAddress1,
      billingAddress2: message.order.$data.billingAddress2,
      billingCity: message.order.$data.billingCity,
      billingRegion: message.order.$data.billingRegion,
      billingPostalCode: message.order.$data.billingPostalCode,
      xeroParentAgentBillId: message.order.$data.xeroParentAgentBillId,
      createdBy: message.order.$data.createdBy,
    })
    .result();

  await Api.resource("portal", "/v2/portal/order/order-items")
    .method("put")

    .body({
      order: message.order.id,
      orderItems: message.order.orderItems.map((orderItem) => ({
        id: orderItem.id,
        text: orderItem.text,
        value: orderItem.value,
        price: { amount: orderItem.price.amount, type: orderItem.price.type },
        quantity: orderItem.quantity,
        requiresDd: orderItem.requiresDd,
        recurring: orderItem.recurring,
        product: orderItem.product?.id ?? null,
        vat: orderItem.vat,
      })),
    })
    .result();

  message.order.orderItems.forEach((orderItem) => orderItem.$delete());

  // await window.Kernel.ActionBus.execute("order/order/record", {
  //   order: message.order,
  // });
  await window.Kernel.ActionBus.order.order.record({
    order: message.order,
  });

  return {
    order: Order.$findOneOrThrow(message.order.id),
    orderItems: OrderItem.$findBy({
      order: {
        id: message.order.id,
      },
    }),
  };
};
