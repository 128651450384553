<template>
  <div v-if="kintin">
    <CallScriptSection title="Financial Adviser">
      <mark>@todo</mark>

      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1g"
        :kintin="kintin"
        v-if="checkIsYes('4.7.1e')"
      />
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IfaTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
    ConfirmCheck,
  },
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
});
</script>
