<template>
  <div v-if="data">
    <p>
      <strong>Shares to Reserve Beneficiaries</strong><br />
      In the event that all of the Beneficiaries listed above, have died at a
      time when funds remain in the Trust Fund undistributed, I would wish you
      to exercise your powers in relation to the Trust Fund to pay the remaining
      funds as follows:
    </p>
    <ol type="i">
      <li
        v-for="beneficiary in data.reserveBeneficiaries"
        :key="beneficiary.id"
      >
        <span
          v-html="
            helpers.var(
              helpers.calculateBeneficiaryShare(
                data.reserveBeneficiaries,
                beneficiary,
                false,
              ),
            )
          "
        />
        <span v-html="helpers.var(helpers.formatBeneficiary(beneficiary))" />
        <span
          v-html="helpers.var(helpers.beneficiaryTheirConcern(beneficiary))"
        />.
      </li>
    </ol>
    <p>
      Should any of the above shares fail in their entirety whilst assets remain
      in the trust, I would like the share that has failed to be divided between
      the shares that have not failed in proportion to that respective share.
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
