import { Api } from "@/service/api.service";
import { CpdSession } from "@kinherit/sdk";

interface DeleteCpdSessionMessage {
  cpdSession: string | CpdSession;
}

interface DeleteCpdSessionResponse {
  [key: string]: never;
}

export const DeleteCpdSessionHandler = async (
  message: DeleteCpdSessionMessage,
): Promise<DeleteCpdSessionResponse> => {
  await Api.resource("portal", "/v2/portal/cpd-session/{cpdSession}", {
    cpdSession:
      "string" === typeof message.cpdSession
        ? message.cpdSession
        : message.cpdSession.id,
  })
    .method("delete")
    .result();

  return {};
};
