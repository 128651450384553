import { Api, EmailCampaignLog, FileLog } from "@kinherit/sdk";
// type Message = {
//   emailCampaignLog: EmailCampaignLog;
//   fileLog: FileLog | string;
// };

// type Response = {};

// export class DeleteEmailCampaignLogAttachmentHandler<
//   M extends Message,
//   R extends Response,
// > extends ActionBase {
//   constructor(private message: M) {
//     super();
//   }

//   public async execute(): Promise<R> {
export const DeleteEmailCampaignLogAttachmentHandler = async (message: {
  emailCampaignLog: EmailCampaignLog;
  fileLog: FileLog | string;
}): Promise<{}> => {
  await Api.resource(
    "portal",
    "/v2/portal/email-campaign-log/{emailCampaignLog}/attachment/{fileLog}",
    message,
  )
    .method("delete")
    .result();

  return {};
};
