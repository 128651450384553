import { Api } from "@kinherit/sdk/api";

type AcuityReconcileProfilesMessage = {
  minDate: string;
  maxDate: string;
};

export const AcuityReconcileProfilesAction = async (
  message: AcuityReconcileProfilesMessage,
): Promise<void> => {
  await Api.resource("portal", "/v2/portal/appointment/reconcile-profiles")
    .method("put")
    .body({
      minDate: message.minDate,
      maxDate: message.maxDate,
    })
    .result();
};
