import {
  Api,
  FileLog,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
} from "@kinherit/sdk";

export interface RecordKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument | string;
}

interface RecordKnowledgeBaseDocumentResponse {
  knowledgeBaseDocuments: Array<KnowledgeBaseDocument>;
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
  fileLogs: Array<FileLog>;
}

export const RecordKnowledgeBaseDocumentHandler = async (
  message: RecordKnowledgeBaseDocumentMessage,
): Promise<RecordKnowledgeBaseDocumentResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}",
    message,
  ).method("get");

  const response = await request.result();

  return {
    knowledgeBaseDocuments: KnowledgeBaseDocument.$inflate(
      response.knowledgeBaseDocument,
      undefined,
      response.$rootIds,
    ),
    knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
    ),
    fileLogs: FileLog.$inflate(response.fileLog),
  };
};
