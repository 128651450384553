<template>
  <CallScriptSection title="Security">
    <b v-if="start">{{ start }}</b>
    <br /><br />
    <b>Can you confirm the email address we hold for you?</b><br />
    <em>[also check at least one other data point] </em><br /><br />
    <div class="columns">
      <div class="column">
        <CallScriptPersonDetails
          v-if="primaryPerson"
          :person="primaryPerson"
          :hideEmpty="true"
        />
      </div>
      <div class="column" v-if="secondaryPerson">
        <CallScriptPersonDetails
          v-if="secondaryPerson"
          :person="secondaryPerson"
          :hideEmpty="true"
        />
      </div>
    </div>
    <b>That's great, thank you.</b>
    <br />
    <em>
      [If Fail: Check you're speaking to the correct person and that they are
      expecting the call. Terminate call and reschedule if appropriate.]
    </em>
    <ConfirmCheck
      v-if="step"
      :step="step"
      :kintin="kintin"
      :checks="checks"
      :kintinChecks="kintinChecks"
      @update="(step, value) => $emit('update', step, value)"
    />
    <span v-if="end">{{ end }}</span>
  </CallScriptSection>
</template>

<script lang="ts">
import { Check, Kintin, KintinCheck } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import ConfirmCheck from "./ConfirmCheck.vue";
import CallScriptPersonDetails from "./PersonDetails.vue";
import CallScriptSection from "./Section.vue";

export default defineComponent({
  name: "CallScriptSecuritySection",
  components: { CallScriptSection, CallScriptPersonDetails, ConfirmCheck },
  props: {
    step: {
      type: String as PropType<`${string}.${string}.${string}`>,
      default: null,
    },
    start: {
      type: String,
      default: null,
    },
    end: {
      type: String,
      default: null,
    },
    kintin: {
      type: Kintin,
      default: null,
    },
    checks: {
      type: Array as PropType<Check[]>,
      required: true,
    },
    kintinChecks: {
      type: Array as PropType<KintinCheck[]>,
      required: true,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: (step: string, value: number) => true,
  },
  computed: {
    primaryPerson() {
      return this.kintin.primaryPerson;
    },
    secondaryPerson() {
      return this.kintin.secondaryPerson;
    },
  },
});
</script>
