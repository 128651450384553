<template>
  <div class="branded-kinvault-master-list">
    <PageHeader htag="h1" text="Branded Kinvaults">
      <template #buttons>
        <Button class="create" size="is-normal" @click="createBrandedKinvault">
          Add Branded Kinvault
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.organisationName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultMasterListWrapper",
  "route": "BrandedKinvaultMasterList",
  "selector": ".branded-kinvault-master-list",
  "imports": {
    "ReadBrandedKinvaultFormWrapper": "@/module/admin.branded-kinvault/form/read-branded-kinvault.form.test",
    "BrandedKinvaultDetailsWrapper": "@/module/admin.branded-kinvault/page/details/BrandedKinvaultDetails.test",
    "CreateBrandedKinvaultFormWrapper": "@/module/admin.branded-kinvault/form/create-branded-kinvault.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadBrandedKinvaultFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "BrandedKinvaultDetailsWrapper"
    },
    "createBrandedKinvault": {
      "type": "click",
      "selector": ".create"
    },
    "createBrandedKinvaultForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateBrandedKinvaultFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { BrandedKinvaultTableColumns } from "@/config/table-columns.config";
import { ReadBrandedKinvaultForm } from "@/module/admin.branded-kinvault/form/read-branded-kinvault.form";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { BrandedKinvault, IBrandedKinvault } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultMasterListRoute } from ".";
import { CreateBrandedKinvaultForm } from "../form/create-branded-kinvault.form";
import {
  BrandedKinvaultDetailsParams,
  BrandedKinvaultDetailsRoute,
} from "./details";

export default defineComponent({
  name: BrandedKinvaultMasterListRoute,
  components: { MasterListPage, PageHeader, Button },
  data: () => ({
    filters: ReadBrandedKinvaultForm(),
    columns: BrandedKinvaultTableColumns,
    rows: Array<BrandedKinvault>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvault,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    handleClick(brandedKinvault: BrandedKinvault, event: MouseEvent): void {
      const params: BrandedKinvaultDetailsParams = {
        brandedKinvault: brandedKinvault.id,
      };

      window.Kernel.visitRoute(
        {
          name: BrandedKinvaultDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadBrandedKinvaultForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/branded-kinvault/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data =
        await window.Kernel.ActionBus.adminBrandedKinvault.brandedKinvault.read(
          {
            ...formData,
            sort: this.sort,
            pagination: this.pagination,
          },
        );

      this.rows = data.brandedKinvaults;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async createBrandedKinvault(): Promise<void> {
      const brandedKinvault = await CreateBrandedKinvaultForm().dialog({
        dialog: {
          title: "Add Branded Kinvault",
        },
      });

      // const reponse = await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/branded-kinvault/create",
      //   {
      //     brandedKinvault,
      //   },
      // );

      const reponse =
        await window.Kernel.ActionBus.adminBrandedKinvault.brandedKinvault.create(
          {
            brandedKinvault,
          },
        );

      window.Kernel.visitRoute({
        name: BrandedKinvaultDetailsRoute,
        params: {
          brandedKinvault: reponse.brandedKinvault.id,
        } satisfies BrandedKinvaultDetailsParams,
      });
    },
  },
});
</script>
