import { Api } from "@/service/api.service";
import { Appointment } from "@kinherit/sdk";

interface RecordAppointmentMessage {
  appointment: Appointment | string;
}

interface RecordAppointmentResponse {
  appointment: Appointment;
}

export const RecordAppointmentHandler = async (
  message: RecordAppointmentMessage,
): Promise<RecordAppointmentResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/appointment/{appointment}",
    {
      appointment: message.appointment,
    },
  )
    .method("get")

    .result();

  const appointment = Appointment.$inflate(response.appointment).first();

  if (!appointment) {
    throw new Error("Appointment not found");
  }

  return {
    appointment,
  };
};
