import { Api, BrandedKinvaultPricingSchema } from "@kinherit/sdk";

export interface DeleteBrandedKinvaultPricingSchemaMessage {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

interface DeleteBrandedKinvaultPricingSchemaResponse {}

export const DeleteBrandedKinvaultPricingSchemaHandler = async (
  message: DeleteBrandedKinvaultPricingSchemaMessage,
): Promise<DeleteBrandedKinvaultPricingSchemaResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-kinvault-pricing-schema/{brandedKinvaultPricingSchema}",
    message,
  ).method("delete");

  await request.result();

  message.brandedKinvaultPricingSchema.$delete();

  return {};
};
