<template>
  <span v-if="data">
    <strong>Legacy - Bank Accounts</strong>
    <p>
      I give the balance including any accrued interest to the date of my death
      of the following bank accounts:
    </p>
    <ul type="i">
      <li v-for="bankAccount in data.bankAccountGifts" :key="bankAccount.id">
        to
        <span v-html="helpers.var(helpers.formatRecipient(bankAccount))" /> the
        balance including any accrued interest of
        <span v-html="helpers.var(bankAccount.notes)" />
        <span v-if="bankAccount.onSecondDeath" class="is-will-builder-inject">
          only if my
          <span
            v-if="data.person"
            v-html="
              helpers.var(helpers.formatRelationship(data.person, data.partner))
            "
          />
          has predeceased me
          <span v-html="helpers.var(helpers.formatPredeceased(bankAccount))" />
        </span>
      </li>
    </ul>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
