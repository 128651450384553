import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { EmailCampaignDetailsParams } from "..";
import {
  EmailCampaignDetailsMembersBreadCrumb,
  EmailCampaignDetailsMembersCsvBreadCrumb,
} from "../../../breadcrumbs";

export const EmailCampaignDetailsMembersRoute = "EmailCampaignDetailsMembers";
const EmailCampaignDetailsMembersURI =
  "/admin.email-campaign/:emailCampaign/members";
export type EmailCampaignDetailsMembersParams = RouteParamObject<
  typeof EmailCampaignDetailsMembersURI
>;

export const EmailCampaignDetailsMembersCsvRoute =
  "EmailCampaignDetailsMembersCsv";
const EmailCampaignDetailsMembersCsvURI =
  "/admin.email-campaign/:emailCampaign/members/csv";
export type EmailCampaignDetailsMembersCsvParams = RouteParamObject<
  typeof EmailCampaignDetailsMembersCsvURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: EmailCampaignDetailsMembersRoute,
    path: EmailCampaignDetailsMembersURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "EmailCampaignDetailsMembers" */ "./EmailCampaignDetails.Members.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        EmailCampaignDetailsMembersBreadCrumb(
          params as EmailCampaignDetailsParams,
        ),
    },
  },
  {
    name: EmailCampaignDetailsMembersCsvRoute,
    path: EmailCampaignDetailsMembersCsvURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "EmailCampaignDetailsMembersCsv" */ "./EmailCampaignDetails.MembersCsv.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        EmailCampaignDetailsMembersCsvBreadCrumb(
          params as EmailCampaignDetailsParams,
        ),
    },
  },
];
