import { Api } from "@/service/api.service";
import { Kintin, Person } from "@kinherit/sdk";

interface CreateKintinPersonMessage {
  person: Person;
  kintin: Kintin;
}

interface CreateKintinPersonResponse {
  person: Person;
}

export const CreateKintinPersonHandler = async (
  message: CreateKintinPersonMessage,
): Promise<CreateKintinPersonResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-person/{kintin}/person",
    {
      kintin: message.kintin.id,
    },
  )
    .method("post")

    .body({
      kintin: message.kintin.id,
      notificationDate: message.person.$data.notificationDate,
      notifications: message.person.$data.notifications,
      gender: message.person.$data.gender,
      maritalStatus: message.person.$data.maritalStatus,
      notifyOnDeath: message.person.$data.notifyOnDeath,
      under18ForPlanningPurposes:
        message.person.$data.under18ForPlanningPurposes,
      requiresCare: message.person.$data.requiresCare,
      isTrusted: message.person.$data.isTrusted,
      dateOfBirth: message.person.$data.dateOfBirth,
      relationToPrimaryPerson: message.person.$data.relationToPrimaryPerson,
      relationToSecondaryPerson: message.person.$data.relationToSecondaryPerson,
      dateOfDeath: message.person.$data.dateOfDeath,
      profile: {
        title: message.person.profile.$data.title,
        firstName: message.person.profile.$data.firstName,
        middleNames: message.person.profile.$data.middleNames,
        lastName: message.person.profile.$data.lastName,
        suffix: message.person.profile.$data.suffix,
        organisationName: message.person.profile.$data.organisationName,
        organisationNumber: message.person.profile.$data.organisationNumber,
        addresses: message.person.profile.addresses.map((address) => ({
          line1: address.$data.line1,
          line2: address.$data.line2,
          city: address.$data.city,
          country: address.$data.country,
          state: address.$data.state,
          postcode: address.$data.postcode,
        })),
        emails: message.person.profile.emails.map((email) => ({
          email: email.$data.email,
        })),
        phoneNumbers: message.person.profile.phoneNumbers.map(
          (phoneNumber) => ({
            tel: phoneNumber.$data.tel,
          }),
        ),
        websites: message.person.profile.websites.map((website) => ({
          url: website.$data.url,
        })),
      },
      type: message.person.$data.type,
    })
    .result();

  const personData = response.person?.first();

  if (!personData) {
    throw new Error(`Failed to create person`);
  }

  message.person.$delete({
    profile: true,
    // relationToPrimaryPerson: true,
    // relationToSecondaryPerson: true,
  });

  // const { people } = await window.Kernel.ActionBus.execute(
  //   "kinvault/kintin/person/record",
  //   {
  //     person: personData.id,
  //     kintin: message.kintin.id,
  //   },
  // );
  const { people } = await window.Kernel.ActionBus.kinvaultKintin.person.record(
    {
      person: personData.id,
      kintin: message.kintin.id,
    },
  );

  const person = people.first();

  if (!person) {
    throw new Error(`Failed to create person`);
  }

  return { person };
};
