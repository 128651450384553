<template>
  <strong> Executor and Trustee Powers </strong>
  <p>
    My Executors and trustees shall in addition to and without prejudice to all
    statutory powers have the powers and immunities set out in The STEP Powers
    provided they shall not exercise any of their powers so as to conflict with
    the beneficial provisions of this my Will
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
