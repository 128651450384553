import { OpenDangerDialog } from "@kinherit/framework/global/dialog";
import { Api } from "@kinherit/sdk/api";

export const UniqueUsernameAction = async (username: string): Promise<void> => {
  try {
    await Api.resource("auth", "/v2/auth/unique-username")
      .method("get")
      .params({ username: username })
      .result();
  } catch (error) {
    OpenDangerDialog({
      dialog: {
        title: "Username is not unique",
        message: `The username "${username}" is already in use. This usually indicates that an account already exists: check with TK!`,
      },
    });
    throw error;
  }
};
