import { Api } from "@/service/api.service";
import { BrandedKinvault, Profile, QueryMask } from "@kinherit/sdk";

export interface ReadBrandedKinvaultMessage {
  query?: QueryMask<typeof BrandedKinvault>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadBrandedKinvaultResponse {
  brandedKinvaults: Array<BrandedKinvault>;
  profiles: Array<Profile>;
}

export const ReadBrandedKinvaultHandler = async (
  message: ReadBrandedKinvaultMessage,
): Promise<ReadBrandedKinvaultResponse> => {
  const request = Api.resource("core", "/v2/core/select/branded-kinvault")
    .method("get")

    .paginate(message.pagination ?? false);

  request.buildQuery(BrandedKinvault).where(message.query ?? {});

  const response = await request.result();

  return {
    brandedKinvaults: BrandedKinvault.$inflate(
      response.brandedKinvault,
      undefined,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
  };
};
