<template>
  <Grid class="introducer-fee-details" desktop="one-quarter">
    <template #client>
      <DataListWidget
        title="Account"
        is-compact
        :details="[
          {
            label: `Name`,
            value: data.data.client.name,
          },
          {
            label: `Created`,
            value: formatDate(data.data.client.createdAt),
          },
          {
            label: `Age`,
            value: clientAge,
          },
          {
            label: `Referral code`,
            value: data.data.client.referralCode,
          },
        ]"
      />
      <DataListWidget
        title="Order"
        is-compact
        :details="[
          {
            label: `ID`,
            value: data.data.order.id,
          },
          {
            label: `Created`,
            value: formatDate(data.data.order.createdAt),
          },
          {
            label: `Paid on`,
            value: formatDate(data.data.order.paidAt),
          },
          {
            label: `Absolute Discount`,
            value: `£${data.data.order.discountValue?.toString()}`,
            hide: null === data.data.order.discountReason,
          },
          {
            label: `Discount By`,
            value: `${data.data.order.discountPercentage?.toString()}%`,
            hide: null === data.data.order.discountReason,
          },
          {
            label: `Discount Reason`,
            value: data.data.order.discountValue?.toString(),
            hide: null === data.data.order.discountReason,
          },
          {
            label: `Total Price`,
            value: `£${data.data.order.totalPrice.toString()}`,
          },
          { label: `Total Price - exvat`, value: totalPriceExVat },
        ]"
      />
    </template>
    <template #introducer>
      <DataListWidget
        title="Introducer"
        is-compact
        :details="[
          {
            label: `Name`,
            value: data.data.company?.name,
            hide: null === data.data.company,
          },
          {
            label: `Contact`,
            value: data.data.contact?.name,
            hide: null === data.data.contact,
          },
          {
            label: `Created`,
            value: formatDate(data.data.company?.createdAt),
          },
          {
            label: `Notes`,
            value: data.data.company?.contractNotes,
            hide: null === data.data.company?.contractNotes,
          },
        ]"
      />
      <DataListWidget
        v-if="data.data.companyContract?.type === 'introducer'"
        title="Contract"
        is-compact
        :details="[
          {
            label: 'Contract Type',
            value: data.data.companyContract?.type,
          },
          {
            label: 'Contract Date',
            value: formatDate(data.data.companyContract?.createdAt),
          },
          {
            label: 'Contract End',
            value: formatDate(
              data.data.companyContract?.datedTill,
              `-> No End Date`,
            ),
          },
          {
            label: 'Percent',
            value: `${data.data.companyContract?.i_4c_percent}% / ${data.data.companyContract?.vat}`,
          },
        ]"
      />
      <DataListWidget
        v-if="data.data.companyContract?.type === 'parentnetwork'"
        title="Contract"
        is-compact
        :details="[
          {
            label: 'Contract Type',
            value: data.data.companyContract?.type,
          },
          {
            label: 'Parent Name',
            value: data.data.parentCompany?.name,
          },
          {
            label: 'Contract Date',
            value: formatDate(data.data.parentAgentContract.createdAt),
          },
          {
            label: 'Contract End',
            value: formatDate(
              data.data.parentAgentContract.datedTill,
              `-> No End Date`,
            ),
          },
          {
            label: 'Percent',
            value: `${data.data.parentAgentContract.i_4c_percent}% / ${data.data.parentAgentContract.vat}`,
          },
          {
            label: `Overridden`,
            value: `${data.data.companyContract?.i_4c_percent}%`,
            hide: [null, undefined].includes(
              data.data.companyContract?.i_4c_percent as any,
            ),
          },
        ]"
      />
      <DataListWidget
        title="Fee"
        is-compact
        :details="[
          {
            label: `Calculated Fee`,
            value: `£${data.calculations.introducerFeeNet}`,
            hide: null === data.data.companyContract,
          },
          {
            label: `Actual Fee Paid`,
            value: `£${data.data.order.feesPayOutIntro?.format()} on ${formatDate(
              data.data.order.feesPayOutIntroDate,
            )}`,

            hide:
              null === data.data.order.feesPayOutIntro ||
              null === data.data.order.feesPayOutIntroDate,
          },
        ]"
      />
    </template>
    <template #agent>
      <DataListWidget
        v-if="data.data.agentCompany.id"
        title="Agent Payment"
        is-compact
        :details="[
          {
            label: `Name`,
            value: data.data.agentCompany.name,
          },
        ]"
      />
      <DataListWidget
        v-if="data.data.agentContract.id"
        title="Contract"
        is-compact
        :details="[
          {
            label: `Contract Date`,
            value: formatDate(data.data.agentContract.datedAt),
            hide: null === data.data.agentContract.id,
          },
          {
            label: `Contract End`,
            value: formatDate(
              data.data.agentContract.datedTill,
              `-> No End Date`,
            ),
            hide: null === data.data.agentContract.datedTill,
          },
          {
            label: `Matched Period`,
            value: data.calculations.agentFee.selected?.toString(),
          },
          {
            label: `Period 1`,
            value: `${data.data.agentContract.ai_5a_months} @ ${data.data.agentContract.ai_5a_percent}% ${data.data.agentContract.vat}`,
            hide: [null, undefined].includes(
              data.data.agentContract.ai_5a_months as any,
            ),
          },
          {
            label: `Period 2`,
            value: `${data.data.agentContract.ai_5b_months} @ ${data.data.agentContract.ai_5b_percent}% ${data.data.agentContract.vat}`,
            hide: [null, undefined].includes(
              data.data.agentContract.ai_5b_months as any,
            ),
          },
          {
            label: `Period 3`,
            value: `${data.data.agentContract.ai_5c_months} @ ${data.data.parentAgentContract.ai_5c_percent}% ${data.data.parentAgentContract.vat}`,
            hide: [null, undefined].includes(
              data.data.agentContract.ai_5c_months as any,
            ),
          },
        ]"
      />
      <DataListWidget
        title="Fee"
        is-compact
        :details="[
          {
            label: 'Calculated Fee',
            value: `£${data.calculations.agentFee.fee}`,
            hide: null === data.data.agentContract,
          },
          {
            label: `Actual Fee Paid`,
            value: `£${data.data.order.feesPayoutAgent} on ${formatDate(data.data.order.feesPayOutAgentDate)}`,
            hide:
              null === data.data.order.feesPayoutAgent ||
              null === data.data.order.feesPayOutAgentDate,
          },
        ]"
      />
    </template>
    <template #parent-agent>
      <DataListWidget
        v-if="data.data.parentAgentCompany.id"
        title="Parent Agent Payment"
        is-compact
        :details="[
          {
            label: `Name`,
            value: data.data.parentAgentCompany.name,
          },
        ]"
      />
      <DataListWidget
        v-if="data.data.parentAgentContract.id"
        title="Contract"
        is-compact
        :details="[
          {
            label: 'Contract Date',
            value: formatDate(data.data.parentAgentContract.datedAt),
          },
          {
            label: 'Contract End',
            value: data.data.parentAgentContract.datedTill
              ? formatDate(data.data.parentAgentContract.datedTill)
              : `-> No End Date`,
          },
          {
            label: 'Matched Period',
            value: data.calculations.parentAgentFee.selected?.toString(),
          },
          {
            label: 'Period 1',
            value: `${data.data.parentAgentContract.ai_5a_months} @ ${data.data.parentAgentContract.ai_5a_percent}% ${data.data.parentAgentContract.vat}`,
            hide: [null, undefined].includes(
              data.data.parentAgentContract.ai_5a_months as null | undefined,
            ),
          },
          {
            label: 'Period 2',
            value: `${data.data.parentAgentContract.ai_5b_months} @ ${data.data.parentAgentContract.ai_5b_percent}% ${data.data.parentAgentContract.vat}`,
            hide: [null, undefined].includes(
              data.data.parentAgentContract.ai_5b_months as null | undefined,
            ),
          },
          {
            label: 'Period 3',
            value: `${data.data.parentAgentContract.ai_5c_months} @ ${data.data.parentAgentContract.ai_5c_percent}% ${data.data.parentAgentContract.vat}`,
            hide: [null, undefined].includes(
              data.data.parentAgentContract.ai_5c_months as null | undefined,
            ),
          },
        ]"
      />
      <DataListWidget
        title="Fee"
        is-compact
        :details="[
          {
            label: 'Calculated Fee',
            value: `£${data.calculations.parentAgentFee.fee.toString()}`,
          },
          {
            label: 'Actual Fee Paid',
            value: `£${data.data.order.feesPayOutParentAgent?.format()} on ${
              data.data.order.feesPayOutParentAgentDate
            }`,
            hide:
              null === data.data.order.feesPayOutParentAgent ||
              null === data.data.order.feesPayOutParentAgentDate,
          },
        ]"
      />
    </template>
  </Grid>
</template>

<style lang="scss">
.introducer-fee-details {
  .card {
    box-shadow: none;
  }
}
</style>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Grid } from "@kinherit/framework/component.layout/grid";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Currency, DateTime } from "@kinherit/ts-common";
import { PropType, defineComponent } from "vue";
import { ReadKintinIntroducerFeeResponse } from "../action/read-introducer-fee-kintin.action";
export default defineComponent({
  name: `IntroducerFeeDetails`,
  props: {
    data: {
      type: Object as PropType<ReadKintinIntroducerFeeResponse["data"][number]>,
      required: true,
    },
  },
  components: { DataListWidget, Grid },
  computed: {
    clientAge(): string {
      return new DateTime(this.data.data.client.createdAt).since();
    },
    totalPriceExVat() {
      return new Currency({
        amount:
          this.data.data.order.totalPrice - this.data.data.order.totalPriceVat,
      }).format;
    },
    showJson(): boolean {
      return window.Kernel.Mode === KernelModes.Dev;
    },
  },
  methods: {
    formatDate(
      date?: string | number | null | undefined,
      alt?: string,
    ): string | null {
      if (null === date || undefined === date) {
        return alt ?? null;
      }

      return new DateTime(date).formatYMD;
    },
  },
});
</script>
