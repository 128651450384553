import { KnowledgeBaseCategoryField } from "@/config/form.config";
import { KnowledgeBaseDocument } from "@kinherit/sdk";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const UpdateKnowledgeBaseDocumentForm = (
  document: KnowledgeBaseDocument,
) =>
  defineForm({
    name: "update-knowledge-base-document-form",
    data: () => document,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([["title"], ["categories"], ["content"]]),
        components: () => ({
          title: [
            FormTextField({
              props: {
                label: "title",
              },
              models: {
                value: "title",
              },
            }),
          ],
          content: [
            FormRichTextField({
              props: {
                label: "content",
              },
              models: {
                value: "content",
              },
            }),
          ],
          categories: [
            KnowledgeBaseCategoryField({
              props: {
                label: "categories",
                vModel: "_data.categories",
                isMultiSelect: true,
              },
              query: {},
              simplifyData: true,
            }),
          ],
        }),
      }),
    ],
  });
