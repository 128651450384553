<template>
  <strong>Schedule <span v-html="helpers.var(position)" /></strong>
  <p v-if="data">
    <strong>Interest In Possession Trust</strong><br />
    This trust shall be known as THE
    <span
      v-if="data.person"
      v-html="helpers.var(data.person.profile.fullName).toUpperCase()"
    />
    INTEREST IN POSSESSION TRUST <br /><br />
    The trustees shall be as defined below
    <Trustees />
    <br /><br />
    Any money or property transferred to the trustees is hereinafter referred to
    as the trust property
    <br /><br />
    The beneficiaries shall be as defined below
    <Beneficiaries title="Section A - beneficiaries" />
    <span v-if="data.kintinType === 'joint'">
      <br /><br />
      <table class="table-box">
        <thead>
          <tr>
            <th>
              <strong>Section B - beneficiaries</strong>
              <em>(this may span multiple pages)</em>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-html="helpers.var(helpers.formatOfficer(data.partner, false))"
            />
          </tr>
        </tbody>
      </table>
    </span>
  </p>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";
import Beneficiaries from "../shared/Beneficiaries.vue";
import Trustees from "../shared/Trustees.vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    position() {
      return this.helpers.getSchedulePosition(WillBuilderSections.ScheduleIIP);
    },
  },
  components: { Beneficiaries, Trustees },
});
</script>

<style lang="scss" scoped>
.table-box {
  border: thick double black;
  width: 15cm;
  margin: auto;
  tbody,
  thead {
    tr {
      td,
      th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }
    }
  }
}
</style>
