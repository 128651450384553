import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { IntroducerOutsourceDetailsNotesBreadCrumb } from "../../../breadcrumbs";

/** Notes */
export const IntroducerOutsourceDetailsNotesRoute =
  "IntroducerOutsourceDetailsNotes";
const IntroducerOutsourceDetailsNotesURI =
  "/introducer/:introducerOutsource/notes";
export type IntroducerOutsourceDetailsNotesParams = RouteParamObject<
  typeof IntroducerOutsourceDetailsNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerOutsourceDetailsNotesRoute,
    path: IntroducerOutsourceDetailsNotesURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerOutsourceDetails.Notes" */ "./IntroducerOutsourceDetails.Notes.vue"
        ),
    },
    meta: {
      breadcrumbs: IntroducerOutsourceDetailsNotesBreadCrumb,
    },
  },
];
