import { Api } from "@/service/api.service";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface SendBrandedKinvaultInvitationEmailFollowup1Message {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface SendBrandedKinvaultInvitationEmailFollowup1Response {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export const SendBrandedKinvaultInvitationEmailFollowup1Handler = async (
  message: SendBrandedKinvaultInvitationEmailFollowup1Message,
): Promise<SendBrandedKinvaultInvitationEmailFollowup1Response> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}",
    {
      brandedKinvaultInvitation: message.brandedKinvaultInvitation,
      invitationType: "followup1",
    },
  )
    .method("put")
    .result();

  const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
    response.brandedKinvaultInvitation,
  ).first();

  if (!brandedKinvaultInvitation) {
    throw new Error(
      "Failed to send branded kinvault invitation followup email",
    );
  }

  return {
    brandedKinvaultInvitation,
  };
};
