import { Api } from "@/service/api.service";
import { Between, Equal, Like } from "@kinherit/orm";
import {
  EmailAddress,
  IIntroducerBillingContact,
  IntroducerBillingContact,
  IntroducerCompany,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerBillingContactMessage {
  name?: string;
  created?: null | [number, number];
  isGlobal: null | boolean;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerBillingContact;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerContactBillingResponse {
  introducerBillingContacts: Array<IntroducerBillingContact>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
  emailAddress: Array<EmailAddress>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerBillingContactHandler = async (
  message: ReadIntroducerBillingContactMessage,
): Promise<ReadIntroducerContactBillingResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-billing-contact",
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerBillingContact).where([
    {
      profile: {
        fullName: Like(message.name),
      },
      createdAt: Between(message.created),
      isGlobal: Equal(message.isGlobal),
    },
  ]);

  const response = await request.result();

  return {
    introducerBillingContacts: IntroducerBillingContact.$inflate(
      response.introducerBillingContact,
      message.sort,
      response.$rootIds,
    ),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    profiles: Profile.$inflate(response.profile),
    emailAddress: EmailAddress.$inflate(response.emailAddress),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
