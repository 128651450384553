<template>
  <p v-if="data">
    <strong>First Priority</strong><br />
    The first priority of the Trustees is to ensure
    <span
      v-html="
        helpers.var(
          data.beneficiariesFirstLine
            .map(helpers.formatBeneficiary)
            .join(' and '),
        )
      "
    />
    &nbsp;is adequately provided for. Only if their present and future needs
    have been fully provided for would I want you to consider the needs of other
    Beneficiaries. Regardless, the priority Beneficiary may ask the Trustees to
    consider utilising funds for other Beneficiaries, even when their needs have
    not been fully met.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
