import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../breadcrumbs";
import { Routes as IntroducerCompanyDetailsAccountingRoutes } from "./accounting";
import { Routes as IntroducerCompanyDetailsContactsRoutes } from "./contacts";
import { Routes as IntroducerCompanyDetailsContractsRoutes } from "./contracts";
import { Routes as IntroducerCompanyDetailsEmailLogRoutes } from "./email-log";
import { Routes as IntroducerCompanyDetailsNotesRoutes } from "./notes";
import { Routes as IntroducerCompanyDetailsPartnerPortalRoutes } from "./partner-portal";
import { Routes as IntroducerCompanyDetailsReferralRoutes } from "./referrals";
import {
  IntroducerCompanyDetailsSummaryRoute,
  Routes as IntroducerCompanyDetailsSummaryRoutes,
} from "./summary";
import { Routes as IntroducerCompanyDetailsUpdateProfileRoutes } from "./update-profile";

export const IntroducerCompanyDetailsRoute = "IntroducerCompanyDetails";
export const IntroducerCompanyDetailsURI =
  "/introducer/company/:introducerCompany";
export type IntroducerCompanyDetailsParams = RouteParamObject<
  typeof IntroducerCompanyDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsRoute,
    path: IntroducerCompanyDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "IntroducerCompanyDetails" */ "./IntroducerCompanyDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
      ],
    },
    children: [
      ...IntroducerCompanyDetailsSummaryRoutes,
      ...IntroducerCompanyDetailsUpdateProfileRoutes,
      ...IntroducerCompanyDetailsNotesRoutes,
      ...IntroducerCompanyDetailsEmailLogRoutes,
      ...IntroducerCompanyDetailsContactsRoutes,
      ...IntroducerCompanyDetailsContractsRoutes,
      ...IntroducerCompanyDetailsReferralRoutes,
      ...IntroducerCompanyDetailsAccountingRoutes,
      ...IntroducerCompanyDetailsPartnerPortalRoutes,
    ],
    redirect: { name: IntroducerCompanyDetailsSummaryRoute },
  },
];
