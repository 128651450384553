import { Api } from "@/service/api.service";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

interface DeleteIntroducerContactPartnerPortalMessage {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

interface DeleteIntroducerContactPartnerPortalResponse {
  [key: string]: never;
}

export const DeleteIntroducerContactPartnerPortalHandler = async (
  message: DeleteIntroducerContactPartnerPortalMessage,
): Promise<DeleteIntroducerContactPartnerPortalResponse> => {
  console.log(message);
  await Api.resource(
    "portal",
    "/v2/portal/introducer-contact-introducer-partner-portal/{introducerContact}/partner-portal-permissions/{contactPartnerPortalPermissions}",
    {
      introducerContact: message.contactPartnerPortalPermissions.contact,
      contactPartnerPortalPermissions:
        message.contactPartnerPortalPermissions.$id,
    },
  )
    .method("delete")
    .result();

  message.contactPartnerPortalPermissions.$delete();

  return {};
};
