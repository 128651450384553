import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeDocstorageReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
}

interface ComputeDocstorageReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<TDocstorageReportResult>;
}
export type TDocstorageReportResult = {
  year: number;
  week: number;
  month: number;
  quarter: number;
  accountCount: number;
  clientCount: number;
  fileCount: number;
  culumativeAccountCount: number;
  culumativeClientCount: number;
  culumativeFileCount: number;
};

export const ComputeDocstorageReportHandler = async (
  message: ComputeDocstorageReportMessage,
): Promise<ComputeDocstorageReportResponse> => {
  const { year, created, granularity } = message || {};

  const calculatedDates = calculateStartEndDates(year, created);

  const response = await Api.resource(
    "portal",
    "/v2/portal/report/docstorage",
    {},
  )
    .method("get")
    .params({
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
    })

    .result();

  const datasets: {
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }[] = [];

  const colours = ["#773ab5", "#431573"];
  // For each dataset returned, create a new dataset object with the correct format
  // for the chart.js graph
  response.datasets.forEach((dataset: any, index: number) => {
    datasets.push({
      label: "Docstorage",
      stack: "Stack " + index,
      backgroundColor: colours[index],
      data: dataset.datasets.map((i: any) => {
        return i.culmativeCount;
      }),
    });
  });

  return {
    datasets: datasets,
    labels: response.labels,
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};
