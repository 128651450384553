<template>
  <p>
    <strong>Shares to Beneficiaries</strong><br />
    I would wish my Trustees to utilise funds within the trust for the benefit
    of
    <span
      v-html="
        helpers.var(
          helpers.formatBeneficiary(firstOrSecondLineBeneficiary.first()),
        )
      "
    />
    <span
      v-html="
        helpers.var(
          helpers.beneficiaryTheirConcern(firstOrSecondLineBeneficiary.first()),
        )
      "
    />.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    /*
    If there are both first and secondline beneficiaries, then this should only output second line beneficiaries.
    Otherwise should output first line beneficiaries.
    */
    firstOrSecondLineBeneficiary() {
      if (!this.data) {
        return [];
      }

      return this.data.beneficiariesFirstLine.length > 0 &&
        this.data.beneficiariesSecondLine.length > 0
        ? this.data.beneficiariesSecondLine
        : this.data.beneficiariesFirstLine;
    },
  },
});
</script>
