import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { OrderDetailsBreadCrumb } from "../../breadcrumbs";
import { Routes as OrderCreditNotesRoutes } from "./credit-notes";
import { OrderDetailsSummaryRoute, Routes as SummaryRoutes } from "./summary";

export const OrderDetailsRoute = "OrderDetails";
export const OrderDetailsURI = "/order/:order";
export type OrderDetailsParams = RouteParamObject<typeof OrderDetailsURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: OrderDetailsRoute,
    path: OrderDetailsURI,
    component: () =>
      import(/* webpackChunkName: "OrderDetails" */ "./OrderDetails.vue"),
    meta: {
      breadcrumbs: (params) =>
        OrderDetailsBreadCrumb(params as OrderDetailsParams),
    },
    children: [...SummaryRoutes, ...OrderCreditNotesRoutes],
    redirect: { name: OrderDetailsSummaryRoute },
  },
];
