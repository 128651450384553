import { Api } from "@/service/api.service";
import { Beneficiary, Kintin } from "@kinherit/sdk";

interface CreateBeneficiaryMessage {
  kintin: string | Kintin;
  beneficiary: Beneficiary;
}

interface CreateBeneficiaryResponse {
  beneficiary: Beneficiary;
}

export const CreateBeneficiaryHandler = async (
  message: CreateBeneficiaryMessage,
): Promise<CreateBeneficiaryResponse> => {
  const { beneficiary: beneficiaryData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-beneficiary/{kintin}/person/beneficiary",
    {
      kintin: message.kintin,
    },
  )
    .method("post")

    .body({
      classGiftType: message.beneficiary.$data.classGiftType,
      forPerson: message.beneficiary.$data.forPerson,
      ifPredeceased: message.beneficiary.$data.ifPredeceased,
      notes: message.beneficiary.$data.notes,
      person: message.beneficiary.$data.person,
      position: message.beneficiary.$data.position,
      reservePerson: message.beneficiary.$data.reservePerson,
      reserveclassGiftType: message.beneficiary.$data.reserveclassGiftType,
      shareFraction: message.beneficiary.$data.shareFraction,
      sharePercentage: message.beneficiary.$data.sharePercentage,
      kintin: message.kintin.toString(),
    })
    .result();

  const beneficiary = Beneficiary.$inflate(beneficiaryData).first();

  if (!beneficiary) {
    throw new Error("Failed to create beneficiary");
  }

  message.beneficiary.$delete();

  return { beneficiary };
};
