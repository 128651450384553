<template>
  <div class="address-list">
    <AddressSummary
      v-for="address in addresses"
      :key="`address-${address.id}`"
      :kintin="kintin"
      :address="address"
      @edit="edit"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AddressListWrapper",
  "imports": {
    "AddressSummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/AddressSummary.test"
  },
  "methods": {
    "addresses": {
      "type": "to-many",
      "selector": ".address-summary",
      "wrapper": "AddressSummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import AddressSummary from "@/module/kinvault.kintin/component/summary-cards/AddressSummary.vue";
import { UpdateAddressForm } from "@/shared/form/address/update-address.form";
import { OpenAutocompleteDialog } from "@kinherit/framework/global/dialog";
import { Equal } from "@kinherit/orm";
import { Address, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddressList",
  components: { AddressSummary },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    addresses: {
      type: Array as () => Address[],
      required: true,
    },
  },
  methods: {
    async edit(address: Address): Promise<void> {
      if (!this.kintin) {
        return;
      }

      const summary = address.summary;

      // const { addresses: matches } = await window.Kernel.ActionBus.execute(
      //   "core/select/address/kintin/read",
      //   {
      //     kintin: this.kintin,
      //     query: {
      //       summary: Equal(summary),
      //     },
      //   },
      // );
      const { addresses: matches } =
        await window.Kernel.ActionBus.core.select.address.kintin.read({
          kintin: this.kintin,
          query: {
            summary: Equal(summary),
          },
        });

      try {
        await UpdateAddressForm({
          data: address,
          kintin: this.kintin,
          isAddressBook: true,
        }).dialog({
          dialog: {
            title: "Update address",
          },
          button: {
            ok: {
              text: "Select People",
            },
          },
        });
      } catch {
        address.$restore();
        return;
      }

      const addresses = (await OpenAutocompleteDialog({
        dialog: {
          title: "Select People",
          message: "Select the people you would like to make this change to",
        },
        list: {
          options: () => matches,
          mapOptions: {
            value: "id",
            label: "profile.fullName",
          },
          isMultiSelect: true,
        },
      })) as Address[];

      const line1 = address.line1;
      const line2 = address.line2;
      const city = address.city;
      const state = address.state;
      const postcode = address.postcode;
      const country = address.country;

      await addresses.forEachAsync(async (selectedAddress) => {
        selectedAddress.line1 = line1;
        selectedAddress.line2 = line2;
        selectedAddress.city = city;
        selectedAddress.state = state;
        selectedAddress.postcode = postcode;
        selectedAddress.country = country;

        // await window.Kernel.ActionBus.execute(
        //   "kinvault/kintin/address/update",
        //   {
        //     address: selectedAddress,
        //     kintin: this.kintin,
        //   },
        // );
        await window.Kernel.ActionBus.kinvaultKintin.address.update({
          address: selectedAddress,
          kintin: this.kintin,
        });
      });

      this.$emit("reload");
    },
  },
});
</script>
