import { Api } from "@/service/api.service";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

interface UpdateIntroducerContactPartnerPortalPermissionsMessage {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

interface UpdateIntroducerContactPartnerPortalPermissionsResponse {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

export const UpdateIntroducerContactPartnerPortalPermissionsHandler = async (
  message: UpdateIntroducerContactPartnerPortalPermissionsMessage,
): Promise<UpdateIntroducerContactPartnerPortalPermissionsResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-contact-introducer-partner-portal/{introducerContact}/partner-portal-permissions/{contactPartnerPortalPermissions}",
    {
      introducerContact: message.contactPartnerPortalPermissions.contact,
      contactPartnerPortalPermissions:
        message.contactPartnerPortalPermissions.$id,
    },
  )
    .method("patch")
    .body({
      clientsRead: message.contactPartnerPortalPermissions.clientsRead,
      clientsCreate: message.contactPartnerPortalPermissions.clientsCreate,
      feesRead: message.contactPartnerPortalPermissions.feesRead,
      invitationsCreate:
        message.contactPartnerPortalPermissions.invitationsCreate,
    })
    .result();

  const partnerPortalPermissions = ContactPartnerPortalPermissions.$inflate(
    response.contactPartnerPortalPermissions,
  ).first();

  if (!partnerPortalPermissions) {
    throw new Error("Failed to update partner portal permission");
  }

  return {
    contactPartnerPortalPermissions: partnerPortalPermissions,
  };
};
