<template>
  <HistoryPage type="kintin" :id="$params.kintin" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsChangeLogWrapper",
  "route": "KinvaultKintinDetailsChangeLog",
  "extends": {
    "name": "HistoryPageWrapper",
    "path": "@/shared/component/history/HistoryPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import HistoryPage from "@/shared/component/history/HistoryPage.vue";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsChangeLogRoute } from ".";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: KinvaultKintinDetailsChangeLogRoute,
  components: { HistoryPage },
  mixins: [KinvaultKintinDetailsMixin],
});
</script>
