<template>
  <span v-if="data">
    <strong> Provision for Pets </strong>
    <ol type="i">
      <li v-for="gift in data.petGifts" :key="gift.id">
        I give any pets in my possession at the date of my death to
        <span v-html="helpers.var(helpers.formatRecipient(gift))" />
        with the wish that they will take care of such pets for the remainder of
        their natural lives
        <span v-if="gift.onSecondDeath">
          only if my
          <span
            v-if="data.person"
            v-html="
              helpers.var(
                helpers.formatRelationship(data.person, gift.forPerson),
              )
            "
          />
          has predeceased me
        </span>
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
