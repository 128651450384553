<template>
  <span v-if="data">
    <strong>Gifts of Specific Items</strong>
    <p>
      <span v-if="data.specificGifts.length === 1">
        I Give absolutely and free of inheritance tax to
        <span
          v-html="
            helpers.var(helpers.formatSpecificGift(data.specificGifts[0]))
          "
        />
      </span>
    </p>
    <span v-if="data.specificGifts.length > 1">
      <p>
        I Give the following specific legacies absolutely and free of
        inheritance tax
      </p>
      <ol type="i">
        <li v-for="gift in data.specificGifts" :key="gift.id">
          <span v-html="helpers.var(helpers.formatSpecificGift(gift))" />
        </li>
      </ol>
    </span>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
