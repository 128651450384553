import { Api } from "@/service/api.service";
import { Subscription } from "@kinherit/sdk";

interface DeleteSubscriptionMessage {
  subscription: Subscription;
}

interface DeleteSubscriptionResponse {
  [key: string]: never;
}

export const DeleteSubscriptionHandler = async (
  message: DeleteSubscriptionMessage,
): Promise<DeleteSubscriptionResponse> => {
  await Api.resource("portal", "/v2/portal/subscription/{subscription}", {
    subscription: message.subscription.id,
  })
    .method("delete")
    .result();

  message.subscription.$delete();

  return {};
};
