import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsPartnerPortalRoute =
  "IntroducerCompanyDetailsPartnerPortal";
export const IntroducerCompanyDetailsPartnerPortalURI =
  "/introducer/company/:introducerCompany/portal";
export type IntroducerCompanyDetailsPartnerPortalParams = RouteParamObject<
  typeof IntroducerCompanyDetailsPartnerPortalURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsPartnerPortalRoute,
    path: IntroducerCompanyDetailsPartnerPortalURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsPartnerPortal" */ "./IntroducerCompanyDetails.PartnerPortal.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Partner Portal",
          route: {
            name: IntroducerCompanyDetailsPartnerPortalRoute,
            params,
          },
        },
      ],
    },
  },
];
