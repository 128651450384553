import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const CallLogMasterListRoute = "CallLogMasterList";

export const CallLogDetailsRoute = "CallLogDetails";
export const CallLogDetailsURI = "/admin/call-log/:id";
export type CallLogDetailsParams = RouteParamObject<typeof CallLogDetailsURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: CallLogMasterListRoute,
    path: "/admin/call-logs",
    component: () =>
      import(
        /* webpackChunkName: "CallLogMasterList" */ "./CallLogMasterList.vue"
      ),
  },
  {
    name: CallLogDetailsRoute,
    path: CallLogDetailsURI,
    component: () =>
      import(/* webpackChunkName: "CallLogDetails" */ "./CallLogDetails.vue"),
  },
];
