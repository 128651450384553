<template>
  <Card :title="name" :title-size="'is-4'" class="person-summary">
    <small v-if="accountType">({{ accountType }} Account Holder)</small>
    <div class="columns">
      <div class="column">
        <DataListWidget
          v-if="!hideSummary"
          no-card
          is-compact
          :details="[
            {
              label: 'Marital Status',
              value: person.maritalStatus?.text,
              hide:
                null == person.maritalStatus ||
                '' === person.maritalStatus?.text,
            },
            {
              label: `Relationship to ${primaryRelationshipTitle}`,
              value: primaryRelationship,
              hide:
                accountType === 'Primary' ||
                person.type === 'company' ||
                person.type === 'charity' ||
                null === primaryRelationship,
            },
            {
              label: `Relationship to ${secondaryRelationshipTitle}`,
              value: secondaryRelationship,
              hide:
                accountType === 'Secondary' ||
                person.type === 'company' ||
                person.type === 'charity' ||
                null == secondaryRelationship,
            },
            {
              label: 'Date of Birth',
              value: `${person.dateOfBirth?.formatDate} (${person.dateOfBirth?.getAge()} years old)`,
              hide: null === person.dateOfBirth,
            },
            {
              label: 'Date of Death',
              value: person.dateOfDeath?.formatDate,
              hide: null === person.dateOfDeath,
            },
            {
              label: 'Trusted Person',
              hide: null === person.isTrusted || !person.isTrusted,
            },
            {
              label: 'Is Under 18 for Planning',
              value: 'Yes',
              hide:
                null === person.under18ForPlanningPurposes ||
                !person.under18ForPlanningPurposes,
            },
            {
              label: 'Requires specialist care: ',
              value: 'Yes',
              hide: null === person.requiresCare || !person.requiresCare,
            },
            {
              label: 'Organisation',
              value: person.profile.organisationName,
              hide: null === person.profile.organisationName,
            },
          ]"
        />
      </div>
      <div class="column">
        <ContactDetailsWidget
          v-if="!hideSummary"
          no-card
          :profile-id="person.profile.id"
        />
        <DataListWidget
          v-if="!hideSummary"
          no-card
          class="mt-2"
          :details="[
            {
              label: 'Roles',
              value: roles.join(', '),
              hide: roles.length === 0,
            },
          ]"
        />
      </div>
    </div>
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
      />
      <Button
        v-if="person.kintinPersonType === null"
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="$emit('delete', $event)"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "PersonSummaryWrapper"
}
</cypress-wrapper>

<script lang="ts">
import ContactDetailsWidget from "@/shared/component/profile/ContactDetailsWidget.vue";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import {
  Adviser,
  Attorney,
  Beneficiary,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Person,
  Trustee,
} from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PersonSummary",
  props: {
    person: {
      type: Person as PropType<Person>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
    hideSummary: {
      type: Boolean as PropType<boolean | null>,
      default: false,
    },
  },
  components: { Card, ContactDetailsWidget, DataListWidget, Button },
  emits: ["edit", "delete"],
  computed: {
    accountType(): "Primary" | "Secondary" | null {
      if (this.kintin.primaryPerson?.id === this.person.id) {
        return "Primary";
      } else if (this.kintin.secondaryPerson?.id === this.person.id) {
        return "Secondary";
      } else {
        return null;
      }
    },
    primaryRelationshipTitle(): string {
      return this.kintin.primaryPerson?.profile.fullName ?? "Primary";
    },
    primaryRelationship() {
      if (this.kintin.primaryPerson?.id === this.person.id) {
        return null;
      }

      return this.person.relationToPrimaryPerson
        ? `${this.person.relationToPrimaryPerson.text.ucFirst()}`
        : {
            color: "has-text-warning" as const,
            text: "Not set",
          };
    },
    secondaryRelationshipTitle(): string {
      return this.kintin.secondaryPerson?.profile.fullName ?? "Secondary";
    },
    secondaryRelationship() {
      if (
        this.kintin.secondaryPerson?.id === this.person.id ||
        null === this.kintin.secondaryPerson
      ) {
        return null;
      }

      return this.person.relationToSecondaryPerson
        ? `${this.person.relationToSecondaryPerson.text.ucFirst()}`
        : {
            color: "has-text-warning" as const,
            text: "Not set",
          };
    },
    name(): string {
      const person = this.person;
      const profile = person?.profile;

      if (!person || !profile) {
        return "";
      }

      let name = profile.fullName;

      if (this.hideSummary) {
        name = profile.lastName?.toUpperCase() + ", " + profile.firstName;
      }

      const title = profile.title?.text;
      if (title) {
        name += ` (${title})`;
      }

      if (person.profile.knownAs) {
        if (null === name) {
          name = person.profile.knownAs;
        } else {
          name += ` (${person.profile.knownAs})`;
        }
      }

      return name as string;
    },
    roles(): string[] {
      const isAdviser = Adviser.$findBy({
        person: {
          id: this.person.id,
        },
      }).isNotEmpty();

      const isAttorney = Attorney.$findBy({
        person: {
          id: this.person.id,
        },
        isCertProv: false,
      }).isNotEmpty();

      const isCertProv = Attorney.$findBy({
        person: {
          id: this.person.id,
        },
        isCertProv: true,
      }).isNotEmpty();

      const isBeneficiary = Beneficiary.$findBy({
        person: {
          id: this.person.id,
        },
      }).isNotEmpty();

      const isExecutor = Executor.$findBy({
        person: {
          id: this.person.id,
        },
      }).isNotEmpty();

      const isGiftRecipient = Gift.$findBy({
        forPerson: {
          id: this.person.id,
        },
      }).isNotEmpty();

      const isGuardian = Guardian.$findBy({
        person: {
          id: this.person.id,
        },
      }).isNotEmpty();

      const isTrustee = Trustee.$findBy({
        person: {
          id: this.person.id,
        },
      }).isNotEmpty();

      return Object.entries({
        Adviser: isAdviser,
        Attorney: isAttorney,
        "Certificate Provider": isCertProv,
        Beneficiary: isBeneficiary,
        Executor: isExecutor,
        "Gift Recipient": isGiftRecipient,
        Guardian: isGuardian,
        Trustee: isTrustee,
      })
        .filter(([, value]) => value)
        .map(([key]) => key);
    },
  },
});
</script>
