import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../breadcrumbs";
import { Routes as IntroducerContactDetailsClientProgressRoutes } from "./client-progress";
import { Routes as IntroducerContactDetailsReferralFormRoutes } from "./referral-form";
import { Routes as IntroducerContactDetailsCpdSessionRoutes } from "./cpd-session";
import { Routes as IntroducerContactDetailsEmailLogRoutes } from "./email-log";
import { Routes as IntroducerContactDetailsKinvaultAccessRoutes } from "./kinvault-access";
import { Routes as IntroducerContactDetailsNotesRoutes } from "./notes";
import {
  IntroducerContactDetailsSummaryRoute,
  Routes as SummaryRoutes,
} from "./summary";
import { Routes as IntroducerContactDetailsUpdateProfileRoutes } from "./update-profile";

export const IntroducerContactDetailsRoute = "IntroducerContactDetails";
export const IntroducerContactDetailsURI =
  "/introducer/contact/:introducerContact";
export type IntroducerContactDetailsParams = RouteParamObject<
  typeof IntroducerContactDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsRoute,
    path: IntroducerContactDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "IntroducerContactDetails" */ "./IntroducerContactDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
      ],
    },
    children: [
      ...SummaryRoutes,
      ...IntroducerContactDetailsUpdateProfileRoutes,
      ...IntroducerContactDetailsEmailLogRoutes,
      ...IntroducerContactDetailsClientProgressRoutes,
      ...IntroducerContactDetailsReferralFormRoutes,
      ...IntroducerContactDetailsNotesRoutes,
      ...IntroducerContactDetailsCpdSessionRoutes,
      ...IntroducerContactDetailsKinvaultAccessRoutes,
    ],
    redirect: { name: IntroducerContactDetailsSummaryRoute },
  },
];
