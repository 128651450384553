import { Icon } from "@kinherit/framework/component.display/icon";
import { Button } from "@kinherit/framework/component.input/button";
import {
  ThemeIconName,
  ThemeImportedIcons,
} from "@kinherit/framework/theme/prop/icon";
import { defineComponent } from "vue";

const button: Record<
  "success" | "danger" | "warning",
  InstanceType<typeof Button>["$props"]
> = {
  success: {
    color: "is-success",
  },
  danger: {
    color: "is-danger",
  },
  warning: {
    color: "is-warning",
  },
};

const icon: Record<
  | "options"
  | "pinned"
  | "contract"
  | "notes"
  | "referral"
  | "lock"
  | "addressBook"
  | "edit"
  | "delete"
  | "add"
  | "phone"
  | "chevronRight"
  | "search"
  | "person"
  | "personTie"
  | "signFile"
  | "email"
  | "organisation"
  | "kintin"
  | "profile"
  | "calendar"
  | "trustReg"
  | "summary"
  | "contact"
  | "accounting"
  | "progress"
  | "history"
  | "file"
  | "order"
  | "subscription"
  | "uploadForm"
  | "speechBubble"
  | "cpd"
  | "company"
  | "theme"
  | "postPayment"
  | "creditNote"
  | "download"
  | "upload"
  | "settings"
  | "copy"
  | "sync",
  InstanceType<typeof Icon>["$props"]
> = {
  options: {
    icon: "Horizontalellipsis",
    class: "is-clickable",
  },
  pinned: {
    icon: {
      icon: ThemeImportedIcons["fa-map-pin"],
      style: "Duotone",
      primaryColor: "var(--color-highlight)",
      secondaryColor: "var(--color-dark-grey)",
    },
  },
  contract: {
    icon: {
      icon: ThemeImportedIcons["fa-file-contract"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  copy: {
    icon: {
      icon: ThemeImportedIcons["fa-copy"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  file: {
    icon: {
      icon: ThemeImportedIcons["fa-file"],
      style: "Regular",
    },
  },
  order: {
    icon: {
      icon: ThemeImportedIcons["fa-file-alt"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  notes: {
    icon: {
      icon: ThemeImportedIcons["fa-file-lines"],
      style: "Regular",
    },
  },
  lock: {
    icon: {
      icon: ThemeImportedIcons["fa-lock"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  addressBook: {
    icon: ThemeIconName.AddressBook,
  },
  edit: {
    icon: ThemeIconName.Edit,
  },
  delete: {
    icon: ThemeIconName.Trash,
  },
  add: {
    icon: ThemeIconName.Plus,
  },
  phone: {
    icon: {
      icon: ThemeImportedIcons["fa-phone"],
      style: "Regular",
    },
  },
  chevronRight: {
    icon: ThemeIconName.ChevronRight,
  },
  search: {
    icon: ThemeIconName.Search,
  },
  person: {
    icon: ThemeIconName.Person,
  },
  personTie: {
    icon: ThemeIconName.PersonTie,
  },
  profile: {
    icon: ThemeIconName.PersonTie,
  },
  signFile: {
    icon: ThemeIconName.SignFile,
  },
  email: {
    icon: ThemeIconName.Email,
  },
  sync: {
    icon: {
      icon: ThemeImportedIcons["fa-sync-alt"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  organisation: {
    icon: {
      icon: ThemeImportedIcons["fa-sitemap"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  kintin: {
    icon: {
      icon: ThemeImportedIcons["fa-box-check"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  calendar: {
    icon: {
      icon: ThemeImportedIcons["fa-calendar"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  trustReg: {
    icon: {
      icon: ThemeImportedIcons["fa-shield-check"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  summary: {
    icon: {
      icon: ThemeImportedIcons["fa-address-card"],
      style: "Regular",
    },
  },
  contact: {
    icon: {
      icon: ThemeImportedIcons["fa-address-book"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  accounting: {
    icon: {
      icon: ThemeImportedIcons["fa-credit-card"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  postPayment: {
    icon: {
      icon: ThemeImportedIcons["fa-credit-card"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  progress: {
    icon: {
      icon: ThemeImportedIcons["fa-tasks-alt"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  referral: {
    icon: {
      icon: ThemeImportedIcons["fa-people-arrows"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  history: {
    icon: {
      icon: ThemeImportedIcons["fa-history"],
      style: "Regular",
    },
  },
  subscription: {
    icon: {
      icon: ThemeImportedIcons["fa-repeat"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  uploadForm: {
    icon: {
      icon: ThemeImportedIcons["fa-file-upload"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  speechBubble: {
    icon: {
      icon: ThemeImportedIcons["fa-comment"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  cpd: {
    icon: {
      icon: ThemeImportedIcons["fa-comment"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  settings: {
    icon: {
      icon: ThemeImportedIcons["fa-cog"],
      style: "Regular",
      color: "black",
      opacity: 1,
    },
  },
  company: {
    icon: {
      icon: ThemeImportedIcons["fa-building"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  theme: {
    icon: {
      icon: ThemeImportedIcons["fa-vial"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  creditNote: {
    icon: {
      icon: ThemeImportedIcons["fa-envelope-open-dollar"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  download: {
    icon: {
      icon: ThemeImportedIcons["fa-download"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
  upload: {
    icon: {
      icon: ThemeImportedIcons["fa-upload"],
      style: "Regular",
      color: "var(--color-icon)",
      opacity: 1,
    },
  },
};

export class StyleService {
  public static get mixin() {
    return defineComponent({
      name: "StyleMixin",
      computed: {
        $style(): Omit<typeof StyleService, "mixin"> {
          return StyleService;
        },
      },
    });
  }

  public static get button(): typeof button {
    return button;
  }

  public static get icon(): typeof icon {
    return icon;
  }
}
