<template>
  <div
    v-if="kintin"
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script-family-guardians': true,
    }"
  >
    <PageHeader htag="h2" text="Family & Guardians">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>
    <Tabs
      v-model:tab="tab"
      is-lazy
      position="both"
      :config="
        ['Account Type', 'Clients', 'Family', 'Guardians'].map((x) => ({
          label: x,
        }))
      "
    >
      <!-- Tab Account Type -->
      <template #accountType>
        <AccountTypeTab />
      </template>
      <!-- Tab Clients -->
      <template #clients>
        <ClientsTab />
      </template>
      <!-- Tab Family -->
      <template #family>
        <FamilySituationTab />
      </template>
      <!-- Tab Guardians -->
      <template #guardians>
        <GuardiansTab
          @save="saveHandler"
          @save-and-continue="saveAndContinueHandler"
        />
      </template>
    </Tabs>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptFamilyGuardiansWrapper",
  "route": "KintinDetailsCallScriptFamilyGuardians",
  "selector": ".call-script-family-guardians"
}
</cypress-wrapper>

<script lang="ts">
import { CreatePerson } from "@/config/model.config";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { KintinDetailsCallScriptFamilyGuardiansRoute } from "@/module/kinvault.kintin/page/details/call-script/family-guardians";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Tabs from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { NotIn } from "@kinherit/orm";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams } from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptPropertyAssetsRoute } from "../property-assets";
import AccountTypeTab from "./AccountTypeTab.vue";
import ClientsTab from "./ClientsTab.vue";
import FamilySituationTab from "./FamilySituationTab.vue";
import GuardiansTab from "./GuardiansTab.vue";

export default defineComponent({
  name: KintinDetailsCallScriptFamilyGuardiansRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    PageHeader,
    Button,
    AccountTypeTab,
    ClientsTab,
    FamilySituationTab,
    GuardiansTab,
    Tabs,
  },
  data: () => ({
    guardianSectionRenderKey: 0,
    isFocused: false,
    renderKey: 0,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
    people: [] as Person[],
    tab: 0,
  }),
  mounted() {
    this.loadPeople();
  },
  methods: {
    async addRelativeHandler() {
      if (!this.kintin) {
        return;
      }

      const person = CreatePerson();

      try {
        await UpdatePersonForm({
          person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create person",
          },
        });
      } catch {
        person.$delete({
          profile: true,
          relationToPrimaryPerson: true,
          relationToSecondaryPerson: true,
        });
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/create", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.create({
        person: person,
        kintin: this.kintin,
      });

      this.renderKey++;
    },
    async saveAndContinueHandler() {
      if (!this.kintin) {
        return;
      }

      await this.saveHandler();

      this.$snackBar.success.saved();
      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptPropertyAssetsRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    async saveHandler() {
      if (!this.kintin) {
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/call-script/update",
      //   {
      //     kintin: this.kintin,
      //   },
      // );
      await window.Kernel.ActionBus.kinvaultKintin.callScript.update({
        kintin: this.kintin,
      });

      this.$snackBar.success.saved();
    },
    loadPeople() {
      if (!this.kintin) {
        return;
      }

      this.people = Person.$findBy({
        id: NotIn(
          [
            this.kintin.$data.primaryPerson,
            this.kintin.$data.secondaryPerson,
          ].filter(Boolean),
        ),
        kintin: {
          id: this.kintin.id,
        },
      });
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
