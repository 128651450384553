import { Api } from "@/service/api.service";
import { EmailCampaign, Profile } from "@kinherit/sdk";

export interface AddEmailCampaignProfilesMessage {
  emailCampaign: EmailCampaign;
  profiles: Profile[];
}

export interface AddEmailCampaignProfilesResponse {
  [key: string]: never;
}

export const AddEmailCampaignProfilesHandler = async (
  message: AddEmailCampaignProfilesMessage,
): Promise<AddEmailCampaignProfilesResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/email-campaign/{emailCampaign}",
    message,
  )
    .method("patch")

    .body({
      profiles: message.profiles.pluck("id").map((id) => ({ id })),
    })
    .result();

  return {};
};
