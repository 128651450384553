import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderBase } from "@/module/kinvault.kintin/service/will-builder.base";
import {
  Adviser,
  Beneficiary,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  Person,
  Trustee,
} from "@kinherit/sdk/index";
import { reactive } from "vue";

type WillBuilderData = {
  firstname: null | string;
  middlenames: null | string;
  lastname: null | string;
  ktref: string;
  isGettingMarried: boolean;
  proExecutorOptOut: boolean;
  proExecutorOption: number | null;
  executors: Array<Executor>;
  primaryExecutors: Array<Executor>;
  reserveExecutors: Array<Executor>;
  singlePrimaryExecutorRelation: "primary" | "secondary";
  childrenWithGuardians: Array<Person>;
  guardians: Array<Guardian>;
  mainGuardians: Array<Guardian>;
  reserveGuardians: Array<Guardian>;
  guardiansAreSameForAllChildren: boolean;
  // whether the guardians should be appointed on death of partner if joint kintin
  onSecondDeath: boolean;
  partner: Person | null;
  hasCharitiesInAddressBook: boolean;
  type: "primary" | "secondary";
  gifts: Array<Gift>;
  specificGifts: Array<Gift>;
  businessGifts: Array<Gift>;
  propertyGifts: Array<Gift>;
  petGifts: Array<Gift>;
  cashGifts: Array<Gift>;
  isaGifts: Array<Gift>;
  bankAccountGifts: Array<Gift>;
  sharesGifts: Array<Gift>;
  beneficiaryAge: number | null;
  trustees: Array<Trustee>;
  reserveTrustees: Array<Trustee>;
  outputBeneficiaryAge: boolean;
  outputFinancialAssistGuardians: boolean;
  allowFundsForEducation: boolean;
  allowFundsForMedical: boolean;
  allowFundsForTravel: boolean;
  //[key: string]: never;
  hasAssetsOutsideUK: boolean;
  beneficiaries: Array<Beneficiary>;
  kintinType: "single" | "joint";
  exclusions: Array<Exclusion>;
  beneficiariesFirstLine: Array<Beneficiary>;
  beneficiariesSecondLine: Array<Beneficiary>;
  reserveBeneficiaries: Array<Beneficiary>;
  charityGifts: Array<Gift>;
  protrusteeOptOut: boolean;
  protrusteeOption: number | null;
  person: Person | null;
  advisers: Array<Adviser>;
  additionalTrustees: Array<Trustee>;
  giftsForMow: Array<Gift>;
  charityGiftsMOW: Array<Gift>;
  chattelGifts: Array<Gift>;
  chattelsGiftsMOW: Array<Gift>;
  specificGiftsMOW: Array<Gift>;
  propertyGiftsMOW: Array<Gift>;
  petGiftsMOW: Array<Gift>;
  cashGiftsMOW: Array<Gift>;
  bankaccountGiftsMOW: Array<Gift>;
  isaGiftsMOW: Array<Gift>;
  sharesGiftsMOW: Array<Gift>;
  outputCharityClauseMOW: boolean;
};

export class WillBuilderStore extends WillBuilderBase {
  static readonly data: WillBuilderData = reactive({
    firstname: null,
    middlenames: null,
    lastname: null,
    ktref: "",
    isGettingMarried: false,
    proExecutorOptOut: false,
    proExecutorOption: null,
    executors: [],
    primaryExecutors: [],
    reserveExecutors: [],
    singlePrimaryExecutorRelation: "primary",
    childrenWithGuardians: [],
    guardians: [],
    mainGuardians: [],
    reserveGuardians: [],
    guardiansAreSameForAllChildren: false,
    onSecondDeath: false,
    partner: null,
    hasCharitiesInAddressBook: false,
    type: "primary",
    gifts: [],
    specificGifts: [],
    propertyGifts: [],
    businessGifts: [],
    petGifts: [],
    cashGifts: [],
    isaGifts: [],
    bankAccountGifts: [],
    sharesGifts: [],
    beneficiaryAge: null,
    trustees: [],
    reserveTrustees: [],
    outputBeneficiaryAge: false,
    outputFinancialAssistGuardians: false,
    allowFundsForEducation: false,
    allowFundsForMedical: false,
    allowFundsForTravel: false,
    hasAssetsOutsideUK: false,
    beneficiaries: [],
    kintinType: "single",
    exclusions: [],
    beneficiariesFirstLine: [],
    beneficiariesSecondLine: [],
    reserveBeneficiaries: [],
    charityGifts: [],
    protrusteeOptOut: false,
    protrusteeOption: null,
    person: null,
    advisers: [],
    additionalTrustees: [],
    giftsForMow: [],
    charityGiftsMOW: [],
    businessGiftsMOW: [],
    chattelGifts: [],
    chattelsGiftsMOW: [],
    specificGiftsMOW: [],
    propertyGiftsMOW: [],
    petGiftsMOW: [],
    cashGiftsMOW: [],
    bankaccountGiftsMOW: [],
    isaGiftsMOW: [],
    sharesGiftsMOW: [],
    outputCharityClauseMOW: false,
  });

  static refreshData() {
    if (!this.options) {
      throw new Error("WillBuilderStore.options is not set");
    }

    const { kintin, person, partner, type } = this.options;

    if (!kintin) {
      throw new Error("Kintin not set");
    }

    if (!person) {
      throw new Error("Person not set");
    }

    const profile = person.profile;

    const executors = person.appointedExecutor.sortBy("sortOrder", "asc");
    let { primaryExecutors, reserveExecutors } =
      person.appointedExecutor.groupBy({
        primaryExecutors: (executor) => !executor.isReserve,
        reserveExecutors: (executor) => executor.isReserve,
      });

    primaryExecutors = primaryExecutors.sortBy("sortOrder", "asc");
    reserveExecutors = reserveExecutors.sortBy("sortOrder", "asc");

    let { trustees, reserveTrustees } = person.appointedTrustee.groupBy({
      trustees: (trustee) => !trustee.isReserve,
      reserveTrustees: (trustee) => trustee.isReserve,
    });

    trustees = trustees.sortBy("sortOrder", "asc");
    reserveTrustees = reserveTrustees.sortBy("sortOrder", "asc");

    const gifts = kintin.gifts
      .filter(
        (gift) =>
          (gift.sentBy?.id === person.id || gift.onSecondDeath) &&
          (gift.outputPreference === "will" || gift.outputPreference === null),
      )
      .sortBy("sortOrder", "asc");

    const charityGifts = gifts.filter(
      (gift) => gift.type.value === "charityclause",
    );
    const chattelGifts = gifts.filter((gift) => gift.type.value === "chattels");
    const specificGifts = gifts.filter((gift) => gift.type.value === "item");
    const propertyGifts = gifts.filter(
      (gift) => gift.type.value === "property",
    );
    const businessGifts = gifts.filter(
      (gift) => gift.type.value === "business",
    );
    const petGifts = gifts.filter((gift) => gift.type.value === "pet");
    const cashGifts = gifts.filter((gift) => gift.type.value === "fixedamount");
    const bankAccountGifts = gifts.filter(
      (gift) => gift.type.value === "bankaccount",
    );
    const sharesGifts = gifts.filter((gift) => gift.type.value === "shares");
    const isaGifts = gifts.filter((gift) => gift.type.value === "isa");

    const beneficiaries = person.appointedBeneficiary.sortBy(
      "sortOrder",
      "asc",
    );
    const beneficiariesFirstLine = beneficiaries.filter(
      (beneficiary) => beneficiary.position === 0,
    );
    const beneficiariesSecondLine = beneficiaries.filter(
      (beneficiary) => beneficiary.position === 1,
    );
    const beneficiariesReserve = beneficiaries.filter(
      (beneficiary) => beneficiary.position === 2,
    );

    // This should only look for charities attached to the person who's will it is.
    // We need to check beneficiaries & gift recipients.
    const hasCharitiesAsBeneficiaries = person.appointedBeneficiary.some(
      (beneficiary) => beneficiary.person?.type === "charity",
    );
    const hasCharitiesAsGiftRecipients = gifts.some(
      (gift) => gift.forPerson?.type === "charity",
    );
    const hasCharitiesInAddressBook =
      hasCharitiesAsBeneficiaries || hasCharitiesAsGiftRecipients;

    const advisers = person.appointedAdviser;

    const checks = kintin.checks;

    const proExecutorOptOut = this.checkIsNo("4.6.3b", checks);
    const proExecutorOption = this.checkValueByStep("4.6.3c", checks);
    const protrusteeOptOut = this.checkIsNo("4.4.2", checks);
    const protrusteeOption = this.checkValueByStep("4.4.2a", checks);
    const onSecondDeath = this.checkIsYes("4.0.0c", checks);
    const outputBeneficiaryAge = this.checkIsYes("4.3.1e", checks);
    const outputFinancialAssistGuardians = this.checkIsYes("4.0.0b", checks);
    const allowFundsForEducation = this.checkIsYes("4.3.1f", checks);
    const allowFundsForMedical = this.checkIsYes("4.3.1g", checks);
    const allowFundsForTravel = this.checkIsYes("4.3.1h", checks);

    const hasAssetsOutsideUK = this.checkIsYes("3.0.10", checks);

    const exclusions = person.exclusions;

    const kintinType = kintin.type.value as "single" | "joint";

    const parterRelationship =
      person.relationToPrimaryPerson ?? person.relationToSecondaryPerson;

    const isGettingMarried =
      kintinType === "joint"
        ? null !== parterRelationship &&
          ["fiancee", "fiance"].includes(parterRelationship?.value)
        : false;

    const giftsForMow = kintin.gifts
      .filter(
        (gift) =>
          (gift.sentBy?.id === person.id || gift.onSecondDeath) &&
          gift.outputPreference === "mow",
      )
      .sortBy("sortOrder", "asc");

    const specificGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "item",
    );
    const propertyGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "property",
    );
    const petGiftsMOW = giftsForMow.filter((gift) => gift.type.value === "pet");
    const cashGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "fixedamount",
    );
    const bankaccountGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "bankaccount",
    );
    const sharesGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "shares",
    );
    const isaGiftsMOW = giftsForMow.filter((gift) => gift.type.value === "isa");
    const charityGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "charityclause",
    );
    const chattelsGiftsMOW = giftsForMow.filter(
      (gift) => gift.type.value === "chattels",
    );

    const childrenWithGuardians = Person.$findBy({
      kintin: {
        id: kintin.id,
      },
    })
      .filter(
        (person) => person.under18ForPlanningPurposes || person.requiresCare,
      )
      .filter((person) => person.appointedGuardian.isNotEmpty());

    const guardians = childrenWithGuardians
      .pluck("appointedGuardian")
      .flat()
      .unique("id")
      .sortBy("sortOrder", "asc");
    const mainGuardians = guardians
      .flat()
      .filter((guardian) => !guardian.isReserve)
      .sortBy("sortOrder", "asc");
    const reserveGuardians = guardians
      .flat()
      .filter((guardian) => guardian.isReserve)
      .sortBy("sortOrder", "asc");

    const additionalTrustees = trustees.filter(
      (trustee) => null !== trustee.attainmentAge && trustee.attainmentAge > 17,
    );

    const outputCharityClauseMOW = hasCharitiesInAddressBook;
    const singlePrimaryExecutorRelation = type;

    const guardianCounts = childrenWithGuardians
      .pluck("appointedGuardian")
      .pluck("length");

    const guardiansAreSameForAllChildren =
      Math.max(...guardianCounts) === Math.min(...guardianCounts);
    const beneficiaryAge = kintin.callScript.beneficiaryAge;

    Object.assign(this.data, {
      firstname: profile.firstName,
      middlenames: profile.middleNames,
      lastname: profile.lastName,
      ktref: kintin.ref,
      isGettingMarried,
      proExecutorOptOut,
      proExecutorOption,
      executors,
      primaryExecutors,
      reserveExecutors,
      outputBeneficiaryAge,
      outputFinancialAssistGuardians,
      allowFundsForEducation,
      allowFundsForMedical,
      allowFundsForTravel,
      singlePrimaryExecutorRelation,
      childrenWithGuardians,
      guardians,
      hasCharitiesInAddressBook,
      chattelGifts,
      mainGuardians,
      reserveGuardians,
      guardiansAreSameForAllChildren,
      onSecondDeath,
      partner,
      type,
      gifts,
      specificGifts,
      propertyGifts,
      businessGifts,
      petGifts,
      cashGifts,
      isaGifts,
      bankAccountGifts,
      sharesGifts,
      beneficiaryAge,
      trustees,
      reserveTrustees,
      hasAssetsOutsideUK,
      beneficiaries,
      kintinType,
      exclusions,
      beneficiariesFirstLine,
      beneficiariesSecondLine,
      reserveBeneficiaries: beneficiariesReserve,
      charityGifts,
      protrusteeOptOut,
      protrusteeOption,
      person,
      advisers,
      additionalTrustees,
      giftsForMow,
      charityGiftsMOW,
      chattelsGiftsMOW,
      specificGiftsMOW,
      propertyGiftsMOW,
      petGiftsMOW,
      cashGiftsMOW,
      bankaccountGiftsMOW,
      isaGiftsMOW,
      sharesGiftsMOW,
      outputCharityClauseMOW,
    });
  }

  static refreshEnabledSections(): void {
    if (!this.data) {
      throw new Error(`Data not loaded`);
    }

    if (!this.options.kintin) {
      throw new Error(`Kintin not set`);
    }

    if (!this.form) {
      throw new Error(`Form not set`);
    }

    const isBusinessTrust = this.form.hasBusinessTrust === true;

    const isEstateTrust = this.form.hasEstateProtectionTrust === true;

    const isIIPTrust = this.form.hasIipTrust === true;

    const isBusinessClause = this.form.hasBusinessClause === true;

    const isRNRBTrust = isEstateTrust && isIIPTrust;
    const isRESTrust = isEstateTrust && !isIIPTrust;

    const isAnyTrust = isBusinessTrust || isEstateTrust || isIIPTrust;

    const checks = this.options.kintin.checks;

    this.sections.enabled = {
      [WillBuilderSections.FrontPage]: true,

      [WillBuilderSections.Revocation]: true,
      // Anticipation of Marriage only enabled if relationship to partner is
      // either fiance or fiancee
      [WillBuilderSections.AnticipationOfMarriage]: this.data.isGettingMarried,
      [WillBuilderSections.Declaration]: true,
      [WillBuilderSections.Executors]: this.data.executors.length > 0,
      [WillBuilderSections.Guardians]: this.data.guardians.length > 0,

      // Gifts could be output in either will or MOW
      [WillBuilderSections.CharityGifts]: this.data.charityGifts.length > 0,
      [WillBuilderSections.ChattelGifts]: this.data.chattelGifts.length > 0,
      [WillBuilderSections.SpecificGifts]: this.data.specificGifts.length > 0,
      [WillBuilderSections.PropertyGifts]: this.data.propertyGifts.length > 0,
      [WillBuilderSections.PetGifts]: this.data.petGifts.length > 0,
      [WillBuilderSections.CashGifts]: this.data.cashGifts.length > 0,
      [WillBuilderSections.BankaccountGifts]:
        this.data.bankAccountGifts.length > 0,
      [WillBuilderSections.SharesGifts]: this.data.sharesGifts.length > 0,
      [WillBuilderSections.IsaGifts]: this.data.isaGifts.length > 0,
      [WillBuilderSections.BusinessGifts]: this.data.businessGifts.length > 0,

      [WillBuilderSections.DefinitionOfEstate]: true,
      [WillBuilderSections.DistributionOfResidue]: !isEstateTrust,
      // Hidden if Trust:
      [WillBuilderSections.ConditionalGifts]:
        this.checkIsYes("4.3.1e", checks) && !isEstateTrust,
      [WillBuilderSections.ExecutorAndTrusteePowers]: true,
      [WillBuilderSections.Survivorship]: true,
      [WillBuilderSections.Exclusions]: this.data.exclusions.length > 0,
      [WillBuilderSections.StepPowers]: true,
      [WillBuilderSections.AvoidanceOfDoubt]: true,
      // To check?
      [WillBuilderSections.CharitiesClause]:
        this.data.hasCharitiesInAddressBook && !isRNRBTrust,
      // Business Clause Option
      // @todo: Get gifts of type business and output for business clause beneficiaries
      [WillBuilderSections.BusinessClause]: isBusinessClause,
      [WillBuilderSections.LegacyOfBusinessProperty]: isBusinessTrust,
      [WillBuilderSections.LegacyOfCombinedNRA]: isRNRBTrust,
      [WillBuilderSections.LegacyOfRES]: isRESTrust,
      [WillBuilderSections.AbsoluteResiduaryGift]: isIIPTrust,
      [WillBuilderSections.Trusts]: isAnyTrust,
      [WillBuilderSections.ScheduleBusiness]: isBusinessTrust,
      [WillBuilderSections.ScheduleNRB]: isRNRBTrust,
      [WillBuilderSections.ScheduleRES]: isRESTrust,
      [WillBuilderSections.ScheduleIIP]: isIIPTrust,
      [WillBuilderSections.ProvisionsBusiness]: isBusinessTrust,
      [WillBuilderSections.ProvisionsNRB]: isRNRBTrust,
      [WillBuilderSections.ProvisionsRES]: isRESTrust,
      [WillBuilderSections.ProvisionsIIP]: isIIPTrust,

      [WillBuilderSections.GeneralTrustPowers]: isAnyTrust,
      [WillBuilderSections.OverridingPowers]: isAnyTrust,
      // Change to Any?
      [WillBuilderSections.PowerToRemove]: isEstateTrust || isIIPTrust,
      [WillBuilderSections.MowBusiness]: isBusinessTrust,
      [WillBuilderSections.MowIIP]: isIIPTrust,
      [WillBuilderSections.MowNRB]: isRNRBTrust,
      [WillBuilderSections.MowRES]: isRESTrust,
      [WillBuilderSections.LetterOfWishes]: true,
    };
  }
}
