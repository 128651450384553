import { Api } from "@/service/api.service";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface UpdateKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
}

interface UpdateKintinStorageRequestResponse {
  physicalStorageFile: PhysicalStorageFile;
}

export const UpdateKintinStorageRequestHandler = async (
  message: UpdateKintinStorageRequestMessage,
): Promise<UpdateKintinStorageRequestResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}",
    {
      kintin: message.kintin,
      subscription: message.subscription,
      physicalStorageFile: message.storageRequest,
    },
  )
    .method("patch")
    .body({
      length: message.storageRequest.$data.length,
      file: message.storageRequest.$data.file,
      notes: message.storageRequest.$data.notes,
      subscription: message.storageRequest.$data.subscription,
      title: message.storageRequest.$data.title,
    })

    .result();

  const physicalStorageFile = PhysicalStorageFile.$inflate(
    response.physicalStorageFile,
  ).first();

  if (!physicalStorageFile) {
    throw new Error("Failed to update storage request");
  }

  return {
    physicalStorageFile,
  };
};
