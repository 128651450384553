import { DangerDialog } from "@/config/dialog.config";
import { FormPasswordField } from "@kinherit/framework/component.input/password-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const ResetPasswordForm = (username: boolean) =>
  defineForm({
    name: "reset-password-form",
    data: () => ({
      username: "",
      newPassword: "",
      confirmPassword: "",
    }),
    validate: (data) => {
      const valid = data.newPassword === data.confirmPassword;

      if (!valid) {
        DangerDialog({
          dialog: {
            title: "Error",
            message: "Passwords do not match",
          },
          button: {
            cancel: {
              show: false,
            },
          },
        });
      }

      return valid;
    },
    formAreas: (data) => [
      defineFormArea({
        name: "reset-password-form-area",
        data,
        template: username
          ? GridLayout([["username"], ["newPassword", "confirmPassword"]])
          : GridLayout([["newPassword"], ["confirmPassword"]]),
        components: () => ({
          username: [
            FormTextField({
              props: {
                label: "Username",
                validators: ["required"],
              },
              models: {
                value: "username",
              },
            }),
          ],
          newPassword: [
            FormPasswordField({
              props: {
                label: "New Password",
                validators: ["required"],
              },
              models: {
                value: "newPassword",
              },
            }),
          ],
          confirmPassword: [
            FormPasswordField({
              props: {
                label: "Confirm Password",
                validators: ["required"],
              },
              models: {
                value: "confirmPassword",
              },
            }),
          ],
        }),
      }),
    ],
  });
