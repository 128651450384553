import { SharedFormProps } from "@/config/form.config";
import { FormFileField } from "@kinherit/framework/component.input/file-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export type TModifyPDFDocumentMessage = {
  pdfDocument: File[] | null;
  selectedWatermarkOption: number;
  selectedPasswordProtectionOption: number;
  selectedRestrictEditingOption: number;
  selectedRestrictPrintingOption: number;
  documentPassword: string | null;
};

export const ModifyPdfForm = (data: TModifyPDFDocumentMessage) => {
  return defineForm({
    name: "modify-pdf-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "modify-pdf-form-area",
        data,
        template: GridLayout([
          ["file"],
          ["selectedWatermarkOption"],
          {
            is: "hr",
          },
          ["selectedPasswordProtectionOption"],
          ["documentPassword"],
          {
            is: "hr",
          },
          ["selectedRestrictEditingOption"],
          ["selectedRestrictPrintingOption"],
          {
            is: "hr",
          },
        ]),
        components: () => ({
          file: [
            FormFileField({
              models: {
                value: "pdfDocument",
              },
              props: {
                label: "Select a PDF file to modify",
                validators: ["required"],
                isSingle: true,
                allowedFileTypes: [".pdf"],
              },
            }),
          ],
          selectedWatermarkOption: [
            FormRadioField({
              models: {
                value: "selectedWatermarkOption",
              },
              props: {
                label: "Add Watermark",
                options: ["None", "Draft", "Conformed Copy"],
              },
            }),
          ],
          selectedPasswordProtectionOption: [
            FormRadioField({
              reactive: true,
              models: {
                value: "selectedPasswordProtectionOption",
              },
              props: {
                label: "Add Password to open",
                options: ["None", "Yes, require a password"],
              },
            }),
          ],
          documentPassword: [
            FormTextField({
              reactive: true,
              models: {
                value: "documentPassword",
              },
              props: {
                label: "Document Password",
                message:
                  "Enter a password to open the document: typically this is the client DOB in the format DDMMYY",
                validators: ["required"],
                placeholder: "DDMMYY",
                vIf: (data) => data.selectedPasswordProtectionOption === 1,
              },
            }),
          ],
          selectedRestrictEditingOption: [
            FormRadioField({
              models: {
                value: "selectedRestrictEditingOption",
              },
              props: {
                label: "Restrict Editing",
                options: ["None", "Yes Restrict Editing"],
              },
            }),
          ],
          selectedRestrictPrintingOption: [
            FormRadioField({
              models: {
                value: "selectedRestrictPrintingOption",
              },
              props: {
                label: "Restrict Printing",
                options: ["None", "Yes Restrict Printing"],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
