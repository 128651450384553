import { EmailCampaign } from "@kinherit/sdk";
import { EmailCampaignDetailsParams } from "./page/details";

export const EmailCampaignsMasterListBreadCrumb = {
  text: "Email Campaigns",
  route: { name: "EmailCampaignsMasterList" },
};

export const EmailCampaignDetailsBreadCrumb = (
  params: EmailCampaignDetailsParams,
) => [
  EmailCampaignsMasterListBreadCrumb,
  {
    text: EmailCampaign.$findOne(params.emailCampaign)?.name ?? "",
    route: { name: "EmailCampaignDetails", params },
  },
];

export const EmailCampaignDetailsSummaryBreadCrumb = (
  params: EmailCampaignDetailsParams,
) => [...EmailCampaignDetailsBreadCrumb(params)];

export const EmailCampaignDetailsMembersBreadCrumb = (
  params: EmailCampaignDetailsParams,
) => [
  ...EmailCampaignDetailsBreadCrumb(params),
  {
    text: "Members",
    route: { name: "EmailCampaignDetailsMembers", params },
  },
];

export const EmailCampaignDetailsMembersCsvBreadCrumb = (
  params: EmailCampaignDetailsParams,
) => [
  ...EmailCampaignDetailsMembersBreadCrumb(params),
  {
    text: "CSV",
    route: { name: "EmailCampaignDetailsMembersCsv", params },
  },
];

export const EmailCampaignDetailsLogsBreadCrumb = (
  params: EmailCampaignDetailsParams,
) => [
  ...EmailCampaignDetailsBreadCrumb(params),
  {
    text: "Logs",
    route: { name: "EmailCampaignDetailsLogs", params },
  },
];
