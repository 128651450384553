import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsChangeLogBreadCrumb } from "../../../breadcrumbs";

/** ChangeLog */
export const KinvaultKintinDetailsChangeLogRoute =
  "KinvaultKintinDetailsChangeLog";
const KinvaultKintinDetailsChangeLogURI = "/kinvault/:kintin/change-log";
export type KinvaultKintinDetailsChangeLogParams = RouteParamObject<
  typeof KinvaultKintinDetailsChangeLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsChangeLogRoute,
    path: KinvaultKintinDetailsChangeLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsChangeLog" */ "./KintinDetails.ChangeLog.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsChangeLogBreadCrumb,
    },
  },
];
