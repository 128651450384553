<template>
  <div class="is-will-builder-section-controls buttons">
    <Button
      v-if="hasCustomContent"
      size="is-small"
      text="Custom"
      :is-outlined="state !== 'custom'"
      is-rounded
      color="is-primary"
      @click="updateState('custom')"
    />
    <Button
      text="Generated"
      :is-outlined="state !== 'generated'"
      is-rounded
      size="is-small"
      color="is-primary"
      @click="updateState('generated')"
    />
    <Button
      v-if="hasCustomContent"
      size="is-small"
      :is-outlined="state !== 'difference'"
      is-rounded
      text="Difference"
      color="is-primary"
      @click="updateState('difference')"
    />
    <Button
      v-if="options.editing"
      aria-label="Edit"
      size="is-small"
      icon-left="Edit"
      color="is-plain"
      @click="$emit('edit')"
    />
  </div>
</template>

<script lang="ts">
import Button from "@kinherit/framework/component.input/button";
import { PropType, defineComponent } from "vue";
import { WillBuilderService } from "../../service/will-builder.service";

export default defineComponent({
  name: "WillBuilderSectionControls",
  components: { Button },
  mixins: [WillBuilderService.mixin],
  emits: ["edit", "update:state"],
  props: {
    state: {
      type: String as PropType<"custom" | "generated" | "difference">,
      required: true,
    },
    hasCustomContent: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateState(state: "custom" | "generated" | "difference") {
      this.$emit("update:state", state);
    },
  },
});
</script>

<style lang="scss">
.is-will-builder-section-controls {
  visibility: hidden;
  position: absolute;
}
</style>
