<template>
  <Card
    title="Authentication Methods"
    v-if="!loading"
    subtitle="Manage this user's 2FA methods"
    class="user-details-manage-2fa"
  >
    <template #buttons>
      <Button
        @click="$emit('create:mf-auth-method')"
        text="Create"
        class="create-button"
        :icon-left="$style.icon.lock.icon"
        ariaLabel="Create MFA Method"
        size="is-small"
        color="is-plain"
      />
    </template>
    <Message
      title="Warning"
      :color="'is-warning'"
      size="is-small"
      class="mb-4 mt-4"
    >
      <b
        >Do not delete an authentication methods without an elevated security
        check</b
      >
      <br />
      <small>
        The client needs to be on the phone (recorded call) and should be able
        to go through security questions
      </small>
    </Message>
    <Table
      is-scrollable
      :columns="columns"
      :rows="rows"
      is-fullwidth
      is-narrow
      is-hoverable
    >
      <template #actions="{ row }: { row: MfAuthMethod }">
        <Button
          @click="handleDelete(row)"
          class="delete-button"
          :icon-left="$style.icon.delete.icon"
          ariaLabel="Delete MFA Method"
          size="is-small"
          color="is-plain"
        />
        <Button
          v-if="row.setupCompleteAt === null"
          @click="handleResend(row)"
          class="resend-button"
          icon-left="Reset"
          ariaLabel="Reset MFA Code"
          size="is-small"
          color="is-plain"
        />
      </template>
      <template #alias="{ row }: { row: MfAuthMethod }">
        <b v-if="row.type === 'totp' && !row.alias"> Authenticator </b>
        <b v-else-if="row.device && !row.alias">{{ row.device }}</b>
        <b v-else-if="row.alias">{{ row.alias }}</b>
        <span v-else class="has-text-grey-light">No Alias</span>
      </template>
    </Table>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsManage2FactorAuthWrapper",
  "route": "UserDetailsManage2FactorAuth",
  "selector": ".user-details-manage-2fa"
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import Message from "@kinherit/framework/component.display/message";
import Table from "@kinherit/framework/component.display/table";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { IMfAuthMethod, MfAuthMethod } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { AdminUserDetailsManage2FactorAuthRoute } from ".";

export default defineComponent({
  name: AdminUserDetailsManage2FactorAuthRoute,
  mixins: [
    AuthService.mixin({ sessionRequired: true, permissions: ["user:write"] }),
    UserDetailsMixin,
    StyleService.mixin,
  ],
  components: {
    Table,
    Card,
    Button,
    Message,
  },
  data: () => ({
    loading: true,
    columns: [
      {
        title: "Alias",
        sort: true,
        slot: "alias",
      },
      {
        title: "Type",
        sort: true,
        map: (v: MfAuthMethod) => {
          if (v.type === "email") {
            return "Email";
          }

          return v.type.toUpperCase();
        },
      },
      {
        title: "Actions",
        sort: false,
        slot: "actions",
        class: "is-narrow",
      },
    ],
    rows: Array<MfAuthMethod>(),
    sort: {
      by: "date" as keyof IMfAuthMethod,
      direction: "asc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const user = this.user;

      if (!user) {
        return;
      }

      this.loading = true;
      // const { mfAuthMethods } = await window.Kernel.ActionBus.execute(
      //   "admin/user/mf-auth-methods/read",
      //   {
      //     user,
      //     pagination: false,
      //   },
      // );
      const { mfAuthMethods } =
        await window.Kernel.ActionBus.adminUser.mfAuthMethods.read({
          user,
          pagination: false,
        });

      this.rows = mfAuthMethods;

      this.loading = false;
    },
    async handleDelete(mfAuthMethod: MfAuthMethod): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Auth Method",
          message: `Are you sure you want to delete ${
            mfAuthMethod.alias ?? `this authentication method`
          }?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/user/mf-auth-methods/delete",
      //   {
      //     mfAuthMethod: mfAuthMethod,
      //   },
      // );
      await window.Kernel.ActionBus.adminUser.mfAuthMethods.delete({
        mfAuthMethod: mfAuthMethod,
      });

      await this.refresh();
    },
    async handleResend(mfAuthMethod: MfAuthMethod): Promise<void> {
      // await window.Kernel.ActionBus.execute(
      //   "admin/user/mf-auth-methods/resend",
      //   {
      //     mfAuthMethod: mfAuthMethod,
      //   },
      // );
      await window.Kernel.ActionBus.adminUser.mfAuthMethods.resend({
        mfAuthMethod: mfAuthMethod,
      });
    },
    cancel(): void {
      this.$router.back();
    },
  },
});
</script>
