import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadAppointmentHandler } from "./read-appointment.action";
import { RecordAppointmentHandler } from "./record-appointment.action";
import { UpdateAppointmentHandler } from "./update-appointment.action";

export const Actions = {
  "appointment/read": ReadAppointmentHandler,
  "appointment/update": UpdateAppointmentHandler,
  "appointment/record": RecordAppointmentHandler,
};

export const AppointmentActionBus = DefineActionBus({
  name: "Appointment",
  actions: {
    read: ReadAppointmentHandler,
    update: UpdateAppointmentHandler,
    record: RecordAppointmentHandler,
  },
});
