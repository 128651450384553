import { OptionsSelectField } from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerBillingContact } from "@kinherit/sdk";

export const UpdateIntroducerBillingContactForm = (
  contact: IntroducerBillingContact,
) =>
  defineForm({
    name: "update-introducer-billing-contact-form",
    data: () => contact,
    formAreas: (data) => [
      defineFormArea({
        name: "update-introducer-billing-contact-form-area",
        data,
        template: GridLayout([
          ["introducerRemittanceType", "agentRemittanceType"],
          ["notes"],
          ["isGlobal"],
        ]),
        components: () => ({
          introducerRemittanceType: [
            OptionsSelectField({
              group: "introducerRemittanceType",
              vModel: "introducerRemittanceType",
            }),
          ],
          agentRemittanceType: [
            OptionsSelectField({
              group: "agentRemittanceType",
              vModel: "agentRemittanceType",
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
          isGlobal: [
            FormCheckboxField({
              props: {
                label: "Global",
                message: "This billing contact should receive all remittances",
              },
              models: {
                value: "isGlobal",
              },
            }),
          ],
        }),
      }),
    ],
  });
