import { OptionsAutoCompleteField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Person } from "@kinherit/sdk";

export const UpdateOfficerCallStatusForm = (person: Person) =>
  defineForm({
    name: "update-officer-call-status-form",
    data: () => person,
    formAreas: (data) => [
      defineFormArea({
        name: "update-officer-call-status-form-area",
        data,
        template: GridLayout(["officerCallStatus"]),
        components: () => ({
          officerCallStatus: [
            OptionsAutoCompleteField({
              props: {
                showDropDown: true,
                showMoreButton: false,
                validators: ["required"],
              },
              group: "officerCallStatus",
              vModel: "officerCallStatus",
            }),
          ],
        }),
      }),
    ],
  });
