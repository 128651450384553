import { Api } from "@/service/api.service";
import { Adviser, Kintin } from "@kinherit/sdk";

interface UpdateAdviserMessage {
  kintin: string | Kintin;
  adviser: Adviser;
}

interface UpdateAdviserResponse {
  adviser: Adviser;
}

export const UpdateAdviserHandler = async (
  message: UpdateAdviserMessage,
): Promise<UpdateAdviserResponse> => {
  const { adviser: adviserData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-adviser/{kintin}/person/adviser/{adviser}",
    {
      kintin: message.kintin,
      adviser: message.adviser.$data.id,
    },
  )
    .method("patch")

    .body({
      defaultTrustIfa: message.adviser.$data.defaultTrustIfa,
      notes: message.adviser.$data.notes,
      person: message.adviser.$data.person,
      forPerson: message.adviser.$data.forPerson,
    })
    .result();

  const adviser = Adviser.$inflate(adviserData).first();

  if (!adviser) {
    throw new Error("Failed to update adviser");
  }

  return { adviser };
};
