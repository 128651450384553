import { Api } from "@/service/api.service";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";

interface RemoveKnowledgeBaseCategoryDocumentMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory | string;
  knowledgeBaseDocument: KnowledgeBaseDocument | string;
}

interface RemoveKnowledgeBaseCategoryDocumentResponse {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

export const RemoveKnowledgeBaseCategoryDocumentHandler = async (
  message: RemoveKnowledgeBaseCategoryDocumentMessage,
): Promise<RemoveKnowledgeBaseCategoryDocumentResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-category/{knowledgeBaseCategory}/documents/{knowledgeBaseDocument}/remove",
    {
      knowledgeBaseCategory:
        "string" === typeof message.knowledgeBaseCategory
          ? message.knowledgeBaseCategory
          : message.knowledgeBaseCategory.id,
      knowledgeBaseDocument:
        "string" === typeof message.knowledgeBaseDocument
          ? message.knowledgeBaseDocument
          : message.knowledgeBaseDocument.id,
    },
  )
    .method("delete")

    .result();

  const knowledgeBaseCategory = KnowledgeBaseCategory.$inflate(
    response.knowledgeBaseCategory,
  ).first();

  if (!knowledgeBaseCategory) {
    throw new Error("Failed to remove knowledge base category document");
  }

  return {
    knowledgeBaseCategory,
  };
};
