import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateEmailTemplateHandler } from "./email-template/create-email-template.action";
import { DeleteEmailTemplateHandler } from "./email-template/delete-email-template.action";
import { ReadEmailTemplateHandler } from "./email-template/read-email-template.action";
import { RecordEmailTemplateHandler } from "./email-template/record-email-template.action";
import { UpdateEmailTemplateHandler } from "./email-template/update-email-template.action";

export const Actions = {
  "admin/email-template/read": ReadEmailTemplateHandler,
  "admin/email-template/record": RecordEmailTemplateHandler,
  "admin/email-template/delete": DeleteEmailTemplateHandler,
  "admin/email-template/create": CreateEmailTemplateHandler,
  "admin/email-template/update": UpdateEmailTemplateHandler,
};

export const AdminEmailTemplateActionBus = DefineActionBus({
  name: "Admin.EmailTemplate",
  actions: {
    read: ReadEmailTemplateHandler,
    record: RecordEmailTemplateHandler,
    delete: DeleteEmailTemplateHandler,
    create: CreateEmailTemplateHandler,
    update: UpdateEmailTemplateHandler,
  },
});
