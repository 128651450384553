import { Api } from "@/service/api.service";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

interface CreateDefaultBrandedKinvaultReferralCodeMessage {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

interface CreateDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

export const CreateDefaultBrandedKinvaultReferralCodeHandler = async (
  message: CreateDefaultBrandedKinvaultReferralCodeMessage,
): Promise<CreateDefaultBrandedKinvaultReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/default-branded-kinvault-referral-code",
  )
    .method("post")

    .body({
      code: message.defaultBrandedKinvaultReferralCode.$data.code,
      name: message.defaultBrandedKinvaultReferralCode.$data.name,
      brandedKinvault:
        message.defaultBrandedKinvaultReferralCode.$data.brandedKinvault,
    })
    .result();

  const defaultBrandedKinvaultReferralCode =
    DefaultBrandedKinvaultReferralCode.$inflate(
      response.defaultBrandedKinvaultReferralCode,
      undefined,
      response.$rootIds,
    ).first();

  if (!defaultBrandedKinvaultReferralCode) {
    throw new Error("Failed to create default referral code");
  }

  message.defaultBrandedKinvaultReferralCode.$delete();

  return {
    defaultBrandedKinvaultReferralCode,
  };
};
