import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateOrderItemHandler } from "./order-item/create-order-item.action";
import { DeleteOrderItemHandler } from "./order-item/delete-order-item.action";
import { UpdateOrderItemHandler } from "./order-item/update-order-item.action";
import { CreateOrderHandler } from "./order/create-order.action";
import { DeleteOrderHandler } from "./order/delete-order.action";
import { DownloadOrderInvoiceHandler } from "./order/download-order-invoice.action";
import { PushOrderHandler } from "./order/push-order.action";
import { ReadOrderHandler } from "./order/read-order.action";
import { RecordOrderHandler } from "./order/record-order.action";
import { UpdateOrderHandler } from "./order/update-order.action";

export const Actions = {
  "order/order/read": ReadOrderHandler,
  "order/order/record": RecordOrderHandler,
  "order/order/delete": DeleteOrderHandler,
  "order/order/update": UpdateOrderHandler,
  "order/order/create": CreateOrderHandler,
  "order/order/download-invoice": DownloadOrderInvoiceHandler,
  "order/order-item/create": CreateOrderItemHandler,
  "order/order-item/update": UpdateOrderItemHandler,
  "order/order-item/delete": DeleteOrderItemHandler,
  "order/order/push-to-xero": PushOrderHandler,
};

export const OrderActionBus = DefineActionBus({
  name: "Order",
  actions: {
    order: DefineActionBus({
      name: "Order.Order",
      actions: {
        read: ReadOrderHandler,
        record: RecordOrderHandler,
        delete: DeleteOrderHandler,
        update: UpdateOrderHandler,
        create: CreateOrderHandler,
        downloadInvoice: DownloadOrderInvoiceHandler,
        pushToXero: PushOrderHandler,
      },
    }),
    orderItem: DefineActionBus({
      name: "Order.OrderItem",
      actions: {
        create: CreateOrderItemHandler,
        update: UpdateOrderItemHandler,
        delete: DeleteOrderItemHandler,
      },
    }),
  },
});
