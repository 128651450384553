import { Api } from "@/service/api.service";
import { WillRevision } from "@kinherit/sdk";

interface DeleteWillRevisionMessage {
  willRevision: WillRevision;
}

interface DeleteWillRevisionResponse {
  [key: string]: never;
}

export const DeleteWillRevisionHandler = async (
  message: DeleteWillRevisionMessage,
): Promise<DeleteWillRevisionResponse> => {
  await Api.resource("portal", "/v2/portal/will-revision/{willRevision}", {
    willRevision: message.willRevision.id,
  })
    .method("delete")
    .result();

  message.willRevision.$delete();

  return {};
};
