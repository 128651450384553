import { CreatePhysicalStorageFile } from "@/config/model.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { PhysicalStorageFile } from "@kinherit/sdk";

export const UpdatePhysicalStorageFileForm = (
  physicalStorageFile: PhysicalStorageFile = CreatePhysicalStorageFile(),
) =>
  defineForm({
    name: "update-physical-storage-file-form",
    data: () => physicalStorageFile,
    formAreas: (data) => [
      defineFormArea({
        name: "update-physical-storage-file-form-area",
        data,
        template: GridLayout([["title"], ["length"], ["notes"]]),
        components: () => ({
          title: [
            FormTextField({
              props: {
                label: "Title",
                placeholder: "i.e. Last Will and Testament of Joe Bloggs",
              },
              models: {
                value: "title",
              },
            }),
          ],
          length: [
            FormTextField({
              props: {
                label: "Length",
                message: "Length of document",
                placeholder: "i.e 6 pages",
              },
              models: {
                value: "length",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Condition Notes",
                isTextArea: true,
                message:
                  "Include any notes about the condition of the file, i.e, torn corner, water damaged, etc.",
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
