<template>
  <div v-if="data">
    <span>
      <strong>Revocation</strong>
      <p>
        I
        <span
          v-if="data.person"
          v-html="helpers.var(data.person.profile.fullName?.toUpperCase())"
        />
        <span
          v-if="data.person?.formerNames"
          v-html="
            ` also known as ${helpers.var(
              data.person.formerNames.toUpperCase(),
            )} `
          "
        />
        date of birth
        <span
          v-if="data.person"
          v-html="helpers.var(helpers.formatDate(data.person.dateOfBirth))"
        />
        of
        <span
          v-if="data.person"
          v-html="
            helpers.var(
              helpers.formatAddress(
                data.person.profile.addresses.findBy(
                  'primaryResidential',
                  true,
                ),
              ),
            )
          "
        />

        do hereby revoke all former wills and testamentary dispositions
        <span v-if="!data.hasAssetsOutsideUK">
          made by me and declare this to be my last will (hereinafter referred
          to as 'my Will')
        </span>

        <span v-if="data.hasAssetsOutsideUK">
          so far as they relate to my property of every kind wherever situate
          but excluding that in [COUNTRY] and declare that the law of England &
          Wales shall apply to this my will (hereinafter referred to as my Will)
          in relation to my property of every kind wherever situate but
          excluding that in [COUNTRY]
        </span>
      </p>
    </span>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RevocationOutput",
  mixins: [WillBuilderService.mixin],
});
</script>
