import { PersonField, SharedFormProps } from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In, NotEqual } from "@kinherit/orm/index";
import { Kintin, Trustee } from "@kinherit/sdk";
import { AddKintinPersonButton } from "./shared.form";

export const UpdateTrusteeForm = ({
  data,
  kintin,
}: {
  data: Trustee;
  kintin: Kintin;
}) => {
  return defineForm({
    name: "update-trustee-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "trustee-form-area",
        data,
        template: GridLayout([
          ["person", "forPerson"],
          { is: "hr" },
          ["notes"],
          ["isReserve", "attainmentAge"],
        ]),
        components: () => ({
          person: [
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.primaryPerson,
                label: "Who is the trustee?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.primaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            PersonField({
              props: {
                vIf: (data) =>
                  data.forPerson?.id === kintin.$data.secondaryPerson,
                label: "Who is the trustee?",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotEqual(kintin.$data.secondaryPerson),
                kintin: {
                  id: Equal(kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
          ],
          forPerson: [
            PersonField({
              reactive: true,
              props: {
                label: (data) =>
                  `Who is ${
                    data.person?.profile?.fullName ?? `this person`
                  } a trustee for?`,
                validators: ["required"],
                reference: "forPerson",
                vModel: {
                  get: (data) => data.forPerson,
                  set: (value, data) => {
                    data.forPerson = value;

                    if (data.forPerson?.id === data.person?.id) {
                      data.person = null as any;
                    }
                  },
                },
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          isReserve: [
            FormCheckboxField({
              props: {
                label: "Add as Reserve Trustee",
                size: "is-medium",
              },
              models: {
                value: "isReserve",
              },
            }),
          ],
          attainmentAge: [
            FormNumberField({
              props: {
                label: "Age of Attainment",
                message:
                  "Only use for children U18 - will be output in trusts MOW as Appointment of Additional Trustee(s)",
              },
              models: {
                value: "attainmentAge",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
                placeholder: "Addition notes about this trustee",
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
