import {
  AccountReferralCodeField,
  IntroducerCompanyField,
  SharedFilterProps,
  XeroContactField,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadIntroducerFeeKintinForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-fee-kinintin-form",
    data: () => ({
      referralCode: Array<string>(),
      agent: Array<string>(),
      period: null as null | [number, number],
      reconciled: null as null | boolean,
      onXero: null as null | "introducer" | "agent" | "parentAgent",
      showOrders: "waitingForPayment" as
        | null
        | "waitingForPayment"
        | "within21DayCoolOffPeriod",
      xeroContacts: Array<string>(),
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      referralCode: [
        AccountReferralCodeField({
          props: {
            vModel: "referralCode",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
      agent: [
        IntroducerCompanyField({
          props: {
            label: "Agent",
            vModel: "agent",
            isMultiSelect: true,
          },
          query: {
            contracts: {
              type: {
                value: "agentintroducer",
              },
            },
          },
          simplifyData: true,
        }),
      ],
      showOrders: [
        FormSelectField({
          reactive: true,
          props: {
            label: "Show orders",
            options: {
              null: "Any",
              waitingForPayment: "Waiting for payment",
              within21DayCoolOffPeriod: "Within 21 day cool off period",
            },
            showClearButton: false,
            reference: "showOrders",
          },
          models: {
            value: {
              get: (data) => {
                return data.showOrders;
              },
              set: (value, data) => {
                data.showOrders = value;
                data.period = null;
              },
            },
          },
        }),
      ],
      reconciled: [
        FormSelectField({
          props: {
            label: "Reconciled",
            options: {
              null: "Any",
              yes: "Yes",
              no: "No",
            },
            showClearButton: false,
            reference: "reconciled",
          },
          models: {
            value: {
              get: (data) => {
                if (data.reconciled === null) {
                  return `null`;
                }

                return data.reconciled ? "yes" : "no";
              },
              set: (value, data) => {
                data.reconciled =
                  value === "yes" ? true : value === "no" ? false : null;
              },
            },
          },
        }),
      ],
      onXero: [
        FormSelectField({
          props: {
            label: "On Xero",
            options: {
              null: "Any",
              introducer: "Introducer",
              agent: "Agent",
              parentAgent: "Parent Agent",
            },
            showClearButton: false,
            reference: "onXero",
          },
          models: {
            value: {
              get: (data) => {
                if (data.onXero === null) {
                  return `null`;
                }
                return data.onXero;
              },
              set: (value, data) => {
                data.onXero = value;
              },
            },
          },
        }),
      ],
      period: [
        FormSelectField({
          reactive: true,
          props: {
            label: "Period",
            showClearButton: true,
            options: (() => {
              const start = 2019;

              const options: Record<string, string> = {};

              for (let i = start; i <= new Date().getFullYear(); i++) {
                options[i.toString()] = i.toString();
              }

              return options;
            })(),
            reference: "period",
          },
          models: {
            value: {
              get: (data) => {
                if (!data.period) {
                  return null;
                }

                const [, year] = data.period;

                return year;
              },
              set: (value, data) => {
                if (null === value) {
                  data.period = null;
                  return;
                }

                data.showOrders = null;

                const period = data.period;

                if (!period) {
                  data.period = [new Date().getMonth() + 1, value];
                  return;
                }

                const [month] = period;
                data.period = [month, value];
              },
            },
          },
          slots: {
            left: [
              FormSelectField({
                props: {
                  style: {
                    minWidth: "105px",
                  },
                  showClearButton: false,
                  options: {
                    1: "January",
                    2: "February",
                    3: "March",
                    4: "April",
                    5: "May",
                    6: "June",
                    7: "July",
                    8: "August",
                    9: "September",
                    10: "October",
                    11: "November",
                    12: "December",
                  },
                  reference: "month",
                },
                models: {
                  value: {
                    get: (data) => {
                      if (!data.period) {
                        return null;
                      }

                      const [month] = data.period;

                      return month;
                    },
                    set: (value, data) => {
                      if (null === value) {
                        data.period = null;
                        return;
                      }

                      const period = data.period;

                      if (!period) {
                        data.period = [value, new Date().getFullYear()];
                        return;
                      }

                      const [, year] = period;
                      data.period = [value, year];
                    },
                  },
                },
              }),
            ],
          },
          emits: {
            clear: (data) => {
              data.period = null;
            },
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      xeroContact: [
        XeroContactField({
          props: {
            label: "Xero Contact",
            vModel: "xeroContacts",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
