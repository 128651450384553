<template>
  <DataListWidget v-if="emailTemplate" title="Settings" :details="details" />
  <DataListWidget
    v-if="emailTemplate"
    title="Attachments"
    :details="attachmentDetails"
  />
</template>

<script lang="ts">
import { EmailTemplateDetailsMixin } from "@/module/admin.email-template/mixin/email-template-details.mixin";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { Option } from "@kinherit/sdk";
import { defineComponent } from "vue";
export default defineComponent({
  name: "EmailTemplateCodeSummary",
  mixins: [EmailTemplateDetailsMixin],
  components: { DataListWidget },
  computed: {
    details(): DataListWidgetDetail[] {
      if (!this.emailTemplate) return [];
      return [
        {
          label: "Type",
          value: this.emailTemplate?.type.text,
        },
        {
          label: "Include To",
          value: this.emailTemplate?.includeTo?.text,
          hide: !this.emailTemplate?.includeTo,
        },
        {
          label: "Notes",
          value: this.emailTemplate?.notes,
          hide: !this.emailTemplate?.notes,
        },
      ];
    },
    attachmentDetails(): DataListWidgetDetail[] {
      const attachments = Option.$findBy({
        group: "preloadedEmailAttachments",
      });

      if (!this.emailTemplate) return [];

      return [
        {
          label: "Allows Custom Attachments",
          value: this.emailTemplate?.allowAttachments ? "Yes" : "No",
        },
        {
          label: "Attachments must start with KTREF",
          value: this.emailTemplate?.strictAttachments ? "Yes" : "No",
          hide: !this.emailTemplate?.allowAttachments,
        },
        {
          label: "Required Attachments",
          value: this.emailTemplate?.requiredAttachments.join(", "),
          hide: this.emailTemplate?.requiredAttachments.length === 0,
        },
        {
          label: "Preloaded Attachments",
          value: {
            html: this.emailTemplate?.$data.preloadedFiles
              .map((id) => {
                const attachment = attachments.find(
                  (attachment) => attachment.$data.id === id,
                );
                return attachment?.value
                  ? "<a target='_blank' href='https://static.kinherit.co.uk/files/salesaids/" +
                      encodeURIComponent(attachment?.value) +
                      "'>" +
                      attachment?.text +
                      "</a>"
                  : id;
              })
              .join("<br />"),
          },
          hide: this.emailTemplate?.$data.preloadedFiles.length === 0,
        },
      ];
    },
  },
});
</script>
