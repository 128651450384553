import { Api } from "@/service/api.service";
import { CreditNote } from "@kinherit/sdk";

export interface CreateCreditNoteMessage {
  creditNote: CreditNote;
}

export interface CreateCreditNoteResponse {
  creditNote: CreditNote;
}

export const CreateCreditNoteHandler = async (
  message: CreateCreditNoteMessage,
): Promise<CreateCreditNoteResponse> => {
  const response = await Api.resource("portal", "/v2/portal/credit-note")
    .method("post")
    .body({
      issuedAt: message.creditNote.$data.issuedAt,
      issuedBy: message.creditNote.$data.issuedBy,
      note: {
        completedAt: message.creditNote.note.$data.completedAt,
        dueAt: message.creditNote.note.$data.dueAt,
        kintin: message.creditNote.note.$data.kintin,
        name: message.creditNote.note.$data.name,
        notes: message.creditNote.note.$data.notes,
        pinned: message.creditNote.note.$data.pinned,
        type: "creditNote",
      },
      relatedKintin: message.creditNote.$data.relatedKintin,
      relatedUser: message.creditNote.$data.relatedUser,
      initialValue: message.creditNote.$data.initialValue,
      remainingValue: message.creditNote.$data.remainingValue,
    })

    .result();

  message.creditNote.$delete();

  const creditNote = CreditNote.$inflate(response.creditNote).first();

  if (!creditNote) {
    throw new Error("Failed to create credit note");
  }

  message.creditNote.$delete();

  return {
    creditNote,
  };
};
