<template>
  <table class="table-box">
    <thead>
      <th>
        <strong><span v-html="helpers.var(title)" /></strong>
        <em>(this may span multiple pages)</em>
      </th>
    </thead>
    <tbody>
      <tr
        v-for="beneficiary in allBeneficiariesAndGiftRecipients"
        :key="beneficiary.id"
      >
        <td>
          <span v-html="helpers.var(helpers.formatBeneficiary(beneficiary))" />
        </td>
      </tr>
      <tr v-if="allBeneficiariesAndGiftRecipientsIssue.isNotEmpty()">
        <td>
          The ISSUE and REMOTER ISSUE of
          <span
            v-html="
              helpers.var(
                allBeneficiariesAndGiftRecipientsIssue
                  .map(helpers.formatBeneficiary)
                  .join(' and '),
              )
            "
          />
        </td>
      </tr>
      <!--tr
        v-for="reserveBeneficiary in reserveBeneficiaries"
        :key="reserveBeneficiary.id"
      >
        <td>
          <span
            v-html="helpers.var(helpers.formatBeneficiary(reserveBeneficiary))"
          />
        </td>
      </tr>
      <tr v-if="reserveBeneficiariesIssue.isNotEmpty()">
        <td>
          The ISSUE and REMOTER ISSUE of
          <span
            v-html="
              helpers.var(
                reserveBeneficiariesIssue
                  .map(helpers.formatBeneficiary)
                  .join(' and '),
              )
            "
          />
        </td>
      </tr-->
      <tr>
        <td>
          Plus any other persons nominated by the trustees as herein defined
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { Beneficiary, Gift } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `BeneficiariesOutput`,
  mixins: [WillBuilderService.mixin],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    /*
    Get all beneficiaries and gift recipients; remove duplicates.
    */
    allBeneficiariesAndGiftRecipients(): Array<Beneficiary | Gift> {
      const uniqueBeneficiaries = [
        ...(this.data?.beneficiariesFirstLine ?? []),
        ...(this.data?.beneficiariesSecondLine ?? []),
        ...(this.data.reserveBeneficiaries ?? []),
      ].unique("person.id");

      const gifts = this.data?.giftsForMow.unique("forPerson.id");

      const all = [...uniqueBeneficiaries, ...gifts];

      const result = all.unique((item) => {
        if (item instanceof Beneficiary) {
          return (item as Beneficiary).person?.id;
        } else {
          return (item as Gift).forPerson?.id;
        }
      });

      return result;
    },
    allBeneficiariesAndGiftRecipientsIssue(): Array<Beneficiary | Gift> {
      return this.allBeneficiariesAndGiftRecipients.filterBy(
        "ifPredeceased.value",
        "theirconcern",
      );
    },

    // /*
    // Needs to include gift recipients when output in a schedule
    // No person should be duplicated
    // */
    // distinctBeneficiariesWithGiftRecipients(): Array<Beneficiary | Gift> {
    //   const all = [
    //     ...this.beneficiaries,
    //     ...this.reserveBeneficiaries,
    //     ...this.giftRecipients,
    //   ];
    //   // For each entry look for duplicate person id
    //   return all.filter(
    //     (item, index, self) =>
    //       index ===
    //       self.findIndex((t) =>
    //         // Check if item is a gift or beneficiary
    //         "person" in item
    //           ? (t as Beneficiary).person?.id ===
    //             (item as Beneficiary).person?.id
    //           : (t as Gift).forPerson?.id === (item as Gift).forPerson?.id,
    //       ),
    //   );
    // },
    // distinctIssueWithGiftRecipients(): Array<Beneficiary | Gift> {
    //   const all = [
    //     ...this.beneficiariesIssue,
    //     ...this.reserveBeneficiariesIssue,
    //     ...this.giftRecipientsIssue,
    //   ];
    //   return all.filter(
    //     (item, index, self) =>
    //       index ===
    //       self.findIndex((t) =>
    //         // Check if item is a gift or beneficiary
    //         "person" in item
    //           ? (t as Beneficiary).person?.id ===
    //             (item as Beneficiary).person?.id
    //           : (t as Gift).forPerson?.id === (item as Gift).forPerson?.id,
    //       ),
    //   );
    // },
    // // Should only be gift recipients which are output in the MOW.
    // giftRecipients(): Gift[] {
    //   return this.data?.giftsForMow ?? [];
    // },
    // giftRecipientsIssue(): Gift[] {
    //   return this.giftRecipients.filterBy(
    //     "ifPredeceased.value",
    //     "theirconcern",
    //   );
    // },
    // beneficiaries(): Beneficiary[] {
    //   return [
    //     ...(this.data?.beneficiariesFirstLine ?? []),
    //     ...(this.data?.beneficiariesSecondLine ?? []),
    //   ];
    // },
    // beneficiariesIssue(): Beneficiary[] {
    //   return this.beneficiaries.filterBy("ifPredeceased.value", "theirconcern");
    // },
    // reserveBeneficiaries(): Beneficiary[] {
    //   return this.data?.reserveBeneficiaries ?? [];
    // },
    // reserveBeneficiariesIssue(): Beneficiary[] {
    //   return this.reserveBeneficiaries.filterBy(
    //     "ifPredeceased.value",
    //     "theirconcern",
    //   );
    // },
  },
});
</script>

<style lang="scss" scoped>
.table-box {
  border: thick double black;
  width: 15cm;
  margin: auto;
  tbody,
  thead {
    tr {
      td,
      th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }
    }
  }
}
</style>
