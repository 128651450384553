import { Api } from "@/service/api.service";
import { IntroducerNetwork } from "@kinherit/sdk";

export interface DeleteIntroducerNetworkMessage {
  introducerNetwork: IntroducerNetwork | string;
}

interface DeleteIntroducerNetworkResponse {
  [key: string]: never;
}

export const DeleteIntroducerNetworkHandler = async (
  message: DeleteIntroducerNetworkMessage,
): Promise<DeleteIntroducerNetworkResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-network/{introducerNetwork}",
    {
      introducerNetwork: message.introducerNetwork,
    },
  )
    .method("delete")

    .result();

  IntroducerNetwork.$delete(message.introducerNetwork);

  return {};
};
