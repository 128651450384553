import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { OrderDetailsBreadCrumb } from "../../../breadcrumbs";

export const OrderDetailsSummaryRoute = "OrderDetailsSummary";
export const OrderDetailsSummaryURI = "/order/:order";
export type OrderDetailsSummaryParams = RouteParamObject<
  typeof OrderDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: OrderDetailsSummaryRoute,
    path: OrderDetailsSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "OrderDetailsSummary" */ "./OrderDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...OrderDetailsBreadCrumb(params as OrderDetailsSummaryParams),
      ],
    },
  },
];
