import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsEmailLogRoute =
  "IntroducerCompanyDetailsEmailLog";
export const IntroducerCompanyDetailsEmailLogURI =
  "/introducer/company/:introducerCompany/email-log";
export type IntroducerCompanyDetailsEmailLogParams = RouteParamObject<
  typeof IntroducerCompanyDetailsEmailLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsEmailLogRoute,
    path: IntroducerCompanyDetailsEmailLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsEmailLog" */ "./IntroducerCompanyDetails.EmailLog.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Email Log",
          route: {
            name: IntroducerCompanyDetailsEmailLogRoute,
            params,
          },
        },
      ],
    },
  },
];
