import { Api } from "@/service/api.service";
import {
  Address,
  Beneficiary,
  CallScript,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Person,
  Profile,
  Property,
  Trustee,
  User,
} from "@kinherit/sdk";

interface RecordKintinWillRevisionDataMessage {
  kintin: string;
}

interface RecordKintinWillRevisionDataResponse {
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  users: Array<User>;
  people: Array<Person>;
  addresses: Array<Address>;
  callScripts: Array<CallScript>;
  properties: Array<Property>;
  beneficiaries: Array<Beneficiary>;
  executors: Array<Executor>;
  guardians: Array<Guardian>;
  exclusions: Array<Exclusion>;
  gift: Array<Gift>;
  trustees: Array<Trustee>;
}

export const RecordKintinWillRevisionDataHandler = async (
  message: RecordKintinWillRevisionDataMessage,
): Promise<RecordKintinWillRevisionDataResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/kintin-will-revision/{kintin}/data",
    {
      kintin: message.kintin,
    },
  )
    .method("get")

    .result();

  return {
    kintins: Kintin.$inflate(response.kintin),
    profiles: Profile.$inflate(response.profile),
    users: User.$inflate(response.user),
    people: Person.$inflate(response.person),
    addresses: Address.$inflate(response.address),
    callScripts: CallScript.$inflate(response.callScript),
    properties: Property.$inflate(response.property),
    beneficiaries: Beneficiary.$inflate(response.beneficiary),
    executors: Executor.$inflate(response.executor),
    guardians: Guardian.$inflate(response.guardian),
    exclusions: Exclusion.$inflate(response.exclusion),
    gift: Gift.$inflate(response.gift),
    trustees: Trustee.$inflate(response.trustee),
  };
};
