import { Api } from "@/service/api.service";
import { CpdSessionRegistration } from "@kinherit/sdk";

interface DeleteCpdSessionRegistrationMessage {
  cpdSessionRegistration: CpdSessionRegistration;
}

interface DeleteCpdSessionRegistrationResponse {
  [key: string]: never;
}

export const DeleteCpdSessionRegistrationHandler = async (
  message: DeleteCpdSessionRegistrationMessage,
): Promise<DeleteCpdSessionRegistrationResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/cpd-session/cpd-session-registration/{cpdSessionRegistration}",
    {
      cpdSessionRegistration: message.cpdSessionRegistration,
    },
  )
    .method("delete")

    .result();

  message.cpdSessionRegistration.$delete();

  return {};
};
