<template>
  <strong> Provisions for the Business Property Trust</strong>
  <p>
    This clause shall apply to the trusts detailed in
    <strong>Schedule <span v-html="helpers.var(schedule)" /></strong>
    of this my Will hereinafter referred to as 'the Business Trust'
    <br /><br />
    The trustees of the Business Trust shall hold the trust property on trust
    for such of the beneficiaries in such shares and in such manner as the
    trustees (being at least two in number or a trust corporation) shall in
    their absolute discretion appoint by deed or deeds revocable or irrevocable
    and executed at any time during the Trust Period
  </p>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    schedule() {
      return this.helpers.getSchedulePosition(
        WillBuilderSections.ScheduleBusiness,
      );
    },
  },
});
</script>
