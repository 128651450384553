<template>
  <div class="form-controls">
    <Title v-if="title"> {{ title }} </Title>
    <Title v-if="subtitle" is-subtitle> {{ subtitle }} </Title>
    <Form :config="form" is-borderless @update:data="updateWarnings" />
    <div class="columns mt-4">
      <div class="column">
        <Level is-mobile>
          <template #left>
            <span
              v-if="mode === 'update' && false !== deleteButton"
              v-tool-tip="warnings.delete"
            >
              <Button
                :is-disabled="!!warnings.delete"
                v-bind="$style.button.danger"
                @click="remove"
                class="remove-button"
              >
                {{ deleteButton }}
              </Button>
            </span>
            <span v-if="false !== resetButton" v-tool-tip="warnings.reset">
              <Button
                :is-disabled="!!warnings.reset"
                v-bind="$style.button.warning"
                @click="resetForm"
                class="reset-button"
              >
                {{ resetButton }}
              </Button>
            </span>
          </template>
          <template #right>
            <Button
              v-if="false !== cancelButton"
              @click="$emit('cancel')"
              class="cancel-button"
            >
              {{ cancelButton }}
            </Button>
            <span
              v-if="
                (mode === 'update' && false !== updateButton) ||
                (mode === 'create' && false !== createButton)
              "
              v-tool-tip="warnings.save"
            >
              <Button
                :is-disabled="!!warnings.save"
                class="save-button"
                color="is-positive"
                @click="$emit('save', form.localData)"
                @click:disabled="$emit('show-validation')"
              >
                {{ mode === "update" ? updateButton : createButton }}
              </Button>
            </span>
          </template>
        </Level>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { StyleService } from "@/service/style.service";
import { Title } from "@kinherit/framework/component.display/title";
import { Form } from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import { Level } from "@kinherit/framework/component.layout/level";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { AlertDialogRequest } from "@kinherit/framework/global/dialog";
import { DeepPartial } from "@kinherit/ts-common";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "FormControls",
  mixins: [StyleService.mixin],
  components: {
    Form,
    Title,
    Button,
    Level,
  },
  props: {
    form: {
      type: Object as PropType<FormBuilder<any, string>>,
      required: true,
    },
    deleteDialog: {
      type: Object as PropType<DeepPartial<AlertDialogRequest>>,
      default: () => ({}),
    },
    mode: {
      type: String as PropType<"update" | "create">,
      required: true,
    },
    createButton: {
      type: [String, Boolean] as PropType<string | false>,
      default: "Save",
    },
    updateButton: {
      type: [String, Boolean] as PropType<string | false>,
      default: "Update",
    },
    deleteButton: {
      type: [String, Boolean] as PropType<string | false>,
      default: "Delete",
    },
    title: {
      type: String,
      default: null,
    },
    /** Subtitle to display under the title */
    subtitle: {
      type: String,
      default: null,
    },
    cancelButton: {
      type: [String, Boolean] as PropType<string | false>,
      default: "Cancel",
    },
    resetButton: {
      type: [String, Boolean] as PropType<string | false>,
      default: "Reset",
    },
  },
  emits: ["save", "remove", "cancel", "reset", "show-validation"],
  data: () => ({
    loading: true,
    warnings: {
      delete: null as string | null,
      reset: null as string | null,
      save: null as string | null,
    },
  }),
  mounted() {
    this.warnings.delete = null;
    this.warnings.reset = "No changes found";
    this.warnings.save = !this.form.isValid()
      ? "Form incomplete"
      : "No changes found";
  },
  methods: {
    async remove(): Promise<void> {
      await DangerDialog(this.deleteDialog);

      this.$emit("remove");
    },
    resetForm(): void {
      this.$emit("reset");

      this.$nextTick(() => {
        this.warnings.delete = null;
        this.warnings.reset = "No changes found";
        this.warnings.save = !this.form.isValid()
          ? "Form incomplete"
          : "No changes found";
        this.form.controls.rebuildForm();
      });
    },
    updateWarnings(): void {
      this.warnings = {
        delete: "You have unsaved changes",
        reset: null,
        save: !this.form.isValid() ? "Form incomplete" : null,
      };
    },
  },
});
</script>
