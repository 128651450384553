import { RouteInterface } from "@kinherit/framework/core/route-config";

export const DashboardEstatePlannerRoute = "DashboardEstatePlanner";
export const DashboardLegalAdminRoute = "DashboardLegalAdmin";

export const Routes: Array<RouteInterface> = [
  {
    name: DashboardEstatePlannerRoute,
    path: "ep",
    component: () => import("./EstatePlannerDashboard.vue"),
  },
  {
    name: DashboardLegalAdminRoute,
    path: "la",
    component: () => import(".//LegalAdminDashboard.vue"),
  },
];
