<template>
  <div class="kintin-details-address-book-gift-recipients">
    <PageHeader htag="h2" text="Gift Recipients" v-if="kintin">
      <template #buttons>
        <Button text="Add Gift" @click="createGift" class="add-button" />
        <Button
          :text="sorting ? 'Done' : 'Sort'"
          @click="togglingSorting"
          class="sort-button"
          v-if="!isPeopleTab"
        />
        <Button
          v-if="kintin.type.value === 'joint'"
          text="Copy To"
          @click="copy"
          class="copy-button"
        />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <Tabs
        v-if="!sorting"
        :config="tabConfig"
        v-model:tab="computedTab"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4 person-tabs"
      />
      <div v-if="!isPeopleTab">
        <SortRoles
          v-if="sorting && tabConfig[selectedTab].label !== 'Joint Gifts'"
          :kintin="kintin"
          :roles="$data.filtered.gifts"
        />
        <GiftRecipientList
          v-else-if="tabConfig[selectedTab].label !== 'Joint Gifts'"
          :kintin="kintin"
          :gifts="$data.filtered.gifts"
          @reload="filter"
        />
        <SortRoles
          v-if="sorting && tabConfig[selectedTab].label === 'Joint Gifts'"
          :kintin="kintin"
          :roles="$data.filtered.jointGifts"
        />
        <GiftRecipientList
          v-else-if="tabConfig[selectedTab].label === 'Joint Gifts'"
          :kintin="kintin"
          :gifts="$data.filtered.jointGifts"
          @reload="filter"
        />
      </div>
      <PersonList
        :key="renderKey"
        v-if="isPeopleTab"
        :kintin="kintin"
        :people="$data.filtered.people"
        @reload="filter"
      />
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "GiftRecipientsContainerWrapper",
  "selector": ".kintin-details-address-book-gift-recipients",
  "imports": {
    "PersonListWrapper": "@/module/kinvault.kintin/component/lists/PersonList.test",
    "UpdateGiftFormWrapper": "@/module/kinvault.kintin/form/update-gift.form.test",
    "GiftRecipientListWrapper": "@/module/kinvault.kintin/component/lists/GiftRecipientList.test"
  },
  "methods": {
    "tabs": {
      "type": "indexed-tabs",
      "selector": ".person-tabs"
    },
    "giftRecipientList": {
      "type": "to-one",
      "selector": "",
      "wrapper": "GiftRecipientListWrapper"
    },
    "personList": {
      "type": "to-one",
      "selector": ".person-list",
      "wrapper": "PersonListWrapper"
    },
    "addGift": {
      "type": "click",
      "selector": ".add-button"
    },
    "addGiftForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateGiftFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateGift } from "@/config/model.config";
import GiftRecipientList from "@/module/kinvault.kintin/component/lists/GiftRecipientList.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdateGiftForm } from "@/module/kinvault.kintin/form/update-gift.form";
import { StyleService } from "@/service/style.service";
import { Json } from "@kinherit/framework/component.display/json";
import { Tabs } from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Gift, Kintin, Person } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { CopyRolesForm } from "../../form/copy-roles.form";
import SortRoles from "./SortRoles.vue";

export default defineComponent({
  name: `GiftRecipientsContainer`,
  components: {
    Tabs,
    Json,
    GiftRecipientList,
    Button,
    PageHeader,
    PersonList,
    SortRoles,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      gifts: Array<Gift>(),
      jointGifts: Array<Gift>(),
      people: Array<Person>(),
    },
    selectedTab: 0,
    sorting: false,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    renderKey: 0,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson) {
        names.push(this.kintin?.primaryPerson?.profile.fullName ?? "Primary");
      }
      if (this.kintin?.secondaryPerson) {
        names.push(
          this.kintin?.secondaryPerson?.profile.fullName ?? "Secondary",
        );
      }

      if (this.kintin?.type?.value === "joint") {
        names.push("Joint Gifts");
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const gifts = Gift.$findBy({
        sentBy: {
          id:
            selectedPerson?.id ??
            In([
              this.kintin?.primaryPerson?.id,
              this.kintin?.secondaryPerson?.id,
            ]),
        },
      });

      this.filtered.gifts = gifts.filter((g) => !g.onSecondDeath);
      this.filtered.jointGifts = gifts.filter((g) => g.onSecondDeath);

      this.filtered.gifts = this.filtered.gifts.sortBy("sortOrder");
      this.filtered.jointGifts = this.filtered.jointGifts.sortBy("sortOrder");

      this.filtered.people = gifts
        .pluck("forPerson")
        .unique("id")
        .filter(Boolean)
        .sortBy("profile.fullName")
        .cast<Person>();

      this.renderKey++;

      this.$emit("reload");
    },
    async createGift() {
      if (!this.kintin) {
        return;
      }

      let sentBy = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === sentBy && this.kintin.type.value === "single") {
        sentBy = this.kintin.primaryPerson?.id;
      }

      const gift = CreateGift({
        sentBy,
        onSecondDeath: undefined === sentBy,
      });

      try {
        await UpdateGiftForm({
          data: gift,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create gift",
          },
        });
      } catch {
        gift.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/gift/create", {
      //   gift,
      //   kintin: this.kintin.id,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.gift.create({
        gift,
        kintin: this.kintin.id,
      });

      this.filter();
    },
    async togglingSorting() {
      if (this.sorting) {
        await [
          ...this.$data.filtered.gifts,
          ...this.$data.filtered.jointGifts,
        ].forEachAsync(async (gift) =>
          // window.Kernel.ActionBus.execute("kinvault/kintin/gift/update", {
          //   gift,
          //   kintin: this.kintin,
          // }),
          window.Kernel.ActionBus.kinvaultKintin.gift.update({
            gift,
          }),
        );
      }

      this.sorting = !this.sorting;
    },
    async copy() {
      const sentBy =
        this.selectedTab === 0
          ? this.kintin.secondaryPerson
          : this.kintin.primaryPerson;

      let name: string = "";

      if (sentBy?.profile.fullName) {
        name = sentBy?.profile.fullName;
      }

      const type =
        this.selectedTab === 0 ? "secondary person" : "primary person";

      name = name === "" ? type : `${name} (${type})`;

      const { rolesToCopy } = await CopyRolesForm(
        this.$data.filtered.gifts.filter((a) => a.forPerson?.id !== sentBy?.id),
        this.kintin,
      ).dialog({
        dialog: {
          title: `Copy to ${name}`,
        },
      });

      await rolesToCopy.forEachAsync(async (gift) => {
        const newGift = CreateGift({
          ...gift.$data,
          sentBy: sentBy?.id,
          id: Uuid.generate(),
        });

        // await window.Kernel.ActionBus.execute("kinvault/kintin/gift/create", {
        //   gift: newGift,
        //   kintin: this.kintin.id,
        // });
        await window.Kernel.ActionBus.kinvaultKintin.gift.create({
          gift: newGift,
          kintin: this.kintin.id,
        });
      });

      this.selectedTab = this.selectedTab === 0 ? 1 : 0;
      this.filter();
    },
  },
});
</script>
