import { Api } from "@/service/api.service";
import {
  AccountReferralCode,
  IAccountReferral,
  IntroducerCompany,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyAccountReferralCodesMessage {
  introducerCompany: IntroducerCompany | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferral;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyAccountReferralCodesResponse {
  referralCodes: Array<AccountReferralCode>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerCompanyAccountReferralCodesHandler = async (
  message: ReadIntroducerCompanyAccountReferralCodesMessage,
): Promise<ReadIntroducerCompanyAccountReferralCodesResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-referral/{introducerCompany}/referral-codes",
    {
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
    },
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  const response = await request.result();

  return {
    referralCodes: AccountReferralCode.$inflate(
      response.accountReferralCode,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
