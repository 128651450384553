import { Api } from "@/service/api.service";
import { Order, Person } from "@kinherit/sdk";

export interface SendOrderPaymentEmailMessage {
  order: string | Order;
  person: string | Person;
}

interface SendOrderPaymentEmailResponse {
  [key: string]: never;
}

// export class SendOrderPaymentEmailHandler extends ActionBase {
//   constructor(private message: SendOrderPaymentEmailMessage) {
//     super();
//   }

//   public async execute(): Promise<SendOrderPaymentEmailResponse> {
export const SendOrderPaymentEmailHandler = async (
  message: SendOrderPaymentEmailMessage,
): Promise<SendOrderPaymentEmailResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/order/{order}/send-order-payment-email/{person}",
    {
      order: message.order,
      person: message.person,
    },
  )
    .method("put")
    .result();

  return {};
};
