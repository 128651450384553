import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { LegacyEmailLog } from "@kinherit/sdk";

export const UpdateLegacyEmailLogForm = (legacyEmailLog: LegacyEmailLog) =>
  defineForm({
    name: "update-legacy-email-log-form",
    data: () => legacyEmailLog,
    formAreas: (data) => [
      defineFormArea({
        name: "update-legacy-email-log-form-area",
        data,
        template:
          null !== legacyEmailLog.bcc
            ? GridLayout([
                ["from", "to"],
                ["bcc"],
                {
                  is: "hr",
                },
                ["title", "subject"],
                ["preload", "attachments"],
                {
                  is: "hr",
                },
                ["body"],
              ])
            : GridLayout([
                ["from", "to"],
                {
                  is: "hr",
                },
                ["title", "subject"],
                ["preload", "attachments"],
                {
                  is: "hr",
                },
                ["body"],
              ]),
        components: () => ({
          from: [
            FormElement({
              props: {
                html: (data) => `
                    <b>From:</b></br> ${data.sentFrom}</br>
                    <small>${data.sentAt?.formatDateTime}</small>
                `,
              },
            }),
          ],
          to: [
            FormElement({
              props: {
                html: (data) => `
                        <b>To:</b></br>
                         ${data.recipients ?? ""}
                    `,
              },
            }),
          ],
          bcc: [
            FormElement({
              props: {
                html: (data) => `
                        <b>BCC:</b></br>
                            ${data.bcc ?? ""}
                    `,
              },
            }),
          ],
          title: [
            FormElement({
              props: {
                html: (data) => `
                        <b>Title:</b></br>
                            ${data.title}
                    `,
              },
            }),
          ],
          subject: [
            FormElement({
              props: {
                html: (data) => `
                        <b>Subject:</b></br>
                            ${data.subject}
                    `,
              },
            }),
          ],
          preload: [
            FormElement({
              props: {
                html: (data) => `
                        <b>Preload:</b></br>
                            ${
                              data.preload?.isNotEmpty()
                                ? data.preload.join("</br>")
                                : "No preloads found"
                            }
                    `,
              },
            }),
          ],
          attachments: [
            FormElement({
              props: {
                html: (data) => `
                        <b>Attachments:</b></br>
                            ${
                              data.attachments?.isNotEmpty()
                                ? data.attachments
                                    .map((a) => a.fileName)
                                    ?.join("</br>")
                                : "No attachments found"
                            }
                    `,
              },
            }),
          ],
          body: [
            FormElement({
              props: {
                html: (data) => `
                        <b>Body:</b></br>
                            ${data.body}
                    `,
              },
            }),
          ],
        }),
      }),
    ],
  });
