import { RouteInterface } from "@kinherit/framework/core/route-config";
import { KinvaultOfficerMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as KinvaultOfficerDetailsRoutes } from "./details";

export const KinvaultOfficerMasterListRoute = "KinvaultOfficerMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultOfficerMasterListRoute,
    path: "/trusted-people",
    component: () =>
      import(
        /* webpackChunkName: "OfficerMasterList" */ "./OfficerMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KinvaultOfficerMasterListBreadCrumb],
    },
  },
  ...KinvaultOfficerDetailsRoutes,
];
