import { Api } from "@/service/api.service";
import { Kintin, Person } from "@kinherit/sdk";

interface UpdateKintinPersonMessage {
  person: Person;
  kintin: Kintin;
}

interface UpdateKintinPersonResponse {
  person: Person;
}

export const UpdateKintinPersonHandler = async (
  message: UpdateKintinPersonMessage,
): Promise<UpdateKintinPersonResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-person/{kintin}/person/{person}",
    {
      kintin: message.kintin.id,
      person: message.person.id,
    },
  )
    .method("patch")

    .body({
      type: message.person.$data.type,
      notificationDate: message.person.$data.notificationDate,
      notifications: message.person.$data.notifications,
      gender: message.person.$data.gender,
      maritalStatus: message.person.$data.maritalStatus,
      under18ForPlanningPurposes:
        message.person.$data.under18ForPlanningPurposes,
      requiresCare: message.person.$data.requiresCare,
      isTrusted: message.person.$data.isTrusted,
      dateOfBirth: message.person.$data.dateOfBirth,
      dateOfDeath: message.person.$data.dateOfDeath,
      relationToPrimaryPerson: message.person.$data.relationToPrimaryPerson,
      relationToSecondaryPerson: message.person.$data.relationToSecondaryPerson,
    })
    .result();

  const person = Person.$inflate(response.person).first();

  if (!person) {
    throw new Error("Failed to update person");
  }

  return {
    person,
  };
};
