<template>
  <DataListWidget v-if="brandedKinvault" title="About" :details="details">
    <template #below>
      <hr />
      <div class="has-text-right has-text-grey">
        Created {{ brandedKinvault.createdAt.formatDate }}
      </div>
    </template>
  </DataListWidget>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsSidebarWrapper",
  "imports": {
    "DataListWidgetWrapper": "@/shared/component/widget/DataListWidget.test"
  },
  "methods": {
    "about": {
      "type": "to-one",
      "selector": "",
      "wrapper": "DataListWidgetWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsMixin } from "../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: "BrandedKinvaultDetailsSidebar",
  components: {
    DataListWidget,
  },
  mixins: [BrandedKinvaultDetailsMixin],
  computed: {
    details(): Array<DataListWidgetDetail> {
      const brandedKinvault = this.brandedKinvault;

      if (!brandedKinvault) {
        return [];
      }

      const details: Array<DataListWidgetDetail> = [];

      return details;
    },
  },
});
</script>
