import { Api } from "@/service/api.service";

export interface ModifyPdfMessage {
  pdfDocument: File;
  selectedWatermarkOption: number;
  selectedPasswordProtectionOption: number;
  selectedRestrictEditingOption: number;
  selectedRestrictPrintingOption: number;
  documentPassword: string | null;
}

export interface ModifyPdfResponse {}

export const ModifyPdfHandler = async (
  message: ModifyPdfMessage,
): Promise<ModifyPdfResponse> => {
  if (!message.pdfDocument) {
    throw new Error("No file selected");
  }
  const uploadedFile = message.pdfDocument;
  let fileName = uploadedFile.name;

  // For conformed copy, add CC_ prefix to the file name
  if (message.selectedWatermarkOption === 2) {
    fileName = "CC_" + fileName;
  }

  // For draft, add DRAFT_ prefix to the file name
  if (message.selectedWatermarkOption === 1) {
    fileName = "DRAFT_" + fileName;
  }

  // If PW Protection is enabled, add _pw suffix to the file name but beford extension
  if (message.selectedPasswordProtectionOption === 1) {
    const fileExtension = fileName.split(".").pop();
    fileName = fileName.replace("." + fileExtension, "_pw." + fileExtension);
  }

  console.log(uploadedFile.name, uploadedFile.size, uploadedFile.type);
  await Api.resource("portal", "/v2/portal/document-utils/modify")
    .method("post")
    .files({
      uploadedFile: uploadedFile,
    })
    .params({
      selectedWatermarkOption: message.selectedWatermarkOption,
      selectedPasswordProtectionOption:
        message.selectedPasswordProtectionOption,
      selectedRestrictEditingOption: message.selectedRestrictEditingOption,
      selectedRestrictPrintingOption: message.selectedRestrictPrintingOption,
      documentPassword: message.documentPassword ?? "",
    })
    .download({
      fileName: fileName,
      encoding: "application/pdf",
    });
  return {};
};
