<template>
  <div
    :class="{
      'call-script': true,
      'is-focused-fullscreen': isFocused,
      'call-script--client-summary': true,
    }"
  >
    <PageHeader htag="h2" text="Client Summary">
      <template #buttons>
        <Button
          :text="isFocused ? 'Unfocus' : 'Focus'"
          @click="isFocused = !isFocused"
        />
        <Button text="Save" @click="saveHandler" color="is-positive" />
      </template>
    </PageHeader>
    <div class="client-summary-output">
      <ClientSummaryPage1 />
      <ClientSummaryPage2 />
      <ClientSummaryPage3 />
    </div>
    <Button @click="generateClientSummary">Generate</Button>
    <Button @click="generateClientSummaryWithPassword"
      >Generate With Password</Button
    >
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsCallScriptClientSummaryWrapper",
  "route": "KintinDetailsCallScriptClientSummary",
  "selector": ".call-script--client-summary"
}
</cypress-wrapper>

<script lang="ts">
import ClientSummaryPage1 from "@/module/kinvault.kintin/component/call-script/client-summary/ClientSummary.Page1.vue";
import ClientSummaryPage2 from "@/module/kinvault.kintin/component/call-script/client-summary/ClientSummary.Page2.vue";
import ClientSummaryPage3 from "@/module/kinvault.kintin/component/call-script/client-summary/ClientSummary.Page3.vue";
import { KintinDetailsCallScriptClientSummaryRoute } from "@/module/kinvault.kintin/page/details/call-script/client-summary";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { EmailTemplateService } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams } from "..";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";
import { KintinDetailsCallScriptFollowUpRoute } from "../follow-up";

export default defineComponent({
  name: KintinDetailsCallScriptClientSummaryRoute,
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
  ],
  components: {
    PageHeader,
    Button,
    ClientSummaryPage2,
    ClientSummaryPage1,
    ClientSummaryPage3,
  },
  data: () => ({
    isFocused: false,
  }),
  methods: {
    saveAndContinueHandler() {
      // @todo
      if (!this.kintin) {
        return;
      }

      this.$snackBar.success.saved();
      window.Kernel.visitRoute({
        name: KintinDetailsCallScriptFollowUpRoute,
        params: { kintin: this.kintin.id } as KintinDetailsCallScriptParams,
      });
    },
    saveHandler() {
      // @todo
      this.$snackBar.success.saved();
    },

    getModifiedContent(): string | null {
      const originalElement = document
        .getElementsByClassName("client-summary-output")
        .item(0);

      if (originalElement && originalElement instanceof HTMLElement) {
        // Clone the element to prevent modifying the actual content in the DOM
        const clonedElement = originalElement.cloneNode(true);

        if (clonedElement instanceof HTMLElement) {
          // Get all the 'help-content' elements within the cloned element
          const helpContents =
            clonedElement.getElementsByClassName("help-content");

          // Remove all the 'help-content' elements from the cloned element
          while (helpContents.length > 0) {
            helpContents[0].parentNode?.removeChild(helpContents[0]);
          }

          // Return the modified content
          return clonedElement.innerHTML;
        }
      }

      return null;
    },

    hasMissingVariables(content: string): boolean {
      return EmailTemplateService.hasMissingVariables(content, {});
    },
    async generateClientSummary() {
      const kintin = this.kintin;

      const content = this.getModifiedContent();

      if (!kintin || !content) {
        return;
      }

      if (this.hasMissingVariables(content)) {
        SnackBarService.errors.generic("Please check for placeholder values.");
        return;
      }

      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/client-summary/download",
      //   {
      //     content: content,
      //     kintin: kintin,
      //     password: false,
      //   },
      // );
      await window.Kernel.ActionBus.kinvaultKintin.clientSummary.download({
        content: content,
        kintin: kintin,
        password: false,
      });
    },
    async generateClientSummaryWithPassword() {
      const kintin = this.kintin;

      const content = this.getModifiedContent();

      const primaryPerson = kintin?.primaryPerson;

      if (!kintin || !content) {
        return;
      }

      const hasMissingVariables = this.hasMissingVariables(content);

      if (null === primaryPerson?.dateOfBirth) {
        SnackBarService.errors.generic(
          "Primary account holder date of birth is missing, which is required to add the default password",
        );
        return;
      }

      if (hasMissingVariables) {
        SnackBarService.errors.generic("Please check for placeholder values.");
        return;
      }
      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/client-summary/download",
      //   {
      //     content: content,
      //     kintin: kintin,
      //     password: true,
      //   },
      // );
      await window.Kernel.ActionBus.kinvaultKintin.clientSummary.download({
        content: content,
        kintin: kintin,
        password: true,
      });
    },
  },
});
</script>

<style lang="scss">
@for $i from 1 through 5 {
  [data-depth="#{$i}"] {
    padding-left: $i * 1em;
  }
}
</style>
