import {
  IntroducerCompanyDetailsParams,
  IntroducerCompanyDetailsRoute,
} from "@/module/introducer.company/page/details";
import { AuthService } from "@/service/auth.service";
import { IntroducerCompany } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const IntroducerCompanyDetailsMixin = defineComponent({
  name: "introducer-company-details-mixin",
  computed: {
    $params(): IntroducerCompanyDetailsParams {
      return this.$route.params as any;
    },
    introducerCompany(): IntroducerCompany | null {
      return IntroducerCompany.$findOne(this.$params.introducerCompany);
    },
    hasWritePermission(): boolean {
      return AuthService.hasPermission("introducer-company:write");
    },
  },
  methods: {
    visitIntroducerCompanyDetails(): void {
      window.Kernel.Router.push({
        name: IntroducerCompanyDetailsRoute,
        params: { introducerCompany: this.introducerCompany?.id },
      });
    },
  },
});
