import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormTimeField } from "@kinherit/framework/component.input/time-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { In } from "@kinherit/orm";
import { Option } from "@kinherit/sdk";
import { Time } from "@kinherit/ts-common/dto/time";

export const ReadCallLogForm = (): FormBuilder<
  () => {
    username: string;
    direction: Array<string>;
    minDuration: number | null;
    maxDuration: number | null;
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-call-log-form",
    data: () => ({
      username: "",
      direction: Option.$findBy({
        group: "callDirections",
        value: In(["I", "O"]),
      }).pluck("id"),
      minDuration: null as number | null,
      maxDuration: null as number | null,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
          },
          models: {
            value: "username",
          },
        }),
      ],
      direction: [
        OptionsAutoCompleteField({
          vModel: "direction",
          group: "callDirections",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      minDuration: [
        FormTimeField({
          props: {
            label: "Min Duration",
            placeholder: "00:00",
            reference: "minDuration",
            max: (data) =>
              data.maxDuration ? Time.fromSeconds(data.maxDuration) : null,
          },
          models: {
            value: {
              get: (data) =>
                data.minDuration ? Time.fromSeconds(data.minDuration) : null,
              set: (value, data) =>
                (data.minDuration = value?.toSeconds() ?? null),
            },
          },
        }),
      ],
      maxDuration: [
        FormTimeField({
          props: {
            label: "Max Duration",
            placeholder: "00:00",
            reference: "maxDuration",
            min: (data) =>
              data.minDuration ? Time.fromSeconds(data.minDuration) : null,
          },
          models: {
            value: {
              get: (data) =>
                data.maxDuration ? Time.fromSeconds(data.maxDuration) : null,
              set: (value, data) =>
                (data.maxDuration = value?.toSeconds() ?? null),
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
