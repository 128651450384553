<template>
  <Message
    :title="title"
    size="is-medium"
    color="is-primary"
    shade="is-light"
    drop-shadow
  >
    <div class="columns is-mobile">
      <div
        v-if="showIcon"
        class="column pr-0"
        :style="{
          flexShrink: 1,
          flexGrow: 0,
        }"
      >
        <Icon :icon="icon" size="is-large" />
      </div>
      <div
        :class="{
          column: true,
          'ml-6 mb-3 mt-1': showIcon,
        }"
      >
        <slot />
      </div>
    </div>
  </Message>
</template>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import Icon from "@kinherit/framework/component.display/icon";
import Message from "@kinherit/framework/component.display/message";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CallScriptSection",
  components: { Message, Icon },
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    icon: StyleService.icon.speechBubble.icon,
  }),
});
</script>
