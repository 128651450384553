import { Api } from "@/service/api.service";
import {
  BrandedKinvault,
  BrandedKinvaultMembership,
  User,
} from "@kinherit/sdk";

interface CreateBrandedKinvaultMembershipMessage {
  user: User;
  brandedKinvault: BrandedKinvault;
}

interface CreateBrandedKinvaultMembershipResponse {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

export const CreateBrandedKinvaultMembershipHandler = async (
  message: CreateBrandedKinvaultMembershipMessage,
): Promise<CreateBrandedKinvaultMembershipResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-membership",
  ).method("post");

  request.body({
    user: message.user.id,
    brandedKinvault: message.brandedKinvault.id,
    allowAccess: true,
  });

  const result = await request.result();

  const brandedKinvaultMembership = BrandedKinvaultMembership.$inflate(
    result.brandedKinvaultMembership,
  ).first();

  if (!brandedKinvaultMembership) {
    throw new Error("Failed to create branded kinvault membership");
  }

  return {
    brandedKinvaultMembership,
  };
};
