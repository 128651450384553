import {
  OptionsSelectField,
  SharedFormProps,
  XeroContactField,
} from "@/config/form.config";
import { CreateBrandedKinvault } from "@/config/model.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { BrandedKinvault } from "@kinherit/sdk";

export const UpdateBrandedKinvaultSettingsForm = (
  brandedKinvault: BrandedKinvault = CreateBrandedKinvault(),
) => {
  return defineForm({
    name: "update-branded-kinvault-form",
    props: SharedFormProps,
    data: () => brandedKinvault,
    formAreas: (data) => [
      defineFormArea({
        name: "branded-kinvault-form-area",
        data,
        template: GridLayout([
          ["systemEmail"],
          ["type", "clientPoolType"],
          ["fontendUrl", "siteMode"],
          { is: "hr" },
          ["subtitle"],
          ["xeroContact"],
          { is: "hr" },
          ["subtitle1"],
          [
            "allowDefaultAssetAccess",
            "useSetupWizard",
            "useBrandedKinvaultInvitations",
            "allowPublicRegistration",
            "useUserInvitations",
          ],
          { is: "hr" },
          ["subtitle2"],
          ["restrictUserEmailDomain", "allowedEmailDomains"],
          ["notes"],
        ]),
        components: () => ({
          systemEmail: [
            FormEmailField({
              props: {
                label: "System Email",
                message: "System Email From Address",
                isNamed: true,
              },
              models: {
                value: "systemEmail",
              },
            }),
          ],
          type: [
            OptionsSelectField({
              vModel: "type",
              group: "organisationTypes",
              props: {
                placeholder: "Select Type",
                validators: ["required"],
                message: "If in doubt, select 'IFA'",
              },
            }),
          ],
          clientPoolType: [
            OptionsSelectField({
              vModel: "clientPoolType",
              group: "clientPoolTypes",
              props: {
                placeholder: "Select Type",
                message:
                  "Shared: Any Adviser can access any client; Peradviser: can only access own clients unless granted implicit access",
                validators: ["required"],
              },
            }),
          ],
          fontendUrl: [
            FormUrlField({
              props: {
                label: "Frontend URL",
                validators: ["required"],
                message: "i.e foo.kinvault.com",
                noProtocol: true,
              },
              models: {
                value: "frontendUrl",
              },
            }),
          ],
          siteMode: [
            OptionsSelectField({
              vModel: "siteMode",
              group: "organisationSiteMode",
              props: {
                validators: ["required"],
              },
            }),
          ],
          subtitle: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "Billing",
                size: "is-4",
              },
            }),
          ],
          xeroContact: [
            XeroContactField({
              props: {
                label: "Xero Contact",
                message: "The Xero contact for billing",
                vModel: "xeroContact",
              },
            }),
          ],
          subtitle1: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "Kinvault Features",
                size: "is-4",
              },
            }),
          ],
          useSetupWizard: [
            FormCheckboxField({
              props: {
                label: "Use Setup Wizard",
                message:
                  "Trigger the setup wizard for new accounts not onboarded by kinherit",
              },
              models: {
                value: "useSetupWizard",
              },
            }),
          ],
          useBrandedKinvaultInvitations: [
            FormCheckboxField({
              props: {
                label: "Use Branded Kinvault Invitations",
                message: "Allow the use of standalone Kinvault invitations",
              },
              models: {
                value: "useBrandedKinvaultInvitations",
              },
            }),
          ],
          useUserInvitations: [
            FormCheckboxField({
              props: {
                label: "Use User Invitations",
                message:
                  "Allow the user to invite another user to their kinvault",
              },
              models: {
                value: "useUserInvitations",
              },
            }),
          ],
          allowPublicRegistration: [
            FormCheckboxField({
              props: {
                label: "Allow Public Registration",
                message: "Allow /register/:referralcode subscription signups",
              },
              models: {
                value: "allowPublicRegistration",
              },
            }),
          ],
          allowDefaultAssetAccess: [
            FormCheckboxField({
              props: {
                label: "Allow Default Asset Access",
                message:
                  "Allow all advisers to access asset register by default",
              },
              models: {
                value: "allowDefaultAssetAccess",
              },
            }),
          ],
          subtitle2: [
            FormTitle({
              props: {
                isSubtitle: true,
                text: "Email Restrictions",
                size: "is-4",
              },
            }),
          ],
          restrictUserEmailDomain: [
            FormCheckboxField({
              props: {
                label: "Restrict User Email Domain",
                message:
                  "Restrict Adviser & Administrator accounts to the preapproved domain list",
              },
              models: {
                value: "restrictUserEmailDomain",
              },
            }),
          ],
          allowedEmailDomains: [
            FormTextField({
              props: {
                label: "Allowed Email Domains",
                message: "Comma separated list of allowed email domains",
              },
              models: {
                value: "allowedEmailDomains",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
