import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between } from "@kinherit/orm/index";
import {
  Api,
  INotification,
  Note,
  Notification,
  Profile,
  User,
} from "@kinherit/sdk";

export interface ReadNotificationLogMessage {
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof INotification;
    direction: "asc" | "desc";
  };
}

interface ReadNotificationLogResponse {
  notification: Array<Notification>;
  // emailAddresses: Array<EmailAddress>;
  user: Array<User>;
  profile: Array<Profile>;
  note: Array<Note>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadNotificationLogHandler = async (
  message: ReadNotificationLogMessage,
): Promise<ReadNotificationLogResponse> => {
  const request = Api.resource("portal", "/v2/portal/notification-log")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(Notification).where({
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    notification: Notification.$inflate(
      response.notification,
      message.sort,
      response.$rootIds,
    ),
    user: User.$inflate(response.user),
    profile: Profile.$inflate(response.profile),
    note: Note.$inflate(response.note),

    pagination: getPagination(response),
  };
};
