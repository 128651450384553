import { Api } from "@/service/api.service";
import { Executor, Kintin } from "@kinherit/sdk";

interface UpdateExecutorMessage {
  kintin: string | Kintin;
  executor: Executor;
}

interface UpdateExecutorResponse {
  executor: Executor;
}

export const UpdateExecutorHandler = async (
  message: UpdateExecutorMessage,
): Promise<UpdateExecutorResponse> => {
  const { executor: executorData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-executor/{kintin}/person/executor/{executor}",
    {
      kintin: message.kintin,
      executor: message.executor.$data.id,
    },
  )
    .method("patch")

    .body({
      forPerson: message.executor.$data.forPerson,
      person: message.executor.$data.person,
      isReserve: message.executor.$data.isReserve,
      notes: message.executor.$data.notes,
      professionalSupport: message.executor.$data.professionalSupport,
      sortOrder: message.executor.$data.sortOrder,
    })
    .result();

  const executor = Executor.$inflate(executorData).first();

  if (!executor) {
    throw new Error("Failed to update executor");
  }

  return { executor };
};
