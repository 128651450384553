import {
  LeadDetailsParams,
  LeadDetailsRoute,
} from "@/module/lead/page/details";
import { AuthService } from "@/service/auth.service";
import { Lead } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const LeadDetailsMixin = defineComponent({
  name: "lead-details-mixin",
  computed: {
    $params(): LeadDetailsParams {
      return this.$route.params as any;
    },
    lead(): Lead | null {
      return Lead.$findOne(this.$params.lead);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("lead:write");
    },
  },
  methods: {
    visitLeadDetails(): void {
      window.Kernel.Router.push({
        name: LeadDetailsRoute,
        params: { lead: this.lead?.id },
      });
    },
  },
});
