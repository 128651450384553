import { Api } from "@/service/api.service";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk";

interface CreateIntroducerCompanyPartnerPortalPermissionsMessage {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

interface CreateIntroducerCompanyPartnerPortalPermissionsResponse {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

export const CreateIntroducerCompanyPartnerPortalPermissionsHandler = async (
  message: CreateIntroducerCompanyPartnerPortalPermissionsMessage,
): Promise<CreateIntroducerCompanyPartnerPortalPermissionsResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-partner-portal/{introducerCompany}/company-partner-portal-permissions",
    {
      introducerCompany: message.companyPartnerPortalPermissions.company,
    },
  )
    .method("post")
    .body({
      company: message.companyPartnerPortalPermissions.company.$id,
      allowPartnerPortal:
        message.companyPartnerPortalPermissions.allowPartnerPortal ?? false,
      enableClients:
        message.companyPartnerPortalPermissions.enableClients ?? false,
      enableFees: message.companyPartnerPortalPermissions.enableFees ?? false,
      viewOrderProducts:
        message.companyPartnerPortalPermissions.viewOrderProducts ?? false,
      enableStandaloneKinvault:
        message.companyPartnerPortalPermissions.enableStandaloneKinvault ??
        false,
    })
    .result();

  const partnerPortalPermissions = CompanyPartnerPortalPermissions.$inflate(
    response.companyPartnerPortalPermissions,
  ).first();

  if (!partnerPortalPermissions) {
    throw new Error("Failed to create partner portal permission");
  }

  return {
    companyPartnerPortalPermissions: partnerPortalPermissions,
  };
};
