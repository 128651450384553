import {
  IntroducerCompanyField,
  OptionsSelectField,
} from "@/config/form.config";
import { CreateAccountReferralCode } from "@/config/model.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { AccountReferralCode } from "@kinherit/sdk";

export const UpdateAccountReferralCodeForm = (
  referralCode: AccountReferralCode = CreateAccountReferralCode({}),
) =>
  defineForm({
    name: "update-account-referral-code-form",
    data: () => referralCode,
    formAreas: (data) => [
      defineFormArea({
        name: "details",
        data,
        template: GridLayout([
          ["name", "code"],
          ["category", "company"],
          ["isFeeSacrifice"],
          ["isSalarySacrifice"],
          ["isFriendsAndFamily"],
          ["notes"],
        ]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
                message: "Referral Code Name",
              },
              models: {
                value: "name",
              },
            }),
          ],
          code: [
            FormTextField({
              props: {
                label: "Code",
                message: "Referral Code: should be lowercase and no spaces",
              },
              models: {
                value: "code",
              },
            }),
          ],
          category: [
            OptionsSelectField({
              group: "referralSources",
              vModel: "category",
              props: {
                label: "Category",
                showClearButton: false,
                validators: ["required"],
                message: "Category used in reports etc",
              },
            }),
          ],
          company: [
            IntroducerCompanyField({
              props: {
                vModel: "company",
                label: "Company",
                message: "Which company is this referral code for?",
              },
            }),
          ],
          isFeeSacrifice: [
            FormCheckboxField({
              props: {
                label: "Sacrificing Fees",
                message:
                  "Whether the introducer is sacrificing their fee in order to give the client a discount, usually 15%",
              },
              models: {
                value: "isFeeSacrifice",
              },
            }),
          ],
          isSalarySacrifice: [
            FormCheckboxField({
              props: {
                label: "Salary Sacrifice",
                message:
                  "Employee Benefits Only: Whether the introducer has a salary sacrifice scheme, and we should invoice the company and not the client",
              },
              models: {
                value: "isSalarySacrifice",
              },
            }),
          ],
          isFriendsAndFamily: [
            FormCheckboxField({
              props: {
                label: "Friends and Family",
                message:
                  "Whether this code is for introducer friends and family where no fees are payable and there is a 30% discount",
              },
              models: {
                value: "isFriendsAndFamily",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Additional Notes for Estate Planner",
                isTextArea: true,
                message:
                  "Displayed on main account screen and or order creation screen",
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
