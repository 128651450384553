import { Api } from "@/service/api.service";
import { KintinAccess } from "@kinherit/sdk";

interface CreateKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface CreateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export const CreateKintinAccessHandler = async (
  message: CreateKintinAccessMessage,
): Promise<CreateKintinAccessResponse> => {
  const response = await Api.resource("portal", "/v2/portal/kintin-user-access")
    .method("post")
    .body({
      introducerContact: message.kintinAccess.$data.introducerContact,
      accessType: message.kintinAccess.$data.accessType,
      admin: message.kintinAccess.$data.admin,
      allowAccess: message.kintinAccess.$data.allowAccess,
      assets: message.kintinAccess.$data.assets,
      care: message.kintinAccess.$data.care,
      contacts: message.kintinAccess.$data.contacts,
      death: message.kintinAccess.$data.death,
      kintin: message.kintinAccess.$data.kintin,
      legal: message.kintinAccess.$data.legal,
      misc: message.kintinAccess.$data.misc,
      settings: message.kintinAccess.$data.settings,
    })

    .result();

  const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

  if (!kintinAccess) {
    throw new Error("Failed to create kinvault access");
  }

  message.kintinAccess.$delete();

  return {
    kintinAccess,
  };
};
