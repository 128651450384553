import {
  CreatedAtField,
  IntroducerCompanyField,
  IntroducerNetworkField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  TagsField,
  UserField,
} from "@/config/form.config";

import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { Equal, In } from "@kinherit/orm";

export const ReadIntroducerCompanyForm = () => {
  const introducerCompanyField = IntroducerCompanyField<any>({
    props: {
      label: "Introduced By",
      vModel: "introducedBy",
      isMultiSelect: true,
    },
    query: {
      contracts: {
        type: {
          value: Equal("agentintroducer"),
        },
      },
    },
    simplifyData: true,
  });

  const assignedUserField = UserField<any>({
    props: {
      label: "Estate Planner",
      vModel: "assignedUser",
      isMultiSelect: true,
    },
    query: {
      roles: {
        role: In(["staff", "admin"]),
      },
      displayUserInPortal: Equal(true),
    },
    simplifyData: true,
  });

  const seniorAdviserField = UserField<any>({
    props: {
      label: "Sales Team",
      vModel: "seniorAdviser",
      isMultiSelect: true,
    },
    query: {
      roles: {
        role: In(["staff", "admin"]),
      },
      displayUserInPortal: Equal(true),
    },
    simplifyData: true,
  });

  return MasterListFiltersForm({
    name: "filter-introducer-company-form",
    data: () => ({
      search: "",
      tags: Array<string>(),
      status: Array<string>(),
      type: Array<string>(),
      stage: Array<string>(),
      assignedUser: Array<string>(),
      seniorAdviser: Array<string>(),
      network: Array<string>(),
      introducedBy: Array<string>(),
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name",
            size: "is-small",
          },
          models: {
            value: "search",
          },
        }),
      ],
      seniorAdviser: [seniorAdviserField],
      assignedUser: [assignedUserField],
      network: [
        IntroducerNetworkField({
          props: {
            label: "Network",
            isMultiSelect: true,
            vModel: "network",
          },
          simplifyData: true,
        }),
      ],
      stage: [
        OptionsAutoCompleteField({
          vModel: "stage",
          group: "introducerStage",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({
      tags: [
        TagsField({
          props: {
            vModel: "tags",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "introducerType",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      introducedBy: [introducerCompanyField],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "introducerStatus",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
