import { getPagination } from "@kinherit/framework/action-bus/helper";
import {
  Between,
  Equal,
  In,
  LessThanOrEqual,
  Like,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import {
  Api,
  BrandedKinvaultPricingSchema,
  IBrandedKinvaultPricingSchema,
} from "@kinherit/sdk";

export interface ReadBrandedKinvaultPricingSchemaMessage {
  name: string | null;
  brandedKinvaults: string[];
  dated: [number, number] | null;
  created: [number, number] | null;
  defaultOnly: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IBrandedKinvaultPricingSchema;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultPricingSchemaResponse {
  brandedKinvaultPricingSchemas: Array<BrandedKinvaultPricingSchema>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadBrandedKinvaultPricingSchemaHandler = async (
  message: ReadBrandedKinvaultPricingSchemaMessage,
): Promise<ReadBrandedKinvaultPricingSchemaResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-kinvault-pricing-schema",
  )
    .method("get")
    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(BrandedKinvaultPricingSchema).where({
    name: Like(message.name),
    brandedKinvaults: {
      id: In(message.brandedKinvaults),
    },
    startDate: LessThanOrEqual(message.dated?.[0]),
    endDate: MoreThanOrEqual(message.dated?.[1]),
    createdAt: Between(message.created),
    isDefault: message.defaultOnly ? Equal(true) : undefined,
  });

  const response = await request.result();

  return {
    brandedKinvaultPricingSchemas: BrandedKinvaultPricingSchema.$inflate(
      response.brandedKinvaultPricingSchema,
      message.sort,
      response.$rootIds,
    ),
    pagination: getPagination(response),
  };
};
