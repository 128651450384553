import { Api } from "@/service/api.service";
import { Kintin, Person } from "@kinherit/sdk";

interface DeleteKintinPersonMessage {
  person: string | Person;
  kintin: string | Kintin;
}

interface DeleteKintinPersonResponse {}

export const DeleteKintinPersonHandler = async (
  message: DeleteKintinPersonMessage,
): Promise<DeleteKintinPersonResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-person/{kintin}/person/{person}",
    {
      kintin: message.kintin,
      person: message.person,
    },
  )
    .method("delete")

    .result();

  Person.$delete(message.person);

  return {};
};
