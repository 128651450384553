import { OptionsSelectField, SharedFormProps } from "@/config/form.config";
import { OptionService } from "@/module/core/service/option.service";
import { FormCalendar } from "@kinherit/framework/component.display/calendar";
import { FormField } from "@kinherit/framework/component.form/field";
import { FormDropDownMenu } from "@kinherit/framework/component.input/drop-down-menu";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Kintin, Option } from "@kinherit/sdk";

const OptionsMatrix = {
  a: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "toprint",
    "notnow",
    "closed",
  ],
  b: [
    "new",
    "booked",
    "nurture",
    "notnow",
    "closed",
    "rebook1",
    "rebook2",
    "rebook3",
  ],
  c: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "notnow",
    "closed",
  ],
  d: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "notnow",
    "closed",
  ],
  e: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "toprint",
    "notnow",
    "closed",
  ],
  e2: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "toprint",
    "notnow",
    "closed",
  ],
  f: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "toprint",
    "notnow",
    "closed",
  ],
  g1: ["new", "booked", "nurture"],
  g2: ["new", "booked", "nurture"],
  g3: [
    "new",
    "booked",
    "nurture",
    "awaiting",
    "nopaylink",
    "todo",
    "toprint",
    "notnow",
  ],
  h: [],
  z1: [],
  z2: [],
};

const ShowOptionsMatrix = {
  nextAction: (data: Kintin) =>
    data.status?.value !== "closed" && data.stage?.value !== "h",
  closedReason: (data: Kintin) => data.status?.value === "closed",
  notifications: (data: Kintin) => data.stage?.value === "h",
};

export const UpdateKintinStatusForm = (kintin: Kintin) => {
  const stages = OptionService.getOptions("kintinStatus", true);
  const qualifiedOut = OptionService.getOptions("qualifiedOut", true);

  return defineForm({
    name: "update-kintin-status-form",
    props: SharedFormProps,
    data: () => ({
      voidOutstandingOrders: false,
      disableUserAccount: false,
      removeUserBrandedKinvaults: false,
      kintin: kintin,
      notes: "",
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "update-kintin-status-form-area",
        data,
        template: {
          attr: {
            class: "columns",
          },
          children: [
            {
              attr: {
                class: "column columns",
              },
              slot: "stage_status",
            },
            {
              attr: {
                class: "column",
                style: {
                  minWidth: "270px",
                },
              },
              children: [
                {
                  slot: "calendar",
                },
                {
                  slot: "options",
                },
              ],
            },
          ],
        },
        components: () => ({
          stage_status: [
            FormField({
              props: {
                label: "Stage",
                class: "column",
                reference: "",
              },
              slots: {
                default: [
                  FormDropDownMenu({
                    props: {
                      noSort: true,
                      includeSelectedInOptions: false,
                      options: OptionService.getOptions("kintinStage", true),
                      showMoreButton: false,
                      mapOptions: {
                        value: "id",
                        label: "text",
                        line1: "description",
                      },
                      isStatic: true,
                      show: true,
                      isScrollable: false,
                      rowClasses: () => (item: Option) =>
                        `has-background-stage-${item.value[0]}-light has-border-stage-${item.value[0]}`,
                      reference: "stage",
                      forceBackground: true,
                      isMultiSelect: false,
                    },
                    models: {
                      value: {
                        get: ({ kintin }) => [kintin.stage],
                        set: (value, data, controls) => {
                          data.kintin.stage = value ?? data.kintin.stage;
                          console.log(value);
                          data.kintin.status = ["h", "z1", "z2"].includes(
                            value?.value,
                          )
                            ? Option.$findOneByOrThrow({
                                value: "complete",
                                group: "kintinStatus",
                              })
                            : (null as any);
                          data.kintin.statusChangeReason = null;

                          data.disableUserAccount = false;
                          data.removeUserBrandedKinvaults = false;
                          data.voidOutstandingOrders = false;

                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ],
              },
            }),
            FormField({
              props: {
                vIf: ({ kintin }) =>
                  kintin.stage != null &&
                  OptionsMatrix[
                    kintin.stage.value as keyof typeof OptionsMatrix
                  ].intersection(stages.pluck("value")).length > 0,
                label: "Status",
                class: "column",
                reference: "",
              },
              slots: {
                default: [
                  FormDropDownMenu({
                    props: {
                      noSort: true,
                      includeSelectedInOptions: false,
                      options: ({ kintin }) =>
                        OptionsMatrix[
                          kintin.stage.value as keyof typeof OptionsMatrix
                        ]?.map((s) =>
                          stages.find((stage) => stage.value === s),
                        ) ?? [],
                      showMoreButton: false,
                      mapOptions: {
                        value: "id",
                        label: "text",
                        line1: "description",
                      },
                      isStatic: true,
                      show: true,
                      isScrollable: false,
                      rowClasses: () => (item: Option) =>
                        `has-background-${item.class}-light has-border-${item.class}`,
                      reference: "status",
                      forceBackground: true,
                      isMultiSelect: false,
                    },
                    models: {
                      value: {
                        get: ({ kintin }) => [kintin.status],
                        set: (value, data, controls) => {
                          data.kintin.status = value ?? data.kintin.status;
                          data.kintin.statusChangeReason = null;

                          if (data.kintin.status?.value === "closed") {
                            data.disableUserAccount = true;
                            data.removeUserBrandedKinvaults = true;
                            data.voidOutstandingOrders = true;
                          } else {
                            data.disableUserAccount = false;
                            data.removeUserBrandedKinvaults = false;
                            data.voidOutstandingOrders = false;
                          }

                          controls.incrementFormRenderKey();
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          calendar: [
            FormField({
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.nextAction(kintin),
                label: "Next Action Due",
                class: "mb-4",
              },
              slots: {
                default: [
                  FormCalendar({
                    props: {
                      isWidget: true,
                      isDatePicker: true,
                      showSuggestions: true,
                      reference: "nextActionAt",
                    },
                    models: {
                      value: {
                        get: ({ kintin }) => kintin.referral?.nextActionAt,
                        set: (value, { kintin }) => {
                          if (!kintin.referral) {
                            return;
                          }

                          kintin.referral.nextActionAt = value;
                        },
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          options: [
            FormTextField({
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.nextAction(kintin),
                label: "Reason for Status Change:",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
            OptionsSelectField({
              group: "qualifiedOut",
              vModel: {
                get: ({ kintin }) =>
                  qualifiedOut.find(
                    (o) => o.text === kintin.statusChangeReason,
                  ),
                set: (value, { kintin }) =>
                  (kintin.statusChangeReason = value?.text ?? null),
              },
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.closedReason(kintin),
                reference: "statusChangeReason",
                class: "mb-4",
                placeholder: "-- Please select an answer --",
              },
            }),
            FormSelectField({
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.closedReason(kintin),
                label: "Disable User Accounts?",
                options: {
                  "0": "No, I'll do this manually",
                  "1": "Yes, disable attached user accounts",
                },
                reference: "disableUserAccount",
                message: "Prevent users from logging in",
                class: "mb-4",
                placeholder: "-- Please select an answer --",
              },
              models: {
                value: {
                  get: ({ disableUserAccount }) =>
                    disableUserAccount ? "1" : "0",
                  set: (value, data) =>
                    (data.disableUserAccount = value === "1"),
                },
              },
            }),
            FormSelectField({
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.closedReason(kintin),
                label: "Remove User Branded Kinvaults?",
                options: {
                  "0": "No, I'll do this manually",
                  "1": "Yes, remove any existing user branded kinvaults",
                },
                reference: "removeUserBrandedKinvautls",
                message:
                  "Remove any user branded kinvaults (i.e IFA access): Non reversible",
                class: "mb-4",
                placeholder: "-- Please select an answer --",
              },
              models: {
                value: {
                  get: ({
                    removeUserBrandedKinvaults: removeUserBrandedKinvaults,
                  }) => (removeUserBrandedKinvaults ? "1" : "0"),
                  set: (value, data) =>
                    (data.removeUserBrandedKinvaults = value === "1"),
                },
              },
            }),
            FormSelectField({
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.closedReason(kintin),
                label: "Void Outstanding Orders?",
                options: {
                  "0": "No, I'll do this manually",
                  "1": "Yes, void any existing orders",
                },
                reference: "voidOutstandingOrders",
                message: "Remove any new or pending orders",
                class: "mb-4",
                placeholder: "-- Please select an answer --",
              },
              models: {
                value: {
                  get: ({ voidOutstandingOrders }) =>
                    voidOutstandingOrders ? "1" : "0",
                  set: (value, data) =>
                    (data.voidOutstandingOrders = value === "1"),
                },
              },
            }),
            FormTextField({
              props: {
                vIf: ({ kintin }) => ShowOptionsMatrix.closedReason(kintin),
                isTextArea: true,
                label: "Any more information?",
                placeholder: "Message Here",
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
