import {
  Between,
  Equal,
  In,
  LessThan,
  Like,
  MoreThanOrEqual,
  Operator,
} from "@kinherit/orm";
import { Address, Api, CpdSession, ICpdSession, Website } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

export interface ReadCpdSessionMessage {
  title?: string;
  period?: "any" | "forthcoming" | "recent" | null;
  type?: Array<string>;
  isMatched?: null | boolean;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof ICpdSession;
    direction: "asc" | "desc";
  };
}

interface ReadCpdSessionResponse {
  cpdSessions: Array<CpdSession>;
  websites: Array<Website>;
  addresses: Array<Address>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadCpdSessionHandler = async (
  message: ReadCpdSessionMessage,
): Promise<ReadCpdSessionResponse> => {
  let date: undefined | Operator;

  if (message.period === "forthcoming") {
    date = MoreThanOrEqual(new DateTime().timestamp);
  } else if (message.period === "recent") {
    date = LessThan(new DateTime().timestamp);
  }

  const request = Api.resource("portal", "/v2/portal/cpd-session")
    .method("get")

    .paginate(message.pagination ?? false)
    .sort({
      sortBy: message.sort.by,
      sortDirection: message.sort.direction,
    });

  request.buildQuery(CpdSession).where({
    title: Like(message.title),
    createdAt: Between(message.created),
    type: {
      id: In(message.type),
    },
    unmachedRegistrationCount:
      message.isMatched === null
        ? undefined
        : message.isMatched
          ? Equal(0)
          : MoreThanOrEqual(1),
    date,
  });

  const response = await request.result();

  return {
    cpdSessions: CpdSession.$inflate(
      response.cpdSession,
      message.sort,
      response.$rootIds,
    ),
    websites: Website.$inflate(response.website),
    addresses: Address.$inflate(response.address),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
