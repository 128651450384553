import { Api } from "@/service/api.service";
import { IsNotNull } from "@kinherit/orm";
import { IMfAuthMethod, MfAuthMethod, User } from "@kinherit/sdk";

export interface ReadUserMfAuthMethodsMessage {
  user: User;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IMfAuthMethod;
    direction: "asc" | "desc";
  };
}

interface ReadUserMfAuthMethodsResponse {
  mfAuthMethods: Array<MfAuthMethod>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadUserMfAuthMethodsHandler = async (
  message: ReadUserMfAuthMethodsMessage,
): Promise<ReadUserMfAuthMethodsResponse> => {
  const credential = message.user.credential;

  const request = Api.resource(
    "portal",
    "/v2/portal/user/{credential}/mf-auth-methods",
    {
      credential,
    },
  )
    .method("get")
    .sort(message.sort)
    .paginate(message.pagination);
  request.buildQuery(MfAuthMethod).where({
    setupCompleteAt: IsNotNull(),
  });

  const response = await request.result();

  return {
    mfAuthMethods: MfAuthMethod.$inflate(response.mfAuthMethod),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
