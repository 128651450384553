import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsSummaryRoute =
  "IntroducerContactDetailsSummary";
export const IntroducerContactDetailsSummaryURI =
  "/introducer/contact/:introducerContact";
export type IntroducerContactDetailsSummaryParams = RouteParamObject<
  typeof IntroducerContactDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsSummaryRoute,
    path: IntroducerContactDetailsSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsSummary" */ "./IntroducerContactDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
      ],
    },
  },
];
