import { Api, Order } from "@kinherit/sdk";
export interface DeleteOrdersMessage {
  order: Order;
}

export interface DeleteOrdersResponse {
  [key: string]: never;
}

export const DeleteOrderHandler = async (
  message: DeleteOrdersMessage,
): Promise<DeleteOrdersResponse> => {
  await Api.resource("portal", "/v2/portal/order/{order}", {
    order: message.order.id,
  })
    .method("delete")
    .result();

  message.order.$delete();

  return {};
};
