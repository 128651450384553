import { SharedFormProps } from "@/config/form.config";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Website } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { Uuid } from "@kinherit/ts-common/service/uuid";

export const UpdateWebsiteForm = ({
  data,
  isNew,
}: {
  data?: Website;
  isNew?: boolean;
} = {}) => {
  isNew = undefined === isNew ? !data : isNew;

  if (!data) {
    data = Website.$create({
      id: Uuid.generate(),
      url: "",
      createdAt: new DateTime().formatMachine,
    });
  }

  return defineForm({
    name: "update-website-form",
    props: SharedFormProps,
    data: () => data as Website,
    formAreas: (data) => [
      defineFormArea({
        name: "update-website-form-area",
        data,
        template: !isNew
          ? GridLayout(["warning", "url"])
          : GridLayout([["url"]]),
        components: () => ({
          warning: [
            FormMessage({
              props: {
                vIf: !isNew,
                color: "is-warning",
                title: "Warning",
                text: "Changes to this website will be propagated throughout the system.",
              },
            }),
          ],
          url: [
            FormUrlField({
              props: {
                label: "URL",
                validators: ["required"],
                reference: "url",
              },
              models: {
                value: "url",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
