import { LeadDetailsUploadFormsBreadCrumb } from "@/module/lead/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const LeadDetailsUploadFormRoute = "LeadDetailsUploadForm";
export const LeadDetailsUploadFormURI = "/lead/:lead/documents/upload-forms";
export type LeadDetailsUploadFormParams = RouteParamObject<
  typeof LeadDetailsUploadFormURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsUploadFormRoute,
    path: LeadDetailsUploadFormURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsUploadForm" */ "./LeadDetails.UploadForms.vue"
        ),
    },
    meta: {
      breadcrumbs: LeadDetailsUploadFormsBreadCrumb,
    },
  },
];
