import { Api } from "@/service/api.service";
import { EmailAddress, Profile, QueryMask, User } from "@kinherit/sdk";

export interface ReadUserMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  query?: QueryMask<typeof User>;
}

interface ReadUserResponse {
  users: Array<User>;
  profiles: Array<Profile>;
  emailAddresses: Array<EmailAddress>;
}

export const ReadUserHandler = async (
  message: ReadUserMessage,
): Promise<ReadUserResponse> => {
  const request = Api.resource("core", "/v2/core/select/user")
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(User).where(message.query ?? {});

  const response = await request.result();

  return {
    users: User.$inflate(response.user, undefined, response.$rootIds),
    profiles: Profile.$inflate(response.profile),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
  };
};
