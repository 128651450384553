<template>
  <strong>Absolute Residuary Gift</strong>
  <p>
    Subject to the trusts declared above my Executors shall hold my Estate upon
    trust for the trustees of the trust as defined in
    <strong>
      Schedule
      <span
        v-html="
          helpers.var(helpers.getSchedulePosition(sections.ids.ScheduleIIP))
        "
      />
    </strong>
    of this my Will to hold under the terms of the said trust
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
