import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  TagDetailsBreadCrumb,
  TagDetailsCreateBreadCrumb,
  TagDetailsUpdateBreadCrumb,
  TagMasterListBreadCrumb,
} from "../breadcrumbs";

/** Tag/MasterList */
export const TagMasterListRoute = "TagMasterList";

/** Tag/Details */
export const TagDetailsRoute = "TagDetails";
export const TagDetailsURI = "/admin/tag/:tag";
export type TagDetailsParams = RouteParamObject<typeof TagDetailsURI>;

/** Tag/Edit */
export const TagUpdateRoute = "TagUpdate";
export const TagUpdateURI = "/admin/tag/:tag/edit";
export type TagUpdateParams = RouteParamObject<typeof TagUpdateURI>;

/** Tag/Create */
export const TagCreateRoute = "TagCreate";
export const TagCreateURI = "/admin/tag/create";
export type TagCreateParams = RouteParamObject<typeof TagCreateURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: TagMasterListRoute,
    path: "/admin/tags",
    component: () =>
      import(/* webpackChunkName: "TagMasterList" */ "./TagMasterList.vue"),
    meta: {
      breadcrumbs: () => [TagMasterListBreadCrumb],
    },
  },
  {
    name: TagDetailsRoute,
    path: TagDetailsURI,
    component: () =>
      import(/* webpackChunkName: "TagDetails" */ "./TagDetails.vue"),
    meta: {
      breadcrumbs: TagDetailsBreadCrumb,
    },
  },
  {
    name: TagUpdateRoute,
    path: TagUpdateURI,
    component: () =>
      import(/* webpackChunkName: "TagUpdate" */ "./TagUpdate.vue"),
    meta: {
      breadcrumbs: TagDetailsUpdateBreadCrumb,
    },
  },
  {
    name: TagCreateRoute,
    path: TagCreateURI,
    component: () =>
      import(/* webpackChunkName: "TagUpdate" */ "./TagUpdate.vue"),
    meta: {
      breadcrumbs: () => TagDetailsCreateBreadCrumb,
    },
  },
];
