import {
  AdminBrandedKinvaultDetailsInvitationBreadCrumb,
  AdminBrandedKinvaultDetailsInvitationBulkUploadBreadCrumb,
  AdminBrandedKinvaultDetailsInvitationsBillingBreadCrumb,
} from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const AdminBrandedKinvaultDetailsInvitationBulkUploadRoute =
  "AdminBrandedKinvaultDetailsInvitationBulkUpload";
const AdminBrandedKinvaultDetailsInvitationBulkUploadURL =
  "/admin/branded-kinvault/branded-kinvault-invitations";
export type AdminBrandedKinvaultDetailsInvitationBulkUploadParams =
  RouteParamObject<typeof AdminBrandedKinvaultDetailsInvitationBulkUploadURL>;

export const AdminBrandedKinvaultDetailsInvitationsRoute =
  "AdminBrandedKinvaultDetailsInvitations";
const AdminBrandedKinvaultDetailsInvitationsURL =
  "/admin/branded-kinvault/branded-kinvault-invitations";
export type AdminBrandedKinvaultDetailsInvitationsParams = RouteParamObject<
  typeof AdminBrandedKinvaultDetailsInvitationsURL
>;

export const AdminBrandedKinvaultDetailsInvitationsBillingRoute =
  "AdminBrandedKinvaultDetailsInvitationsBilling";
const AdminBrandedKinvaultDetailsInvitationsBillingURL =
  "/admin/branded-kinvault/:brandedKinvault/invitation/billing";
export type BrandedKinvaultDetailsInvitationsBillingParams = RouteParamObject<
  typeof AdminBrandedKinvaultDetailsInvitationsBillingURL
>;

export const AdminBrandedKinvaultDetailsInvitationsIndexRoute =
  "AdminBrandedKinvaultDetailsInvitationsIndexRoute";
export const AdminBrandedKinvaultDetailsInvitationsIndexURL =
  "/admin/branded-kinvault/:brandedKinvault/invitations";

export const Routes: Array<RouteInterface> = [
  {
    name: AdminBrandedKinvaultDetailsInvitationsIndexRoute,
    path: AdminBrandedKinvaultDetailsInvitationsIndexURL,
    component: () => import("./Index.vue"),
    redirect: {
      name: AdminBrandedKinvaultDetailsInvitationsRoute,
    },
    children: [
      {
        name: AdminBrandedKinvaultDetailsInvitationsRoute,
        path: "/admin/branded-kinvault/:brandedKinvault/invitations",
        component: () =>
          import(
            /* webpackChunkName: "BrandedKinvaultDetails.Invitations" */ "./BrandedKinvaultDetails.Invitations.vue"
          ),
        meta: {
          breadcrumbs: AdminBrandedKinvaultDetailsInvitationBreadCrumb,
        },
      },
      {
        name: AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
        path: "/admin/branded-kinvault/:brandedKinvault/invitations/upload",
        component: () =>
          import(
            /* webpackChunkName: "BrandedKinvaultDetails.BulkUpload" */ "./BrandedKinvaultDetails.BulkUpload.vue"
          ),
        meta: {
          breadcrumbs:
            AdminBrandedKinvaultDetailsInvitationBulkUploadBreadCrumb,
        },
      },
      {
        name: AdminBrandedKinvaultDetailsInvitationsBillingRoute,
        path: AdminBrandedKinvaultDetailsInvitationsBillingURL,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "BrandedKinvaultDetailsInvitationBilling" */ "./BrandedKinvaultDetails.InviteBilling.vue"
            ),
        },
        meta: {
          breadcrumbs: AdminBrandedKinvaultDetailsInvitationsBillingBreadCrumb,
        },
      },
    ],
  },
];
