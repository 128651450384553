<template>
  <span v-if="data">
    <strong> Definition and Administration of my Estate </strong>
    <ol type="a">
      <li>
        In this my Will where the context so admits my Estate shall mean:
        <ol type="i">
          <li>
            all my real and personal property of every kind wherever situate
            <span v-if="data.hasAssetsOutsideUK" class="is-will-builder-inject"
              >but excluding that in [COUNTRY]</span
            >
            including that over which I have a general power of appointment and
          </li>
          <li>
            the money investments and property from time to time representing
            all such property
          </li>
        </ol>
      </li>
      <li>
        My Executors shall hold my Estate upon trust
        <ol type="i">
          <li>
            to pay and discharge all my debts funeral testamentary and
            administration expenses and
          </li>
          <li>to give effect to all legacies</li>
        </ol>
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
