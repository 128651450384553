import { Api } from "@/service/api.service";
import { IXeroContact, QueryMask, XeroContact } from "@kinherit/sdk";

export interface ReadXeroContactMessage {
  query?: QueryMask<typeof XeroContact>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IXeroContact;
    direction: "asc" | "desc";
  };
}

interface ReadXeroContactResponse {
  xeroContacts: Array<XeroContact>;
}

export const ReadXeroContactHandler = async (
  message: ReadXeroContactMessage,
): Promise<ReadXeroContactResponse> => {
  const request = Api.resource("core", "/v2/core/select/xero-contact")
    .method("get")
    .sort({
      sortBy: message.sort?.by ?? "contactName",
      sortDirection: message.sort?.direction ?? "asc",
    })
    .paginate(message.pagination ?? false);

  request.buildQuery(XeroContact).where(message.query ?? {});

  const response = await request.result();

  return {
    xeroContacts: XeroContact.$inflate(
      response.xeroContact,
      message.sort,
      response.$rootIds,
    ),
  };
};
