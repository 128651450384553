import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailAddress,
  IEmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
  Profile,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IEmailLog;
    direction: "asc" | "desc";
  };
};

type Response = {
  emailAddresses: Array<EmailAddress>;
  profiles: Array<Profile>;
  people: Array<Person>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
};

export const ReadEmailLogEmailAddressHandler = async (
  message: Message,
): Promise<Response> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer-company-email-log/{owner}/email-address",
    [IntroducerContact.$name]:
      "/v2/portal/introducer-contact-email-log/{owner}/email-address",
    [Kintin.$name]: "/v2/portal/kintin-email-log/{owner}/email-address",
    [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-address",
    [Person.$name]: "/v2/portal/officer-email-log/{owner}/email-address",
    [BrandedKinvault.$name]:
      "/v2/portal/branded-kinvault-email-log/{owner}/email-address",
  } as const;

  const route = routes[message.owner.$name];

  const request = Api.resource("portal", route, {
    owner: message.owner,
  })
    .method("get")
    .paginate(message.pagination ?? false)

    .sort(message.sort ?? false);

  const response = await request.result();

  return {
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    profiles: Profile.$inflate(response.profile),
    people: Person.$inflate(response.person),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
