import { Api } from "@/service/api.service";
import {
  Address,
  BrandedKinvault,
  EmailAddress,
  PhoneNumber,
  Profile,
  Website,
  XeroContact,
} from "@kinherit/sdk";

export interface RecordBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
}

interface RecordBrandedKinvaultResponse {
  brandedKinvaults: Array<BrandedKinvault>;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
  xeroContact: Array<XeroContact>;
}

export const RecordBrandedKinvaultHandler = async (
  message: RecordBrandedKinvaultMessage,
): Promise<RecordBrandedKinvaultResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/{brandedKinvault}",
    message,
  )
    .method("get")

    .result();

  return {
    brandedKinvaults: BrandedKinvault.$inflate(
      response.brandedKinvault,
      undefined,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
    addresses: Address.$inflate(response.address),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    websites: Website.$inflate(response.website),
    xeroContact: XeroContact.$inflate(response.xeroContact),
  };
};
