<template>
  <Card>
    <h4 class="is-underlined">
      <strong>{{ person.profile.fullName }}</strong>
    </h4>
    <em v-if="!hasContactDetails"> [No contact details available] </em>
    <strong> {{ person.kintinPersonType?.ucFirst() }} Email Addresses: </strong>
    <ul v-if="showEmailAddresses">
      <li v-for="email in person.profile.emails" :key="email.id">
        <code>
          {{ email.email }}
        </code>
      </li>
      <li v-if="person.profile.emails.isEmpty()">
        <em> [No email addresses available] </em>
      </li>
    </ul>
    <strong>
      {{ person.kintinPersonType?.ucFirst() }} Telephone Numbers:
    </strong>
    <ul v-if="showPhoneNumbers">
      <li v-for="phone in person.profile.phoneNumbers" :key="phone.id">
        <code>
          {{ phone.tel }}
        </code>
      </li>
      <li v-if="person.profile.phoneNumbers.isEmpty()">
        <em> [No telephone numbers available] </em>
      </li>
    </ul>
    <strong> {{ person.kintinPersonType?.ucFirst() }} Addresses: </strong>
    <ul v-if="showAddresses">
      <li
        v-for="address in person.profile.addresses.filter(
          (a) => a.primaryResidential === true,
        )"
        :key="address.id"
      >
        1st Line: <code>{{ address.line1 }}</code>
        <br />
        Postcode: <code>{{ address.postcode }}</code>
      </li>
      <li v-if="person.profile.addresses.isEmpty()">
        <em> [No addresses available] </em>
      </li>
    </ul>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CallScriptPersonDetails",
  props: {
    person: {
      type: Person,
      default: null,
    },
    hideEmpty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasContactDetails(): boolean {
      return (
        this.person?.profile?.emails.isNotEmpty() ||
        this.person?.profile?.phoneNumbers.isNotEmpty() ||
        this.person?.profile?.addresses.isNotEmpty()
      );
    },
    showEmailAddresses(): boolean {
      return !(this.person?.profile?.emails.isEmpty() && this.hideEmpty);
    },
    showPhoneNumbers(): boolean {
      return !(this.person?.profile?.phoneNumbers.isEmpty() && this.hideEmpty);
    },
    showAddresses(): boolean {
      return !(this.person?.profile?.addresses.isEmpty() && this.hideEmpty);
    },
  },
  components: { Card },
});
</script>
