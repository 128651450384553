import { Api } from "@/service/api.service";
import { IntroducerContract } from "@kinherit/sdk";

interface UpdateIntroducerCompanyIntroducerContractMessage {
  introducerContract: IntroducerContract;
}

interface UpdateIntroducerCompanyIntroducerContractResponse {
  introducerContract: IntroducerContract;
}

export const UpdateIntroducerCompanyIntroducerContractHandler = async (
  message: UpdateIntroducerCompanyIntroducerContractMessage,
): Promise<UpdateIntroducerCompanyIntroducerContractResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-contract/{introducerCompany}/contracts/{introducerContract}",
    {
      introducerCompany: message.introducerContract.$data.company,
      introducerContract: message.introducerContract.$data.id,
    },
  )
    .method("patch")
    .body({
      ai_5a_months: message.introducerContract.ai_5a_months,
      ai_5a_percent: message.introducerContract.ai_5a_percent,
      ai_5b_months: message.introducerContract.ai_5b_months,
      ai_5b_percent: message.introducerContract.ai_5b_percent,
      ai_5c_months: message.introducerContract.ai_5c_months,
      ai_5c_percent: message.introducerContract.ai_5c_percent,
      company: message.introducerContract.$data.company,
      datedAt: message.introducerContract.$data.datedAt ?? undefined,
      datedTill: message.introducerContract.$data.datedTill ?? undefined,
      i_4c_months: message.introducerContract.i_4c_months,
      i_4c_percent: message.introducerContract.i_4c_percent,
      isSigned: message.introducerContract.$data.isSigned,
      notes: message.introducerContract.$data.notes,
      parentContract: message.introducerContract.$data.parentContract,
      prePaidClients: message.introducerContract.prePaidClients,
      sentAt: message.introducerContract.$data.sentAt ?? undefined,
      type: message.introducerContract.$data.type,
      vat: message.introducerContract.$data.vat,
    })

    .result();

  const introducerContract = IntroducerContract.$inflate(
    response.introducerContract,
  ).first();

  if (!introducerContract) {
    throw new Error("IntroducerContract not found");
  }

  return {
    introducerContract,
  };
};
