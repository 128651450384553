import { Api } from "@/service/api.service";
import { IOrder, Order, QueryMask } from "@kinherit/sdk";

export interface ReadOrderMessage {
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IOrder;
    direction: "asc" | "desc";
  };
  query?: QueryMask<typeof Order>;
}

interface ReadOrderResponse {
  orders: Array<Order>;
}

export const ReadOrderHandler = async (
  message: ReadOrderMessage,
): Promise<ReadOrderResponse> => {
  const request = Api.resource("core", "/v2/core/select/order")
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "asc",
    })
    .paginate(message.pagination ?? false);

  request.buildQuery(Order).where(message.query ?? {});

  const response = await request.result();

  return {
    orders: Order.$inflate(response.order, message.sort, response.$rootIds),
  };
};
