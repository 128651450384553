import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsEmailsBreadCrumb } from "../../../breadcrumbs";

export const KintinDetailsEmailLogRoute = "KintinDetailsEmailLog";
export const KintinDetailsEmailLogURI = "/kinvault/:kintin/email-log";
export type KintinDetailsEmailLogParams = RouteParamObject<
  typeof KintinDetailsEmailLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsEmailLogRoute,
    path: KintinDetailsEmailLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsEmailLog" */ "./KintinDetails.EmailLog.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsEmailsBreadCrumb,
    },
  },
];
