import { Api } from "@/service/api.service";
import { FileLog } from "@kinherit/sdk";

interface DeleteKintinFileMessage {
  fileLog: FileLog;
}

interface DeleteKintinFileResponse {
  [key: string]: never;
}

export const DeleteKintinFileHandler = async (
  message: DeleteKintinFileMessage,
): Promise<DeleteKintinFileResponse> => {
  const kintin = message.fileLog.$data.kintin;

  if (!kintin) {
    throw new Error("Kintin is required");
  }

  await Api.resource(
    "portal",
    "/v2/portal/kintin-file/{kintin}/files/{fileLog}",
    {
      kintin,
      fileLog: message.fileLog,
    },
  )
    .method("delete")

    .result();

  message.fileLog.$delete();

  return {};
};
