<template>
  <div class="post-payment-merge-contacts">
    <PersonChangesSummary
      v-for="person in $data.people"
      :person="person"
      :key="person.id"
      @merge="reload"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsPostPaymentMergeContactsWrapper",
  "route": "KinvaultKintinDetailsPostPaymentMergeContacts",
  "selector": ".post-payment-merge-contacts"
}
</cypress-wrapper>

<script lang="ts">
import { Person, SignDoc } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  KinvaultKintinDetailsPostPaymentMergeContactsParams,
  KinvaultKintinDetailsPostPaymentMergeContactsRoute,
} from ".";
import PersonChangesSummary from "../../../../component/PersonChangesSummary.vue";

export default defineComponent({
  name: KinvaultKintinDetailsPostPaymentMergeContactsRoute,
  components: { PersonChangesSummary },
  async mounted(): Promise<void> {
    await this.reload();
  },
  data: () => ({
    signDoc: null as SignDoc | null,
    people: [] as Person[],
  }),
  computed: {
    $params(): KinvaultKintinDetailsPostPaymentMergeContactsParams {
      return this.$route
        .params as KinvaultKintinDetailsPostPaymentMergeContactsParams;
    },
  },
  methods: {
    async reload(): Promise<void> {
      // const { signDocs, people } = await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/sign-docs/contacts/record",
      //   {
      //     signDoc: this.$params.signDoc,
      //     kintin: this.$params.kintin,
      //   },
      // );
      const { signDocs, people } =
        await window.Kernel.ActionBus.kinvaultKintin.signDoc.record({
          signDoc: this.$params.signDoc,
          kintin: this.$params.kintin,
        });

      this.signDoc = signDocs.first() ?? null;
      this.people = people;
    },
  },
});
</script>
