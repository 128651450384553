import { ReadUserMessage } from "@/module/admin.user/action/read-user.action";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  IsNull,
  Like,
} from "@kinherit/orm/index";
import { Api, Credential, IUser, Profile, Role, User } from "@kinherit/sdk";

export interface ReadStaffUserMessage {
  search?: null | string;
  role?: Array<string>;
  created?: null | [number, number];
  displayUserInPortal?: boolean;
  acuityUser?: boolean;
  brandedKinvault?: Array<string>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IUser;
    direction: "asc" | "desc";
  };
}

interface ReadStaffUserResponse {
  users: Array<User>;
  profiles: Array<Profile>;
  credentials: Array<Credential>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadStaffUserHandler = async (
  message: ReadUserMessage,
): Promise<ReadStaffUserResponse> => {
  const roles = Role.$findBy({
    role: In(["staff", "admin"]),
  }).pluck("id");

  console.log(roles);
  const request = Api.resource("portal", "/v2/portal/user")
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "desc",
    })
    .paginate(message.pagination ?? false);

  request.buildQuery(User).where({
    profile: {
      fullName: Like(message.search),
    },
    roles: {
      id: In(roles),
    },
    displayUserInPortal: Equal(
      undefined === message.displayUserInPortal
        ? undefined
        : message.displayUserInPortal
          ? 1
          : 0,
    ),
    createdAt: Between(message.created),
    acuityUserId:
      undefined !== message.acuityUser
        ? message.acuityUser
          ? IsNotNull()
          : IsNull()
        : undefined,
    brandedKinvaultMemberships: {
      brandedKinvault: {
        id: In(message.brandedKinvault),
      },
    },
  });

  const response = await request.result();

  return {
    users: User.$inflate(response.user, message.sort, response.$rootIds),
    profiles: Profile.$inflate(response.profile),
    credentials: Credential.$inflate(response.credential),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
