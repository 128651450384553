import { Between, In, Like } from "@kinherit/orm/index";
import {
  Api,
  EmailAddress,
  IIntroducerContact,
  IntroducerCompany,
  IntroducerContact,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerContactMessage {
  search?: string;
  tags?: Array<string>;
  status?: Array<string>;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IIntroducerContact;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerContactResponse {
  introducerContacts: Array<IntroducerContact>;
  introducerCompanies: Array<IntroducerCompany>;
  emailAddresses: Array<EmailAddress>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerContactHandler = async (
  message: ReadIntroducerContactMessage,
): Promise<ReadIntroducerContactResponse> => {
  const request = Api.resource("portal", "/v2/portal/introducer-contact")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(IntroducerContact).where([
    {
      profile: {
        fullName: Like(message.search),
      },
      tags: {
        id: In(message.tags),
      },
      status: {
        id: In(message.status),
      },
      createdAt: Between(message.created),
    },
    {
      profile: {
        emails: {
          email: Like(message.search),
        },
      },
      tags: {
        id: In(message.tags),
      },
      status: {
        id: In(message.status),
      },
      createdAt: Between(message.created),
    },
  ]);

  const response = await request.result();

  return {
    introducerContacts: IntroducerContact.$inflate(
      response.introducerContact,
      message.sort,
      response.$rootIds,
    ),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    profiles: Profile.$inflate(response.profile),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
