<template>
  <CallScriptSection v-if="kintin" title="Kintin Type">
    <b>Can I just double check:</b>
    <ConfirmCheck
      @update="updateCheckValue"
      v-if="!isJoint"
      :kintin="kintin"
      step="2.4.1a"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :on="{
        no: {
          flag: [
            'Check if we need to restart the process with person 2 in mind and change the Kintin Type',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      v-if="!isJoint"
      :kintin="kintin"
      step="2.4.1e"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
    />
    <ConfirmCheck
      @update="updateCheckValue"
      v-if="isJoint"
      :kintin="kintin"
      step="2.4.1b"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :on="{
        no: {
          flag: [
            'Check if we need to restart the process with person 2 in mind and change the Kintin Type',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      v-if="isJoint"
      :kintin="kintin"
      step="2.4.1c"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
    />
    <ConfirmCheck
      @update="updateCheckValue"
      v-if="checkIsNo('2.4.1c')"
      :kintin="kintin"
      step="2.4.1e"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
    />
    <br />
    <b>
      Brilliant, I'm going to take some details on you, before we move onto your
      family.
    </b>
  </CallScriptSection>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountTypeTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
    ConfirmCheck,
  },
});
</script>
