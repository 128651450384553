<template>
  <p v-if="data">
    <strong> Funds for Education or Medical Treatment </strong><br />
    If any Beneficiary to whom you are considering appointing a share of the
    Trust Fund
    <span v-if="data.allowFundsForEducation">
      is either in education or training
    </span>
    <span v-if="data.allowFundsForMedical">
      or requires urgent medical treatment,
    </span>
    I would like you to consider funding this
    <span
      v-html="helpers.var(['Medical Treatment', 'Education'].join(' or '))"
    />
    from the Trust Fund.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
