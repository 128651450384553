<template>
  <CallScriptSection title="Process">
    <b>
      Just a little about us. We are pioneers of what we call Intelligent Will
      planning, which is the art of planning ‘beyond the Will’.
      <br /><br />
      All our ‘Intelligent Wills’, come with something we call a Kinvault, and a
      handover process. This makes a massive difference – making the process
      when needed - faster, cheaper and less distressing, so it really is not
      comparable to anyone else’s Will.
      <br /><br />
      But for today, our focus is just the legal bit. And to get that right we
      take it step by step, with a load of questions for you, and it usually
      takes about 40 minutes – is that ok?
    </b>
    <br /><br />
    <em>[PAUSE]</em>
    <br /><br />
    <b>
      Perfect, after today’s call I’ll email you a written report on what we’ve
      talked about, and with my recommendations, which we’ll discuss on a
      follow-up call.
    </b>
    <br /><br />
    <em>[PAUSE]</em> <br /><br />
    <b>
      Normally, at the end of the follow-up call, you will be clear on what
      documents you want to deliver your plan – these can include Wills, Trusts,
      Powers of Attorney for example – and we can then move to payment. But if
      you are not ready that isn’t a problem either.
      <br /><br />
      A quick word on cost - our prices are all on our website, and will also be
      in the recommendations report I‘ll send today. So to be clear there is no
      charge for today or the report or follow-up, and you only pay if you want
      us to create documents for you.
    </b>
    <br />
    <br /><em>[PAUSE]</em> <br /><br />
    <b>
      Once you are ready and have paid, it is a simple process to check the
      information that we then use to produce your legal documents. We’ll also
      help you through signing, and have options on storage that sort of thing.
      <br /><br />
      Do you have any questions for me about the process?
    </b>
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProcessTab",
  components: { CallScriptSection },
});
</script>
