import {
  OpenDangerSnackBar,
  OpenSuccessSnackBar,
} from "@kinherit/framework/global/snack-bar";
import { defineComponent } from "vue";

export class SnackBarService {
  public static get mixin() {
    return defineComponent({
      name: "SnackBarMixin",
      computed: {
        $snackBar: () =>
          SnackBarService as {
            errors: typeof SnackBarService.errors;
            success: typeof SnackBarService.success;
          },
      },
    });
  }

  public static errors = {
    mustBeLoggedIn: () =>
      OpenDangerSnackBar({
        text: "You must be logged in to access this page.",
      }),
    mustBeAuthenticated: () =>
      OpenDangerSnackBar({
        text: "You must authenticate to access this page",
      }),
    notEnoughPermissions: () =>
      OpenDangerSnackBar({
        text: "You do not have permission to access this page",
      }),
    invalidForm: () =>
      OpenDangerSnackBar({
        text: "Please fill out all required fields",
      }),
    generic: (text: string) =>
      OpenDangerSnackBar({
        text,
      }),
  };

  public static success = {
    saved: () =>
      OpenSuccessSnackBar({
        text: "Save complete",
      }),
  };

  public static toast = ({
    text,
    type,
  }: {
    text: string;
    type: "success" | "error";
  }) => {
    if (type === "success") {
      OpenSuccessSnackBar({
        text,
      });
    } else {
      OpenDangerSnackBar({
        text,
      });
    }
  };
}
