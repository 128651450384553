import { Api } from "@/service/api.service";
import { EmailCampaign, Profile } from "@kinherit/sdk";

export interface AddEmailCampaignProfileMessage {
  emailCampaign: EmailCampaign;
  profile: Profile;
}

export interface AddEmailCampaignProfileResponse {
  [key: string]: never;
}

export const AddEmailCampaignProfileHandler = async (
  message: AddEmailCampaignProfileMessage,
): Promise<AddEmailCampaignProfileResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/email-campaign/profile/{emailCampaign}/profiles/{profile}/add",
    message,
  )
    .method("post")

    .result();

  return {};
};
