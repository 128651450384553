import { Api } from "@/service/api.service";
import { Check, ICheck } from "@kinherit/sdk";

export interface ReadCheckMessage {
  sort?: {
    by: keyof ICheck;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadCheckResponse {
  checks: Array<Check>;
}

export const ReadCheckHandler = async (
  message: ReadCheckMessage,
): Promise<ReadCheckResponse> => {
  const response = await Api.resource("core", "/v2/core/select/check")
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "name",
      sortDirection: message.sort?.direction ?? "asc",
    })
    .paginate(message.pagination ?? false)
    .result();

  return {
    checks: Check.$inflate(response.check, message.sort, response.$rootIds),
  };
};
