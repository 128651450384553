import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between } from "@kinherit/orm/index";
import {
  IIntroducerFeePaymentItem,
  IntroducerFeePaymentItem,
} from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentItemMessage {
  createdAt?: [number, number] | null;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerFeePaymentItem;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerFeePaymentItemResponse {
  introducerFeePaymentItems: Array<IntroducerFeePaymentItem>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerFeePaymentItemHandler = async (
  message: ReadIntroducerFeePaymentItemMessage,
): Promise<ReadIntroducerFeePaymentItemResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-item",
  )
    .method("get")
    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerFeePaymentItem).where({
    createdAt: Between(message.createdAt),
  });

  const response = await request.result();

  return {
    introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
      response.introducerFeePaymentItem,
      message.sort,
      response.$rootIds,
    ),
    pagination: getPagination(response),
  };
};
