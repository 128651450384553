import { Api } from "@/service/api.service";
import { Kintin, KintinAccess } from "@kinherit/sdk";

interface CreateKintinAccessMessage {
  kintin: string | Kintin;
  kintinAccess: KintinAccess;
}

interface CreateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export const CreateKintinAccessHandler = async (
  message: CreateKintinAccessMessage,
): Promise<CreateKintinAccessResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/kintin-user-access/{kintin}/assigned-users",
    {
      kintin: message.kintin,
    },
  )
    .method("post")
    .body({
      accessType: message.kintinAccess.$data.accessType,
      allowAccess: message.kintinAccess.$data.allowAccess,
      kintin:
        "string" === typeof message.kintin
          ? message.kintin
          : message.kintin.$data.id,
      user: message.kintinAccess.$data.user,
    })

    .result();

  const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

  if (!kintinAccess) {
    throw new Error("Failed to create kintin check");
  }

  message.kintinAccess.$delete();

  return {
    kintinAccess,
  };
};
