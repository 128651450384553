import { Api, Order } from "@kinherit/sdk";
export interface PushOrderMessage {
  order: Order;
}

export interface PushOrderResponse {
  [key: string]: never;
}

export const PushOrderHandler = async (
  message: PushOrderMessage,
): Promise<PushOrderResponse> => {
  await Api.resource("portal", "/v2/portal/order/{order}/push-to-xero", {
    order: message.order.id,
  })
    .method("put")
    .result();

  return {};
};
