import { Api } from "@/service/api.service";
import { IntroducerFeePayment } from "@kinherit/sdk";
import { IntroducerFeePaymentItem, XeroContact } from "@kinherit/sdk/index";

export interface RecordIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment | string;
}

export interface RecordIntroducerFeePaymentResponse {
  introducerFeePayment: IntroducerFeePayment;
  xeroContact: XeroContact;
  introducerFeePaymentItems: IntroducerFeePaymentItem[];
}

export const RecordIntroducerFeePaymentHandler = async (
  message: RecordIntroducerFeePaymentMessage,
): Promise<RecordIntroducerFeePaymentResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment/{introducerFeePayment}",
    message,
  ).method("get");

  const response = await request.result();

  const introducerFeePayment = IntroducerFeePayment.$inflate(
    response.introducerFeePayment,
  ).first();

  if (!introducerFeePayment) {
    throw new Error("Failed to find introducerFeePayment");
  }

  const xeroContact = XeroContact.$inflate(response.xeroContact).first();

  if (!xeroContact) {
    throw new Error("Failed to find xeroContact");
  }

  return {
    introducerFeePayment,
    xeroContact,
    introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
      response.introducerFeePaymentItem,
    ),
  };
};
