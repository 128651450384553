import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptPropertyAssetsRoute =
  "KintinDetailsCallScriptPropertyAssets";
export const KintinDetailsCallScriptPropertyAssetsURI =
  "/kinvault/:kintin/call-script/property-assets";
export type KintinDetailsCallScriptPropertyAssetsParams = RouteParamObject<
  typeof KintinDetailsCallScriptPropertyAssetsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptPropertyAssetsRoute,
    path: KintinDetailsCallScriptPropertyAssetsURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptPropertyAssets" */ "./KinvaultKintinDetails.CallScript.PropertyAssets.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Property & Assets",
          route: { name: KintinDetailsCallScriptPropertyAssetsRoute, params },
        },
      ],
    },
  },
];
