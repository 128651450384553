import { Api } from "@/service/api.service";
import { KintinCheckFollowUp } from "@kinherit/sdk";

interface DeleteKintinCheckFollowUpMessage {
  kintin: string;
  kintinCheckFollowUp: KintinCheckFollowUp;
}

interface DeleteKintinCheckFollowUpResponse {
  [key: string]: never;
}

export const DeleteKintinCheckFollowUpHandler = async (
  message: DeleteKintinCheckFollowUpMessage,
): Promise<DeleteKintinCheckFollowUpResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-check-follow-up/{kintin}/check-follow-ups/{kintinCheckFollowUp}",
    {
      kintin: message.kintin,
      kintinCheckFollowUp: message.kintinCheckFollowUp.id,
    },
  )
    .method("delete")
    .result();

  message.kintinCheckFollowUp.$delete({
    note: true,
  });

  return {};
};
