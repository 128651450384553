<template>
  <span>
    <strong>General Trust Powers</strong><br />
    <p>
      <span v-if="helpers.getSchedules().length > 1">
        This clause and clause <span v-html="helpers.var(position)" /> inclusive
        of this my Will shall apply to the trusts that are defined in the
        following schedules hereinafter referred to as 'the Trusts':
      </span>
    </p>
    <ol type="i">
      <li v-for="(item, i) in helpers.getSchedules()" :key="i">
        Schedule <span v-html="helpers.var(i + 1)" />
      </li>
    </ol>
    <p>
      Any monies liable to be invested hereunder may be invested in any manner
      the trustees in their absolute discretion think fit. If applicable Section
      32 of the Trustee Act (Northern Ireland) 1958 shall not apply to this
      trust
      <br /><br />
      The trustees may at their discretion lend to any one or more of the
      beneficiaries such amounts on such terms as to interest and security (if
      any) as they think fit
      <br /><br />
      If applicable the statutory powers of advancement contained in section 33
      of the Trustee Act (Northern Ireland) 1958 shall apply to the Trusts with
      the omission in proviso (a) to sub section (1) of the words one-half of
      <br /><br />
      Any trustee for the time being a solicitor or other person engaged in any
      profession or business shall be entitled to charge and be paid all usual
      professional or other charges for business done by him/her in relation to
      the Trusts
      <br /><br />
      The trustees shall have the power to appoint new or additional trustees
      <br /><br />
      The Trust Period shall mean the period ending on the earlier of:
    </p>
    <ol type="i">
      <li>
        The last day of the period of (A) 80 years from the date of my death if
        the respective trust is governed by and subject to the law of Northern
        Ireland or Scotland or (B) 125 years from the date of my death if the
        respective trust is governed and subject to the law of England and Wales
        which period, and no other shall be the applicable perpetuity period or
      </li>
      <li>
        Such date as shall for the time being be specified pursuant to the power
        conferred on the trustees of the respective trust by the clause (iii)
        immediately below
      </li>
      <li>
        The trustees of the trust may at any time during the Trust Period
        specify by deed or deeds in relation to the whole or any part of the
        respective trust property a date for the purposes of sub clause (i)
        above and the date specified shall not be earlier than the date of
        execution of such deed or later than the date on which the applicable
        perpetuity period expires
      </li>
    </ol>
    <p>
      The Residence Nil Rate Amount Period and the Trust Period shall together
      hereinafter be referred to as 'the Trust Periods'
      <br /><br />
      The Standard Provisions and all of the Special Provisions (with the
      exception of 18.2) of the Society of Trust and Estate Practitioners (3rd
      edition) shall apply to the Trusts
    </p>
  </span>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    position(): string {
      return this.helpers
        .getSectionPosition(WillBuilderSections.OverridingPowers)
        .toString();
    },
  },
});
</script>
