import { Api } from "@/service/api.service";
import { AccountReferral } from "@kinherit/sdk";

interface UpdateAccountReferralMessage {
  referral: AccountReferral;
}

interface UpdateAccountReferralResponse {
  referral: AccountReferral;
}

export const UpdateAccountReferralHandler = async (
  message: UpdateAccountReferralMessage,
): Promise<UpdateAccountReferralResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/account-referral/{accountReferral}",
    {
      accountReferral: message.referral,
    },
  )
    .method("patch")

    .body({
      contact: message.referral.$data.contact ?? null,
      nextActionAt: message.referral.$data.nextActionAt ?? null,
      referralCode: message.referral.$data.referralCode ?? null,
    })
    .result();

  const referral = AccountReferral.$inflate(
    response.accountReferral,
    undefined,
    response.$rootIds,
  ).first();

  if (!referral) {
    throw new Error("Failed to update referral code");
  }

  return {
    referral,
  };
};
