import CopyRoles from "@/module/kinvault.kintin/component/CopyRoles.vue";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import {
  Adviser,
  Attorney,
  Beneficiary,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Property,
  Trustee,
} from "@kinherit/sdk";

const FormCopyRoles = FormComponentWrapper(CopyRoles);

export const CopyRolesForm = <
  T extends
    | Adviser[]
    | Attorney[]
    | Beneficiary[]
    | Executor[]
    | Exclusion[]
    | Gift[]
    | Guardian[]
    | Property[]
    | Trustee[],
>(
  roles: T,
  kintin: Kintin,
) =>
  defineForm({
    name: "copy-roles-form",
    data: () => ({
      roles: roles,
      rolesToCopy: [] as never as T,
      kintin: kintin,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "copy-roles-form-area",
        data,
        components: (data) => ({
          default: [
            FormCopyRoles({
              props: {
                roles: data.roles,
                kintin: data.kintin,
              },
              models: {
                ["to-copy"]: "rolesToCopy",
              },
            }),
          ],
        }),
      }),
    ],
  });
