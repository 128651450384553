import { CpdSession } from "@kinherit/sdk";
import { CpdDetailsParams } from "./page/details";

export const CpdSessionsMasterListBreadCrumb = {
  text: "CPD Sessions",
  route: { name: "CpdSessionsMasterList" },
};

export const CpdDetailsBreadCrumb = (params: CpdDetailsParams) => [
  CpdSessionsMasterListBreadCrumb,
  {
    text: CpdSession.$findOne(params.cpdSession)?.title ?? "",
    route: { name: "CpdDetails", params },
  },
];

export const CpdDetailsSummaryBreadCrumb = (params: CpdDetailsParams) => [
  ...CpdDetailsBreadCrumb(params),
];

export const CpdDetailsIntroducersBreadCrumb = (params: CpdDetailsParams) => [
  ...CpdDetailsBreadCrumb(params),
  {
    text: "Introducers",
    route: { name: "CpdDetailsIntroducers", params },
  },
];

export const CpdDetailsRegistrationsBreadCrumb = (params: CpdDetailsParams) => [
  ...CpdDetailsBreadCrumb(params),
  {
    text: "Registrations",
    route: { name: "CpdDetailsRegistrations", params },
  },
];
