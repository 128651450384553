<template>
  <CallScriptSection v-if="kintin" title="Family Situation">
    <b> Now moving onto your family: </b>
    <ConfirmCheck
      @update="
        (step, value) => {
          updateCheckValue(step, value);
          guardianSectionRenderKey++;
        }
      "
      :kintin="kintin"
      step="2.5.0a"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :on="{
        yes: { action: ['Detail each child, with simple name and dob'] },
      }"
    />
    <ConfirmCheck
      @update="
        (step, value) => {
          updateCheckValue(step, value);
          guardianSectionRenderKey++;
        }
      "
      :kintin="kintin"
      step="2.5.0d"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      v-if="checkIsYes('2.5.0a')"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin="kintin"
      step="2.5.0b"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      v-if="checkIsYes('2.5.0a')"
      :on="{
        yes: {
          ask: [
            'We have suggestions on how best to deal with this, which we’ll explain later.',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin="kintin"
      step="2.5.0c"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      v-if="checkIsYes('2.5.0a')"
      :on="{
        yes: {
          ask: [
            'We ask so that we can identify whether the Will can take allowance of things like adult care costs, which can offset Inheritance Tax',
          ],
          action: [
            'Add follow-up note detailing nature of needs, eg physical/mental, age, whether adult care needed, whether getting worse.',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin="kintin"
      step="2.5.2a"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :yes-label="'Yes/Maybe'"
      :on="{
        yes: {
          say: [
            'Just so you are aware, we offer a complimentary rewrite to add new children within 5 years',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin="kintin"
      step="2.5.2b"
      :kintin-checks="kintinChecks"
      :checks="checks"
      is-reversed
      :yes-label="'Yes/Maybe'"
      :on="{
        yes: {
          action: ['Take details as followup note'],
        },
      }"
    />
    <h5 class="title is-emphasised mb-0 mt-6">Family &amp; Others</h5>
    <PersonList :kintin="kintin" :people="$data.people" @reload="loadPeople" />
    <Button
      class="mt-4 mb-4"
      text="Add Person"
      color="is-success"
      @click="() => addRelativeHandler().then(loadPeople)"
    />
    <p>
      <b> And moving onto your wider family: </b>
    </p>
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin="kintin"
      step="2.5.0f"
      :kintin-checks="kintinChecks"
      :checks="checks"
      :on="{
        yes: {
          action: [
            'Note grandchildren names (if 1 or 2) or that there are many',
          ],
          ask: [
            'We ask about grandchildren because people generally want wealth to pass through generations.',
          ],
        },
      }"
    />
    <b>
      Thank you so much for that. Do you have any questions, or anything you’d
      like to mention about your situation before we move on?
    </b>
    <br /><br />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin="kintin"
      step="2.5.1"
      :kintin-checks="kintinChecks"
      :checks="checks"
    />
  </CallScriptSection>
</template>

<script lang="ts">
import { CreatePerson } from "@/config/model.config";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import Button from "@kinherit/framework/component.input/button";
import { NotIn } from "@kinherit/orm/index";
import { Person } from "@kinherit/sdk/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FamilySituationTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
    ConfirmCheck,
    PersonList,
    Button,
  },
  data: () => ({
    guardianSectionRenderKey: 0,
    people: [] as Person[],
    renderKey: 0,
  }),
  mounted() {
    this.loadPeople();
  },
  methods: {
    async addRelativeHandler() {
      if (!this.kintin) {
        return;
      }

      const person = CreatePerson();

      try {
        await UpdatePersonForm({
          person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create person",
          },
        });
      } catch {
        person.$delete({
          profile: true,
          relationToPrimaryPerson: true,
          relationToSecondaryPerson: true,
        });
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/create", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.create({
        person: person,
        kintin: this.kintin,
      });

      this.renderKey++;
    },
    loadPeople() {
      if (!this.kintin) {
        return;
      }

      this.people = Person.$findBy({
        id: NotIn(
          [
            this.kintin.$data.primaryPerson,
            this.kintin.$data.secondaryPerson,
          ].filter(Boolean),
        ),
        kintin: {
          id: this.kintin.id,
        },
      });
    },
  },
});
</script>
