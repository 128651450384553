import { Api, Kintin, Trustee } from "@kinherit/sdk";

interface DeleteTrusteeMessage {
  kintin: string | Kintin;
  trustee: Trustee;
}

interface DeleteTrusteeResponse {
  [key: string]: never;
}

export const DeleteTrusteeHandler = async (
  message: DeleteTrusteeMessage,
): Promise<DeleteTrusteeResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-trustee/{kintin}/person/trustee/{trustee}",
    {
      kintin: message.kintin,
      trustee: message.trustee.$data.id,
    },
  )
    .method("delete")
    .result();

  message.trustee.$delete();

  return {};
};
