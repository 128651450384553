<template>
  <Card
    v-if="computedUser"
    title="Branded Kinvaults"
    subtitle="Manage which Branded Kinvaults this user belongs to"
    class="user-details-branded-kinvaults"
  >
    <Table
      :key="renderKey"
      class="user-branded-kinvaults-table"
      is-narrow
      :columns="columns"
      :rows="brandedKinvaultMemberships"
    >
      <template #actions="{ row }">
        <Button
          @click="editBrandedKinvaultMembership(row, $event)"
          class="edit-button"
          :icon-left="$style.icon.edit.icon"
          ariaLabel="Edit Branded Kinvault Membership"
          size="is-small"
          color="is-plain"
        />
        <Button
          v-if="row.allowAccess"
          icon-left="Cross"
          is-outlined
          color="is-danger"
          @click="toggleAccess(row)"
          text="Revoke"
        />
        <Button
          v-else
          icon-left="Success"
          is-outlined
          color="is-success"
          @click="toggleAccess(row)"
          text="Grant"
        />
        <Button
          icon-left="Trash"
          is-outlined
          color="is-danger"
          @click="deleteAccess(row)"
          text="Delete"
        />
      </template>
    </Table>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsBrandedKinvaultsWrapper",
  "route": "UserDetailsBrandedKinvaults",
  "selector": ".user-details-branded-kinvaults"
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Table } from "@kinherit/framework/component.display/table";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { BrandedKinvaultMembership, User } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import {
  AdminUserDetailsJoinBrandedKinvaultParams,
  AdminUserDetailsJoinBrandedKinvaultRoute,
  UserDetailsBrandedKinvaultsRoute,
} from ".";

export default defineComponent({
  name: UserDetailsBrandedKinvaultsRoute,
  mixins: [
    AuthService.mixin({ sessionRequired: true }),
    UserDetailsMixin,
    StyleService.mixin,
  ],
  components: {
    Card,
    Table,
    Button,
  },
  props: {
    userOverride: {
      type: User as PropType<User | null>,
      default: null,
    },
  },
  data: () => ({
    columns: [
      {
        title: "Branded Kinvault",
        map: (v: BrandedKinvaultMembership) =>
          v.brandedKinvault.profile.organisationName ??
          v.brandedKinvault.profile.fullName ??
          ``,
        sortable: true,
      },
      {
        title: "Joined",
        map: (v: BrandedKinvaultMembership) => v.createdAt.formatDate,
        sortable: true,
      },
      {
        title: "Actions",
        sort: false,
        slot: "actions",
        class: "is-narrow",
      },
    ],
    brandedKinvaultMemberships: Array<BrandedKinvaultMembership>(),
    renderKey: 0,
  }),
  mounted(): void {
    this.load();
  },
  computed: {
    computedUser(): User | null {
      return this.userOverride ?? this.user;
    },
  },
  methods: {
    editBrandedKinvaultMembership(
      brandedKinvaultMembership: BrandedKinvaultMembership,
      event: MouseEvent,
    ): void {
      if (
        !this.$auth.hasPermission("branded-kinvault-membership:write") &&
        !this.$auth.hasPermission("user:write")
      ) {
        return;
      }

      const params: AdminUserDetailsJoinBrandedKinvaultParams = {
        user: this.$params.user,
        brandedKinvaultMembership: brandedKinvaultMembership.id,
      };

      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsJoinBrandedKinvaultRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    load(): void {
      const user = this.computedUser;
      if (!user) {
        return;
      }

      this.brandedKinvaultMemberships = user.brandedKinvaultMemberships.sortBy(
        "createdAt",
        "desc",
      );
      this.renderKey++;
    },
    async toggleAccess(
      brandedKinvaultMembership: BrandedKinvaultMembership,
    ): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `${
            brandedKinvaultMembership.allowAccess ? "Revoke" : "Grant"
          } Access`,
          message: `Are you sure you want to ${
            brandedKinvaultMembership.allowAccess ? "revoke" : "grant"
          } ${
            brandedKinvaultMembership.user.profile.fullName
          } access to this branded kinvault?`,
        },
      });

      brandedKinvaultMembership.allowAccess =
        !brandedKinvaultMembership.allowAccess;

      // await window.Kernel.ActionBus.execute(
      //   "admin/user/branded-kinvault-membership/update",
      //   {
      //     brandedKinvaultMembership,
      //   },
      // );
      await window.Kernel.ActionBus.adminUser.brandedKinvaultMembership.update({
        brandedKinvaultMembership,
      });

      this.load();
      this.renderKey++;
    },
    async deleteAccess(
      brandedKinvaultMembership: BrandedKinvaultMembership,
    ): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Access",
          message: `Are you sure you want to delete ${brandedKinvaultMembership.user.profile.fullName}'s access to this branded kinvault?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/user/branded-kinvault-membership/delete",
      //   {
      //     brandedKinvaultMembership,
      //   },
      // );
      await window.Kernel.ActionBus.adminUser.brandedKinvaultMembership.delete({
        brandedKinvaultMembership,
      });

      this.load();
      this.renderKey++;
    },
  },
});
</script>
