import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { BrandedKinvaultDetailsBreadCrumb } from "../../breadcrumbs";
import { Routes as BrandedKinvaultDetailsActivityRoutes } from "./activity";
import { Routes as BrandedKinvaultDetailsMembersRoutes } from "./members";
import { Routes as BrandedKinvaultDetailsSubscriptionRoutes } from "./subscriptions";

import { Routes as BrandedKinvaultDetailsDefaultReferralRoute } from "./default-referral";
import { Routes as BrandedKinvaultDetailsEmailRoutes } from "./email-log";
import { Routes as BrandedKinvaultDetailsSubscriptionInvitationRoute } from "./invitations";
import {
  BrandedKinvaultDetailsSummaryRoute,
  Routes as BrandedKinvaultDetailsSummaryRoutes,
} from "./summary";
import { Routes as BrandedKinvaultDetailsThemeRoutes } from "./theme";
import { Routes as BrandedKinvaultDetailsUpdateProfileRoute } from "./update-profile";

export const BrandedKinvaultDetailsRoute = "BrandedKinvaultDetails";
export const BrandedKinvaultDetailsURI =
  "/admin/branded-kinvaults/:brandedKinvault";
export type BrandedKinvaultDetailsParams = RouteParamObject<
  typeof BrandedKinvaultDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsRoute,
    path: BrandedKinvaultDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "BrandedKinvaultDetails" */ "./BrandedKinvaultDetails.vue"
      ),
    meta: {
      breadcrumbs: BrandedKinvaultDetailsBreadCrumb,
    },
    children: [
      ...BrandedKinvaultDetailsSummaryRoutes,
      ...BrandedKinvaultDetailsMembersRoutes,
      ...BrandedKinvaultDetailsThemeRoutes,
      ...BrandedKinvaultDetailsActivityRoutes,
      ...BrandedKinvaultDetailsUpdateProfileRoute,
      ...BrandedKinvaultDetailsSubscriptionRoutes,
      ...BrandedKinvaultDetailsSubscriptionInvitationRoute,
      ...BrandedKinvaultDetailsEmailRoutes,
      ...BrandedKinvaultDetailsDefaultReferralRoute,
    ],
    redirect: { name: BrandedKinvaultDetailsSummaryRoute },
  },
];
