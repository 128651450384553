import { OrderField, PersonField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";
import { Kintin } from "@kinherit/sdk";

export const SendOrderPaymentEmailForm = (kintin: Kintin) =>
  defineForm({
    name: "send-order-payment-email",
    data: () => ({
      order: null as null | string,
      person: null as null | string,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "send-order-payment-email",
        data,
        template: GridLayout([["order"], ["person"]]),
        components: () => ({
          order: [
            OrderField({
              simplifyData: true,
              props: {
                vModel: "order",
                validators: ["required"],
              },
              query: {
                kintin: {
                  id: Equal(kintin.id),
                },
              },
            }),
          ],
          person: [
            PersonField({
              props: {
                vModel: "person",
                validators: ["required"],
              },
              query: {
                id: In(
                  [kintin.primaryPerson.id, kintin.secondaryPerson?.id].filter(
                    Boolean,
                  ),
                ),
              },
            }),
          ],
        }),
      }),
    ],
  });
