<template>
  <MOW trust-name="INTEREST IN POSSESSION TRUST" />
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";
import MOW from "../shared/MOW.vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  components: { MOW },
});
</script>
