import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeCashflowReportMessage {
  group: "paidAt" | "createdAt";
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  compareTo: string | null;
  referral: string | null;
  referrerCategory: string | null;
}

interface ComputeCashflowReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    advisedValue: number;
    advisedCount: number;
    hostedValue: number;
    hostedCount: number;
    lpasOnlyValue: number;
    lpasOnlyCount: number;
    outsourcedCount: number;
    outsourcedValue: number;
    sumValue: number;
  }>;
}
export type TCashflowReportResult = {
  year: number;
  month: number;
  week: number;
  quarter: number;
  unpaidValue: number;
  unpaidCount: number;
  advisedValue: number;
  advisedCount: number;
  hostedValue: number;
  hostedCount: number;
  lpasOnlyValue: number;
  lpasOnlyCount: number;
  subscriptionValue: number;
  subscriptionCount: number;
  outsourcedCount: number;
  outsourcedValue: number;
  sumValue: number;
};
export const ComputeCashflowReportHandler = async (
  message: ComputeCashflowReportMessage,
): Promise<ComputeCashflowReportResponse> => {
  const {
    year,
    created,
    granularity,
    assignedUser,
    referral,
    referrerCategory,
    compareTo,
    group,
  } = message || {};

  const calculatedDates = calculateStartEndDates(year, created, compareTo);

  const response = await Api.resource(
    "portal",
    "/v2/portal/report/cashflow",
    {},
  )
    .method("get")
    .params({
      group: group,
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
      assignedUser: assignedUser ?? null,
      referral: referral ?? null,
      referrerCategory: referrerCategory ?? null,
      compareToStartDate: calculatedDates.compareToStartDate,
      compareToEndDate: calculatedDates.compareToEndDate,
    })

    .result();

  const datasets: {
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }[] = [];

  const coloursAdvised = ["#689F38", "#33691E"];
  const coloursLpasOnly = ["#3287a8", "#225a70"];
  const coloursOther = ["#f9a825", "#f57f17"];
  const coloursSubscription = ["#5f30a6", "#351c5c"];

  // For each dataset returned, create a new dataset object with the correct format
  // for the chart.js graph
  response.datasets.forEach((dataset, index: number) => {
    datasets.push({
      label: "Advised",
      stack: "Stack " + index,
      backgroundColor: coloursAdvised[index],
      data: dataset.datasets.map((i: any) => {
        return i.advisedValue;
      }),
    });
    datasets.push({
      label: "LPAs Only",
      stack: "Stack " + index,
      backgroundColor: coloursLpasOnly[index],
      data: dataset.datasets.map((i: any) => {
        return i.lpasOnlyValue;
      }),
    });
    datasets.push({
      label: "Other",
      stack: "Stack " + index,
      backgroundColor: coloursOther[index],
      data: dataset.datasets.map((i: any) => {
        return i.outsourcedValue;
      }),
    });
    datasets.push({
      label: "Subscription",
      stack: "Stack " + index,
      backgroundColor: coloursSubscription[index],
      data: dataset.datasets.map((i: any) => {
        return i.subscriptionValue;
      }),
    });
  });

  return {
    datasets: datasets,
    labels: response.labels.map((label) => label.toString()),
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};
