import { Api } from "@/service/api.service";
import { Exclusion } from "@kinherit/sdk";

interface DeleteKintinExclusionMessage {
  exclusion: Exclusion;
}

interface DeleteKintinExclusionResponse {
  [key: string]: never;
}

export const DeleteKintinExclusionHandler = async (
  message: DeleteKintinExclusionMessage,
): Promise<DeleteKintinExclusionResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-exclusion/{kintin}/exclusion/{exclusion}",
    {
      kintin: message.exclusion.$data.kintin,
      exclusion: message.exclusion.$data.id,
    },
  )
    .method("delete")

    .result();

  message.exclusion.$delete();

  return {};
};
