import { Between, Equal } from "@kinherit/orm/index";
import {
  Api,
  IntroducerCompany,
  IntroducerFeePayment,
  IntroducerFeePaymentItem,
  IntroducerFeePaymentRun,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyFeeMessage {
  introducerCompany: IntroducerCompany | string;
  feeTypeId?: "introducerFee" | "agentFee" | "parentAgentFee" | null;
  created?: [number, number] | null;
  thisCompanyOnly?: boolean;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IntroducerFeePaymentItem | keyof IntroducerFeePaymentRun;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerCompanyFeeResponse {
  introducerFeePaymentRuns: Array<IntroducerFeePaymentRun>;
  introducerFeePayments: Array<IntroducerFeePayment>;
  introducerFeePaymentItems: Array<IntroducerFeePaymentItem>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerCompanyFeeHandler = async (
  message: ReadIntroducerCompanyFeeMessage,
): Promise<ReadIntroducerCompanyFeeResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-fees/{introducerCompany}/fees",
    {
      introducerCompany: message.introducerCompany,
    },
  )
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(IntroducerFeePaymentItem).where([
    {
      feeTypeId: Equal(message.feeTypeId),
      createdAt: Between(message.created),
      order: {
        kintin: {
          referral: {
            referralCode: {
              company: {
                id: message.thisCompanyOnly
                  ? Equal(message.introducerCompany.toString())
                  : undefined,
              },
            },
          },
        },
      },
    },
  ]);

  const response = await request.result();

  return {
    introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
      response.introducerFeePaymentItem,
      message.sort,
      response.$rootIds,
    ),
    introducerFeePaymentRuns: IntroducerFeePaymentRun.$inflate(
      response.introducerFeePaymentRun,
    ),
    introducerFeePayments: IntroducerFeePayment.$inflate(
      response.introducerFeePayment,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
