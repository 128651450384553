import { Api } from "@/service/api.service";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";

interface UpdateKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

interface UpdateKnowledgeBaseDocumentResponse {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
}

export const UpdateKnowledgeBaseDocumentHandler = async (
  message: UpdateKnowledgeBaseDocumentMessage,
): Promise<UpdateKnowledgeBaseDocumentResponse> => {
  const localCategories = message.knowledgeBaseDocument.$data.categories;

  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}",
    {
      knowledgeBaseDocument: message.knowledgeBaseDocument.id,
    },
  )
    .method("patch")

    .body({
      title: message.knowledgeBaseDocument.title,
      content: message.knowledgeBaseDocument.content,
    })
    .result();

  const knowledgeBaseCategories = KnowledgeBaseCategory.$inflate(
    response.knowledgeBaseCategory,
  );

  const knowledgeBaseDocuments = KnowledgeBaseDocument.$inflate(
    response.knowledgeBaseDocument,
  );

  const knowledgeBaseDocument = knowledgeBaseDocuments.first();

  if (!knowledgeBaseDocument) {
    throw new Error("KnowledgeBaseDocument not found");
  }

  const remoteCategories = knowledgeBaseDocument.$data.categories ?? [];

  const existngCategories = localCategories.intersection(remoteCategories);
  const newCategories = remoteCategories.filter(
    (category) => !existngCategories.includes(category),
  );
  const removedCategories = localCategories.filter(
    (category) => !existngCategories.includes(category),
  );

  await newCategories.forEachAsync(async (category) => {
    // await window.Kernel.ActionBus.execute(
    //   "knowledge-base/category/document/add",
    //   {
    //     knowledgeBaseCategory: category,
    //     knowledgeBaseDocument: knowledgeBaseDocument,
    //   },
    // );
    await window.Kernel.ActionBus.knowledgeBase.category.document.add({
      knowledgeBaseCategory: category,
      knowledgeBaseDocument: knowledgeBaseDocument,
    });
  });

  await removedCategories.forEachAsync(async (category) => {
    // await window.Kernel.ActionBus.execute(
    //   "knowledge-base/category/document/remove",
    //   {
    //     knowledgeBaseCategory: category,
    //     knowledgeBaseDocument: knowledgeBaseDocument,
    //   },
    // );
    await window.Kernel.ActionBus.knowledgeBase.category.document.remove({
      knowledgeBaseCategory: category,
      knowledgeBaseDocument: knowledgeBaseDocument,
    });
  });

  return {
    knowledgeBaseDocument,
    knowledgeBaseCategories,
  };
};
