<template>
  <strong>Schedule <span v-html="helpers.var(position)" /></strong>
  <p v-if="data">
    <strong>Combined Nil Rate Amount Discretionary Trust</strong><br />
    This trust shall be known as THE
    <span
      v-if="data.person"
      v-html="helpers.var(data.person.profile.fullName).toUpperCase()"
    />
    COMBINED NIL RATE AMOUNT DISCRETIONARY TRUST <br /><br />
    <br /><br />
    The trustees shall be as defined below
    <Trustees />
    <br /><br />
    Any money or property transferred to the trustees is hereinafter referred to
    as the trust property
    <br /><br />
    The beneficiaries shall be as defined below
    <Beneficiaries title="Section A - beneficiaries" />
  </p>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";
import Beneficiaries from "../shared/Beneficiaries.vue";
import Trustees from "../shared/Trustees.vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    position() {
      return this.helpers.getSchedulePosition(WillBuilderSections.ScheduleNRB);
    },
  },
  components: { Beneficiaries, Trustees },
});
</script>
