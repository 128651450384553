<template>
  <CallScriptSection title="Savings" v-if="kintin">
    <b>
      OK, property is clear. So let’s move on to your other assets now then.
      <br /><br />
      If we look at your individual and joint savings do you have a rough figure
      for what this might be worth?
      <br />
      Now the same question but this time for investments? Do you have a rough
      figure?
    </b>
    <br />
    <em>[Add total Savings + Investments figure]</em>
    <CurrencyField
      label="Savings & Investments"
      class="mt-4 mb-4"
      v-model:value="values.advSavingsValue"
    />
    <Button text="Save" @click="saveHandler" color="is-positive" class="mb-4" />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.3"
      :kintin="kintin"
    />
  </CallScriptSection>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import { CurrencyField } from "@kinherit/framework/component.input/currency-field";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "SavingsTab",
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    CallScriptSection,
    ConfirmCheck,
    Button,
    CurrencyField,
  },
  emits: ["save"],
  data: () => ({
    values: {
      advSavingsValue: null as null | Currency,
    },
  }),
  computed: {},
  mounted(): void {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }

    this.values.advSavingsValue =
      this.kintin.callScript?.advSavingsValue ?? null;
  },
  methods: {
    async saveHandler() {
      if (!this.kintin) {
        return;
      }
      this.kintin.callScript.advSavingsValue = this.values.advSavingsValue;
      this.$emit("save");
    },
  },
});
</script>
