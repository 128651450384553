<template>
  <Card title="Cashflow By EP">
    <p>
      Average Order value and totals, should not be used for accounting
      purposes, merely cash flow indication
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :rows="results"
      :footer="footer"
      @loaded="load"
    >
    </Table>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { CashflowByEpReportRoute } from ".";

import { TCashflowByEpReportResult } from "@/module/report/action/compute-cashflow-by-ep-report.action";
import { ReadCashflowByEpReportFilters } from "@/module/report/form/read-cashflow-by-ep.report.form";
import Table from "@kinherit/framework/component.display/table";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { Currency } from "@kinherit/ts-common/index";

export default defineComponent({
  name: CashflowByEpReportRoute,
  components: {
    Card,
    MasterListFilters,
    Table,
  },
  data: () => ({
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadCashflowByEpReportFilters(),

    columns: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "EP",
        mapHtml: (results: TCashflowByEpReportResult) => {
          return results.estatePlanner;
        },
      },
      {
        title: "Advised (AVG)",
        class: "cash-report-result",
        mapHtml: (results: TCashflowByEpReportResult) => {
          const value = new Currency({
            amount: results.advisedValue,
          });

          const averageOrderValue =
            results.advisedCount > 0
              ? new Currency({
                  amount: results.advisedValue / results.advisedCount,
                }).toFormattedString(true)
              : "-";

          return value.amount > 0
            ? `${value.toFormattedString()} <span class='has-text-grey'>(${averageOrderValue})</span> <span class='badge is-small is-compact is-light-grey'>${
                results.advisedCount
              }</span>`
            : "-";
        },
      },
      {
        title: "Hosted",
        class: "cash-report-result",
        mapHtml: (results: TCashflowByEpReportResult) => {
          const value = new Currency({
            amount: results.hostedValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.hostedCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "LPAs Only",
        class: "cash-report-result",
        mapHtml: (results: TCashflowByEpReportResult) => {
          const value = new Currency({
            amount: results.lpasOnlyValue,
          });
          return value.amount > 0
            ? value.toFormattedString() +
                "<span class='badge is-small is-compact is-light-grey'>" +
                results.lpasOnlyCount +
                "</span>"
            : "-";
        },
      },
      {
        title: "Total",
        class: "has-text-weight-bold",
        map: (results: TCashflowByEpReportResult) => {
          const value = new Currency({
            amount: results.sumValue,
          });
          return value.toFormattedString();
        },
      },
    ],
    footer: [
      "Total",
      // Pipeline
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowByEpReportResult[]) => {
          const value = new Currency({
            amount: results.reduce((total, row) => total + row.unpaidValue, 0),
          });
          return value.toFormattedString();
        },
      },
      // Advised
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowByEpReportResult[]) => {
          const totalAdvisedValue = results.reduce(
            (total, row) => total + row.advisedValue,
            0,
          );
          const totalAdvisedCount = results.reduce(
            (total, row) => total + row.advisedCount,
            0,
          );

          const averageOrderValue =
            totalAdvisedCount > 0
              ? new Currency({
                  amount: totalAdvisedValue / totalAdvisedCount,
                }).format
              : "-";

          const totalValue = new Currency({
            amount: totalAdvisedValue,
          });

          return `${totalValue.toFormattedString()} <span class='has-text-grey'>(${averageOrderValue})</span>`;
        },
      },
      // Hosted
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowByEpReportResult[]) => {
          const value = new Currency({
            amount: results.reduce((total, row) => total + row.hostedValue, 0),
          });
          return value.toFormattedString();
        },
      },
      // LPAs Only
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowByEpReportResult[]) => {
          const value = new Currency({
            amount: results.reduce(
              (total, row) => total + row.lpasOnlyValue,
              0,
            ),
          });
          return value.toFormattedString();
        },
      },
      // Total
      {
        class: "has-text-weight-bold",
        mapHtml: (results: TCashflowByEpReportResult[]) => {
          const value = new Currency({
            amount: results.reduce((total, row) => total + row.sumValue, 0),
          });
          return value.toFormattedString();
        },
      },
    ],
  }),
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.execute(
      //   "report/cashflow/by-ep",
      //   this.filters.localData,
      // );
      const result = await window.Kernel.ActionBus.report.cashflowByEp(
        this.filters.localData,
      );

      this.datasets = result.datasets;
      this.labels = result.labels;
      this.results = result.results;
    },
  },
});
</script>
