import {
  Adviser,
  Attorney,
  Beneficiary,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Person,
  Trustee,
} from "@kinherit/sdk";

export const KinvaultKintinMasterListBreadCrumb = () => [
  {
    text: "Accounts",
    route: { name: "KinvaultKintinMasterList" },
  },
];

export const KinvaultKintinDetailsWillBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Will",
    route: { name: "KinvaultKintinDetailsWill", params },
  },
];

export const KintinMasterListEscalatedBreadCrumb = () => [
  {
    text: "Escalated",
    route: { name: "KintinMasterListEscalated" },
  },
];

export const KinvaultKintinDetailsBreadCrumb = (
  params: Record<string, string>,
) => [
  KinvaultKintinMasterListBreadCrumb()[0],
  {
    text: Kintin.$findOne(params.kintin)?.friendlyName ?? "",
    route: { name: "KinvaultKintinDetails", params },
  },
];

export const KinvaultKintinDetailsSummaryBreadCrumb = (
  params: Record<string, string>,
) => [...KinvaultKintinDetailsBreadCrumb(params)];

export const KinvaultKintinDetailsActivityBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Activity",
    route: { name: "KinvaultKintinDetailsActivity", params },
  },
];

export const KinvaultKintinDetailsAddressBookBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Address Book",
    route: { name: "KinvaultKintinDetailsAddressBook", params },
  },
];

export const KinvaultKintinDetailsCallsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Calls",
    route: { name: "KinvaultKintinDetailsCalls", params },
  },
];

export const KinvaultKintinDetailsDocumentsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Documents",
    route: { name: "KinvaultKintinDetailsDocuments", params },
  },
];

export const KinvaultKintinDetailsProcessBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Process",
    route: { name: "KinvaultKintinDetailsProcess", params },
  },
];

export const KinvaultKintinDetailsFileVaultBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "File Vault",
    route: { name: "KinvaultKintinDetailsFileVault", params },
  },
];

export const KinvaultKintinDetailsCreditNotesBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Credit Notes",
    route: { name: "KintinDetailsCreditNotes", params },
  },
];

export const KinvaultKintinDetailsSplitBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Split",
    route: { name: "KintinDetailsSplit", params },
  },
];

export const KinvaultKintinDetailsOrdersBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Orders",
    route: { name: "KintinDetailsOrder", params },
  },
];

export const KinvaultKintinDetailsNotesBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Notes",
    route: { name: "KinvaultKintinDetailsNotes", params },
  },
];

export const KinvaultKintinDetailsEmailsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Emails",
    route: { name: "KinvaultKintinDetailsEmails", params },
  },
];

export const KinvaultKintinDetailsAddressBookUpdateProfileBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsAddressBookBreadCrumb(params),
  {
    text: Person.$findOne(params.person)?.profile?.fullName ?? "",
    route: { name: "KinvaultKintinDetailsAddressBookUpdateProfile", params },
  },
];

export const KinvaultKintinDetailsAddressBookUpdateGiftBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsAddressBookBreadCrumb(params),
  {
    text: Gift.$findOne(params.gift)?.forPerson?.profile.fullName ?? `Gift`,
    route: { name: "KinvaultKintinDetailsAddressBookUpdateProfile", params },
  },
];

export const KinvaultKintinDetailsAddressBookUpdateExclusionBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsAddressBookBreadCrumb(params),
  {
    text: Exclusion.$findOne(params.exclusion)?.who ?? "",
    route: { name: "KinvaultKintinDetailsAddressBookUpdateProfile", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreatePersonBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsAddressBookBreadCrumb(params),
  {
    text: "Person",
    route: { name: "KinvaultKintinDetailsAddressBookCreatePerson", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateGiftBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsAddressBookBreadCrumb(params),
  {
    text: "Gift",
    route: { name: "KinvaultKintinDetailsAddressBookCreateGift", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateExclusionBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsAddressBookBreadCrumb(params),
  {
    text: "Exclusion",
    route: { name: "KinvaultKintinDetailsAddressBookCreateExclusion", params },
  },
];

export const KinvaultKintinDetailsPostPaymentBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Post Payment",
    route: { name: "KinvaultKintinDetailsPostPayment", params },
  },
];

export const KinvaultKintinDetailsPostPaymentMergeContactsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsPostPaymentBreadCrumb(params),
  {
    text: "Merge Contacts",
    route: {
      name: "KinvaultKintinDetailsPostPaymentMergeContacts",
      params,
    },
  },
];

export const KinvaultKintinDetailsSubscriptionsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Subscriptions",
    route: { name: "KintinDetailsSubscription", params },
  },
];

export const KinvaultKintinDetailsUploadFormsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Upload Forms",
    route: { name: "KintinDetailsUploadForm", params },
  },
];

export const KinvaultKintinDetailsWillBuilderBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsDocumentsBreadCrumb(params),
  {
    text: "Will Builder",
    route: { name: "KinvaultKintinDetailsWillBuilder", params },
  },
];

export const KinvaultKintinDetailsCallScriptBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Call Script",
    route: { name: "KinvaultKintinDetailsCallScript", params },
  },
];

export const KinvaultKintinDetailsChangeLogBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Changes",
    route: { name: "KinvaultKintinDetailsChangeLog", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateAdviserBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Adviser",
    route: { name: "KinvaultKintinDetailsAddressBookCreateAdviser", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateAttorneyBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Attorney",
    route: { name: "KinvaultKintinDetailsAddressBookCreateAttorney", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateBeneficiaryBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Beneficiary",
    route: {
      name: "KinvaultKintinDetailsAddressBookCreateBeneficiary",
      params,
    },
  },
];

export const KinvaultKintinDetailsAddressBookCreateExecutorBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Executor",
    route: { name: "KinvaultKintinDetailsAddressBookCreateExecutor", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateGuardianBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Guardian",
    route: { name: "KinvaultKintinDetailsAddressBookCreateGuardian", params },
  },
];

export const KinvaultKintinDetailsAddressBookCreateTrusteeBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: "Trustee",
    route: { name: "KinvaultKintinDetailsAddressBookCreateTrustee", params },
  },
];

export const KinvaultKintinDetailsAddressBookUpdateAdviserBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: `Adviser: ${
      Adviser.$findOne(params.adviser)?.person.profile.fullName ?? ""
    }`,
    route: { name: "KinvaultKintinDetailsAddressBookUpdateAdviser", params },
  },
];
export const KinvaultKintinDetailsAddressBookUpdateAttorneyBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: `Attorney: ${
      Attorney.$findOne(params.attorney)?.person.profile.fullName ?? ""
    }`,
    route: { name: "KinvaultKintinDetailsAddressBookUpdateAttorney", params },
  },
];
export const KinvaultKintinDetailsAddressBookUpdateBeneficiaryBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: `Beneficiary: ${
      Beneficiary.$findOne(params.beneficiary)?.person?.profile.fullName ??
      Beneficiary.$findOne(params.beneficiary)?.reservePerson?.profile
        .fullName ??
      ""
    }`,
    route: {
      name: "KinvaultKintinDetailsAddressBookUpdateBeneficiary",
      params,
    },
  },
];
export const KinvaultKintinDetailsAddressBookUpdateExecutorBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: `Executor: ${
      Executor.$findOne(params.executor)?.person.profile.fullName ?? ""
    }`,
    route: { name: "KinvaultKintinDetailsAddressBookUpdateExecutor", params },
  },
];
export const KinvaultKintinDetailsAddressBookUpdateGuardianBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: `Guardian: ${
      Guardian.$findOne(params.guardian)?.person.profile.fullName ?? ""
    }`,
    route: { name: "KinvaultKintinDetailsAddressBookUpdateGuardian", params },
  },
];
export const KinvaultKintinDetailsAddressBookUpdateTrusteeBreadCrumb = (
  params: Record<string, string>,
) => [
  ...KinvaultKintinDetailsBreadCrumb(params),
  {
    text: `Trustee: ${
      Trustee.$findOne(params.trustee)?.person.profile.fullName ?? ""
    }`,
    route: { name: "KinvaultKintinDetailsAddressBookUpdateTrustee", params },
  },
];
