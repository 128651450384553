<template>
  <div class="copy-roles">
    <AdviserSummary
      v-for="(adviser, index) in advisers"
      :key="`adviser-${index}`"
      :role="adviser"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(adviser.id)"
          @update:value="toggle(adviser)"
        />
      </template>
    </AdviserSummary>
    <AttorneySummary
      v-for="(attorney, index) in attorneys"
      :key="`attorney-${index}`"
      :role="attorney"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(attorney.id)"
          @update:value="toggle(attorney)"
        />
      </template>
    </AttorneySummary>
    <BeneficiarySummary
      v-for="(beneficiary, index) in beneficiaries"
      :key="`beneficiary-${index}`"
      :role="beneficiary"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(beneficiary.id)"
          @update:value="toggle(beneficiary)"
        />
      </template>
    </BeneficiarySummary>
    <ExecutorSummary
      v-for="(executor, index) in executors"
      :key="`executor-${index}`"
      :role="executor"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(executor.id)"
          @update:value="toggle(executor)"
        />
      </template>
    </ExecutorSummary>
    <ExclusionSummary
      v-for="(exclusion, index) in exclusions"
      :key="`exclusion-${index}`"
      :role="exclusion"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(exclusion.id)"
          @update:value="toggle(exclusion)"
        />
      </template>
    </ExclusionSummary>
    <GiftRecipientSummary
      v-for="(gift, index) in gifts"
      :key="`gift-${index}`"
      :gift="gift"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(gift.id)"
          @update:value="toggle(gift)"
        />
      </template>
    </GiftRecipientSummary>
    <GuardianSummary
      v-for="(guardian, index) in guardians"
      :key="`guardian-${index}`"
      :role="guardian"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(guardian.id)"
          @update:value="toggle(guardian)"
        />
      </template>
    </GuardianSummary>
    <PropertySummary
      v-for="(property, index) in properties"
      :key="`property-${index}`"
      :role="property"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(property.id)"
          @update:value="toggle(property)"
        />
      </template>
    </PropertySummary>
    <TrusteeSummary
      v-for="(trustee, index) in trustees"
      :key="`trustee-${index}`"
      :role="trustee"
      :kintin="kintin"
    >
      <template #buttons>
        <CheckboxField
          :value="toCopy.pluck('id').includes(trustee.id)"
          @update:value="toggle(trustee)"
        />
      </template>
    </TrusteeSummary>
  </div>
</template>

<script lang="ts">
import CheckboxField from "@kinherit/framework/component.input/checkbox-field";
import {
  Adviser,
  Attorney,
  Beneficiary,
  Exclusion,
  Executor,
  Gift,
  Guardian,
  Kintin,
  Property,
  Trustee,
} from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import AdviserSummary from "./summary-cards/AdviserSummary.vue";
import AttorneySummary from "./summary-cards/AttorneySummary.vue";
import BeneficiarySummary from "./summary-cards/BeneficiarySummary.vue";
import ExclusionSummary from "./summary-cards/ExclusionSummary.vue";
import ExecutorSummary from "./summary-cards/ExecutorSummary.vue";
import GiftRecipientSummary from "./summary-cards/GiftRecipientSummary.vue";
import GuardianSummary from "./summary-cards/GuardianSummary.vue";
import PropertySummary from "./summary-cards/PropertySummary.vue";
import TrusteeSummary from "./summary-cards/TrusteeSummary.vue";

export default defineComponent({
  name: "CopyRoles",
  props: {
    roles: {
      type: Array as PropType<
        | Adviser[]
        | Attorney[]
        | Beneficiary[]
        | Executor[]
        | Exclusion[]
        | Gift[]
        | Guardian[]
        | Property[]
        | Trustee[]
      >,
      required: true,
    },
    kintin: {
      type: Object as PropType<Kintin>,
      required: true,
    },
    toCopy: {
      type: Array as PropType<
        | Adviser[]
        | Attorney[]
        | Beneficiary[]
        | Executor[]
        | Exclusion[]
        | Gift[]
        | Guardian[]
        | Property[]
        | Trustee[]
      >,
      required: true,
    },
  },
  emits: ["update:to-copy"],
  computed: {
    advisers(): Adviser[] {
      return this.roles.filter((role) => role instanceof Adviser) as Adviser[];
    },
    attorneys(): Attorney[] {
      return this.roles.filter(
        (role) => role instanceof Attorney,
      ) as Attorney[];
    },
    beneficiaries(): Beneficiary[] {
      return this.roles.filter(
        (role) => role instanceof Beneficiary,
      ) as Beneficiary[];
    },
    executors(): Executor[] {
      return this.roles.filter(
        (role) => role instanceof Executor,
      ) as Executor[];
    },
    exclusions(): Exclusion[] {
      return this.roles.filter(
        (role) => role instanceof Exclusion,
      ) as Exclusion[];
    },
    gifts(): Gift[] {
      return this.roles.filter((role) => role instanceof Gift) as Gift[];
    },
    guardians(): Guardian[] {
      return this.roles.filter(
        (role) => role instanceof Guardian,
      ) as Guardian[];
    },
    properties(): Property[] {
      return this.roles.filter(
        (role) => role instanceof Property,
      ) as Property[];
    },
    trustees(): Trustee[] {
      return this.roles.filter((role) => role instanceof Trustee) as Trustee[];
    },
  },
  methods: {
    toggle(
      role:
        | Adviser
        | Attorney
        | Beneficiary
        | Executor
        | Exclusion
        | Gift
        | Guardian
        | Property
        | Trustee,
    ) {
      let toCopy = [...this.toCopy];

      if (toCopy.pluck("id").includes(role.id)) {
        toCopy = toCopy.remove(role);
      } else {
        toCopy.push(role);
      }

      this.$emit("update:to-copy", toCopy);
    },
  },
  components: {
    AdviserSummary,
    AttorneySummary,
    BeneficiarySummary,
    ExecutorSummary,
    ExclusionSummary,
    GiftRecipientSummary,
    GuardianSummary,
    PropertySummary,
    TrusteeSummary,
    CheckboxField,
  },
});
</script>
