import { Api } from "@/service/api.service";
import {
  CommunicationNote,
  IntroducerCompany,
  IntroducerContact,
  IntroducerNote,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";

type Controllers =
  | "/v2/portal/kintin-note/{owner}/notes/{note}"
  | "/v2/portal/lead-note/{owner}/notes/{note}"
  | "/v2/portal/introducer-company-note/{owner}/notes/{note}"
  | "/v2/portal/introducer-contact-note/{owner}/notes/{note}"
  | "/v2/portal/officer-note/{owner}/notes/{note}"
  | "/v2/portal/introducer-outsource-note/{owner}/notes/{note}";

export type RecordNoteMessage = {
  note: Note | string;
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      person: string | Person;
    }
  | {
      introducerOutsource: string | IntroducerOutsource;
    }
);

export interface RecordNotesResponse {
  notes: Array<Note>;
  communicationNotes: Array<CommunicationNote>;
  introducerNotes: Array<IntroducerNote>;
}

export const RecordNoteHandler = async (
  message: RecordNoteMessage,
): Promise<RecordNotesResponse> => {
  let path = "" as any;
  const params = {
    note: message.note,
  } as any;

  if ("kintin" in message && message.kintin) {
    path = "/v2/portal/kintin-note/{owner}/notes/{note}";
    params.owner = message.kintin;
  } else if ("lead" in message && message.lead) {
    path = "/v2/portal/lead-note/{owner}/notes/{note}";
    params.owner = message.lead;
  } else if ("introducerCompany" in message && message.introducerCompany) {
    path = "/v2/portal/introducer-company-note/{owner}/notes/{note}";
    params.owner = message.introducerCompany;
  } else if ("introducerContact" in message && message.introducerContact) {
    path = "/v2/portal/introducer-contact-note/{owner}/notes/{note}";
    params.owner = message.introducerContact;
  } else if ("person" in message && message.person) {
    path = "/v2/portal/officer-note/{owner}/notes/{note}";
    params.owner = message.person;
  } else if ("introducerOutsource" in message && message.introducerOutsource) {
    path = "/v2/portal/introducer-outsource-note/{owner}/notes/{note}";
    params.owner = message.introducerOutsource;
  }

  const response = await Api.resource("portal", path as Controllers, params)
    .method("get")

    .result();

  return {
    notes: Note.$inflate(response.note),
    communicationNotes: CommunicationNote.$inflate(response.communicationNote),
    introducerNotes: IntroducerNote.$inflate(response.introducerNote),
  };
};
