export const IntroducerContractStatus = {
  void: {
    id: 0,
    text: "Void",
    value: "void",
  },
  ["not-sent"]: {
    id: 1,
    text: "Not sent",
    value: "not-sent",
  },
  ["sent-not-signed"]: {
    id: 2,
    text: "Sent, not signed",
    value: "sent-not-signed",
  },
  ["sent-signed"]: {
    id: 3,
    text: "Sent, signed",
    value: "sent-signed",
  },
};
