import { Api } from "@/service/api.service";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk";

interface UpdateIntroducerCompanyPartnerPortalPermissionsMessage {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

interface UpdateIntroducerCompanyPartnerPortalPermissionsResponse {
  companyPartnerPortalPermissions: CompanyPartnerPortalPermissions;
}

export const UpdateIntroducerCompanyPartnerPortalPermissionsHandler = async (
  message: UpdateIntroducerCompanyPartnerPortalPermissionsMessage,
): Promise<UpdateIntroducerCompanyPartnerPortalPermissionsResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-partner-portal/{introducerCompany}/company-partner-portal-permissions/{companyPartnerPortalPermissions}",
    {
      introducerCompany: message.companyPartnerPortalPermissions.company,
      companyPartnerPortalPermissions:
        message.companyPartnerPortalPermissions.$id,
    },
  )
    .method("patch")
    .body({
      allowPartnerPortal:
        message.companyPartnerPortalPermissions.allowPartnerPortal,
      enableClients: message.companyPartnerPortalPermissions.enableClients,
      enableFees: message.companyPartnerPortalPermissions.enableFees,
      viewOrderProducts:
        message.companyPartnerPortalPermissions.viewOrderProducts,
      enableStandaloneKinvault:
        message.companyPartnerPortalPermissions.enableStandaloneKinvault,
    })
    .result();

  const partnerPortalPermissions = CompanyPartnerPortalPermissions.$inflate(
    response.companyPartnerPortalPermissions,
  ).first();

  if (!partnerPortalPermissions) {
    throw new Error("Failed to update partner portal permission");
  }

  return {
    companyPartnerPortalPermissions: partnerPortalPermissions,
  };
};
