import {
  KintinAddressField,
  OptionsAutoCompleteField,
} from "@/config/form.config";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Kintin, Property } from "@kinherit/sdk";
import { AddAddressButton } from "./shared.form";

export const UpdatePropertyForm = ({
  property,
  kintin,
}: {
  property: Property;
  kintin: Kintin;
}) =>
  defineForm({
    name: "update-property-form",
    data: () => property,
    formAreas: (data) => [
      defineFormArea({
        name: "update-property-form-area",
        data,
        template: GridLayout([
          ["address"],
          ["type", "howOwned", "ownership"],
          ["price", "value", "lessMortgage"],
          ["titleDeed", "netValue"],
          ["notes"],
        ]),
        components: () => ({
          address: [
            KintinAddressField({
              props: {
                label: undefined,
                vModel: "address",
                placeholder: "-- Please select an address --",
              },
              kintin: kintin.id,
              slots: {
                right: [
                  AddAddressButton({
                    kintin,
                    targetProperty: "address",
                    unique: false,
                  }),
                ],
              },
              unique: false,
            }),
          ],
          howOwned: [
            OptionsAutoCompleteField({
              vModel: "howOwned",
              group: "howOwnedTypes",
              props: {
                label: "Who owns this?",
                validators: ["required"],
                isScrollable: true,
              },
            }),
          ],
          ownership: [
            OptionsAutoCompleteField({
              vModel: "ownership",
              group: "ownershipTypes",
              props: {
                label: "How is this owned?",
                validators: ["required"],
                isScrollable: true,
              },
            }),
          ],
          type: [
            OptionsAutoCompleteField({
              vModel: "type",
              group: "propertyTypes",
              props: {
                label: "Type of Property",
                validators: ["required"],
                isScrollable: true,
              },
            }),
          ],
          price: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Purchase Price",
                validators: ["required"],
              },
              models: {
                value: "price",
              },
            }),
          ],
          value: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Property Value",
                validators: ["required"],
              },
              models: {
                value: "value",
              },
            }),
          ],
          lessMortgage: [
            FormCurrencyField({
              reactive: true,
              props: {
                label: "Mortgage Value",
                validators: ["required"],
              },
              models: {
                value: "lessMortgage",
              },
            }),
          ],
          titleDeed: [
            FormTextField({
              props: {
                label: "Title Deed",
              },
              models: {
                value: "titleDeed",
              },
            }),
          ],
          netValue: [
            FormElement({
              props: {
                html: (data) =>
                  `<b>Net Value: £${
                    (data.value?.amount ?? 0) - (data.lessMortgage?.amount ?? 0)
                  }</b>`,
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                placeholder: "Additional notes about this property",
                class: "mt-4",
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
