import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import {
  KinvaultOfficerDetailsParams,
  KinvaultOfficerDetailsRoute,
} from "@/module/kinvault.officer/page/details";
import { AuthService } from "@/service/auth.service";
import { Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const KinvaultOfficerDetailsMixin = defineComponent({
  name: "kinvault-officer-details-mixin",
  data: () => ({
    officer: null as Person | null,
  }),
  computed: {
    $params(): KinvaultOfficerDetailsParams {
      return this.$route.params as KinvaultOfficerDetailsParams;
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("kintin:write");
    },
  },
  beforeMount(): void {
    this.$data.officer = Person.$findOne(this.$params.officer);
  },
  methods: {
    reloadOfficer(): void {
      this.$data.officer = Person.$findOne(this.$params.officer);
    },
    visitOfficerDetails(event?: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultOfficerDetailsRoute,
          params: this.$params,
        },
        event?.ctrlKey,
      );
    },
    visitKintinDetails(event?: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params: {
            kintin: this.officer?.$data.kintin as string,
          } satisfies KinvaultKintinDetailsParams,
        },
        event?.ctrlKey,
      );
    },
  },
});
