import { RouteInterface } from "@kinherit/framework/core/route-config";
import { IntroducerContactMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as IntroducerContactDetailsRoutes } from "./details";

export const IntroducerContactMasterListRoute = "IntroducerContactMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactMasterListRoute,
    path: "/introducer/contacts",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerContactMasterList" */ "./IntroducerContactMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerContactMasterListBreadCrumb],
    },
  },
  ...IntroducerContactDetailsRoutes,
];
