import { Api } from "@/service/api.service";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

interface DeleteKnowledgeBaseCategoryMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

interface DeleteKnowledgeBaseCategoryResponse {
  [key: string]: never;
}

export const DeleteKnowledgeBaseCategoryHandler = async (
  message: DeleteKnowledgeBaseCategoryMessage,
): Promise<DeleteKnowledgeBaseCategoryResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-category/{knowledgeBaseCategory}",
    {
      knowledgeBaseCategory: message.knowledgeBaseCategory.id,
    },
  )
    .method("delete")
    .result();

  return {};
};
