import { Api } from "@/service/api.service";
import { Kintin, KintinAccess, User } from "@kinherit/sdk";

interface CreateUserKintinAccessMessage {
  accessType: "owner" | "edit" | "readonly" | "adviser" | "none";
  user: User;
  kintin: Kintin;
}

interface CreateUserKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export const CreateUserKintinAccessHandler = async (
  message: CreateUserKintinAccessMessage,
): Promise<CreateUserKintinAccessResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kintin-user-access",
  ).method("post");

  request.body({
    accessType: message.accessType,
    kintin: message.kintin.id,
    user: message.user.id,
    allowAccess: true,
  });

  const result = await request.result();

  const kintinAccess = KintinAccess.$inflate(result.kintinAccess).first();

  if (!kintinAccess) {
    throw new Error("Failed to create kintin user access");
  }

  return {
    kintinAccess,
  };
};
