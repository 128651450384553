<template>
  <p>
    <strong>Funds for Travel</strong><br />
    Should any Beneficiary to whom you are considering appointing a share of the
    Trust have an interest and opportunity in pursuing travel ambitions before
    they come of age, I wish that the Trustees consider providing funds for such
    requests to the extent amounts are reasonable and travel plans are
    considered life expanding and not disruptive to education progress or
    otherwise.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
