import { DictionaryField, KintinField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { KintinAccess } from "@kinherit/sdk";

export const UpdateUserKintinAccessFrom = async (
  data: Partial<KintinAccess> = {},
): Promise<FormBuilder<() => Partial<KintinAccess>, "default">> => {
  const kintinField = KintinField<any>({
    props: {
      validators: ["required"],
      vModel: "kintin",
    },
    query: {},
  });

  return defineForm({
    name: "update-user-kintin-access-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([["accessType"], ["kintin"]]),
        components: () => ({
          accessType: [
            DictionaryField<any>({
              dictionary: "kintinAccessTypes",
              props: {
                label: "Access Type",
                validators: ["required"],
                vModel: "accessType",
              },
            }),
          ],
          kintin: [kintinField],
        }),
      }),
    ],
  });
};
