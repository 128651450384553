import { Api } from "@/service/api.service";
import { Kintin, KintinAccess } from "@kinherit/sdk";

export interface RevokeKintinAccessMessage {
  kintin: string | Kintin;
}

interface RevokeKintinAccessResponse {
  kintinAccess: Array<KintinAccess>;
}

export const RevokeKintinAccessHandler = async (
  message: RevokeKintinAccessMessage,
): Promise<RevokeKintinAccessResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/{kintin}/assigned-users/revoke-access",
    {
      kintin: message.kintin,
    },
  )
    .method("put")
    .result();

  return {
    kintinAccess: KintinAccess.$inflate(response.kintinAccess),
  };
};
