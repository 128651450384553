import { AddressField, UserField } from "@/config/form.config";
import { AuthService } from "@/service/auth.service";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { Address, Person, User } from "@kinherit/sdk";

export const CreateOfficerLetterForm = (
  officer: Person,
  data = {
    address: null as null | Address,
    specialist: null as null | User,
  },
) => {
  data.specialist = data.specialist ?? AuthService.loggedInUser;
  return defineForm({
    name: "create-officer-letter-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-officer-letter-form-area",
        data,
        template: GridLayout([["address"], ["specialist"]]),
        components: () => ({
          address: [
            AddressField({
              props: {
                label: "Address",
                vModel: "address",
              },
              query: {
                profile: {
                  person: {
                    id: Equal(officer.id),
                  },
                },
              },
            }),
          ],
          specialist: [
            UserField({
              props: {
                label: "Specialist",
                vModel: "specialist",
              },
              query: {
                displayUserInPortal: Equal(true),
              },
            }),
          ],
        }),
      }),
    ],
  });
};
