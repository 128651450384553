import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsEmailLogRoute =
  "IntroducerContactDetailsEmailLog";
export const IntroducerContactDetailsEmailLogURI =
  "/introducer/contact/:introducerContact/email-log";
export type IntroducerContactDetailsEmailLogParams = RouteParamObject<
  typeof IntroducerContactDetailsEmailLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsEmailLogRoute,
    path: IntroducerContactDetailsEmailLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsEmailLog" */ "./IntroducerContactDetails.EmailLog.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "Email Log",
          route: {
            name: IntroducerContactDetailsEmailLogRoute,
            params,
          },
        },
      ],
    },
  },
];
