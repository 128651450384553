import { IntroducerCompanyField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { BrandedKinvault } from "@kinherit/sdk/index";

export const UpdateBrandedKinvaultOwners = (company: BrandedKinvault) =>
  defineForm({
    name: "update-introducer-company-branded-kinvaults",
    data: () => company,
    formAreas: (data) => [
      defineFormArea({
        name: "branded-kinvaults",
        data,
        template: GridLayout([["ownership"], ["membership"]]),
        components: () => ({
          ownership: [
            IntroducerCompanyField({
              props: {
                label: "Ownership",
                vModel: "ownerCompanies",
                isMultiSelect: true,
              },
            }),
          ],
          membership: [
            IntroducerCompanyField({
              props: {
                label: "Membership",
                vModel: "memberCompanies",
                isMultiSelect: true,
              },
            }),
          ],
        }),
      }),
    ],
  });
