import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptWillReviewRoute =
  "KintinDetailsCallScriptWillReview";
export const KintinDetailsCallScriptWillReviewURI =
  "/kinvault/:kintin/call-script/will-review";
export type KintinDetailsCallScriptWillReviewParams = RouteParamObject<
  typeof KintinDetailsCallScriptWillReviewURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptWillReviewRoute,
    path: KintinDetailsCallScriptWillReviewURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptWillReview" */ "./KinvaultKintinDetails.CallScript.WillReview.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Will Review",
          route: { name: KintinDetailsCallScriptWillReviewRoute, params },
        },
      ],
    },
  },
];
