import { Api } from "@/service/api.service";
import { IntroducerCompany, Profile, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerCompanyMessage {
  query?: QueryMask<typeof IntroducerCompany>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerCompanyResponse {
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
}

export const ReadIntroducerCompanyHandler = async (
  message: ReadIntroducerCompanyMessage,
): Promise<ReadIntroducerCompanyResponse> => {
  const request = Api.resource("core", "/v2/core/select/introducer-company")
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(IntroducerCompany).where(message.query ?? {});

  const response = await request.result();

  return {
    introducerCompanies: IntroducerCompany.$inflate(
      response.introducerCompany,
      undefined,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
  };
};
