import { Api } from "@/service/api.service";
import { AccountReferralCode } from "@kinherit/sdk";

interface UpdateAccountReferralCodeMessage {
  accountReferralCode: AccountReferralCode;
}

interface UpdateAccountReferralCodeResponse {
  accountReferralCode: AccountReferralCode;
}

export const UpdateAccountReferralCodeHandler = async (
  message: UpdateAccountReferralCodeMessage,
): Promise<UpdateAccountReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/account-referral-code/{accountReferralCode}",
    {
      accountReferralCode: message.accountReferralCode,
    },
  )
    .method("patch")

    .body({
      category: message.accountReferralCode.$data.category,
      code: message.accountReferralCode.$data.code,
      company: message.accountReferralCode.$data.company,
      isForUsers: message.accountReferralCode.$data.isForUsers,
      name: message.accountReferralCode.$data.name,
      notes: message.accountReferralCode.$data.notes,
      isFeeSacrifice: message.accountReferralCode.$data.isFeeSacrifice,
      isSalarySacrifice: message.accountReferralCode.$data.isSalarySacrifice,
      isFriendsAndFamily: message.accountReferralCode.$data.isFriendsAndFamily,
    })
    .result();

  const accountReferralCode = AccountReferralCode.$inflate(
    response.accountReferralCode,
    undefined,
    response.$rootIds,
  ).first();

  if (!accountReferralCode) {
    throw new Error("Failed to create account referral code");
  }

  return {
    accountReferralCode,
  };
};
