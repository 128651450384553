import { Api } from "@/service/api.service";
import { Order, OrderItem } from "@kinherit/sdk";

export interface UpdateOrderItemMessage {
  order: Order | string;
  orderItem: OrderItem;
}

export interface UpdateOrderItemResponse {
  orderItems: Array<OrderItem>;
}

export const UpdateOrderItemHandler = async (
  message: UpdateOrderItemMessage,
): Promise<UpdateOrderItemResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/order/{order}/order-items/{orderItem}",
    {
      order: message.order,
      orderItem: message.orderItem,
    },
  )
    .method("patch")

    .body({
      price: message.orderItem.$data.price,
      quantity: message.orderItem.$data.quantity,
      recurring: message.orderItem.$data.recurring,
      requiresDd: message.orderItem.$data.requiresDd,
      text: message.orderItem.$data.text,
      value: message.orderItem.$data.value,
    });

  const response = await request.result();

  return {
    orderItems: OrderItem.$inflate(
      response.orderItem,
      undefined,
      response.$rootIds,
    ),
  };
};
