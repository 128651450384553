<template>
  <SidebarPage
    :key="key"
    v-if="emailCampaign"
    class="email-campaign-details"
    :name="emailCampaign.name"
    :tabs="tabs"
    :has-context-menu="hasWriteAccess"
    @context-menu="contextMenu"
  >
    <template #sidebar>
      <RouterChildView name="sidebar">
        <EmailCampaignDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <EmailCampaignDetailsSummary />
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else />
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailCampaignDetailsWrapper",
  "route": "EmailCampaignDetails",
  "selector": ".email-campaign-details",
  "imports": {
    "EmailCampaignDetailsSummaryWrapper": "@/module/admin.email-campaign/page/details/summary/EmailCampaignDetails.Summary.test",
    "EmailCampaignDetailsMembersWrapper": "@/module/admin.email-campaign/page/details/members/EmailCampaignDetails.Members.test",
    "EmailCampaignDetailsLogsWrapper": "@/module/admin.email-campaign/page/details/logs/EmailCampaignDetails.Logs.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Edit", "Delete", "Upload Members CSV"]
    },
    "tabs": {
      "type": "tabs",
      "items": ["Summary", "Members", "Emails"]
    },
    "summary": {
      "type": "to-one",
      "selector": "",
      "wrapper": "EmailCampaignDetailsSummaryWrapper"
    },
    "members": {
      "type": "to-one",
      "selector": "",
      "wrapper": "EmailCampaignDetailsMembersWrapper"
    },
    "emails": {
      "type": "to-one",
      "selector": "",
      "wrapper": "EmailCampaignDetailsLogsWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { EmailCampaignDetailsParams, EmailCampaignDetailsRoute } from ".";
import { EmailCampaignsMasterListRoute } from "..";
import { UpdateEmailCampaignForm } from "../../form/update-email-campaign.form";
import EmailCampaignDetailsSidebar from "./EmailCampaignDetails.Sidebar.vue";
import { EmailCampaignDetailsLogsRoute } from "./logs";
import {
  EmailCampaignDetailsMembersCsvRoute,
  EmailCampaignDetailsMembersRoute,
} from "./members";
import { EmailCampaignDetailsSummaryRoute } from "./summary";
import EmailCampaignDetailsSummary from "./summary/EmailCampaignDetails.Summary.vue";

export default defineComponent({
  name: EmailCampaignDetailsRoute,
  mixins: [EmailCampaignDetailsMixin, AuthService.mixin()],
  components: {
    PageNotFound,
    SidebarPage,
    EmailCampaignDetailsSummary,
    EmailCampaignDetailsSidebar,
    RouterChildView,
  },
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.adminEmailCampaign.campaign.record(
      to.params as EmailCampaignDetailsParams,
    );
  },
  data: (ctx) => ({
    key: 0,
    tabs: [
      {
        label: "Summary",
        route: {
          name: EmailCampaignDetailsSummaryRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Members",
        route: {
          name: EmailCampaignDetailsMembersRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Emails",
        route: {
          name: EmailCampaignDetailsLogsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.calendar.icon,
      },
    ],
  }),
  methods: {
    async contextMenu(): Promise<void> {
      const contextMenu = this.$auth.openContextMenu({
        request: {
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
          items: [
            {
              title: "Edit",
              icon: StyleService.icon.edit.icon,
              line1: "Edit this Email Campaign",
              action: this.edit,
              premissions: ["email-campaign:write"],
            },
            {
              title: "Delete",
              icon: StyleService.icon.delete.icon,
              line1: "Delete this Email Campaign",
              action: this.delete,
              premissions: ["email-campaign:write"],
            },
            {
              title: "Upload Members CSV",
              icon: StyleService.icon.upload.icon,
              line1: "Populate the member list from a CSV",
              action: this.membersFromCsv,
              premissions: ["email-campaign:write"],
            },
          ],
        },
        filter: "all",
        path: "premissions",
        callback: (item) => item.action(),
      });

      await contextMenu();
    },
    async edit(): Promise<void> {
      if (!this.emailCampaign) {
        return;
      }

      try {
        await UpdateEmailCampaignForm(this.emailCampaign).dialog({
          dialog: {
            title: "Edit Email Campaign",
          },
        });
      } catch (e) {
        this.emailCampaign.$restore();
        throw e;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/email-campaign/campaign/update",
      //   {
      //     emailCampaign: this.emailCampaign,
      //   },
      // );

      await window.Kernel.ActionBus.adminEmailCampaign.campaign.update({
        emailCampaign: this.emailCampaign,
      });

      this.key++;
    },
    async delete(): Promise<void> {
      if (!this.emailCampaign) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Email Campaign",
          message: `Are you sure you want to delete this email campaign?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/email-campaign/campaign/delete",
      //   {
      //     emailCampaign: this.emailCampaign,
      //   },
      // );

      await window.Kernel.ActionBus.adminEmailCampaign.campaign.delete({
        emailCampaign: this.emailCampaign,
      });

      window.Kernel.Router.push({ name: EmailCampaignsMasterListRoute });
    },
    async membersFromCsv(): Promise<void> {
      window.Kernel.visitRoute({
        name: EmailCampaignDetailsMembersCsvRoute,
        params: this.$params,
      });
    },
  },
});
</script>
