import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { IntroducerOutsourceDetailsBreadCrumb } from "../../breadcrumbs";
import { Routes as IntroducerOutsourceDetailsNotesRoutes } from "./notes";

export const IntroducerOutsourceDetailsRoute = "IntroducerOutsourceDetails";
export const IntroducerOutsourceDetailsURI =
  "/introducer-outsource/:introducerOutsource";
export type IntroducerOutsourceDetailsParams = RouteParamObject<
  typeof IntroducerOutsourceDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerOutsourceDetailsRoute,
    path: IntroducerOutsourceDetailsURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerOutsourceDetails" */ "./IntroducerOutsourceDetails.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "IntroducerOutsourceDetailsSidebar" */ "./IntroducerOutsourceDetails.sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => IntroducerOutsourceDetailsBreadCrumb(params),
    },
    children: [...IntroducerOutsourceDetailsNotesRoutes],
  },
];
