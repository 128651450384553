import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  UserBreadCrumb,
  UserDetailsCreditNotesBreadCrumb,
  UserDetailsUpdateProfileBreadCrumb,
} from "../../breadcrumbs";

export const AdminUserDetailsRoute = "AdminUserDetails";
export const AdminUserDetailsURI = "/admin/user/:user";
export type AdminUserDetailsParams = RouteParamObject<
  typeof AdminUserDetailsURI
>;

export const AdminUserDetailsUpdateProfileRoute =
  "AdminUserDetailsUpdateProfile";
export const AdminUserDetailsUpdateProfileURI =
  "/admin/user/:user/edit-profile";
export type AdminUserDetailsUpdateProfileParams = RouteParamObject<
  typeof AdminUserDetailsUpdateProfileURI
>;

export const AdminUserDetailsCreditNotesRoute = "AdminUserDetailsCreditNotes";
export const AdminUserDetailsCreditNotesURI = "/admin/user/:user/edit-profile";
export type AdminUserDetailsCreditNotesParams = RouteParamObject<
  typeof AdminUserDetailsCreditNotesURI
>;

export const AdminUserDetailsAssignKintinAccessRoute =
  "AdminUserDetailsAssignKintinAccess";
export const AdminUserDetailsAssignKintinAccessURI =
  "/admin/user/:user/Kinvaults/access/:kintinAccess?/";
export type AdminUserDetailsAssignKintinAccessParams = RouteParamObject<
  typeof AdminUserDetailsAssignKintinAccessURI
>;

export const AdminUserDetailsJoinBrandedKinvaultRoute =
  "AdminUserDetailsJoinBrandedKinvault";
export const AdminUserDetailsJoinBrandedKinvaultURI =
  "/admin/user/:user/join-branded-kinvault/:brandedKinvaultMembership?/";
export type AdminUserDetailsJoinBrandedKinvaultParams = RouteParamObject<
  typeof AdminUserDetailsJoinBrandedKinvaultURI
>;

export const UserDetailsSummaryRoute = "AdminUserDetailsSummary";
export const UserDetailsSummaryURI = "/admin/user/:user";
export type UserDetailsSummaryParams = RouteParamObject<
  typeof UserDetailsSummaryURI
>;

export const UserDetailsKintinsRoute = "AdminUserDetailsKintins";
export const UserDetailsKintinsURI = "/admin/user/:user/kinvaults";
export type UserDetailsKintinsParams = RouteParamObject<
  typeof UserDetailsKintinsURI
>;

export const UserDetailsBrandedKinvaultsRoute =
  "AdminUserDetailsBrandedKinvaults";
export const UserDetailsBrandedKinvaultsURI =
  "/admin/user/:user/branded-kinvaults";
export type UserDetailsBrandedKinvaultsParams = RouteParamObject<
  typeof UserDetailsBrandedKinvaultsURI
>;

export const AdminUserDetailsManage2FactorAuthRoute =
  "AdminUserDetailsManage2FactorAuth";
export const AdminUserDetailsManage2FactorAuthURI =
  "/admin/user/:user/auth-methods";
export type AdminUserDetailsManage2FactorAuthParams = RouteParamObject<
  typeof AdminUserDetailsManage2FactorAuthURI
>;

export const AdminUserDetailsCreate2FactorAuthRoute =
  "AdminUserCreate2FactorAuth";
export const AdminUserDetailsCreate2FactorAuthURI =
  "/admin/user/:user/auth-methods/create";
export type AdminUserDetalsCreate2FactorAuthParams = RouteParamObject<
  typeof AdminUserDetailsCreate2FactorAuthURI
>;

export const AdminUserDetailsActivityRoute = "AdminUserDetailsActivity";
export const AdminUserDetailsActivityURI = "/admin/user/:user/activity";
export type AdminUserDetailsActivityParams = RouteParamObject<
  typeof AdminUserDetailsActivityURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: AdminUserDetailsRoute,
    path: AdminUserDetailsURI,
    component: () =>
      import(/* webpackChunkName: "UserDetails" */ "./UserDetails.vue"),
    meta: {
      breadcrumbs: UserBreadCrumb,
    },
    children: [
      {
        name: AdminUserDetailsUpdateProfileRoute,
        path: AdminUserDetailsUpdateProfileURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsUpdateProfile" */ "./UserDetails.UpdateProfile.vue"
            ),
        },
        meta: {
          breadcrumbs: UserDetailsUpdateProfileBreadCrumb,
        },
      },
      {
        name: AdminUserDetailsCreditNotesRoute,
        path: AdminUserDetailsCreditNotesURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsCreditNotes" */ "./UserDetails.CreditNotes.vue"
            ),
        },
        meta: {
          breadcrumbs: UserDetailsCreditNotesBreadCrumb,
        },
      },
      {
        name: AdminUserDetailsAssignKintinAccessRoute,
        path: AdminUserDetailsAssignKintinAccessURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsAssignKintinAccess" */ "./UserDetails.AssignKintinAccess.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Kinvault Access",
              route: { name: AdminUserDetailsAssignKintinAccessRoute, params },
            },
          ],
        },
      },
      {
        name: AdminUserDetailsJoinBrandedKinvaultRoute,
        path: AdminUserDetailsJoinBrandedKinvaultURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsJoinBrandedKinvault" */ "./UserDetails.JoinBrandedKinvault.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Branded Kinvault membership",
              route: { name: AdminUserDetailsJoinBrandedKinvaultRoute, params },
            },
          ],
        },
      },
      {
        name: UserDetailsSummaryRoute,
        path: UserDetailsSummaryURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsSummary" */ "./UserDetails.Summary.vue"
            ),
        },
        meta: {
          breadcrumbs: UserBreadCrumb,
        },
      },
      {
        name: UserDetailsKintinsRoute,
        path: UserDetailsKintinsURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsKintins" */ "./UserDetails.Kintins.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Kinvaults",
              route: { name: UserDetailsKintinsRoute, params },
            },
          ],
        },
      },
      {
        name: UserDetailsBrandedKinvaultsRoute,
        path: UserDetailsBrandedKinvaultsURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsBrandedKinvaults" */ "./UserDetails.BrandedKinvaults.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Branded Kinvaults",
              route: { name: UserDetailsBrandedKinvaultsRoute, params },
            },
          ],
        },
      },
      {
        name: AdminUserDetailsManage2FactorAuthRoute,
        path: AdminUserDetailsManage2FactorAuthURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsManage2FactorAuth" */ "./UserDetails.Manage2FactorAuth.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Auth Methods",
              route: { name: AdminUserDetailsManage2FactorAuthRoute, params },
            },
          ],
        },
      },
      {
        name: AdminUserDetailsCreate2FactorAuthRoute,
        path: AdminUserDetailsCreate2FactorAuthURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserCreate2FactorAuth" */ "./UserDetails.Create2FactorAuth.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Auth Methods",
              route: { name: AdminUserDetailsManage2FactorAuthRoute, params },
            },
            {
              text: "Create",
              route: { name: AdminUserDetailsCreate2FactorAuthRoute, params },
            },
          ],
        },
      },
      {
        name: AdminUserDetailsActivityRoute,
        path: AdminUserDetailsActivityURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "UserDetailsActivity" */ "./UserDetails.ActivityLog.vue"
            ),
        },
        meta: {
          breadcrumbs: (params: Record<string, string>) => [
            ...UserBreadCrumb(params),
            {
              text: "Activity",
              route: { name: AdminUserDetailsActivityRoute, params },
            },
          ],
        },
      },
    ],
  },
];
