import { Api } from "@/service/api.service";
import {
  BrandedKinvaultMembership,
  IBrandedKinvaultMembership,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";

export interface ReadBrandedKinvaultMembershipMessage {
  query?: QueryMask<typeof BrandedKinvaultMembership>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IBrandedKinvaultMembership;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultMembershipResponse {
  brandedKinvaultMemberships: Array<BrandedKinvaultMembership>;
  users: Array<User>;
  profiles: Array<Profile>;
}

export const ReadBrandedKinvaultMembershipHandler = async (
  message: ReadBrandedKinvaultMembershipMessage,
): Promise<ReadBrandedKinvaultMembershipResponse> => {
  const request = Api.resource(
    "core",
    "/v2/core/select/branded-kinvault-membership",
  )
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "user.profile.fullName",
      sortDirection: message.sort?.direction ?? "asc",
    })
    .paginate(message.pagination ?? false);

  request.buildQuery(BrandedKinvaultMembership).where(message.query ?? {});

  const response = await request.result();

  return {
    brandedKinvaultMemberships: BrandedKinvaultMembership.$inflate(
      response.brandedKinvaultMembership,
      message.sort,
      response.$rootIds,
    ),
    users: User.$inflate(response.user),
    profiles: Profile.$inflate(response.profile),
  };
};
