export const DashboardBreadcrumb = [
  {
    text: "Dashboard",
    route: { name: "DashboardIndex" },
  },
];

export const DashboardEstatePlannerBreadcrumb = [
  {
    text: "Dashboard [EP]",
    route: { name: "DashboardEstatePlanner" },
  },
];

export const DashboardLegalAdminBreadcrumb = [
  {
    text: "Dashboard [LA]",
    route: { name: "DashboardLegalAdmin" },
  },
];

export const SearchBreadcrumb = [
  DashboardBreadcrumb[0],
  {
    text: "Global Search",
    route: { name: "DashboardSearch" },
  },
];

export const AcuityBreadcrumb = [
  DashboardBreadcrumb[0],
  {
    text: "Acuity Links",
    route: { name: "DashboardAcuity" },
  },
];

export const PdfUtilsBreadcrumb = [
  DashboardBreadcrumb[0],
  {
    text: "PDF Utils",
    route: { name: "PdfUtilsRoute" },
  },
];

export const PdfOptionsBreadcrumb = [
  DashboardBreadcrumb[0],
  {
    text: "Available PDFs",
    route: { name: "PdfOptionsRoute" },
  },
];
