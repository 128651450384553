import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

import { FormTextField } from "@kinherit/framework/component.input/text-field";

export const ReadIntroducerNetworkForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-network-form",
    data: () => ({
      name: "",
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
