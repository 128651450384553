<template>
  <p v-if="data">
    <span v-if="data.protrusteeOption === 0">
      <strong>Professional Trustee Option</strong><br />
      In the event that my existing Trustee(s) wish to appoint an additional
      Trustee that is a professional (being, ordinarily engaged in providing
      professional Trustee services) to help them manage the Trusts, then I wish
      that they give consideration to any professional Trustees recommended by
      Kinherit Ltd.
    </span>
    <span v-if="data.protrusteeOption === 1">
      <strong>Professional Trustee Support</strong><br />
      I would wish that my existing Trustee(s) appoint an additional Trustee to
      act alongside them to manage the practical and legal realities of managing
      a Trust in a way that protects the beneficiaries’ assets in the long term,
      and in the most tax efficient manner. Such additional Trustee should be a
      suitably qualified trust specialist (individual or firm) ordinarily
      engaged in providing professional Trustee services. I would request my
      existing Trustees consider any professional Trustees recommended by
      Kinherit Ltd as part of this process.
    </span>
    <span v-if="data.protrusteeOption === 2">
      <strong>Appointment of Professional Trustee Requirement</strong><br />
      I explicitly request that my existing Trustee(s) appoint an additional
      Trustee to act alongside them to manage the practical and legal realities
      of managing a Trust in a way that protects the beneficiaries’ assets in
      the long term, and in the most tax efficient manner. Such additional
      Trustee should be a suitably qualified trust specialist (individual or
      firm) ordinarily engaged in providing professional Trustee services, and
      their appointment should occur prior to or contemporaneously with the
      receipt by the Trust of moneys or assets of my estate to the extent
      reasonably practicable. I would request my existing Trustees consider any
      professional Trustees recommended by Kinherit Ltd as part of this process.
    </span>
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
