import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsAddressBookBreadCrumb } from "../../../breadcrumbs";

/** AddressBook */
export const KinvaultKintinDetailsAddressBookRoute =
  "KinvaultKintinDetailsAddressBook";
const KinvaultKintinDetailsAddressBookURI = "/kinvault/:kintin/address-book";
export type KinvaultKintinDetailsAddressBookParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookURI
>;

/** Charities */
export const KinvaultKintinDetailsAddressBookCharitiesRoute =
  "KinvaultKintinDetailsAddressBookCharities";
const KinvaultKintinDetailsAddressBookCharitiesURI =
  "/kinvault/:kintin/address-book/charities";
export type KinvaultKintinDetailsAddressBookCharitiesParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookCharitiesURI
>;

/** Guardians */
export const KinvaultKintinDetailsAddressBookGuardiansRoute =
  "KinvaultKintinDetailsAddressBookGuardians";
const KinvaultKintinDetailsAddressBookGuardiansURI =
  "/kinvault/:kintin/address-book/guardians";
export type KinvaultKintinDetailsAddressBookGuardiansParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookGuardiansURI
>;

/** AccountHolders */
export const KinvaultKintinDetailsAddressBookAccountHoldersRoute =
  "KinvaultKintinDetailsAddressBookAccountHolders";
const KinvaultKintinDetailsAddressBookAccountHoldersURI =
  "/kinvault/:kintin/address-book/account-holders";
export type KinvaultKintinDetailsAddressBookAccountHoldersParams =
  RouteParamObject<typeof KinvaultKintinDetailsAddressBookAccountHoldersURI>;

/** Companies */
export const KinvaultKintinDetailsAddressBookCompaniesRoute =
  "KinvaultKintinDetailsAddressBookCompanies";
const KinvaultKintinDetailsAddressBookCompaniesURI =
  "/kinvault/:kintin/address-book/companies";
export type KinvaultKintinDetailsAddressBookCompaniesParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookCompaniesURI
>;

/** Addresses */
export const KinvaultKintinDetailsAddressBookAddressesRoute =
  "KinvaultKintinDetailsAddressBookAddresses";
const KinvaultKintinDetailsAddressBookAddressesURI =
  "/kinvault/:kintin/address-book/addresses";
export type KinvaultKintinDetailsAddressBookAddressesParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookAddressesURI
>;

/** Dependents */
export const KinvaultKintinDetailsAddressBookDependentsRoute =
  "KinvaultKintinDetailsAddressBookDependents";
const KinvaultKintinDetailsAddressBookDependentsURI =
  "/kinvault/:kintin/address-book/dependents";
export type KinvaultKintinDetailsAddressBookDependentsParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookDependentsURI
>;

/** Trustees */
export const KinvaultKintinDetailsAddressBookTrusteesRoute =
  "KinvaultKintinDetailsAddressBookTrustees";
const KinvaultKintinDetailsAddressBookTrusteesURI =
  "/kinvault/:kintin/address-book/trustees";
export type KinvaultKintinDetailsAddressBookTrusteesParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookTrusteesURI
>;

/** Advisers */
export const KinvaultKintinDetailsAddressBookAdvisersRoute =
  "KinvaultKintinDetailsAddressBookAdvisers";
const KinvaultKintinDetailsAddressBookAdvisersURI =
  "/kinvault/:kintin/address-book/advisers";
export type KinvaultKintinDetailsAddressBookAdvisersParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookAdvisersURI
>;

/** Exclusions */
export const KinvaultKintinDetailsAddressBookExclusionsRoute =
  "KinvaultKintinDetailsAddressBookExclusions";
const KinvaultKintinDetailsAddressBookExclusionsURI =
  "/kinvault/:kintin/address-book/exclusions";
export type KinvaultKintinDetailsAddressBookExclusionsParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookExclusionsURI
>;

/** All */
export const KinvaultKintinDetailsAddressBookAllRoute =
  "KinvaultKintinDetailsAddressBookAll";
const KinvaultKintinDetailsAddressBookAllURI =
  "/kinvault/:kintin/address-book/all";
export type KinvaultKintinDetailsAddressBookAllParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookAllURI
>;

/** Executors */
export const KinvaultKintinDetailsAddressBookExecutorsRoute =
  "KinvaultKintinDetailsAddressBookExecutors";
const KinvaultKintinDetailsAddressBookExecutorsURI =
  "/kinvault/:kintin/address-book/executors";
export type KinvaultKintinDetailsAddressBookExecutorsParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookExecutorsURI
>;

/** Attorneys */
export const KinvaultKintinDetailsAddressBookAttorneysRoute =
  "KinvaultKintinDetailsAddressBookAttorneys";
const KinvaultKintinDetailsAddressBookAttorneysURI =
  "/kinvault/:kintin/address-book/attorneys";
export type KinvaultKintinDetailsAddressBookAttorneysParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookAttorneysURI
>;

/** Children */
export const KinvaultKintinDetailsAddressBookChildrenRoute =
  "KinvaultKintinDetailsAddressBookChildren";
const KinvaultKintinDetailsAddressBookChildrenURI =
  "/kinvault/:kintin/address-book/children";
export type KinvaultKintinDetailsAddressBookChildrenParams = RouteParamObject<
  typeof KinvaultKintinDetailsAddressBookChildrenURI
>;

/** Beneficiaries */
export const KinvaultKintinDetailsAddressBookBeneficiariesRoute =
  "KinvaultKintinDetailsAddressBookBeneficiaries";
const KinvaultKintinDetailsAddressBookBeneficiariesURI =
  "/kinvault/:kintin/address-book/beneficiaries";
export type KinvaultKintinDetailsAddressBookBeneficiariesParams =
  RouteParamObject<typeof KinvaultKintinDetailsAddressBookBeneficiariesURI>;

/** GiftRecipients */
export const KinvaultKintinDetailsAddressBookGiftRecipientsRoute =
  "KinvaultKintinDetailsAddressBookGiftRecipients";
const KinvaultKintinDetailsAddressBookGiftRecipientsURI =
  "/kinvault/:kintin/address-book/gift-recipients";
export type KinvaultKintinDetailsAddressBookGiftRecipientsParams =
  RouteParamObject<typeof KinvaultKintinDetailsAddressBookGiftRecipientsURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsAddressBookRoute,
    path: KinvaultKintinDetailsAddressBookURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsAddressBook" */ "./KintinDetails.AddressBook.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "KintinDetailsAddressBook" */ "./KintinDetails.AddressBook.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
    },
    children: [
      {
        name: KinvaultKintinDetailsAddressBookCharitiesRoute,
        path: KinvaultKintinDetailsAddressBookCharitiesURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookCharities" */ "./KintinDetails.AddressBook.Charities.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookGuardiansRoute,
        path: KinvaultKintinDetailsAddressBookGuardiansURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookGuardians" */ "./KintinDetails.AddressBook.Guardians.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookAccountHoldersRoute,
        path: KinvaultKintinDetailsAddressBookAccountHoldersURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookAccountHolders" */ "./KintinDetails.AddressBook.AccountHolders.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookCompaniesRoute,
        path: KinvaultKintinDetailsAddressBookCompaniesURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookCompanies" */ "./KintinDetails.AddressBook.Companies.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookAddressesRoute,
        path: KinvaultKintinDetailsAddressBookAddressesURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookAddresses" */ "./KintinDetails.AddressBook.Addresses.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookDependentsRoute,
        path: KinvaultKintinDetailsAddressBookDependentsURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookDependents" */ "./KintinDetails.AddressBook.Dependents.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookTrusteesRoute,
        path: KinvaultKintinDetailsAddressBookTrusteesURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookTrustees" */ "./KintinDetails.AddressBook.Trustees.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookAdvisersRoute,
        path: KinvaultKintinDetailsAddressBookAdvisersURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookAdvisers" */ "./KintinDetails.AddressBook.Advisers.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookExclusionsRoute,
        path: KinvaultKintinDetailsAddressBookExclusionsURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookExclusions" */ "./KintinDetails.AddressBook.Exclusions.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookAllRoute,
        path: KinvaultKintinDetailsAddressBookAllURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookAll" */ "./KintinDetails.AddressBook.All.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookExecutorsRoute,
        path: KinvaultKintinDetailsAddressBookExecutorsURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookExecutors" */ "./KintinDetails.AddressBook.Executors.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookAttorneysRoute,
        path: KinvaultKintinDetailsAddressBookAttorneysURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookAttorneys" */ "./KintinDetails.AddressBook.Attorneys.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookChildrenRoute,
        path: KinvaultKintinDetailsAddressBookChildrenURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookChildren" */ "./KintinDetails.AddressBook.Children.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookBeneficiariesRoute,
        path: KinvaultKintinDetailsAddressBookBeneficiariesURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookBeneficiaries" */ "./KintinDetails.AddressBook.Beneficiaries.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
      {
        name: KinvaultKintinDetailsAddressBookGiftRecipientsRoute,
        path: KinvaultKintinDetailsAddressBookGiftRecipientsURI,
        component: () =>
          import(
            /* webpackChunkName: "KintinDetailsAddressBookGiftRecipients" */ "./KintinDetails.AddressBook.GiftRecipients.vue"
          ),
        meta: {
          breadcrumbs: KinvaultKintinDetailsAddressBookBreadCrumb,
        },
      },
    ],
    redirect: {
      name: KinvaultKintinDetailsAddressBookAccountHoldersRoute,
    },
  },
];
