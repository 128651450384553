<template>
  <div class="will will-highlight">
    <Will
      class="will"
      v-show="options.document === 'will'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowNrb
      class="mow-nrb"
      v-show="options.document === 'mow-nrb'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowIip
      class="mow-iip"
      v-show="options.document === 'mow-iip'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowRes
      class="mow-res"
      v-show="options.document === 'mow-res'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
    <MowBusiness
      class="mow-business"
      v-show="options.document === 'mow-business'"
      @edit-section="(data) => $emit('edit-section', data)"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "DocumentContainerWrapper",
  "imports": {
    "MowBusinessDocumentWrapper": "@/module/kinvault.kintin/component/will-builder/document/MowBusiness.test",
    "MowIipDocumentWrapper": "@/module/kinvault.kintin/component/will-builder/document/MowIIP.test",
    "MowNrbDocumentWrapper": "@/module/kinvault.kintin/component/will-builder/document/MowNRB.test",
    "MowResDocumentWrapper": "@/module/kinvault.kintin/component/will-builder/document/MowRES.test",
    "WillDocumentWrapper": "@/module/kinvault.kintin/component/will-builder/document/Will.test"
  },
  "methods": {
    "will": {
      "type": "to-one",
      "selector": ".will",
      "wrapper": "WillDocumentWrapper"
    },
    "mowBusiness": {
      "type": "to-one",
      "selector": ".mow-business",
      "wrapper": "MowBusinessDocumentWrapper"
    },
    "mowIip": {
      "type": "to-one",
      "selector": ".mow-iip",
      "wrapper": "MowIipDocumentWrapper"
    },
    "mowNrb": {
      "type": "to-one",
      "selector": ".mow-nrb",
      "wrapper": "MowNrbDocumentWrapper"
    },
    "mowRes": {
      "type": "to-one",
      "selector": ".mow-res",
      "wrapper": "MowResDocumentWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import MowBusiness from "@/module/kinvault.kintin/component/will-builder/document/MowBusiness.vue";
import MowIip from "@/module/kinvault.kintin/component/will-builder/document/MowIIP.vue";
import MowNrb from "@/module/kinvault.kintin/component/will-builder/document/MowNRB.vue";
import MowRes from "@/module/kinvault.kintin/component/will-builder/document/MowRES.vue";
import Will from "@/module/kinvault.kintin/component/will-builder/document/Will.vue";
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentInnerContainer",
  mixins: [WillBuilderService.mixin],
  components: {
    MowBusiness,
    MowIip,
    MowNrb,
    MowRes,
    Will,
  },
  emits: {
    "edit-section": (section: {
      section: WillBuilderSections;
      custom?: string;
      generated: string;
    }) => !!section,
  },
});
</script>
