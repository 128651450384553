import { Api } from "@/service/api.service";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface UpdateBrandedKinvaultInvitationMessage {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface UpdateBrandedKinvaultInvitationResponse {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export const UpdateBrandedKinvaultInvitationHandler = async (
  message: UpdateBrandedKinvaultInvitationMessage,
): Promise<UpdateBrandedKinvaultInvitationResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}",
    {
      brandedKinvaultInvitation: message.brandedKinvaultInvitation.id,
    },
  )
    .method("patch")

    .body({
      brandedKinvault: message.brandedKinvaultInvitation.$data.brandedKinvault,
      createdBy: message.brandedKinvaultInvitation.$data.createdBy,
      profile: message.brandedKinvaultInvitation.$data.profile,
      signUpToken: message.brandedKinvaultInvitation.$data.signUpToken,
      externalRef: message.brandedKinvaultInvitation.$data.externalRef,
      status: message.brandedKinvaultInvitation.$data.status,
      sentAt: message.brandedKinvaultInvitation.$data.sentAt,
      completedAt: message.brandedKinvaultInvitation.$data.completedAt,
      declinedAt: message.brandedKinvaultInvitation.$data.declinedAt,
      introducerContact:
        message.brandedKinvaultInvitation.$data.introducerContact,
      isBillable: message.brandedKinvaultInvitation.$data.isBillable,
    })
    .result();

  const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
    response.brandedKinvaultInvitation,
  ).first();

  if (!brandedKinvaultInvitation) {
    throw new Error("BrandedKinvaultInvitation not found");
  }

  return {
    brandedKinvaultInvitation,
  };
};
