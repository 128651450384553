<template>
  <Badge
    v-if="type === 'advised'"
    text="A"
    color="is-success"
    size="is-small"
    tool-tip="Advised"
  />
  <Badge
    v-else-if="type === 'hosted'"
    text="H"
    color="is-info"
    size="is-small"
    tool-tip="Hosted"
  />
  <Badge
    v-else-if="type === 'lpasonly'"
    text="L"
    size="is-small"
    tool-tip="LPAs only"
  />
  <Badge
    v-else-if="type === 'lite'"
    text="LT"
    size="is-small"
    color="is-purple"
    tool-tip="Lite"
  />
  <Badge
    v-else-if="type === 'placeholder'"
    text="P"
    color="is-light-grey"
    size="is-small"
    tool-tip="Placeholder"
  />
</template>

<script lang="ts">
import { Badge } from "@kinherit/framework/component.display/badge";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountTypeBadge",
  components: { Badge },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
});
</script>
