import {
  AccountReferralCodeField,
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";

import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { yearOptions } from "../data/filter-options";

// Sets the default year to the current year
const thisYear = new Date().getFullYear();

// Sets the default start and end dates to the first and last days of the current year
const defaultStartDate = new Date(thisYear, 0, 1).getTime();
const defaultEndDate = new Date(thisYear, 11, 31).getTime();

export const ReadLeadsByEpReportFilters = () =>
  MasterListFiltersForm({
    name: "read-leads-by-ep-report-filters",
    props: SharedFilterProps,
    data: () => ({
      referral: null as string | null,
      referrerCategory: null as string | null,
      compareTo: null as string | null,
      created: [defaultStartDate, defaultEndDate] as [number, number] | null,
    }),
  })({
    basicComponents: () => ({
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
            label: "Date Range",
          },
          simplifyData: true,
        }),
      ],
      referral: [
        AccountReferralCodeField({
          props: {
            vModel: "referral",
            label: "Referral Code",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
      referrerCategory: [
        OptionsAutoCompleteField({
          group: "referralSources",
          vModel: "referrerCategory",
          props: {
            label: "Referral Category",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      compareTo: [
        FormSelectField({
          props: {
            label: "Compare To",
            options: yearOptions,
            size: "is-small",
            placeholder: "No Comparison",
            reference: "compareTo",
          },
          models: {
            value: "compareTo",
          },
        }),
      ],
    }),
    // advancedComponents: () => ({}),
  });
