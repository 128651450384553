<template>
  <p v-if="data">
    <strong>Shares to Reserve Beneficiaries</strong><br />
    In the event that all of the Beneficiaries listed above, have died at a time
    when funds remain in the Trust Fund undistributed, I would wish you to
    exercise your powers in relation to the Trust Fund to utilise the remaining
    funds for the benefit of
    <span
      v-html="
        helpers.var(
          helpers.formatBeneficiary(data.reserveBeneficiaries.first()),
        )
      "
    />
    <span
      v-html="
        helpers.var(
          helpers.beneficiaryTheirConcern(data.reserveBeneficiaries.first()),
        )
      "
    />.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
