import { Api, Attorney, Kintin } from "@kinherit/sdk";

interface DeleteAttorneyMessage {
  kintin: string | Kintin;
  attorney: Attorney;
}

interface DeleteAttorneyResponse {
  [key: string]: never;
}

export const DeleteAttorneyHandler = async (
  message: DeleteAttorneyMessage,
): Promise<DeleteAttorneyResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-attorney/{kintin}/person/attorney/{attorney}",
    {
      kintin: message.kintin,
      attorney: message.attorney.$data.id,
    },
  )
    .method("delete")
    .result();

  message.attorney.$delete();

  return {};
};
