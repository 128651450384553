import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { EmailCampaign } from "@kinherit/sdk";

export const CreateEmailCampaignForm = (emailCampaign: EmailCampaign) => {
  return defineForm({
    name: "create-email-campaign-form",
    data: () => ({
      emailCampaign,
      include: null as null | "registrations" | "participants" | "both",
    }),
    formAreas: (data) => [
      defineFormArea({
        name: `create-email-campaign-form-area`,
        data,
        template: GridLayout([["name", "include"], ["description"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: `Name`,
              },
              models: {
                value: "emailCampaign.name",
              },
            }),
          ],
          description: [
            FormTextField({
              props: {
                label: `Description`,
              },
              models: {
                value: "emailCampaign.description",
              },
            }),
          ],
          include: [
            FormSelectField({
              props: {
                label: "Email campaign memebers",
                options: {
                  registrations: "Registrations",
                  participants: "Participants",
                  both: "Both",
                },
              },
              models: {
                value: "include",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
