import { Api } from "@/service/api.service";
import {
  IntroducerCompany,
  IntroducerContact,
  IntroducerOutsource,
  Kintin,
  Lead,
  Note,
  Person,
} from "@kinherit/sdk";

type DeleteNoteMessage = {
  note: Note;
} & (
  | {
      kintin: Kintin | string;
    }
  | {
      lead: Lead | string;
    }
  | {
      introducerCompany: IntroducerCompany | string;
    }
  | {
      introducerContact: IntroducerContact | string;
    }
  | {
      introducerOutsource: string | IntroducerOutsource;
    }
  | {
      person: string | Person;
    }
);

type Controllers =
  | "/v2/portal/kintin-note/{owner}/notes/{note}"
  | "/v2/portal/lead-note/{owner}/notes/{note}"
  | "/v2/portal/introducer-company-note/{owner}/notes/{note}"
  | "/v2/portal/introducer-contact-note/{owner}/notes/{note}"
  | "/v2/portal/officer-note/{owner}/notes/{note}"
  | "/v2/portal/introducer-outsource-note/{owner}/notes/{note}";

interface DeleteNoteResponse {
  [key: string]: never;
}

export const DeleteNoteHandler = async (
  message: DeleteNoteMessage,
): Promise<DeleteNoteResponse> => {
  let path = "" as any;
  const params = {
    note: message.note,
  } as any;

  if ("kintin" in message && message.kintin) {
    path = "/v2/portal/kintin-note/{owner}/notes/{note}";
    params.owner = message.kintin;
  } else if ("lead" in message && message.lead) {
    path = "/v2/portal/lead-note/{owner}/notes/{note}";
    params.owner = message.lead;
  } else if ("introducerCompany" in message && message.introducerCompany) {
    path = "/v2/portal/introducer-company-note/{owner}/notes/{note}";
    params.owner = message.introducerCompany;
  } else if ("introducerContact" in message && message.introducerContact) {
    path = "/v2/portal/introducer-contact-note/{owner}/notes/{note}";
    params.owner = message.introducerContact;
  } else if ("introducerOutsource" in message && message.introducerOutsource) {
    path = "/v2/portal/introducer-outsource-note/{owner}/notes/{note}";
    params.owner = message.introducerOutsource;
  } else if ("person" in message && message.person) {
    path = "/v2/portal/officer-note/{owner}/notes/{note}";
    params.owner = message.person;
  } else {
    throw new Error("Invalid message");
  }

  await Api.resource("portal", path as Controllers, params)
    .method("delete")
    .result();

  message.note.$delete();

  return {};
};
