import { Api } from "@/service/api.service";
import { Kintin, Lead, Option } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";

interface ConvertLeadToKintinMessage {
  lead: Lead;
  type: Option;
  status: Option;
  nextAction: DateTime;
  notify: boolean;
}

interface ConvertLeadToKintinResponse {
  kintin: Kintin;
}

export const ConvertLeadToKintinHandler = async (
  message: ConvertLeadToKintinMessage,
): Promise<ConvertLeadToKintinResponse> => {
  const request = Api.resource("portal", "/v2/portal/lead/{lead}/to-kintin", {
    lead: message.lead.id,
  })
    .method("post")

    .body({
      type: message.type.id,
      status: message.status.id,
      nextAction: message.nextAction.formatMachine,
      notify: message.notify,
    });

  const response = await request.result();

  const kintin = Kintin.$inflate(response.kintin).first();

  if (!kintin) {
    throw new Error("Unable to create Kintin");
  }

  return {
    kintin,
  };
};
