import { Api } from "@/service/api.service";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface RecordIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem | string;
}

export interface RecordIntroducerFeePaymentItemResponse {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export const RecordIntroducerFeePaymentItemHandler = async (
  message: RecordIntroducerFeePaymentItemMessage,
): Promise<RecordIntroducerFeePaymentItemResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-item/{introducerFeePaymentItem}",
    message,
  ).method("get");

  const response = await request.result();

  const introducerFeePaymentItem = IntroducerFeePaymentItem.$inflate(
    response.introducerFeePaymentItem,
  ).first();

  if (!introducerFeePaymentItem) {
    throw new Error("Failed to find introducerFeePaymentItem");
  }

  return {
    introducerFeePaymentItem,
  };
};
