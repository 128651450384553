<template>
  <div v-if="kintin">
    <CallScriptSection title="Pre-call" :speed="false">
      Prepare in advance what you will say in your opening sections to put them
      at ease (based on notes, or otherwise). Suggest you focus on pre-preparing
      <b>one</b> opening line and <b>two</b> follow-up lines. Ie how the call
      was set up, followed by a closed statement ending “… get your Powers of
      Attorney sorted”, and finally a more open line that encourages engagement.
    </CallScriptSection>
    <CallScriptSection title="Preamble">
      <b
        >Good morning/afternoon. It's
        {{ $auth.loggedInUser?.profile.firstName }} from Kinherit. Am I speaking
        to {{ primaryProfile?.fullName
        }}{{
          secondaryProfile?.fullName ? ` / ${secondaryProfile?.fullName}` : ""
        }}?
      </b>
      <br />
      <em>[pause]</em>
      <br /><br />
      <b>
        I understand [you had a good chat with x, who booked this call / you use
        XYZ as your Financial Adviser, who introduced us]. He mentioned that you
        [_______________] and are looking to get your Powers of Attorney sorted.
        He said [__________________] is that right?
      </b>
    </CallScriptSection>
    <CallScriptReferralSection
      :checks="checks"
      :kintinChecks="kintinChecks"
      :kintin="kintin"
      step="2.3.1"
    />
  </div>
</template>

<script lang="ts">
import CallScriptReferralSection from "@/module/kinvault.kintin/component/call-script/shared/ReferralSection.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IntroTab",
  mixins: [AuthService.mixin(), KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
    CallScriptReferralSection,
  },
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
});
</script>
