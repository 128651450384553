import {
  AdminBrandedKinvaultDetailsSubscriptionsBreadCrumb,
  AdminBrandedKinvaultDetailsSubscriptionsReferralCodesBreadCrumb,
} from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsSubscriptionsRoute =
  "BrandedKinvaultDetailsSubscriptions";
export const BrandedKinvaultDetailsSubscriptionsURI =
  "/branded-kinvaults/:brandedKinvault/subscriptions";
export type BrandedKinvaultDetailsSubscriptionsParams = RouteParamObject<
  typeof BrandedKinvaultDetailsSubscriptionsURI
>;

export const BrandedKinvaultDetailsSubscriptionsReferralCodesRoute =
  "BrandedKinvaultDetailsSubscriptionsReferralCodes";
export const BrandedKinvaultDetailsSubscriptionsReferralCodesURI =
  "/branded-kinvault/:brandedKinvault/subscription/referral-codes";
export type BrandedKinvaultDetailsSubscriptionsReferralCodesParams =
  RouteParamObject<typeof BrandedKinvaultDetailsSubscriptionsReferralCodesURI>;

export const BrandedKinvaultDetailsSubscriptionsIndexRoute =
  "BrandedKinvaultDetailsSubscriptionsIndexRoute";
export const BrandedKinvaultDetailsSubscriptionsIndexURL =
  "/branded-kinvault/:brandedKinvault/Subscriptions";

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsSubscriptionsIndexRoute,
    path: BrandedKinvaultDetailsSubscriptionsIndexURL,
    component: () => import("./Index.vue"),
    redirect: {
      name: BrandedKinvaultDetailsSubscriptionsRoute,
    },
    children: [
      {
        name: BrandedKinvaultDetailsSubscriptionsRoute,
        path: BrandedKinvaultDetailsSubscriptionsURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "BrandedKinvaultDetailsSubscriptions" */ "./BrandedKinvaultDetails.Subscriptions.vue"
            ),
        },
        meta: {
          breadcrumbs: AdminBrandedKinvaultDetailsSubscriptionsBreadCrumb,
        },
      },
      {
        name: BrandedKinvaultDetailsSubscriptionsReferralCodesRoute,
        path: BrandedKinvaultDetailsSubscriptionsReferralCodesURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "BrandedKinvaultDetailsSubscriptionReferralCodes" */ "./BrandedKinvaultDetails.SubscriptionReferralCodes.vue"
            ),
        },
        meta: {
          breadcrumbs:
            AdminBrandedKinvaultDetailsSubscriptionsReferralCodesBreadCrumb,
        },
      },
    ],
  },
];
