import { Api } from "@/service/api.service";

interface Message {
  file: File;
}

interface Response {
  duplicateProfiles: {
    emailAddress: string;
    profiles: string[];
  }[];
  emailAddressColumnTitle: string;
  unmatchedEmailAddresses: string[];
}

export const ValidateEmailCampaignMembersCsvHandler = async (
  message: Message,
): Promise<Response> => {
  const { file } = message;

  return await Api.resource(
    "portal",
    "/v2/portal/email-campaign/members/csv/validate",
  )
    .method("put")

    .files({
      uploadedFile: file,
    })
    .result();
};
