import { Api } from "@/service/api.service";

import { components } from "@kinherit/sdk/schema/schema-portal";

export const ComputeSummaryReportHandler = async (): Promise<{
  priorWeek: components["schemas"]["ComputeSummaryReportSectionResponse"];
  wtd: components["schemas"]["ComputeSummaryReportSectionResponse"];
  mtd: components["schemas"]["ComputeSummaryReportSectionResponse"];
  qtd: components["schemas"]["ComputeSummaryReportSectionResponse"];
  last12Months: components["schemas"]["ComputeSummaryReportCashResponse"];
}> => {
  const response = await Api.resource("portal", "/v2/portal/report/summary", {})
    .method("get")
    .result();
  return response;
};
