<template>
  <div class="trustee-summary">
    <TrusteeSummary
      v-for="role in trustees.sortBy('sortOrder', 'asc')"
      :key="`trustee-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!trustees.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "TrusteeListWrapper",
  "imports": {
    "TrusteeSummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/TrusteeSummary.test"
  },
  "methods": {
    "trustees": {
      "type": "to-many",
      "selector": ".trustee-summary",
      "wrapper": "TrusteeSummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import TrusteeSummary from "@/module/kinvault.kintin/component/summary-cards/TrusteeSummary.vue";
import { UpdateTrusteeForm } from "@/module/kinvault.kintin/form/update-trustee.form";
import Card from "@kinherit/framework/component.layout/card";
import { Kintin, Trustee } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TrusteeList",
  components: { TrusteeSummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    trustees: {
      type: Array as () => Trustee[],
      required: true,
    },
  },
  methods: {
    async edit(trustee: Trustee): Promise<void> {
      try {
        await UpdateTrusteeForm({
          data: trustee,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Trustee",
          },
        });
      } catch {
        trustee.$restore();
        return;
      }

      const kintin = this.kintin;

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
      //   person: trustee.person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.update({
        person: trustee.person,
        kintin: this.kintin,
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/trustee/update", {
      //   trustee,
      //   kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.trustee.update({
        trustee,
        kintin,
      });

      this.$emit("reload");
    },
    async remove(trustee: Trustee): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Trustee",
          message: "Are you sure you want to delete this trustee?",
        },
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/trustee/delete", {
      //   trustee,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.trustee.delete({
        trustee,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
