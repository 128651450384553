import { StyleService } from "@/service/style.service";
import { FormTable } from "@kinherit/framework/component.display/table";
import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormCard } from "@kinherit/framework/component.layout/card";
import { FormLevel } from "@kinherit/framework/component.layout/level";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Currency } from "@kinherit/ts-common/index";
import { reactive } from "vue";
import { ReadKintinIntroducerFeeResponse } from "../action/read-introducer-fee-kintin.action";

type IntroducerData = {
  introducer: {
    id: string | null;
    name: string | null;
    kintinXeroContactId: string | null;
  } | null;
  contract: { id: string | null; datedAt: number | null } | null;
  fees: ReadKintinIntroducerFeeResponse["data"];
  canPushToXero: boolean;
  canDownloadRemittance: boolean;
};

export const UpdateIntroducerFeeKintinForm = (
  fees: ReadKintinIntroducerFeeResponse["data"],
) => {
  const localData = reactive({
    tab: 0,
    selectedDownloadOption: {} as Record<string, string>,
  });
  const introducerRows: Array<IntroducerData> = fees
    .map((fee) => fee.data.company)
    .filter(
      (company, index, self) =>
        self.findIndex(
          (c) => c.kintinXeroContactId === company.kintinXeroContactId,
        ) === index,
    )
    .map((introducer) => ({
      introducer: introducer ?? null,
      fees: fees.filter(
        (fee) =>
          fee.data.company?.kintinXeroContactId ===
          introducer?.kintinXeroContactId,
      ),
    }))
    .map((data) => ({
      ...data,
      contract: data.fees[0].data.companyContract,
      canPushToXero: data.fees.every((fee) => {
        console.log(fee.data.order.xeroIntroducerBillId);
        return !fee.data.order.xeroIntroducerBillId;
      }),
      canDownloadRemittance: data.fees.every(
        (fee) => !!fee.data.order.xeroIntroducerBillId,
      ),
    }))
    .sort((a, b) => {
      if (a.introducer === null) return -1;
      if (b.introducer === null) return 1;
      return (a.introducer.name ?? "") < (b.introducer.name ?? "") ? -1 : 1;
    });

  const agentRows: Array<IntroducerData> = fees
    .map((fee) => fee.data.agentCompany)
    .filter(
      (agent, index, self) =>
        self.findIndex(
          (c) => c.kintinXeroContactId === agent.kintinXeroContactId,
        ) === index,
    )
    .map((agent) => ({
      introducer: agent ?? null,
      fees: fees.filter(
        (fee) =>
          fee.data.agentCompany?.kintinXeroContactId ===
          agent?.kintinXeroContactId,
      ),
      contract: null, //? check this
    }))
    .map((data) => ({
      ...data,
      contract: data.fees[0].data.agentContract,
      canPushToXero: data.fees.every((fee) => !fee.data.order.xeroAgentBillId),
      canDownloadRemittance: data.fees.every(
        (fee) => !!fee.data.order.xeroAgentBillId,
      ),
    }))
    .sort((a, b) => {
      if (a.introducer === null) return -1;
      if (b.introducer === null) return 1;
      return (a.introducer.name ?? "") < (b.introducer.name ?? "") ? -1 : 1;
    });

  const parentAgentRows: Array<IntroducerData> = fees
    .map((fee) => fee.data.parentAgentCompany)
    .filter(
      (parentAgent, index, self) =>
        self.findIndex(
          (c) => c.kintinXeroContactId === parentAgent.kintinXeroContactId,
        ) === index,
    )
    .map((parentAgent) => ({
      introducer: parentAgent ?? null,
      fees: fees.filter(
        (fee) =>
          fee.data.parentAgentCompany?.kintinXeroContactId ===
          parentAgent?.kintinXeroContactId,
      ),
      contract: null, //? check this
    }))
    .map((data) => ({
      ...data,
      contract: data.fees[0].data.parentAgentContract,
      canPushToXero: data.fees.every(
        (fee) => !fee.data.order.xeroParentAgentBillId,
      ),
      canDownloadRemittance: data.fees.every(
        (fee) => !!fee.data.order.xeroParentAgentBillId,
      ),
    }))
    .sort((a, b) => {
      if (a.introducer === null) return -1;
      if (b.introducer === null) return 1;
      return (a.introducer.name ?? "") < (b.introducer.name ?? "") ? -1 : 1;
    });

  const createRows = (
    type: "introducer" | "agent" | "parentAgent",
    data: Array<IntroducerData>,
  ) =>
    data.map((data: IntroducerData) =>
      FormCard<typeof fees>({
        props: {
          title: data.introducer?.name
            ? `${data.introducer?.name}`
            : "No Contact Found",
          subtitle: data.introducer?.kintinXeroContactId ?? `Not On Xero`,
        },
        slots: {
          buttons: [
            FormButton({
              props: {
                text: "View Contact on Xero",
                ariaLabel: "View Contact on Xero",
                vIf: null !== data.introducer?.kintinXeroContactId,
                href: `https://go.xero.com/Contacts/View/${data.introducer?.kintinXeroContactId}`,
                target: "_blank",
                reference: "viewContact",
                size: "is-small",
              },
            }),
          ],
          default: [
            FormTable({
              props: {
                isFixed: true,
                isNarrow: true,
                columns: [
                  {
                    title: "Company",
                    map: (row: IntroducerData["fees"][number]) =>
                      row.data.company.name,
                  },
                  {
                    title: "XeroId",
                    map: (row: IntroducerData["fees"][number]) =>
                      row.data.company.kintinXeroContactId,
                  },
                  {
                    title: "Client",
                    map: (row: IntroducerData["fees"][number]) =>
                      row.data.client.ref,
                  },
                  {
                    map: (row: IntroducerData["fees"][number]) =>
                      row.data.client.name,
                  },
                  {
                    title: "Introducer Fee",
                    mapHtml: (row: IntroducerData["fees"][number]) => {
                      return `<strong class='${
                        row.data.order.feesPayOutIntroDate
                          ? "has-text-success"
                          : row.data.order.xeroIntroducerBillId
                            ? "has-text-info"
                            : "has-text-positive"
                      }'>
                        ${
                          new Currency({
                            amount:
                              row.data.companyContract.vat === "gross"
                                ? row.calculations.introducerFeeGross
                                : row.calculations.introducerFeeNet,
                          }).format
                        }
                         (${
                           row.data.companyContract.vat === "gross"
                             ? "gross"
                             : "net"
                         })
                        ${
                          row.data.order.feesPayOutIntroDate
                            ? "Paid"
                            : row.data.order.xeroIntroducerBillId
                              ? "On Xero"
                              : "Unpaid"
                        }</strong>`;
                    },
                    hidden: type !== "introducer",
                  },
                  {
                    title: "Agent Fee",
                    mapHtml: (row: IntroducerData["fees"][number]) => {
                      return `<strong class='${
                        row.data.order.feesPayOutAgentDate
                          ? "has-text-success"
                          : row.data.order.xeroAgentBillId
                            ? "has-text-info"
                            : "has-text-positive"
                      }'>${
                        new Currency({
                          amount: row.calculations.agentFee.fee,
                        }).format
                      }   ${
                        row.data.order.feesPayOutAgentDate
                          ? "Paid"
                          : row.data.order.xeroAgentBillId
                            ? "On Xero"
                            : "Unpaid"
                      }</strong>`;
                    },
                    hidden: type !== "agent",
                  },
                  {
                    title: "Parent Agent Fee",
                    mapHtml: (row: IntroducerData["fees"][number]) => {
                      return `<strong class='${
                        row.data.order.feesPayOutParentAgentDate
                          ? "has-text-success"
                          : row.data.order.xeroParentAgentBillId
                            ? "has-text-info"
                            : "has-text-positive"
                      }'>${
                        new Currency({
                          amount: row.calculations.parentAgentFee.fee,
                        }).format
                      }   ${
                        row.data.order.feesPayOutParentAgentDate
                          ? "Paid"
                          : row.data.order.xeroParentAgentBillId
                            ? "On Xero"
                            : "Unpaid"
                      }</strong>`;
                    },
                    hidden: type !== "parentAgent",
                  },
                ],
                rows: data.fees,
              },
            }),
            FormLevel({
              props: {
                // vIf: data.canPushToXero || data.canDownloadRemittance,
                class: "mt-4",
              },
              slots: {
                left: [
                  FormButton({
                    props: {
                      text: "Create Bill in Xero",
                      ariaLabel: "Create Bill in Xero",
                      // vIf: data.canPushToXero,
                      color: "is-positive",
                      size: "is-small",
                      reference: "pushToXero",
                    },
                    emits: {
                      click: async () => {
                        await OpenAlertDialog({
                          dialog: {
                            title: `Confirm`,
                            message: `Are you sure you want push this to Xero?`,
                          },
                        });

                        // await window.Kernel.ActionBus.execute(
                        //   "introducer-fee/invoice/create",
                        //   {
                        //     introducerCompany: data.introducer?.id as string,
                        //     introducerCompanyType:
                        //       "introducer" === type ? "introducer" : "agent",
                        //     feesType: type,
                        //     brand: "kinherit",
                        //     introducerContract: data.contract?.id as string,
                        //     orders: data.fees.map((fee) => fee.data.order.id),
                        //   },
                        // );

                        await window.Kernel.ActionBus.introducerFeeKintin.create(
                          {
                            introducerCompany: data.introducer?.id as string,
                            introducerCompanyType:
                              "introducer" === type ? "introducer" : "agent",
                            feesType: type,
                            brand: "kinherit",
                            introducerContract: data.contract?.id as string,
                            orders: data.fees.map((fee) => fee.data.order.id),
                          },
                        );
                      },
                    },
                  }),
                ],
                right: [
                  FormLevel({
                    slots: {
                      left: [
                        FormSelectField({
                          props: {
                            isFullwidth: false,
                            // vIf: data.canDownloadRemittance,
                            options: {
                              "comp-adv-cli-pro":
                                "Company > Adviser > Client > Product",
                              "comp-adv-cli": "Company > Adviser > Client",
                              "comp-adv": "Company > Adviser",
                              comp: "Company Only",
                              "flying-colours": "Adviser > Client > Product",
                              "client-by-adviser": "Adviser > Client",
                              "client-only": "Client details only",
                              "amount-only": "Amount only",
                              "sort-refer": "Custom: SortRefer",
                            },
                            reference: "downloadOption",
                          },
                          models: {
                            value: {
                              get: () =>
                                localData.selectedDownloadOption[
                                  data.introducer?.id ?? "no-xero-contact"
                                ],
                              set: (value) =>
                                (localData.selectedDownloadOption[
                                  data.introducer?.id ?? "no-xero-contact"
                                ] = value),
                            },
                          },
                          slots: {
                            right: [
                              FormButton({
                                props: {
                                  iconLeft: StyleService.icon.download.icon,
                                  ariaLabel: "Download Remittance Advice",
                                  reference: "download",
                                },
                                emits: {
                                  click: async () => {
                                    console.log(data);
                                    // await window.Kernel.ActionBus.execute(
                                    //   "introducer-fee/kintin/download",
                                    //   {
                                    //     introducerName:
                                    //       data.introducer?.name ?? "",
                                    //     introducerCompany: data.introducer
                                    //       ?.id as string,
                                    //     introducerCompanyType:
                                    //       "introducer" === type
                                    //         ? "introducer"
                                    //         : "agent",
                                    //     downloadType: localData
                                    //       .selectedDownloadOption[
                                    //       data.introducer?.id ??
                                    //         "no-xero-contact"
                                    //     ] as
                                    //       | "comp-adv-cli-pro"
                                    //       | "comp-adv-cli"
                                    //       | "comp-adv"
                                    //       | "comp"
                                    //       | "client-by-adviser"
                                    //       | "client-only"
                                    //       | "amount-only"
                                    //       | "flying-colours"
                                    //       | "sort-refer",
                                    //     feesType: type,
                                    //     brand: "kinherit",
                                    //     introducerContract: data.contract
                                    //       ?.id as string,
                                    //     orders: data.fees.map(
                                    //       (fee) => fee.data.order.id,
                                    //     ),
                                    //   },
                                    // );

                                    await window.Kernel.ActionBus.introducerFeeKintin.download(
                                      {
                                        introducerName:
                                          data.introducer?.name ?? "",
                                        introducerCompany: data.introducer
                                          ?.id as string,
                                        introducerCompanyType:
                                          "introducer" === type
                                            ? "introducer"
                                            : "agent",
                                        downloadType: localData
                                          .selectedDownloadOption[
                                          data.introducer?.id ??
                                            "no-xero-contact"
                                        ] as
                                          | "comp-adv-cli-pro"
                                          | "comp-adv-cli"
                                          | "comp-adv"
                                          | "comp"
                                          | "client-by-adviser"
                                          | "client-only"
                                          | "amount-only"
                                          | "flying-colours"
                                          | "sort-refer",
                                        feesType: type,
                                        brand: "kinherit",
                                        introducerContract: data.contract
                                          ?.id as string,
                                        orders: data.fees.map(
                                          (fee) => fee.data.order.id,
                                        ),
                                      },
                                    );
                                  },
                                },
                              }),
                            ],
                          },
                        }),
                      ],
                    },
                  }),
                ],
              },
            }),
          ],
        },
      }),
    );

  return defineForm({
    name: "update-introducer-fee-kintin-form",
    data: () => fees,
    formAreas: (data) => [
      defineFormArea({
        name: "update-introducer-fee-kintin-form-area",
        data,
        components: () => ({
          default: [
            FormTabs({
              props: {
                isFullwidth: false,
                config: [
                  {
                    label: "Introducers",
                  },
                  {
                    label: "Agents",
                  },
                  {
                    label: "Parent Agents",
                  },
                ],
                style: {
                  marginTop: "-1em",
                },
                reference: "tabs",
              },
              models: {
                tab: {
                  get: () => localData.tab,
                  set: (value) => (localData.tab = value),
                },
              },
              slots: {
                introducers: createRows("introducer", introducerRows),
                agents: createRows("agent", agentRows),
                parentAgents: createRows("parentAgent", parentAgentRows),
              },
            }),
          ],
        }),
      }),
    ],
  });
};
