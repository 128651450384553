import { AdminBrandedKinvaultDetailsActivityBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsActivityRoute =
  "BrandedKinvaultDetailsActivity";
export const BrandedKinvaultDetailsActivityURI =
  "/branded-kinvaults/:brandedKinvault/teams";
export type BrandedKinvaultDetailsActivityParams = RouteParamObject<
  typeof BrandedKinvaultDetailsActivityURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsActivityRoute,
    path: BrandedKinvaultDetailsActivityURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsActivity" */ "./BrandedKinvaultDetails.Activity.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsActivityBreadCrumb,
    },
  },
];
