import { IntroducerContact } from "@kinherit/sdk";

export const IntroducerContactMasterListBreadCrumb = {
  text: "Introducer Contacts",
  route: { name: "IntroducerContactMasterList" },
};

export const IntroducerContactDetailsBreadCrumb = (params: any) => ({
  text:
    IntroducerContact.$findOne(params.introducerContact)?.profile.fullName ??
    "",
  route: { name: "IntroducerContactDetails", params },
});
