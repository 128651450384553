import { Api } from "@/service/api.service";
import { Kintin, KintinAccess, User } from "@kinherit/sdk";

export interface ReadUserAssignedKintinsMessage {
  user: string | User;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof KintinAccess;
    direction: "asc" | "desc";
  };
}

interface ReadUserAssignedKintinsResponse {
  kintinAccess: Array<KintinAccess>;
  kintins: Array<Kintin>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadUserAssignedKintinsHandler = async (
  message: ReadUserAssignedKintinsMessage,
): Promise<ReadUserAssignedKintinsResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/user/{user}/assigned-kintins",
    {
      user: "string" === typeof message.user ? message.user : message.user.id,
    },
  )
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "desc",
    })
    .paginate(message.pagination ?? false)
    .result();

  return {
    kintinAccess: KintinAccess.$inflate(
      response.kintinAccess,
      message.sort,
      response.$rootIds,
    ),
    kintins: Kintin.$inflate(response.kintin),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
