import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsSummaryBreadCrumb } from "../../../breadcrumbs";

/** Summary */
export const KinvaultKintinDetailsSummaryRoute = "KinvaultKintinDetailsSummary";
const KinvaultKintinDetailsSummaryURI = "/kinvault/:kintin";
export type KinvaultKintinDetailsSummaryParams = RouteParamObject<
  typeof KinvaultKintinDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsSummaryRoute,
    path: KinvaultKintinDetailsSummaryURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsSummary" */ "./KintinDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsSummaryBreadCrumb,
    },
  },
];
