import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { IntroducerContractStatus } from "../data/introducer-contract-status.data";

export const ReadIntroducerContractForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-contract-form",
    data: () => ({
      name: "",
      contractType: Array<string>(),
      isSigned: null as boolean | null,
      contractStatus: null as null | keyof typeof IntroducerContractStatus,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "contractType",
          group: "introducerContractType",
          props: {
            label: "Contract Type",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      status: [
        FormAutoCompleteField({
          props: {
            label: "Status",
            options: Object.values(IntroducerContractStatus),
            size: "is-small",
            placeholder: "Any",
            mapOptions: {
              value: "id",
              label: "text",
            },
            isScrollable: true,
            reference: "contractStatus",
          },
          models: {
            value: {
              get: (data) =>
                data.contractStatus
                  ? IntroducerContractStatus[data.contractStatus]
                  : null,
              set: (value, data) => (data.contractStatus = value?.value),
            },
          },
        }),
      ],
      isSigned: [
        FormSelectField({
          props: {
            label: "Is Signed",
            placeholder: "Any",
            options: {
              true: "Yes",
              false: "No",
            },
          },
          models: {
            value: "isSigned",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
