import { Model } from "@kinherit/orm";
import {
  Api,
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  emailNamedAttachment: string;
};

type Response = {};

export const DeleteEmailNamedAttachmentHandler = async (
  message: Message,
): Promise<Response> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}/named-attachment/{emailNamedAttachment}",
    [IntroducerContact.$name]:
      "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}/named-attachment/{emailNamedAttachment}",
    [Kintin.$name]:
      "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}/named-attachment/{emailNamedAttachment}",
    [Lead.$name]:
      "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}/named-attachment/{emailNamedAttachment}",
    [Person.$name]:
      "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}/named-attachment/{emailNamedAttachment}",
    [BrandedKinvault.$name]:
      "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}/named-attachment/{emailNamedAttachment}",
  } as const;

  const route = routes[message.owner.$name];

  await Api.resource("portal", route, {
    owner: message.owner,
    emailLog: message.emailLog,
    emailNamedAttachment: message.emailNamedAttachment,
  })
    .method("delete")
    .result();

  return {};
};
