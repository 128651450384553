import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallsBreadCrumb } from "../../../breadcrumbs";

/** Calls */
export const KinvaultKintinDetailsCallsRoute = "KinvaultKintinDetailsCalls";
const KinvaultKintinDetailsCallsURI = "/kinvault/:kintin/calls";
export type KinvaultKintinDetailsCallsParams = RouteParamObject<
  typeof KinvaultKintinDetailsCallsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsCallsRoute,
    path: KinvaultKintinDetailsCallsURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsCalls" */ "./KintinDetails.Calls.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "KintinDetailsCalls" */ "./KintinDetails.Calls.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsCallsBreadCrumb,
    },
  },
];
