import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsUpdateProfileRoute =
  "IntroducerContactDetailsUpdateProfile";
export const IntroducerContactDetailsUpdateProfileURI =
  "/introducer/contact/:introducerContact/profile";
export type IntroducerContactDetailsUpdateProfileParams = RouteParamObject<
  typeof IntroducerContactDetailsUpdateProfileURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsUpdateProfileRoute,
    path: IntroducerContactDetailsUpdateProfileURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsUpdateProfile" */ "./IntroducerContactDetails.UpdateProfile.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "Profile",
          route: { name: IntroducerContactDetailsUpdateProfileRoute, params },
        },
      ],
    },
  },
];
