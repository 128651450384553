import { Api } from "@/service/api.service";
import { Like } from "@kinherit/orm";
import {
  CpdSessionRegistration,
  EmailAddress,
  IProfile,
  IntroducerContact,
  PhoneNumber,
  Profile,
} from "@kinherit/sdk";

export interface ReadCpdSessionRegistrationMatchesMessage {
  cpdSessionRegistation: CpdSessionRegistration;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by?: keyof IProfile;
    direction?: "asc" | "desc";
  };
}

export interface ReadCpdSessionRegistrationMatchesResponse {
  profiles: Array<Profile>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  introducerContacts: Array<IntroducerContact>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadCpdSessionRegistrationMatchesHandler = async (
  message: ReadCpdSessionRegistrationMatchesMessage,
): Promise<ReadCpdSessionRegistrationMatchesResponse> => {
  const request = Api.resource("portal", "/v2/portal/cpd-session/profile")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerContact).where([
    {
      profile: {
        firstName: Like(message.cpdSessionRegistation.profile.firstName),
        lastName: Like(message.cpdSessionRegistation.profile.lastName),
      },
    },
    {
      profile: {
        phoneNumbers: {
          tel: Like(message.cpdSessionRegistation.profile.phoneNumbers[0].tel),
        },
      },
    },
    {
      profile: {
        emails: {
          email: Like(message.cpdSessionRegistation.profile.emails[0].email),
        },
      },
    },
  ]);

  const response = await request.result();

  return {
    profiles: Profile.$inflate(
      response.profile,
      message.sort,
      response.$rootIds,
    ),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    introducerContacts: IntroducerContact.$inflate(response.introducerContact),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
