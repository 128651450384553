import { OptionsSelectField } from "@/config/form.config";
import { CreateAccountReferralCode } from "@/config/model.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormSwitchField } from "@kinherit/framework/component.input/switch-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { AccountReferralCode } from "@kinherit/sdk";

export const AddReferralCodeForm = (
  data: {
    referralCode: AccountReferralCode | null;
  } = { referralCode: null },
) => {
  if (!data.referralCode) {
    data.referralCode = CreateAccountReferralCode();
  }
  return defineForm({
    name: "create-referral-form",
    data: () => data.referralCode as AccountReferralCode,
    formAreas: (data) => [
      defineFormArea({
        name: "create-referral-form-area",
        data,
        template: GridLayout([
          [`name`, `code`],
          [`category`, `isForUsers`],
          [`isFeeSacrifice`],
          [`isSalarySacrifice`],
          [`isFriendsAndFamily`],
          [`notes`],
        ]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
          code: [
            FormTextField({
              props: {
                label: "Code",
                validators: ["required"],
              },
              models: {
                value: "code",
              },
            }),
          ],
          category: [
            OptionsSelectField({
              props: {
                label: "Category",
              },
              vModel: "category",
              group: "referralSources",
            }),
          ],
          isForUsers: [
            FormSwitchField({
              props: {
                label: "Is for users",
              },
              models: {
                value: "isForUsers",
              },
            }),
          ],
          isFeeSacrifice: [
            FormCheckboxField({
              props: {
                label: "Sacrificing Fees",
                message:
                  "Whether the introducer is sacrificing their fee in order to give the client a discount, usually 15%",
              },
              models: {
                value: "isFeeSacrifice",
              },
            }),
          ],
          isSalarySacrifice: [
            FormCheckboxField({
              props: {
                label: "Salary Sacrifice",
                message:
                  "Employee Benefits Only: Whether the introducer has a salary sacrifice scheme, and we should invoice the company and not the client",
              },
              models: {
                value: "isSalarySacrifice",
              },
            }),
          ],
          isFriendsAndFamily: [
            FormCheckboxField({
              props: {
                label: "Friends and Family",
                message:
                  "Whether this code is for introducer friends and family where no fees are payable and there is a 30% discount",
              },
              models: {
                value: "isFriendsAndFamily",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Additional Notes for Estate Planner",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
