import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsNotesRoute =
  "IntroducerContactDetailsNotes";
export const IntroducerContactDetailsNotesURI =
  "/introducer/contact/:introducerContact/notes";
export type IntroducerContactDetailsNotesParams = RouteParamObject<
  typeof IntroducerContactDetailsNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsNotesRoute,
    path: IntroducerContactDetailsNotesURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsNotes" */ "./IntroducerContactDetails.Notes.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "Notes",
          route: {
            name: IntroducerContactDetailsNotesRoute,
            params,
          },
        },
      ],
    },
  },
];
