<template>
  <div
    v-for="(options, title) in items"
    class="report-sidebar"
    :key="`widget-${title}`"
  >
    <div v-if="title" class="report-sidebar__title">{{ title }}</div>
    <Tabs
      v-if="options.length > 0"
      v-model:value="selected"
      class="sidebar-page__tabs"
      is-vertical
      :config="options"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "ReportSidebarWrapper",
  "imports": {
    "DataListWidgetWrapper": "@/shared/component/widget/DataListWidget.test"
  },
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": ".report-sidebar__tabs",
      "items": [
        "Summary",
        "Leads",
        "Cashflow",
        "Accounts",
        "Appointments",
        "Products",
        "Doc Storage",
        "Agreements",
        "Companies",
        "Downloads",
        "Signups"
      ]
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import Tabs from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";
import {
  AccountsReportRoute,
  AgreementsReportRoute,
  AppointmentsReportRoute,
  CashflowReportRoute,
  CompaniesReportRoute,
  DocstorageReportRoute,
  DownloadReportRoute,
  LeadsReportRoute,
  ProductsReportRoute,
  SignupsReportRoute,
  SummaryReportRoute,
} from ".";

import { RouteTabOptions } from "@kinherit/framework/component.display/tabs/types";

const items: Record<"Core" | "Custom" | "Kinvault", RouteTabOptions[]> = {
  Core: [
    {
      label: "Summary",
      route: {
        name: SummaryReportRoute,
      },
    },
    {
      label: "Leads",
      route: {
        name: LeadsReportRoute,
      },
    },
    {
      label: "Cashflow",
      route: {
        name: CashflowReportRoute,
      },
    },
    {
      label: "Accounts",
      route: {
        name: AccountsReportRoute,
      },
    },
    {
      label: "Appointments",
      route: {
        name: AppointmentsReportRoute,
      },
    },
    {
      label: "Products",
      route: {
        name: ProductsReportRoute,
      },
    },
    {
      label: "Doc Storage",
      route: {
        name: DocstorageReportRoute,
      },
    },
    {
      label: "Agreements",
      route: {
        name: AgreementsReportRoute,
      },
    },
    {
      label: "Companies",
      route: {
        name: CompaniesReportRoute,
      },
    },
  ],
  Custom: [
    {
      label: "Downloads",
      route: {
        name: DownloadReportRoute,
      },
    },
  ],
  Kinvault: [
    {
      label: "Signups",
      route: {
        name: SignupsReportRoute,
      },
    },
  ],
};

export default defineComponent({
  name: "ReportSidebar",
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    Tabs,
  },
  data: () => ({
    items,
  }),
  computed: {
    selected: {
      get(): RouteTabOptions {
        return (
          Object.values(items)
            .flat()
            .find((tab) => {
              if (
                "string" !== typeof tab.route &&
                "name" in tab.route &&
                tab.route.name === this.$route.name
              ) {
                return true;
              }

              return false;
            }) ?? items["Core"][0]
        );
      },
      set({ route }: RouteTabOptions) {
        window.Kernel.visitRoute(route);
      },
    },
  },
});
</script>

<style lang="scss">
.report-sidebar__title {
  border-right: 1px solid var(--color-border);
  padding: 0.5rem 1rem;
  cursor: default;
  @include font-weight(700);
}
</style>
