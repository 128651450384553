export const granularityOptions = {
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
};

export const compareToOptions = {
  "": "None",
  previousPeriod: "Previous Period",
  previousWeek: "Previous Week",
  previousMonth: "Previous Month",
  previousQuarter: "Previous Quarter",
  previousYear: "Previous Year",
  custom: "Custom",
};

export const orderGroupOptions = {
  paidAt: "Paid At",
  createdAt: "Created At",
};

const yearOptions: { [key: string]: string } = {};
const currentYear: number = new Date().getFullYear();
for (let i: number = currentYear; i >= 2019; i--) {
  yearOptions[i.toString()] = i.toString();
}
yearOptions["custom"] = "Custom Range";

export { yearOptions };
