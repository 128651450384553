import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCreditNotesBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCreditNotesRoute = "KintinDetailsCreditNotes";
export const KintinDetailsCreditNotesURI =
  "/kinvault/:kintin/documents/credit-notes";
export type KintinDetailsCreditNotesParams = RouteParamObject<
  typeof KintinDetailsCreditNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCreditNotesRoute,
    path: KintinDetailsCreditNotesURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsCreditNotes" */ "./KintinDetails.CreditNotes.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsCreditNotesBreadCrumb,
    },
  },
];
