import { Api } from "@/service/api.service";
import { Person } from "@kinherit/sdk";

interface UpdateOfficerCallStatusMessage {
  person: Person;
}

interface UpdateOfficerCallStatusResponse {
  person: Person;
}

export const UpdateOfficerCallStatusHandler = async (
  message: UpdateOfficerCallStatusMessage,
): Promise<UpdateOfficerCallStatusResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-person/{kintin}/person/{person}",
    {
      kintin: message.person.$data.kintin as string,
      person: message.person.id,
    },
  )
    .method("patch")

    .body({
      officerCallStatus: message.person.$data.officerCallStatus,
    })
    .result();

  const person = Person.$inflate(response.person).first();

  if (!person) {
    throw new Error(`Failed to create person`);
  }

  return { person };
};
