import { Api } from "@/service/api.service";
import { DateTime } from "@kinherit/ts-common";

import { customReportTypes } from "@/module/report/form/read-download-report.form";

interface ComputeDownloadReportMessage {
  customReportType: keyof typeof customReportTypes;
  network: string | null;
  company: string | null;
  created?: [number, number] | null;
  assignedTo?: string | null;
  salesTeam?: string | null;
  includeOptions?: Array<{
    value: string;
    text: string;
    description: string;
  }>;
  tags?: string[] | null;
}

interface ComputeDownloadReportResponse {
  [key: string]: never;
}

export const ComputeDownloadReportHandler = async (
  message: ComputeDownloadReportMessage,
): Promise<ComputeDownloadReportResponse> => {
  if (!message.created) {
    message.created = [new Date("2018-01-01").getTime(), new Date().getTime()];
  }
  let startDate: string = new DateTime(message.created[0]).formatYMD;
  let endDate: string = new DateTime(message.created[1]).formatYMD;

  // Set to the start of the day
  startDate += "T00:00";
  // Set to the end of the day
  endDate += "T23:59";

  const includeOptions = message.includeOptions
    ? message.includeOptions.map((option) => option.value)
    : [];

  const target =
    message.customReportType === "leadReport"
      ? "/v2/portal/report/download/lead-report"
      : message.customReportType === "feeReport"
        ? "/v2/portal/report/download/fee-report"
        : message.customReportType === "contactReport"
          ? "/v2/portal/report/download/contacts-report"
          : "/v2/portal/report/download/lead-report";

  await Api.resource("portal", target, {})
    .method("get")
    .params({
      network: message.network ?? null,
      company: message.company ?? null,
      startDate: startDate ?? null,
      endDate: endDate ?? null,
      includeOptions: includeOptions ?? null,
      assignedTo: message.assignedTo ?? null,
      salesTeam: message.salesTeam ?? null,
      tags: message.tags ?? null,
    })
    .download({
      encoding: "text/csv",
      fileName: `${message.customReportType}.csv`,
    });

  return {};
};
