import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadStorageRequestForm = () =>
  MasterListFiltersForm({
    name: "filter-storage-request-form",
    data: () => ({
      name: "",
      complete: false as boolean | null,
      created: undefined as undefined | [number, number],
      logAction: [] as string[],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      complete: [
        FormSelectField({
          props: {
            label: "Approval Status",
            options: {
              Any: "Any",
              Yes: "Approved",
              No: "Awaiting Approval",
            },
            reference: "complete",
          },
          models: {
            value: {
              get: (data) =>
                null === data.complete
                  ? "Any"
                  : true === data.complete
                    ? "Yes"
                    : "No",
              set: (value, data) =>
                (data.complete =
                  "Any" === value ? null : "Yes" === value ? true : false),
            },
          },
        }),
      ],
      request: [
        OptionsAutoCompleteField({
          group: "physicalStorageFileLogActions",
          vModel: "logAction",
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
