import { BreadCrumbConfig } from "@kinherit/framework/component.layout/breadcrumbs/types";
import { IntroducerNetwork } from "@kinherit/sdk";

export const IntroducerNetworkMasterListBreadCrumb = {
  text: "Outbound Referrals",
  route: { name: "IntroducerNetworkMasterList" },
};

export const IntroducerNetworkDetailsBreadCrumb = (
  params: Record<string, string>,
) => {
  const introducerNetwork = IntroducerNetwork.$findOne(
    params.introducerNetwork,
  );

  const breadcrumbs: Array<BreadCrumbConfig> = [
    IntroducerNetworkMasterListBreadCrumb,
  ];
  breadcrumbs.push({
    text: introducerNetwork?.name ?? "Unknown",
    route: { name: "IntroducerNetworkDetails", params },
  });

  return breadcrumbs;
};

export const IntroducerNetworkDetailsNotesBreadCrumb = (
  params: Record<string, string>,
) => [
  ...IntroducerNetworkDetailsBreadCrumb(params),
  {
    text: "Notes",
    route: { name: "IntroducerNetworkDetailsNotes", params },
  },
];
