import { Api } from "@/service/api.service";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

interface UpdateKnowledgeBaseCategoryMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

interface UpdateKnowledgeBaseCategoryResponse {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

export const UpdateKnowledgeBaseCategoryHandler = async (
  message: UpdateKnowledgeBaseCategoryMessage,
): Promise<UpdateKnowledgeBaseCategoryResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-category/{knowledgeBaseCategory}",
    {
      knowledgeBaseCategory: message.knowledgeBaseCategory.id,
    },
  )
    .method("patch")

    .body({
      slug: message.knowledgeBaseCategory.slug,
      title: message.knowledgeBaseCategory.title,
    })
    .result();

  const knowledgeBaseCategory = KnowledgeBaseCategory.$inflate(
    response.knowledgeBaseCategory,
  ).first();

  if (!knowledgeBaseCategory) {
    throw new Error("Failed to update knowledge base category");
  }

  return {
    knowledgeBaseCategory,
  };
};
