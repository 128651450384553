import { Api } from "@/service/api.service";
import { Appointment } from "@kinherit/sdk";

export interface UpdateAppointmentMessage {
  appointment: Appointment;
}

interface UpdateAppointmentResponse {
  appointment: Appointment;
}

export const UpdateAppointmentHandler = async (
  message: UpdateAppointmentMessage,
): Promise<UpdateAppointmentResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/appointment/{appointment}",
    {
      appointment: message.appointment,
    },
  )
    .method("patch")

    .body({
      type: message.appointment.$data.type,
      outcome: message.appointment.$data.outcome,
      tags: message.appointment.$data.tags,
      acuityId: message.appointment.$data.acuityId,
      calendar: message.appointment.$data.calendar,
      appointmentAt: message.appointment.$data.appointmentAt,
      cancelled: message.appointment.$data.cancelled,
      notes: message.appointment.$data.notes,
    });

  const response = await request.result();

  const appointment = Appointment.$inflate(response.appointment).first();

  if (!appointment) {
    throw new Error("Failed to update appointment");
  }

  return {
    appointment,
  };
};
