<template>
  <EmailLogPage v-if="kintin" :owner="kintin" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsEmailLogWrapper",
  "route": "KintinDetailsEmailLog",
  "extends": {
    "name": "EmailLogPageWrapper",
    "path": "@/module/core/component/EmailLogPage.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import EmailLogPage from "@/module/core/component/EmailLogPage.vue";
import { defineComponent } from "vue";
import { KintinDetailsEmailLogRoute } from ".";
import { KinvaultKintinDetailsParams } from "..";
import { KinvaultKintinDetailsMixin } from "../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: KintinDetailsEmailLogRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: {
    EmailLogPage,
  },
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.execute(
    //   "kinvault/kintin/email-log/record",
    //   to.params as KinvaultKintinDetailsParams,
    // );
    await window.Kernel.ActionBus.kinvaultKintin.emailLog.record(
      to.params as KinvaultKintinDetailsParams,
    );
  },
});
</script>
