import { RouteInterface } from "@kinherit/framework/core/route-config";
import { Routes as AdminReferralDetailsRoutes } from "./details";

export const ReferralCodeMasterListRoute = "AccountReferralCodeMasterList";
export const Routes: Array<RouteInterface> = [
  {
    name: ReferralCodeMasterListRoute,
    path: "/admin/account-referral-codes",
    component: () =>
      import(
        /* webpackChunkName: "ReferralCodeMasterList" */ "./AccountReferralCodeMasterList.vue"
      ),
  },
  ...AdminReferralDetailsRoutes,
];
