<template>
  <span>
    <strong>Legacy of Business Property</strong><br />

    <ol type="a" v-for="gift in data.businessGifts" :key="gift.id">
      <li>
        I give free of inheritance tax ALL my capital holdings and interests in
        <span v-html="helpers.var(gift.notes)" /> ('the Company') to
        <span v-html="helpers.var(helpers.formatRecipient(gift, true))" />
        <span v-if="gift.onSecondDeath">
          only if my
          <span
            v-if="data.person"
            v-html="
              helpers.var(helpers.formatRelationship(data.person, data.partner))
            "
          />
          has predeceased me
        </span>
        <span v-html="helpers.var(helpers.formatPredeceased(gift))" />
      </li>
      <li>
        IF at my death my capital holdings and interest in the Company as a
        result of:
        <ol type="i">
          <li>ANY amalgamation restructure or rearrangement of capital or</li>
          <li>
            THE sale of the Company's business are represented by substituted
            capital holdings or interest in a new business whether or not there
            shall be one or more substitutions
          </li>
        </ol>
        THEN this gift SHALL take effect as if it had been a gift of such
        holdings or interests
      </li>
      <li>
        SUBJECT as above I declare that the gift SHALL exclude the cash proceeds
        of sale (or the money investments and property from time to time
        representing the same) of my capital holdings and interests if sold
        before my death
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
