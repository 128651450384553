<template>
  <DataListWidget :details="details">
    <template #above>
      <span
        :class="{
          'ml-0 mt-0 mb-3 is-fullwidth badge': true,
          'is-success': documentState === 'approved',
          'is-danger': documentState === 'waiting',
          'is-warning': documentState === 'generated',
        }"
      >
        <span v-if="documentState === 'approved'"> Approved </span>
        <span v-else-if="documentState === 'waiting'"> Awaiting Approval </span>
        <span v-else-if="documentState === 'generated'"> Generated </span>
      </span>
      <small v-if="documentState === 'generated'">
        No versions have been created yet. Create one to be able to download the
        final version.
      </small>
    </template>
    <template #below>
      <div class="buttons is-right mt-5">
        <Button
          v-if="editorState === 'viewing'"
          @click="edit"
          color="is-warning"
          is-fullwidth
          is-outlined
          class="has-text-left edit-button"
          icon-left="Edit"
        >
          Edit
        </Button>
        <Button
          v-if="editorState === 'viewing'"
          @click="changeVersion"
          color="is-primary"
          is-outlined
          is-fullwidth
          class="has-text-left version-button"
          :icon-left="StyleService.icon.history.icon"
        >
          Versions
        </Button>
        <Button
          v-if="options.willRevision?.approvedAt && editorState === 'viewing'"
          @click="downloadPdf(false)"
          color="is-success"
          is-outlined
          is-fullwidth
          class="has-text-left download-button"
          icon-left="PDF"
        >
          Download Final
        </Button>
        <Button
          v-if="
            !options.willRevision?.approvedAt &&
            documentState !== 'generated' &&
            editorState === 'viewing'
          "
          @click="approve"
          color="is-success"
          is-outlined
          is-fullwidth
          icon-left="Tick"
          class="has-text-left approve-button"
        >
          Approve
        </Button>
        <Button
          v-if="editorState !== 'selecting'"
          @click="downloadPdf(true)"
          color="is-success"
          is-outlined
          is-fullwidth
          icon-left="PDF"
          class="has-text-left download-draft-button"
        >
          Download Draft
        </Button>
        <Button
          v-if="editorState !== 'selecting'"
          @click="editConfig"
          color="is-warning"
          is-fullwidth
          is-outlined
          icon-left="Cog"
          class="has-text-left settings-button"
        >
          Settings
        </Button>
        <OptionalDocumentsForm />

        <Button
          v-if="editorState === 'editing'"
          @click="save"
          color="is-success"
          is-fullwidth
          class="has-text-left save-button"
          icon-left="Tick"
        >
          Save
        </Button>
        <Button
          v-if="editorState === 'editing'"
          @click="cancel"
          color="is-danger"
          is-fullwidth
          class="has-text-left cancel-button"
          icon-left="Cross"
        >
          Cancel
        </Button>
        <Button
          v-if="editorState === 'selecting'"
          @click="options.selecting = false"
          color="is-primary"
          is-outlined
          is-fullwidth
          class="has-text-left back-button"
          icon-left="ChevronLeft"
        >
          Back
        </Button>
      </div>
    </template>
  </DataListWidget>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinDetailsWillSidebarWrapper",
  "imports": {
    "DataListWidgetWrapper": "@/shared/component/widget/DataListWidget.test"
  },
  "methods": {
    "about": {
      "type": "to-one",
      "selector": "",
      "wrapper": "DataListWidgetWrapper"
    },
    "editButton": {
      "type": "click",
      "selector": ".edit-button"
    },
    "versionButton": {
      "type": "click",
      "selector": ".version-button"
    },
    "downloadButton": {
      "type": "click",
      "selector": ".download-button"
    },
    "approveButton": {
      "type": "click",
      "selector": ".approve-button"
    },
    "downloadDraftButton": {
      "type": "click",
      "selector": ".download-draft-button"
    },
    "settingsButton": {
      "type": "click",
      "selector": ".settings-button"
    },
    "saveButton": {
      "type": "click",
      "selector": ".save-button"
    },
    "cancelButton": {
      "type": "click",
      "selector": ".cancel-button"
    },
    "backButton": {
      "type": "click",
      "selector": ".back-button"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import OptionalDocumentsForm from "@/module/kinvault.kintin/component/will-builder/output/OptionalDocumentsForm.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { StyleService } from "@/service/style.service";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KintinDetailsWillSidebar",
  mixins: [KinvaultKintinDetailsMixin, WillBuilderService.mixin],
  components: {
    Button,
    OptionalDocumentsForm,
    DataListWidget,
  },
  data: () => ({
    StyleService,
  }),
  methods: {
    edit() {
      this.options.editing = true;
    },
    cancel() {
      this.options.editing = false;

      this.init();
    },
    changeVersion() {
      this.options.selecting = true;
    },
  },
  computed: {
    documentState(): "generated" | "approved" | "waiting" {
      if (this.options.willRevision?.approvedAt) {
        return "approved";
      }

      if (this.options.willRevision) {
        return "waiting";
      }

      return "generated";
    },
    editorState(): "editing" | "viewing" | "selecting" {
      if (this.options.editing) {
        return "editing";
      }

      if (this.options.selecting) {
        return "selecting";
      }

      return "viewing";
    },
    details(): DataListWidgetDetail[] {
      const details: DataListWidgetDetail[] = [];

      if (this.options.willRevision) {
        if (this.options.willRevision.approvedAt) {
          details.push({
            label: "Approved",
            value: this.options.willRevision.approvedAt.formatDate,
          });

          details.push({
            label: "Approved By",
            value: this.options.willRevision.approvedBy?.profile.fullName,
          });
        }

        details.push({
          label: "Created",
          value: this.options.willRevision.createdAt.formatDate,
        });

        details.push({
          label: "Created By",
          value: this.options.willRevision.createdBy?.profile.fullName,
        });

        details.push({
          label: "Notes",
          value: this.options.willRevision.notes,
        });
      }

      return details;
    },
  },
});
</script>
