import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsReferralFormRoute =
  "IntroducerContactDetailsReferralForm";
export const IntroducerContactDetailsReferralFormURI =
  "/introducer/contact/:introducerContact/referral-form";
export type IntroducerContactDetailsReferralFormParams = RouteParamObject<
  typeof IntroducerContactDetailsReferralFormURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsReferralFormRoute,
    path: IntroducerContactDetailsReferralFormURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsReferralForm" */ "./IntroducerContactDetails.ReferralForm.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "Referral Forms",
          route: {
            name: IntroducerContactDetailsReferralFormRoute,
            params,
          },
        },
      ],
    },
  },
];
