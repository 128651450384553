import { Api } from "@/service/api.service";
import {
  AccountReferral,
  FileLog,
  IUploadForm,
  SignDoc,
  UploadForm,
} from "@kinherit/sdk";

export interface ReadUploadFormsMessage {
  accountReferral: AccountReferral | string;
  pinned?: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IUploadForm;
    direction: "asc" | "desc";
  };
}

export interface ReadUploadFormsResponse {
  uploadForms: Array<UploadForm>;
  uploadFormSubmissions: Array<FileLog>;
  signDocs: Array<SignDoc>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadUploadFormsHandler = async (
  message: ReadUploadFormsMessage,
): Promise<ReadUploadFormsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/upload-form/{accountReferral}/upload-forms",
    {
      accountReferral: message.accountReferral,
    },
  )
    .method("get")

    .paginate({
      currentPage: message.pagination?.currentPage ?? 1,
      perPage: message.pagination?.perPage ?? 10,
    })
    .sort(
      message.sort
        ? {
            by: message.sort.by,
            direction: message.sort.direction,
          }
        : undefined,
    );

  const response = await request.result();

  return {
    uploadForms: UploadForm.$inflate(
      response.uploadForm,
      message.sort,
      response.$rootIds,
    ),
    uploadFormSubmissions: FileLog.$inflate(response.fileLog),
    signDocs: SignDoc.$inflate(response.signDoc),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
