import { Api } from "@/service/api.service";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface DeleteKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  fileLog: FileLog;
}

interface DeleteKnowledgeBaseDocumentFileLogResponse {}

export const DeleteKnowledgeBaseDocumentFileLogHandler = async (
  message: DeleteKnowledgeBaseDocumentFileLogMessage,
): Promise<DeleteKnowledgeBaseDocumentFileLogResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document/{fileLog}",
    message,
  )
    .method("delete")
    .result();

  message.fileLog.$delete();

  return {};
};
