import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadContactsHandler } from "./xero/read-contacts.action";
import { ReadInvoicesHandler } from "./xero/read-invoices.action";

export const Actions = {
  "external/xero/invoices/read": ReadInvoicesHandler,
  "external/xero/contacts/read": ReadContactsHandler,
};

export const ExternalActionBus = DefineActionBus({
  name: "External",
  actions: {
    xero: DefineActionBus({
      name: "External.Xero",
      actions: {
        invoices: ReadInvoicesHandler,
        contacts: ReadContactsHandler,
      },
    }),
  },
});
