export enum Titles {
  All = "All",
  AccountHolder = "Account Holders",
  Children = "Children",
  Dependents = "Dependents",
  Advisers = "Advisers",
  Attorneys = "Attorneys",
  Beneficiaries = "Beneficiaries",
  Executors = "Executors",
  GiftRecipients = "Gift Recipients",
  Guardians = "Guardians",
  Trustees = "Trustees",
  Addesses = "Addresses",
  Charities = "Charities",
  Companies = "Companies",
  Exclusions = "Exclusions",
}
