import { Api } from "@/service/api.service";
import { KintinCheckFollowUp, Note } from "@kinherit/sdk";

interface CreateKintinCheckFollowUpMessage {
  kintin: string;
  kintinCheckFollowUp: KintinCheckFollowUp;
}

interface CreateKintinCheckFollowUpResponse {
  kintinCheckFollowUp: KintinCheckFollowUp;
}

export const CreateKintinCheckFollowUpHandler = async (
  message: CreateKintinCheckFollowUpMessage,
): Promise<CreateKintinCheckFollowUpResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-check-follow-up/{kintin}/check-follow-ups",
    {
      kintin: message.kintin,
    },
  )
    .method("post")
    .body({
      step: message.kintinCheckFollowUp.$data.step,
      kintin: message.kintin,
      note: {
        completedAt: message.kintinCheckFollowUp.note.$data.completedAt,
        kintin: message.kintin,
        name: message.kintinCheckFollowUp.note.$data.name,
        notes: message.kintinCheckFollowUp.note.$data.notes,
        dueAt: message.kintinCheckFollowUp.note.$data.dueAt,
        pinned: message.kintinCheckFollowUp.note.$data.pinned,
        type: "kintinCheckFollowUp",
      },
    })

    .result();

  const kintinCheckFollowUp = KintinCheckFollowUp.$inflate(
    response.kintinCheckFollowUp,
  ).first();

  message.kintinCheckFollowUp.$delete();

  if (!kintinCheckFollowUp) {
    throw new Error("Failed to create kintin check");
  }

  Note.$inflate(response.note);

  return {
    kintinCheckFollowUp,
  };
};
