import { ReadEmailLogHandler } from "@/module/admin.log/action/read-email-log.action";
import { ReadNotificationLogHandler } from "@/module/admin.log/action/read-notification-log.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadFailedLoginAttemptsHandler } from "./read-failed-login-attempts.action";

export const Actions = {
  "admin/failed-login-attempts/read": ReadFailedLoginAttemptsHandler,
  "admin/email-log/read": ReadEmailLogHandler,
  "admin/notification-log/read": ReadNotificationLogHandler,
};

export const AdminLogActionBus = DefineActionBus({
  name: "Admin.Log",
  actions: {
    failedLoginAttempts: DefineActionBus({
      name: "Admin.Log.FailedLoginAttempts",
      actions: {
        read: ReadFailedLoginAttemptsHandler,
      },
    }),
    emailLog: DefineActionBus({
      name: "Admin.Log.EmailLog",
      actions: {
        read: ReadEmailLogHandler,
      },
    }),
    notificationLog: DefineActionBus({
      name: "Admin.Log.NotificationLog",
      actions: {
        read: ReadNotificationLogHandler,
      },
    }),
  },
});
