<template>
  <div class="kintin-master-list-escalated">
    <PageHeader htag="h1" text="Escalated Cases " />
    <KintinMasterList
      hide-title
      :show-filters="false"
      :is-escalated="true"
      :is-active="true"
      :persist-state="false"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinMasterListEscalatedWrapper",
  "route": "KintinMasterListEscalated",
  "selector": ".kintin-master-list-escalated",
  "imports": {
    "ReadKintinFormWrapper": "@/module/kinvault.kintin/form/read-kintin.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKintinFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import KintinMasterList from "@/module/kinvault.kintin/page/KintinMasterList.vue";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { KintinMasterListEscalatedRoute } from ".";

export default defineComponent({
  name: KintinMasterListEscalatedRoute,
  components: {
    KintinMasterList,
    PageHeader,
  },
});
</script>
