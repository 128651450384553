import { Api } from "@/service/api.service";
import { IWillRevision, QueryMask, WillRevision } from "@kinherit/sdk";

export interface ReadWillRevisionMessage {
  query?: QueryMask<typeof WillRevision>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IWillRevision;
    direction: "asc" | "desc";
  };
}

interface ReadWillRevisionResponse {
  willRevisions: Array<WillRevision>;
}

export const ReadWillRevisionHandler = async (
  message: ReadWillRevisionMessage,
): Promise<ReadWillRevisionResponse> => {
  const request = Api.resource("core", "/v2/core/select/will-revision")
    .method("get")

    .paginate(message.pagination ?? false)
    .sort(message.sort);
  request.buildQuery(WillRevision).where(message.query ?? {});

  const response = await request.result();

  return {
    willRevisions: WillRevision.$inflate(
      response.willRevision,
      undefined,
      response.$rootIds,
    ),
  };
};
