<template>
  <span>
    <strong>
      Provisions for the Discretionary Trust of Residue (including RNRB)
    </strong>
    <p>
      <span v-if="scheduleCount === 1">
        This clause and clauses
        <span v-html="helpers.var(helpers.getRESTrustsClauseRange())" />
        inclusive shall apply to the trust detailed in
        <strong>Schedule 1</strong> of this my Will hereinafter referred to as
        'the Trust'
      </span>

      <span v-if="scheduleCount > 1">
        This clause shall apply to the trusts detailed in
        <strong>Schedule <span v-html="helpers.var(schedule)" /></strong> of
        this my Will hereinafter referred to as 'the Trust'
      </span>

      <br /><br />
      The trustees of the Trust shall hold the Residence Nil Rate Amount as
      shall be held by them on trust for such of the beneficiaries in such
      shares and in such manner as the trustees (being at least two in number or
      a trust corporation) shall in their absolute discretion appoint by deed or
      deeds revocable or irrevocable and executed at any time during the
      Residence Nil Rate Amount Period
      <br /><br />
      At the end of the Residence Nil Rate Amount Period in default of
      appointment thereafter the trustees shall continue to hold the Residence
      Nil Rate Amount as shall be held by them on trust for such of the
      beneficiaries in such shares and in such manner as the trustees (being at
      least two in number or a trust corporation) shall in their absolute
      discretion appoint by deed or deeds revocable or irrevocable and executed
      at any time during the remainder of the Trust Period and in default of
      appointment or so far as no such appointment shall extend for the benefit
      of my Chosen Beneficiary
      <br /><br />
      Furthermore the trustees shall hold the remainder of the trust property on
      trust for such of the beneficiaries in such shares and in such manner as
      the trustees (being at least two in number or a trust corporation) shall
      in their absolute discretion appoint by deed or deeds revocable or
      irrevocable and executed at any time during the Trust Period
      <br /><br />
      The Residence Nil Rate Amount means the total amount or allowance in
      respect of increased nil rate band for Inheritance Tax to which my estate
      may be entitled at the date of my death under sections 8D to 8M of the
      Inheritance Tax Act 1984 as amended (or any re-enactment thereof at the
      date of my death) including any amount brought forward under section 8F
      and any Downsizing Addition under section 8FA to 8FE
      <br /><br />
      The Residence Nil Rate Amount Period means the period ending the earlier
      of the following:
    </p>
    <ol type="i">
      <li>18 months from the date of my death</li>
      <li>
        The day immediately before the death of the last of my Lineal
        Descendants
      </li>
    </ol>
    <p>Lineal Descendants shall mean:</p>
    <ol type="a">
      <li>my great grandchildren</li>
      <li>my grandchildren</li>
      <li>my children</li>
      <li>children who are, or were at any time step-children of mine</li>
      <li>my adopted children</li>
      <li>children who were fostered by me at any time</li>
      <li>
        children where I was appointed as a guardian or special guardian for
        whilst they were under 18
      </li>
    </ol>
    <p>
      My Chosen Beneficiary shall mean any person or persons whose relationship
      to me is defined here within (a) above and if more than one in equal
      shares if none then within (b) above and if more than one in equal shares
      if none then within (c) above and if more than one in equal shares if none
      then within (d) above and if more than one in equal shares if none then
      within (e) above and if more than one in equal shares if none then within
      (f) above and if more than one in equal shares if none then within (g)
      above and if more than one in equal shares
    </p>
  </span>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    scheduleCount(): number {
      return this.helpers.getSchedules().length;
    },
    schedule() {
      return this.helpers.getSchedulePosition(WillBuilderSections.ScheduleRES);
    },
  },
});
</script>
