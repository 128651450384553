import { Api } from "@/service/api.service";
import { Kintin, Trustee } from "@kinherit/sdk";

interface CreateTrusteeMessage {
  kintin: string | Kintin;
  trustee: Trustee;
}

interface CreateTrusteeResponse {
  trustee: Trustee;
}

export const CreateTrusteeHandler = async (
  message: CreateTrusteeMessage,
): Promise<CreateTrusteeResponse> => {
  const { trustee: trusteeData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-trustee/{kintin}/person/trustee",
    {
      kintin: message.kintin,
    },
  )
    .method("post")

    .body({
      forPerson: message.trustee.$data.forPerson,
      person: message.trustee.$data.person,
      isReserve: message.trustee.$data.isReserve,
      notes: message.trustee.$data.notes,
      attainmentAge: message.trustee.$data.attainmentAge,
      kintin: message.kintin.toString(),
    })
    .result();

  const trustee = Trustee.$inflate(trusteeData).first();

  if (!trustee) {
    throw new Error("Failed to create trustee");
  }

  message.trustee.$delete();

  return { trustee };
};
