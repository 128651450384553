import { Between, In, Like } from "@kinherit/orm/index";
import { Api, BrandedKinvault, IBrandedKinvault, Profile } from "@kinherit/sdk";

export interface ReadBrandedKinvaultMessage {
  name?: string;
  created?: null | [number, number];
  type: Array<string>;
  poolType: Array<string>;
  mode: Array<string>;
  frontendUrl: string;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IBrandedKinvault;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultResponse {
  brandedKinvaults: Array<BrandedKinvault>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadBrandedKinvaultHandler = async (
  message: ReadBrandedKinvaultMessage,
): Promise<ReadBrandedKinvaultResponse> => {
  const request = Api.resource("portal", "/v2/portal/branded-kinvault")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(BrandedKinvault).where({
    profile: {
      fullName: Like(message.name),
    },
    type: { id: In(message.type) },
    clientPoolType: { id: In(message.poolType) },
    siteMode: { id: In(message.mode) },
    createdAt: Between(message.created),
    frontendUrl: Like(message.frontendUrl),
  });

  const response = await request.result();

  return {
    brandedKinvaults: BrandedKinvault.$inflate(
      response.brandedKinvault,
      message.sort,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
