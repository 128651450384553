import { Api } from "@/service/api.service";
import { Kintin, User } from "@kinherit/sdk";

export interface RevokeKintinBrandedKinvaultMembershipMessage {
  kintin: string | Kintin;
}

interface RevokeKintinBrandedKinvaultMembershipResponse {
  users: Array<User>;
}

export const RevokeKintinBrandedKinvaultMembershipHandler = async (
  message: RevokeKintinBrandedKinvaultMembershipMessage,
): Promise<RevokeKintinBrandedKinvaultMembershipResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/{kintin}/branded-kinvault/revoke-access",
    {
      kintin: message.kintin,
    },
  )
    .method("put")
    .result();

  return {
    users: User.$inflate(response.user),
  };
};
