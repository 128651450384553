<template>
  <GuardiansContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookGuardiansWrapper",
  "route": "KinvaultKintinDetailsAddressBookGuardians",
  "extends": {
    "name": "GuardiansContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/GuardiansContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import GuardiansContainer from "@/module/kinvault.kintin/component/address-book/GuardiansContainer.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import RouteStorageMixin from "@kinherit/framework/component.mixin/route-storage-mixin";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookGuardiansRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookGuardiansRoute,
  components: {
    GuardiansContainer,
  },
  mixins: [KinvaultKintinDetailsMixin, RouteStorageMixin(true, "query")],
  emits: ["reload"],
});
</script>
