import { Api } from "@/service/api.service";
import { FileLog } from "@kinherit/sdk";

interface CreateKintinFileMessage {
  file: FileLog;
  uploadedFile: File | null;
}

interface CreateKintinFileResponse {
  file: FileLog;
}

export const CreateKintinFileHandler = async (
  message: CreateKintinFileMessage,
): Promise<CreateKintinFileResponse> => {
  const kintin = message.file.$data.kintin;

  if (!kintin) {
    throw new Error("Kintin is required");
  }

  const { fileLog: fileData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-file/{kintin}/files",
    {
      kintin,
    },
  )
    .method("post")

    .body({
      type: message.file.$data.type,
      subType: message.file.$data.subType,
      tab: message.file.$data.tab,
      fileName: message.file.$data.fileName,
      fileExtension: message.file.$data.fileExtension,
      fileSize: message.file.$data.fileSize,
      kintin: message.file.$data.kintin,
      notes: message.file.$data.notes,
      isRichText: message.file.isRichText,
    })
    .result();

  const file = FileLog.$inflate(fileData).first();

  if (!file) {
    throw new Error("Failed to create file");
  }

  if (message.uploadedFile) {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-file/{kintin}/files/{file}/upload",
      {
        kintin,
        file: file.$data.id,
      },
    )
      .method("put")

      .files({
        uploadedFile: message.uploadedFile,
      })
      .result();
  }

  message.file.$delete();

  return { file };
};
