import { FormFileField } from "@kinherit/framework/component.input/file-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const UploadKintinStorageRequestForm = () =>
  defineForm({
    name: "upload-kintin-storage-request-form",
    data: () => ({
      files: [] as File[],
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "upload-kintin-storage-request-form-area",
        data,
        components: () => ({
          default: [
            FormFileField({
              props: {
                label: "Select File to Upload",
                placeholder: "Choose a file...",
                validators: ["required"],
              },
              models: {
                value: "files",
              },
            }),
          ],
        }),
      }),
    ],
  });
