import { Api } from "@/service/api.service";
import { EmailTemplate } from "@kinherit/sdk";

export interface UpdateEmailTemplateMessage {
  emailTemplate: EmailTemplate;
}

interface UpdateEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export const UpdateEmailTemplateHandler = async (
  message: UpdateEmailTemplateMessage,
): Promise<UpdateEmailTemplateResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/email-template/{emailTemplate}",
    {
      emailTemplate: message.emailTemplate,
    },
  )
    .method("patch")
    .body({
      name: message.emailTemplate.$data.name,
      subject: message.emailTemplate.$data.subject,
      body: message.emailTemplate.$data.body,
      allowAttachments: message.emailTemplate.$data.allowAttachments,
      strictAttachments: message.emailTemplate.$data.strictAttachments,
      notes: message.emailTemplate.$data.notes,
      requiredAttachments: message.emailTemplate.$data.requiredAttachments,
      type: message.emailTemplate.$data.type,
      includeTo: message.emailTemplate.$data.includeTo,
      preloadedFiles: message.emailTemplate.$data.preloadedFiles,
    })

    .result();

  return {
    emailTemplates: EmailTemplate.$inflate(
      response.emailTemplate,
      undefined,
      response.$rootIds,
    ),
  };
};
