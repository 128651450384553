import { Api } from "@/service/api.service";
import { EmailTemplate } from "@kinherit/sdk";

export interface RecordEmailTemplateMessage {
  emailTemplate: EmailTemplate | string;
}

interface RecordEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export const RecordEmailTemplateHandler = async (
  message: RecordEmailTemplateMessage,
): Promise<RecordEmailTemplateResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/email-template/{emailTemplate}",
    {
      emailTemplate: message.emailTemplate,
    },
  )
    .method("get")

    .result();

  return {
    emailTemplates: EmailTemplate.$inflate(
      response.emailTemplate,
      undefined,
      response.$rootIds,
    ),
  };
};
