import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface RecordBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral:
    | BrandedKinvaultSubscriptionReferral
    | string;
}

interface RecordBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

export const RecordBrandedKinvaultSubscriptionReferralHandler = async (
  message: RecordBrandedKinvaultSubscriptionReferralMessage,
): Promise<RecordBrandedKinvaultSubscriptionReferralResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral/{brandedKinvaultSubscriptionReferral}",
    {
      brandedKinvaultSubscriptionReferral:
        typeof message.brandedKinvaultSubscriptionReferral,
    },
  )
    .method("get")

    .result();

  const brandedKinvaultSubscriptionReferral =
    BrandedKinvaultSubscriptionReferral.$inflate(
      response.brandedKinvaultSubscriptionReferral,
    ).first();

  if (!brandedKinvaultSubscriptionReferral) {
    throw new Error("Subscription not found");
  }

  return {
    brandedKinvaultSubscriptionReferral,
  };
};
