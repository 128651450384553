import { Api } from "@/service/api.service";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface DeleteIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem | string;
}

export interface DeleteIntroducerFeePaymentItemResponse {}

export const DeleteIntroducerFeePaymentItemHandler = async (
  message: DeleteIntroducerFeePaymentItemMessage,
): Promise<DeleteIntroducerFeePaymentItemResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-item/{introducerFeePaymentItem}",
    message,
  )
    .method("delete")
    .result();

  IntroducerFeePaymentItem.$delete(message.introducerFeePaymentItem);

  return {};
};
