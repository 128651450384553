import {
  Api,
  IntroducerCompany,
  IntroducerContract,
  Order,
} from "@kinherit/sdk";

export interface CreateIntroducerInvoiceMessage {
  introducerCompany: IntroducerCompany | string;
  brand: "kinherit";
  introducerCompanyType: "agent" | "introducer";
  introducerContract: IntroducerContract | string;
  orders: Array<Order | string>;
  feesType: "introducer" | "agent" | "parentAgent";
}

interface CreateIntroducerInvoiceResponse {
  [key: string]: never;
}

export const CreateIntroducerInvoiceHandler = async (
  message: CreateIntroducerInvoiceMessage,
): Promise<CreateIntroducerInvoiceResponse> => {
  const response = await Api.resource(
    "external",
    `/v2/external/xero/create-introducer-fee-bill`,
  )
    .method("post")
    .body({
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
      //introducerCompanyType: message.introducerCompanyType,
      brand: message.brand,
      introducerContract:
        "string" === typeof message.introducerContract
          ? message.introducerContract
          : message.introducerContract.id,
      orders: message.orders.map((order) =>
        "string" === typeof order ? order : order.id,
      ),
      feesType: message.feesType,
    })
    .result();

  console.log(response);

  return {};
};
