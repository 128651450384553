<template>
  <div class="summary-report">
    <Card v-if="!loading" title="Summary Report">
      <Table
        :columns="leadColumns"
        is-striped
        :rows="leadRows"
        is-narrow
        title="Leads"
        subtitle="Uses lead creation date"
      ></Table>
      <Table
        :columns="appointmentColumns"
        is-striped
        :rows="appointmentRows"
        is-narrow
        title="Appointments"
        subtitle="Call 1 / 2 Cancellation and Negative Outcomes"
      ></Table>
      <Table
        :columns="accountColumns"
        is-striped
        :rows="accountRows"
        is-narrow
        title="Accounts"
        subtitle="Uses account creation / lead conversion date"
      ></Table>
      <Table
        :columns="cashColumns"
        is-striped
        :rows="cashRows"
        is-narrow
        title="Cashflow"
        :subtitle="`Pipeline: ${pipeline} (${pipelineCount} orders)`"
      ></Table>
    </Card>
  </div>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { SummaryReportRoute } from ".";

import { quickPercentage } from "@/module/report/data/helper-functions";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Currency } from "@kinherit/ts-common/index";

interface LeadReportResponse {
  count: number;
  buildCount: number;
  unqualifiedCount: number;
  pendingCount: number;
  dropoutCount: number;
  convertedCount: number;
  ifaCount: number;
  mortgageCount: number;
  organicCount: number;
  otherCount: number;
  accountDropoutCount: number;
  accountPaidCount: number;
}

interface CashflowReportResponse {
  year: number;
  quarter: number;
  month: number;
  week: number;
  unpaidValue: number;
  unpaidCount: number;
  advisedValue: number;
  advisedCount: number;
  hostedValue: number;
  hostedCount: number;
  outsourcedValue: number;
  outsourcedCount: number;
  lpasOnlyValue: number;
  lpasOnlyCount: number;
  subscriptionCount: number;
  subscriptionValue: number;
  sumValue: number;
}

interface AccountReportResponse {
  year: number;
  week: number;
  month: number;
  quarter: number;
  kintinStages: Array<AccountReportAccountStageCount>;
}
interface AppointmentSummaryReportResponse {
  call1: {
    total: number;
    cancelled: number;
    negativeOutcomes: number;
  };
  call2: {
    total: number;
    cancelled: number;
    negativeOutcomes: number;
  };
}
interface AccountReportAccountStageCount {
  id: string;
  name: string;
  count: number;
  inProgressCount: number;
  notNowCount: number;
  dropoutCount: number;
  overdueCount: number;
}

interface ComputeSummaryReportResponse {
  priorWeek: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
    accounts: AccountReportResponse;
    appointments: AppointmentSummaryReportResponse;
  };
  wtd: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
    accounts: AccountReportResponse;
    appointments: AppointmentSummaryReportResponse;
  };
  mtd: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
    accounts: AccountReportResponse;
    appointments: AppointmentSummaryReportResponse;
  };
  qtd: {
    leads: LeadReportResponse;
    cash: CashflowReportResponse;
    accounts: AccountReportResponse;
    appointments: AppointmentSummaryReportResponse;
  };
  last12Months: {
    cash: CashflowReportResponse;
  };
}

export default defineComponent({
  name: SummaryReportRoute,
  components: {
    Card,
    Table,
  },
  data: () => ({
    loading: true,
    results: {} as ComputeSummaryReportResponse,
    leadColumns: [
      {
        field: "name",
      },
      {
        title: "Total",
        field: "count",
        class: "has-text-weight-bold",
      },
      {
        title: "IFA",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.ifaCount, results.count);
        },
      },
      {
        title: "Mortgage",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.mortgageCount, results.count);
        },
      },
      {
        title: "Organic",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.organicCount, results.count);
        },
      },
      {
        title: "Other",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.otherCount, results.count);
        },
      },
      {
        title: "Dropout",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.dropoutCount, results.count);
        },
      },
      {
        title: "Conv",
        class: "has-text-success has-text-weight-bold",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.convertedCount, results.count);
        },
      },
      {
        title: "Account Dropout",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.accountDropoutCount, results.count);
        },
      },
      {
        title: "Paid",
        class: "has-text-success has-text-weight-bold",
        mapHtml: (results: LeadReportResponse) => {
          return quickPercentage(results.accountPaidCount, results.count);
        },
      },
    ] satisfies TableColumn[],
    leadRows: Array<{
      name: string | "Prior" | "WTD" | "MTD" | "QTD";
      count: number;
      dropoutCount: number;
      convertedCount: number;
      ifaCount: number;
      mortgageCount: number;
      organicCount: number;
      otherCount: number;
      accountDropoutCount: number;
      accountPaidCount: number;
    }>(),
    appointmentColumns: [
      {
        field: "name",
      },
      {
        title: "Type",
        map: () => "Call 1",
      },
      {
        title: "Total",
        map: (results) => results.call1.total,
      },
      {
        title: "Cancelled",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results) => {
          return quickPercentage(results.call1.cancelled, results.call1.total);
        },
      },
      {
        title: "Negative",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results) => {
          return quickPercentage(
            results.call1.negativeOutcomes,
            results.call1.total,
          );
        },
      },
      {
        title: "Type",
        map: () => "Call 2",
      },
      {
        title: "Total",
        map: (results) => results.call2.total,
      },
      {
        title: "Cancelled",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results) => {
          return quickPercentage(results.call2.cancelled, results.call2.total);
        },
      },
      {
        title: "Negative",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results) => {
          return quickPercentage(
            results.call2.negativeOutcomes,
            results.call2.total,
          );
        },
      },
    ] satisfies TableColumn<AppointmentSummaryReportResponse>[],
    appointmentRows: Array<{
      name: string | "Prior" | "WTD" | "MTD" | "QTD";
      call1: {
        total: number;
        cancelled: number;
        negativeOutcomes: number;
      };
      call2: {
        total: number;
        cancelled: number;
        negativeOutcomes: number;
      };
    }>(),

    accountColumns: [
      {
        field: "name",
      },
      {
        title: "Dropout B",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: AccountReportResponse) => {
          const stageB = results.kintinStages.find(
            (stage) => stage.name === "b",
          );
          const stageBDropout = stageB ? stageB.dropoutCount : 0;

          const count = results.kintinStages.reduce(
            (acc, stage) => acc + stage.count,
            0,
          );
          return quickPercentage(stageBDropout, count);
        },
      },
      {
        title: "Dropout C",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: AccountReportResponse) => {
          const stageC = results.kintinStages.find(
            (stage) => stage.name === "c",
          );
          const stageCDropout = stageC ? stageC.dropoutCount : 0;

          const count = results.kintinStages.reduce(
            (acc, stage) => acc + stage.count,
            0,
          );
          return quickPercentage(stageCDropout, count);
        },
      },
      {
        title: "Dropout D",
        class: "has-text-danger has-text-weight-bold",
        mapHtml: (results: AccountReportResponse) => {
          const stageD = results.kintinStages.find(
            (stage) => stage.name === "d",
          );
          const stageDDropout = stageD ? stageD.dropoutCount : 0;

          const count = results.kintinStages.reduce(
            (acc, stage) => acc + stage.count,
            0,
          );
          return quickPercentage(stageDDropout, count);
        },
      },
    ] satisfies TableColumn[],
    accountRows: Array<{
      name: string | "Prior" | "WTD" | "MTD" | "QTD";
      kintinStages: Array<AccountReportAccountStageCount>;
      total: number;
    }>(),
    cashColumns: [
      {
        field: "name",
      },
      {
        title: "Total",
        class: "has-text-weight-bold",
        map: (results: CashflowReportResponse) => {
          const value = new Currency({
            amount: results.sumValue,
          });
          return value.toFormattedString();
        },
      },
      {
        title: "Advised (avg)",
        mapHtml: (results: CashflowReportResponse) => {
          const averageOrderValue =
            results.advisedValue > 0
              ? new Currency({
                  amount: results.advisedValue / results.advisedCount,
                }).format
              : "-";
          const totalValue = new Currency({
            amount: results.advisedValue,
          });

          return `${totalValue.toFormattedString()} <span class='has-text-grey'>(${averageOrderValue})</span>`;
        },
      },
      {
        title: "Other",
        map: (results: CashflowReportResponse) => {
          const value = new Currency({
            amount: results.hostedValue + results.outsourcedValue,
          });
          return value.toFormattedString();
        },
      },
      {
        title: "LPA Only",
        map: (results: CashflowReportResponse) => {
          const value = new Currency({
            amount: results.lpasOnlyValue,
          });
          return value.toFormattedString();
        },
      },
      {
        title: "Subs",
        map: (results: CashflowReportResponse) => {
          const value = new Currency({
            amount: results.subscriptionValue,
          });
          return value.toFormattedString();
        },
      },
    ] satisfies TableColumn[],
    cashRows: Array<{
      name: String | "Prior" | "WTD" | "MTD" | "QTD";
      sumValue: number;
      advisedValue: number;
      advisedCount: number;
      hostedValue: number;
      lpasOnlyValue: number;
      subscriptionValue: number;
      outsourcedValue: number;
    }>(),
    pipeline: "" as string,
    pipelineCount: 0 as number,
  }),
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      // const data = await window.Kernel.ActionBus.execute(
      //   "report/summary",
      //   undefined,
      // );
      const data = await window.Kernel.ActionBus.report.summary();

      const leadPeriod = [
        { name: "Prior", leads: data.priorWeek.leads },
        { name: "WTD", leads: data.wtd.leads },
        { name: "MTD", leads: data.mtd.leads },
        { name: "QTD", leads: data.qtd.leads },
      ];

      this.leadRows = leadPeriod.map((period) => ({
        name: period.name,
        count: period.leads.count,
        dropoutCount: period.leads.dropoutCount,
        convertedCount: period.leads.convertedCount,
        ifaCount: period.leads.ifaCount,
        mortgageCount: period.leads.mortgageCount,
        organicCount: period.leads.organicCount,
        otherCount: period.leads.otherCount,
        accountDropoutCount: period.leads.accountDropoutCount,
        accountPaidCount: period.leads.accountPaidCount,
      }));

      const appointmentPeriod = [
        { name: "Prior", appointments: data.priorWeek.appointments },
        { name: "WTD", appointments: data.wtd.appointments },
        { name: "MTD", appointments: data.mtd.appointments },
        { name: "QTD", appointments: data.qtd.appointments },
      ];

      this.appointmentRows = appointmentPeriod.map((period) => ({
        name: period.name,
        call1: period.appointments.call1,
        call2: period.appointments.call2,
      }));

      const accountPeriod = [
        { name: "MTD", accounts: data.mtd.accounts },
        { name: "QTD", accounts: data.qtd.accounts },
      ];

      this.accountRows = accountPeriod.map((period) => ({
        name: period.name,
        kintinStages: period.accounts.kintinStages,
        total: period.accounts.kintinStages.reduce(
          (acc, stage) => acc + stage.count,
          0,
        ),
      }));

      const cashPeriod = [
        { name: "Prior", cash: data.priorWeek.cash },
        { name: "WTD", cash: data.wtd.cash },
        { name: "MTD", cash: data.mtd.cash },
        { name: "QTD", cash: data.qtd.cash },
      ];
      this.cashRows = cashPeriod.map((period) => ({
        name: period.name,
        sumValue: period.cash.sumValue,
        advisedValue: period.cash.advisedValue,
        advisedCount: period.cash.advisedCount,
        hostedValue: period.cash.hostedValue,
        outsourcedValue: period.cash.outsourcedValue,
        lpasOnlyValue: period.cash.lpasOnlyValue,
        subscriptionValue: period.cash.subscriptionValue,
      }));

      this.pipeline = new Currency({
        amount: data.last12Months.cash.unpaidValue,
      }).toFormattedString();
      this.pipelineCount = data.last12Months.cash.unpaidCount;
      this.loading = false;
    },
  },
});
</script>
