import { Api } from "@/service/api.service";
import {
  IIntroducerContract,
  IntroducerCompany,
  IntroducerContract,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyContractsMessage {
  introducerCompany: IntroducerCompany | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerContract;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyContractsResponse {
  contracts: Array<IntroducerContract>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerCompanyContractsHandler = async (
  message: ReadIntroducerCompanyContractsMessage,
): Promise<ReadIntroducerCompanyContractsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-contract/{introducerCompany}/contracts",
    {
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
    },
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  const response = await request.result();

  return {
    contracts: IntroducerContract.$inflate(
      response.introducerContract,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
