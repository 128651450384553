import { AdminBrandedKinvaultDetailsEmailLogBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsEmailLogRoute =
  "BrandedKinvaultDetailsEmailLog";
export const BrandedKinvaultDetailsEmailLogURI =
  "/branded-kinvaults/:brandedKinvault/email-log";
export type BrandedKinvaultDetailsEmailLogParams = RouteParamObject<
  typeof BrandedKinvaultDetailsEmailLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsEmailLogRoute,
    path: BrandedKinvaultDetailsEmailLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsEmailLog" */ "./BrandedKinvaultDetails.EmailLog.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsEmailLogBreadCrumb,
    },
  },
];
