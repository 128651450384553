<template>
  <strong>Trusts</strong>
  <p>
    The following clauses (<span
      v-html="helpers.var(helpers.getTrustsClauseRange())"
    />) inclusive of this my Will shall detail all of the trusts that have been
    created and the powers and provisions that shall apply to the trustees of
    the said trusts
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TrustsOutput",
  mixins: [WillBuilderService.mixin],
});
</script>
