import { KinvaultKintinDetailsProcessBreadCrumb } from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const KintinDetailsProcessRoute = "KintinDetailsProcess";
export const KintinDetailsProcessURI = "/kinvault/:kintin/documents/process";
export type KintinDetailsProcessParams = RouteParamObject<
  typeof KintinDetailsProcessURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsProcessRoute,
    path: KintinDetailsProcessURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsProcess" */ "./KintinDetails.Process.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsProcessBreadCrumb,
    },
  },
];
