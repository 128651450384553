import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsNotesRoute =
  "IntroducerCompanyDetailsNotes";
export const IntroducerCompanyDetailsNotesURI =
  "/introducer/company/:introducerCompany/notes";
export type IntroducerCompanyDetailsNotesParams = RouteParamObject<
  typeof IntroducerCompanyDetailsNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsNotesRoute,
    path: IntroducerCompanyDetailsNotesURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsNotes" */ "./IntroducerCompanyDetails.Notes.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Notes",
          route: {
            name: IntroducerCompanyDetailsNotesRoute,
            params,
          },
        },
      ],
    },
  },
];
