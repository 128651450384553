<template>
  <div v-if="kintin">
    <CallScriptSection title="Totals">
      <PropertiesContainer class="mt-4" :kintin="kintin" />
      <Form
        v-if="$data.propertyTotalsSectionForm"
        :config="$data.propertyTotalsSectionForm"
        is-borderless
      />
      <Button
        text="Save"
        @click="saveHandler"
        color="is-positive"
        class="is-pulled-right"
      />
    </CallScriptSection>
    <CallScriptSection title="Scenario for Client Summary">
      <CurrencyField
        label="Estate Value for 4.1"
        v-model:value="values.advHteStateValue"
        @update:value="
          propertyTotalsSectionForm?.controls?.incrementFormRenderKey()
        "
      />
      <SelectField
        class="mt-3"
        label="Likelyhood of Inheritance Tax"
        :options="{
          '0': 'Is Unlikely',
          '1': 'May be payable',
          '2': 'Is Likely',
        }"
        v-model:value="callScript.advIhteStateSitu"
      />
      <div class="buttons is-right">
        <Button text="Save" @click="saveHandler" color="is-positive" />
        <Button
          text="Save and Continue"
          @click="saveAndContinueHandler"
          color="is-positive"
        />
      </div>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import PropertiesContainer from "@/module/kinvault.kintin/component/address-book/PropertiesContainer.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { PropertyTotalsSectionForm } from "@/module/kinvault.kintin/form/call-script.form/property-totals-section.form";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import Form from "@kinherit/framework/component.form/form";
import { Button } from "@kinherit/framework/component.input/button";
import CurrencyField from "@kinherit/framework/component.input/currency-field";
import SelectField from "@kinherit/framework/component.input/select-field";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { IntroducerCompany, IntroducerContact } from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "TotalsTab",
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    Button,
    CallScriptSection,
    CurrencyField,
    Form,
    SelectField,
    PropertiesContainer,
  },
  emits: ["save", "save-and-continue"],
  data: () => ({
    propertyTotalsSectionForm: null as null | FormBuilder<any, any>,
    values: {
      advSavingsValue: null as null | Currency,
      advPropsValue: null as null | Currency,
      advPolicyValue: null as null | Currency,
      advPensionValue: null as null | Currency,
      advOtherAssetValue: null as null | Currency,
      advBusinessAssetValue: null as null | Currency,
      advPotentialValue: null as null | Currency,
      advDebtValue: null as null | Currency,
      advHteStateValue: null as null | Currency,
    },
  }),
  computed: {
    referralContact(): IntroducerContact | null {
      return this.kintin?.referral?.contact ?? null;
    },
    referralCompany(): IntroducerCompany | null {
      return this.kintin?.referral?.referralCode?.company ?? null;
    },
    assetNetTotal(): number {
      return (
        (this.values.advSavingsValue?.amount ?? 0) +
        (this.values.advPropsValue?.amount ?? 0) +
        (this.values.advPolicyValue?.amount ?? 0) +
        (this.values.advPensionValue?.amount ?? 0) +
        (this.values.advOtherAssetValue?.amount ?? 0) +
        (this.values.advBusinessAssetValue?.amount ?? 0) +
        (this.values.advPotentialValue?.amount ?? 0) +
        (this.values.advDebtValue?.amount ?? 0)
      );
    },
  },
  mounted(): void {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }

    const { form, updateAssetNetTotal } = PropertyTotalsSectionForm(
      this.values,
    );
    this.propertyTotalsSectionForm = form;
    this.$watch(
      () => this.assetNetTotal,
      () => {
        updateAssetNetTotal();
      },
    );

    this.values.advSavingsValue =
      this.kintin.callScript?.advSavingsValue ?? null;
    this.values.advPropsValue = this.kintin.callScript?.advPropsValue ?? null;
    this.values.advPolicyValue = this.kintin.callScript?.advPolicyValue ?? null;
    this.values.advPensionValue =
      this.kintin.callScript?.advPensionValue ?? null;
    this.values.advOtherAssetValue =
      this.kintin.callScript?.advOtherAssetValue ?? null;
    this.values.advBusinessAssetValue =
      this.kintin.callScript?.advBusinessAssetValue ?? null;
    this.values.advPotentialValue =
      this.kintin.callScript?.advPotentialValue ?? null;
    this.values.advDebtValue = this.kintin.callScript?.advDebtValue ?? null;
    this.values.advHteStateValue =
      this.kintin.callScript?.advHteStateValue ?? null;
  },
  methods: {
    async updateValues() {
      if (!this.kintin) {
        return;
      }
      this.kintin.callScript.advSavingsValue = this.values.advSavingsValue;
      this.kintin.callScript.advPropsValue = this.values.advPropsValue;
      this.kintin.callScript.advPolicyValue = this.values.advPolicyValue;
      this.kintin.callScript.advPensionValue = this.values.advPensionValue;
      this.kintin.callScript.advOtherAssetValue =
        this.values.advOtherAssetValue;
      this.kintin.callScript.advBusinessAssetValue =
        this.values.advBusinessAssetValue;
      this.kintin.callScript.advPotentialValue = this.values.advPotentialValue;
      this.kintin.callScript.advDebtValue = this.values.advDebtValue;
      this.kintin.callScript.advHteStateValue = this.values.advHteStateValue;
    },
    async saveAndContinueHandler() {
      this.updateValues();
      this.$emit("save-and-continue");
    },
    async saveHandler() {
      this.updateValues();
      this.$emit("save");
    },
  },
});
</script>
