import { OptionsSelectField } from "@/config/form.config";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormSwitchField } from "@kinherit/framework/component.input/switch-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerContact } from "@kinherit/sdk";

export const IntroducerContactSettingsForm = (contact: IntroducerContact) =>
  defineForm({
    name: "introducer-contact-settings",
    data: () => contact,
    formAreas: (data) => [
      defineFormArea({
        name: "contact-details",
        data,
        template: GridLayout([
          ["status", "callStatus"],
          ["newsLetter", "newsLetterStatus"],
          ["notes"],
        ]),
        components: () => ({
          status: [
            OptionsSelectField({
              group: "introducerContactStatus",
              vModel: "status",
              props: {
                label: "Status",
                validators: ["required"],
                message: "Contacts main status",
              },
            }),
          ],
          callStatus: [
            OptionsSelectField({
              group: "introducerCpdCallStatus",
              vModel: "callStatus",
              props: {
                label: "Call Status",
                validators: ["required"],
                message: "Contacts CPD Call Status (deprecated)",
              },
            }),
          ],
          newsLetter: [
            FormSwitchField({
              reactive: true,
              props: {
                label: "Newsletter",
                message: "Subscribe to main introducer newsletter",
              },
              models: {
                value: "newsLetter",
              },
            }),
          ],
          newsLetterStatus: [
            FormMessage({
              props: {
                title: "Newsletter Status",
                class(data) {
                  return null !== data.newsLetter
                    ? data.newsLetter
                      ? "is-success"
                      : "is-danger"
                    : "is-plain";
                },
                text(data) {
                  return null !== data.newsLetter
                    ? data.newsLetter
                      ? "Subscribed"
                      : "Opted Out"
                    : "Not Set";
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
