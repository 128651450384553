import {
  IntroducerCompanyField,
  IntroducerNetworkField,
  OptionsSelectField,
  TagsField,
  UserField,
} from "@/config/form.config";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { IntroducerCompany } from "@kinherit/sdk";

export const IntroducerCompanySettingsForm = (company: IntroducerCompany) =>
  defineForm({
    name: "introducer-company-settings",
    data: () => company,
    formAreas: (data) => [
      defineFormArea({
        name: "company-details",
        data,
        template: GridLayout([
          ["type", "network"],
          ["generalNotes", "contractNotes"],
          ["status", "stage", "agentIntroducer"],
          [
            "kinvaultStatus",
            "salesTeamLead",
            "assignedSpecialist",
            "defaultLegalAssistant",
          ],
          ["lastMeaningfulConversation", "tags"],
        ]),
        components: () => ({
          network: [
            IntroducerNetworkField({
              props: {
                label: "Network",
                vModel: "network",
              },
            }),
          ],
          type: [
            OptionsSelectField({
              group: "introducerType",
              vModel: "type",
              props: {
                validators: ["required"],
              },
            }),
          ],
          generalNotes: [
            FormTextField({
              props: {
                label: "General Notes",
                isTextArea: true,
              },
              models: {
                value: "description",
              },
            }),
          ],
          contractNotes: [
            FormTextField({
              props: {
                label: "Contract Notes",
                isTextArea: true,
                message: "Only use for notes about fees",
              },
              models: {
                value: "contractNotes",
              },
            }),
          ],
          status: [
            OptionsSelectField({
              group: "introducerStatus",
              vModel: "status",
              props: {
                validators: ["required"],
              },
            }),
          ],
          stage: [
            OptionsSelectField({
              group: "introducerStage",
              vModel: "stage",
              props: {
                validators: ["required"],
              },
            }),
          ],
          agentIntroducer: [
            IntroducerCompanyField({
              props: {
                label: "Introduced By",
                vModel: "introducedBy",
                message: "Agent Introducer if appropriate",
              },
              query: {
                contracts: {
                  type: {
                    value: Equal("agentintroducer"),
                  },
                },
              },
            }),
          ],
          kinvaultStatus: [
            OptionsSelectField({
              group: "kinvaultStatus",
              vModel: "kinvaultStatus",
            }),
          ],
          salesTeamLead: [
            UserField({
              props: {
                label: "Sales Team Lead",
                vModel: "seniorAdviser",
                isScrollable: true,
              },
              query: {
                displayUserInPortal: Equal(true),
              },
            }),
          ],
          assignedSpecialist: [
            UserField({
              props: {
                label: "Assigned Estate Planner",
                vModel: "assignedTo",
                isScrollable: true,
              },
              query: {
                displayUserInPortal: Equal(true),
                isEstatePlanner: Equal(true),
              },
            }),
          ],
          defaultLegalAssistant: [
            UserField({
              props: {
                label: "Assigned Legal Assistant",
                vModel: "defaultLegalAssistant",
                message: "The assigned Legal Assistant",
              },
              query: {
                displayUserInPortal: Equal(true),
                isLegalAssistant: Equal(true),
              },
            }),
          ],
          lastMeaningfulConversation: [
            FormDateField({
              props: {
                label: "Last Meaningful Conversation",
              },
              models: {
                value: "lastConversation",
              },
            }),
          ],
          tags: [
            TagsField({
              props: {
                label: "Tags",
                vModel: "tags",
                isMultiSelect: true,
              },
            }),
          ],
        }),
      }),
    ],
  });
