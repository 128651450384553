import {
  IntroducerNetworkField,
  OptionsAutoCompleteField,
  TagsField,
} from "@/config/form.config";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";

export const ReadIntroducerCompanyProfileComponent = () =>
  FormElement({
    props: {
      is: "div",
      style: {
        borderBottom: `1px dashed hsl(0,0%,71%)`,
      },
      class: "mb-4 pb-3",
      reference: "",
    },
    slots: {
      default: {
        template: GridLayout([
          ["title"],
          [
            "companyTags",
            "companyNetwork",
            "companyStatus",
            "companyStage",
            "companyType",
          ],
        ]),
        components: {
          title: [
            FormTitle({
              props: {
                text: "Introducers Company",
                size: "is-4",
              },
            }),
          ],
          companyStatus: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Company status",
              },
              group: "introducerStatus",
              vModel: "introducerCompanyStatus",
              simplifyData: true,
            }),
          ],
          companyStage: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Company Stage",
              },
              group: "introducerStage",
              vModel: "introducerCompanyStage",
              simplifyData: true,
            }),
          ],
          companyType: [
            OptionsAutoCompleteField({
              props: {
                isMultiSelect: true,
                label: "Company type",
              },
              group: "introducerType",
              vModel: "introducerCompanyType",
              simplifyData: true,
            }),
          ],
          companyNetwork: [
            IntroducerNetworkField({
              props: {
                label: "Company Network",
                isMultiSelect: true,
                vModel: "introducerCompanyNetwork",
              },
              simplifyData: true,
            }),
          ],
          companyTags: [
            TagsField({
              props: {
                label: "Company Tags",
                isMultiSelect: true,
                vModel: "introducerCompanyTags",
              },
              simplifyData: true,
            }),
          ],
        },
      },
    },
  });
