import { Api } from "@/service/api.service";
import { Subscription } from "@kinherit/sdk";

interface CreateSubscriptionMessage {
  subscription: Subscription;
}

interface CreateSubscriptionResponse {
  subscription: Subscription;
}

export const CreateSubscriptionHandler = async (
  message: CreateSubscriptionMessage,
): Promise<CreateSubscriptionResponse> => {
  const response = await Api.resource("portal", "/v2/portal/subscription")
    .method("post")

    .body({
      status: message.subscription.$data.status,
      kintin: message.subscription.$data.kintin,
      productSku: message.subscription.$data.productSku,
      provider: message.subscription.$data.provider,
      billingDate: message.subscription.$data.billingDate ?? undefined,
      procXero: message.subscription.$data.procXero,
      procDd: message.subscription.$data.procDd,
      notes: message.subscription.$data.notes,
      subscriptionValue:
        message.subscription.$data.subscriptionValue ?? undefined,
      billingEndDate: message.subscription.$data.billingEndDate ?? undefined,
    })
    .result();

  const subscription = Subscription.$inflate(response.subscription).first();

  if (!subscription) {
    throw new Error("Subscription not found");
  }

  message.subscription.$delete();

  return {
    subscription,
  };
};
