import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { CpdDetailsBreadCrumb } from "../../breadcrumbs";
import { Routes as IntroducersRoutes } from "./introducers";
import { Routes as RegistrationsRoutes } from "./registrations";
import { Routes as SummaryRoutes } from "./summary";

export const CpdDetailsRoute = "CpdDetails";
export const CpdDetailsURI = "/cpd/:cpdSession";
export type CpdDetailsParams = RouteParamObject<typeof CpdDetailsURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: CpdDetailsRoute,
    path: CpdDetailsURI,
    components: {
      default: () =>
        import(/* webpackChunkName: "CpdDetails" */ "./CpdDetails.vue"),
      sidebar: () =>
        import(
          /* webpackChunkName: "CpdDetailsSidebar" */ "./CpdDetails.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => CpdDetailsBreadCrumb(params as CpdDetailsParams),
    },
    children: [...SummaryRoutes, ...IntroducersRoutes, ...RegistrationsRoutes],
  },
];
