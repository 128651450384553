import { KnowledgeBaseCategoryMasterListBreadCrumb } from "@/module/knowledge-base/breadcrumbs";
import { KnowledgeBaseCategoryDetailsRoute } from "@/module/knowledge-base/page";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";

/** Documents */
export const KnowledgeBaseDocumentDetailsContentRoute =
  "KnowledgeBaseDocumentDetailsContent";
export const KnowledgeBaseDocumentDetailsContentURI =
  "/knowledge-base/category/:category/document/:document";
export type KnowledgeBaseDocumentDetailsContentParams = RouteParamObject<
  typeof KnowledgeBaseDocumentDetailsContentURI
>;

export const KnowledgeBaseDocumentDetailsFilesRoute =
  "KnowledgeBaseDocumentDetailsFiles";
export const KnowledgeBaseDocumentDetailsFilesURI =
  "/knowledge-base/category/:category/document/:document/files";
export type KnowledgeBaseDocumentDetailsFilesParams = RouteParamObject<
  typeof KnowledgeBaseDocumentDetailsFilesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KnowledgeBaseDocumentDetailsContentRoute,
    path: KnowledgeBaseDocumentDetailsContentURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgeBaseDocumentDetails.Content" */ "./KnowledgeBaseDocumentDetails.Content.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        {
          text: KnowledgeBaseCategory.$findOne(params.category)?.title ?? "",
          route: {
            name: KnowledgeBaseCategoryDetailsRoute,
            params: { category: params.category },
          },
        },
        {
          text: KnowledgeBaseDocument.$findOne(params.document)?.title ?? "",
          route: { name: KnowledgeBaseDocumentDetailsContentRoute, params },
        },
      ],
    },
  },
  {
    name: KnowledgeBaseDocumentDetailsFilesRoute,
    path: KnowledgeBaseDocumentDetailsFilesURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgeBaseDocumentDetails.Files" */ "./KnowledgeBaseDocumentDetails.Files.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        {
          text: KnowledgeBaseCategory.$findOne(params.category)?.title ?? "",
          route: {
            name: KnowledgeBaseCategoryDetailsRoute,
            params: { category: params.category },
          },
        },
        {
          text: KnowledgeBaseDocument.$findOne(params.document)?.title ?? "",
          route: { name: KnowledgeBaseDocumentDetailsContentRoute, params },
        },
        {
          text: "Files",
          route: { name: KnowledgeBaseDocumentDetailsFilesRoute, params },
        },
      ],
    },
  },
];
