import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerInvoiceHandler } from "./create-introducer-invoice.action";
import { DownloadIntroducerRemittanceHandler } from "./download-introducer-remittance.action";
import { ReadIntroducerFeeKintinHandler } from "./read-introducer-fee-kintin.action";

export const Actions = {
  "introducer-fee/kintin/read": ReadIntroducerFeeKintinHandler,
  "introducer-fee/kintin/download": DownloadIntroducerRemittanceHandler,
  "introducer-fee/invoice/create": CreateIntroducerInvoiceHandler,
};

export const IntroducerFeeKintinActionBus = DefineActionBus({
  name: "Introducer.Fee.Kintin",
  actions: {
    read: ReadIntroducerFeeKintinHandler,
    download: DownloadIntroducerRemittanceHandler,
    create: CreateIntroducerInvoiceHandler,
  },
});
