<template>
  <div v-if="data">
    <strong>Intentional Exclusions </strong>
    <p>
      <span v-if="data.exclusions.length === 1">
        I Have deliberately excluded
        <span v-if="data.exclusions[0].relationship">
          my <span v-html="helpers.var(data.exclusions[0].relationship)" />
        </span>
        &nbsp;<span
          v-html="helpers.var(data.exclusions[0].who?.toUpperCase())"
        />
        (hereinafter referred to as 'the Excluded Person') from this my Will and
        it is my intention that the said
        <span v-html="helpers.var(data.exclusions[0].who?.toUpperCase())" />
        should receive no part of my Estate
      </span>

      <span v-if="data.exclusions.length > 1">
        I Have deliberately excluded
        <span v-for="(exclusion, index) in data.exclusions" :key="index">
          <span v-if="index !== 0"> and </span>
          my <span v-html="helpers.var(exclusion.relationship)" />&nbsp;
          <span v-html="helpers.var(exclusion.who?.toUpperCase())" />
        </span>
        (hereinafter referred to as 'the Excluded Persons') from this my Will
        and it is my intention that the said
        <span v-for="(exclusion, index) in data.exclusions" :key="index">
          <span v-if="index !== 0"> and </span>
          <span v-html="helpers.var(exclusion.who?.toUpperCase())" />&nbsp;
        </span>
        should receive no part of my Estate
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: `ExclusionsSection`,
  mixins: [WillBuilderService.mixin],
});
</script>
