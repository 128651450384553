import { IntroducerFeePaymentRunDetailsParams } from "@/module/introducer.fees/page/details";
import { IntroducerFeePaymentRun } from "@kinherit/sdk/index";
import { defineComponent } from "vue";

export const IntroducerFeePaymentRunDetailsMixin = defineComponent({
  name: "IntroducerFeePaymentRunDetailsMixin",
  data: () => ({
    introducerFeePaymentRun: null as null | IntroducerFeePaymentRun,
  }),
  computed: {
    $params(): IntroducerFeePaymentRunDetailsParams {
      return this.$route.params as IntroducerFeePaymentRunDetailsParams;
    },
  },
  async mounted() {
    this.introducerFeePaymentRun = IntroducerFeePaymentRun.$findOne(
      this.$params.introducerFeePaymentRun,
    );
  },
});
