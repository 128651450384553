import { Api } from "@/service/api.service";
import { Attorney, Kintin } from "@kinherit/sdk";

interface UpdateAttorneyMessage {
  kintin: string | Kintin;
  attorney: Attorney;
}

interface UpdateAttorneyResponse {
  attorney: Attorney;
}

export const UpdateAttorneyHandler = async (
  message: UpdateAttorneyMessage,
): Promise<UpdateAttorneyResponse> => {
  const { attorney: attorneyData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-attorney/{kintin}/person/attorney/{attorney}",
    {
      kintin: message.kintin,
      attorney: message.attorney.$data.id,
    },
  )
    .method("patch")

    .body({
      isReserve: message.attorney.$data.isReserve,
      notes: message.attorney.$data.notes,
      isCertProv: message.attorney.$data.isCertProv,
      isHealthWealth: message.attorney.$data.isHealthWealth,
      isPropFinance: message.attorney.$data.isPropFinance,
      person: message.attorney.$data.person,
      forPerson: message.attorney.$data.forPerson,
      sortOrder: message.attorney.$data.sortOrder,
    })
    .result();

  const attorney = Attorney.$inflate(attorneyData).first();

  if (!attorney) {
    throw new Error("Failed to update attorney");
  }

  return { attorney };
};
