import { Api, IntroducerFeePaymentRun } from "@kinherit/sdk/index";

// export class ApproveIntroducerFeePaymentRunHandler extends ActionBase {
//   constructor(
//     private data: {
//       introducerFeePaymentRun: IntroducerFeePaymentRun | string;
//     },
//   ) {
//     super();
//   }

//   async execute() {
export const ApproveIntroducerFeePaymentRunHandler = async (data: {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
}): Promise<void> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}/approve",
    data,
  )
    .method("post")
    .result();
};
