import {
  AccountReferralCodeField,
  SharedFormProps,
} from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { AccountReferralCode, BrandedKinvaultInvitation } from "@kinherit/sdk";
import { reactive } from "vue";

export const UpdateBrandedKinvaultInvitationForm = (
  data: BrandedKinvaultInvitation,
) => {
  const localData = reactive({
    referralCode: null as null | AccountReferralCode,
  });

  return defineForm({
    name: "update-branded-kinvault-invitation-form",
    props: SharedFormProps,
    data: () => data as BrandedKinvaultInvitation,
    formAreas: (data) => [
      defineFormArea({
        name: "branded-kinvault-invitation-form-area",
        data,
        template: GridLayout([
          ["externalRef", "isBillable"],
          ["referralCode", "introducerContact"],
        ]),
        components: () => ({
          externalRef: [
            FormTextField({
              props: {
                label: "External Reference",
                message: "IFA or 3rd Party reference",
              },
              models: {
                value: "externalRef",
              },
            }),
          ],
          referralCode: [
            AccountReferralCodeField({
              props: {
                label: "Referral Code",
                reference: "referralCode",
                isMultiSelect: false,
                validators: ["required"],
                vModel: {
                  get: (data) => data.referral?.referralCode,
                  set: (value: null | AccountReferralCode, data, controls) => {
                    if (data.referral) {
                      data.referral.referralCode = value as AccountReferralCode;
                    }

                    localData.referralCode = value;

                    controls.rebuildForm();
                  },
                },
              },
            }),
          ],
          //introducerContact: [
          //  IntroducerContactField({
          //    props: {
          //      size: "is-normal",
          //      reference: "contact",
          //      vModel: {
          //        get: (data) => data.introducerContact,
          //        set: (value: null | IntroducerContact, data, controls) => {
          //          DashLoader.set(data, ["referral", "contact"], value);
          //
          //          controls.rebuildForm();
          //        },
          //      },
          //    },
          //    query: {
          //      companies: {
          //        referralCodes: {
          //          id: Equal(localData.referralCode?.id),
          //        },
          //      },
          //    },
          //  }),
          //],
          isBillable: [
            FormCheckboxField({
              props: {
                label: "Is Billable",
                message: "Include this in billing calculations",
              },
              models: {
                value: "isBillable",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
