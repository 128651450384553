import { OptionsSelectField, SharedFormProps } from "@/config/form.config";
import {
  FormAddressSection,
  FormContactDetailButtonSection,
  FormEmailSection,
  FormPhoneSection,
  FormWebsiteSection,
} from "@/shared/form/profile/update-profile.form";
import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormDateOfBirthField } from "@kinherit/framework/component.input/date-of-birth-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import {
  ColumnLayout,
  GridLayout,
} from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Kintin, Person } from "@kinherit/sdk";

export const UpdatePersonForm = ({
  person,
  kintin,
}: {
  person: Person;
  kintin: Kintin;
}) => {
  const relationSlots = Array<string>();

  if (kintin.$data.primaryPerson !== person.id) {
    relationSlots.push("primaryRelation");
  }

  if (kintin.$data.secondaryPerson !== person.id && kintin.secondaryPerson) {
    relationSlots.push("secondaryRelation");
  }

  return defineForm({
    name: "update-person-form",
    props: SharedFormProps,
    data: () => ({
      person,
      activeTab: 0,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "update-person-form-area",
        data,
        template: {
          children: [
            ColumnLayout(["type"]),
            {
              slot: "tabs",
            },
          ],
        },
        components: () => ({
          type: [
            FormSelectField({
              props: {
                label: "Type",
                size: "is-small",
                options: {
                  person: "Person",
                  company: "Company",
                  charity: "Charity",
                },
                reference: "person.type",
              },
              models: {
                value: {
                  get: (data) => data.person.type,
                  set: (value, data, controls) => {
                    data.person.type = value;
                    controls.rebuildForm();
                  },
                },
              },
            }),
          ],
          tabs: [
            FormTabs({
              props: {
                isLazy: true,
                config:
                  kintin.type.value === "single" &&
                  kintin.$data.primaryPerson === person.id
                    ? [{ label: "Main Details" }, { label: "Contact Details" }]
                    : [
                        { label: "Main Details" },
                        { label: "Contact Details" },
                        { label: "Relationships" },
                      ],
                reference: "tabs",
              },
              models: {
                tab: "activeTab",
              },
              slots: {
                quickSummary: {
                  template:
                    person.type === "person"
                      ? GridLayout([
                          ["title", "suffix", "salutation"],
                          ["firstName", "middleNames", "lastName"],
                          ["organsationName", "organisationNumber"],
                          ["dateOfBirth", "dateOfDeath"],
                          ["gender", "maritalStatus"],
                          ["isTrusted"],
                          ["under18", "requiresCare"],
                          ...(person.isTrusted
                            ? [["notifications", "notificationDate"]]
                            : []),
                          ["notifyOnDeath"],
                        ])
                      : GridLayout([
                          ["organsationName"],
                          ["organisationNumber"],
                          ["firstName", "lastName"],
                        ]),
                  components: {
                    firstName: [
                      FormTextField({
                        props: {
                          label: "First Name",
                        },
                        models: {
                          value: "person.profile.firstName",
                        },
                      }),
                    ],
                    middleNames: [
                      FormTextField({
                        props: {
                          label: "Middle Names",
                        },
                        models: {
                          value: "person.profile.middleNames",
                        },
                      }),
                    ],
                    lastName: [
                      FormTextField({
                        props: {
                          label: "Last Name",
                        },
                        models: {
                          value: "person.profile.lastName",
                        },
                      }),
                    ],
                    organsationName: [
                      FormTextField({
                        props: {
                          size: "is-small",
                          autoCollapseLabel: true,
                          label: (data) =>
                            data.person.type === "charity"
                              ? "Charity Name"
                              : "Company Name",
                        },
                        models: {
                          value: "person.profile.organisationName",
                        },
                      }),
                    ],
                    organisationNumber: [
                      FormTextField({
                        props: {
                          size: "is-small",
                          autoCollapseLabel: true,
                          label: (data) =>
                            data.person.type === "charity"
                              ? "Charity Number"
                              : "Company Number",
                        },
                        models: {
                          value: "person.profile.organisationNumber",
                        },
                      }),
                    ],
                    suffix: [
                      FormTextField({
                        props: {
                          label: "Suffix",
                          size: "is-small",
                          autoCollapseLabel: true,
                        },
                        models: {
                          value: "person.profile.suffix",
                        },
                      }),
                    ],
                    title: [
                      OptionsSelectField({
                        group: "titles",
                        vModel: "person.profile.title",
                        props: {
                          size: "is-small",
                          autoCollapseLabel: true,
                        },
                      }),
                    ],
                    salutation: [
                      FormTextField({
                        props: {
                          label: "Known As",
                          size: "is-small",
                          autoCollapseLabel: true,
                        },
                        models: {
                          value: "person.profile.knownAs",
                        },
                      }),
                    ],
                    dateOfBirth: [
                      FormDateOfBirthField({
                        props: {
                          label: "Date of Birth",
                        },
                        models: {
                          value: "person.dateOfBirth",
                        },
                      }),
                    ],
                    dateOfDeath: [
                      FormDateOfBirthField({
                        props: {
                          label: "Date of Death",
                        },
                        models: {
                          value: "person.dateOfDeath",
                        },
                      }),
                    ],
                    requiresCare: [
                      FormCheckboxField({
                        props: {
                          label: "Requires specialised care",
                          message:
                            "Mark if this person requires specialised care",
                        },
                        models: {
                          value: "person.requiresCare",
                        },
                      }),
                    ],
                    isTrusted: [
                      FormCheckboxField({
                        reactive: true,
                        props: {
                          label: "Trusted Person",
                          message:
                            "Ensure ticked unless a charity, company, adviser or certificate provider",
                        },
                        models: {
                          value: "person.isTrusted",
                        },
                      }),
                    ],
                    gender: [
                      OptionsSelectField({
                        vModel: "person.gender",
                        group: "genders",
                        props: {
                          size: "is-small",
                          autoCollapseLabel: true,
                        },
                      }),
                    ],
                    maritalStatus: [
                      OptionsSelectField({
                        vModel: "person.maritalStatus",
                        group: "maritalStatuses",
                        props: {
                          size: "is-small",
                          autoCollapseLabel: true,
                        },
                      }),
                    ],
                    notifications: [
                      FormCheckboxField({
                        props: {
                          label: "Notifications",
                          message:
                            "Enable Kinvault notifications for this person",
                          reference: "person.notifications",
                        },
                        models: {
                          value: {
                            get: (data) => data.person.notifications,
                            set: (value, data, controls) => {
                              data.person.notifications = value;
                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                    ],
                    under18: [
                      FormCheckboxField({
                        props: {
                          label: "Under 18",
                          message: "Mark as Under 18 for planning purposes",
                        },
                        models: {
                          value: "person.under18ForPlanningPurposes",
                        },
                      }),
                    ],
                    notificationDate: [
                      FormDateField({
                        props: {
                          vIf: (data) => data.person.notifications,
                          label: "Notification Date",
                        },
                        models: {
                          value: "person.notificationDate",
                        },
                      }),
                    ],
                    notifyOnDeath: [
                      FormCheckboxField({
                        props: {
                          label: "Notify on death",
                          message: "This person should be notified on death",
                        },
                        models: {
                          value: "notifyOnDeath",
                        },
                      }),
                    ],
                  },
                },
                contactDetails: {
                  template: GridLayout([
                    ["buttons"],
                    ["address"],
                    ["email"],
                    ["phone"],
                    ["website"],
                  ]),
                  components: {
                    buttons: FormContactDetailButtonSection(
                      data.person.profile,
                      kintin,
                    ),
                    address: FormAddressSection(data.person.profile, kintin),
                    email: FormEmailSection(data.person.profile),
                    phone: FormPhoneSection(data.person.profile),
                    website: FormWebsiteSection(data.person.profile),
                  },
                },
                relations: {
                  template: GridLayout(relationSlots),
                  components: {
                    primaryRelation: [
                      FormTitle({
                        props: {
                          text: `Relation to ${kintin.primaryPerson.profile.fullName}`,
                          size: "is-5",
                        },
                      }),
                      OptionsSelectField({
                        vModel: "person.relationToPrimaryPerson",
                        group: "relationRelationship",
                        optionDataFilter:
                          data.person.kintinPersonType === "secondary" ||
                          data.person.kintinPersonType === "primary"
                            ? {
                                type: "partner",
                              }
                            : {},
                        props: {
                          vIf: (data) =>
                            data.person.kintinPersonType !== "primary",
                        },
                      }),
                    ],
                    secondaryRelation: [
                      FormTitle({
                        props: {
                          text: `Relation to ${kintin.secondaryPerson?.profile.fullName}`,
                          size: "is-5",
                        },
                      }),
                      OptionsSelectField({
                        vModel: "person.relationToSecondaryPerson",
                        group: "relationRelationship",
                        optionDataFilter:
                          data.person.kintinPersonType === "secondary" ||
                          data.person.kintinPersonType === "primary"
                            ? {
                                type: "partner",
                              }
                            : {},
                        props: {
                          vIf: (data) =>
                            data.person.kintinPersonType !== "secondary",
                        },
                      }),
                    ],
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
