import { KintinAddressField, SharedFormProps } from "@/config/form.config";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormAddressLookupField } from "@kinherit/framework/component.input/address-lookup-field";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { OpenAutocompleteDialog } from "@kinherit/framework/global/dialog";
import { ValidationType } from "@kinherit/framework/validation/core/validation-type";
import { Address, Kintin } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common";
import { Country } from "@kinherit/ts-common/dto/country";
import { Uuid } from "@kinherit/ts-common/service/uuid";
import { CountryCode, WorldInfo } from "@kinherit/ts-common/service/world-info";
import { reactive } from "vue";

export const UpdateAddressForm = (
  {
    data,
    kintin,
    unique,
    isAddressBook = false,
    isNew,
  }: {
    data?: Address;
    kintin?: Kintin;
    unique?: boolean;
    isAddressBook?: boolean;
    isNew?: boolean;
  } = {
    data: undefined,
  },
) => {
  isNew = undefined === isNew ? !data : isNew;

  if (!data) {
    data = new Address({
      id: Uuid.generate(),
      line1: "",
      line2: "",
      city: "",
      state: "",
      postcode: "",
      country: "GB",
      summary: "",
      primaryMailing: false,
      primaryResidential: false,
      createdAt: new DateTime().formatMachine,
    });
  }

  const localData = reactive({
    search: "",
    search2: "",
    message: null as string | null,
  });

  return defineForm({
    name: "update-address-form",
    props: SharedFormProps,
    data: () => data as Address,
    formAreas: (data) => [
      defineFormArea({
        name: "address-form-area",
        data,
        template: GridLayout([
          ...(window.Kernel.Mode === KernelModes.Dev
            ? [
                [
                  {
                    html: `
                      <ul class="mb-5">
                        <li><b>XX2 00X</b> or <b>TR19 7AA</b> or <b>KW1 4YT</b> Returns a 'successful' response <b class="has-text-success">200</b>. Your request was successful.</li>
                        <li class="mt-2"><b>XX4 00X</b> Returns 'bad request' response <b class="has-text-danger">400</b>. Your postcode is not valid.</li>
                        <li class="mt-2"><b>XX4 01X</b> Returns 'unauthorized' response <b class="has-text-danger">401</b>. Your api-key is not valid.</li>
                        <li class="mt-2"><b>XX4 03X</b> Returns 'forbidden' response <b class="has-text-danger">403</b>. Your api-key is valid but you do not have permission to access to the resource.</li>
                        <li class="mt-2"><b>XX4 29X</b> Returns 'too many requests' response <b class="has-text-danger">429</b>. You have made more requests than your allowed limit.</li>
                        <li class="mt-2"><b>XX5 00X</b> Returns 'server error' response <b class="has-text-danger">500</b>. Server error, you should never see this.</li>
                      </ul>
                    `,
                  },
                ],
                ["search"],
              ]
            : ["search"]),
          ["search2"],
          ...(kintin ? [["available"]] : []),
          {
            is: "hr",
          },
          [...(!isNew ? ["warning"] : [])],
          ["line1", "line2"],
          ["city", "state"],
          ["postcode"],
          ["country"],
        ]),
        components: () => ({
          available: [
            KintinAddressField({
              props: {
                label: `From existing addresses`,
                reference: "address",
                vModel: {
                  get: () => null,
                  set: (value: Address, data, controls) => {
                    data.line1 = value.line1;
                    data.line2 = value.line2;
                    data.city = value.city;
                    data.state = value.state;
                    data.postcode = value.postcode;
                    data.country = value.country;
                    controls.rebuildTemplateBindings();
                  },
                },
              },
              kintin: kintin?.id as string,
              exclude: [data],
              unique,
            }),
          ],
          warning: [
            FormMessage({
              props: {
                vIf: !isNew,
                color: "is-warning",
                title: "Warning",
              },
              slots: {
                default: [
                  FormElement({
                    props: {
                      is: "span",
                      text: "Changes to this address will be propagated throughout the system.",
                    },
                  }),
                  FormElement({
                    props: {
                      vIf: kintin && !isAddressBook,
                      is: "span",
                      text: "Use the address page to change this address for all residents",
                    },
                  }),
                ],
              },
            }),
          ],
          search: [
            FormTextField({
              props: {
                label: "UK Postcode Search",
                placeholder: "-- Please enter a UK postcode --",
                message: () => localData.message,
                validators: [
                  [
                    "regex",
                    {
                      buildin: "POSTCODE",
                    },
                  ],
                ],
                autofocus: true,
                reference: "search",
              },
              models: {
                value: {
                  get: () => localData.search,
                  set: (value: string) =>
                    (localData.search = value.toUpperCase()),
                },
              },
              slots: {
                right: [
                  FormButton({
                    props: {
                      text: "Search",
                      color: "is-success",
                      reference: "searchButton",
                    },
                    emits: {
                      click: async (
                        _mouseEvent,
                        address,
                        controls,
                      ): Promise<void> => {
                        localData.message = null;
                        // const { addresses } =
                        //   await window.Kernel.ActionBus.execute(
                        //     "core/address/search",
                        //     {
                        //       postcode: localData.search,
                        //     },
                        //   );
                        const { addresses } =
                          await window.Kernel.ActionBus.core.address.search({
                            postcode: localData.search,
                          });

                        if (addresses.length === 0) {
                          localData.message = "No address found";
                          return;
                        }

                        const selected = (await OpenAutocompleteDialog({
                          dialog: {
                            title: "Select Address",
                          },
                          list: {
                            options: () => addresses,
                            mapOptions: {
                              label: "summary",
                              value: "id",
                            },
                          },
                        })) as Address;

                        address.line1 = selected.line1;
                        address.line2 = selected.line2;
                        address.city = selected.city;
                        address.state = selected.state;
                        address.postcode = selected.postcode;
                        address.country = "GB";

                        controls.rebuildForm();
                      },
                    },
                  }),
                ],
              },
            }),
          ],
          search2: [
            FormAddressLookupField({
              props: {
                label: "Search for an address (Beta)",
                autofocus: true,
                placeholder: "Search for an address",
              },
              emits: {
                "update:value": (value, data, controls) => {
                  data.line1 = value.line1;
                  data.line2 = value.line2;
                  data.city = value.city;
                  data.state = value.state;
                  data.postcode = value.postcode;
                  data.country = value.country;
                  controls.rebuildForm();
                },
              },
            }),
          ],
          line1: [
            FormTextField({
              props: {
                label: "Line 1",
              },
              models: {
                value: "line1",
              },
            }),
          ],
          line2: [
            FormTextField({
              props: {
                label: "Line 2",
              },
              models: {
                value: "line2",
              },
            }),
          ],
          city: [
            FormTextField({
              props: {
                label: "City",
              },
              models: {
                value: "city",
              },
            }),
          ],
          state: [
            FormTextField({
              props: {
                label: "State",
              },
              models: {
                value: "state",
              },
            }),
          ],
          postcode: [
            FormTextField({
              props: {
                label: "Postcode",
                validators: [
                  [
                    "regex",
                    {
                      buildin: "POSTCODE",
                      type: ValidationType.Warning,
                    },
                  ],
                ],
              },
              models: {
                value: "postcode",
              },
            }),
          ],
          country: [
            FormAutoCompleteField({
              props: {
                label: "Country",
                options: WorldInfo.countries,
                mapOptions: {
                  value: "code",
                  label: "name",
                },
                validators: ["required"],
                reference: "country",
              },
              models: {
                value: {
                  get: (data) =>
                    WorldInfo.getCountry(data.country as CountryCode),
                  set: (country: Country | null, address) => {
                    if (null === address) {
                      return;
                    }
                    address.country = country?.code ?? null;
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
