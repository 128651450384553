import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsBreadCrumb } from "../../../breadcrumbs";

export const LeadDetailsIfaCallScriptRoute = "LeadDetailsIfaCallScript";
export const LeadDetailsIfaCallScriptURI = "/lead/:lead/ifa-call-script";
export type LeadDetailsIfaCallScriptParams = RouteParamObject<
  typeof LeadDetailsIfaCallScriptURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsIfaCallScriptRoute,
    path: LeadDetailsIfaCallScriptURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsIfaCallScript" */ "./LeadDetails.IfaCallScript.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...LeadDetailsBreadCrumb(params),
        {
          text: "IFA Call Script",
          route: { name: LeadDetailsIfaCallScriptRoute, params },
        },
      ],
    },
  },
];
