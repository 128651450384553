import { IntroducerCompany } from "@kinherit/sdk";

export const IntroducerCompanyMasterListBreadCrumb = {
  text: "Introducer Companies",
  route: { name: "IntroducerCompanyMasterList" },
};

export const IntroducerCompanyDetailsBreadCrumb = (params: any) => ({
  text:
    IntroducerCompany.$findOne(params.introducerCompany)?.profile.fullName ??
    "",
  route: { name: "IntroducerCompanyDetails", params },
});
