import { Api } from "@/service/api.service";

interface RequestMultiFactorAuthMessage {
  methodId: string;
}

export interface RequestMultiFactorAuthResult {
  multiFactorSessionId: string;
  code?: number;
  type: "email" | "sms" | "totp";
}

export const RequestMultiFactorAuthHandler = async (
  message: RequestMultiFactorAuthMessage,
): Promise<RequestMultiFactorAuthResult> => {
  const response = await Api.resource("auth", "/v2/auth/multi-factor/request")
    .method("put")
    .body({
      methodId: message.methodId,
    })
    .result();

  return {
    multiFactorSessionId: response.session.id,
    code: response.session.code,
    type: response.session.type,
  };
};
