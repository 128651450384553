<template>
  <span v-if="data">
    <strong> Legacy - ISA (Individual Savings Account) </strong>
    <ol type="i">
      <li>
        I give my Individual Savings Account including any accrued interest and
        other rights held with
        <span v-for="(item, i) in data.isaGifts" :key="i">
          <span v-html="helpers.var(item.notes)" />
        </span>
        to
        <span v-for="(item, i) in data.isaGifts" :key="i">
          <span v-html="helpers.var(helpers.formatRecipient(item))" />
        </span>
        <span v-for="(item, i) in data.isaGifts" :key="i">
          <span v-html="helpers.var(helpers.formatPredeceased(item))" />
        </span>
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
