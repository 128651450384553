<template>
  <div class="page-1 columns">
    <div class="page column" :key="renderKey">
      <div class="grey">
        <h1>1. A summary of your situation</h1>
        <p class="lead">
          This brief summary covers some of the key people and facts as
          discussed.
        </p>
      </div>
      <div class="white">
        <h2>Your family</h2>
        <p>
          <span>{{ generateRelationshipStatus() }}.&nbsp;</span>
          <span>{{ generateChildStatus() }}.&nbsp;</span>
          <!--span v-if="dependents.length">
            {{ generateDependents() }}
          </span-->
        </p>
        <h2>Your property and assets</h2>
        <p>
          <span>{{ generateHomeOwner() }}</span
          >. You estimate your personal assets total around
          <span>&pound;{{ assetnetTotal.toFormattedString(false, 2) }}</span>
          (excluding any life insurance or pension policies)<span
            v-if="advbusinessassetvalue && advbusinessassetvalue.amount > 0"
            >, of which approximately &pound;{{
              advbusinessassetvalue.toFormattedString(false, 2)
            }}
            are business or agricultural assets</span
          >.
        </p>
        <p v-if="checkIsYes('3.0.5a') || checkIsYes('3.0.6b')">
          You mentioned you had life insurance and/or pension policies. Once
          your Will is in place, we'll send you a list of things you may want to
          check with your provider or financial adviser.
        </p>
        <h2>Your beneficiaries</h2>
        <h3>Dividing up your estate</h3>
        <div
          v-if="override.includes('advBeneficiaryNotes')"
          v-html="advBeneficiaryNotes"
        ></div>
        <div v-else>
          <p>
            <span>{{ forYou() }}</span>
            <span v-if="concatPrimaryBeneficiaries1.length === 0">
              [NO BENEFICIARIES]
            </span>
            <span v-if="concatPrimaryBeneficiaries1.length === 1">
              would like your entire estate to go to
              {{ beneficiaryName(concatPrimaryBeneficiaries1[0]) }}.
            </span>
            <span v-if="concatPrimaryBeneficiaries1.length > 1">
              would like your estate to be divided in the following way between
              <ul>
                <li v-for="(pb1, i) in concatPrimaryBeneficiaries1" :key="i">
                  {{ beneficiaryName(pb1) }} ({{ shareOrPercentage(pb1) }})
                </li>
              </ul>
            </span>
            <span v-if="concatPrimaryBeneficiaries2.length === 1">
              and if they have pre-deceased, to
              {{ beneficiaryName(concatPrimaryBeneficiaries2[0]) }}.
            </span>
            <span v-if="concatPrimaryBeneficiaries2.length > 1">
              and if they have pre-deceased, to be divided in the following way
              between
              <ul>
                <li v-for="(pb2, ii) in concatPrimaryBeneficiaries2" :key="ii">
                  {{ beneficiaryName(pb2) }} ({{ shareOrPercentage(pb2) }})
                </li>
              </ul>
            </span>
          </p>
          <p v-if="concatPrimaryReserveBeneficiaries.length > 0">
            And in reserve,
            <span v-if="concatPrimaryReserveBeneficiaries.length === 1">
              you would like your estate to go to
              {{ beneficiaryName(concatPrimaryReserveBeneficiaries[0]) }}.
            </span>
            <span v-if="concatPrimaryReserveBeneficiaries.length > 1">
              you would like your estate to be divided in the following way
              between
              <ul>
                <li
                  v-for="(pbr, iii) in concatPrimaryReserveBeneficiaries"
                  :key="iii"
                >
                  {{ beneficiaryName(pbr) }} ({{ shareOrPercentage(pbr) }})
                </li>
              </ul>
            </span>
          </p>
          <p v-if="type === 'joint'">
            <span>
              For {{ secondarySalutation }} you
              <span v-if="concatSecondaryBeneficiaries1.length === 0"
                >[NO BENEFICIARIES]</span
              >
            </span>
            <span v-if="concatSecondaryBeneficiaries1.length === 1">
              would like your entire estate to go to
              {{ beneficiaryName(concatSecondaryBeneficiaries1[0]) }}.
            </span>
            <span v-if="concatSecondaryBeneficiaries1.length > 1">
              would like your estate to be divided in the following way between
              <ul>
                <li v-for="(pb1, i) in concatSecondaryBeneficiaries1" :key="i">
                  {{ beneficiaryName(pb1) }} ({{ shareOrPercentage(pb1) }})
                </li>
              </ul>
            </span>
            <span v-if="concatSecondaryBeneficiaries2.length === 1">
              and if they have pre-deceased, to
              {{ beneficiaryName(concatSecondaryBeneficiaries2[0]) }}.
            </span>
            <span v-if="concatSecondaryBeneficiaries2.length > 1">
              and if they have pre-deceased, to be divided in the following way
              between
              <ul>
                <li
                  v-for="(pb2, ii) in concatSecondaryBeneficiaries2"
                  :key="ii"
                >
                  {{ beneficiaryName(pb2) }} ({{ shareOrPercentage(pb2) }})
                </li>
              </ul>
            </span>
          </p>
          <p v-if="concatSecondaryReserveBeneficiaries.length > 0">
            And in reserve,
            <span v-if="concatSecondaryReserveBeneficiaries.length === 1">
              you would like your estate to go to
              {{ beneficiaryName(concatSecondaryReserveBeneficiaries[0]) }}.
            </span>
            <span v-if="concatSecondaryReserveBeneficiaries.length > 1">
              you would like your estate to be divided in the following way
              between
              <ul>
                <li
                  v-for="(pbr, iii) in concatSecondaryReserveBeneficiaries"
                  :key="iii"
                >
                  {{ beneficiaryName(pbr) }} ({{ shareOrPercentage(pbr) }})
                </li>
              </ul>
            </span>
          </p>
        </div>
        <h3>Gifts you want to make</h3>

        <div
          v-if="override.includes('advGiftNotes')"
          v-html="advGiftNotes"
        ></div>
        <div v-else>
          <p v-if="gifts.length === 0">You do not want to make any gifts.</p>
          <p v-if="primaryGifts.length > 0">
            <span>{{ forYou() }}</span> would like to make the following gifts:
            <span>
              <ul>
                <li v-for="(g, gi) in primaryGifts" :key="gi">
                  {{ beneficiaryName(g) }} {{ outputGiftDetails(g) }}
                </li>
              </ul>
            </span>
          </p>
          <p v-if="secondaryGifts.length > 0 && type === 'joint'">
            For {{ secondarySalutation }}, you would like to make the following
            gifts:
            <span>
              <ul>
                <li v-for="(gs, gii) in secondaryGifts" :key="gii">
                  {{ beneficiaryName(gs) }} {{ outputGiftDetails(gs) }}
                </li>
              </ul>
            </span>
          </p>
          <p v-if="jointGifts.length">
            To be gifted on "second death":
            <span>
              <ul>
                <li v-for="(gj, giii) in jointGifts" :key="giii">
                  {{ beneficiaryName(gj) }} {{ outputGiftDetails(gj) }}
                </li>
              </ul>
            </span>
          </p>
        </div>
        <h3>Exclusions</h3>
        <div
          v-if="override.includes('advExclusionNotes')"
          v-html="advExclusionNotes"
        ></div>
        <div v-else>
          <p v-if="type === 'single'">
            <span v-if="primaryExclusions.length === 0">
              You do not need to exclude anyone from your estate.
            </span>
            <span v-if="primaryExclusions.length > 0">
              You would like to exclude the following from your estate:
              <ul>
                <li v-for="(e, ei) in primaryExclusions" :key="ei">
                  {{ e.who }}
                </li>
              </ul>
            </span>
          </p>
          <p v-if="type === 'joint'">
            <span v-if="exclusions.length === 0">
              Neither of you need to exclude anyone from your estate.
            </span>
            <span v-if="primaryExclusions.length > 0">
              {{ primarySalutation }} would like to exclude the following from
              their estate:
              <ul>
                <li v-for="(e, ei) in primaryExclusions" :key="ei">
                  {{ e.who }}
                </li>
              </ul>
            </span>
            <span v-if="secondaryExclusions.length > 0">
              {{ secondarySalutation }} would like to exclude the following from
              their estate:
              <ul>
                <li v-for="(e, ei) in secondaryExclusions" :key="ei">
                  {{ e.who }}
                </li>
              </ul>
            </span>
          </p>
        </div>
        <h2>Your trusted people</h2>
        <h3>Executors</h3>
        <div
          v-if="override.includes('advExecutorNotes')"
          v-html="advExecutorNotes"
        ></div>
        <div v-else>
          <p>
            <span>{{ forYou() }}</span> named
            <span v-if="primaryMainExecutors.length === 0">[NO EXECUTORS]</span>
            <span
              >{{ listOfNames(primaryMainExecutors) }} as your executor<span
                v-if="primaryMainExecutors.length > 1"
                >s</span
              ></span
            ><span v-if="primaryReserveExecutors.length > 0">
              with {{ listOfNames(primaryReserveExecutors) }} in a reserve
              capacity</span
            >.
          </p>
          <p v-if="type === 'joint'">
            For {{ secondarySalutation }} you named
            <span v-if="secondaryMainExecutors.length === 0"
              >[NO EXECUTORS]</span
            >
            <span
              >{{ listOfNames(secondaryMainExecutors) }} as your executor<span
                v-if="secondaryMainExecutors.length > 1"
                >s</span
              ></span
            ><span v-if="secondaryReserveExecutors.length > 0">
              with {{ listOfNames(secondaryReserveExecutors) }} in a reserve
              capacity</span
            >.
          </p>
          <p v-if="!checkIsNo('4.6.3b')">
            Your executors will also have the option (but not obligation) to
            appoint a professional to handle the legal administration side,
            based on the situation at the time.
          </p>
        </div>
        <div v-if="checkIsYes('2.5.0a') && checkIsYes('2.5.0d')">
          <h3>Guardians</h3>
          <div
            v-if="override.includes('advGuardianNotes')"
            v-html="advGuardianNotes"
          ></div>
          <div v-else>
            <p v-if="mainGuardians.length">
              <span v-if="guardiansAreSameForAllChildren">
                Your choice of guardian<span v-if="mainGuardians.length > 1"
                  >s</span
                >
                for your children (should they still be under 18)
                <span v-if="mainGuardians.length > 1"> are </span
                ><span v-else> is </span
                ><span>{{ listOfNames(mainGuardians) }}</span
                ><span v-if="reserveGuardians.length > 0"
                  >, with {{ listOfNames(reserveGuardians) }} as potential
                  reserve Guardian<span v-if="reserveGuardians.length > 1"
                    >s</span
                  ></span
                >.
              </span>
              <span v-if="!guardiansAreSameForAllChildren">
                You have named the following guardians:<br />
                <span v-for="(cfg, ccfg) in childrenWithGuardians" :key="ccfg">
                  <strong>{{ cfg.person.profile.fullName }}</strong>
                  <ul>
                    <li
                      v-for="(cfgg, cfggg) in cfg.main"
                      :key="'g' + ccfg + cfggg"
                    >
                      {{ cfgg.person.profile.fullName }}<br />
                    </li>
                    <li
                      v-for="(cfgr, cfgrr) in cfg.reserve"
                      :key="'gr' + ccfg + cfgrr"
                    >
                      {{ cfgr.person.profile.fullName }} (Reserve)<br />
                    </li>
                  </ul>
                </span>
              </span>
            </p>
            <p v-if="!mainGuardians.length">
              You have not appointed any guardians.
            </p>
          </div>
        </div>
        <h3>Trustees</h3>
        <div
          v-if="override.includes('advTrusteenNotes')"
          v-html="advTrusteenNotes"
        ></div>
        <div v-else>
          <p v-if="primaryTrustees.length">
            <span>{{ forYou() }}</span> named
            <span>{{ listOfNames(primaryTrustees) }}</span
            ><span v-if="primaryReserveTrustees.length > 0"
              >, and {{ listOfNames(primaryReserveTrustees) }} in reserve</span
            >.
          </p>
          <p v-if="type === 'joint' && secondaryTrustees.length">
            <span>For {{ secondarySalutation }} you named </span>
            <span>{{ listOfNames(secondaryTrustees) }}</span
            ><span v-if="secondaryReserveTrustees.length > 0"
              >, and {{ listOfNames(secondaryReserveTrustees) }} in
              reserve</span
            >.
          </p>
          <p v-if="!primaryTrustees.length && !secondaryTrustees.length">
            You have not appointed any trustees.
          </p>
          <p v-if="!checkIsNo('4.4.2')">
            Your trustees will also have the option (but not obligation) to
            appoint a professional to handle the legal administration side,
            based on the situation at the time.
          </p>
        </div>
        <h3>Attorneys</h3>
        <div
          v-if="override.includes('advAttorneyenNotes')"
          v-html="advAttorneyenNotes"
        ></div>
        <div v-else>
          <p v-if="primaryAttorneys.length">
            <span>{{ forYou() }}</span> named
            <span>{{ listOfNames(primaryAttorneys) }}</span
            ><span v-if="primaryReserveAttorneys.length > 0"
              >, and {{ listOfNames(primaryReserveAttorneys) }} in reserve</span
            >.
            <span v-if="primaryCertProv.length">
              For Certificate Provider, you named
              {{ listOfNames(primaryCertProv) }}. </span
            ><span v-else>You did not name a Certificate Provider.</span>
          </p>
          <p v-if="type === 'joint' && secondaryAttorneys.length">
            <span>For {{ secondarySalutation }} you named </span>
            <span>{{ listOfNames(secondaryAttorneys) }}</span
            ><span v-if="secondaryReserveAttorneys.length > 0"
              >, and {{ listOfNames(secondaryReserveAttorneys) }} in
              reserve</span
            >.
            <span v-if="secondaryCertProv.length">
              For Certificate Provider, you named
              {{ listOfNames(secondaryCertProv) }}. </span
            ><span v-else>You did not name a Certificate Provider.</span>
          </p>
          <p v-if="!primaryAttorneys.length && !secondaryAttorneys.length">
            You have not appointed any attorneys.
          </p>
        </div>
        <h3>Financial Advisers</h3>
        <div v-if="override.includes('advIfaNotes')" v-html="advIfaNotes"></div>
        <div v-else>
          <p v-if="checkIsYes('3.0.3b')">
            <!-- has checked yes have a financial adviser, but not actively specified who-->
            <span v-if="primaryfinancialAdvisers.length === 0">
              <span>{{ forYou() }}</span> have a financial adviser.
            </span>
            <span v-if="primaryfinancialAdvisers.length > 0">
              <span>{{ forYou() }}</span> mentioned your financial adviser
              <span v-if="primaryfinancialAdvisers.length > 1">are </span
              ><span v-else>is </span>
              <span>{{ listOfNames(primaryfinancialAdvisers) }}</span
              >.
            </span>
            <span
              v-if="type === 'joint' && secondaryfinancialAdvisers.length === 0"
            >
              {{ secondarySalutation }} doesn't have a financial adviser.
            </span>
            <span
              v-if="type === 'joint' && secondaryfinancialAdvisers.length > 0"
            >
              <br />
              For {{ secondarySalutation }} you mentioned your financial adviser
              <span v-if="secondaryfinancialAdvisers.length > 1">are </span
              ><span v-else>is </span>
              <span
                v-if="
                  listOfNames(primaryfinancialAdvisers) ===
                  listOfNames(secondaryfinancialAdvisers)
                "
              >
                also </span
              ><span>{{ listOfNames(secondaryfinancialAdvisers) }}</span
              >.
            </span>
          </p>
          <p v-else>You do not have a financial adviser.</p>
        </div>
        <h3>Accountants</h3>
        <p>
          <span v-if="checkIsYes('3.0.3d')">You have</span
          ><span v-else>You do not have</span> an accountant.
        </p>
      </div>
    </div>
    <div
      class="help-content box column"
      v-if="isAdmin"
      style="height: fit-content"
    >
      <h3>Section overrides</h3>
      <CheckboxField
        label="Business"
        :value="override.includes('advBusinessNotes')"
        @update:value="(value) => toggle('advBusinessNotes', value)"
      />
      <CheckboxField
        label="Beneficiaries"
        :value="override.includes('advBeneficiaryNotes')"
        @update:value="(value) => toggle('advBeneficiaryNotes', value)"
      />
      <CheckboxField
        label="Gift"
        :value="override.includes('advGiftNotes')"
        @update:value="(value) => toggle('advGiftNotes', value)"
      />
      <CheckboxField
        label="Exclusions"
        :value="override.includes('advExclusionNotes')"
        @update:value="(value) => toggle('advExclusionNotes', value)"
      />
      <CheckboxField
        label="Executors"
        :value="override.includes('advExecutorNotes')"
        @update:value="(value) => toggle('advExecutorNotes', value)"
      />
      <CheckboxField
        label="Guardians"
        :value="override.includes('advGuardianNotes')"
        @update:value="(value) => toggle('advGuardianNotes', value)"
      />
      <CheckboxField
        label="Trustees"
        :value="override.includes('advTrusteenNotes')"
        @update:value="(value) => toggle('advTrusteenNotes', value)"
      />
      <CheckboxField
        label="Attornies"
        :value="override.includes('advAttorneyenNotes')"
        @update:value="(value) => toggle('advAttorneyenNotes', value)"
      />
      <CheckboxField
        label="IFA"
        :value="override.includes('advIfaNotes')"
        @update:value="(value) => toggle('advIfaNotes', value)"
      />

      <RichTextField
        v-if="override.includes('advBusinessNotes')"
        label="Business Notes"
        v-model:value="advBusinessNotes"
      />
      <RichTextField
        v-if="override.includes('advBeneficiaryNotes')"
        label="Beneficiary Notes"
        v-model:value="advBeneficiaryNotes"
      />
      <RichTextField
        v-if="override.includes('advGiftNotes')"
        label="Gift Notes"
        v-model:value="advGiftNotes"
      />
      <RichTextField
        v-if="override.includes('advExclusionNotes')"
        label="Exclusion Notes"
        v-model:value="advExclusionNotes"
      />
      <RichTextField
        v-if="override.includes('advExecutorNotes')"
        label="Executor Notes"
        v-model:value="advExecutorNotes"
      />
      <RichTextField
        v-if="override.includes('advGuardianNotes')"
        label="Guardian Notes"
        v-model:value="advGuardianNotes"
      />
      <RichTextField
        v-if="override.includes('advTrusteenNotes')"
        label="Trusteen Notes"
        v-model:value="advTrusteenNotes"
      />
      <RichTextField
        v-if="override.includes('advAttorneyenNotes')"
        label="Attornies Notes"
        v-model:value="advAttorneyenNotes"
      />
      <RichTextField
        v-if="override.includes('advIfaNotes')"
        label="IFA Notes"
        v-model:value="advIfaNotes"
      />

      <Button v-if="override.length > 0" @click="saveAdvancedNotes">
        Save
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import Button from "@kinherit/framework/component.input/button";
import CheckboxField from "@kinherit/framework/component.input/checkbox-field";
import RichTextField from "@kinherit/framework/component.input/rich-text-field";
import { CallScript } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { ClientSummaryMixin } from "../../../mixin/client-summary.mixin";

export default defineComponent({
  name: "ClientSummary.Page1",
  mixins: [ClientSummaryMixin],
  components: { CheckboxField, RichTextField, Button },
  data: () => ({
    renderKey: 0,
    override: [] as (
      | "advBusinessNotes"
      | "advBeneficiaryNotes"
      | "advGiftNotes"
      | "advExclusionNotes"
      | "advExecutorNotes"
      | "advGuardianNotes"
      | "advTrusteenNotes"
      | "advAttorneyenNotes"
      | "advIfaNotes"
    )[],
    notes: {
      advBusinessNotes: null as null | string,
      advBeneficiaryNotes: null as null | string,
      advGiftNotes: null as null | string,
      advExclusionNotes: null as null | string,
      advExecutorNotes: null as null | string,
      advGuardianNotes: null as null | string,
      advTrusteenNotes: null as null | string,
      advAttorneyenNotes: null as null | string,
      advIfaNotes: null as null | string,
    },
  }),
  computed: {
    advBusinessNotes: {
      get(): null | string {
        return this.notes.advBusinessNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advBusinessNotes = val;
        this.notes.advBusinessNotes = val;
      },
    },
    advBeneficiaryNotes: {
      get(): null | string {
        return this.notes.advBeneficiaryNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advBeneficiaryNotes = val;
        this.notes.advBeneficiaryNotes = val;
      },
    },
    advGiftNotes: {
      get(): null | string {
        return this.notes.advGiftNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advGiftNotes = val;
        this.notes.advGiftNotes = val;
      },
    },
    advExclusionNotes: {
      get(): null | string {
        return this.notes.advExclusionNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advExclusionNotes = val;
        this.notes.advExclusionNotes = val;
      },
    },
    advExecutorNotes: {
      get(): null | string {
        return this.notes.advExecutorNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advExecutorNotes = val;
        this.notes.advExecutorNotes = val;
      },
    },
    advGuardianNotes: {
      get(): null | string {
        return this.notes.advGuardianNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advGuardianNotes = val;
        this.notes.advGuardianNotes = val;
      },
    },
    advTrusteenNotes: {
      get(): null | string {
        return this.notes.advTrusteenNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advTrusteenNotes = val;
        this.notes.advTrusteenNotes = val;
      },
    },
    advAttorneyenNotes: {
      get(): null | string {
        return this.notes.advAttorneyenNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advAttorneyenNotes = val;
        this.notes.advAttorneyenNotes = val;
      },
    },
    advIfaNotes: {
      get(): null | string {
        return this.notes.advIfaNotes;
      },
      set(val: null | string) {
        if (!this.kintin) {
          return null;
        }

        const callScript = CallScript.$findOneByOrThrow({
          id: this.kintin.callScript.id,
        });

        callScript.advIfaNotes = val;
        this.notes.advIfaNotes = val;
      },
    },
  },
  mounted(): void {
    const kintin = this.kintin;

    if (!kintin) {
      return;
    }

    const callScript = kintin.callScript;

    this.notes.advBusinessNotes = callScript.advBusinessNotes;
    this.notes.advBeneficiaryNotes = callScript.advBeneficiaryNotes;
    this.notes.advGiftNotes = callScript.advGiftNotes;
    this.notes.advExclusionNotes = callScript.advExclusionNotes;
    this.notes.advExecutorNotes = callScript.advExecutorNotes;
    this.notes.advGuardianNotes = callScript.advGuardianNotes;
    this.notes.advTrusteenNotes = callScript.advTrusteenNotes;
    this.notes.advAttorneyenNotes = callScript.advAttorneyenNotes;
    this.notes.advIfaNotes = callScript.advIfaNotes;
  },
  methods: {
    toggle(
      option:
        | "advBusinessNotes"
        | "advBeneficiaryNotes"
        | "advGiftNotes"
        | "advExclusionNotes"
        | "advExecutorNotes"
        | "advGuardianNotes"
        | "advTrusteenNotes"
        | "advAttorneyenNotes"
        | "advIfaNotes",
      value: boolean,
    ) {
      if (value && !this.override.includes(option)) {
        this.override.push(option);
        this.renderKey++;
        return;
      }

      this.override = this.override.remove(option);
      this.renderKey++;
    },
  },
});
</script>
