import { Api } from "@/service/api.service";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface UpdateIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export interface UpdateIntroducerFeePaymentRunResponse {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export const UpdateIntroducerFeePaymentRunHandler = async (
  message: UpdateIntroducerFeePaymentRunMessage,
): Promise<UpdateIntroducerFeePaymentRunResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}",
    message,
  )
    .method("patch")
    .body({
      completedAt: message.introducerFeePaymentRun.$data.completedAt,
      name: message.introducerFeePaymentRun.$data.name,
      status: message.introducerFeePaymentRun.$data.status,
      feeTypeId: message.introducerFeePaymentRun.$data.feeTypeId,
    })
    .result();

  const introducerFeePaymentRun = IntroducerFeePaymentRun.$inflate(
    repsonse.introducerFeePaymentRun,
  ).first();

  if (!introducerFeePaymentRun) {
    throw new Error("Failed to Update introducerfeepaymentrun");
  }

  return {
    introducerFeePaymentRun: introducerFeePaymentRun,
  };
};
