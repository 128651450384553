import { Api } from "@/service/api.service";
import { IntroducerBillingContact, IntroducerCompany } from "@kinherit/sdk";

export interface UpdateIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  introducerBillingContact: IntroducerBillingContact;
}

export interface UpdateIntroducerBillingContactResponse {
  introducerBillingContacts: Array<IntroducerBillingContact>;
}

export const UpdateIntroducerBillingContactHandler = async (
  message: UpdateIntroducerBillingContactMessage,
): Promise<UpdateIntroducerBillingContactResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts/{introducerBillingContact}",
    {
      introducerCompany: message.introducerCompany,
      introducerBillingContact: message.introducerBillingContact,
    },
  )
    .method("patch")

    .body({
      agentRemittanceType:
        message.introducerBillingContact.$data.agentRemittanceType,
      company: message.introducerBillingContact.$data.company,
      introducerRemittanceType:
        message.introducerBillingContact.$data.introducerRemittanceType,
      notes: message.introducerBillingContact.$data.notes,
      isGlobal: message.introducerBillingContact.$data.isGlobal,
    });

  const response = await request.result();

  return {
    introducerBillingContacts: IntroducerBillingContact.$inflate(
      response.introducerBillingContact,
      undefined,
      response.$rootIds,
    ),
  };
};
