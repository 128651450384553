import { Api } from "@/service/api.service";
import { Address, Kintin, Person, User } from "@kinherit/sdk";

type DownloadOfficerLetterMessage = {
  kintin: Kintin;
  officer: Person;
  address: Address;
  specialist: User;
};

interface DownloadOfficerLetterResponse {
  [key: string]: never;
}

export const DownloadOfficerLetterHandler = async (
  message: DownloadOfficerLetterMessage,
): Promise<DownloadOfficerLetterResponse> => {
  const { kintin, officer, address, specialist } = message;

  await Api.resource("portal", "/v2/portal/officer-letter/download")
    .method("get")
    .params({
      kintin: kintin.id,
      officer: officer.id,
      address: address.id,
      specialist: specialist.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${kintin.ref} Officer Letter - ${
        officer.profile.knownAs ?? officer.profile.fullName
      }`,
    });

  return {};
};
