import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { CpdDetailsParams } from "..";
import { CpdDetailsRegistrationsBreadCrumb } from "../../../breadcrumbs";

/** Registrations */
export const CpdDetailsRegistrationsRoute = "CpdDetailsRegistrations";
const CpdDetailsRegistrationsURI = "/cpd/:cpdSession/registrations";
export type CpdDetailsRegistrationsParams = RouteParamObject<
  typeof CpdDetailsRegistrationsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: CpdDetailsRegistrationsRoute,
    path: CpdDetailsRegistrationsURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsRegistrations" */ "./CpdDetails.Registrations.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        CpdDetailsRegistrationsBreadCrumb(params as CpdDetailsParams),
    },
  },
];
