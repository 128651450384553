import { Api } from "@/service/api.service";
import {
  EmailAddress,
  IntroducerContact,
  PhoneNumber,
  Profile,
} from "@kinherit/sdk";

interface CreateIntroducerContactMessage {
  introducerContact: IntroducerContact;
}

interface CreateIntroducerContactResponse {
  introducerContact: IntroducerContact;
  profiles: Array<Profile>;
  emailAddresses: Array<EmailAddress>;
  phoneNumbers: Array<PhoneNumber>;
}

export const CreateIntroducerContactHandler = async (
  message: CreateIntroducerContactMessage,
): Promise<CreateIntroducerContactResponse> => {
  const response = await Api.resource("portal", "/v2/portal/introducer-contact")
    .method("post")
    .body({
      companies: message.introducerContact.companies.pluck("id"),
      newsLetter: null,
      status: message.introducerContact.$data.status,
      callStatus: message.introducerContact.$data.callStatus,
      profile: {
        jobTitle: message.introducerContact.profile.$data.jobTitle,
        firstName: message.introducerContact.profile.$data.firstName,
        lastName: message.introducerContact.profile.$data.lastName,
        emails: message.introducerContact.profile.emails.map((email) => ({
          email: email.$data.email,
        })),
        phoneNumbers: message.introducerContact.profile.phoneNumbers.map(
          (phoneNumber) => ({
            tel: phoneNumber.$data.tel,
          }),
        ),
      },
    })

    .result();

  const introducerContact = IntroducerContact.$inflate(
    response.introducerContact,
  ).first();

  if (!introducerContact) {
    throw new Error("Failed to create user");
  }

  message.introducerContact.$delete({
    profile: {
      addresses: true,
      emails: true,
      phoneNumbers: true,
      websites: true,
    },
  });

  return {
    introducerContact,
    profiles: Profile.$inflate(response.profile),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
  };
};
