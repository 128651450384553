import { Api } from "@/service/api.service";
import { Kintin, KintinAccess } from "@kinherit/sdk";

interface UpdateKintinAccessMessage {
  kintin: string | Kintin;
  kintinAccess: KintinAccess;
}

interface UpdateKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export const UpdateKintinAccessHandler = async (
  message: UpdateKintinAccessMessage,
): Promise<UpdateKintinAccessResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/kintin-user-access/{kintin}/assigned-users/{kintinAccess}",
    {
      kintin: message.kintin,
      kintinAccess: message.kintinAccess,
    },
  )
    .method("patch")
    .body({
      accessType: message.kintinAccess.$data.accessType,
      allowAccess: message.kintinAccess.$data.allowAccess,
      kintin: message.kintinAccess.$data.kintin,
      user: message.kintinAccess.$data.user,
    })

    .result();

  const kintinAccess = KintinAccess.$inflate(response.kintinAccess).first();

  if (!kintinAccess) {
    throw new Error("Failed to update kintin check");
  }

  return {
    kintinAccess,
  };
};
