import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { Appointment } from "@kinherit/sdk";

export const AppointmentMasterListRoute = "AppointmentMasterList";

export const AppointmentDetailsRoute = "AppointmentDetails";
export const AppointmentDetailsURI = "/appointment/:id";
export type AppointmentDetailsParams = RouteParamObject<
  typeof AppointmentDetailsURI
>;

const AppointmentMasterListBreadCrumb = {
  text: "Appointments",
  route: { name: AppointmentMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  {
    name: AppointmentMasterListRoute,
    path: "/appointments",
    component: () =>
      import(
        /* webpackChunkName: "AppointmentMasterList" */ "./AppointmentMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [AppointmentMasterListBreadCrumb],
    },
  },
  {
    name: AppointmentDetailsRoute,
    path: AppointmentDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "AppointmentDetails" */ "./AppointmentDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => {
        const appointment = Appointment.$findOne(params.id);

        const name =
          appointment?.profile?.fullName ??
          appointment?.kintin?.friendlyName ??
          "Unknown";
        return [
          AppointmentMasterListBreadCrumb,
          {
            text: `${name} - ${
              appointment?.appointmentAt.formatDateTime ?? ""
            }`,
            route: { name: AppointmentDetailsRoute, params },
          },
        ];
      },
    },
  },
];
