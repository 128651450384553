import {
  AccountReferralCodeField,
  BrandedKinvaultField,
  IntroducerContactField,
} from "@/config/form.config";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const CreateBrandedKinvaultInvitationForm = () => {
  return defineForm({
    name: "create-branded-kinvault-invitation-form",
    data: () => ({
      firstName: "",
      lastName: "",
      emailAddress: "",
      reference: null as null | string,
      referralCode: null as null | string,
      adviser: null as null | string,
      brandedKinvaultOverride: null as null | string,
      sendInvitationEmail: null as null | boolean,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "referral",
        data,
        template: GridLayout([
          ["firstName", "lastName"],
          ["emailAddress", "reference"],
          ["referralCode", "adviser"],
          { is: "hr" },
        ]),
        components: () => ({
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
              },
              models: {
                value: "firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
              },
              models: {
                value: "lastName",
              },
            }),
          ],
          emailAddress: [
            FormEmailField({
              props: {
                label: "Email Address",
                validators: ["required"],
              },
              models: {
                value: "emailAddress",
              },
            }),
          ],
          reference: [
            FormTextField({
              props: {
                label: "Reference",
                message: "External reference",
              },
              models: {
                value: "reference",
              },
            }),
          ],
          referralCode: [
            AccountReferralCodeField({
              props: {
                label: "Referral Code",
                vModel: "referralCode",
                validators: ["required"],
              },
              simplifyData: true,
            }),
          ],
          adviser: [
            IntroducerContactField({
              props: {
                label: "Adviser",
                message: "The introducer/adviser",
                vModel: "adviser",
              },
              simplifyData: true,
            }),
          ],
        }),
      }),
      defineFormArea({
        name: "invite",
        data,
        template: GridLayout([
          ["brandedKinvaultOverride"],
          ["sendInvitationEmail"],
        ]),
        components: () => ({
          brandedKinvaultOverride: [
            BrandedKinvaultField({
              props: {
                label: "Brand Kinvault Override",
                vModel: "brandedKinvaultOverride",
                message:
                  "This should match the branded kinvault you wish to send an invite for",
              },
              simplifyData: true,
            }),
          ],
          sendInvitationEmail: [
            FormRadioField({
              props: {
                label: "Send Invitation Email",
                validators: ["required"],
                options: ["No", "Yes"],
                direction: "is-vertical",
                class: "mt-4",
                reference: "sendInvitationEmail",
              },
              models: {
                value: {
                  get: (data) => data.sendInvitationEmail,
                  set: (value, data) => (data.sendInvitationEmail = value),
                },
              },
              slots: {
                label: [
                  FormElement({
                    props: {
                      html: (data, controls) => {
                        if (controls.templateScope.option === "Yes") {
                          return `
                                <b>Yes, send the invitation email</b></br>
                                Suitable if you wish us to email the user with an invitation link
                            `;
                        }

                        return `
                                <b>No, I'll do this myself</b></br>
                                Suitable if you wish to email generated invitation links via your own mail system
                            `;
                      },
                    },
                  }),
                ],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
