import { PersonField } from "@/config/form.config";
import { ReadPersonResponse } from "@/module/core/action/select/read-person.action";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, NotIn } from "@kinherit/orm/index";
import { Guardian, Kintin } from "@kinherit/sdk";
import { AddKintinPersonButton } from "./shared.form";

export const UpdateGuardianForm = async ({
  data,
  kintin,
}: {
  data: Guardian;
  kintin: Kintin;
}) => {
  // const childrenAndDependents = await window.Kernel.ActionBus.execute(
  //   "core/select/person/read",
  //   {
  //     query: [
  //       {
  //         kintin: {
  //           id: Equal("string" === typeof kintin ? kintin : kintin.id),
  //         },
  //         under18ForPlanningPurposes: Equal(true),
  //       },
  //       {
  //         kintin: {
  //           id: Equal("string" === typeof kintin ? kintin : kintin.id),
  //         },
  //         requiresCare: Equal(true),
  //       },
  //     ],
  //   },
  // );

  let childrenAndDependents: ReadPersonResponse = {
    people: [],
    profiles: [],
  };

  return defineForm({
    name: "update-guardian-form",
    data: () => data,
    beforeMount: async () => {
      childrenAndDependents =
        await window.Kernel.ActionBus.core.select.person.read({
          query: [
            {
              kintin: {
                id: Equal("string" === typeof kintin ? kintin : kintin.id),
              },
              under18ForPlanningPurposes: Equal(true),
            },
            {
              kintin: {
                id: Equal("string" === typeof kintin ? kintin : kintin.id),
              },
              requiresCare: Equal(true),
            },
          ],
        });
    },
    formAreas: (data) => [
      defineFormArea({
        name: "guardian-form-area",
        data,
        template: GridLayout([
          ["person", "forPerson"],
          ["isReserve", "includePartner"],
          ["notes"],
        ]),
        components: () => ({
          person: [
            PersonField({
              props: {
                label: `Who is the guardian?`,
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: NotIn(childrenAndDependents.people.pluck("id")),
                kintin: {
                  id: Equal("string" === typeof kintin ? kintin : kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
          ],
          forPerson: [
            PersonField({
              props: {
                label: (data) =>
                  `Who is ${
                    data.person?.profile?.fullName ?? `this person`
                  } a guardian for?`,
                validators: ["required"],
                vModel: "forPerson",
              },
              query: [
                {
                  kintin: {
                    id: Equal("string" === typeof kintin ? kintin : kintin.id),
                  },
                  under18ForPlanningPurposes: Equal(true),
                },
                {
                  kintin: {
                    id: Equal("string" === typeof kintin ? kintin : kintin.id),
                  },
                  requiresCare: Equal(true),
                },
              ],
              slots: {
                right: [
                  AddKintinPersonButton({
                    kintin,
                    targetProperty: "forPerson",
                  }),
                ],
              },
            }),
          ],
          isReserve: [
            FormCheckboxField({
              props: {
                label: "Reserve",
              },
              models: {
                value: "isReserve",
              },
            }),
          ],
          includePartner: [
            FormCheckboxField({
              props: {
                label: "Include Partner",
              },
              models: {
                value: "includePartner",
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Additional Notes",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
