import { Api } from "@/service/api.service";
import { Tag } from "@kinherit/sdk";

interface RecordTagMessage {
  tag: string;
}

interface RecordTagResponse {
  tag: Tag;
}

export const RecordTagHandler = async (
  message: RecordTagMessage,
): Promise<RecordTagResponse> => {
  const response = await Api.resource("portal", "/v2/portal/tag/{tag}", {
    tag: message.tag,
  })
    .method("get")

    .result();

  const tag = Tag.$inflate(response.tag).first();

  if (!tag) {
    throw new Error("Tag not found");
  }

  return {
    tag,
  };
};
