import { Api } from "@/service/api.service";
import { BrandedKinvault } from "@kinherit/sdk";

export interface CreateBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault;
}

interface CreateBrandedKinvaultResponse {
  brandedKinvault: BrandedKinvault;
}

export const CreateBrandedKinvaultHandler = async (
  message: CreateBrandedKinvaultMessage,
): Promise<CreateBrandedKinvaultResponse> => {
  const response = await Api.resource("portal", "/v2/portal/branded-kinvault")
    .method("post")
    .body({
      profile: {
        organisationName: message.brandedKinvault.profile.organisationName,
      },
      kinvaultTheme: {
        footer: {},
        header: {},
        login: {},
        brand: message.brandedKinvault.kinvaultTheme.brand,
      },
      frontendUrl: message.brandedKinvault.$data.frontendUrl,
      clientPoolType: message.brandedKinvault.$data.clientPoolType,
      type: message.brandedKinvault.$data.type,
      systemEmail: message.brandedKinvault.$data.systemEmail,
      xeroContact: message.brandedKinvault.$data.xeroContact,
    })
    .result();

  const brandedKinvault = BrandedKinvault.$inflate(
    response.brandedKinvault,
  ).first();

  if (!brandedKinvault) {
    throw new Error("Failed to create branded kinvault");
  }

  message.brandedKinvault.$delete({
    kinvaultTheme: true,
    profile: {
      addresses: true,
      emails: true,
      phoneNumbers: true,
      websites: true,
    },
  });

  return {
    brandedKinvault,
  };
};
