import { Api } from "@/service/api.service";
import { Address, Kintin } from "@kinherit/sdk";

interface UpdateKintinAddressMessage {
  address: Address;
  kintin: Kintin;
}

interface UpdateKintinAddressResponse {
  address: Address;
}

export const UpdateKintinAddressHandler = async (
  message: UpdateKintinAddressMessage,
): Promise<UpdateKintinAddressResponse> => {
  const response = await Api.resource(
    "kinvault",
    "/v2/kinvault/{kintin}/address/{address}",
    {
      kintin: message.kintin,
      address: message.address,
    },
  )
    .method("patch")

    .body({
      line1: message.address.$data.line1,
      line2: message.address.$data.line2,
      city: message.address.$data.city,
      state: message.address.$data.state,
      postcode: message.address.$data.postcode,
      country: message.address.$data.country,
    })
    .result();

  const address = Address.$inflate(response.address).first();

  if (!address) {
    throw new Error(`Failed to update address`);
  }

  return { address };
};
