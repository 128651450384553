import { BrandedKinvaultField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { BrandedKinvaultMembership } from "@kinherit/sdk";

export const UserJoinBrandedKinvaultForm = async (
  data: Partial<BrandedKinvaultMembership>,
): Promise<
  FormBuilder<() => Partial<BrandedKinvaultMembership>, "default">
> => {
  const brandedKinvaultField = BrandedKinvaultField<any>({
    props: {
      showClearButton: false,
      vModel: "brandedKinvault",
      validators: ["required"],
    },
    query: {},
  });

  return defineForm({
    name: "user-join-branded-kinvault-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout(["brandedKinvault"]),
        components: () => ({
          brandedKinvault: [brandedKinvaultField],
        }),
      }),
    ],
  });
};
