<template>
  <div v-if="kintin">
    <CallScriptSection title="Intro">
      <b>
        OK, so let’s have a look at where you want your estate to go – in other
        words, who gets what
      </b>
    </CallScriptSection>
    <CallScriptSection title="Main Beneficiaries">
      <p v-if="checkIsYes('2.5.0b')">
        [Note]: Has children from prior relationship.
      </p>
      <p v-if="checkIsYes('3.0.0a')">[Note]: Owns own property</p>
      <p v-if="checkIsYes('3.0.0d')">
        [Note]: Someone else lives in the main property
      </p>
      <p v-if="checkIsNo('3.0.0d')">
        [Note]: No one else lives in the main property
      </p>
      <b>
        We’ll come to any specific gifts you might want to make later, but first
        can we talk about your main beneficiaries. Is that ok? </b
      ><br /><em>[Wait for Answer]</em> <br /><br />
      <b
        >So when you die, who would be the main beneficiary of your estate?
        <br /><br />
      </b>
      <b v-if="isJoint && checkIsYes('2.5.0b')">
        You mentioned you have children from a prior relationship, and I am
        guessing you have thought about how it could work. Is it some
        combination of partner-and-kids?
      </b>
      <span v-if="isJoint && checkIsNo('2.5.0b')">
        <b>
          Most couples choose partner first then any Children, but everyone
          needs to make their own choice. How do you see it?
        </b>
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1i"
        is-reversed
        :yesLabel="isJoint ? 'Partner then Children' : 'Yes'"
        :noLabel="isJoint ? 'Other' : 'No'"
        :on="{
          yes: {
            action: [
              'Enter Details: Take particular care where kids from prior relationship, to set 1st/second line etc.',
            ],
          },
        }"
      />
      <span v-if="isJoint && checkIsNo('4.3.1i')">
        <b>
          Ok, so, {{ primarySalutation }} if you pass away, what happens to your
          assets? <br /><em>[Add Person 1 beneficiary]</em>
        </b>
        <br /><br />
        <b>
          And {{ secondarySalutation }} if you pass away, what happens to your
          assets? <br /><em>[Add Person 2 Beneficiary]</em>
        </b>
        <br /><br />
        <b> And finally, when you both pass away. What happens? </b>
        <br /><em>[Add second line beneficiary]</em>
      </span>
      <span v-if="!isJoint">
        <b>
          So when you pass away, who would be the main beneficiary of your
          estate?
        </b>
        <br /><em>[Add beneficiary]</em>
      </span>
      <BeneficiariesContainer :kintin="kintin" />
      <br />
      <span>
        <em>
          [Specialist reconfirm the choices here to make sure we have them right
          and then ask]
        </em>
        <br />
        <b>
          And if any beneficiaries pass away before you do, have you thought
          about who it would go to then? Would you want their share to then be
          given to their children? That’s usually what happens, but it could
          revert to surviving beneficiaries if you wanted it to.
        </b>
        <br />
        <br /><em>[Add reserve Beneficiary]</em> <br />
      </span>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1"
      />
    </CallScriptSection>
    <CallScriptSection title="Inheritance before 18-25">
      <em>[If any beneficiaries could be under 25 at time of receipt]</em><br />
      <b>
        Legally, people cannot inherit until they turn 18, so any assets would
        be held in trust for them until this age.
        <br /><br />
        Now I suppose the question is … does 18 years old sound too young to be
        inheriting a load of money?
        <br />
        <br />
      </b>
      <em>
        [Put a bit of fun in here – something like – I know that If I’d
        inherited everything from my parents are age 21 I might not have spent
        it too wisely.]
      </em>
      <b>
        <br /><br />
        So what do you think?
        <br /><br />You can increase the age up to 25 years old (or higher with
        an Estate Protection trust) Would you consider choosing this?
      </b>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1e"
        is-reversed
      />
      <NumberField
        v-if="checkIsYes('4.3.1e')"
        label="Inheritance Age"
        :min="18"
        v-model:value="callScript.beneficiaryAge"
      />
      <br />
      <b>
        Money can be made available before the age of ……. For two main areas
        Education and Medical is that something you look like to allow.
      </b>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1f"
        is-reversed
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1g"
        is-reversed
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.1h"
        is-reversed
        :on="{
          yes: {
            say: [
              'Travel typically needs an Estate Protection trust, but I can put a flag down and we can come back to that later.',
            ],
            action: ['Add note so precise language can be checked later'],
          },
        }"
      />
      <br />
      <b>
        Thanks for that, we have taken care of the bulk of your estate and I
        will make sure that is clear to see in the docs I send over following
        this call.
      </b>
      <br /><br />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.3.2"
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advBeneficiaryNotes"
      :has-continue="false"
    />
  </div>
</template>

<script lang="ts">
import BeneficiariesContainer from "@/module/kinvault.kintin/component/address-book/BeneficiariesContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import NumberField from "@kinherit/framework/component.input/number-field";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BeneficiariesTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    BeneficiariesContainer,
    CallScriptSection,
    ConfirmCheck,
    CallScriptSpecialistNotesSection,
    NumberField,
  },
});
</script>
