import { DangerDialog } from "@/config/dialog.config";
import {
  WillBuilderOrderedSections,
  WillBuilderSections,
} from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderConfigForm } from "@/module/kinvault.kintin/form/will-builder/will-builder-config.form";
import { WillBuilderHelpersType } from "@/module/kinvault.kintin/service/will-builder.helpers";
import { WillBuilderNotices } from "@/module/kinvault.kintin/service/will-builder.notices";
import { AuthService } from "@/service/auth.service";
import { OpenTextDialog } from "@kinherit/framework/global/dialog";
import { Equal, In } from "@kinherit/orm/index";
import {
  Check,
  Kintin,
  KintinCheck,
  Note,
  Option,
  WillRevision,
} from "@kinherit/sdk/index";
import { DateTime, Uuid } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";

export class WillBuilderService extends WillBuilderNotices {
  static async init(
    {
      kintin,
      willRevision,
      type,
    }: {
      kintin: Kintin | null;
      willRevision: WillRevision | null;
      type: "primary" | "secondary";
    } = {
      kintin: this.options.kintin,
      willRevision: this.options.willRevision,
      type: this.options.type,
    },
  ): Promise<void> {
    if (willRevision) {
      // await window.Kernel.ActionBus.execute(
      //   "kinvault/kintin/will-revision/content/record",
      //   {
      //     willRevision: willRevision.id,
      //   },
      // );
      await window.Kernel.ActionBus.kinvaultKintin.willRevision.recordContent({
        willRevision: willRevision.id,
      });
    }

    const person =
      type === "primary" ? kintin?.primaryPerson : kintin?.secondaryPerson;
    const partner =
      type === "primary" ? kintin?.secondaryPerson : kintin?.primaryPerson;

    this.options.person = person ?? null;
    this.options.partner = partner ?? null;
    this.options.type = type;
    this.options.editing = false;
    this.options.selecting = false;

    if (
      this.options.kintin?.id !== kintin?.id ||
      this.options.willRevision?.id !== willRevision?.id
    ) {
      this.options.document = "will";
    }

    this.options.kintin = kintin;
    this.options.willRevision = willRevision;

    this.form.hasBusinessClause = willRevision?.hasBusinessClause ?? false;
    this.form.hasBusinessTrust = willRevision?.hasBusinessTrust ?? false;
    this.form.hasEstateProtectionTrust =
      willRevision?.hasEstateProtectionTrust ?? false;
    this.form.hasIipTrust = willRevision?.hasIipTrust ?? false;

    Object.keys(this.overrides).forEach((key) => {
      // @ts-ignore
      delete this.overrides[key];
    });

    Object.assign(this.overrides, willRevision?.content ?? {});

    if (!this.options.kintin || !this.options.person) {
      return;
    }

    this.refreshData();
    this.refreshEnabledSections();
    this.refreshWarnings();
  }

  static mixin = defineComponent({
    computed: {
      data() {
        return WillBuilderService.data!;
      },
      notices(): typeof WillBuilderService.notices {
        return WillBuilderService.notices;
      },
      options(): typeof WillBuilderService.options {
        return WillBuilderService.options;
      },
      form(): typeof WillBuilderService.form {
        return WillBuilderService.form;
      },
      sections(): typeof WillBuilderService.sections {
        return WillBuilderService.sections;
      },
      optionalSections(): typeof WillBuilderService.optionalSections {
        return WillBuilderService.optionalSections;
      },
      scheduleSections(): typeof WillBuilderService.optionalSections {
        return WillBuilderService.scheduleSections;
      },
      overrides: {
        get: () => WillBuilderService.overrides,
        set: (value: Partial<Record<WillBuilderSections, string>>) => {
          Object.assign(WillBuilderService.overrides, value);
        },
      },
      helpers(): WillBuilderHelpersType {
        return WillBuilderService.helpers;
      },
    },
    methods: {
      init: WillBuilderService.init.bind(WillBuilderService),
      refreshData: WillBuilderService.refreshData.bind(WillBuilderService),
      refreshWarnings:
        WillBuilderService.refreshWarnings.bind(WillBuilderService),
      refreshEnabledSections:
        WillBuilderService.refreshEnabledSections.bind(WillBuilderService),
      getSectionContent(
        section: WillBuilderSections,
        type?: "custom" | "generated",
      ): string {
        const container = document.querySelectorAll(
          `.section-container[section="${section}"] .section-content`,
        );

        if (container.length === 0) {
          return "";
        }

        if (type === "generated") {
          return container[0].innerHTML;
        }

        if (type === "custom") {
          return container[1].innerHTML;
        }

        return this.hasCustom(section)
          ? container[1].innerHTML
          : container[0].innerHTML;
      },
      hasCustom(section: WillBuilderSections): boolean {
        return !!this.overrides[
          section.toString() as never as WillBuilderSections
        ];
      },
      async downloadPdf(draft: boolean) {
        const willRevision = this.options.willRevision;

        const reference = this.options.kintin?.ref ?? "";

        const chattelGifts =
          this.options.kintin?.gifts.filter(
            (gift) => gift.type.value === "chattels",
          ) ?? [];

        const hasLetterOfWishes = chattelGifts.isNotEmpty();

        const mowBusinessContent = this.form?.hasBusinessTrust
          ? this.getSectionContent(WillBuilderSections.MowBusiness)
          : "";

        const mowIipContent = this.form?.hasIipTrust
          ? this.getSectionContent(WillBuilderSections.MowIIP)
          : "";

        const mowNrbContent =
          this.form?.hasEstateProtectionTrust && this.form?.hasIipTrust
            ? this.getSectionContent(WillBuilderSections.MowNRB)
            : "";

        const mowResContent =
          this.form?.hasEstateProtectionTrust && !this.form?.hasIipTrust
            ? this.getSectionContent(WillBuilderSections.MowRES)
            : "";

        const willContent = Object.values(WillBuilderOrderedSections)
          .filter((section) => {
            const isEnabled = this.sections.enabled![section];

            if (!isEnabled) {
              return false;
            }

            if (
              [
                WillBuilderSections.MowBusiness,
                WillBuilderSections.MowIIP,
                WillBuilderSections.MowNRB,
                WillBuilderSections.MowRES,
                WillBuilderSections.FrontPage,
              ].includes(section)
            ) {
              return false;
            }

            return true;
          })
          .map((section) => this.getSectionContent(section));

        const person =
          this.options.type === "primary"
            ? this.options.kintin?.primaryPerson
            : this.options.kintin?.secondaryPerson;

        if (!person) {
          throw new Error("Person is not defined");
        }

        // await window.Kernel.ActionBus.execute(
        //   "kinvault/kintin/will-revision/download",
        //   {
        //     willRevision,
        //     reference,
        //     person,
        //     willContent,
        //     hasLetterOfWishes,
        //     draft,
        //     mowBusinessContent,
        //     mowIipContent,
        //     mowNrbContent,
        //     mowResContent,
        //   },
        // );
        await window.Kernel.ActionBus.kinvaultKintin.willRevision.download({
          willRevision,
          reference,
          person,
          willContent,
          hasLetterOfWishes,
          draft,
          mowBusinessContent,
          mowIipContent,
          mowNrbContent,
          mowResContent,
        });
      },

      async save(): Promise<void> {
        if (!this.form) {
          throw new Error("Form is not defined");
        }

        const revision = this.options.willRevision;

        if (!revision) {
          return await this.create();
        }

        revision.notes = await this.getNotes();
        revision.content = this.overrides;
        revision.hasBusinessClause = this.form.hasBusinessClause;
        revision.hasBusinessTrust = this.form.hasBusinessTrust;
        revision.hasEstateProtectionTrust = this.form.hasEstateProtectionTrust;
        revision.hasIipTrust = this.form.hasIipTrust;

        const createdBy = AuthService.loggedInUser;

        if (!createdBy) {
          throw new Error("Current user not found");
        }

        revision.createdBy = createdBy;

        revision.approvedAt = null;
        revision.approvedBy = null;

        // const { willRevision } = await window.Kernel.ActionBus.execute(
        //   "kinvault/will-revision/create",
        //   {
        //     willRevision: revision,
        //   },
        // );
        const { willRevision } =
          await window.Kernel.ActionBus.kinvaultWillRevision.create({
            willRevision: revision,
          });

        this.init({
          kintin: this.options.kintin,
          type: this.options.type,
          willRevision: willRevision,
        });

        this.options.editing = false;
      },
      async approve(): Promise<void> {
        const revision = this.options.willRevision;

        if (!revision) {
          return;
        }

        const approvedBy = AuthService.loggedInUser;

        if (!approvedBy) {
          throw new Error("Current user not found");
        }

        await DangerDialog({
          dialog: {
            title: "Approve Will Revision",
            message: "Are you sure you want to approve this will revision?",
          },
        });

        revision.approvedAt = new DateTime();
        revision.approvedBy = AuthService.loggedInUser;

        // const { willRevision } = await window.Kernel.ActionBus.execute(
        //   "kinvault/will-revision/update",
        //   {
        //     willRevision: revision,
        //   },
        // );
        const { willRevision } =
          await window.Kernel.ActionBus.kinvaultWillRevision.update({
            willRevision: revision,
          });

        this.init({
          kintin: this.options.kintin,
          type: this.options.type,
          willRevision: willRevision,
        });

        this.options.editing = false;
      },
      async create(): Promise<void> {
        if (!this.form) {
          throw new Error("Form is not defined");
        }

        if (!this.options.kintin) {
          throw new Error("Kintin is not defined");
        }

        const createdBy = AuthService.loggedInUser;

        if (!createdBy) {
          throw new Error("Current user not found");
        }

        const revision = new WillRevision({
          id: Uuid.generate(),
          kintin: this.options.kintin.id,
          content: this.overrides,
          hasBusinessClause: this.form.hasBusinessClause,
          hasBusinessTrust: this.form.hasBusinessTrust,
          hasEstateProtectionTrust: this.form.hasEstateProtectionTrust,
          hasIipTrust: this.form.hasIipTrust,
          createdBy: createdBy.id,
          ownedBy: Option.$findOneByOrThrow({
            group: "ownedTypes",
            value: this.options.type,
          }).id,
          approvedAt: null,
          approvedBy: null,
          createdAt: new DateTime().formatMachine,
          notes: await this.getNotes(),
        });

        // const { willRevision } = await window.Kernel.ActionBus.execute(
        //   "kinvault/will-revision/create",
        //   {
        //     willRevision: revision,
        //   },
        // );
        const { willRevision } =
          await window.Kernel.ActionBus.kinvaultWillRevision.create({
            willRevision: revision,
          });

        this.init({
          kintin: this.options.kintin,
          type: this.options.type,
          willRevision: willRevision,
        });
        this.options.editing = false;
      },
      async getNotes(): Promise<string> {
        const notes = await OpenTextDialog({
          dialog: {
            title: "Notes",
            message: "Enter notes",
          },
          value: "",
          input: {},
        });

        return notes;
      },
      async editConfig() {
        const kintin = this.options.kintin;

        if (!kintin) {
          throw new Error("Kintin is not defined");
        }

        const checkValues = await WillBuilderConfigForm(kintin).dialog({
          dialog: {
            title: "Will Builder Config",
          },
        });

        const checkSteps = Object.keys(checkValues)
          .map((key) => key.replaceAll("_", "."))
          .filter((step) => step !== "configuredBeneficiaryAge");

        const kintinChecks = KintinCheck.$findBy({
          step: In(checkSteps),
          kintin: {
            id: Equal(kintin.id),
          },
        });

        const checks = Check.$findBy({
          step: In(checkSteps),
        });

        const callScript = kintin.callScript;
        callScript.beneficiaryAge = checkValues.configuredBeneficiaryAge;
        callScript.$persist();

        // await window.Kernel.ActionBus.execute(
        //   "kinvault/kintin/call-script/update",
        //   {
        //     kintin,
        //   },
        // );
        await window.Kernel.ActionBus.kinvaultKintin.callScript.update({
          kintin,
        });

        await Object.values(checkSteps).forEachAsync(async (key) => {
          const value =
            checkValues[key.replaceAll(".", "_") as keyof typeof checkValues];

          if (null === value) {
            return;
          }

          const step = key.replaceAll("_", ".");

          let kintinCheck = kintinChecks.find((check) => check.step === step);

          if (!kintinCheck) {
            const check = checks.find((check) => check.step === step);
            if (!check) {
              throw new Error(`Check ${step} is not defined`);
            }

            kintinCheck = new KintinCheck({
              id: Uuid.generate(),
              kintin: kintin.id,
              step,
              checkType: check.checkType,
              checkValue: value,
              createdAt: new DateTime().formatMachine,
              note: new Note({
                id: Uuid.generate(),
                kintin: kintin.id,
                name: check.name,
                notes: check.notes,
                createdAt: new DateTime().formatMachine,
                pinned: false,
                isPublic: false,
                type: "kintinCheck",
              }).$persist().id,
            }).$persist();

            // await window.Kernel.ActionBus.execute(
            //   "kinvault/kintin/kintin-check/create",
            //   {
            //     kintinCheck,
            //     kintin: kintin.id,
            //     replace: false,
            //   },
            // );
            await window.Kernel.ActionBus.kinvaultKintin.kintinCheck.create({
              kintinCheck,
              kintin: kintin.id,
              replace: false,
            });

            return;
          }

          kintinCheck.checkValue = value;
          kintinCheck.$persist();

          // await window.Kernel.ActionBus.execute(
          //   "kinvault/kintin/kintin-check/update",
          //   {
          //     kintinCheck,
          //     kintin: kintin.id,
          //   },
          // );
          await window.Kernel.ActionBus.kinvaultKintin.kintinCheck.update({
            kintinCheck,
            kintin: kintin.id,
          });
        });

        this.refreshData();
        this.refreshEnabledSections();
        this.refreshWarnings();
      },
    },
  });
}
