<template>
  <RouterChildView />
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { DashboardIndexRoute } from ".";

export default defineComponent({
  name: DashboardIndexRoute,
  components: { RouterChildView },
  mixins: [AuthService.mixin({ sessionRequired: true })],
});
</script>
