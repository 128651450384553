import { Api } from "@/service/api.service";
import { Kintin, KintinCheck, Note } from "@kinherit/sdk";

interface ReadKintinWillRevisionChecksMessage {
  kintin: Kintin | string;
}

interface ReadKintinWillRevisionChecksResponse {
  kintinChecks: KintinCheck[];
  notes: Note[];
}

export const ReadKintinWillRevisionChecksHandler = async (
  message: ReadKintinWillRevisionChecksMessage,
): Promise<ReadKintinWillRevisionChecksResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/kintin-will-revision/{kintin}/checks",
    {
      kintin: message.kintin,
    },
  )
    .method("get")

    .result();

  return {
    kintinChecks: KintinCheck.$inflate(response.kintinCheck),
    notes: Note.$inflate(response.note),
  };
};
