<template>
  <PageHeader htag="h2" text="Overview" />
  <div class="dashboard-overview">
    <Card>
      <Form
        v-if="$data.filters"
        :config="$data.filters"
        is-borderless
        @update:data="refresh"
        @click="refresh"
      />
    </Card>
    <Card v-if="computedUser" :key="renderKey">
      <p>
        <strong>{{ computedUser.profile?.fullName }}</strong>
        - {{ computedUser.isEstatePlanner ? "Estate Planner" : "" }}
        {{ computedUser.isLegalAssistant ? "Legal Assistant" : "" }}
        : {{ computedTimeFrame }}
      </p>
      <Tabs
        :is-fullwidth="false"
        :config="Object.values(computedTabs)"
        v-model:tab="subTabs.active"
      />
      <template v-if="subTabs.index.appointments === computedTab">
        <AppointmentMasterList
          v-if="['today', 'tomorrow'].includes(computedTimeFrame)"
          hide-title
          :show-filters="false"
          :specialist="[computedUser.id]"
          :date-range="computedTimeFrame === 'today' ? 'today' : 'tomorrow'"
          :persist-state="false"
        />
        <p v-if="!['today', 'tomorrow'].includes(computedTimeFrame)">
          Please select either today or tomorrow to view appointments.
        </p>
      </template>
      <template v-if="subTabs.index.leads === computedTab">
        <LeadMasterList
          hide-title
          :show-filters="false"
          :assigned-user="[computedUser.id]"
          :due-by="computedTimeFrame"
          :status="computedLeadStatuses"
          :persist-state="false"
        />
      </template>
      <template v-if="subTabs.index.accounts === computedTab">
        <KintinMasterList
          hide-title
          :show-filters="false"
          :assigned-user="[computedUser.id]"
          :due-by="computedTimeFrame"
          :status="computedKintinStatuses"
          :stage="computedKintinStages"
          :is-active="true"
          :persist-state="false"
        />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { DashboardIndexRoute } from ".";

import AppointmentMasterList from "@/module/appointment/page/AppointmentMasterList.vue";
import { ReadOverviewForm } from "@/module/dashboard/form/read-overview.form";
import KintinMasterList from "@/module/kinvault.kintin/page/KintinMasterList.vue";
import LeadMasterList from "@/module/lead/page/LeadMasterList.vue";
import Form from "@kinherit/framework/component.form/form";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { NotIn } from "@kinherit/orm";
import { Option } from "@kinherit/sdk";

export default defineComponent({
  name: DashboardIndexRoute,
  mixins: [AuthService.mixin({ sessionRequired: true })],
  components: {
    Tabs,
    Card,
    PageHeader,
    Form,
    LeadMasterList,
    KintinMasterList,
    AppointmentMasterList,
  },
  data: () => ({
    dueByLeadFilteredStatuses: [] as string[],
    kintinStatuses: [] as Option[],
    kintinFilteredStages: [] as Option[],
    kintinFilteredStagesLegalAssistant: [] as string[],
    kintinFilteredStagesEstatePlanner: [] as string[],
    renderKey: 0,
    filters: ReadOverviewForm(),
    subTabs: {
      active: 0,
      index: {
        appointments: 0,
        leads: 1,
        accounts: 2,
      },
    },
  }),
  mounted() {
    // We never want converted / QO
    this.dueByLeadFilteredStatuses = Option.$findBy({
      group: "leadStatus",
      value: NotIn([
        "converted",
        "duplicate",
        "enquiry",
        "deadlead",
        "qualifiedout",
        "willreview",
        "donotcall",
      ]),
    }).pluck("id");

    // We never want completed / QO
    this.kintinStatuses = Option.$findBy({
      group: "kintinStatus",
      value: NotIn(["closed", "complete", "adminlock"]),
    });

    // We never want completed / bereaved etc.
    this.kintinFilteredStages = Option.$findBy({
      group: "kintinStage",
      value: NotIn(["h", "z1", "z2"]),
    });

    // Legal Assistants should only see kintinStages of A,  E2-> G3
    this.kintinFilteredStagesLegalAssistant = this.kintinFilteredStages
      .filter((s) => ["a", "b", "e", "f", "g1", "g2", "g3"].includes(s.value))
      .pluck("id");

    // EPs should only see kintinStages of A, E1, E2, F, G1, G2
    this.kintinFilteredStagesEstatePlanner = this.kintinFilteredStages
      .filter((s) => ["a", "b", "c", "d", "e2"].includes(s.value))
      .pluck("id");
  },
  computed: {
    computedUser() {
      return this.$data.filters?.localData?.selected ?? this.$auth.loggedInUser;
    },
    computedTimeFrame() {
      return this.$data.filters?.localData?.timeFrame;
    },
    computedTab() {
      return this.$data.subTabs.active;
    },
    computedLeadStatuses() {
      return this.dueByLeadFilteredStatuses;
    },
    computedKintinStatuses() {
      return this.kintinStatuses.pluck("id");
      // return this.computedUser?.isLegalAssistant
      //   ? kintinFilteredStatusesLegalAssistant
      //   : this.computedUser?.isEstatePlanner
      //   ? kintinFilteredStatusesEstatePlanner
      //   : kintinStatuses.pluck("id");
    },
    computedKintinStages() {
      return this.computedUser?.isLegalAssistant
        ? this.kintinFilteredStagesLegalAssistant
        : this.computedUser?.isEstatePlanner
          ? this.kintinFilteredStagesEstatePlanner
          : this.kintinFilteredStages.pluck("id");
    },
    computedTabName(): string | undefined {
      // Find the key in subTabs.index that corresponds to the current value of subTabs.active
      const computedTabIndex: number = this.subTabs.active;
      const computedTabKey: keyof typeof this.subTabs.index | undefined =
        Object.keys(this.subTabs.index).find(
          (key) =>
            this.subTabs.index[key as keyof typeof this.subTabs.index] ===
            computedTabIndex,
        ) as keyof typeof this.subTabs.index | undefined;

      // Use the found key to get the label from computedTabs, ensuring the key is a valid type
      if (computedTabKey) {
        return this.computedTabs[computedTabKey]?.label;
      }
      return undefined;
    },
    computedTabs() {
      const config = {
        appointments: {
          label: "Appointments",
        },
        leads: {
          label: "Leads",
        },
        accounts: {
          label: "Accounts",
        },
      } as Record<string, TabOptions>;

      return config;
    },
  },
  methods: {
    refresh() {
      this.renderKey++;
    },
  },
});
</script>
