import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadCallLogHandler } from "./read-call-logs.action";

export const Actions = {
  "admin/call-log/read": ReadCallLogHandler,
};

export const AdminCallLogActionBus = DefineActionBus({
  name: "Admin.CallLog",
  actions: {
    read: ReadCallLogHandler,
  },
});
