import {
  IntroducerFeeMasterListBreadCrumb,
  IntroducerFeePaymentRunMasterListBreadCrumb,
} from "@/module/introducer.fees/breadcrumbs";
import { RouteInterface } from "@kinherit/framework/core/route-config";
import { Routes as IntroducerFeePaymentRunDetailRoutes } from "./details/index";

export const IntroducerFeePaymentRunMasterListRoute =
  "IntroducerFeePaymentRunMasterList";

export const IntroducerFeeMasterListRoute = "IntroducerFeeMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerFeePaymentRunMasterListRoute,
    path: "/introducer/fees/payment-run",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeePaymentRunMasterList" */ "./IntroducerFeePaymentRunMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerFeePaymentRunMasterListBreadCrumb],
    },
  },
  {
    name: IntroducerFeeMasterListRoute,
    path: "/introducer/fees",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeeMasterList" */ "./IntroducerFeeMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerFeeMasterListBreadCrumb],
    },
  },
  ...IntroducerFeePaymentRunDetailRoutes,
];
