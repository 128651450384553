import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadAccountReferralCodeForm = () => {
  return MasterListFiltersForm({
    name: "filter-account-referral-code-form",
    data: () => ({
      code: "",
      category: Array<string>(),
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      code: [
        FormTextField({
          props: {
            label: "Name/Code",
            placeholder: "Code",
            reference: "code",
          },
          models: {
            value: "code",
          },
        }),
      ],
      category: [
        OptionsAutoCompleteField({
          vModel: "category",
          group: "referralSources",
          props: {
            isMultiSelect: true,
            reference: "category",
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
            reference: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
