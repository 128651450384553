import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, Like } from "@kinherit/orm/index";
import {
  IIntroducerFeePaymentRun,
  IntroducerFeePaymentRun,
} from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentRunMessage {
  name?: string | null;
  createdAt?: [number, number] | null;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerFeePaymentRun;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerFeePaymentRunResponse {
  introducerFeePaymentRuns: Array<IntroducerFeePaymentRun>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerFeePaymentRunHandler = async (
  message: ReadIntroducerFeePaymentRunMessage,
): Promise<ReadIntroducerFeePaymentRunResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-run",
  )
    .method("get")
    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerFeePaymentRun).where({
    name: Like(message.name),
    createdAt: Between(message.createdAt),
  });

  const response = await request.result();

  return {
    introducerFeePaymentRuns: IntroducerFeePaymentRun.$inflate(
      response.introducerFeePaymentRun,
      message.sort,
      response.$rootIds,
    ),
    pagination: getPagination(response),
  };
};
