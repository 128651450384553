import { Api } from "@/service/api.service";
import {
  Address,
  CpdSession,
  CpdSessionRegistration,
  EmailAddress,
  IntroducerCompany,
  IntroducerContact,
  IntroducerCpd,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

interface RecordCpdSessionMessage {
  cpdSession: string | CpdSession;
}

interface RecordCpdSessionResponse {
  cpdSessions: Array<CpdSession>;
  addresses: Array<Address>;
  websites: Array<Website>;
  cpdSessionRegistrations: Array<CpdSessionRegistration>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  profiles: Array<Profile>;
  introducerContacts: Array<IntroducerContact>;
  introducerCompanies: Array<IntroducerCompany>;
  introducerCpds: Array<IntroducerCpd>;
}

export const RecordCpdSessionHandler = async (
  message: RecordCpdSessionMessage,
): Promise<RecordCpdSessionResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/cpd-session/{cpdSession}",
    {
      cpdSession:
        "string" === typeof message.cpdSession
          ? message.cpdSession
          : message.cpdSession.id,
    },
  )
    .method("get")

    .result();

  return {
    cpdSessions: CpdSession.$inflate(
      response.cpdSession,
      undefined,
      response.$rootIds,
    ),
    websites: Website.$inflate(response.website),
    addresses: Address.$inflate(response.address),
    cpdSessionRegistrations: CpdSessionRegistration.$inflate(
      response.cpdSessionRegistration,
    ),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    profiles: Profile.$inflate(response.profile),
    introducerContacts: IntroducerContact.$inflate(response.introducerContact),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    introducerCpds: IntroducerCpd.$inflate(response.introducerCpd),
  };
};
