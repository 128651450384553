import { AccountReferralCode } from "@kinherit/sdk";

export const AccountReferralCodeMasterListBreadCrumb = {
  text: "Account Referral Codes",
  route: { name: "AccountReferralCodeMasterList" },
};

export const AccountReferralCodeDetailsBreadCrumb = (params: any) => ({
  text: `Code: ${
    AccountReferralCode.$findOne(params.accountReferralCode)?.code ?? ""
  }`,
  route: { name: "AccountReferralDetails", params },
});
