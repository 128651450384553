import {
  AccountReferralCodeField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  TagsField,
  UserField,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { Equal, In, NotIn } from "@kinherit/orm/index";
import { Option } from "@kinherit/sdk";

// Overdue
export const ReadLeadForm = () => {
  const dueByLeadFilteredStatuses = Option.$findBy({
    group: "leadStatus",
    value: NotIn([
      "converted",
      "duplicate",
      "enquiry",
      "deadlead",
      "qualifiedout",
      "willreview",
      "donotcall",
    ]),
  }).pluck("id");

  const assignedUserField = UserField<any>({
    props: {
      label: "Assigned to",
      vModel: "assignedUser",
      isMultiSelect: true,
    },
    query: {
      roles: {
        role: In(["staff", "admin"]),
      },
      displayUserInPortal: Equal(true),
    },
    simplifyData: true,
  });

  const ownedByField = UserField<any>({
    props: {
      label: "Owned by",
      vModel: "ownedBy",
      isMultiSelect: true,
    },
    query: {
      roles: {
        role: In(["staff", "admin"]),
      },
      displayUserInPortal: Equal(true),
    },
    simplifyData: true,
  });

  const referralField = AccountReferralCodeField<any>({
    props: {
      label: "Referral code",
      vModel: "referral",
      isMultiSelect: true,
    },
    query: {},
    simplifyData: true,
  });
  return MasterListFiltersForm({
    name: "filter-lead-form",
    data: () => ({
      search: "",
      assignedUser: Array<string>(),
      ownedBy: Array<string>(),
      status: Array<string>(),
      referral: Array<string>(),
      tags: Array<string>(),
      created: null as null | [number, number],
      nextActionAt: null as null | [number, number],
      dueBy: null as null | "today" | "tomorrow" | "overdue",
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name / Email / Tel",
          },
          models: {
            value: "search",
          },
        }),
      ],
      ownedBy: [ownedByField],
      assignedUser: [assignedUserField],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "leadStatus",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      referral: [referralField],
    }),
    advancedComponents: () => ({
      tags: [
        TagsField({
          props: {
            vModel: "tags",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      dueBy: [
        FormSelectField({
          reactive: true,
          props: {
            label: "Due by",
            options: {
              today: "Today",
              tomorrow: "Tomorrow",
              overdue: "Overdue",
            },
            reference: "dueBy",
          },
          models: {
            value: {
              get: (data) => data.dueBy,
              set: (value, data, controls) => {
                data.dueBy = value;
                data.status = dueByLeadFilteredStatuses;
                controls.incrementFormRenderKey();
              },
            },
          },
        }),
      ],
    }),
  });
};
