import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KinvaultOfficerDetailsBreadCrumb,
  KinvaultOfficerMasterListBreadCrumb,
} from "../../breadcrumbs";
import { Routes as KinvaultOfficerDetailsAnswerphoneMessageScriptRoutes } from "./answerphone-message-script";
import { Routes as KinvaultOfficerDetailsCallScriptRoutes } from "./call-script";
import { Routes as KinvaultOfficerDetailsEmailsRoutes } from "./email-log";
import {
  KinvaultOfficerDetailsNoteRoute,
  Routes as KinvaultOfficerDetailsNoteRoutes,
} from "./note";
import { Routes as KinvaultOfficerDetailsUpdateProfileRoutes } from "./update-profile";

export const KinvaultOfficerDetailsRoute = "KinvaultOfficerDetails";
export const KinvaultOfficerDetailsURI = "/officer/:officer";
export type KinvaultOfficerDetailsParams = RouteParamObject<
  typeof KinvaultOfficerDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultOfficerDetailsRoute,
    path: KinvaultOfficerDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "KinvaultOfficerDetails" */ "./KinvaultOfficerDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KinvaultOfficerMasterListBreadCrumb,
        KinvaultOfficerDetailsBreadCrumb(params),
      ],
    },
    children: [
      ...KinvaultOfficerDetailsEmailsRoutes,
      ...KinvaultOfficerDetailsCallScriptRoutes,
      ...KinvaultOfficerDetailsUpdateProfileRoutes,
      ...KinvaultOfficerDetailsAnswerphoneMessageScriptRoutes,
      ...KinvaultOfficerDetailsNoteRoutes,
    ],
    redirect: { name: KinvaultOfficerDetailsNoteRoute },
  },
];
