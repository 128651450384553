import { Api } from "@/service/api.service";
import { Gift } from "@kinherit/sdk";

interface DeleteKintinGiftMessage {
  gift: Gift;
}

interface DeleteKintinGiftResponse {
  [key: string]: never;
}

export const DeleteKintinGiftHandler = async (
  message: DeleteKintinGiftMessage,
): Promise<DeleteKintinGiftResponse> => {
  await Api.resource("portal", "/v2/portal/kintin-gift/{kintin}/gift/{gift}", {
    kintin: message.gift.$data.kintin,
    gift: message.gift.$data.id,
  })
    .method("delete")

    .result();

  message.gift.$delete();

  return {};
};
