import { Api } from "@/service/api.service";
import { KintinCheck, Note } from "@kinherit/sdk";

interface UpdateKintinCheckMessage {
  kintin: string;
  kintinCheck: KintinCheck;
}

interface UpdateKintinCheckResponse {
  kintinCheck: KintinCheck;
  notes: Note[];
}

export const UpdateKintinCheckHandler = async (
  message: UpdateKintinCheckMessage,
): Promise<UpdateKintinCheckResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-check/{kintin}/checks/{kintinCheck}",
    {
      kintin: message.kintin,
      kintinCheck: message.kintinCheck.$data.id,
    },
  )
    .method("patch")
    .body({
      checkType: message.kintinCheck.$data.checkType,
      checkValue: message.kintinCheck.$data.checkValue,
      note: {
        completedAt: message.kintinCheck.note.$data.completedAt,
        kintin: message.kintin,
        name: message.kintinCheck.note.$data.name,
        notes: message.kintinCheck.note.$data.notes,
        dueAt: message.kintinCheck.note.$data.dueAt,
        pinned: message.kintinCheck.note.$data.pinned,
      },
      kintin: message.kintin,
      step: message.kintinCheck.$data.step,
    })

    .result();

  const kintinCheck = KintinCheck.$inflate(response.kintinCheck).first();

  if (!kintinCheck) {
    throw new Error("Failed to update kintin check");
  }

  return {
    kintinCheck,
    notes: Note.$inflate(response.note),
  };
};
