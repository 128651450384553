<template>
  <div class="branded-kinvault-subsciptions-page">
    <Tabs :config="tabs" />
    <RouterChildView />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsSubscriptionsIndexWrapper",
  "route": "BrandedKinvaultDetailsSubscriptionsIndexRoute",
  "selector": ".branded-kinvault-subsciptions-page"
}
</cypress-wrapper>

<script lang="ts">
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  BrandedKinvaultDetailsSubscriptionsIndexRoute,
  BrandedKinvaultDetailsSubscriptionsReferralCodesRoute,
  BrandedKinvaultDetailsSubscriptionsRoute,
} from ".";

export default defineComponent({
  name: BrandedKinvaultDetailsSubscriptionsIndexRoute,
  components: { Tabs, RouterChildView },
  data: () => ({
    tabs: [
      {
        label: `Subscriptions`,
        route: { name: BrandedKinvaultDetailsSubscriptionsRoute },
      },
      {
        label: `Referral Codes`,
        route: { name: BrandedKinvaultDetailsSubscriptionsReferralCodesRoute },
      },
    ],
  }),
});
</script>
