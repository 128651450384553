import { Api } from "@/service/api.service";
import { AccountReferralCode, IntroducerCompany, Profile } from "@kinherit/sdk";

interface RecordAccountReferralCodeMessage {
  accountReferralCode: AccountReferralCode | string;
}

interface RecordAccountReferralCodeResponse {
  accountReferralCode: AccountReferralCode;
  introducerCompany: IntroducerCompany[];
  profile: Profile[];
}

export const RecordAccountReferralCodeHandler = async (
  message: RecordAccountReferralCodeMessage,
): Promise<RecordAccountReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/account-referral-code/{accountReferralCode}",
    {
      accountReferralCode: message.accountReferralCode,
    },
  )
    .method("get")

    .result();

  const accountReferralCode = AccountReferralCode.$inflate(
    response.accountReferralCode,
    undefined,
    response.$rootIds,
  ).first();

  if (!accountReferralCode) {
    throw new Error("Failed to record account referral code");
  }

  return {
    accountReferralCode,
    introducerCompany: IntroducerCompany.$inflate(response.introducerCompany),
    profile: Profile.$inflate(response.profile),
  };
};
