import { Api } from "@/service/api.service";
import { Between, Like } from "@kinherit/orm/index";
import {
  AccountReferral,
  AccountReferralCode,
  IAccountReferral,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyAccountReferralsMessage {
  introducerCompany: IntroducerCompany | string;
  name: null | string;
  created: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferral;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyAccountReferralsResponse {
  leads: Array<Lead>;
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  introducerContacts: Array<IntroducerContact>;
  referrals: Array<AccountReferral>;
  referralCodes: Array<AccountReferralCode>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerCompanyAccountReferralsHandler = async (
  message: ReadIntroducerCompanyAccountReferralsMessage,
): Promise<ReadIntroducerCompanyAccountReferralsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-referral/{introducerCompany}/referrals",
    {
      introducerCompany: message.introducerCompany,
    },
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request
    .buildQuery(AccountReferral)
    .where({
      kintin: {
        friendlyName: Like(message.name),
      },
      createdAt: Between(message.created),
    })
    .orWhere({
      lead: {
        profile: {
          fullName: Like(message.name),
        },
      },
      createdAt: Between(message.created),
    });

  const response = await request.result();

  return {
    referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
    leads: Lead.$inflate(response.lead),
    kintins: Kintin.$inflate(response.kintin),
    profiles: Profile.$inflate(response.profile),
    introducerContacts: IntroducerContact.$inflate(response.introducerContact),
    referrals: AccountReferral.$inflate(
      response.accountReferral,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
