import { RouteInterface } from "@kinherit/framework/core/route-config";
import { OrderMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as OrderDetailsRoutes } from "./details";

export const OrderMasterListRoute = "OrderMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: OrderMasterListRoute,
    path: "/orders",
    component: () =>
      import(/* webpackChunkName: "OrderMasterList" */ "./OrderMasterList.vue"),
    meta: {
      breadcrumbs: () => [OrderMasterListBreadCrumb],
    },
  },
  ...OrderDetailsRoutes,
];
