<template>
  <SidebarPage
    :key="key"
    v-if="introducerOutsource && !loading"
    class="admin-referral-details"
    :has-context-menu="showContextMenu"
    :name="pageTitle"
    @context-menu="contextMenu"
    :tabs="tabs"
  >
    <template #sidebar>
      <RouterChildView name="sidebar">
        <IntroducerOutsourceDetailsSidebar />
      </RouterChildView>
    </template>
    <template #default>
      <RouterChildView name="default">
        <Card>
          <Button
            @click="edit"
            icon-left="Edit"
            aria-label="Edit Referral"
            text="Edit Referral"
            size="is-small"
            class="mr-1"
          />
        </Card>
        <Card>
          <div class="columns">
            <div class="column">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Client Name</th>
                    <td>{{ introducerOutsource.clientName }}</td>
                  </tr>
                  <tr>
                    <th>Referral To</th>
                    <td>
                      {{ introducerOutsource.company?.profile?.fullName }}
                    </td>
                  </tr>
                  <tr>
                    <th>Referral Date</th>
                    <td>{{ introducerOutsource.datedAt?.formatDate }}</td>
                  </tr>
                  <tr>
                    <th>Expected Value</th>
                    <td>{{ introducerOutsource.expectedValue?.format }}</td>
                  </tr>
                  <tr>
                    <th>Paid</th>
                    <td>
                      {{ introducerOutsource.paidValue?.format }}
                      {{
                        introducerOutsource.paidAt?.formatDate ?? "Not Yet Paid"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Status</th>
                    <td>{{ introducerOutsource.status?.text }}</td>
                  </tr>
                  <tr>
                    <th>Assigned To</th>
                    <td>
                      {{ introducerOutsource.assignedTo?.profile?.fullName }}
                    </td>
                  </tr>
                  <tr>
                    <th>Next Action</th>
                    <td>
                      {{
                        introducerOutsource.nextActionAt?.formatDate ??
                        "Not Set"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div></Card
        >
      </RouterChildView>
    </template>
  </SidebarPage>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerOutsourceDetailsWrapper",
  "route": "IntroducerOutsourceDetails",
  "selector": ".admin-referral-details",
  "imports": {
    "IntroducerOutsourceDetailsNotesWrapper": "@/module/introducer.outsource/page/details/notes/IntroducerOutsourceDetails.Notes.test",
    "IntroducerOutsourceDetailsSidebarWrapper": "@/module/introducer.outsource/page/details/IntroducerOutsourceDetails.sidebar.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Edit", "Delete"]
    },
    "tabs": {
      "type": "tabs",
      "items": ["Summary", "Notes"]
    },
    "notes": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerOutsourceDetailsNotesWrapper"
    },
    "sidebar": {
      "type": "to-one",
      "selector": ".sidebar",
      "wrapper": "IntroducerOutsourceDetailsSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { IntroducerOutsourceMasterListRoute } from "@/module/introducer.outsource/page";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerOutsourceDetailsParams,
  IntroducerOutsourceDetailsRoute,
} from ".";
import { CreateIntroducerOutsourceForm } from "../../form/create-introducer-outsource.form";
import { IntroducerOutsourceDetailsMixin } from "../../mixin/introducer-outsource-details.mixin";
import IntroducerOutsourceDetailsSidebar from "./IntroducerOutsourceDetails.sidebar.vue";
import { IntroducerOutsourceDetailsNotesRoute } from "./notes";

export default defineComponent({
  name: IntroducerOutsourceDetailsRoute,
  mixins: [IntroducerOutsourceDetailsMixin],
  components: {
    SidebarPage,
    IntroducerOutsourceDetailsSidebar,
    RouterChildView,
    PageNotFound,
    Card,
    Button,
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Summary",
        route: {
          name: IntroducerOutsourceDetailsRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.summary.icon,
      },
      {
        label: "Notes",
        route: {
          name: IntroducerOutsourceDetailsNotesRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.notes.icon,
      },
    ] satisfies TabOptions[],
    tab: 0,
  }),
  computed: {
    showContextMenu(): boolean {
      return true; // this.hasWritePermission;
    },
    pageTitle(): string {
      return this.introducerOutsource?.$data?.clientName ?? "Not Set";
    },
  },
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.introducerOutsource.record(
      to.params as IntroducerOutsourceDetailsParams,
    );
  },
  mounted(): void {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.introducerOutsource) return;
      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit",
              line1: "Edit this entry",
              icon: StyleService.icon.edit.icon,
              permission: "introducer-outsource:write",
              action: this.edit,
            },
            {
              title: "Delete",
              line1: "Delete this entry",
              icon: StyleService.icon.delete.icon,
              permission: "introducer-outsource:write",
              action: this.delete,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "icon",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action?.(),
      })();
    },
    async delete(): Promise<void> {
      if (!this.introducerOutsource) {
        return;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Entry",
          message: "Are you sure you want to delete this?",
        },
      });

      await window.Kernel.ActionBus.introducerOutsource.delete({
        introducerOutsource: this.introducerOutsource,
      });

      window.Kernel.visitRoute({
        name: IntroducerOutsourceMasterListRoute,
      });
    },
    async edit(): Promise<void> {
      if (!this.introducerOutsource) {
        return;
      }

      try {
        await CreateIntroducerOutsourceForm(this.introducerOutsource).dialog({
          dialog: {
            title: "Edit Entry",
          },
        });
      } catch (e) {
        this.introducerOutsource.$restore();
        throw e;
      }

      await window.Kernel.ActionBus.introducerOutsource.update({
        introducerOutsource: this.introducerOutsource,
      });

      this.key++;
    },
  },
});
</script>
