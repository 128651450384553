<template>
  <div class="mt-4 introducer-company-details-fees">
    <MasterListPage
      v-if="$data.filters"
      title="Fees"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :sort-by-options="{
        'introducerFeePayment.introducerFeePaymentRun.createdAt': 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #paymentRun="{ row }: { row: IntroducerFeePaymentItem }">
        <a
          title="View Related Payment Run"
          class="is-clickable has-text-info"
          @click="handlePaymentRunClick(row)"
          >{{ row.introducerFeePayment.introducerFeePaymentRun?.name }}</a
        >
      </template>
      <template #client="{ row }: { row: IntroducerFeePaymentItem }">
        <a
          title="View Related Order"
          class="is-clickable has-text-info"
          @click="handleOrderClick(row)"
          >{{ row.name }}</a
        >
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsFeesWrapper",
  "route": "IntroducerCompanyDetailsFees",
  "selector": ".introducer-company-details-fees",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "imports": {
    "ReadIntroducerCompanyFeesFormWrapper": "@/module/introducer.company/form/read-introducer-company-fees.form.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerCompanyFeesFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadIntroducerCompanyFeesForm } from "@/module/introducer.company/form/read-introducer-company-fees.form";
import { IntroducerCompanyDetailsFeesRoute } from "@/module/introducer.company/page/details/accounting";
import {
  IntroducerFeePaymentRunDetailsItemsParams,
  IntroducerFeePaymentRunDetailsItemsRoute,
} from "@/module/introducer.fees/page/details/items";
import {
  OrderDetailsParams,
  OrderDetailsRoute,
} from "@/module/order/page/details";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  IntroducerFeePaymentItem,
  IntroducerFeePaymentRun,
} from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";
export default defineComponent({
  name: IntroducerCompanyDetailsFeesRoute,
  mixins: [IntroducerCompanyDetailsMixin],
  components: { MasterListPage },
  data: () => ({
    filters: ReadIntroducerCompanyFeesForm(),
    columns: [
      {
        title: "createdAt",
        map: (item: IntroducerFeePaymentItem) =>
          item.introducerFeePayment?.introducerFeePaymentRun?.createdAt
            .formatDate,
      },
      {
        title: "Payment Run",
        slot: "paymentRun",
      },
      // {
      //   title: "Payment Run",
      //   map: (item: IntroducerFeePaymentItem) =>
      //     item.introducerFeePayment?.introducerFeePaymentRun?.name,
      // },
      {
        title: "Status",
        map: (item: IntroducerFeePaymentItem) =>
          item.introducerFeePayment?.introducerFeePaymentRun?.status?.text,
      },
      {
        title: "Client",
        slot: "client",
      },
      {
        title: "Type",
        field: "feeTypeId",
      },
      {
        title: "amount",
        map: (item: IntroducerFeePaymentItem) => item.amount.amount.toFixed(2),
      },
      {
        title: "Payment Status",
        map: (item: IntroducerFeePaymentItem) =>
          item.introducerFeePayment?.status?.text,
      },
      {
        title: "vat",
        field: "vat",
      },
    ] as Array<TableColumn<IntroducerFeePaymentItem>>,
    rows: Array<IntroducerFeePaymentItem>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "introducerFeePayment.introducerFeePaymentRun.createdAt" as keyof IntroducerFeePaymentRun,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    async refresh(
      formData: ReturnType<typeof ReadIntroducerCompanyFeesForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.introducerCompany.fees.read({
        introducerCompany: this.$params.introducerCompany,
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.introducerFeePaymentItems;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    handlePaymentRunClick(row: IntroducerFeePaymentItem): void {
      if (!row.introducerFeePayment?.introducerFeePaymentRun) {
        return;
      }

      const params: IntroducerFeePaymentRunDetailsItemsParams = {
        introducerFeePaymentRun:
          row.introducerFeePayment.introducerFeePaymentRun?.id,
      };

      window.Kernel.visitRoute({
        name: IntroducerFeePaymentRunDetailsItemsRoute,
        params,
      });
    },
    handleOrderClick(row: IntroducerFeePaymentItem): void {
      if (!row.$data.order) {
        return;
      }

      const params: OrderDetailsParams = {
        order: row.$data.order,
      };

      window.Kernel.visitRoute({
        name: OrderDetailsRoute,
        params,
      });
    },
  },
});
</script>
