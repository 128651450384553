import { Api } from "@/service/api.service";
import { Gift } from "@kinherit/sdk";

interface UpdateKintinGiftMessage {
  gift: Gift;
}

interface UpdateKintinGiftResponse {
  gift: Gift;
}

export const UpdateKintinGiftHandler = async (
  message: UpdateKintinGiftMessage,
): Promise<UpdateKintinGiftResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-gift/{kintin}/gift/{gift}",
    {
      kintin: message.gift.$data.kintin,
      gift: message.gift.$data.id,
    },
  )
    .method("patch")

    .body({
      kintin: message.gift.$data.kintin,
      address: message.gift.$data.address,
      forPerson: message.gift.$data.forPerson,
      sentBy: message.gift.$data.sentBy,
      type: message.gift.$data.type,
      classGiftType: message.gift.$data.classGiftType,
      amount: message.gift.$data.amount
        ? {
            amount: message.gift.$data.amount.amount,
            type: message.gift.$data.amount.type,
          }
        : undefined,
      notes: message.gift.$data.notes,
      onSecondDeath: message.gift.$data.onSecondDeath,
      asPrimaryResidence: message.gift.$data.asPrimaryResidence,
      charityName: message.gift.$data.charityName,
      charityNo: message.gift.$data.charityNo,
      ifPredeceased: message.gift.$data.ifPredeceased,
      sortOrder: message.gift.$data.sortOrder,
      outputPreference: message.gift.$data.outputPreference,
      reservePerson: message.gift.$data.reservePerson,
      reserveclassGiftType: message.gift.$data.reserveclassGiftType,
    })
    .result();

  const gift = Gift.$inflate(response.gift).first();

  if (!gift) {
    throw new Error("Failed to update gift");
  }

  return { gift };
};
