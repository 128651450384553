import { Api } from "@/service/api.service";
import { Kintin, Order } from "@kinherit/sdk";

export interface VoidOustandOrdersMessage {
  kintin: string | Kintin;
}

interface VoidOustandOrdersResponse {
  orders: Array<Order>;
}

export const VoidOustandOrdersHandler = async (
  message: VoidOustandOrdersMessage,
): Promise<VoidOustandOrdersResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin/{kintin}/orders/void-outstanding",
    {
      kintin: message.kintin,
    },
  )
    .method("put")
    .result();

  return {
    orders: Order.$inflate(response.order),
  };
};
