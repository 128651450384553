<template>
  <div v-if="kintin">
    <CallScriptSection title="Coercion">
      <b
        >Well we now we know why we are here, and how it all works I’ll get on
        with the questions. These first questions just require Yes and No
        answers.</b
      >
      <br />
      <br />
      <div class="columns">
        <div class="column">
          <h4>{{ primaryProfile?.fullName }}</h4>
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0d"
            :kintin="kintin"
            is-reversed
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0a"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0b"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0c"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
        </div>

        <div v-if="person2Present" class="column">
          <h4>{{ secondaryProfile?.fullName }}</h4>
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0dd"
            :kintin="kintin"
            is-reversed
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.00"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0aa"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0bb"
            :kintin="kintin"
            is-reversed
            :on="{
              no: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
          <ConfirmCheck
            @update="updateCheckValue"
            :kintin-checks="kintinChecks"
            :checks="checks"
            step="2.2.0cc"
            :kintin="kintin"
            is-reversed
            :on="{
              yes: {
                flag: [
                  'Take details put it in a follow-up note and discuss with Ben.',
                ],
              },
            }"
          />
        </div>
      </div>

      <hr class="mt-0" />
      <b
        >And do <span v-if="isJoint">either of</span> you have an existing
        Will?</b
      >
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="5.2.0"
        :kintin="kintin"
        hide-field-label
        :yes-label="'Has an existing Will, but it needs updating'"
        :no-label="'No existing Will'"
        :on="{
          yes: {
            say: [
              'When your new Will is signed, any old Wills should be destroyed, to make sure they don’t interfere.',
            ],
          },
        }"
      />
      <hr class="mt-0" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="2.2.1"
        :kintin="kintin"
      />
      <p>
        <b>Thanks for that. Right, let’s move on to you and your family.</b>
      </p>
      <div class="buttons is-right">
        <Button text="Save" @click="saveHandler" color="is-positive" />
        <Button
          text="Save and Continue"
          @click="saveAndContinueHandler"
          color="is-positive"
        />
      </div>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { Button } from "@kinherit/framework/component.input/button";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptRoute } from "..";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    Button,
    CallScriptSection,
    ConfirmCheck,
  },
  emits: ["save", "save-and-continue"],
  data: () => ({
    person2Present: null as boolean | null,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
  methods: {
    async saveAndContinueHandler(): Promise<void> {
      this.$emit("save-and-continue");
    },
    async saveHandler() {
      this.$emit("save");
    },
  },
});
</script>
