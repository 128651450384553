import { Api } from "@/service/api.service";
import { Option, Profile, User } from "@kinherit/sdk";

interface CreateUserMessage {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  roles: Array<string>;
}

interface CreateUserResponse {
  user: User;
  profiles: Array<Profile>;
}

export const CreateUserHandler = async (
  message: CreateUserMessage,
): Promise<CreateUserResponse> => {
  const status = Option.$findOneByOrThrow({
    group: "userStatus",
    value: "new",
  }).id;

  const response = await Api.resource("portal", "/v2/portal/user")
    .method("post")
    .body({
      credential: {
        username: message.emailAddress,
      },
      displayUserInPortal: true,
      profile: {
        firstName: message.firstName,
        lastName: message.lastName,
        emails: [
          {
            email: message.emailAddress,
            primary: true,
          },
        ],
        phoneNumbers:
          "" !== message.phoneNumber.trim()
            ? [{ tel: message.phoneNumber, primary: true }]
            : undefined,
      },
      roles: message.roles,
      status,
      isEstatePlanner: false,
      isLegalAssistant: false,
    })

    .result();

  const user = User.$inflate(response.user).first();

  if (!user) {
    throw new Error("Failed to create user");
  }

  return {
    user,
    profiles: Profile.$inflate(response.profile),
  };
};
