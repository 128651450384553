import { Api } from "@/service/api.service";
import {
  Address,
  EmailAddress,
  IntroducerBillingContact,
  IntroducerCompany,
  PhoneNumber,
  Profile,
  Website,
} from "@kinherit/sdk";

export interface CreateIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  introducerBillingContact: IntroducerBillingContact;
}

export interface CreateIntroducerBillingContactResponse {
  introducerBillingContacts: Array<IntroducerBillingContact>;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  websites: Array<Website>;
}

export const CreateIntroducerBillingContactHandler = async (
  message: CreateIntroducerBillingContactMessage,
): Promise<CreateIntroducerBillingContactResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts",
    {
      introducerCompany: message.introducerCompany,
    },
  )
    .method("post")
    .body({
      agentRemittanceType:
        message.introducerBillingContact.$data.agentRemittanceType,
      company: message.introducerBillingContact.$data.company,
      introducerRemittanceType:
        message.introducerBillingContact.$data.introducerRemittanceType,
      notes: message.introducerBillingContact.$data.notes,
      isGlobal: message.introducerBillingContact.$data.isGlobal,
      profile: {
        knownAs: message.introducerBillingContact.profile.$data.knownAs,
        jobTitle: message.introducerBillingContact.profile.$data.jobTitle,
        firstName: message.introducerBillingContact.profile.$data.firstName,
        middleNames: message.introducerBillingContact.profile.$data.middleNames,
        lastName: message.introducerBillingContact.profile.$data.lastName,
        title: message.introducerBillingContact.profile.$data.title,
        organisationName:
          message.introducerBillingContact.profile.$data.organisationName,
        organisationNumber:
          message.introducerBillingContact.profile.$data.organisationNumber,
        suffix: message.introducerBillingContact.profile.$data.suffix,
        addresses: message.introducerBillingContact.profile.addresses.map(
          (address) => ({
            line1: address.$data.line1,
            line2: address.$data.line2,
            city: address.$data.city,
            postcode: address.$data.postcode,
            country: address.$data.country,
            state: address.$data.state,
          }),
        ),
        phoneNumbers: message.introducerBillingContact.profile.phoneNumbers.map(
          (phoneNumber) => ({
            tel: phoneNumber.$data.tel,
          }),
        ),
        emails: message.introducerBillingContact.profile.emails.map(
          (emailAddress) => ({
            email: emailAddress.$data.email,
          }),
        ),
        websites: message.introducerBillingContact.profile.websites.map(
          (website) => ({
            url: website.$data.url,
          }),
        ),
      },
    });

  const response = await request.result();

  message.introducerBillingContact.$delete({
    profile: {
      addresses: true,
      phoneNumbers: true,
      emails: true,
      websites: true,
    },
  });

  return {
    introducerBillingContacts: IntroducerBillingContact.$inflate(
      response.introducerBillingContact,
      undefined,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
    addresses: Address.$inflate(response.address),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    websites: Website.$inflate(response.website),
  };
};
