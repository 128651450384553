import { Api } from "@/service/api.service";
import { IntroducerCompany } from "@kinherit/sdk";

interface DeleteIntroducerCompanyMessage {
  introducerCompany: string | IntroducerCompany;
}

interface DeleteIntroducerCompanyResponse {
  [key: string]: never;
}

export const DeleteIntroducerCompanyHandler = async (
  message: DeleteIntroducerCompanyMessage,
): Promise<DeleteIntroducerCompanyResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-company/{introducerCompany}",
    {
      introducerCompany: message.introducerCompany,
    },
  )
    .method("delete")

    .result();

  IntroducerCompany.$delete(message.introducerCompany);

  return {};
};
