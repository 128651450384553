import { Api } from "@/service/api.service";
import { UploadForm } from "@kinherit/sdk";

interface DeleteUploadFormMessage {
  uploadForm: UploadForm;
}

interface DeleteUploadFormResponse {
  [key: string]: never;
}

export const DeleteUploadFormHandler = async (
  message: DeleteUploadFormMessage,
): Promise<DeleteUploadFormResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/upload-form/upload-forms/{uploadForm}",
    {
      uploadForm: message.uploadForm.id,
    },
  )
    .method("delete")
    .result();

  message.uploadForm.$delete();

  return {};
};
