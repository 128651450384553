<template>
  <strong>Charities</strong>
  <p>
    The receipt of a person who appears to be a proper officer of the above
    charity/charities shall be a discharge to my trustees
    <br />
    <br />
    This distribution shall not fail if at my death the charity/charities have
    ceased to exist or have amalgamated with another charity or have changed
    name and my trustees shall pay it to the charitable organisation which they
    consider most nearly fulfils the objects I intended to benefit
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
