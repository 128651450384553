import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptIntroRoute = "KintinDetailsCallScriptIntro";
export const KintinDetailsCallScriptIntroURI =
  "/kinvault/:kintin/call-script/intro";
export type KintinDetailsCallScriptIntroParams = RouteParamObject<
  typeof KintinDetailsCallScriptIntroURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptIntroRoute,
    path: KintinDetailsCallScriptIntroURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptIntro" */ "./KinvaultKintinDetails.CallScript.Intro.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Intro",
          route: { name: KintinDetailsCallScriptIntroRoute, params },
        },
      ],
    },
  },
];
