import { Api } from "@/service/api.service";
import { IntroducerCpd } from "@kinherit/sdk";

interface CreateIntroducerCpdMessage {
  introducerCpd: IntroducerCpd;
}

interface CreateIntroducerCpdResponse {
  introducerCpd: IntroducerCpd;
}

export const CreateIntroducerCpdHandler = async (
  message: CreateIntroducerCpdMessage,
): Promise<CreateIntroducerCpdResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/cpd-session/introducer-cpd",
  )
    .method("post")

    .body({
      contact: message.introducerCpd.$data.contact,
      session: message.introducerCpd.$data.session,
      status: message.introducerCpd.$data.status,
      type: message.introducerCpd.$data.type,
    })
    .result();

  const introducerCpd = IntroducerCpd.$inflate(response.introducerCpd).first();

  if (!introducerCpd) {
    throw new Error("Failed to create Introducer Cpd");
  }

  message.introducerCpd.$delete();

  return { introducerCpd };
};
