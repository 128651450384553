import { RouteInterface } from "@kinherit/framework/core/route-config";
import { UserBreadCrumb } from "../breadcrumbs";
import { Routes as AdminUserDetailsRoutes } from "./details";

export const AdminUserMasterListRoute = "AdminUserMasterList";
export const AdminUserMasterListUri = "/admin/users";
export const AdminStaffMasterListRoute = "AdminStaffMasterList";
export const AdminStaffMasterListUri = "/admin/users/staff";

export const Routes: Array<RouteInterface> = [
  {
    name: AdminUserMasterListRoute,
    path: AdminUserMasterListUri,
    component: () =>
      import(/* webpackChunkName: "UserMasterList" */ "./UserMasterList.vue"),
    meta: {
      breadcrumbs: UserBreadCrumb,
    },
  },
  {
    name: AdminStaffMasterListRoute,
    path: AdminStaffMasterListUri,
    component: () =>
      import(/* webpackChunkName: "StaffMasterList" */ "./StaffMasterList.vue"),
    meta: {
      breadcrumbs: UserBreadCrumb,
    },
  },
  ...AdminUserDetailsRoutes,
];
