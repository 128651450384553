import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsKinvaultAccessRoute =
  "IntroducerContactDetailsKinvaultAccess";
export const IntroducerContactDetailsKinvaultAccessURI =
  "/introducer/contact/:introducerContact/kinvault-access";
export type IntroducerContactDetailsKinvaultAccessParams = RouteParamObject<
  typeof IntroducerContactDetailsKinvaultAccessURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsKinvaultAccessRoute,
    path: IntroducerContactDetailsKinvaultAccessURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsKinvaultAccess" */ "./IntroducerContactDetails.KinvaultAccess.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "Kinvault Access",
          route: {
            name: IntroducerContactDetailsKinvaultAccessRoute,
            params,
          },
        },
      ],
    },
  },
];
