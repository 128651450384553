<template>
  <div v-if="kintin">
    <CallScriptSection title="Lasting Power of Attorneys">
      <Message title="Reminder" color="is-warning" class="mb-4">
        <span v-if="checkIsYes('3.0.0a')">
          Customer DOES own family home.
        </span>
        <span v-else> Customer DOES NOT own family home. </span>
        <br /><br />
        <span
          class="is-block"
          v-for="address in primaryProfile?.addresses.filter(
            (a) => a.primaryResidential === true,
          )"
          :key="address.id"
        >
          {{ address.summary }}
        </span>
        <span v-if="checkIsYes('2.4.4b')" class="is-block">
          Sole Jurisdiction is England & Wales
        </span>
        <span v-else> Sole Jurisdiction is NOT England & Wales! </span>
      </Message>
      <b>
        So now we need to have a quick look at Lasting Powers of Attorney. Do
        you know what they are?
      </b>
      <br /><em>[pause]</em> <br /><br />
      <b>
        They let family or friends speak for you if you are incapacitated,
        noting the alternative is the local authority or the courts – so if
        something happens they really are invaluable.
      </b>
      <br /><br />
      <b v-if="!checkIsYes('2.4.4b')">
        The process can be a bit different outside of England, but the basic
        questions are the same
      </b>
      <em v-if="!checkIsYes('2.4.4b')">
        SPECIALIST NOTE:
        <br />
        SCOTLAND – powers have different names (and can be combined), with a
        letter via Legacy to set them up (not a form), and potentially pay just
        one lot of registration fees.
        <br />- Key difference is that customer needs to be guided to asking
        their DOCTOR to sign certificate (ie not friend cert provider). <br />-
        <a
          href="https://www.publicguardian-scotland.gov.uk/power-of-attorney"
          target="_blank"
          >https://www.publicguardian-scotland.gov.uk/power-of-attorney</a
        >
        <br />
        NORTHERN IRELAND – still using old Enduring Power of Attorney (single
        document, very flexible re wishes in different situations) with an
        archaic framework (1989).
        <br />- Key difference is that notice of intent to put in place an EPA
        needs to be given(posted) to “at least 3 relatives” in the order:
        spouse, kids, parents, siblings etc... <br />-
        <a
          href="https://www.justice-ni.gov.uk/publications/epa-notes-and-forms"
          target="_blank"
          >https://www.justice-ni.gov.uk/publications/epa-notes-and-forms</a
        >
      </em>
      <b>
        They let family or friends speak for you if you are incapacitated.
        Without this, the Local Authority make decisions on your behalf, not
        your family, co if something happens they really are invaluable.
        <br /><br />
        There are two types of Lasting Power of Attorney – one for your Health &
        Welfare, and the other for your Property & Financial Affairs.
        <br /><br />
        The Health one is to make decisions about your care, and is very common
        as people get older, but obviously accident or illness can happen at any
        time.
        <br />
      </b>
      [IF UNSURE – direct them to
      <a
        href="https://www.dailymail.co.uk/news/article-1194735/Home-pensioner-snatched-daughters-home-social-workers.html"
        target="_blank"
        >DailyMail… Betty Figg</a
      >] <br /><br />
      <b>
        The Financial Affairs one lets someone step in and pay your bills, and
        generally manage your affairs. If you haven’t sorted this, someone would
        need to apply to court to become a Deputy, and would need to keep
        records of everything. For partners who are financially reliant, it can
        also be a nightmare to get court approval to use what is basically the
        couple’s money.
      </b>
      <br />
      [IF UNSURE – direct them to youtube video…
      <a href="https://www.youtube.com/watch?v=xNqGw1cvnkI" target="_blank">
        Heather Bateman on BBC’s One Show
      </a>
      ]
      <br /><br />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1d"
        :kintin="kintin"
        :on="{
          yes: {
            say: [
              'Can I double-check, were they set up since the law changed in 2017?',
            ],
          },
          no: {
            action: ['Discuss LPoAs'],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1e"
        :kintin="kintin"
        :on="{
          yes: {
            action: [
              'Step through, Health Person 1 / Health Person 2 / Is Wealth same P1 / Is wealth same P2?',
            ],
          },
          no: {
            say: [
              'IF COST THE ISSUE SPECIALIST CONSIDER SAYING: Just so you are aware, you can get LPAs drafted but only register them if or when needed, to defer half the cost. It is far better to have something that could be used, than having nothing.',
            ],
          },
        }"
      />
      <AttorneysContainer :kintin="kintin" />
      <b v-if="checkIsYes('4.7.1e')">
        To set up a power of attorney, we will also need someone to vouch for
        you – which means they are confirming you know what you are doing, are
        capable of making decisions, that sort of thing.
        <br /><br />
        There is a long list of people that can't vouch for you, so no relatives
        or employees. They also need to have known you for at least 2 years,
        which is why people normally pick friends or neighbours. It can also be
        a doctor, but that is typically where there is a question-mark about
        capacity. The role is called Certificate Provider … do you have an idea
        who you might use? … it would be great to add it now
        <br /><em>[Add above as appropriate]</em> <br /><br />
        <b
          >There a few more detailed questions but we can pick that up later.</b
        >
      </b>
      <b v-if="checkIsYes('3.0.8a')">
        Just to flag, you mentioned you have business assets. A Finance LPA
        would also cover them, … or do you think you could want different
        Attorneys?
        <br /><em
          >[SPECIALIST: if a business LPA is needed, add note + mention in box
          to output to 4.1]
        </em>
        <br /><br />
      </b>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advAttorneyenNotes"
      :has-continue="false"
    />
  </div>
</template>

<script lang="ts">
import AttorneysContainer from "@/module/kinvault.kintin/component/address-book/AttorneysContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import Message from "@kinherit/framework/component.display/message";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LPAsTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    ConfirmCheck,
    CallScriptSection,
    Message,
    CallScriptSpecialistNotesSection,
    AttorneysContainer,
  },
});
</script>
