import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadStaffUserForm = () => {
  return MasterListFiltersForm({
    name: "filter-staff-user-form",
    data: () => ({
      search: null as string | null,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            reference: "search",
            label: "Search",
            placeholder: "Name",
            size: "is-small",
          },
          models: {
            value: {
              get: (data) => data.search ?? "",
              set: (value, data) => (data.search = value ?? undefined),
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
