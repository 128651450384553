import { Person } from "@kinherit/sdk";

export const KinvaultOfficerMasterListBreadCrumb = {
  text: "Trusted People",
  route: { name: "KinvaultOfficerMasterList" },
};

export const KinvaultOfficerDetailsBreadCrumb = (params: any) => ({
  text: Person.$findOne(params.officer)?.profile.fullName ?? "",
  route: { name: "KinvaultOfficerDetails", params },
});
