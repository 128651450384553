import { CreateUploadForm } from "@/config/model.config";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { UploadForm } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

export const UpdateUploadForm = (data?: UploadForm) => {
  if (!data) {
    data = CreateUploadForm({
      validTill: new DateTime().add({ day: 90 }).formatMachine,
    });
  }
  return defineForm({
    name: "update-upload-form",
    data: () => data as UploadForm,
    formAreas: (data) => [
      defineFormArea({
        name: "update-upload-form-area",
        data,
        template: GridLayout([["name"], ["validTill"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Form Name",
                message: "Note: Will be displayed to the end user",
                placeholder: "i.e Will Upload Form for Joe Bloggs",
              },
              models: {
                value: "name",
              },
            }),
          ],
          validTill: [
            FormDateField({
              props: {
                label: "Valid Till",
                message: "Form will be automatically disabled after this date",
              },
              models: {
                value: "validTill",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
