import { Api } from "@/service/api.service";
import { Order } from "@kinherit/sdk";

export interface UpdateOrdersMessage {
  order: Order;
}

export interface UpdateOrdersResponse {
  order: Order;
}

export const UpdateOrderHandler = async (
  message: UpdateOrdersMessage,
): Promise<UpdateOrdersResponse> => {
  await Api.resource("portal", "/v2/portal/order/{order}", {
    order: message.order.id,
  })
    .method("patch")

    .body({
      feesConfirmed: message.order.$data.feesConfirmed,
    })
    .result();

  message.order.$persist();

  return {
    order: Order.$findOneOrThrow(message.order.id),
  };
};
