import { SharedFilterProps, UserField } from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { Equal, In } from "@kinherit/orm";
import { User } from "@kinherit/sdk";
export const ReadDashboardStatisticForm = (
  data: { selected: User | null; timeFrame: "4wk" | "8wk" | "12wk" } = {
    selected: null,
    timeFrame: "4wk",
  },
) =>
  MasterListFiltersForm({
    name: "read-dashboard-statistic-form",
    data: () => data,
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      selected: [
        UserField({
          props: {
            label: "Select User",
            vModel: "selected",
          },
          query: {
            roles: {
              role: In(["staff", "admin"]),
            },
            displayUserInPortal: Equal(true),
          },
        }),
      ],
      timeFrame: [
        FormSelectField({
          props: {
            label: "Time Frame",
            options: {
              "4wk": "4 Weeks Rolling",
              "8wk": "8 Weeks Rolling",
              "12wk": "12 Weeks Rolling",
            },
          },
          models: {
            value: "timeFrame",
          },
        }),
      ],
    }),
  });
