import { Api } from "@/service/api.service";
import { Kintin, Person } from "@kinherit/sdk";

type sendOfficerLetterMessage = {
  kintin: string | Kintin;
  officer: string | Person;
};

interface sendOfficerLetterResponse {
  [key: string]: never;
}

export const sendOfficerLetterHandler = async (
  message: sendOfficerLetterMessage,
): Promise<sendOfficerLetterResponse> => {
  const kintin =
    "string" === typeof message.kintin ? message.kintin : message.kintin.id;
  const officer =
    "string" === typeof message.officer ? message.officer : message.officer.id;

  await Api.resource("portal", "/v2/portal/officer-letter/send")
    .method("get")
    .params({
      kintin,
      officer,
    })
    .result();

  return {};
};
