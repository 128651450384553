import { Api } from "@/service/api.service";
import { EmailCampaign } from "@kinherit/sdk";

export interface RecordEmailCampaignMessage {
  emailCampaign: EmailCampaign | string;
}

export interface RecordEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
}

export const RecordEmailCampaignHandler = async (
  message: RecordEmailCampaignMessage,
): Promise<RecordEmailCampaignResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/email-campaign/{emailCampaign}",
    message,
  ).method("get");
  const response = await request.result();

  return {
    emailCampaign: EmailCampaign.$inflate(
      response.emailCampaign,
      undefined,
      response.$rootIds,
    ),
  };
};
