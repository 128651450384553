import { FormPasswordField } from "@kinherit/framework/component.input/password-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { ColumnLayout } from "@kinherit/framework/component.layout/dynamic-layout/layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const LoginForm = (data = { username: "", password: "" }) =>
  defineForm({
    name: "login-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: {
          children: [
            ColumnLayout(["username" as const]),
            ColumnLayout(["password" as const]),
          ],
        },
        components: () => ({
          username: [
            FormTextField({
              props: {
                label: "Username",
                validators: ["required"],
                autocomplete: "username",
              },
              models: {
                value: "username",
              },
            }),
          ],
          password: [
            FormPasswordField({
              props: {
                label: "Password",
                validators: ["required"],
              },
              models: {
                value: "password",
              },
            }),
          ],
        }),
      }),
    ],
  });
