import { Api } from "@/service/api.service";
import { CreditNote } from "@kinherit/sdk";

export interface UpdateCreditNoteMessage {
  creditNote: CreditNote;
}

export interface UpdateCreditNoteResponse {
  creditNote: CreditNote;
}

export const UpdateCreditNoteHandler = async (
  message: UpdateCreditNoteMessage,
): Promise<UpdateCreditNoteResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/credit-note/{creditNote}",
    {
      creditNote: message.creditNote,
    },
  )
    .method("patch")
    .body({
      issuedAt: message.creditNote.$data.issuedAt,
      issuedBy: message.creditNote.$data.issuedBy,
      note: {
        completedAt: message.creditNote.note.$data.completedAt,
        dueAt: message.creditNote.note.$data.dueAt,
        kintin: message.creditNote.note.$data.kintin,
        name: message.creditNote.note.$data.name,
        notes: message.creditNote.note.$data.notes,
        pinned: message.creditNote.note.$data.pinned,
        type: "creditNote",
      },
      relatedKintin: message.creditNote.$data.relatedKintin,
      relatedUser: message.creditNote.$data.relatedUser,
      initialValue: message.creditNote.$data.initialValue,
      remainingValue: message.creditNote.$data.remainingValue,
    })

    .result();

  const creditNote = CreditNote.$inflate(response.creditNote).first();

  if (!creditNote) {
    throw new Error("Failed to update credit note");
  }

  return {
    creditNote,
  };
};
