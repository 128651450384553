<template>
  <SidebarPage title="Reports">
    <template #sidebar>
      <ReportSidebar />
    </template>

    <template #default>
      <RouterChildView />
    </template>
  </SidebarPage>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ReportRoute } from ".";
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import ReportSidebar from "./Reports.Sidebar.vue";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";

export default defineComponent({
  name: ReportRoute,
  components: {
    ReportSidebar,
    SidebarPage,
    RouterChildView,
  },
});
</script>
