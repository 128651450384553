import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  IIntroducerFeePayment,
  IntroducerFeePayment,
  IntroducerFeePaymentItem,
  XeroContact,
} from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentMessage {
  introducerFeePaymentRun: string;
  client?: string | null;
  xeroContact?: string | null;
  createdAt?: [number, number] | null;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerFeePayment;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerFeePaymentResponse {
  introducerFeePayments: Array<IntroducerFeePayment>;
  xeroContacts: XeroContact[];
  introducerFeePaymentItems: IntroducerFeePaymentItem[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerFeePaymentHandler = async (
  message: ReadIntroducerFeePaymentMessage,
): Promise<ReadIntroducerFeePaymentResponse> => {
  const request = Api.resource("portal", "/v2/portal/introducer-fee-payment")
    .method("get")
    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerFeePayment).where({
    introducerFeePaymentRun: {
      id: Equal(message.introducerFeePaymentRun),
    },
    introducerFeePaymentItems: {
      order: {
        kintin: {
          friendlyName: Like(message.client),
        },
      },
    },
    xeroContact: {
      contactName: Like(message.xeroContact),
    },
    createdAt: Between(message.createdAt),
  });

  const response = await request.result();

  return {
    introducerFeePayments: IntroducerFeePayment.$inflate(
      response.introducerFeePayment,
      message.sort,
      response.$rootIds,
    ),
    xeroContacts: XeroContact.$inflate(response.xeroContact),
    introducerFeePaymentItems: IntroducerFeePaymentItem.$inflate(
      response.introducerFeePaymentItem,
    ),
    pagination: getPagination(response),
  };
};
