<template>
  <div class="columns user-details-summary">
    <div class="column">
      <DataListWidget title="Summary" :details="userSummary" />
    </div>
    <div class="column">
      <DataListWidget title="Portal" :details="portalUserSummary" />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsSummaryWrapper",
  "route": "UserDetailsSummary",
  "selector": ".user-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { UserDetailsSummaryRoute } from ".";

export default defineComponent({
  name: UserDetailsSummaryRoute,
  mixins: [AuthService.mixin({ sessionRequired: true }), UserDetailsMixin],
  components: {
    DataListWidget,
  },
  computed: {
    userSummary(): DataListWidgetDetail[] {
      if (!this.user || !this.user.credential) return [];
      return [
        {
          label: "Username",
          value: this.user.credential.username,
        },
        {
          label: "Title",
          value: this.profile?.title,
          hide: !this.profile?.title,
        },
        {
          label: "First name",
          value: this.profile?.firstName,
          hide: !this.profile?.firstName,
        },
        {
          label: "Middle names",
          value: this.profile?.middleNames,
          hide: !this.profile?.middleNames,
        },
        {
          label: "Last name",
          value: this.profile?.lastName,
          hide: !this.profile?.lastName,
        },
        {
          label: "Organisation",
          value: this.profile?.organisationName,
          hide: !this.profile?.organisationName,
        },
        {
          label: "Organisation number",
          value: this.profile?.organisationNumber,
          hide: !this.profile?.organisationNumber,
        },
        {
          label: "Status",
          value: this.user.status?.text,
        },

        {
          label: "Is Disabled",
          value: this.user.isDisabled ? "Yes" : "No",
        },
        {
          label: "Roles",
          value: this.user.roles
            .pluck("role")
            .map((role: any) => role.ucFirst())
            .join(", "),
          hide: this.user?.roles.isEmpty(),
        },
        {
          label: "Created at",
          value: this.user.createdAt.formatDateTime,
        },
        {
          label: "Last logged in",
          value: this.user.credential.lastLoggedIn?.formatDateTime,
          hide: !this.user.credential.lastLoggedIn,
        },
        {
          label: "Last password reset",
          value: this.user.credential.lastPasswordReset?.formatDateTime,
          hide: !this.user.credential.lastPasswordReset,
        },
      ];
    },
    portalUserSummary(): DataListWidgetDetail[] {
      if (!this.user || !this.user.credential) return [];
      return [
        {
          label: "Display In Portal",
          value: this.user.displayUserInPortal ? "Yes" : "No",
        },
        {
          label: "Email Notifications",
          value: this.user.sendEmailNotifications ? "Yes" : "No",
        },
        {
          label: "SMS Notifications",
          value: this.user.sendSmsNotifications ? "Yes" : "No",
        },
        {
          label: "Is Estate Planner",
          value: this.user.isEstatePlanner ? "Yes" : "No",
        },
        {
          label: "Is Legal Assistant",
          value: this.user.isLegalAssistant ? "Yes" : "No",
        },
      ];
    },
  },
});
</script>
