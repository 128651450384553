import { Api } from "@/service/api.service";
import { ISignDoc, Kintin, Person, Profile, SignDoc } from "@kinherit/sdk";

export interface ReadKintinSignDocsMessage {
  kintin: string | Kintin;
  pinned?: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof ISignDoc;
    direction: "asc" | "desc";
  };
}

export interface ReadKintinSignDocsResponse {
  signDocs: Array<SignDoc>;
  people: Array<Person>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKintinSignDocsHandler = async (
  message: ReadKintinSignDocsMessage,
): Promise<ReadKintinSignDocsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs",
    {
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
    },
  )
    .method("get")

    .paginate({
      currentPage: message.pagination?.currentPage ?? 1,
      perPage: message.pagination?.perPage ?? 10,
    })
    .sort(
      message.sort
        ? {
            by: message.sort.by,
            direction: message.sort.direction,
          }
        : undefined,
    );

  const response = await request.result();

  return {
    signDocs: SignDoc.$inflate(
      response.signDoc,
      message.sort,
      response.$rootIds,
    ),
    people: Person.$inflate(response.person),
    profiles: Profile.$inflate(response.profile),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
