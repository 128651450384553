import { Api } from "@/service/api.service";
import {
  CpdSession,
  IIntroducerCpd,
  IntroducerContact,
  IntroducerCpd,
} from "@kinherit/sdk";

export interface ReadIntroducerContactIntroducerCpdMessage {
  introducerContact: IntroducerContact | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerCpd;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerContactIntroducerCpdResponse {
  introducerCpds: Array<IntroducerCpd>;
  cpdSessions: Array<CpdSession>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerContactIntroducerCpdHandler = async (
  message: ReadIntroducerContactIntroducerCpdMessage,
): Promise<ReadIntroducerContactIntroducerCpdResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-contact/{introducerContact}/cpd",
    {
      introducerContact:
        "string" === typeof message.introducerContact
          ? message.introducerContact
          : message.introducerContact.id,
    },
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  const response = await request.result();

  return {
    introducerCpds: IntroducerCpd.$inflate(
      response.introducerCpd,
      message.sort,
      response.$rootIds,
    ),
    cpdSessions: CpdSession.$inflate(response.cpdSession),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
