import { IntroducerFeePaymentRunDetailsItemsBreadCrumb } from "@/module/introducer.fees/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const IntroducerFeePaymentRunDetailsItemsRoute =
  "IntroducerFeePaymentRunDetailsItems";
const IntroducerFeePaymentRunDetailsItemsUri =
  "/introducer/fees/payment-run/:introducerFeePaymentRun/items";
export type IntroducerFeePaymentRunDetailsItemsParams = RouteParamObject<
  typeof IntroducerFeePaymentRunDetailsItemsUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerFeePaymentRunDetailsItemsRoute,
    path: IntroducerFeePaymentRunDetailsItemsUri,
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeePaymentRunDetailsItems" */ "./IntroducerFeePaymentRunDetails.Items.vue"
      ),
    meta: {
      breadcrumbs: IntroducerFeePaymentRunDetailsItemsBreadCrumb,
    },
  },
];
