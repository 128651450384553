import { Api } from "@/service/api.service";
import { WillRevision } from "@kinherit/sdk";

interface CreateWillRevisionMessage {
  willRevision: WillRevision;
}

interface CreateWillRevisionResponse {
  willRevision: WillRevision;
}

export const CreateWillRevisionHandler = async (
  message: CreateWillRevisionMessage,
): Promise<CreateWillRevisionResponse> => {
  const content: Record<string, string> = {};

  for (const [key, value] of Object.entries(
    message.willRevision.$data.content ?? {},
  )) {
    if ("string" === typeof value) {
      content[key.toString()] = value;
    }
  }

  const response = await Api.resource("portal", "/v2/portal/will-revision")
    .method("post")

    .body({
      approvedAt: message.willRevision.$data.approvedAt,
      approvedBy: message.willRevision.$data.approvedBy,
      notes: message.willRevision.$data.notes,
      content,
      createdBy: message.willRevision.$data.createdBy,
      hasBusinessClause: message.willRevision.$data.hasBusinessClause,
      hasBusinessTrust: message.willRevision.$data.hasBusinessTrust,
      hasEstateProtectionTrust:
        message.willRevision.$data.hasEstateProtectionTrust,
      hasIipTrust: message.willRevision.$data.hasIipTrust,
      kintin: message.willRevision.$data.kintin,
      ownedBy: message.willRevision.$data.ownedBy,
    })
    .result();

  const willRevision = WillRevision.$inflate(response.willRevision).first();

  if (!willRevision) {
    throw new Error("Will revision not found");
  }

  message.willRevision.$delete();

  return {
    willRevision,
  };
};
