import { TransferIntroducerContactHandler } from "@/module/introducer.contact/action/introducer-contact/transfer-introducer-contact.action";
import { UpdateIntroducerContactHandler } from "@/module/introducer.contact/action/introducer-contact/update-introducer-contact.action";
import { CreateKintinAccessHandler } from "@/module/introducer.contact/action/kintin-access/create-kintin-access.action";
import { DeleteKintinAccessHandler } from "@/module/introducer.contact/action/kintin-access/delete-kintin-access.action";
import { ReadKintinAccessHandler } from "@/module/introducer.contact/action/kintin-access/read-kintin-access.action";
import { UpdateKintinAccessHandler } from "@/module/introducer.contact/action/kintin-access/update-kintin-access.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadIntroducerContactIntroducerCpdHandler } from "./cpd/read-introducer-contact-introducer-cpd.action";
import { ComputeIntroducerContactStatsHandler } from "./introducer-contact/compute-introducer-contact-stats.action";
import { CreateIntroducerContactHandler } from "./introducer-contact/create-introducer-contact.action";
import { ReadIntroducerContactHandler } from "./introducer-contact/read-introducer-contact.action";
import { RecordIntroducerContactHandler } from "./introducer-contact/record-introducer-contact.action";
import { UpdateIntroducerContactProfileHandler } from "./profile/update-introducer-contact-profile.action";
import { ReadIntroducerContactAccountReferralCodesHandler } from "./referral/read-introducer-contact-referral-codes.action";
import { ReadIntroducerContactAccountReferralKintinsHandler } from "./referral/read-introducer-contact-referral-kintins.action";
import { ReadIntroducerContactReferralLeadsHandler } from "./referral/read-introducer-contact-referral-leads.action";

export const Actions = {
  "introducer/contact/read": ReadIntroducerContactHandler,
  "introducer/contact/record": RecordIntroducerContactHandler,
  "introducer/contact/update": UpdateIntroducerContactHandler,
  "introducer/contact/profile/update": UpdateIntroducerContactProfileHandler,
  "introducer/contact/account-referral/leads/read":
    ReadIntroducerContactReferralLeadsHandler,
  "introducer/contact/account-referral/kintins/read":
    ReadIntroducerContactAccountReferralKintinsHandler,
  "introducer/contact/referral-codes/read":
    ReadIntroducerContactAccountReferralCodesHandler,
  "introducer/contact/compute/stats": ComputeIntroducerContactStatsHandler,
  "introducer/contact/cpd/read": ReadIntroducerContactIntroducerCpdHandler,
  "introducer/contact/create": CreateIntroducerContactHandler,
  "introducer/contact/transfer": TransferIntroducerContactHandler,
  // kintin-access
  "introducer/contact/kintin-access/read": ReadKintinAccessHandler,
  "introducer/contact/kintin-access/create": CreateKintinAccessHandler,
  "introducer/contact/kintin-access/update": UpdateKintinAccessHandler,
  "introducer/contact/kintin-access/delete": DeleteKintinAccessHandler,
};

export const IntroducerContactActionBus = DefineActionBus({
  name: "Introducer.Contact",
  actions: {
    contact: DefineActionBus({
      name: "Introducer.Contact.Contact",
      actions: {
        read: ReadIntroducerContactHandler,
        record: RecordIntroducerContactHandler,
        update: UpdateIntroducerContactHandler,
        updateProfile: UpdateIntroducerContactProfileHandler,
        create: CreateIntroducerContactHandler,
        transfer: TransferIntroducerContactHandler,
      },
    }),
    accountReferral: DefineActionBus({
      name: "Introducer.Contact.AccountReferral",
      actions: {
        leads: ReadIntroducerContactReferralLeadsHandler,
        kintins: ReadIntroducerContactAccountReferralKintinsHandler,
        codes: ReadIntroducerContactAccountReferralCodesHandler,
      },
    }),
    computeStats: ComputeIntroducerContactStatsHandler,
    readCpd: ReadIntroducerContactIntroducerCpdHandler,
    kintinAccess: DefineActionBus({
      name: "Introducer.Contact.KintinAccess",
      actions: {
        read: ReadKintinAccessHandler,
        create: CreateKintinAccessHandler,
        update: UpdateKintinAccessHandler,
        delete: DeleteKintinAccessHandler,
      },
    }),
  },
});
