import { OptionsSelectField, RoleField } from "@/config/form.config";
import { AuthService } from "@/service/auth.service";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Role, User } from "@kinherit/sdk";

export const UpdateUserForm = (user: User) =>
  defineForm({
    name: "update-user-form",
    data: () => user,
    formAreas: (data) => [
      defineFormArea({
        name: "update-user-form-area",
        data,
        template: GridLayout([
          ["username"],
          ["status"],
          AuthService.hasRole("admin") ? ["roles"] : [],
          ["acuityUserId"],
          ["displayUserInPortal", "isEstatePlanner", "isLegalAssistant"],
          [{ is: "hr" }],
          ["emailNotifications", "smsNotifications"],
        ]),
        components: () => ({
          username: [
            FormTextField({
              props: {
                label: "Username",
                validators: ["required"],
              },
              models: {
                value: "credential.username",
              },
            }),
          ],
          status: [
            OptionsSelectField({
              props: {
                label: "Status",
                showClearButton: false,
              },
              vModel: "status",
              group: "userStatus",
            }),
          ],
          roles: [
            RoleField({
              reactive: true,
              props: {
                reference: "roles",
                vModel: {
                  get: (data) => data.roles,
                  set: (value: Array<Role>, data, controls) => {
                    value.forEach((role) => {
                      if (!data.roles.pluck("id").includes(role.id)) {
                        data.addRoles(role);
                      }
                    });

                    data.roles.forEach((role) => {
                      if (!value.pluck("id").includes(role.id)) {
                        data.removeRoles(role);
                      }
                    });

                    controls.rebuildForm();
                  },
                },
                isMultiSelect: true,
              },
            }),
          ],
          acuityUserId: [
            FormTextField({
              props: {
                vIf: (data) =>
                  data.roles.pluck("role").intersection(["admin", "staff"])
                    .length > 0,
                label: "Acuity User ID",
                message: "Used for Acuity Scheduling integration.",
              },
              models: {
                value: "acuityUserId",
              },
            }),
          ],
          displayUserInPortal: [
            FormCheckboxField({
              props: {
                vIf: (data) =>
                  data.roles.pluck("role").intersection(["admin", "staff"])
                    .length > 0,
                label: "Display User in Portal",
                message:
                  "Show user in dropdowns and allow to be assigned to cases etc.",
              },
              models: {
                value: "displayUserInPortal",
              },
            }),
          ],
          isEstatePlanner: [
            FormCheckboxField({
              props: {
                vIf: (data) =>
                  data.roles.pluck("role").intersection(["admin", "staff"])
                    .length > 0,
                label: "Is Estate Planner",
                message: "Show estate planner specific dashboards etc.",
              },
              models: {
                value: "isEstatePlanner",
              },
            }),
          ],
          isLegalAssistant: [
            FormCheckboxField({
              props: {
                vIf: (data) =>
                  data.roles.pluck("role").intersection(["admin", "staff"])
                    .length > 0,
                label: "Is Legal Assistant",
                message: "Show legal admin specific dashboards etc.",
              },
              models: {
                value: "isLegalAssistant",
              },
            }),
          ],
          emailNotifications: [
            FormCheckboxField({
              props: {
                label: "Email Notifications",
                message:
                  null === data.profile.primaryEmailAddress
                    ? "No email address on file."
                    : "Send email notifications",
                state:
                  null === data.profile.primaryEmailAddress
                    ? "disabled"
                    : undefined,
              },
              models: {
                value: "sendEmailNotifications",
              },
            }),
          ],
          smsNotifications: [
            FormCheckboxField({
              props: {
                label: "SMS Notifications",
                message:
                  null === data.profile.primaryPhoneNumber
                    ? "No phone number on file."
                    : "Send SMS notifications",
                state:
                  null === data.profile.primaryPhoneNumber
                    ? "disabled"
                    : undefined,
              },
              models: {
                value: "sendSmsNotifications",
              },
            }),
          ],
        }),
      }),
    ],
  });
