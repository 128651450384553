import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KinvaultOfficerDetailsBreadCrumb,
  KinvaultOfficerMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const KinvaultOfficerDetailsCallScriptRoute =
  "KinvaultOfficerDetailsCallScript";
export const KinvaultOfficerDetailsCallScriptURI =
  "/officer/:officer/call-script";
export type KinvaultOfficerDetailsCallScriptParams = RouteParamObject<
  typeof KinvaultOfficerDetailsCallScriptURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultOfficerDetailsCallScriptRoute,
    path: KinvaultOfficerDetailsCallScriptURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultOfficerDetailsCallScript" */ "./KinvaultOfficerDetails.CallScript.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        KinvaultOfficerMasterListBreadCrumb,
        KinvaultOfficerDetailsBreadCrumb(params),
        {
          text: "Call Script",
          route: { name: KinvaultOfficerDetailsCallScriptRoute, params },
        },
      ],
    },
  },
];
