import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, In, Like } from "@kinherit/orm";
import { AccountReferralCode, IAccountReferralCode } from "@kinherit/sdk";

interface ReadAccountReferralCodeMessage {
  code?: string;
  category?: Array<string>;
  created?: null | [number, number];
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort: {
    by: keyof IAccountReferralCode;
    direction: "asc" | "desc";
  };
}

interface ReadAccountReferralCodeResponse {
  accountReferralCodes: AccountReferralCode[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadAccountReferralCodeHandler = async (
  message: ReadAccountReferralCodeMessage,
): Promise<ReadAccountReferralCodeResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/referral/account-referral-code",
  )
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(AccountReferralCode).where([
    {
      code: Like(message.code),
      category: {
        id: In(message.category),
      },
      createdAt: Between(message.created),
    },
    {
      name: Like(message.code),
      category: {
        id: In(message.category),
      },
      createdAt: Between(message.created),
    },
  ]);

  const response = await request.result();

  const accountReferralCodes = AccountReferralCode.$inflate(
    response.accountReferralCode,
    undefined,
    response.$rootIds,
  );

  return {
    accountReferralCodes,
    pagination: getPagination(response),
  };
};
