import { Api } from "@/service/api.service";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk";

interface CreateIntroducerContactPartnerPortalPermissionsMessage {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

interface CreateIntroducerContactPartnerPortalPermissionsResponse {
  contactPartnerPortalPermissions: ContactPartnerPortalPermissions;
}

export const CreateIntroducerContactPartnerPortalPermissionsHandler = async (
  message: CreateIntroducerContactPartnerPortalPermissionsMessage,
): Promise<CreateIntroducerContactPartnerPortalPermissionsResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-contact-introducer-partner-portal/{introducerContact}/partner-portal-permissions",
    {
      introducerContact: message.contactPartnerPortalPermissions.contact.$id,
    },
  )
    .method("post")
    .body({
      contact: message.contactPartnerPortalPermissions.contact.$id,
      company: message.contactPartnerPortalPermissions.company.$id,
      clientsRead:
        message.contactPartnerPortalPermissions.clientsRead ?? "none",
      clientsCreate:
        message.contactPartnerPortalPermissions.clientsCreate ?? false,
      feesRead: message.contactPartnerPortalPermissions.feesRead ?? "none",
      invitationsCreate:
        message.contactPartnerPortalPermissions.invitationsCreate ?? false,
    })
    .result();

  const partnerPortalPermissions = ContactPartnerPortalPermissions.$inflate(
    response.contactPartnerPortalPermissions,
  ).first();

  if (!partnerPortalPermissions) {
    throw new Error("Failed to create partner portal permission");
  }

  return {
    contactPartnerPortalPermissions: partnerPortalPermissions,
  };
};
