import {
  BrandedKinvaultField,
  CreatedAtField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadBrandedKinvaultPricingSchemaForm = () =>
  MasterListFiltersForm({
    name: "filter-org-kinvault-pricing-schema-form",
    data: () => ({
      name: null as string | null,
      brandedKinvaults: [] as string[],
      dated: null as [number, number] | null,
      created: null as [number, number] | null,
      defaultOnly: false,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      brandedKinvault: [
        BrandedKinvaultField({
          props: {
            vModel: "brandedKinvaults",
            isMultiSelect: true,
          },
        }),
      ],
      dated: [
        CreatedAtField({
          props: {
            label: "Dated",
            vModel: "dated",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
        }),
      ],
      defaultOnly: [
        FormCheckboxField({
          props: {
            label: "Default Only",
          },
          models: {
            value: "defaultOnly",
          },
        }),
      ],
    }),
    advancedComponents: () => ({}),
  });
