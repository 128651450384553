import { Api } from "@/service/api.service";
import { UploadForm } from "@kinherit/sdk";

interface CreateUploadFormMessage {
  uploadForm: UploadForm;
}

interface CreateUploadFormResponse {
  uploadForm: UploadForm;
}

export const CreateUploadFormHandler = async (
  message: CreateUploadFormMessage,
): Promise<CreateUploadFormResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/upload-form/upload-forms",
  )
    .method("post")
    .body({
      name: message.uploadForm.$data.name,
      accountReferral: message.uploadForm.$data.accountReferral,
      validTill: message.uploadForm.$data.validTill,
    })

    .result();

  const uploadForm = UploadForm.$inflate(response.uploadForm).first();

  if (!uploadForm) {
    throw new Error("Failed to create upload form");
  }

  message.uploadForm.$delete();

  return {
    uploadForm,
  };
};
