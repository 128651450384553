<template>
  <p>
    <strong>Financial support for Guardians</strong><br />
    Should my Children require Guardianship, I wish that my Trustees consider
    appropriate financial support for the acting Guardian(s).
    <br /><br />
    In the absence of a better number, I would consider the sum which has the
    same value at my death as £[AMOUNT] has at the date of this Memorandum of
    Wishes, to be calculated by my Trustees having regard to the Retail Price
    Index in the month in which this, my Memorandum of Wishes, is executed and
    in the month of my death having regard to any re-basing of the Index which
    might have occurred in the meantime.
    <br /><br />
    This sum should be made payable to the acting Guardian(s) on an
    [MONTHLY/ANNUAL] basis and the sum should be payable per Child requiring
    Guardianship. On a Child reaching the age of 18, these [MONTHLY/ANNUAL]
    payments are to cease for that respective Child.
    <br /><br />
    I would ask my Trustees to make such payments once per [MONTH/ANNUM] and in
    the most tax efficient manner across all of my trusts.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
