<template>
  <div v-if="kintin">
    <CallScriptSection title="Gifts">
      <b class="mb-4 is-block">
        We've talked about the bulk of your estate and your main beneficiaries,
        but have you got anything more specific you want to give to anyone? This
        could be a specific item or a specific amount of money? Is there
        anything that springs to mind?
      </b>
      <GiftRecipientsContainer :kintin="kintin" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.2.1"
      />
      <br />
      <b>
        As an aside, if you choose to make gifts of money or property in life
        you should keep a record of these or there can be loads of paperwork
        trawling through bank statements. In your Kinvault there is actually a
        gift register specifically for this purpose, but we can come back to
        that later.
      </b>
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advGiftNotes"
      :has-continue="false"
    />
  </div>
</template>

<script lang="ts">
import GiftRecipientsContainer from "@/module/kinvault.kintin/component/address-book/GiftRecipientsContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GiftsTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
    ConfirmCheck,
    CallScriptSpecialistNotesSection,
    GiftRecipientsContainer,
  },
});
</script>
