<template>summary</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IntroducerFeePaymentRunDetailsSummaryRoute } from ".";

export default defineComponent({
  name: IntroducerFeePaymentRunDetailsSummaryRoute,
});
</script>
