<template>
  <Card :title="giftFor" :title-size="'is-4'" class="gift-recipient-summary">
    <DataListWidget
      no-card
      is-compact
      :details="[
        {
          label: 'From',
          value: sentBy,
          hide: null === sentBy,
        },
        {
          label: 'Gift Type',
          value: gift.type.text,
        },
        {
          label: 'Amount',
          value: gift.amount?.format,
          hide: !displayAmount,
        },
        {
          label: 'Organisation',
          value: gift.forPerson?.profile.organisationName,
          hide: [null, undefined].includes(
            gift.forPerson?.profile.organisationName as never,
          ),
        },
        {
          label: 'Charity',
          value: gift.charityName + ' (Charity)' + gift.charityNo,
          hide: !gift.charityName,
        },
        {
          label: 'Gift Property Address',
          value: gift.address?.summary,
          hide: [null, undefined].includes(gift.address?.summary as never),
        },
        {
          label: 'Gifting as Primary Residence',
          hide: !gift.asPrimaryResidence,
        },
        {
          label: 'Output Preference',
          value:
            gift.outputPreference === 'mow'
              ? 'Memorandum of Wishes'
              : gift.outputPreference === 'will'
                ? 'Will'
                : 'Unknown',
          hide: [null, undefined].includes(gift.outputPreference as never),
        },
        {
          label: 'If Predeceased, Gift to Pass to',
          value: gift.ifPredeceased?.text ?? {
            color: 'has-text-warning',
            text: 'Not set',
          },
        },
        {
          label: '-> ',
          value: {
            text:
              gift.reservePerson?.profile.fullName ??
              gift.reserveclassGiftType?.text,
            bold: false,
          },
          hide:
            null === gift.reservePerson && null === gift.reserveclassGiftType,
        },
        {
          label: 'Notes',
          valueOnly: gift.notes,
          hide: null === gift.notes,
        },
      ]"
    />
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit"
        color="is-plain"
        @click="$emit('edit', $event)"
      />
      <Button
        icon-left="Trash"
        aria-label="Delete"
        color="is-plain"
        @click="$emit('delete', $event)"
      />
      <slot name="buttons" />
    </template>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "GiftRecipientSummaryWrapper"
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Gift, Kintin, Option } from "@kinherit/sdk";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GiftRecipientSummary",
  props: {
    gift: {
      type: Gift as PropType<Gift>,
      required: true,
    },
    kintin: {
      type: Kintin as PropType<Kintin>,
      required: true,
    },
  },
  emits: ["edit", "delete"],
  components: { Card, DataListWidget, Button },
  computed: {
    giftFor() {
      const person = this.gift.forPerson;

      if (person?.profile.fullName) {
        const name = person.profile.fullName;

        if (null === name && null !== person.profile.knownAs) {
          return person.profile.knownAs;
        }

        return `${name}`;
      }

      if (this.gift.charityName) {
        return this.gift.charityName;
      }

      if (this.gift.classGiftType) {
        return `${this.gift.classGiftType.text} (Class gift)`;
      }

      return "Unknown";
    },
    sentBy() {
      if (this.gift.onSecondDeath) {
        return "Joint Gift";
      }
      if (this.gift.sentBy) {
        return this.gift.sentBy.profile.fullName;
      }

      return null;
    },
    displayAmount() {
      const cashGiftOption = Option.$findOneBy({
        group: "giftTypes",
        value: "fixedamount",
      });
      return this.gift.type?.value === cashGiftOption?.value;
    },
  },
});
</script>
