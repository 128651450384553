<template>
  <span v-if="data">
    <strong> Legacy - Shares </strong>
    <ol type="i">
      <li v-for="gift in data.sharesGifts" :key="gift.id">
        I give <span v-html="helpers.var(gift.notes)" /> held by me at the date
        of death
        <span v-if="gift.ifPredeceased">
          only if my
          <span
            v-if="data.person"
            v-html="
              helpers.var(helpers.formatRelationship(data.person, data.partner))
            "
          />
          has predeceased me
        </span>
        to <span v-html="helpers.var(helpers.formatRecipient(gift))" />
        <span v-html="helpers.var(helpers.formatPredeceased(gift))" />
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
