import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface UpdateBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

interface UpdateBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

export const UpdateBrandedKinvaultSubscriptionReferralCodeHandler = async (
  message: UpdateBrandedKinvaultSubscriptionReferralCodeMessage,
): Promise<UpdateBrandedKinvaultSubscriptionReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
    {
      brandedKinvaultSubscriptionReferralCode:
        message.brandedKinvaultSubscriptionReferralCode,
    },
  )
    .method("patch")

    .body({
      code: message.brandedKinvaultSubscriptionReferralCode.$data.code,
      name: message.brandedKinvaultSubscriptionReferralCode.$data.name,
    })
    .result();

  const brandedKinvaultSubscriptionReferralCode =
    BrandedKinvaultSubscriptionReferralCode.$inflate(
      response.brandedKinvaultSubscriptionReferralCode,
      undefined,
      response.$rootIds,
    ).first();

  if (!brandedKinvaultSubscriptionReferralCode) {
    throw new Error("Failed to create subscription referral code");
  }

  return {
    brandedKinvaultSubscriptionReferralCode,
  };
};
