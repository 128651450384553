import {
  Api,
  IntroducerCompany,
  IntroducerContract,
  Order,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";

export interface DownloadIntroducerRemittanceMessage {
  introducerCompany: IntroducerCompany | string;
  introducerName?: string;
  brand: "kinherit";
  introducerCompanyType: "agent" | "introducer";
  downloadType:
    | "comp-adv-cli-pro"
    | "comp-adv-cli"
    | "comp-adv"
    | "comp"
    | "flying-colours"
    | "client-by-adviser"
    | "client-only"
    | "amount-only"
    | "sort-refer";
  introducerContract: IntroducerContract | string;
  orders: Array<Order | string>;
  feesType: "introducer" | "agent" | "parentAgent";
}

interface DownloadIntroducerRemittanceResponse {
  [key: string]: never;
}

export const DownloadIntroducerRemittanceHandler = async (
  message: DownloadIntroducerRemittanceMessage,
): Promise<DownloadIntroducerRemittanceResponse> => {
  const remittanceType =
    message.feesType === "introducer"
      ? "Introducer"
      : message.feesType === "parentAgent"
        ? "PAgent"
        : "Agent";
  const fileName = `${
    new DateTime().formatYMD
  } ${remittanceType} Remittance - ${message.introducerName}.pdf`;

  await Api.resource("portal", `/v2/portal/introducer-fee/download`)
    .method("put")
    .body({
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
      introducerCompanyType: message.introducerCompanyType,
      brand: message.brand,
      introducerContract:
        "string" === typeof message.introducerContract
          ? message.introducerContract
          : message.introducerContract.id,
      orders: message.orders.map((order) =>
        "string" === typeof order ? order : order.id,
      ),
      feesType: message.feesType,
      downloadType: message.downloadType,
    })
    .download({
      encoding: "application/pdf",
      fileName: fileName,
    });

  return {};
};
