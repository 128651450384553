import { Singleton } from "@kinherit/orm";

export interface ActiveSessionData {
  username: string;
  token: string;
  mfaRequired: boolean;
  methods: Array<{
    type: "email" | "sms" | "totp";
    id: string;
    alias: string | null;
  }>;
  user: string;
}

export class ActiveSession extends Singleton<ActiveSessionData> {
  readonly $storage = "local";

  public get token(): string {
    return this.$data.token;
  }

  public get username(): string {
    return this.$data.username;
  }

  public get mfaRequired(): boolean {
    return this.$data.mfaRequired;
  }

  public get mfMethods(): Array<{
    type: "email" | "sms" | "totp";
    id: string;
    alias: string | null;
  }> {
    return this.$data.methods;
  }

  public get user(): string {
    return this.$data.user;
  }
}
