<template>
  <div class="dashboard-statistic-widget">
    <p class="dashboard-statistic-widget__value">
      {{ value }}
    </p>
    <span class="dashboard-statistic-widget__subvalue">
      {{ subValue }}
    </span>
    <p class="dashboard-statistic-widget__label">
      {{ label }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { TDashboardStatisticWidgetProps } from "./dashboard-statistic-widget";

export default defineComponent({
  name: "DashboardStatisticWidget",
  props: {
    label: {
      type: String as PropType<TDashboardStatisticWidgetProps["label"]>,
      required: true,
    },
    value: {
      type: [Number, String] as PropType<
        TDashboardStatisticWidgetProps["value"]
      >,
      required: true,
    },
    subValue: {
      type: [Number, String] as PropType<
        TDashboardStatisticWidgetProps["subValue"]
      >,
      required: false,
      default: undefined,
    },
    cssClass: {
      type: String as PropType<TDashboardStatisticWidgetProps["cssClass"]>,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-statistic-widget {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.3em;
  &__value {
    font-size: 1.6em;
    font-weight: 600;
    margin-bottom: 0.3em;
  }
  &__subvalue {
    font-size: 0.8em;
    font-weight: 600;
    display: block;
  }
  &__label {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.3em;
  }
  &.text-danger {
    &__value {
      color: $danger;
    }
    &__subvalue {
      color: $danger;
    }
    &__label {
      color: $danger;
    }
  }
}
</style>
