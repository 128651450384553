import { Api } from "@/service/api.service";
import { IntroducerNetwork } from "@kinherit/sdk";

export interface UpdateIntroducerNetworkMessage {
  introducerNetwork: IntroducerNetwork;
}

interface UpdateIntroducerNetworkResponse {
  introducerNetworks: Array<IntroducerNetwork>;
}

export const UpdateIntroducerNetworkHandler = async (
  message: UpdateIntroducerNetworkMessage,
): Promise<UpdateIntroducerNetworkResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-network/{introducerNetwork}",
    {
      introducerNetwork: message.introducerNetwork,
    },
  )
    .method("patch")
    .body({
      name: message.introducerNetwork.$data.name,
    })

    .result();

  return {
    introducerNetworks: IntroducerNetwork.$inflate(
      response.introducerNetwork,
      undefined,
      response.$rootIds,
    ),
  };
};
