<template>
  <div v-if="data">
    <p>
      <strong>Substitute Trustee</strong><br />
      In the event of an existing appointed Trustee being unable or unwilling to
      act as Trustee, please consider appointing:
    </p>
    <ol type="i">
      <li v-for="trustee in data.reserveTrustees" :key="trustee.id">
        <span v-html="helpers.var(helpers.formatOfficer(trustee.person))" />
      </li>
    </ol>
    <p>
      to act as Trustees along with the existing Trustee(s) appointed at that
      time provided such new Trustees have attained the age of 30 years.
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
