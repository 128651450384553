<template>
  <CallScriptSection title="Storage">
    <mark>@todo</mark>
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "StorageTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
  },
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
});
</script>
