import { Api } from "@/service/api.service";
import { CreditNote } from "@kinherit/sdk";

export interface DeleteCreditNoteMessage {
  creditNote: CreditNote;
}

export interface DeleteCreditNoteResponse {
  [key: string]: never;
}

export const DeleteCreditNoteHandler = async (
  message: DeleteCreditNoteMessage,
): Promise<DeleteCreditNoteResponse> => {
  await Api.resource("portal", "/v2/portal/credit-note/{creditNote}", {
    creditNote: message.creditNote,
  })
    .method("delete")
    .body({})

    .result();

  message.creditNote.$delete();

  return {};
};
