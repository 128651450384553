import { Api } from "@/service/api.service";
import { KnowledgeBaseDocument } from "@kinherit/sdk";

interface CreateKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

interface CreateKnowledgeBaseDocumentResponse {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

export const CreateKnowledgeBaseDocumentHandler = async (
  message: CreateKnowledgeBaseDocumentMessage,
): Promise<CreateKnowledgeBaseDocumentResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-document",
  )
    .method("post")

    .body({
      title: message.knowledgeBaseDocument.title,
      content: message.knowledgeBaseDocument.content,
    })
    .result();

  const newDocumentId = response.knowledgeBaseDocument?.first()?.id;

  if (!newDocumentId) {
    throw new Error("Could not create knowledge base document");
  }

  await message.knowledgeBaseDocument.categories.forEachAsync(
    async (category) => {
      await Api.resource(
        "portal",
        `/v2/portal/knowledge-base-category/{knowledgeBaseCategory}/documents/{knowledgeBaseDocument}/add`,
        {
          knowledgeBaseCategory: category.id,
          knowledgeBaseDocument: newDocumentId,
        },
      )
        .method("post")

        .result();
    },
  );

  const knowledgeBaseDocument = KnowledgeBaseDocument.$inflate(
    response.knowledgeBaseDocument,
  ).first();

  if (!knowledgeBaseDocument) {
    throw new Error("Failed to create knowledge base document");
  }

  message.knowledgeBaseDocument.$delete();

  return {
    knowledgeBaseDocument,
  };
};
