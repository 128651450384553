import { Api } from "@/service/api.service";
import { IntroducerContract, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerContractMessage {
  query?: QueryMask<typeof IntroducerContract>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerContractResponse {
  introducerContracts: Array<IntroducerContract>;
}

export const ReadIntroducerContractHandler = async (
  message: ReadIntroducerContractMessage,
): Promise<ReadIntroducerContractResponse> => {
  const request = Api.resource("core", "/v2/core/select/introducer-contract")
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(IntroducerContract).where(message.query ?? {});

  const response = await request.result();

  return {
    introducerContracts: IntroducerContract.$inflate(
      response.introducerContract,
      undefined,
      response.$rootIds,
    ),
  };
};
