import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptClientSummaryRoute =
  "KintinDetailsCallScriptClientSummary";
export const KintinDetailsCallScriptClientSummaryURI =
  "/kinvault/:kintin/call-script/client-summary";
export type KintinDetailsCallScriptClientSummaryParams = RouteParamObject<
  typeof KintinDetailsCallScriptClientSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptClientSummaryRoute,
    path: KintinDetailsCallScriptClientSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptClientSummary" */ "./KinvaultKintinDetails.CallScript.ClientSummary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Client Summary",
          route: { name: KintinDetailsCallScriptClientSummaryRoute, params },
        },
      ],
    },
  },
];
