import { Api } from "@/service/api.service";
import { Guardian, Kintin } from "@kinherit/sdk";

interface CreateGuardianMessage {
  kintin: string | Kintin;
  guardian: Guardian;
}

interface CreateGuardianResponse {
  guardian: Guardian;
}

export const CreateGuardianHandler = async (
  message: CreateGuardianMessage,
): Promise<CreateGuardianResponse> => {
  const { guardian: guardianData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-guardian/{kintin}/person/guardian",
    {
      kintin: message.kintin,
    },
  )
    .method("post")

    .body({
      forPerson: message.guardian.$data.forPerson,
      person: message.guardian.$data.person,
      isReserve: message.guardian.$data.isReserve ?? false,
      includePartner: message.guardian.$data.includePartner ?? false,
      notes: message.guardian.$data.notes,
      kintin: message.kintin.toString(),
    })
    .result();

  const guardian = Guardian.$inflate(guardianData).first();

  if (!guardian) {
    throw new Error("Failed to create guardian");
  }

  message.guardian.$delete();

  return { guardian };
};
