import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { PhysicalStorageFile } from "@kinherit/sdk";

export const KinvaultStorageRequestMasterListRoute =
  "KinvaultStorageRequestMasterList";

export const KinvaultStorageRequestDetailsRoute =
  "KinvaultStorageRequestDetailsList";
const KinvaultStorageRequestDetailsURI = "/storage-request/:id";
export type KinvaultStorageRequestDetailsParams = RouteParamObject<
  typeof KinvaultStorageRequestDetailsURI
>;

const KinvaultStorageRequestMasterListBreadCrumb = {
  text: "Storage Requests",
  route: { name: KinvaultStorageRequestMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultStorageRequestMasterListRoute,
    path: "/storage-requests",
    component: () =>
      import(
        /* webpackChunkName: "StorageRequestMasterList" */ "./StorageRequestMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KinvaultStorageRequestMasterListBreadCrumb],
    },
  },
  {
    name: KinvaultStorageRequestDetailsRoute,
    path: KinvaultStorageRequestDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "StorageRequestDetails" */ "./StorageRequestDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => {
        const storageRequest = PhysicalStorageFile.$findOne(params.id);

        return [
          KinvaultStorageRequestMasterListBreadCrumb,
          {
            text: `${
              storageRequest?.subscription?.kintin.friendlyName
                ? `${storageRequest?.subscription?.kintin.friendlyName} (${storageRequest?.title})`
                : (storageRequest?.title ?? "Storage Request")
            }`,
            route: { name: KinvaultStorageRequestDetailsRoute, params },
          },
        ];
      },
    },
  },
];
