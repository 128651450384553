import { KinvaultKintinDetailsOrdersBreadCrumb } from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const KintinDetailsOrderRoute = "KintinDetailsOrder";
export const KintinDetailsOrderURI = "/kinvault/:kintin/documents/orders";
export type KintinDetailsOrderParams = RouteParamObject<
  typeof KintinDetailsOrderURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsOrderRoute,
    path: KintinDetailsOrderURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsOrder" */ "./KintinDetails.Orders.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsOrdersBreadCrumb,
    },
  },
];
