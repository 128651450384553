import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerFeePaymentItem } from "@kinherit/sdk/index";

export const PatchIntroducerFeePaymentItemForm = (
  introducerFeePaymentItem: IntroducerFeePaymentItem,
) =>
  defineForm({
    name: "patch-introducer-fee-payment-item",
    data: () => introducerFeePaymentItem,
    formAreas: (data) => [
      defineFormArea({
        name: "details",
        data,
        template: GridLayout([["name"], ["amount"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
              },
              models: {
                value: "name",
              },
            }),
          ],
          amount: [
            FormCurrencyField({
              props: {
                label: "Amount",
              },
              models: {
                value: "amount",
              },
            }),
          ],
        }),
      }),
    ],
  });
