<template>
  <Card title="Email Campaign" class="email-campaign-details-summary"> </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailCampaignDetailsSummaryWrapper",
  "route": "EmailCampaignDetailsSummary",
  "selector": ".email-campaign-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { EmailCampaignDetailsSummaryRoute } from ".";

export default defineComponent({
  name: EmailCampaignDetailsSummaryRoute,
  components: {
    Card,
  },
  mixins: [EmailCampaignDetailsMixin],
});
</script>
