import { PersonField, SharedFormProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { In } from "@kinherit/orm";
import { Exclusion, Kintin } from "@kinherit/sdk";

export const UpdateExclusionForm = ({
  data,
  kintin,
}: {
  data: Exclusion;
  kintin: Kintin;
}) => {
  return defineForm({
    name: "update-exclusion-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-exclusion-form-area",
        data,
        template: GridLayout([
          ["excludedBy"],
          ["who"],
          ["why"],
          ["relationship"],
        ]),
        components: () => ({
          excludedBy: [
            PersonField({
              props: {
                label: "Whose estate will this exclusion apply to?",
                vModel: "excludedBy",
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          who: [
            FormTextField({
              props: {
                label: "What is the Name of the person you wish to Exclude?",
              },
              models: {
                value: "who",
              },
            }),
          ],
          why: [
            FormTextField({
              props: {
                label: "Relationship",
                message: "i.e, Ex-Partner",
              },
              models: {
                value: "relationship",
              },
            }),
          ],
          relationship: [
            FormTextField({
              props: {
                label: "Why do you wish to exclude them?",
                message: "i.e, Ex-Partner",
              },
              models: {
                value: "why",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
