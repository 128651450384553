<template>
  <Card title="Mock Emails">
    <div class="columns">
      <div class="column">
        <EmailField v-model:value="mockEmails.emailAddress" label="Email" />
      </div>
      <div class="column">
        <SelectField
          v-model:value="mockEmails.template"
          label="Template"
          :options="{
            'document-storage-instructions': 'Document Storage Instructions',
            'branded-kinvault-invitation': 'Organisation Invitation',
            'portal-notification': 'Portal Notification',
            'external-lead-notification': 'External Lead Notification',
            'multi-factor-login': 'Multi Factor Login',
            'multi-factor-setup': 'Multi Factor Setup',
            'order-payment': 'Order Payment',
            'password-reset': 'Password Reset',
            'email-campaign': 'Email Campaign',
            'auto-chase': 'Auto Chasers',
            'annual-account-holder-reminder':
              'Kinvault Annual Account Holder Reminder',
            'annual-first-lite-trusted-people-reminder':
              'Kinvault Lite First Trusted People',
            'annual-trusted-people-reminder':
              'Kinvault Annual Trusted People Reminder',
          }"
        ></SelectField>
      </div>
    </div>
    <Button
      text="Send"
      :disabled="!mockEmails.emailAddress || !mockEmails.template"
      @click="sendMockEmail"
    />
  </Card>
  <Card title="Test Contact Endpoint">
    <Button text="Test Contact Endpoint" @click="testContactEndpoint" />
  </Card>
</template>

<script lang="ts">
import { SettingsEmailRoute } from "@/module/settings/page";
import Button from "@kinherit/framework/component.input/button";
import EmailField from "@kinherit/framework/component.input/email-field";
import SelectField from "@kinherit/framework/component.input/select-field";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";

export default defineComponent({
  name: SettingsEmailRoute,
  components: {
    Card,
    Button,
    EmailField,
    SelectField,
  },
  data: () => ({
    mockEmails: {
      emailAddress: "",
      template: null as
        | null
        | "document-storage-instructions"
        | "branded-kinvault-invitation"
        | "portal-notification"
        | "external-lead-notification"
        | "multi-factor-login"
        | "multi-factor-setup"
        | "order-payment"
        | "password-reset"
        | "email-campaign"
        | "auto-chase"
        | "annual-account-holder-reminder"
        | "annual-first-lite-trusted-people-reminder"
        | "annual-trusted-people-reminder",
    },
  }),
  methods: {
    async sendMockEmail() {
      if (!this.mockEmails.emailAddress || !this.mockEmails.template) {
        return;
      }
      await window.Kernel.ActionBus.settings.testEmail({
        emailAddress: this.mockEmails.emailAddress,
        template: this.mockEmails.template,
      });
    },
    async testContactEndpoint() {
      await window.Kernel.ActionBus.settings.testContactEndpoint({
        name: "Test One",
        email: "test@test.com",
        tel: "000000",
        message: "test message",
        referralcode: "test-company",
      });
    },
  },
});
</script>
