import { Api } from "@/service/api.service";
import { User } from "@kinherit/sdk";

interface DeleteUserMessage {
  user: User;
}

interface DeleteUserResponse {
  [key: string]: never;
}

// export class DeleteUserHandler extends ActionBase {
//   constructor(private message: DeleteUserMessage) {
//     super();
//   }

//   public async execute(): Promise<DeleteUserResponse> {
export const DeleteUserHandler = async (
  message: DeleteUserMessage,
): Promise<DeleteUserResponse> => {
  await Api.resource("portal", "/v2/portal/user/{user}", message)
    .method("delete")

    .result();

  message.user.$delete();

  return {};
};
