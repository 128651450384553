import { RouteInterface } from "@kinherit/framework/core/route-config";
import { EmailCampaignsMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as EmailCampaignDetailsRoutes } from "./details";

export const EmailCampaignsMasterListRoute = "EmailCampaignsMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: EmailCampaignsMasterListRoute,
    path: "/email-campaigns",
    component: () =>
      import(
        /* webpackChunkName: "EmailCampaignsMasterList" */ "./EmailCampaignsMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [EmailCampaignsMasterListBreadCrumb],
    },
  },
  ...EmailCampaignDetailsRoutes,
];
