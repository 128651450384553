import { Api } from "@/service/api.service";
import { IPerson, Person, Profile, QueryMask } from "@kinherit/sdk";

export interface ReadPersonMessage {
  query?: QueryMask<typeof Person>;
  sort?: {
    by: keyof IPerson;
    direction: "asc" | "desc";
  };
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

export interface ReadPersonResponse {
  people: Array<Person>;
  profiles: Array<Profile>;
}

export const ReadPersonHandler = async (
  message: ReadPersonMessage,
): Promise<ReadPersonResponse> => {
  const request = Api.resource("core", "/v2/core/select/person")
    .method("get")

    .paginate(message.pagination ?? false)
    .sort(message.sort);

  request.buildQuery(Person).where(message.query ?? {});

  const response = await request.result();

  return {
    people: Person.$inflate(response.person, undefined, response.$rootIds),
    profiles: Profile.$inflate(response.profile),
  };
};
