import { Api } from "@/service/api.service";
import { FileLog, KnowledgeBaseDocument } from "@kinherit/sdk";

interface UpdateKnowledgeBaseDocumentFileLogMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
  file: FileLog;
}

interface UpdateKnowledgeBaseDocumentFileLogResponse {}

export const UpdateKnowledgeBaseDocumentFileLogHandler = async (
  message: UpdateKnowledgeBaseDocumentFileLogMessage,
): Promise<UpdateKnowledgeBaseDocumentFileLogResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}/document/{fileLog}",
    {
      knowledgeBaseDocument: message.knowledgeBaseDocument.id,
      fileLog: message.file.id,
    },
  )
    .method("patch")
    .body({
      fileName: message.file.fileName,
    })
    .result();

  return {};
};
