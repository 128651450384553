import { Api } from "@/service/api.service";
import { KintinAccess } from "@kinherit/sdk";

interface DeleteUserKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface DeleteUserKintinAccessResponse {
  [key: string]: never;
}

export const DeleteUserKintinAccessHandler = async (
  message: DeleteUserKintinAccessMessage,
): Promise<DeleteUserKintinAccessResponse> => {
  await Api.resource("portal", "/v2/portal/kintin-user-access/{kintinAccess}", {
    kintinAccess: message.kintinAccess,
  })
    .method("delete")
    .result();

  message.kintinAccess.$delete();

  return {};
};
