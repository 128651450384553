<template>
  <div v-if="data">
    <strong>
      Conditional Gift at Specified Age of
      <span v-html="helpers.var(age)" /> Years
    </strong>
    <p>
      Any interest left in this my Will to a beneficiary shall be conditional on
      them attaining the age of
      <span v-html="helpers.var(age)" />
      years and shall carry the intermediate interest until that age and I
      appoint
      <span v-html="helpers.var(renderMainTrustees)" />
      <span v-if="data.trustees.length === 0"> my Executors </span>
      (my 'Children's Trust Trustees') to be the trustees of any interest left
      in this my Will to a beneficiary which is conditional on them attaining
      the age of <span v-html="helpers.var(age)" /> years and I give the power
      to my Children's Trust Trustees in their absolute discretion to advance
      part or all of such entitlement which my Children's Trust Trustees deem to
      be appropriate <br /><br />
      <span v-html="helpers.var(renderTrusteeRobinHoodClauseTrustees)" />
      <span v-if="data.reserveTrustees.isNotEmpty()">
        <br /><br />
        If further trustees are required then I appoint
        <span v-html="helpers.var(renderReserveTrustees)" />
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    age(): number {
      return this.data?.beneficiaryAge ?? 18;
    },
    renderMainTrustees(): string {
      if (!this.data) {
        return "";
      }

      return this.data.trustees
        .filter((trustee) => trustee.isReserve === false)
        .map((trustee) => this.helpers.formatOfficer(trustee.person, false))
        .join(" and ");
    },
    renderReserveTrustees(): string {
      if (!this.data) {
        return "";
      }

      return this.data.reserveTrustees
        .map((trustee) => this.helpers.formatOfficer(trustee.person, false))
        .join(" and ");
    },
    renderTrusteeRobinHoodClauseTrustees(): string {
      if (!this.data) {
        return "";
      }

      const protrusteeOption = this.data.protrusteeOption;

      if (protrusteeOption === 1) {
        return `In the event that my existing Trustee(s) wish to appoint an additional Trustee that is a professional (being, ordinarily engaged in providing professional Trustee services) to help them manage the Trusts, then I wish that they give consideration to any professional Trustees recommended by Kinherit Ltd.
      `;
      }
      if (protrusteeOption === 0) {
        return `I would wish that my existing Trustee(s) appoint an additional Trustee to act alongside them to manage the practical and legal realities of managing a Trust in a way that protects the beneficiaries’ assets in the long term, and in the most tax efficient manner. Such additional Trustee should be a suitably qualified trust specialist (individual or firm) ordinarily engaged in providing professional Trustee services. I would request my existing Trustees consider any professional Trustees recommended by Kinherit Ltd as part of this process.`;
      }
      if (protrusteeOption === 2) {
        return `I explicitly request that my existing Trustee(s) appoint an additional Trustee to act alongside them to manage the practical and legal realities of managing a Trust in a way that protects the beneficiaries’ assets in the long term, and in the most tax efficient manner. Such additional Trustee should be a suitably qualified trust specialist (individual or firm) ordinarily engaged in providing professional Trustee services, and their appointment should occur prior to or contemporaneously with the receipt by the Trust of moneys or assets of my estate to the extent reasonably practicable. I would request my existing Trustees consider any professional Trustees recommended by Kinherit Ltd as part of this process.
      `;
      }
      return ``;
    },
  },
});
</script>
