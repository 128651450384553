import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { EmailCampaignDetailsBreadCrumb } from "../../breadcrumbs";
import { Routes as LogsRoutes } from "./logs";
import { Routes as MembersRoutes } from "./members";
import { Routes as SummaryRoutes } from "./summary";

export const EmailCampaignDetailsRoute = "EmailCampaignDetails";
export const EmailCampaignDetailsURI = "/admin.email-campaign/:emailCampaign";
export type EmailCampaignDetailsParams = RouteParamObject<
  typeof EmailCampaignDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: EmailCampaignDetailsRoute,
    path: EmailCampaignDetailsURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "EmailCampaignDetails" */ "./EmailCampaignDetails.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "EmailCampaignDetailsSidebar" */ "./EmailCampaignDetails.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        EmailCampaignDetailsBreadCrumb(params as EmailCampaignDetailsParams),
    },
    children: [...SummaryRoutes, ...MembersRoutes, ...LogsRoutes],
  },
];
