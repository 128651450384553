import { Api } from "@/service/api.service";
import { Exclusion } from "@kinherit/sdk";

interface UpdateKintinExclusionMessage {
  exclusion: Exclusion;
}

interface UpdateKintinExclusionResponse {
  exclusion: Exclusion;
}

export const UpdateKintinExclusionHandler = async (
  message: UpdateKintinExclusionMessage,
): Promise<UpdateKintinExclusionResponse> => {
  const { exclusion: exclusionData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-exclusion/{kintin}/exclusion/{exclusion}",
    {
      kintin: message.exclusion.kintin.id,
      exclusion: message.exclusion.id,
    },
  )
    .method("patch")

    .body({
      kintin: message.exclusion.$data.kintin,
      excludedBy: message.exclusion.$data.excludedBy,
      relationship: message.exclusion.$data.relationship,
      who: message.exclusion.$data.who,
      why: message.exclusion.$data.why,
    })
    .result();

  const exclusion = Exclusion.$inflate(exclusionData).first();

  if (!exclusion) {
    throw new Error("Failed to update exclusion");
  }

  return { exclusion };
};
