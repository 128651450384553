import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Tag } from "@kinherit/sdk";

export const UpdateTagForm = (
  data: Tag,
): FormBuilder<() => Tag, "update-tag-form-area"> =>
  defineForm({
    name: "update-tag-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-tag-form-area",
        data,
        template: GridLayout([["name"], ["description"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
          description: [
            FormTextField({
              props: {
                label: "Description",
                isTextArea: true,
              },
              models: {
                value: "description",
              },
            }),
          ],
        }),
      }),
    ],
  });
