<template>
  <div class="person-list">
    <template v-if="!hideSummary">
      <PersonSummary
        v-for="person in people"
        :key="`person-${person.id}`"
        :kintin="kintin"
        :person="person"
        :hide-summary="hideSummary"
        @edit="edit(person)"
        @delete="remove(person)"
      />
    </template>
    <template v-else>
      <Table
        is-narrow
        is-hoverable
        is-striped
        :columns="columns"
        :rows="computedData"
      >
        <template #name="{ row }: { row: Person }">
          <span v-if="row.profile.lastName || row.profile.firstName">
            {{ row.profile.lastName?.toUpperCase() }}
            {{ row.profile.lastName && row.profile.firstName ? ", " : "" }}
            {{ row.profile.firstName }}
          </span>
          <span
            v-if="
              (null !== row.profile.lastName ||
                null !== row.profile.firstName) &&
              row.profile.organisationName
            "
          >
            -
          </span>
          <span v-if="row.profile.organisationName">
            {{ row.profile.organisationName.toUpperCase() }}
          </span>
          <span v-if="row.profile.organisationNumber">
            ({{ row.profile.organisationNumber.toUpperCase() }})
          </span>
          <small class="is-block has-text-grey-opaque has-text-weight-light">
            ID: {{ row.id }}
          </small>
          <small class="is-block has-text-grey-opaque has-text-weight-light">
            PROFILE: {{ row.profile.id }}
          </small>
        </template>
        <template #trusted="{ row }: { row: Person }">
          <Icon
            v-if="row.isTrusted"
            icon="Success"
            size="is-small"
            class="is-success"
          />
          <Icon v-else icon="Cross" size="is-small" class="is-warning" />
        </template>
        <template #notifications="{ row }: { row: Person }">
          <Icon
            v-if="row.notifications"
            icon="Success"
            size="is-small"
            class="is-success"
          />
          <Icon v-else icon="Cross" size="is-small" class="is-warning" />
        </template>
        <template #actions="{ row }: { row: Person }">
          <Button
            is-small
            is-primary
            icon-left="Edit"
            tooltip="Edit"
            @click="edit(row)"
          />
          <Button
            v-if="hasDelete(row)"
            is-small
            is-primary
            icon-left="Trash"
            tooltip="Delete"
            @click="remove(row)"
          />
        </template>
      </Table>
    </template>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "PersonListWrapper",
  "imports": {
    "PersonSummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/PersonSummary.test"
  },
  "methods": {
    "people": {
      "type": "to-many",
      "selector": ".person-summary",
      "wrapper": "PersonSummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import PersonSummary from "@/module/kinvault.kintin/component/summary-cards/PersonSummary.vue";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import Icon from "@kinherit/framework/component.display/icon";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PersonList",
  components: { PersonSummary, Button, Table, Icon },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    people: {
      type: Array as () => Person[],
      required: true,
    },
    hideSummary: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data: () => ({
    columns: [
      {
        title: "Name",
        class: "has-text-weight-bold",
        slot: "name",
      },
      {
        title: "Trusted",
        slot: "trusted",
      },
      {
        title: "Notifications",
        slot: "notifications",
      },
      {
        title: "Actions",
        slot: "actions",
      },
    ] as Array<TableColumn>,
  }),
  computed: {
    computedData() {
      return this.people;
    },
  },
  methods: {
    hasDelete(person: Person): boolean {
      if (
        this.kintin.$data.primaryPerson === person.id ||
        this.kintin.$data.secondaryPerson === person.id
      ) {
        return false;
      }

      return true;
    },
    async edit(person: Person): Promise<void> {
      try {
        await UpdatePersonForm({
          person: person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Person",
          },
        });
      } catch {
        person.profile.$restore();
        person.$restore();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/update", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.update({
        person: person,
        kintin: this.kintin,
      });

      // await window.Kernel.ActionBus.execute("kinvault/person/profile/update", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.updateProfile({
        person: person,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
    async remove(person: Person): Promise<void> {
      const roles = {
        attorney: person.attorneyFor.isNotEmpty(),
        adviser: person.adviserFor.isNotEmpty(),
        beneficiary: person.beneficiaryFor.isNotEmpty(),
        executor: person.executorFor.isNotEmpty(),
        giftRecipient: person.receivingGifts.isNotEmpty(),
        guardian: person.guardianFor.isNotEmpty(),
        trustee: person.trusteeFor.isNotEmpty(),
      };

      let html = `<p>Are you sure you want to remove this person?<p>`;

      if (Object.values(roles).includes(true)) {
        html += `<p>The following roles will be removed:</p>`;
        html += `<ul>`;
        Object.keys(roles).forEach((_role) => {
          const role = _role as keyof typeof roles;
          if (roles[role]) {
            html += `<li>${role}</li>`;
          }
        });
        html += `</ul>`;
      }

      await DangerDialog({
        dialog: {
          title: "Delete Person",
          html,
        },
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/delete", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.delete({
        person: person,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
