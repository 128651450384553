import { Api } from "@kinherit/sdk";

export const TestEmailHandler = async (message: {
  emailAddress: string;
  template:
    | "document-storage-instructions"
    | "branded-kinvault-invitation"
    | "portal-notification"
    | "external-lead-notification"
    | "multi-factor-login"
    | "multi-factor-setup"
    | "order-payment"
    | "password-reset"
    | "email-campaign"
    | "auto-chase"
    | "annual-account-holder-reminder"
    | "annual-first-lite-trusted-people-reminder"
    | "annual-trusted-people-reminder";
}): Promise<void> => {
  await Api.resource("portal", `/v2/portal/email-test/${message.template}`)
    .method("put")
    .params({
      emailAddress: message.emailAddress,
    })
    .result();
};
