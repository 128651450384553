<template>
  <DataListWidget
    v-if="introducerCompany"
    :details="introducerCompanyDetails"
    title="About"
  >
    <template #below>
      <p class="has-text-right has-text-grey">
        Added {{ introducerCompany.createdAt.formatDate }}
      </p>
    </template>
  </DataListWidget>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsSidebarWrapper",
  "imports": {
    "DataListWidgetWrapper": "@/shared/component/widget/DataListWidget.test"
  },
  "methods": {
    "about": {
      "type": "to-one",
      "selector": "",
      "wrapper": "DataListWidgetWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsParams,
  IntroducerCompanyDetailsRoute,
} from ".";
import { IntroducerCompanyMasterListRoute } from "..";
import { ReadIntroducerCompanyForm } from "../../form/read-introducer-company.form";
import { IntroducerCompanyDetailsMixin } from "../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: "IntroducerCompanyDetailsSidebar",
  mixins: [IntroducerCompanyDetailsMixin],
  components: {
    DataListWidget,
  },
  computed: {
    introducerCompanyDetails(): Array<DataListWidgetDetail> {
      if (!this.introducerCompany) {
        return [];
      }

      return [
        {
          label: "Updated",
          value: this.introducerCompany.updatedAt?.formatDate,
          hide: !this.introducerCompany.updatedAt,
        },
        {
          label: "Sales Team",
          value: {
            text:
              this.introducerCompany.seniorAdviser?.profile.fullName ?? "None",
            color: this.introducerCompany.seniorAdviser
              ? "has-text-plain"
              : "has-text-danger",
          },
        },
        {
          label: "Estate Planner",
          value: {
            text: this.introducerCompany.assignedTo?.profile.fullName ?? "None",
            color: this.introducerCompany.assignedTo
              ? "has-text-plain"
              : "has-text-danger",
          },
        },
        {
          label: "Legal Assistant",
          value: {
            text:
              this.introducerCompany.defaultLegalAssistant?.profile.fullName ??
              "None",
            color: this.introducerCompany.defaultLegalAssistant
              ? "has-text-plain"
              : "has-text-danger",
          },
        },
        {
          label: "Network",
          value: {
            text: this.introducerCompany.network?.name ?? "None",
            to: this.introducerCompany.network
              ? {
                  name: IntroducerCompanyMasterListRoute,
                  query: ReadIntroducerCompanyForm().createState({
                    network: this.introducerCompany.network.id
                      ? [this.introducerCompany.network.id]
                      : undefined,
                  }),
                }
              : undefined,
          },
        },
        {
          label: "Agent Introducer",
          value: {
            text:
              this.introducerCompany.introducedBy?.profile.fullName ?? "None",
            color: this.introducerCompany.introducedBy?.profile.fullName
              ? "has-text-plain"
              : "has-text-danger",
            to: this.introducerCompany.introducedBy
              ? this.introducerCompany.introducedBy && {
                  name: IntroducerCompanyDetailsRoute,
                  params: {
                    introducerCompany: this.introducerCompany.introducedBy.id,
                  } as IntroducerCompanyDetailsParams,
                }
              : undefined,
          },
        },
        {
          label: "Partner Portal",
          value: {
            text: this.introducerCompany.companyPartnerPortalPermissions
              ?.allowPartnerPortal
              ? "Enabled"
              : "Disabled",
            color: this.introducerCompany.companyPartnerPortalPermissions
              ?.allowPartnerPortal
              ? "has-text-success"
              : "has-text-danger",
          },
        },
        {
          label: "Tags",
          value: this.introducerCompany.tags
            .map((v) => v.name)
            .sort()
            .join(", "),
          hide: 0 === this.introducerCompany.tags.length,
        },
        {
          valueOnly: {
            html: this.introducerCompany.description,
            align: "has-text-centered" as const,
          },
          hide: null === this.introducerCompany.description,
          break: !!this.introducerCompany.description,
        },
      ];
    },
  },
  methods: {},
});
</script>
