import { Api } from "@/service/api.service";
import { Between, Equal, FilterModelInternals, In, Like } from "@kinherit/orm";
import {
  AccountReferral,
  AccountReferralCode,
  DeepPartialArrayApiQuery,
  IKintin,
  Kintin,
  Profile,
  User,
} from "@kinherit/sdk";
import { DeepPartialObjetApiQuery } from "@kinherit/sdk/type/query";
import { DateTime } from "@kinherit/ts-common/index";

export interface ReadKinvaultMessage {
  search?: string;
  created?: null | [number, number];
  status: Array<string>;
  stage: Array<string>;
  assignedUser: Array<string>;
  tags: Array<string>;
  brandedKinvault?: Array<string>;
  dueBy?: null | "today" | "tomorrow" | "overdue";
  referral?: Array<string>;
  process?: Array<string>;
  isActive?: null | boolean;
  isEscalated?: null | boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKintin;
    direction: "asc" | "desc";
  };
}

interface ReadKinvaultResponse {
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  referrals: Array<AccountReferral>;
  referralCodes: Array<AccountReferralCode>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKintinHandler = async (
  message: ReadKinvaultMessage,
): Promise<ReadKinvaultResponse> => {
  // NB Duplicate logic from lead
  const { dueBy } = message;

  const today = new DateTime();

  const todayDateRange: [number, number] = [
    today.setTime(0, 0, 0, 0).timestamp,
    today.setTime(23, 59, 59, 999).timestamp,
  ];

  const tomorrow = new DateTime().add(1, "day");

  const tomorrowDateRange: [number, number] = [
    tomorrow.setTime(0, 0, 0, 0).timestamp,
    tomorrow.setTime(23, 59, 59, 999).timestamp,
  ];
  const yesterday = new DateTime().sub(1, "day");

  const overdueDateRange: [number, number] = [
    0,
    yesterday.setTime(23, 59, 59, 999).timestamp,
  ];

  const nextActionAtDateRange =
    dueBy === "today"
      ? todayDateRange
      : dueBy === "tomorrow"
        ? tomorrowDateRange
        : dueBy === "overdue"
          ? overdueDateRange
          : null;

  const request = Api.resource("portal", "/v2/portal/kintin")
    .method("get")

    .paginate({
      currentPage: message.pagination?.currentPage ?? 1,
      perPage: message.pagination?.perPage ?? 10,
    })
    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "desc",
    });

  const sharedCondition: DeepPartialObjetApiQuery<
    FilterModelInternals<Kintin>
  > = {
    referral: {
      nextActionAt: Between(nextActionAtDateRange),
      referralCode: {
        id: In(message.referral),
      },
    },
    status: {
      id: In(message.status),
    },
    stage: {
      id: In(message.stage),
    },
    tags: {
      id: In(message.tags),
    },
    process: {
      id: In(message.process),
    },
    assignedUsers: {
      user: {
        brandedKinvaultMemberships: {
          brandedKinvault: {
            id: In(message.brandedKinvault),
          },
        },
      },
    },
    isActive: Equal(message.isActive),
    isEscalated: Equal(message.isEscalated),
    createdAt: Between(message.created),
  };

  const uniqueConditions: DeepPartialArrayApiQuery<
    FilterModelInternals<Kintin>
  > = [];

  if ((message.assignedUser.length ?? 0) > 0) {
    uniqueConditions.push({
      estatePlanners: {
        id: In(message.assignedUser),
      },
      ...sharedCondition,
    });

    uniqueConditions.push({
      legalAssistants: {
        id: In(message.assignedUser),
      },
      ...sharedCondition,
    });
  }

  if (message.search) {
    uniqueConditions.push({
      friendlyName: Like(message.search),
      ...sharedCondition,
    });

    uniqueConditions.push({
      ref: Like(message.search),
      ...sharedCondition,
    });

    uniqueConditions.push({
      primaryPerson: {
        profile: {
          emails: {
            email: Like(message.search),
          },
        },
      },
      ...sharedCondition,
    });

    uniqueConditions.push({
      secondaryPerson: {
        profile: {
          emails: {
            email: Like(message.search),
          },
        },
      },
      ...sharedCondition,
    });
  }

  const query = request.buildQuery(Kintin);

  if (uniqueConditions.length > 0) {
    query.where(uniqueConditions);
  } else {
    query.where(sharedCondition);
  }

  const response = await request.result();

  return {
    profiles: Profile.$inflate(response.profile),
    referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
    referrals: AccountReferral.$inflate(response.accountReferral),
    users: User.$inflate(response.user),
    kintins: Kintin.$inflate(response.kintin, message.sort, response.$rootIds),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
