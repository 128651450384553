import { Api } from "@/service/api.service";
import { EmailCampaign } from "@kinherit/sdk";

export interface DeleteEmailCampaignMessage {
  emailCampaign: EmailCampaign;
}

export interface DeleteEmailCampaignResponse {
  [key: string]: never;
}

export const DeleteEmailCampaignHandler = async (
  message: DeleteEmailCampaignMessage,
): Promise<DeleteEmailCampaignResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/email-campaign/{emailCampaign}",
    message,
  )
    .method("delete")

    .result();

  message.emailCampaign.$delete();

  return {};
};
