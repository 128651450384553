import { Api } from "@/service/api.service";
import { IntroducerContact } from "@kinherit/sdk";

interface DeleteIntroducerContactMessage {
  introducerContact: string | IntroducerContact;
}

interface DeleteIntroducerContactResponse {
  [key: string]: never;
}

export const DeleteIntroducerContactHandler = async (
  message: DeleteIntroducerContactMessage,
): Promise<DeleteIntroducerContactResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-contact/{introducerContact}",
    {
      introducerContact: message.introducerContact,
    },
  )
    .method("delete")

    .result();

  IntroducerContact.$delete(message.introducerContact);

  return {};
};
