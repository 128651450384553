import { Api } from "@/service/api.service";
import { ILead, Lead } from "@kinherit/sdk";

interface DeleteLeadMessage {
  lead: string | Lead;
  sort?: {
    by: keyof ILead;
    direction: "asc" | "desc";
  };
}

interface DeleteLeadResponse {
  [key: string]: never;
}

export const DeleteLeadHandler = async (
  message: DeleteLeadMessage,
): Promise<DeleteLeadResponse> => {
  await Api.resource("portal", "/v2/portal/lead/{lead}", {
    lead: message.lead,
  })
    .method("delete")

    .result();

  Lead.$findOne(
    "string" === typeof message.lead ? message.lead : message.lead.id,
  )?.$delete();

  return {};
};
