<template>
  <div class="will will-highlight">
    <CollapsableWarningWidget
      v-if="notices.warnings.isNotEmpty()"
      :items="notices.warnings"
      class="mb-5"
    />
    <CollapsableWarningWidget
      v-if="notices.errors.isNotEmpty()"
      :items="notices.errors"
      color="is-danger"
      class="mb-5"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import CollapsableWarningWidget from "@/shared/component/widget/CollapsableWarningWidget.vue";

export default defineComponent({
  name: "WillWarnings",
  mixins: [WillBuilderService.mixin],
  components: {
    CollapsableWarningWidget,
  },
});
</script>
