import { Api } from "@/service/api.service";
import { Between, Equal, In, IsNotNull, Like } from "@kinherit/orm";
import {
  IIntroducerContract,
  IntroducerCompany,
  IntroducerContract,
  Profile,
} from "@kinherit/sdk";
import { IntroducerContractStatus } from "../data/introducer-contract-status.data";

export interface ReadIntroducerContractMessage {
  name?: string;
  contractType?: Array<string>;
  isSigned?: boolean | null;
  contractStatus?: null | keyof typeof IntroducerContractStatus;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerContract;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerContractResponse {
  introducerContracts: Array<IntroducerContract>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerContractHandler = async (
  message: ReadIntroducerContractMessage,
): Promise<ReadIntroducerContractResponse> => {
  const deletedAt = message.contractStatus === "void" ? IsNotNull() : undefined;
  const sentAt =
    message.contractStatus === "not-sent"
      ? undefined
      : message.contractStatus === "sent-not-signed" ||
          message.contractStatus === "sent-signed"
        ? IsNotNull()
        : undefined;
  const isSigned =
    message.contractStatus === "sent-not-signed"
      ? Equal(false)
      : message.contractStatus === "sent-signed"
        ? Equal(true)
        : Equal(message.isSigned);

  const request = Api.resource("portal", "/v2/portal/introducer-contract")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(IntroducerContract).where([
    {
      company: {
        profile: {
          fullName: Like(message.name),
        },
      },
      type: {
        id: In(message.contractType),
      },
      isSigned: isSigned,
      sentAt: sentAt,
      deletedAt: deletedAt,
      createdAt: Between(message.created),
    },
    {
      company: {
        profile: {
          organisationName: Like(message.name),
        },
      },
      type: {
        id: In(message.contractType),
      },
      isSigned: isSigned,
      sentAt: sentAt,
      deletedAt: deletedAt,
      createdAt: Between(message.created),
    },
  ]);

  const response = await request.result();

  return {
    introducerContracts: IntroducerContract.$inflate(
      response.introducerContract,
      message.sort,
      response.$rootIds,
    ),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    profiles: Profile.$inflate(response.profile),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
