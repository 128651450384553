import { Api } from "@/service/api.service";
import { KnowledgeBaseDocument } from "@kinherit/sdk";

interface DeleteKnowledgeBaseDocumentMessage {
  knowledgeBaseDocument: KnowledgeBaseDocument;
}

interface DeleteKnowledgeBaseDocumentResponse {
  [key: string]: never;
}

export const DeleteKnowledgeBaseDocumentHandler = async (
  message: DeleteKnowledgeBaseDocumentMessage,
): Promise<DeleteKnowledgeBaseDocumentResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-document/{knowledgeBaseDocument}",
    {
      knowledgeBaseDocument: message.knowledgeBaseDocument.id,
    },
  )
    .method("delete")
    .result();

  return {};
};
