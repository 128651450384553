import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface DeleteBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

interface DeleteBrandedKinvaultSubscriptionReferralCodeResponse {
  [key: string]: never;
}

export const DeleteBrandedKinvaultSubscriptionReferralCodeHandler = async (
  message: DeleteBrandedKinvaultSubscriptionReferralCodeMessage,
): Promise<DeleteBrandedKinvaultSubscriptionReferralCodeResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
    {
      brandedKinvaultSubscriptionReferralCode:
        message.brandedKinvaultSubscriptionReferralCode,
    },
  )
    .method("delete")

    .result();

  message.brandedKinvaultSubscriptionReferralCode.$delete();

  return {};
};
