import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsChangeLogBreadCrumb } from "../../../breadcrumbs";

/** ChangeLog */
export const LeadDetailsChangeLogRoute = "LeadDetailsChangeLog";
const LeadDetailsChangeLogURI = "/lead/:lead/change-log";
export type LeadDetailsChangeLogParams = RouteParamObject<
  typeof LeadDetailsChangeLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsChangeLogRoute,
    path: LeadDetailsChangeLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsChangeLog" */ "./LeadDetails.ChangeLog.vue"
        ),
    },
    meta: {
      breadcrumbs: LeadDetailsChangeLogBreadCrumb,
    },
  },
];
