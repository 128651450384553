import { RouteInterface } from "@kinherit/framework/core/route-config";
import { CpdSessionsMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as CpdDetailsRoutes } from "./details";

export const CpdSessionsMasterListRoute = "CpdSessionsMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: CpdSessionsMasterListRoute,
    path: "/cpds",
    component: () =>
      import(
        /* webpackChunkName: "CpdSessionsMasterList" */ "./CpdSessionsMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [CpdSessionsMasterListBreadCrumb],
    },
  },
  ...CpdDetailsRoutes,
];
