import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadEmailCampaignLogForm = () => {
  return MasterListFiltersForm({
    name: "filter-email-campaign-log-form",
    data: () => ({
      created: null as null | [number, number],
      scheduledToSendAt: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      scheduledToSendAt: [
        CreatedAtField({
          props: {
            label: `Sceheduled`,
            vModel: `scheduledToSendAt`,
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            label: `Created`,
            vModel: `created`,
          },
        }),
      ],
    }),
  });
};
