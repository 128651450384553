<template>
  <div>
    <strong> Appointment of Executors </strong>
    <p>
      <span v-html="helpers.var(renderSoleExecutor())" />
      <span v-html="helpers.var(renderMultipleExecutors())" />
      <span v-html="helpers.var(renderOneExecutorOneReserve())" />
      <span v-html="helpers.var(renderMultipleExecutorNoReserve())" />
      <span v-html="helpers.var(renderReserveExecutors())" /> (hereinafter
      referred to as 'my Executors') <br /><br />
      Always provided that if a trust is created in the following clauses of
      this my Will and no appointment of a trustee is made in relation to that
      trust I direct that my Executor shall be appointed as my trustee
      hereinafter referred to as 'my trustees' which expression shall include
      the trustee or trustees for the time being hereof
      <span v-html="helpers.var(renderExecutorRobinHoodClause())" />
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: `ExecutorsSection`,
  mixins: [WillBuilderService.mixin],
  methods: {
    renderExecutorRobinHoodClause() {
      if (!this.data) {
        return ``;
      }

      const { proExecutorOptOut, proExecutorOption } = this.data;

      if (proExecutorOptOut) {
        return ``;
      }

      if (proExecutorOption === 0) {
        return `<br /><br />In the event that any of my Executors do not wish to act and instead wish to appoint a professional executor on their behalf it is my wish that consideration as to who is appointed should extend to any such firms recommended by Kinherit Ltd.`;
      }

      if (proExecutorOption === 1) {
        return `<br /><br />In the event that any of my Executors do not wish to act and instead wish to appoint a professional executor on their behalf it is my express wish that consideration as to who is appointed should extend to any such firms recommended by Kinherit Ltd.`;
      }

      if (proExecutorOption === 2) {
        return `<br /><br />In the event that any of my Executors do not wish to act and instead wish to appoint a professional executor on their behalf it is my request that consideration as to who is appointed should extend to any such firms recommended by Kinherit Ltd.`;
      }

      return ``;
    },
    renderSoleExecutor() {
      const data = this.data;

      if (!data) {
        return ``;
      }

      if (data.primaryExecutors.length === 1) {
        return `I appoint ${this.helpers.formatOfficer(
          data.primaryExecutors[0].person,
        )}${
          data.primaryExecutors[0].person.profile.organisationName
            ? ` or any other firm which at my death has succeeded to and carries on its practice`
            : ``
        } to be the sole executor of this my Will`;
      }

      return ``;
    },
    renderMultipleExecutors() {
      if (!this.data) {
        return ``;
      }

      if (this.data.primaryExecutors.length > 1) {
        return `I appoint
          ${this.data.primaryExecutors
            .map((executor) => this.helpers.formatOfficer(executor.person))
            .join(" and ")} to be the executors of this my Will`;
      }

      return ``;
    },
    renderOneExecutorOneReserve() {
      if (!this.data.person) {
        return ``;
      }

      const { primaryExecutors, reserveExecutors } = this.data;

      if (primaryExecutors.length === 1 && reserveExecutors.length > 0) {
        return ` but if my ${this.helpers.formatRelationship(
          this.data.person,
          primaryExecutors[0].person,
        )} is unable or unwilling to prove my Will then I APPOINT `;
      }

      return ``;
    },
    renderMultipleExecutorNoReserve() {
      if (!this.data) {
        return ``;
      }

      const { primaryExecutors, reserveExecutors } = this.data;

      if (primaryExecutors.length > 1 && reserveExecutors.length > 0) {
        return ` but if they are unable or unwilling to prove my Will then I APPOINT `;
      }

      return ``;
    },
    renderReserveExecutors() {
      if (!this.data) {
        return ``;
      }

      const { reserveExecutors } = this.data;

      if (reserveExecutors.length > 0) {
        return `${this.data.reserveExecutors
          .map((executor) => this.helpers.formatOfficer(executor.person))
          .join(" and ")}
          to be the
          ${
            this.data.reserveExecutors.length === 1
              ? `sole executor`
              : `executors`
          }
          of this my Will`;
      }

      return ``;
    },
  },
});
</script>
