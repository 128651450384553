import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadCpdForm = () => {
  return MasterListFiltersForm({
    name: "filter-cpd-form",
    data: () => ({
      type: Array<string>(),
      period: "forthcoming" as null | "forthcoming" | "recent",
      isMatched: null as null | boolean,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      period: [
        FormSelectField({
          props: {
            label: "Period",
            size: "is-small",
            options: {
              null: "Any",
              forthcoming: "Forthcoming",
              recent: "Recent",
            },
            reference: "period",
          },
          models: {
            value: {
              get: (data) => data.period,
              set: (value, data) => (data.period = value),
            },
          },
        }),
      ],
      isMatched: [
        FormSelectField({
          props: {
            label: "Registration Status",
            size: "is-small",
            options: {
              null: "Any",
              true: "Matched",
              false: "Unmatched",
            },
            reference: "isMatched",
          },
          models: {
            value: {
              get: (data) => {
                if (data.isMatched === null) {
                  return null;
                }

                return data.isMatched ? "true" : "false";
              },
              set: (value, data) => {
                if (value === null) {
                  data.isMatched = null;
                } else {
                  data.isMatched = value === "true";
                }
              },
            },
          },
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "introducerCpdType",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
