<template>
  <span>
    <strong> Legacy of the Combined Nil Rate Amount</strong><br />
    <p>
      I give the Combined Nil Rate Amount to the trustees of the trust as
      defined in
      <strong>Schedule <span v-html="helpers.var(position)" /></strong> of this
      my Will to hold under the terms of the said trust <br /><br />
      The <strong>Combined Nil Rate Amount</strong> means the maximum amount I
      can give without incurring any liability to inheritance tax on my death
      and includes:
    </p>
    <ol type="a">
      <li>
        the <strong>Residence Nil Rate Amount</strong> means the total amount or
        allowance in respect of increased nil rate band for inheritance tax to
        which my estate may be entitled at the date of my death under the
        Inheritance Tax Act 1984, sections 8D to 8M as amended (or any
        re-enactment thereof at the date of my death) including any amount
        brought forward under Section 8F and any downsizing addition under
        Sections 8FA to 8FE and
      </li>
      <li>
        the <strong>Nil Rate Amount</strong> which is the total amount I can
        give free of inheritance tax other than by way of the Residence Nil Rate
        Amount
      </li>
    </ol>
    <p>
      I direct the trustees of the trusts as defined in
      <strong>Schedule <span v-html="helpers.var(position)" /></strong> to make
      such claim or claims as they see fit in order to secure the Nil Rate
      Amount and the Residence Nil Rate Amount (including any downsizing
      addition) as soon as practicable after my death and in any event within
      such statutory period as may be prescribed
    </p></span
  >
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    position() {
      return this.helpers.getSchedulePosition(WillBuilderSections.ScheduleNRB);
    },
  },
});
</script>
