import { Api } from "@/service/api.service";
import { IntroducerCpd } from "@kinherit/sdk";

interface GenerateCpdCertificateMessage {
  introducerCpd: IntroducerCpd;
}

export const GenerateCpdCertificateHandler = async (
  message: GenerateCpdCertificateMessage,
): Promise<void> => {
  const name = message.introducerCpd.contact.profile.fullName;
  await Api.resource(
    "portal",
    "/v2/portal/cpd-session/introducer-cpd/{introducerCpd}/certificate",
    {
      introducerCpd: message.introducerCpd,
    },
  )
    .method("get")

    .download({
      encoding: "application/pdf",
      fileName: `CPD Certificate - ${name}`,
    });
};
