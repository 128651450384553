import { BrandedKinvault } from "@kinherit/sdk/index";
import { DateTime } from "@kinherit/ts-common/index";

export const ComputeBrandedKinvaultKinvaultFeesHandler = async (data: {
  brandedKinvault: string | BrandedKinvault;
  year: string;
}): Promise<{
  months: {
    year: number;
    month: number;
    newUsers: number;
    renewedUsers: number;
    activeUsers: number;
    pricePerUnit: number;
    newUserTotalPrice: number;
    renewedUserTotalPrice: number;
    totalPrice: number;
  }[];
}> => {
  const startDate = DateTime.fromDate(
    new Date(data.year.toNumber(), 0, 1),
  ).formatYMD;
  const endDate = DateTime.fromDate(
    new Date(data.year.toNumber(), 11, 31),
  ).formatYMD;

  return await window.Kernel.Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-kinvault-fees/{brandedKinvault}",
    {
      brandedKinvault: data.brandedKinvault,
    },
  )
    .method("get")
    .params({
      startDate,
      endDate,
    })
    .result();
};
