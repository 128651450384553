<template>
  <AccountHoldersContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookAccountHoldersWrapper",
  "route": "KinvaultKintinDetailsAddressBookAccountHolders",
  "extends": {
    "name": "AccountHoldersContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/AccountHoldersContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import AccountHoldersContainer from "@/module/kinvault.kintin/component/address-book/AccountHoldersContainer.vue";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookAccountHoldersRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookAccountHoldersRoute,
  components: {
    AccountHoldersContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  data: () => ({
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  methods: {},
});
</script>
