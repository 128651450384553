import {
  Api,
  IntroducerBillingContact,
  IntroducerCompany,
} from "@kinherit/sdk";
export interface DeleteIntroducerBillingContactMessage {
  introducerCompany: IntroducerCompany | string;
  introducerBillingContact: IntroducerBillingContact;
}

export interface DeleteIntroducerBillingContactResponse {
  [key: string]: never;
}

export const DeleteIntroducerBillingContactHandler = async (
  message: DeleteIntroducerBillingContactMessage,
): Promise<DeleteIntroducerBillingContactResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-billing-contact/{introducerCompany}/billing-contacts/{introducerBillingContact}",
    {
      introducerCompany: message.introducerCompany,
      introducerBillingContact: message.introducerBillingContact,
    },
  )
    .method("delete")
    .result();

  message.introducerBillingContact.$delete();

  return {};
};
