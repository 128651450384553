import { Api } from "@/service/api.service";
import { AccountReferralCode, BrandedKinvault } from "@kinherit/sdk";

interface Message {
  brandedKinvault: BrandedKinvault;
  file: File;
  defaultReferralCode: AccountReferralCode;
  brandOverride: BrandedKinvault | null;
  sendInvitations: boolean;
}

interface Response {
  [key: string]: never;
}

export const UploadBrandedKinvaultInvitationCsvHandler = async (
  message: Message,
): Promise<Response> => {
  const { file } = message;

  const resource = message.sendInvitations
    ? "/v2/portal/branded-kinvault/invitation/{brandedKinvault}/csv/send"
    : "/v2/portal/branded-kinvault/invitation/{brandedKinvault}/csv/upload";

  return (await Api.resource("portal", resource, {
    brandedKinvault: message.brandedKinvault,
  })
    .method("put")

    .files({
      uploadedFile: file,
      defaultReferralCode: message.defaultReferralCode.id,
      brandOverride: message.brandOverride && message.brandOverride.id,
    })
    .result()) as never;
};
