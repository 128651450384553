import { CpdDetailsParams, CpdDetailsRoute } from "@/module/cpd/page/details";
import { AuthService } from "@/service/auth.service";
import { CpdSession } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const CpdDetailsMixin = defineComponent({
  name: "cpd-details-mixin",
  computed: {
    $params(): CpdDetailsParams {
      return this.$route.params as CpdDetailsParams;
    },
    cpdSession(): CpdSession | null {
      return CpdSession.$findOne(this.$params.cpdSession);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("cpd-session:write");
    },
  },
  methods: {
    visitKintinDetails(): void {
      window.Kernel.Router.push({
        name: CpdDetailsRoute,
        params: { kintin: this.cpdSession?.id },
      });
    },
    async refresh(): Promise<void> {
      if (!this.cpdSession) {
        return;
      }

      // await window.Kernel.ActionBus.execute("cpd/session/record", {
      //   cpdSession: this.cpdSession,
      // });
      await window.Kernel.ActionBus.cpd.session.record({
        cpdSession: this.cpdSession,
      });
    },
  },
});
