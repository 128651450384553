import { Api } from "@/service/api.service";
import { IntroducerOutsource } from "@kinherit/sdk";

export interface DeleteIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource | string;
}

interface DeleteIntroducerOutsourceResponse {
  [key: string]: never;
}

export const DeleteIntroducerOutsourceHandler = async (
  message: DeleteIntroducerOutsourceMessage,
): Promise<DeleteIntroducerOutsourceResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-outsource/{introducerOutsource}",
    {
      introducerOutsource: message.introducerOutsource,
    },
  )
    .method("delete")

    .result();

  if (message.introducerOutsource instanceof IntroducerOutsource) {
    message.introducerOutsource.$delete();
  } else {
    IntroducerOutsource.$findOne(message.introducerOutsource)?.$delete();
  }

  return {};
};
