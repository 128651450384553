import { Api } from "@/service/api.service";
import { IntroducerNetwork } from "@kinherit/sdk";

interface CreateIntroducerNetworkMessage {
  introducerNetwork: IntroducerNetwork;
}

interface CreateIntroducerNetworkResponse {
  introducerNetwork: IntroducerNetwork;
}

export const CreateIntroducerNetworkHandler = async (
  message: CreateIntroducerNetworkMessage,
): Promise<CreateIntroducerNetworkResponse> => {
  const response = await Api.resource("portal", "/v2/portal/introducer-network")
    .method("post")

    .body({
      name: message.introducerNetwork.$data.name,
    })
    .result();

  const introducerNetwork = IntroducerNetwork.$inflate(
    response.introducerNetwork,
  ).first();

  if (!introducerNetwork) {
    throw new Error("Failed to create Introducer Network");
  }

  message.introducerNetwork.$delete();

  return {
    introducerNetwork,
  };
};
