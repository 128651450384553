import { Api } from "@/service/api.service";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface DeleteIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
}

export interface DeleteIntroducerFeePaymentRunResponse {}

export const DeleteIntroducerFeePaymentRunHandler = async (
  message: DeleteIntroducerFeePaymentRunMessage,
): Promise<DeleteIntroducerFeePaymentRunResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}",
    message,
  )
    .method("delete")
    .result();

  IntroducerFeePaymentRun.$delete(message.introducerFeePaymentRun);

  return {};
};
