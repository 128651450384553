import { Api } from "@/service/api.service";
import { AccountReferralCode } from "@kinherit/sdk";

interface CreateAccountReferralCodeMessage {
  accountReferralCode: AccountReferralCode;
}

interface CreateAccountReferralCodeResponse {
  accountReferralCode: AccountReferralCode;
}

export const CreateAccountReferralCodeHandler = async (
  message: CreateAccountReferralCodeMessage,
): Promise<CreateAccountReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/account-referral-code",
  )
    .method("post")
    .body({
      category: message.accountReferralCode.$data.category,
      code: message.accountReferralCode.$data.code,
      company: message.accountReferralCode.$data.company,
      isForUsers: message.accountReferralCode.$data.isForUsers,
      name: message.accountReferralCode.$data.name,
      notes: message.accountReferralCode.$data.notes,
      isFeeSacrifice: message.accountReferralCode.$data.isFeeSacrifice,
      isSalarySacrifice: message.accountReferralCode.$data.isSalarySacrifice,
      isFriendsAndFamily: message.accountReferralCode.$data.isFriendsAndFamily,
    })
    .result();

  const accountReferralCode = AccountReferralCode.$inflate(
    response.accountReferralCode,
    undefined,
    response.$rootIds,
  ).first();

  if (!accountReferralCode) {
    throw new Error("Failed to create account referral code");
  }

  message.accountReferralCode.$delete();

  return {
    accountReferralCode,
  };
};
