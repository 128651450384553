import { Api } from "@/service/api.service";
import { Lead } from "@kinherit/sdk";

interface UpdateLeadSettingsMessage {
  lead: Lead;
}

interface UpdateLeadSettingsResponse {
  lead: Lead;
}

export const UpdateLeadSettingsHandler = async (
  message: UpdateLeadSettingsMessage,
): Promise<UpdateLeadSettingsResponse> => {
  const response = await Api.resource("portal", "/v2/portal/lead/{lead}", {
    lead: message.lead,
  })
    .method("patch")

    .body({
      ownedBy: message.lead.$data.ownedBy,
      assignedTo: message.lead.$data.assignedTo,
      status: message.lead.$data.status,
      tags: message.lead.$data.tags,
      externalLeadId: message.lead.$data.externalLeadId,
      description: message.lead.$data.description,
    })
    .result();

  const lead = Lead.$inflate(response.lead).first();

  if (!lead) {
    throw new Error("Failed to create lead");
  }

  return {
    lead,
  };
};
