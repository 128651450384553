import { Api } from "@/service/api.service";
import {
  Kintin,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Subscription,
} from "@kinherit/sdk";

interface CreateKintinStorageRequestLogMessage {
  kintin: string | Kintin;
  subscription: string | Subscription;
  storageRequest: string | PhysicalStorageFile;
  physicalStorageFileLog: PhysicalStorageFileLog;
}

interface CreateKintinStorageRequestLogResponse {
  physicalStorageFileLog: PhysicalStorageFileLog;
}

export const CreateKintinStorageRequestLogHandler = async (
  message: CreateKintinStorageRequestLogMessage,
): Promise<CreateKintinStorageRequestLogResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}/log",
    {
      kintin: message.kintin,
      subscription: message.subscription,
      physicalStorageFile: message.storageRequest,
    },
  )
    .method("post")

    .body({
      completedAt: message.physicalStorageFileLog.$data.completedAt,
      requestedAt: message.physicalStorageFileLog.$data.requestedAt,
      confirmedBy: message.physicalStorageFileLog.$data.confirmedBy,
      createdBy: message.physicalStorageFileLog.$data.createdBy,
      file: message.physicalStorageFileLog.$data.file,
      logAction: message.physicalStorageFileLog.$data.logAction,
      notes: message.physicalStorageFileLog.$data.notes,
      kintin: message.kintin.toString(),
    })
    .result();

  const physicalStorageFileLog = PhysicalStorageFileLog.$inflate(
    response.physicalStorageFileLog,
  ).first();

  if (!physicalStorageFileLog) {
    throw new Error("Failed to create property");
  }

  message.physicalStorageFileLog.$delete();

  return { physicalStorageFileLog };
};
