<template>
  <Card :title="title">
    <div class="dashboard-statistic-widget-wrapper">
      <slot></slot>
    </div>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardStatisticWidgetWrapper",
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    hasTimePeriodSelect: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-statistic-widget-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
</style>
