<template>
  <div v-if="kintin">
    <CallScriptSection title="Lasting Power of Attorneys">
      <b
        >So I assume you have a basic understanding what Lasting Powers of
        Attorney are?
      </b>
      <br /><em>[pause]</em> <br /><br />
      <b
        >They let family or friends speak for you if you are incapacitated.
        There are two types of LPA <br /><br />

        The Health LPA is very common as people get older, but anyone can be
        affected by an accident or illness - and no one wants the local
        authority deciding on care.
        <br /><br />

        The Financial Affairs LPA is also triggered on incapacitation, but is
        there to help the people who will have to step in and manage your home,
        bank accounts – or business. The alternative is them needing court
        orders, which can be a nightmare and massively increase the burden and
        distress on others.
      </b>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1d"
        :kintin="kintin"
        :on="{
          yes: {
            say: [
              'Can I double-check, were they set up since the law changed in 2017?',
            ],
          },
          no: {
            action: ['Discuss LPoAs'],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.7.1e"
        :kintin="kintin"
        :on="{
          yes: {
            action: [
              'Step through, Health Person 1 / Health Person 2 / Is Wealth same P1 / Is wealth same P2?',
            ],
          },
          no: {
            say: [
              'IF COST THE ISSUE SPECIALIST CONSIDER SAYING: Just so you are aware, you can get LPAs drafted but only register them if or when needed, to defer half the cost. It is far better to have something that could be used, than having nothing.',
            ],
          },
        }"
      />
      <AttorneysContainer :kintin="kintin" />
      <b>
        To set up a power of attorney, we will also need someone to vouch for
        you – which means they are confirming you know what you are doing, are
        capable of making decisions, that sort of thing.
        <br /><br />
        There is a long list of people that can't vouch for you, so no relatives
        or employees. They also need to have known you for at least 2 years,
        which is why people normally pick friends or neighbours. It can also be
        a doctor, but that is typically where there is a question-mark about
        capacity. The role is called Certificate Provider … do you have an idea
        who you might use? … it would be great to add it now
        <br /><em>[Add above as appropriate]</em> <br /><br />
      </b>

      <ConfirmCheck
        @update="updateCheckValue"
        :kintin="kintin"
        step="4.7.1a"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :on="{
          yes: {
            ask: [
              'It is most practical to allow Attorneys to make decisions on their own or together. It also means that if one of the attorneys dies or can no longer act, your LPA will still work.',
            ],
          },
          no: {
            action: [
              'Decide: Jointly for some decisions OR jointly and severally for other decisions: NB this will NOT be reflected in the generated LPA (for now)',
            ],
            ask: [
              'There is a 3rd option to appoint some attorneys jointly on some decisions and severally on others, however the OPG does not recommend this we would want to make sure you took legal advice on it.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin="kintin"
        step="4.7.1b"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :on="{
          yes: {
            ask: [
              'If you choose this option, your attorneys can speak to doctors on your behalf as if they were you.',
            ],
          },
          no: {
            ask: [
              'If you choose this option, your doctors will take into account the views of the attorneys and of people who are interested in your welfare as well as any written statement you may have made, where it is practical and appropriate.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin="kintin"
        step="4.7.1c"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :on="{
          yes: {
            ask: [
              'Most people choose this option because they are appointing people they trust. The risk is that they could abuse this trust, but the alternative is an Attorney who may be unable to perform their role without going to court.',
            ],
          },
          no: {
            ask: [
              'Only when I don’t have mental capacity: Be careful – this can make your LPA a lot less useful. Your attorneys might be asked to prove you do not have mental capacity each time they try to use this LPA.',
            ],
          },
        }"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin="kintin"
        step="4.7.1f"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :on="{
          yes: {
            say: ['Can I check the best number to give to the OPG?'],
            action: [
              'Write a note detailing the telephone number(s) and whether one person is paying for both or seperately.',
            ],
          },
          no: {
            say: [
              'Details of who to make the cheque out to will be in the cover letter we send',
            ],
          },
        }"
      />
      <span v-if="checkIsYes('4.7.1e')">
        <em
          >[Specialist be aware: Donors can write additional PREFERENCES or
          INSTRUCTIONS for how powers should be applied. If these exist, write a
          note and make the comments directly onto the LPA output.]
        </em>
      </span>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import AttorneysContainer from "@/module/kinvault.kintin/component/address-book/AttorneysContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LpaTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    AttorneysContainer,
    CallScriptSection,
    ConfirmCheck,
  },
  data: () => ({
    renderKey: 0,
    person2Present: null as boolean | null,
    primaryPersonRenderKey: 0,
    secondaryPersonRenderKey: 0,
  }),
  mounted() {
    this.person2Present = this.callScript.person2Present;
  },
});
</script>
