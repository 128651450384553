import { Api } from "@/service/api.service";
import { Beneficiary, Kintin } from "@kinherit/sdk";

interface UpdateBeneficiaryMessage {
  kintin: string | Kintin;
  beneficiary: Beneficiary;
}

interface UpdateBeneficiaryResponse {
  beneficiary: Beneficiary;
}

export const UpdateBeneficiaryHandler = async (
  message: UpdateBeneficiaryMessage,
): Promise<UpdateBeneficiaryResponse> => {
  const { beneficiary: beneficiaryData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-beneficiary/{kintin}/person/beneficiary/{beneficiary}",
    {
      kintin: message.kintin,
      beneficiary: message.beneficiary.$data.id,
    },
  )
    .method("patch")

    .body({
      classGiftType: message.beneficiary.$data.classGiftType,
      forPerson: message.beneficiary.$data.forPerson,
      ifPredeceased: message.beneficiary.$data.ifPredeceased,
      notes: message.beneficiary.$data.notes,
      person: message.beneficiary.$data.person,
      position: message.beneficiary.$data.position,
      reservePerson: message.beneficiary.$data.reservePerson,
      reserveclassGiftType: message.beneficiary.$data.reserveclassGiftType,
      shareFraction: message.beneficiary.$data.shareFraction,
      sharePercentage: message.beneficiary.$data.sharePercentage,
      sortOrder: message.beneficiary.$data.sortOrder,
    })
    .result();

  const beneficiary = Beneficiary.$inflate(beneficiaryData).first();

  if (!beneficiary) {
    throw new Error("Failed to update beneficiary");
  }

  return { beneficiary };
};
