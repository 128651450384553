import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { CpdDetailsParams } from "..";
import { CpdDetailsIntroducersBreadCrumb } from "../../../breadcrumbs";

/** Introducers */
export const CpdDetailsIntroducersRoute = "CpdDetailsIntroducers";
const CpdDetailsIntroducersURI = "/cpd/:cpdSession/introducers";
export type CpdDetailsIntroducersParams = RouteParamObject<
  typeof CpdDetailsIntroducersURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: CpdDetailsIntroducersRoute,
    path: CpdDetailsIntroducersURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsIntroducers" */ "./CpdDetails.Introducers.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        CpdDetailsIntroducersBreadCrumb(params as CpdDetailsParams),
    },
  },
];
