import { AdminBrandedKinvaultDetailsThemeBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsThemeRoute = "BrandedKinvaultDetailsTheme";
export const BrandedKinvaultDetailsThemeURI =
  "/branded-kinvaults/:brandedKinvault/theme";
export type BrandedKinvaultDetailsThemeParams = RouteParamObject<
  typeof BrandedKinvaultDetailsThemeURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsThemeRoute,
    path: BrandedKinvaultDetailsThemeURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsTheme" */ "./BrandedKinvaultDetails.Theme.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsThemeBreadCrumb,
    },
  },
];
