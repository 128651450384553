<template>kintin details - calls</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsCallsWrapper",
  "route": "KinvaultKintinDetailsCalls"
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsCallsRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsCallsRoute,
  mixins: [KinvaultKintinDetailsMixin],
});
</script>
