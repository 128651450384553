import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const WillBuilderOptionalDocumentsForm = () =>
  defineForm({
    name: `will-builder-optional-documents`,
    data: () => WillBuilderService.form,
    formAreas: (data) => [
      defineFormArea({
        name: `will-builder-optional-documents`,
        data,
        template: GridLayout([
          "businessTrust",
          "estateProtectionTrust",
          "iipTrust",
          "businessClause",
        ]),
        components: () => ({
          businessTrust: [
            FormCheckboxField({
              props: {
                label: "Business Trust",
              },
              models: {
                value: "hasBusinessTrust",
              },
            }),
          ],
          estateProtectionTrust: [
            FormCheckboxField({
              props: {
                label: "Estate Protection Trust",
              },
              models: {
                value: "hasEstateProtectionTrust",
              },
            }),
          ],
          iipTrust: [
            FormCheckboxField({
              props: {
                label: "IIP Trust",
              },
              models: {
                value: "hasIipTrust",
              },
            }),
          ],
          businessClause: [
            FormCheckboxField({
              props: {
                label: "Business Clause",
              },
              models: {
                value: "hasBusinessClause",
              },
            }),
          ],
        }),
      }),
    ],
  });
