import {
  calculateStartEndDates,
  mergeArrays,
} from "@/module/report/data/helper-functions";
import { Api } from "@/service/api.service";

interface ComputeAppointmentsReportMessage {
  year: string;
  created: [number, number] | null;
  granularity: "week" | "month" | "quarter" | "year";
  assignedUser: string | null;
  compareTo: string | null;
}

interface ComputeAppointmentsReportResponse {
  datasets: Array<{
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
  labels: Array<string | number>;
  results: Array<{
    year: number;
    week: number;
    month: number;
    quarter: number;
    appointmentCount: number;
    cancelCount: number;
    enquiryCount: number;
    call1Count: number;
    call1CancellationCount: number;
    call1NoShowCount: number;
    call2Count: number;
    call2CancellationCount: number;
    call2NoShowCount: number;
    kinvaultOfficerCount: number;
    willReviewCount: number;
    otherCount: number;
  }>;
}
export type TAppointmentReportResult = {
  year: number;
  week: number;
  month: number;
  quarter: number;
  appointmentCount: number;
  cancelCount: number;
  enquiryCount: number;
  call1Count: number;
  call1CancellationCount: number;
  call1NoShowCount: number;
  call2Count: number;
  call2CancellationCount: number;
  call2NoShowCount: number;
  kinvaultOfficerCount: number;
  willReviewCount: number;
  otherCount: number;
};
export const ComputeAppointmentsReportHandler = async (
  message: ComputeAppointmentsReportMessage,
): Promise<ComputeAppointmentsReportResponse> => {
  const { year, created, granularity, assignedUser, compareTo } = message || {};

  const calculatedDates = calculateStartEndDates(year, created, compareTo);

  const response = await Api.resource(
    "portal",
    "/v2/portal/report/appointments",
    {},
  )
    .method("get")
    .params({
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      granularity: granularity,
      assignedUser: assignedUser ?? null,
      compareToStartDate: calculatedDates.compareToStartDate,
      compareToEndDate: calculatedDates.compareToEndDate,
    })

    .result();

  const datasets: {
    label: string;
    stack: string;
    backgroundColor: string;
    data: Array<number>;
  }[] = [];

  return {
    datasets: datasets,
    labels: response.labels,
    results:
      response.datasets.length > 1
        ? mergeArrays(response.datasets.pluck("datasets"))
        : response.datasets[0].datasets,
  };
};
