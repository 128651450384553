import { CreateFile } from "@/config/model.config";
import { FormFileField } from "@kinherit/framework/component.input/file-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { FileLog } from "@kinherit/sdk";

export const UpdateFileForm = (file: FileLog = CreateFile()) => {
  return defineForm({
    name: "update-file-form",
    data: () => ({ file, uploadedFile: null as File | null }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-file-form-area",
        template:
          file.fileExtension === "rich-text"
            ? GridLayout([["name"], ["notes"]])
            : GridLayout([["file"], ["notes"]]),
        data,
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
                vIf: () => data.file.fileExtension === "rich-text",
              },
              models: {
                value: "file.fileName",
              },
            }),
          ],
          file: [
            FormFileField({
              props: {
                vIf: (data) => data.file.fileExtension !== "rich-text",
                placeholder: () => data.file.fileName,
                label: "File",
                validators: ["required"],
                isSingle: true,
                reference: "uploadedFile",
              },
              models: {
                value: {
                  get: (data) => (data.uploadedFile ? [data.uploadedFile] : []),
                  set: (value: Array<File>, data) => {
                    const file = value.first() ?? null;

                    data.uploadedFile = file;
                    data.file.fileExtension =
                      file?.name.reverse().split(".", 1)[0].reverse() ?? "";
                    data.file.fileSize = file?.size ?? null;
                    data.file.fileName = file?.name ?? "";
                  },
                },
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                label: "Notes",
                validators: ({ file }) =>
                  file.fileExtension === "rich-text" ? ["required"] : [],
              },
              models: {
                value: "file.notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
