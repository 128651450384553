import { FooterOptionsConfig } from "@kinherit/framework/component.layout/footer/types";

export const FooterConfig: FooterOptionsConfig = () => ({
  elements: {
    container: {
      style: {
        background: "transparent",
      },
    },
  },
});
