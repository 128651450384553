import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const IntroducerContractMasterListRoute = "IntroducerContractMasterList";
export const IntroducerContractDetailsRoute = "IntroducerContractDetails";
export const IntroducerContractDetailsURI = "/introducer/contract/:id";
export type IntroducerContractDetailsParams = RouteParamObject<
  typeof IntroducerContractDetailsURI
>;

const IntroducerContractMasterListBreadCrumb = {
  text: "Contracts",
  route: { name: IntroducerContractMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  /** Contract */
  {
    name: IntroducerContractMasterListRoute,
    path: "/introducer/contracts",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerContractMasterList" */ "./IntroducerContractMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerContractMasterListBreadCrumb],
    },
  },
];
