<template>
  <RouterChildView> </RouterChildView>
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsCallScriptWrapper",
  "route": "KinvaultKintinDetailsCallScript",
  "imports": {
    "KinvaultKintinDetailsCallScriptSidebarWrapper": "@/module/kinvault.kintin/page/details/call-script/KinvaultKintinDetails.CallScript.Sidebar.test"
  },
  "methods": {
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsCallScriptSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KintinDetailsCallScriptParams, KintinDetailsCallScriptRoute } from ".";

export default defineComponent({
  name: KintinDetailsCallScriptRoute,
  mixins: [KinvaultKintinDetailsMixin],
  components: { RouterChildView },
  async beforeRouteEnter(to): Promise<void> {
    // await window.Kernel.ActionBus.execute(
    //   "kinvault/kintin/call-script/record",
    //   to.params as KintinDetailsCallScriptParams,
    // );
    await window.Kernel.ActionBus.kinvaultKintin.callScript.record(
      to.params as KintinDetailsCallScriptParams,
    );
  },
});
</script>
