import { Api } from "@kinherit/sdk/api";

export interface ReadContactsMessage {
  type: "KINHERIT" | "TRUREG";
  modifiedSince?: string;
  where?: string;
  order?: string;
  ids?: string[];
  page?: number;
  includeArchived?: boolean;
  summaryOnly?: boolean;
  searchTerm?: string;
}

export const ReadContactsHandler = async (message: ReadContactsMessage) => {
  return await Api.resource("external", "/v2/external/xero/contacts")
    .method("get")
    .params(message)
    .result();
};
