<template>
  <div class="markdown-editor">
    <Message v-if="hasMissingVariables" color="is-danger" class="mt-2 mb-3">
      There are missing variables in the body. Please check the body and subject
      for missing variables.
    </Message>
    <div class="columns">
      <div class="column">
        <TextField
          label="Subject"
          class="email_subject"
          :validators="['required']"
          v-model:value="computedTitle"
        />
      </div>
      <div class="column" style="align-self: center">
        <div class="has-text-weight-bold is-4 subtitle pt-5">
          <span v-html="renderedTitle"></span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label for="email_body" class="label">Body</label>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="markdown-editor__toolbar">
          <Button
            ariaLabel="Bold"
            icon-left="TextBold"
            color="is-plain"
            size="is-small"
            @click="() => toggleWrapper('**')"
            v-tool-tip:is-absolute="tooltip.bold"
          />
          <Button
            ariaLabel="Italic"
            icon-left="TextItalic"
            color="is-plain"
            size="is-small"
            @click="() => toggleWrapper('*')"
            v-tool-tip:is-absolute="tooltip.italic"
          />
          <Button
            ariaLabel="Link"
            icon-left="TextLink"
            color="is-plain"
            size="is-small"
            @click="() => injectLink()"
            v-tool-tip:is-absolute="tooltip.link"
          />
          <Button
            ariaLabel="Ordered List"
            icon-left="ListOrdered"
            color="is-plain"
            size="is-small"
            @click="
              () =>
                injectContent({
                  content: `\n1. ${computedSelectionParts?.selected ?? ''}`,
                  restoreSelection: false,
                })
            "
            v-tool-tip:is-absolute="tooltip.orderedList"
          />
          <Button
            ariaLabel="Unordered List"
            icon-left="ListUnordered"
            color="is-plain"
            size="is-small"
            @click="
              () =>
                injectContent({
                  content: `\n- ${computedSelectionParts?.selected ?? ''}`,
                  restoreSelection: false,
                })
            "
            v-tool-tip:is-absolute="tooltip.unorderedList"
          />
          <Button
            ariaLabel="Table"
            icon-left="Table"
            color="is-plain"
            size="is-small"
            @click="injectTable()"
            v-tool-tip:is-absolute="tooltip.table"
          />
          <span class="select is-small">
            <select
              class="markdown-editor__insert-select"
              v-model="insertvalue"
              :is-fullwidth="false"
              size="is-small"
              @change="injectTag(insertvalue)"
            >
              <option value="">{{ "\{\{" + "..." + "\}\}" }}</option>
              <optgroup
                v-for="group in optionsInsert"
                :label="group.group"
                :key="group.group"
              >
                <option
                  v-for="(text, value) in group.options"
                  :value="value"
                  :key="value"
                >
                  {{ text }}
                </option>
              </optgroup>
            </select>
          </span>
          <span class="select is-small">
            <select
              class="markdown-editor__insert-heading ml-2"
              v-model="insertHeading"
              :is-fullwidth="false"
              size="is-small"
              @change="
                () => {
                  const key = insertHeading as keyof typeof optionsHeading;

                  injectContent({
                    content: `\n${optionsHeading[key] ?? ''}`,
                    restoreSelection: false,
                  });
                  insertHeading = '';
                }
              "
            >
              <option value="">Heading</option>
              <option
                v-for="(text, value) in optionsHeading"
                :value="value"
                :key="value"
              >
                {{ text }}
              </option>
            </select>
          </span>
          <Button
            size="is-small"
            color="is-plain"
            @click="openHelpDialog()"
            text="Help"
          />
        </div>
        <label
          class="markdown-editor__text-area-label"
          :data-value="`${computedBody}\n`"
        >
          <textarea
            id="email_body"
            style="max-height: none"
            :value="computedBody"
            @input="(elm: any) => (computedBody = elm.target.value)"
            class="textarea markdown-editor__body-field"
            @select="renderBody"
            @mousedown="clearSelection"
            @keydown.b.alt.capture.prevent="() => toggleWrapper('**')"
            @keydown.i.alt.capture.prevent="() => toggleWrapper('*')"
            @keydown.k.alt.capture.prevent="() => injectLink()"
            @keydown.o.alt.capture.prevent="
              () =>
                injectContent({
                  content: `\n1. ${computedSelectionParts?.selected ?? ''}`,
                  restoreSelection: false,
                })
            "
            @keydown.u.alt.capture.prevent="
              () =>
                injectContent({
                  content: `\n- ${computedSelectionParts?.selected ?? ''}`,
                  restoreSelection: false,
                })
            "
            @keydown.t.alt.capture.prevent="injectTable()"
          ></textarea>
        </label>
      </div>
      <div class="column">
        <label class="label mt-2" for="body_preview">Preview</label>
        <div id="body_preview" v-html="selectedBody" />
      </div>
    </div>
    <table class="table is-fullwidth" ref="refHelp" style="display: none">
      <thead>
        <tr>
          <th>Element</th>
          <th>Markdown Syntax</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>Heading</b>
          </td>
          <td>
            <code
              ># H1<br />
              ## H2<br />
              ### H3</code
            >
          </td>
        </tr>
        <tr>
          <td>
            <b>Bold</b>
          </td>
          <td><code>**bold text**</code></td>
        </tr>
        <tr>
          <td>
            <b>Italic</b>
          </td>
          <td><code>*italicized text*</code></td>
        </tr>
        <tr>
          <td>
            <b>Blockquote</b>
          </td>
          <td><code>&gt; blockquote</code></td>
        </tr>
        <tr>
          <td>
            <b>Ordered List</b>
          </td>
          <td>
            <code>
              1. First item<br />
              2. Second item<br />
              3. Third item<br />
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <b>Unordered List</b>
          </td>
          <td>
            <code>
              - First item<br />
              - Second item<br />
              - Third item<br />
            </code>
          </td>
        </tr>
        <tr>
          <td>
            <b>Code</b>
          </td>
          <td><code>`code`</code></td>
        </tr>
        <tr>
          <td>
            <b>Horizontal Rule</b>
          </td>
          <td><code>---</code></td>
        </tr>
        <tr>
          <td>
            <b>Link</b>
          </td>
          <td><code>[title](https://www.example.com)</code></td>
        </tr>
        <tr>
          <td>
            <b>Image</b>
          </td>
          <td><code>![alt text](image.jpg)</code></td>
        </tr>
        <tr>
          <td>
            <b>Table</b>
          </td>
          <td>
            <code>
              | Syntax | Description |<br />
              | ----------- | ----------- |<br />
              | Header | Title |<br />
              | Paragraph | Text |
            </code>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import Message from "@kinherit/framework/component.display/message";
import Button from "@kinherit/framework/component.input/button";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { SelectFieldStyles } from "@kinherit/framework/component.input/select-field/styles";
import TextField, {
  FormTextField,
} from "@kinherit/framework/component.input/text-field";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import {
  OpenAlertDialog,
  OpenFormDialog,
} from "@kinherit/framework/global/dialog";
import { EmailTemplateService } from "@kinherit/ts-common";
import { marked } from "marked";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "MarkDownEditor",
  components: { Button, TextField, Message },
  props: {
    // The v-model:title binding
    title: {
      type: String,
      default: "",
    },
    // The v-model:body binding
    body: {
      type: String,
      default: "",
    },
    // The subject title from the template, fallback for when the title is empty
    subjectTemplate: {
      type: String as PropType<string | null>,
      default: null,
    },
    // The body from the template, fallback for when the body is empty
    bodyTemplate: {
      type: String as PropType<string | null>,
      default: null,
    },
    // The templates (body & title) parameters
    params: {
      type: Object as PropType<Record<string, string | null>>,
      default: () => ({}),
    },
  },
  emits: ["update:body", "update:title"],
  data: () => ({
    // Body
    bodyTimeout: null as null | globalThis.NodeJS.Timeout,
    localBody: null as null | string,
    renderedBody: null as null | string,
    // Title
    titleTimeout: null as null | globalThis.NodeJS.Timeout,
    localTitle: null as null | string,
    renderedTitle: null as null | string,
    // Select
    select: {
      start: null as null | number,
      end: null as null | number,
    },
    // Controls
    tooltip: {
      bold: "Alt + B",
      italic: "Alt + I",
      link: "Alt + K",
      orderedList: "Alt + O",
      unorderedList: "Alt + U",
      table: "Alt + T",
    },
    selectedBody: null as null | string,
    optionsHeading: {
      h1: "# Heading1",
      h2: "## Heading2",
      h3: "### Heading3",
      h4: "#### Heading4",
      h5: "##### Heading5",
      h6: "###### Heading6",
    },
    optionsInsert: [
      {
        group: "Global (Where Possible)",
        options: {
          recipients: "recipients",
          hasHave: "hasHave",
          currentuser: "currentuser [new]",
          currentuserfirstname: "currentuserfirstname [new]",
          estateplanner: "estateplanner [new]",
          estateplannerfirstname: "estateplannerfirstname [new]",
          legalassistant: "legalassistant [new]",
          legalassistantfirstname: "legalassistantfirstname [new]",
          specialist: "specialist [deprecated]",
          aboutkinherit: "aboutkinherit [deprecated]",
        },
      },
      {
        group: "Process / Explainer (i.e Kinvault Emails)",
        options: {
          primaryfirstname: "primaryfirstname",
          secondaryfirstname: "secondaryfirstname",
          primaryfullname: "primaryfullname",
          secondaryfullname: "secondaryfullname",
          yourOrName: "yourOrName",
          kinvaultloginurl: "kinvaultloginurl",
        },
      },
      {
        group: "Officer Emails",
        options: {
          officername: "officername",
          testatorfullname: "testatorfullname",
          testatorfirstname: "testatorfirstname",
        },
      },
      {
        group: "Lead Emails",
        options: {
          leadfirstname: "leadfirstname",
          introducerfirstname: "introducerfirstname",
          introducername: "introducername",
          introducercompany: "introducercompany",
        },
      },
      {
        group: "TruReg Emails",
        options: {
          trname: "trname",
          trpin: "trpin",
          trref: "trref",
        },
      },
    ],
    insertvalue: "" as string,
    insertHeading: "" as string,
  }),
  beforeMount() {
    window.Kernel.injectStylesheet("select-field", SelectFieldStyles);
  },
  mounted(): void {
    // Set the template body as the default body if the body is empty
    if (!this.body && this.bodyTemplate) {
      this.computedBody = this.bodyTemplate;
    } else if (this.body) {
      this.computedBody = this.body;
    }

    // Set the template title as the default title if the title is empty
    if (!this.title && this.subjectTemplate) {
      this.computedTitle = this.subjectTemplate;
    } else if (this.title) {
      this.computedTitle = this.title;
    }
  },
  computed: {
    computedBody: {
      get(): string {
        return this.localBody || this.body;
      },
      set(value: string) {
        this.localBody = value;

        // clear the timeout if one is already set
        if (this.bodyTimeout) {
          clearTimeout(this.bodyTimeout);
        }

        // update the rendered body after a timeout
        this.bodyTimeout = setTimeout(() => {
          this.$emit("update:body", value);
          this.renderBody();
        }, 500);
      },
    },
    computedTitle: {
      get(): string {
        return this.localTitle || this.title;
      },
      set(value: string) {
        this.localTitle = value;

        // clear the timeout if one is already set
        if (this.titleTimeout) {
          clearTimeout(this.titleTimeout);
        }

        // update the rendered title after a timeout
        this.titleTimeout = setTimeout(() => {
          this.$emit("update:title", value);
          this.renderedTitle = EmailTemplateService.compile({
            template: value,
            params: this.params ?? {},
            markupParameters: true,
            throwOnMissing: false,
          });
        }, 500);
      },
    },
    computedSelectionParts(): null | {
      before: string;
      selected: string;
      after: string;
    } {
      // if there is no computed body, return null
      const computedBody = this.computedBody;
      if (!computedBody) {
        return null;
      }

      // if there is no selection, return null
      const { start, end } = this.select;
      if (start === null || end === null) {
        return null;
      }

      // return the parts of the selection
      return {
        before: computedBody.slice(0, start),
        selected: computedBody.slice(start, end),
        after: computedBody.slice(end),
      };
    },
    hasMissingVariables(): boolean {
      return (
        EmailTemplateService.hasMissingVariables(
          this.computedBody ?? "",
          this.params,
        ) ||
        EmailTemplateService.hasMissingVariables(
          this.computedTitle ?? "",
          this.params,
        )
      );
    },
  },
  methods: {
    openHelpDialog() {
      const html = (this.$refs.refHelp as HTMLElement).outerHTML.replace(
        "display: none;",
        "",
      );

      OpenAlertDialog({
        dialog: {
          title: "Help",
          html: html,
          showFooter: false,
        },
      });
    },
    getSelection(): {
      start: number;
      end: number;
    } {
      // get the text that is selected in the textarea
      const textarea = this.$el.querySelector(
        "textarea.markdown-editor__body-field",
      ) as HTMLTextAreaElement;

      // get the start and end positions of the selection or cursor
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      // update the select object
      return {
        start,
        end,
      };
    },
    async renderBody(): Promise<void> {
      try {
        const compiledBody = EmailTemplateService.compile({
          template: this.computedBody,
          params: this.params ?? {},
          markupParameters: true,
          throwOnMissing: false,
        });

        const renderedBody = await marked(compiledBody, {
          pedantic: false,
          gfm: true,
        });

        this.renderedBody = renderedBody;
      } catch (error) {
        console.error(error);
        return;
      }

      // update the selection
      this.select = this.getSelection();

      // get the selected text, else return
      const selection = this.computedSelectionParts;

      if (!selection) {
        this.selectedBody = null;
        return;
      }

      // get the selected word(s)
      let content = selection.selected;

      // wrap the selected word with the <mark> tag
      content = content.replace(/[a-zA-Z0-9]+/g, (match) => {
        return `<mark>${match}</mark>`;
      });

      // join the marked up word(s) with the rest of the content
      content = `${selection.before}${content}${selection.after}`;

      // remove <mark> tags that are inside of {{ }} tags
      content = content.replace(/{{.*?<mark>.*?}}/g, (match) => {
        return match.replace(/<mark>/g, "");
      });

      // remove </mark> tags that are inside of {{ }} tags
      content = content.replace(/{{.*?<\/mark>.*?}}/g, (match) => {
        return match.replace(/<\/mark>/g, "");
      });

      // update the textarea with the marked up content
      content = await marked(
        EmailTemplateService.compile({
          template: content,
          params: this.params,
          markupParameters: true,
          throwOnMissing: false,
        }),
        {
          pedantic: false,
          gfm: true,
        },
      );

      //Prevent <a> tags from opening when clicked
      content = content.replace(
        /<a/g,
        "<a onclick='alert(`Link prevented from navigating away from this page`); return false;'",
      );

      // remove **
      content = content.replace(/\*\*/g, "");

      // update the selected body
      this.selectedBody = content;
    },
    clearSelection() {
      // reset the select object
      this.select = {
        start: null,
        end: null,
      };

      // clear the selected text from the preview area
      this.computedBody = this.localBody || this.body;
    },
    injectTag(value: string) {
      if (value === "") {
        return;
      }

      this.injectContent({
        content: `{{${value}}}`,
        restoreSelection: false,
      });

      this.insertvalue = "";
    },
    injectContent({
      content,
      trimSelection = true,
      restoreSelection = true,
    }: {
      content: string;
      trimSelection?: boolean;
      restoreSelection?: boolean;
    }): void {
      // get the selected text, else return
      const selection = this.computedSelectionParts;
      if (!selection) {
        const cursorPositions = this.getSelection();
        const cursorPosition =
          cursorPositions.start ??
          cursorPositions.end ??
          this.computedBody.length;
        this.computedBody = `${this.computedBody.slice(
          0,
          cursorPosition,
        )}${content}${this.computedBody.slice(cursorPosition)}`;
        this.focusTextArea(restoreSelection);
        return;
      }

      // get the start and end position to be updated
      let newStart = selection.before.length;
      let newEnd = selection.before.length + content.length;

      if (trimSelection) {
        // trim the selected text
        const startChange = content.trimStart().length - content.length;
        const endChange = content.trimEnd().length - content.length;

        // update the start and end positions
        newStart += startChange;
        newEnd += endChange;
      }

      // update the body property
      this.computedBody = `${selection.before}${content}${selection.after}`;

      // update the selection
      this.select = {
        start: newStart,
        end: newEnd,
      };

      // restore the cursor position
      this.focusTextArea(restoreSelection);
    },
    focusTextArea(restoreSelection: boolean): void {
      // restore the cursor position
      const textarea = this.$el.querySelector(
        "textarea.markdown-editor__body-field",
      ) as HTMLTextAreaElement;

      if (!textarea) {
        return;
      }

      textarea.focus();
      setTimeout(() => {
        if (!restoreSelection) {
          textarea.setSelectionRange(this.select.end, this.select.end);
          return;
        }
        textarea.setSelectionRange(this.select.start, this.select.end);
      }, 0);
    },
    toggleWrapper(wrapper: string) {
      // get the selected text, else return
      const selection = this.computedSelectionParts;
      if (!selection) {
        return;
      }

      // get the selected word(s)
      let content = selection.selected;

      if (content.startsWith(wrapper) && content.endsWith(wrapper)) {
        // if the text is wrapped in ** then remove the ** from the text
        content = content.slice(wrapper.length, -wrapper.length);
      } else {
        // remove the ** from the text
        content = content.replace(
          new RegExp(`${wrapper.replace("*", "\\*")}`, "g"),
          "",
        );

        // remove any spaces from the start and end of the text
        const spaceBefore = content.match(/^\s+/);
        const spaceAfter = content.match(/\s+$/);
        content = content.trim();

        // wrap the selected word with the ** tag
        content = `${wrapper}${content}${wrapper}`;

        // add the spaces back to the start and end of the text
        if (spaceBefore) {
          content = `${spaceBefore[0]}${content}`;
        }

        if (spaceAfter) {
          content = `${content}${spaceAfter[0]}`;
        }
      }

      this.injectContent({ content });
    },
    async injectLink() {
      // get the selected text
      const selection = this.computedSelectionParts;

      const data = await OpenFormDialog({
        dialog: {
          title: "Insert Link",
          type: "width-auto",
        },
        form: defineForm({
          name: "link",
          data: () => ({
            url: "",
            text: selection?.selected ?? "",
          }),
          formAreas: (data) => [
            defineFormArea({
              name: "link",
              template: GridLayout(["text", "url"]),
              data,
              components: () => ({
                text: [
                  FormTextField({
                    props: {
                      label: "Text",
                    },
                    models: {
                      value: "text",
                    },
                  }),
                ],
                url: [
                  FormUrlField({
                    props: {
                      label: "URL",
                    },
                    models: {
                      value: "url",
                    },
                  }),
                ],
              }),
            }),
          ],
        }),
      }).catch(() => this.focusTextArea(false));

      if (!data) {
        return;
      }

      // wrap the selected word with the ** tag
      const content = `[${data.text}](${data.url})`;

      this.injectContent({ content });
    },
    async injectTable() {
      const data = await OpenFormDialog({
        dialog: {
          title: "Insert Table",
          type: "width-auto",
        },
        form: defineForm({
          name: "table",
          data: () => ({
            rows: 2,
            columns: 2,
          }),
          formAreas: (data) => [
            defineFormArea({
              name: "table",
              template: GridLayout(["rows", "columns"]),
              data,
              components: () => ({
                rows: [
                  FormNumberField({
                    props: {
                      label: "Rows",
                    },
                    models: {
                      value: "rows",
                    },
                  }),
                ],
                columns: [
                  FormNumberField({
                    props: {
                      label: "Columns",
                    },
                    models: {
                      value: "columns",
                    },
                  }),
                ],
              }),
            }),
          ],
        }),
      }).catch(() => this.focusTextArea(false));

      if (!data) {
        return;
      }

      // create the table
      let content = "";

      for (let i = 0; i < data.rows; i++) {
        content += "|";

        for (let j = 0; j < data.columns; j++) {
          content += ` ${i === 0 ? "Header" : "Cell"} ${
            i === 0 ? "" : `${i}/`
          }${j + 1} |`;
        }

        content += "\n";

        if (i === 0) {
          content += "|";

          for (let j = 0; j < data.columns; j++) {
            content += " --- |";
          }

          content += "\n";
        }
      }

      this.injectContent({ content, restoreSelection: false });
    },
  },
});

//@todo change fillscreen to is-fullheight ?
</script>

<style lang="scss">
.markdown-editor {
  &__toolbar {
    margin: 0 0 1em;
  }

  .select &__insert-select {
    width: 6em;
  }
  .select &__insert-heading {
    width: 8em;
  }

  &__text-area-label {
    display: block;
    position: relative;

    textarea {
      width: auto;
      font: inherit;
      resize: none;
      width: 100%;
      height: 100%;
      line-height: inherit;
      position: absolute;
    }

    &::after {
      content: attr(data-value) " ";
      visibility: hidden;
      white-space: pre-wrap;
    }
  }
}
</style>
