import { OptionsSelectField } from "@/config/form.config";
import { CreateBrandedKinvault } from "@/config/model.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const CreateBrandedKinvaultForm = (data = CreateBrandedKinvault()) =>
  defineForm({
    name: "create-branded-kinvault-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-branded-kinvault-form-area",
        data,
        template: GridLayout([
          ["name"],
          ["brand", "type"],
          ["clientPoolType"],
          ["fontendUrl"],
        ]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                reference: "profile.organisationName",
                label: "Name",
                validators: ["required"],
                message: "Main Company Name",
              },
              models: {
                value: {
                  get: (data) => data.profile.organisationName,
                  set: (value, data) => {
                    data.profile.organisationName = value;
                    data.systemEmail = !value
                      ? value
                      : `${value} <no-reply@kinvault.com>`;
                  },
                },
              },
            }),
          ],
          brand: [
            FormTextField({
              props: {
                label: "Brand",
                validators: ["required"],
                message:
                  "The brand short form, i.e kinherit or futuresprotected",
              },
              models: {
                value: "kinvaultTheme.brand",
              },
            }),
          ],
          type: [
            OptionsSelectField({
              vModel: "type",
              group: "organisationTypes",
              props: {
                placeholder: "Select Type",
                validators: ["required"],
              },
            }),
          ],
          clientPoolType: [
            OptionsSelectField({
              vModel: "clientPoolType",
              group: "clientPoolTypes",
              props: {
                placeholder: "Select Type",
                message:
                  "Shared: Any Adviser can access any client; Peradviser: can only access own clients unless granted implicit access",
                validators: ["required"],
              },
            }),
          ],
          fontendUrl: [
            FormUrlField({
              props: {
                label: "Frontend URL",
                validators: ["required"],
                noProtocol: true,
              },
              models: {
                value: "frontendUrl",
              },
            }),
          ],
        }),
      }),
    ],
  });
