import { Api } from "@/service/api.service";
import { Kintin, Property } from "@kinherit/sdk";

interface CreatePropertyMessage {
  kintin: string | Kintin;
  property: Property;
}

interface CreatePropertyResponse {
  property: Property;
}

export const CreatePropertyHandler = async (
  message: CreatePropertyMessage,
): Promise<CreatePropertyResponse> => {
  const { property: propertyData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-property/{kintin}/properties",
    {
      kintin: message.kintin,
    },
  )
    .method("post")

    .body({
      kintin: message.kintin.toString(),
      titleDeed: message.property.$data.titleDeed,
      price: message.property.$data.price,
      value: message.property.$data.value,
      lessMortgage: message.property.$data.lessMortgage,
      notes: message.property.$data.notes,
      address: message.property.$data.address,
      howOwned: message.property.$data.howOwned,
      ownership: message.property.$data.ownership,
      type: message.property.$data.type,
    })
    .result();

  const property = Property.$inflate(propertyData).first();

  if (!property) {
    throw new Error("Failed to create property");
  }

  message.property.$delete();

  return { property };
};
