<template>
  <div v-if="kintin">
    <CallScriptSection title="Financial Advisers">
      <Message
        v-if="null !== (kintin?.referral?.contact ?? null)"
        color="is-warning"
      >
        <strong>Important:</strong> user has been introduced by:
        <br /><strong>{{ kintin?.referral?.contact?.profile.fullName }}</strong>
      </Message>
      <b>
        I mentioned our Kinvault can link to advisers, to help the process of
        probate and reduce the risk of loss.<br />
      </b>
      <em v-if="null === (kintin?.referral?.contact ?? null)">
        [If Referrer is IFA, check yes and skip question:]
      </em>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.3b"
        :kintin="kintin"
        is-reversed
      />
      <br /><br />
      <span
        v-if="
          null !== (kintin?.referral?.contact ?? null) && checkIsYes('3.0.3b')
        "
      >
        <b>
          As you were introduced by
          {{ kintin?.referral?.contact?.profile.fullName }}; I assume you want
          them to be notified if something happens.
        </b>
        <br />
        <em>
          [Yes = Specialist to add adviser as default: TICK BOTH NOTIFY ON DEATH
          AND DEFAULT IFA FOR TRUST]
          <br />[No = Not a problem]
        </em>
      </span>
      <br /><br />
      <span
        v-if="
          null === (kintin?.referral?.contact ?? null) && checkIsYes('3.0.3b')
        "
      >
        <b>
          It would be useful to take their details?.
          <br /><br />
          OK, so if you did go ahead with us and we write your Will for you,
          would you be OK if we contacted them so we can notify them if
          something happens to you?
        </b>
        <br />
        <em>
          [Yes = Specialist to write note to contact IFA / elevate to SMT]
          <br />[No = Not a problem]
        </em>
      </span>
      <b>
        So, the other thing with your financial adviser is that we’d be able to
        give them access to the Asset part of your Kinvault, which means they
        can add and update assets for you, which can work really well. I assume
        this is OK but let me know if you prefer it’s left switched off.
      </b>
      <br /><em
        >[Specialist note: giving Financial Adviser access only works when a
        Financial Adviser has a branded Kinvault. However, we should record
        consent here irrespective]</em
      >
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="9.0.0"
        :kintin="kintin"
        is-reversed
      />
      <b
        ><br /><br />
        <span v-if="checkIsYes('4.7.1e')">
          We discussed LPAs, we would include wording to allow your Financial
          Adviser to continue to manage your assets should you be unable.
        </span>
        <span v-else>
          We skipped over LPAs, but one of the common reasons to setup a finance
          LPA is to let your Financial Adviser continue to manage your assets
          should you be incapacitated. Do you know if this setup already, or
          should we rethink the LPA question?
        </span>
        <br /><br />
        As a sensible default we also normally name your Financial Adviser as
        adviser for any assets in trust. This can be changed by your trustees if
        needed.
      </b>
      <br /><br />
      <AdvisersContainer :kintin="kintin" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.3d"
        :kintin="kintin"
        is-reversed
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advIfaNotes"
      :has-continue="false"
    />
  </div>
</template>

<script lang="ts">
import AdvisersContainer from "@/module/kinvault.kintin/component/address-book/AdvisersContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import Message from "@kinherit/framework/component.display/message";
import { defineComponent } from "vue";

export default defineComponent({
  name: "IFAsTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    ConfirmCheck,
    CallScriptSection,
    Message,
    AdvisersContainer,
    CallScriptSpecialistNotesSection,
  },
});
</script>
