import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between } from "@kinherit/orm/index";
import { Api, EmailAddress, EmailLog, IEmailLog } from "@kinherit/sdk";

export interface ReadEmailLogMessage {
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IEmailLog;
    direction: "asc" | "desc";
  };
}

interface ReadEmailLogResponse {
  emailLogs: Array<EmailLog>;
  emailAddresses: Array<EmailAddress>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadEmailLogHandler = async (
  message: ReadEmailLogMessage,
): Promise<ReadEmailLogResponse> => {
  const request = Api.resource("portal", "/v2/portal/email-log")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(EmailLog).where({
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    emailLogs: EmailLog.$inflate(
      response.emailLog,
      message.sort,
      response.$rootIds,
    ),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),

    pagination: getPagination(response),
  };
};
