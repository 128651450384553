import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface CreateUserMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface CreateUserMfAuthMethodResponse {
  mfAuthMethod: MfAuthMethod;
}

export const CreateUserMfAuthMethodHandler = async (
  message: CreateUserMfAuthMethodMessage,
): Promise<CreateUserMfAuthMethodResponse> => {
  const credential = message.mfAuthMethod.credential;
  const respense = await Api.resource(
    "portal",
    "/v2/portal/user/{credential}/mf-auth-methods",
    {
      credential,
    },
  )
    .method("post")
    .body({
      type: message.mfAuthMethod.$data.type,
      alias: message.mfAuthMethod.$data.alias,
      device: message.mfAuthMethod.$data.device,
      key: message.mfAuthMethod.$data.key,
      credential: message.mfAuthMethod.$data.credential,
      setupCompleteAt: message.mfAuthMethod.$data.setupCompleteAt,
    })
    .result();

  const mfAuthMethod = MfAuthMethod.$inflate(respense.mfAuthMethod).first();

  if (!mfAuthMethod) {
    throw new Error("Failed to create auth method");
  }

  message.mfAuthMethod.$delete();

  return {
    mfAuthMethod,
  };
};
