import { KinvaultKintinDetailsFileVaultBreadCrumb } from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

/** FileVault */
export const KinvaultKintinDetailsFileVaultRoute =
  "KinvaultKintinDetailsFileVault";
const KinvaultKintinDetailsFileVaultURI =
  "/kinvault/:kintin/documents/file-vault";
export type KinvaultKintinDetailsFileVaultParams = RouteParamObject<
  typeof KinvaultKintinDetailsFileVaultURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsFileVaultRoute,
    path: KinvaultKintinDetailsFileVaultURI,
    component: () =>
      import(
        /* webpackChunkName: "KintinDetailsFileVault" */ "./KintinDetails.FileVault.vue"
      ),
    meta: {
      breadcrumbs: KinvaultKintinDetailsFileVaultBreadCrumb,
    },
  },
];
