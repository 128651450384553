import { Api } from "@/service/api.service";
import { Kintin } from "@kinherit/sdk";

interface DownloadClientSummaryMessage {
  content: string;
  kintin: Kintin;
  password: boolean;
}

interface DownloadClientSummaryResponse {
  [key: string]: never;
}

export const DownloadClientSummaryHandler = async (
  message: DownloadClientSummaryMessage,
): Promise<DownloadClientSummaryResponse> => {
  const { kintin, content } = message;

  const ref = kintin.ref;
  const name = kintin.friendlyName;

  await Api.resource("portal", "/v2/portal/kintin-call-script/client-summary")
    .method("post")
    .body({
      content,
      kintin: kintin.id,
      password: message.password,
    })

    .download({
      encoding: "application/pdf",
      fileName: `${ref} Client Summary - ${name}`,
    });

  return {};
};
