import { EmailTemplate } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { EmailTemplateDetailsParams } from "../page/details";

export const EmailTemplateDetailsMixin = defineComponent({
  name: "EmailTemplateDetailsMixin",
  computed: {
    $params(): EmailTemplateDetailsParams {
      return this.$route.params as EmailTemplateDetailsParams;
    },
    emailTemplate(): EmailTemplate | null {
      const params: EmailTemplateDetailsParams = this.$route.params as any;

      const emailtemplate = EmailTemplate.$findOne(
        params.emailTemplate as string,
      );

      return emailtemplate;
    },
  },
});
