<template>
  <ProfileSummary
    :key="key"
    v-if="officer && !loading"
    class="kinvault-officer-details"
    :profile-id="officer.profile.id"
    :tabs="tabs"
    :has-context-menu="showContextMenu"
    @context-menu="contextMenu"
  >
    <template #default>
      <RouterChildView name="default">
        <KinvaultOfficerDetailsNotes />
      </RouterChildView>
    </template>
    <template #sidebar-below>
      <RouterChildView name="sidebar">
        <KinvaultOfficerDetailsSidebar />
      </RouterChildView>
    </template>
  </ProfileSummary>
  <PageNotFound v-else-if="!loading" />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultOfficerDetailsWrapper",
  "route": "KinvaultOfficerDetails",
  "selector": ".kinvault-officer-details",
  "imports": {
    "KinvaultOfficerDetailsNoteWrapper": "@/module/kinvault.officer/page/details/note/KinvaultOfficerDetails.Note.test",
    "OfficerDetailsEmailLogWrapper": "@/module/kinvault.officer/page/details/email-log/OfficerDetails.EmailLog.test",
    "KinvaultOfficerDetailsCallScriptWrapper": "@/module/kinvault.officer/page/details/call-script/KinvaultOfficerDetails.CallScript.test",
    "KinvaultOfficerDetailsAnswerphoneMessageScriptWrapper": "@/module/kinvault.officer/page/details/answerphone-message-script/KinvaultOfficerDetails.AnswerphoneMessageScript.test",
    "KinvaultOfficerDetailsSidebarWrapper": "@/module/kinvault.officer/page/details/KinvaultOfficerDetails.Sidebar.test"
  },
  "methods": {
    "contextMenu": {
      "type": "context-menu",
      "selector": ".js-page-header-context-menu",
      "items": ["Edit Profile"]
    },
    "tabs": {
      "type": "tabs",
      "items": ["Notes", "Emails", "Call Script", "Answerphone Message Script"]
    },
    "notes": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultOfficerDetailsNoteWrapper"
    },
    "emailLog": {
      "type": "to-one",
      "selector": "",
      "wrapper": "OfficerDetailsEmailLogWrapper"
    },
    "callScript": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultOfficerDetailsCallScriptWrapper"
    },
    "answerphoneMessageScript": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultOfficerDetailsAnswerphoneMessageScriptWrapper"
    },
    "sidebar": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultOfficerDetailsSidebarWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { KinvaultOfficerDetailsMixin } from "@/module/kinvault.officer/mixin/kinvault-officer-details.mixin";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import ProfileSummary from "@/shared/component/profile/ProfileSummary.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { RouterChildView } from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { KinvaultOfficerDetailsParams, KinvaultOfficerDetailsRoute } from ".";
import { KinvaultOfficerDetailsAnswerphoneMessageScriptRoute } from "./answerphone-message-script";
import { KinvaultOfficerDetailsCallScriptRoute } from "./call-script";
import { OfficerDetailsEmailLogRoute } from "./email-log";
import KinvaultOfficerDetailsSidebar from "./KinvaultOfficerDetails.Sidebar.vue";
import { KinvaultOfficerDetailsNoteRoute } from "./note";
import KinvaultOfficerDetailsNotes from "./note/KinvaultOfficerDetails.Note.vue";
import { KinvaultOfficerDetailsUpdateProfileRoute } from "./update-profile";

export default defineComponent({
  name: KinvaultOfficerDetailsRoute,
  mixins: [KinvaultOfficerDetailsMixin],
  components: {
    PageNotFound,
    RouterChildView,
    KinvaultOfficerDetailsNotes,
    ProfileSummary,
    KinvaultOfficerDetailsSidebar,
  },
  computed: {
    showContextMenu(): boolean {
      return AuthService.hasPermission("kintin:write");
    },
  },
  data: (ctx) => ({
    key: 0,
    loading: true,
    tabs: [
      {
        label: "Notes",
        route: {
          name: KinvaultOfficerDetailsNoteRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.phone.icon,
      },
      {
        label: "Emails",
        route: {
          name: OfficerDetailsEmailLogRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.email.icon,
      },
      {
        label: "Call Script",
        route: {
          name: KinvaultOfficerDetailsCallScriptRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.phone.icon,
      },
      {
        label: "Answerphone Message Script",
        route: {
          name: KinvaultOfficerDetailsAnswerphoneMessageScriptRoute,
          params: ctx.$params,
        },
        icon: StyleService.icon.phone.icon,
      },
    ],
    tab: 0,
  }),
  beforeRouteUpdate(): void {
    this.key++;
  },
  async beforeRouteEnter(to): Promise<void> {
    await window.Kernel.ActionBus.kinvaultOfficer.record(
      to.params as KinvaultOfficerDetailsParams,
    );
  },
  async mounted(): Promise<void> {
    this.loading = false;
  },
  methods: {
    async contextMenu(): Promise<void> {
      if (!this.officer) {
        return;
      }

      await AuthService.openContextMenu({
        request: {
          items: [
            {
              title: "Edit Profile",
              line1: `Edit ${this.officer.profile.fullName} profile`,
              iconLeft: StyleService.icon.profile.icon,
              permissions: "kintin:write",
              action: this.editProfile,
            },
          ],
          trackingField: "title",
          titleField: "title",
          line1Field: "line1",
          iconLeftField: "iconLeft",
        },
        path: "permissions",
        filter: "all",
        callback: (item) => item.action(),
      })();
    },
    editProfile(): void {
      window.Kernel.Router.push({
        name: KinvaultOfficerDetailsUpdateProfileRoute,
        params: this.$params,
      });
    },
  },
});
</script>
