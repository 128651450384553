import { Api } from "@/service/api.service";
import { KnowledgeBaseCategory } from "@kinherit/sdk";

interface CreateKnowledgeBaseCategoryMessage {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

interface CreateKnowledgeBaseCategoryResponse {
  knowledgeBaseCategory: KnowledgeBaseCategory;
}

export const CreateKnowledgeBaseCategoryHandler = async (
  message: CreateKnowledgeBaseCategoryMessage,
): Promise<CreateKnowledgeBaseCategoryResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/knowledge-base-category",
  )
    .method("post")

    .body({
      slug: message.knowledgeBaseCategory.slug,
      title: message.knowledgeBaseCategory.title,
    })
    .result();

  const knowledgeBaseCategory = KnowledgeBaseCategory.$inflate(
    response.knowledgeBaseCategory,
  ).first();

  if (!knowledgeBaseCategory) {
    throw new Error("Failed to create knowledge base category");
  }

  message.knowledgeBaseCategory.$delete();

  return {
    knowledgeBaseCategory,
  };
};
