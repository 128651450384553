<template>
  <div v-if="data">
    <strong>Appointment of Guardians</strong>
    <p>
      If I am the sole surviving parent with parental responsibility for my
      children then I appoint
      <span v-if="data.guardiansAreSameForAllChildren">
        <span v-html="helpers.var(renderDistinctMainGuardians())" />
        as guardian(s) for any of my children under the age of 18
        <span v-if="distinctReserveGuardians.length > 0">
          but if such guardians are unable or unwilling to act then I appoint
          <span v-html="helpers.var(renderDistinctReserveGuardians())" />
          as guardian(s) for any of my children under the age of 18
        </span>
      </span>
      <span v-if="!data.guardiansAreSameForAllChildren">
        <!--@todo list each child childrenWithGuardians-->
        [GUARDIANS ARE DIFFERENT PER CHILD - OUTPUT SEPERATELY]
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: `GuardiansSection`,
  mixins: [WillBuilderService.mixin],
  computed: {
    distinctMainGuardians() {
      if (!this.data) {
        return [];
      }

      return this.data.mainGuardians.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.person.id === item.person.id),
      );
    },
    distinctReserveGuardians() {
      if (!this.data) {
        return [];
      }

      return this.data.reserveGuardians.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.person.id === item.person.id),
      );
    },
  },
  methods: {
    renderDistinctMainGuardians() {
      return `
      ${this.distinctMainGuardians
        .map((guardian) => this.helpers.formatOfficer(guardian.person))
        .join(" and ")} `;
    },
    renderDistinctReserveGuardians() {
      return `
      ${this.distinctReserveGuardians
        .map((guardian) => this.helpers.formatOfficer(guardian.person))
        .join(" and ")} `;
    },
  },
});
</script>
