import { Api } from "@/service/api.service";
import { KintinAccess } from "@kinherit/sdk";

interface DeleteKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface DeleteKintinAccessResponse {
  [key: string]: never;
}

export const DeleteKintinAccessHandler = async (
  message: DeleteKintinAccessMessage,
): Promise<DeleteKintinAccessResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-user-access/{kintinAccess}",
    message,
  )
    .method("delete")

    .result();

  message.kintinAccess.$delete();

  return {};
};
