import {
  CreatedAtField,
  IntroducerCompanyField,
  IntroducerNetworkField,
  TagsField,
  UserField,
} from "@/config/form.config";

import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";

import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";

export const customReportTypes = {
  feeReport: "Fee Report",
  leadReport: "Lead Report",
  contactReport: "Contact Report",
};

export const includeFeeOptions = [
  {
    text: "Fees: 1 Introducer Fees",
    value: "introducerFees",
    description: "Fees & date paid to the introducer",
  },
  {
    text: "Fees: 2 Agent Fees",
    value: "agentFees",
    description: "Fees & date paid to the agent",
  },
  {
    text: "Fees: 3 Parent Agent Fees",
    value: "parentAgentFees",
    description: "Fees & date paid to the parent agent",
  },
];

export const includeReferredByOptions = [
  {
    text: "Lead: Referred By",
    value: "leadReferredBy",
    description: "Who the lead was referred by",
  },
];
export const includeOrderOptions = [
  {
    text: "Order: Order Value",
    value: "orderValue",
    description: "Order Value",
  },
  {
    text: "Order: Products Taken",
    value: "orderProducts",
    description: "List of Products Taken",
  },
];

export const clientOptions = [
  {
    text: "Client Details",
    value: "clientDetails",
    description: "Client Details including Name",
  },
  {
    text: "Client External Ref",
    value: "clientExternalRef",
    description: "Lead External Reference if available",
  },
];

export const includeDetailOptions = [
  ...includeReferredByOptions,
  ...clientOptions,
  ...includeFeeOptions,
  ...includeOrderOptions,
];

// Sets the default year to the current year
const thisYear = new Date().getFullYear();

// Sets the default start and end dates to the first and last days of the current year
const defaultStartDate = new Date(thisYear, 0, 1).getTime();
const defaultEndDate = new Date(thisYear, 11, 31).getTime();

export const ReadDownloadReportFilters = () =>
  defineForm({
    name: "read-download-report-filters",
    props: {},
    data: () => ({
      customReportType: "feeReport" as keyof typeof customReportTypes,
      company: null as string | null,
      network: null as string | null,
      created: [defaultStartDate, defaultEndDate] as [number, number] | null,
      includeOptions: [] as Array<{
        value: string;
        text: string;
        description: string;
      }>,
      salesTeam: null as string | null,
      assignedTo: null as string | null,
      tags: [] as string[],
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "read-downloads-report-filters-area",
        data,
        template: GridLayout([
          ["customReportType" as const],
          ["formDescription" as const],
          ["created" as const],
          ["network" as const, "introducerCompany" as const, "tags" as const],
          ["includeOptions" as const],
          ["salesTeam", "assignedTo" as const],
        ]),
        components: () => ({
          customReportType: [
            FormRadioField({
              reactive: true,
              props: {
                label: "Report Type",
                options: customReportTypes,
                reference: "customReportType",
                showClearButton: false,
                direction: "is-horizontal",
              },
              models: {
                value: {
                  get: () => data.customReportType,
                  set: (value, data, controls) => {
                    data.customReportType = value;
                    // controls.incrementFormRenderKey();
                  },
                },
              },
            }),
          ],
          formDescription: [
            FormTitle({
              props: {
                text: "Fee Report",
                size: "is-5",
                vIf: () => data.customReportType === "feeReport",
              },
            }),
            FormElement({
              props: {
                text: "Uses order payment date: This report will return a list of fees paid to introducers, agents, and parent agents. i.e, Show me all orders for company 'a' and the respective fees paid with the given time range.",
                vIf: () => data.customReportType === "feeReport",
              },
            }),
            FormTitle({
              props: {
                text: "Lead Report",
                size: "is-5",
                vIf: () => data.customReportType === "leadReport",
              },
            }),

            FormElement({
              props: {
                text: "Uses lead creation date: i.e, Show me all leads for company 'a' and who they were referred by within the given time range. You can also include fee payments where appropriate.",
                vIf: () => data.customReportType === "leadReport",
              },
            }),
            FormTitle({
              props: {
                text: "Contact Report",
                size: "is-5",
                vIf: () => data.customReportType === "contactReport",
              },
            }),
            FormElement({
              props: {
                text: "Get a list of companies and their contacts, optionally filtered by sales team and estate planner.",
                vIf: () => data.customReportType === "contactReport",
              },
            }),
          ],
          network: [
            IntroducerNetworkField({
              props: {
                size: "is-normal",
                vModel: "network",
                label: "Network",
                message: "Filter by Network",
              },
              simplifyData: true,
            }),
          ],
          introducerCompany: [
            IntroducerCompanyField({
              props: {
                size: "is-normal",
                vModel: "company",
                label: "Company",
                message: "Filter by Company",
              },
              simplifyData: true,
            }),
          ],
          tags: [
            TagsField({
              props: {
                vModel: "tags",
                label: "Company Tags",
                isMultiSelect: true,
                message: "Filter by Companies tagged with x",
              },
              simplifyData: true,
            }),
          ],
          created: [
            CreatedAtField({
              props: {
                vModel: "created",
                label: "Date Range",
                vIf: () =>
                  ["feeReport", "leadReport"].includes(data.customReportType),
                message: "Filter by Date Range",
              },
              simplifyData: true,
            }),
          ],
          includeOptions: [
            FormAutoCompleteField({
              props: {
                label: "Include",
                reference: "includeOptions",
                showClearButton: true,
                isMultiSelect: true,
                options: includeDetailOptions,
                mapOptions: {
                  value: "value",
                  label: "text",
                  line1: "description",
                },
                isScrollable: true,
                vIf: () =>
                  ["feeReport", "leadReport"].includes(data.customReportType),

                message: "Select fields to return",
              },
              models: {
                value: "includeOptions",
              },
            }),
          ],
          salesTeam: [
            UserField({
              props: {
                label: "Sales Team",
                vModel: "salesTeam",
                isMultiSelect: true,
                message: "Filter by Sales Team",
                vIf: () => ["contactReport"].includes(data.customReportType),
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
              },
              simplifyData: true,
            }),
          ],
          assignedTo: [
            UserField({
              props: {
                label: "Estate Planner",
                vModel: "assignedTo",
                isMultiSelect: true,
                message: "Filter by Assigned Estate Planner",
                vIf: () => ["contactReport"].includes(data.customReportType),
              },
              query: {
                roles: {
                  role: In(["staff", "admin"]),
                },
                displayUserInPortal: Equal(true),
                isEstatePlanner: Equal(true),
              },
              simplifyData: true,
            }),
          ],
        }),
      }),
    ],
  });
