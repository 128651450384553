<template>
  <GiftRecipientsContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookGiftRecipientsWrapper",
  "route": "KinvaultKintinDetailsAddressBookGiftRecipients",
  "extends": {
    "name": "GiftRecipientsContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/GiftRecipientsContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateGift } from "@/config/model.config";
import { UpdateGiftForm } from "@/module/kinvault.kintin/form/update-gift.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";

import GiftRecipientsContainer from "@/module/kinvault.kintin/component/address-book/GiftRecipientsContainer.vue";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Gift, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookGiftRecipientsRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookGiftRecipientsRoute,
  components: {
    GiftRecipientsContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  emits: ["reload"],
  data: () => ({
    filtered: {
      gifts: Array<Gift>(),
      jointGifts: Array<Gift>(),
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson?.profile.fullName) {
        names.push(this.kintin?.primaryPerson?.profile.fullName);
      }
      if (this.kintin?.secondaryPerson?.profile.fullName) {
        names.push(this.kintin?.secondaryPerson?.profile.fullName);
      }

      if (this.kintin?.type?.value === "joint") {
        names.push("Joint Gifts");
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const gifts = selectedPerson?.sendingGifts ?? [
        ...(this.kintin?.primaryPerson?.sendingGifts ?? []),
        ...(this.kintin?.secondaryPerson?.sendingGifts ?? []),
      ];

      this.filtered.gifts = gifts.filter((g) => !g.onSecondDeath);
      this.filtered.jointGifts = gifts.filter((g) => g.onSecondDeath);
      this.filtered.people = gifts
        .pluck("forPerson")
        .unique("id")
        .filter(Boolean)
        .sortBy("profile.fullName")
        .cast<Person>();
    },
    async createGift() {
      if (!this.kintin) {
        return;
      }

      let sentBy = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === sentBy && this.kintin.type.value === "single") {
        sentBy = this.kintin.primaryPerson?.id;
      }

      const gift = CreateGift({
        sentBy,
        onSecondDeath: undefined === sentBy,
      });

      try {
        await UpdateGiftForm({
          data: gift,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create gift",
          },
        });
      } catch {
        gift.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/gift/create", {
      //   gift,
      //   kintin: this.kintin.id,
      // });

      await window.Kernel.ActionBus.kinvaultKintin.gift.create({
        gift,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
