import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  UserField,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { Equal } from "@kinherit/orm";

export const ReadOfficerForm = () => {
  return MasterListFiltersForm({
    name: "filter-officer-form",
    data: () => ({
      customer: "",
      status: Array<string>(),
      assignedTo: null as null | string,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      customer: [
        FormTextField({
          props: {
            label: "Customer",
            placeholder: "Name / Email / Phone",
          },
          models: {
            value: "customer",
          },
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "officerCallStatus",
          props: {
            isMultiSelect: true,
            label: "Call Status",
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({
      assignedTo: [
        UserField({
          props: {
            label: "Assigned to",
            vModel: "assignedTo",
          },
          simplifyData: true,
          query: {
            displayUserInPortal: Equal(true),
          },
        }),
      ],
    }),
  });
};
