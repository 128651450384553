import { Between, Equal, Like } from "@kinherit/orm/index";
import { Api, EntityHistory, IEntityHistory } from "@kinherit/sdk";

export interface ReadUserActivityMessage {
  user: string;
  subject?: null | string;
  action?: null | "INSERT" | "SOFT_REMOVE" | "RECOVER" | "UPDATE" | "REMOVE";
  date?: null | [number, number];
  route?: null | string;
  origin?: null | string;
  request?: null | string;
  objectId?: null | string;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IEntityHistory;
    direction: "asc" | "desc";
  };
}

interface ReadUserActivityResponse {
  logs: Array<EntityHistory>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadUserActivityHandler = async (
  message: ReadUserActivityMessage,
): Promise<ReadUserActivityResponse> => {
  const request = Api.resource("portal", "/v2/portal/user/{user}/activity", {
    user: message.user,
  })
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "date",
      sortDirection: message.sort?.direction ?? "desc",
    })
    .paginate(message.pagination ?? false);

  request.buildQuery(EntityHistory).where({
    entityName: Like(message.subject?.replaceAll(" ", "")),
    action: Equal(message.action),
    date: Between(message.date),
    route: Equal(message.route),
    origin: Equal(message.origin),
    request: Equal(message.request),
    objectId: Equal(message.objectId),
  });

  const response = await request.result();

  return {
    logs: EntityHistory.$inflate(
      response.entityHistory,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
