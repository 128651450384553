import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

type Message = {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

type Response = {
  emailLog: EmailLog;
};

export const UpdateEmailLogHandler = async (
  message: Message,
): Promise<Response> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}",
    [IntroducerContact.$name]:
      "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}",
    [Kintin.$name]: "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}",
    [Lead.$name]: "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}",
    [Person.$name]: "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}",
    [BrandedKinvault.$name]:
      "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}",
  } as const;

  const route = routes[message.owner.$name];

  const localAttachments = message.emailLog.attachments.pluck("id") ?? [];
  const locatNamedAttachments =
    message.emailLog.namedAttachments.pluck("id") ?? [];

  const response = await Api.resource("portal", route, {
    owner: message.owner.$id,
    emailLog: message.emailLog,
  })
    .method("patch")

    .body({
      template: message.emailLog.$data.template,
      status: message.emailLog.$data.status,
      data: message.emailLog.$data.data,
      preloadedFiles: message.emailLog.$data.preloadedFiles,
      cc: message.emailLog.$data.cc,
      bcc: message.emailLog.$data.bcc,
      to: message.emailLog.$data.to,
      from: message.emailLog.$data.from,
      title: message.emailLog.$data.title,
      subject: message.emailLog.$data.subject,
      sentAt: message.emailLog.$data.sentAt,
      html: message.emailLog.$data.html,
      markdown: message.emailLog.$data.markdown,
    })
    .result();

  const emailLog = EmailLog.$inflate(response.emailLog).first();

  if (!emailLog) {
    throw new Error("Failed to update email log");
  }

  // Unnamed attachments
  const remoteAttachments = emailLog.attachments.pluck("id");
  const deletedAttachments = remoteAttachments.remove(...localAttachments);

  // await window.Kernel.ActionBus.execute("core/email-log/attachment/upload", {
  //   owner: message.owner,
  //   emailLog,
  //   files: message.files,
  // });
  await window.Kernel.ActionBus.core.emailLog.uploadAttachment({
    owner: message.owner,
    emailLog,
    files: message.files,
  });

  for (const fileLog of deletedAttachments) {
    // await window.Kernel.ActionBus.execute(
    //   "core/email-log/attachment/delete",
    //   {
    //     owner: message.owner,
    //     emailLog,
    //     fileLog,
    //   },
    // );
    await window.Kernel.ActionBus.core.emailLog.deleteAttachment({
      owner: message.owner,
      emailLog,
      fileLog,
    });
  }

  // Named attachments
  const remoteNamedAttachments = emailLog.namedAttachments.pluck("id");
  const deletedNamedAttachments = remoteNamedAttachments.remove(
    ...locatNamedAttachments,
  );

  // await window.Kernel.ActionBus.execute(
  //   "core/email-log/named-attachment/create",
  //   {
  //     owner: message.owner,
  //     emailLog,
  //     files: message.requiredFiles,
  //   },
  // );
  await window.Kernel.ActionBus.core.emailLog.createNamedAttachment({
    owner: message.owner,
    emailLog,
    files: message.requiredFiles,
  });

  for (const emailNamedAttachment of deletedNamedAttachments) {
    // await window.Kernel.ActionBus.execute(
    //   "core/email-log/named-attachment/delete",
    //   {
    //     owner: message.owner,
    //     emailLog,
    //     emailNamedAttachment,
    //   },
    // );
    await window.Kernel.ActionBus.core.emailLog.deleteNamedAttachment({
      owner: message.owner,
      emailLog,
      emailNamedAttachment,
    });
  }

  return {
    emailLog,
  };
};
