import { Api } from "@/service/api.service";
import {
  AccountReferral,
  AccountReferralCode,
  Kintin,
  Order,
  OrderItem,
  Profile,
  User,
} from "@kinherit/sdk";

type RecordOrderMessage = {
  order: Order | string;
};

interface RecordOrderResponse {
  orders: Array<Order>;
  kintins: Array<Kintin>;
  referrals: Array<AccountReferral>;
  profiles: Array<Profile>;
  referralCodes: Array<AccountReferralCode>;
  orderItems: Array<OrderItem>;
  users: Array<User>;
}

export const RecordOrderHandler = async (
  message: RecordOrderMessage,
): Promise<RecordOrderResponse> => {
  const response = await Api.resource("portal", "/v2/portal/order/{order}", {
    order: "string" === typeof message.order ? message.order : message.order.id,
  })
    .method("get")

    .result();

  return {
    orders: Order.$inflate(response.order, undefined, response.$rootIds),
    kintins: Kintin.$inflate(response.kintin),
    referrals: AccountReferral.$inflate(response.accountReferral),
    profiles: Profile.$inflate(response.profile),
    referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
    orderItems: OrderItem.$inflate(response.orderItem),
    users: User.$inflate(response.user),
  };
};
