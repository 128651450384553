import { Api } from "@/service/api.service";
import { AccountReferralCode } from "@kinherit/sdk";

interface DeleteAccountReferralCodeMessage {
  accountReferralCode: AccountReferralCode;
}

interface DeleteAccountReferralCodeResponse {
  [key: string]: never;
}

export const DeleteAccountReferralCodeHandler = async (
  message: DeleteAccountReferralCodeMessage,
): Promise<DeleteAccountReferralCodeResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/referral/account-referral-code/{accountReferralCode}",
    {
      accountReferralCode: message.accountReferralCode,
    },
  )
    .method("delete")

    .result();

  message.accountReferralCode.$delete();

  return {};
};
