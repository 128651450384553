import { Api } from "@/service/api.service";
import { Tag } from "@kinherit/sdk";

export const CreateTagAction = async (message: Tag): Promise<Tag> => {
  const data = await Api.resource("portal", "/v2/portal/tag")
    .method("post")

    .body({
      name: message.name,
      description: message.description,
    })
    .result();

  const tag = Tag.$inflate(data.tag).first();

  if (!tag) {
    throw new Error("Failed to create tag");
  }

  message.$delete();

  return tag;
};
