import { Api } from "@/service/api.service";
import { WillRevision } from "@kinherit/sdk";

interface UpdateWillRevisionMessage {
  willRevision: WillRevision;
}

interface UpdateWillRevisionResponse {
  willRevision: WillRevision;
}

export const UpdateWillRevisionHandler = async (
  message: UpdateWillRevisionMessage,
): Promise<UpdateWillRevisionResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/will-revision/{willRevision}",
    {
      willRevision: message.willRevision.id,
    },
  )
    .method("patch")

    .body({
      approvedAt: message.willRevision.$data.approvedAt,
      approvedBy: message.willRevision.$data.approvedBy,
      content: message.willRevision.$data.content,
      createdBy: message.willRevision.$data.createdBy,
      hasBusinessClause: message.willRevision.$data.hasBusinessClause,
      hasBusinessTrust: message.willRevision.$data.hasBusinessTrust,
      hasEstateProtectionTrust:
        message.willRevision.$data.hasEstateProtectionTrust,
      hasIipTrust: message.willRevision.$data.hasIipTrust,
      kintin: message.willRevision.$data.kintin,
    })
    .result();

  const willRevision = WillRevision.$inflate(response.willRevision).first();

  if (!willRevision) {
    throw new Error("Will revision not found");
  }

  return {
    willRevision,
  };
};
