import { Api } from "@/service/api.service";
import { Kintin } from "@kinherit/sdk";

export interface UpdateKintinMessage {
  kintin: Kintin;
}

interface UpdateKintinResponse {
  kintin: Kintin;
}

export const UpdateKintinHandler = async (
  message: UpdateKintinMessage,
): Promise<UpdateKintinResponse> => {
  const response = await Api.resource("portal", "/v2/portal/kintin/{kintin}", {
    kintin: message.kintin,
  })
    .method("patch")

    .body({
      whyEol: message.kintin.$data.whyEol,
      legalAssistants: message.kintin.$data.legalAssistants,
      estatePlanners: message.kintin.$data.estatePlanners,
      statusUpdatedAt: message.kintin.$data.statusUpdatedAt,
      statusChangeReason: message.kintin.$data.statusChangeReason,
      externalLeadId: message.kintin.$data.externalLeadId,
      primaryPerson: message.kintin.$data.primaryPerson,
      secondaryPerson: message.kintin.$data.secondaryPerson,
      type: message.kintin.$data.type,
      status: message.kintin.$data.status,
      callScript: message.kintin.$data.callScript,
      permissions: message.kintin.$data.permissions,
      sourceKintin: message.kintin.$data.sourceKintin,
      process: message.kintin.$data.process,
      stage: message.kintin.$data.stage,
      referral: {
        nextActionAt: message.kintin.referral?.$data.nextActionAt,
      },
      isActive: message.kintin.$data.isActive,
      isEscalated: message.kintin.$data.isEscalated,
    })
    .result();

  const kintin = Kintin.$inflate(response.kintin).first();

  if (!kintin) {
    throw new Error("Failed to update kintin");
  }

  return {
    kintin,
  };
};
