import { Api } from "@/service/api.service";
import {
  AccountReferral,
  AccountReferralCode,
  Address,
  EmailAddress,
  ILead,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  PhoneNumber,
  Profile,
  User,
  Website,
} from "@kinherit/sdk";

interface RecordLeadMessage {
  lead: string;
  sort?: {
    by: keyof ILead;
    direction: "asc" | "desc";
  };
}

interface RecordLeadResponse {
  leads: Array<Lead>;
  profiles: Array<Profile>;
  referrals: Array<AccountReferral>;
  phoneNumbers: Array<PhoneNumber>;
  emailAddresses: Array<EmailAddress>;
  addresses: Array<Address>;
  kintin: Array<Kintin>;
  websites: Array<Website>;
  users: Array<User>;
  introducerCompanies: Array<IntroducerCompany>;
  referralCodes: Array<AccountReferralCode>;
  introducerContacts: Array<IntroducerContact>;
}

export const RecordLeadHandler = async (
  message: RecordLeadMessage,
): Promise<RecordLeadResponse> => {
  const request = Api.resource("portal", "/v2/portal/lead/{lead}", {
    lead: message.lead,
  }).method("get");
  const response = await request.result();

  return {
    leads: Lead.$inflate(response.lead),
    profiles: Profile.$inflate(response.profile),
    referrals: AccountReferral.$inflate(response.accountReferral),
    kintin: Kintin.$inflate(response.kintin),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    users: User.$inflate(response.user),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    addresses: Address.$inflate(response.address),
    websites: Website.$inflate(response.website),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
    introducerContacts: IntroducerContact.$inflate(response.introducerContact),
  };
};
