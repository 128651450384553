import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KnowledgeBaseDocumentDetailsParams } from "../page";

export const KnowledgeBaseDocumentMixin = () =>
  defineComponent({
    name: "KnowledgeBaseDocumentMixin",
    computed: {
      $params(): KnowledgeBaseDocumentDetailsParams {
        return this.$route.params as KnowledgeBaseDocumentDetailsParams;
      },
      knowledgeBaseDocument(): KnowledgeBaseDocument | null {
        return KnowledgeBaseDocument.$findOne(this.$params.document);
      },
      knowledgeBaseCategory(): KnowledgeBaseCategory | null {
        return KnowledgeBaseCategory.$findOne(this.$params.category);
      },
    },
  });
