import { Api } from "@/service/api.service";
import { Adviser, Kintin } from "@kinherit/sdk";

interface CreateAdviserMessage {
  kintin: string | Kintin;
  adviser: Adviser;
}

interface CreateAdviserResponse {
  adviser: Adviser;
}

export const CreateAdviserHandler = async (
  message: CreateAdviserMessage,
): Promise<CreateAdviserResponse> => {
  let { defaultTrustIfa } = message.adviser.$data;

  if (null === defaultTrustIfa || undefined === defaultTrustIfa) {
    defaultTrustIfa = false;
  }

  const { adviser: adviserData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-adviser/{kintin}/person/adviser",
    {
      kintin: message.kintin,
    },
  )
    .method("post")

    .body({
      defaultTrustIfa: defaultTrustIfa,
      notes: message.adviser.$data.notes,
      person: message.adviser.$data.person,
      forPerson: message.adviser.$data.forPerson,
      kintin: message.kintin.toString(),
    })
    .result();

  const adviser = Adviser.$inflate(adviserData).first();

  if (!adviser) {
    throw new Error("Failed to create adviser");
  }

  message.adviser.$delete();

  return { adviser };
};
