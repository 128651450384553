import { Api } from "@/service/api.service";
import { IntroducerNetwork, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerNetworkMessage {
  query?: QueryMask<typeof IntroducerNetwork>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerNetworkResponse {
  introducerNetworks: Array<IntroducerNetwork>;
}

export const ReadIntroducerNetworkHandler = async (
  message: ReadIntroducerNetworkMessage,
): Promise<ReadIntroducerNetworkResponse> => {
  const request = Api.resource("core", "/v2/core/select/introducer-network")
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(IntroducerNetwork).where(message.query ?? {});

  const response = await request.result();

  return {
    introducerNetworks: IntroducerNetwork.$inflate(
      response.introducerNetwork,
      undefined,
      response.$rootIds,
    ),
  };
};
