import { DangerDialog } from "@/config/dialog.config";
import {
  OptionsAutoCompleteField,
  PersonField,
  SharedFormProps,
} from "@/config/form.config";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormRadioField } from "@kinherit/framework/component.input/radio-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In, NotIn } from "@kinherit/orm";
import { Beneficiary, Kintin, Option } from "@kinherit/sdk";
import { reactive } from "vue";
import { AddKintinPersonButton } from "./shared.form";

export const UpdateBeneficiaryForm = ({
  data,
  kintin,
}: {
  data: Beneficiary;
  kintin: Kintin;
}) => {
  const localData = reactive({
    isClassGift:
      null !== data.classGiftType || null !== data.reserveclassGiftType,
  });

  const ifPredeceasedOtherId = Option.$findOneBy({
    group: "ifPredeceasedTypes",
    value: "other",
  })?.id;

  data.ifPredeceased =
    data.ifPredeceased ??
    Option.$findOneByOrThrow({
      group: "ifPredeceasedTypes",
      value: "theirconcern",
    });

  return defineForm({
    name: "update-beneficiary-form",
    props: SharedFormProps,
    data: () => data,
    validate: (data) => {
      if (
        data.ifPredeceased?.id === ifPredeceasedOtherId &&
        data.reservePerson === null &&
        data.reserveclassGiftType === null
      ) {
        DangerDialog({
          dialog: {
            title: `Error`,
            message: `Please set either a reserve person or reserve class gift type`,
          },
        });
        return false;
      }

      return true;
    },
    formAreas: (data) => [
      defineFormArea({
        name: "beneficiary-form-area",
        data,
        template: GridLayout([
          ["forPerson"],
          { is: "hr" },
          ["isClassGift", "position"],
          ["to"],
          ["shareFraction", "sharePercentage"],
          { is: "hr" },
          ["ifPredeceased"],
          ["reserveTo"],
          ["notes"],
        ]),
        components: () => ({
          forPerson: [
            PersonField({
              reactive: true,
              props: {
                label: (data) =>
                  `Who is ${
                    data.person?.profile.fullName ?? `this person`
                  } a beneficiary for?`,
                reference: "forPerson",
                vModel: {
                  get: (data) => data.forPerson,
                  set: (value, data) => {
                    data.forPerson = value;

                    if (data.forPerson?.id === data.person?.id) {
                      data.person = null;
                    }
                  },
                },
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          isClassGift: [
            FormRadioField({
              reactive: true,
              props: {
                label: "Beneficiary Type",
                options: {
                  "0": "Individual / Charity",
                  "1": "Class Gift",
                },
                size: "is-medium",
                reference: "isClassGift",
              },
              models: {
                value: {
                  get: () => (localData.isClassGift === true ? "1" : "0"),
                  set: (value, data) => {
                    localData.isClassGift = value === "1";

                    if (localData.isClassGift) {
                      data.person = null;
                    } else {
                      data.classGiftType = null;
                    }
                  },
                },
              },
            }),
          ],
          position: [
            FormRadioField({
              reactive: true,
              props: {
                label: "Position",
                options: {
                  "0": "First Line Beneficiary",
                  "1": "Secondary Line Beneficiary",
                  "2": "Reserve Beneficiary",
                },
                message: (data) =>
                  1 === data.position
                    ? `*CTTC restricts this to children of married couples.`
                    : undefined,
                messageColor: "has-text-danger",
                size: "is-medium",
                reference: "position",
              },
              models: {
                value: {
                  get: (data) => data.position?.toString() ?? "0",
                  set: (value, data) => {
                    data.position = parseInt(value);
                  },
                },
              },
            }),
          ],
          to: [
            PersonField({
              props: {
                vIf: (data) =>
                  !localData.isClassGift &&
                  data.forPerson?.id === kintin.$data.primaryPerson,
                label: "Who is the beneficiary?",
                vModel: "person",
                validators: ["required"],
              },
              query: {
                id: NotIn([kintin.$data.primaryPerson]),
                kintin: {
                  id: Equal("string" === typeof kintin ? kintin : kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            PersonField<Beneficiary>({
              props: {
                vIf: (data) =>
                  !localData.isClassGift &&
                  data.forPerson.id === kintin.$data.secondaryPerson,
                label: "Who is the beneficiary?",
                vModel: "person",
                validators: ["required"],
              },
              query: {
                id: NotIn([kintin.$data.secondaryPerson]),
                kintin: {
                  id: Equal("string" === typeof kintin ? kintin : kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            OptionsAutoCompleteField({
              vModel: "classGiftType",
              group: "classGiftTypes",
              props: {
                label: "Class Gift To",
                vIf: () => localData.isClassGift,
                validators: ["required"],
              },
            }),
          ],
          notes: [
            FormTextField({
              props: {
                label: "Notes",
                isTextArea: true,
              },
              models: {
                value: "notes",
              },
            }),
          ],
          ifPredeceased: [
            OptionsAutoCompleteField({
              reactive: true,
              vModel: "ifPredeceased",
              group: "ifPredeceasedTypes",
              props: {
                validators: ["required"],
              },
            }),
          ],
          reserveTo: [
            PersonField<Beneficiary>({
              reactive: true,
              props: {
                label: "Who is the reserve beneficiary?",
                reference: "reservePerson",
                vModel: {
                  get: (data) => data.reservePerson,
                  set: (value, data) => {
                    data.reservePerson = value;
                    data.reserveclassGiftType = null;
                  },
                },
                vIf: () => data.ifPredeceased?.id === ifPredeceasedOtherId,
              },
              query: {
                id: NotIn([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
                kintin: {
                  id: Equal("string" === typeof kintin ? kintin : kintin.id),
                },
              },
              slots: {
                right: [
                  AddKintinPersonButton({ kintin, targetProperty: "person" }),
                ],
              },
            }),
            OptionsAutoCompleteField({
              reactive: true,
              vModel: {
                get: (data) => data.reserveclassGiftType,
                set: (value, data) => {
                  data.reserveclassGiftType = value;
                  data.reservePerson = null;
                },
              },
              group: "classGiftTypes",
              props: {
                reference: "reserveclassGiftType",
                label: "Reserve Class Gift Type",
                vIf: () => data.ifPredeceased?.id === ifPredeceasedOtherId,
                //validators: ["required"],
              },
            }),
          ],
          shareFraction: [
            FormTextField({
              reactive: true,
              props: {
                label: "Share Fraction",
                validators: [
                  [
                    "regex",
                    {
                      buildin: "FRACTION",
                    },
                  ],
                ],
                state: (data) =>
                  data.sharePercentage ? "disabled" : undefined,
                showClearButton: true,
                reference: "shareFraction",
              },
              models: {
                value: {
                  get: (data) => data.shareFraction,
                  set: (value, data) => {
                    data.shareFraction = value;
                    data.sharePercentage = null;
                  },
                },
              },
            }),
          ],
          sharePercentage: [
            FormNumberField({
              reactive: true,
              props: {
                label: "Share Percentage",
                min: 0,
                max: 100,
                step: 0.1,
                state: (data) => (data.shareFraction ? "disabled" : undefined),
                showClearButton: true,
                reference: "sharePercentage",
              },
              models: {
                value: {
                  get: (data) => data.sharePercentage?.toNumber() ?? null,
                  set: (value, data) => {
                    data.sharePercentage = value.toString();
                    data.shareFraction = null;
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
