<template>
  <ExecutorsContainer
    v-if="kintin"
    :kintin="kintin"
    @reload="$emit('reload')"
  />
</template>

<cypress-wrapper lang="json">
{
  "name": "KinvaultKintinDetailsAddressBookExecutorsWrapper",
  "route": "KinvaultKintinDetailsAddressBookExecutors",
  "extends": {
    "name": "ExecutorsContainerWrapper",
    "path": "@/module/kinvault.kintin/component/address-book/ExecutorsContainer.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateExecutor } from "@/config/model.config";
import ExecutorsContainer from "@/module/kinvault.kintin/component/address-book/ExecutorsContainer.vue";
import { UpdateExecutorForm } from "@/module/kinvault.kintin/form/update-executor.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { StyleService } from "@/service/style.service";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Executor, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsAddressBookExecutorsRoute } from ".";

export default defineComponent({
  name: KinvaultKintinDetailsAddressBookExecutorsRoute,
  components: {
    ExecutorsContainer,
  },
  mixins: [KinvaultKintinDetailsMixin],
  emits: ["reload"],
  data: () => ({
    filtered: {
      executors: {
        main: Array<Executor>(),
        reserve: Array<Executor>(),
      },
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  mounted(): void {
    this.filter();
  },
  computed: {
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson?.profile.fullName) {
        names.push(this.kintin?.primaryPerson?.profile.fullName);
      }
      if (this.kintin?.secondaryPerson?.profile.fullName) {
        names.push(this.kintin?.secondaryPerson?.profile.fullName);
      }

      names.push("People");

      const tabs = names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });

      return tabs;
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
    isPeopleTab(): boolean {
      return this.selectedTab === this.tabConfig.length - 1;
    },
  },
  methods: {
    filter(): void {
      const selectedPerson = this.tabConfig[this.selectedTab].person;

      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      const executors = selectedPerson?.appointedExecutor ?? [
        ...(this.kintin?.primaryPerson?.appointedExecutor ?? []),
        ...(this.kintin?.secondaryPerson?.appointedExecutor ?? []),
      ];

      this.filtered.executors = executors.groupBy({
        main: (e) => !e.isReserve,
        reserve: (e) => e.isReserve,
      });

      this.filtered.people = executors
        .pluck("person")
        .unique("id")
        .filter(Boolean)
        .cast<Person>()
        .sortBy("profile.fullName");
    },
    async createExecutor() {
      if (!this.kintin) {
        return;
      }

      let forPerson = this.tabConfig[this.selectedTab].person?.id;

      if (undefined === forPerson && this.kintin.type.value === "single") {
        forPerson = this.kintin.primaryPerson?.id;
      }

      const executor = CreateExecutor({
        forPerson,
      });

      try {
        await UpdateExecutorForm({
          data: executor,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create executor",
          },
        });
      } catch {
        executor.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/executor/create", {
      //   executor,
      //   kintin: this.kintin,
      // });

      await window.Kernel.ActionBus.kinvaultKintin.executor.create({
        executor,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
