import { Api, Kintin, Person } from "@kinherit/sdk";
export interface MergeSignDocContactMessage {
  person: Person | string;
  kintin: Kintin | string;
  property:
    | "organisationName"
    | "organisationNumber"
    | "title"
    | "firstName"
    | "middleNames"
    | "lastName"
    | "phoneNumbers"
    | "emails"
    | "addresses"
    | "dateOfBirth";
}

export interface MergeSignDocContactResponse {
  [key: string]: never;
}

export const MergeSignDocContactHandler = async (
  message: MergeSignDocContactMessage,
): Promise<MergeSignDocContactResponse> => {
  const person =
    "string" === typeof message.person
      ? message.person
      : message.person.$data.id;
  const kintin =
    "string" === typeof message.kintin
      ? message.kintin
      : message.kintin.$data.id;

  await Api.resource("portal", "/v2/portal/kintin-sign-doc/merge-contacts")
    .method("post")
    .body({
      person,
      kintin,
      property: message.property,
    })
    .result();

  return {};
};
