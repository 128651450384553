import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface DeleteBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral:
    | BrandedKinvaultSubscriptionReferral
    | string;
}

interface DeleteBrandedKinvaultSubscriptionReferralResponse {
  [key: string]: never;
}

export const DeleteBrandedKinvaultSubscriptionReferralHandler = async (
  message: DeleteBrandedKinvaultSubscriptionReferralMessage,
): Promise<DeleteBrandedKinvaultSubscriptionReferralResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral/{brandedKinvaultSubscriptionReferral}",
    {
      brandedKinvaultSubscriptionReferral:
        typeof message.brandedKinvaultSubscriptionReferral,
    },
  ).method("delete");

  await request.result();

  return {};
};
