<template>
  <Button
    :icon-left="icon"
    color="is-plain"
    aria-label="Show Notes"
    @click.stop="handleClick"
    :count="numNotes"
  />
</template>

<script lang="ts">
import NotePage from "@/module/core/component/note/NotePage.vue";
import { StyleService } from "@/service/style.service";
import { Button } from "@kinherit/framework/component.input/button";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import {
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
} from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "ShowNotesButton",
  components: { Button },
  emits: ["refresh"],
  props: {
    hasWritePermission: {
      type: Boolean,
      required: true,
    },
    isIntroducerNote: {
      type: Boolean,
      default: false,
    },
    isCommunicationNote: {
      type: Boolean,
      default: false,
    },
    kintin: {
      type: Object as PropType<Kintin | null>,
      default: null,
    },
    lead: {
      type: Object as PropType<Lead | null>,
      default: null,
    },
    introducerCompany: {
      type: Object as PropType<IntroducerCompany | null>,
      default: null,
    },
    introducerContact: {
      type: Object as PropType<IntroducerContact | null>,
      default: null,
    },
    showAccountButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icon: StyleService.icon.notes.icon,
  }),
  computed: {
    numNotes(): number {
      return this.lead?.noteCount ?? this.kintin?.noteCount ?? 0;
    },
  },
  methods: {
    async handleClick(): Promise<void> {
      const FormComponent = FormComponentWrapper(NotePage);

      await defineForm({
        name: "Notes",
        formAreas: () => [
          defineFormArea({
            name: "Notes",
            components: () => ({
              default: [
                FormComponent({
                  props: {
                    hideCard: true,
                    ...this.$props,
                  },
                }),
              ],
            }),
          }),
        ],
      }).dialog({
        dialog: {
          title: "Notes",
        },
        button: {
          cancel: {
            show: false,
          },
          ok: {
            text: "Close",
          },
        },
      });

      this.$emit("refresh");
    },
  },
});
</script>
