<template>
  <div v-if="kintin">
    <CallScriptSection title="Exclusions">
      <b>
        Moving on to exclusions. The purpose of excluding people is to protect
        your Will from challenge that they had been accidentally forgotten. Is
        there anyone, typically ex-partners or family members, that you think
        should be listed?
      </b>
      <br /><br /><em>[If no response prompt with]</em>
      <b>
        <br />It is to make sure no one contests your Will, so if you do think
        of anything let me know.
      </b>
      <br />
      <br />
      <ExclusionsContainer :kintin="kintin" />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        :kintin="kintin"
        step="4.5.1"
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advExclusionNotes"
      @save-continue="saveAndContinueHandler"
    />
  </div>
</template>

<script lang="ts">
import ExclusionsContainer from "@/module/kinvault.kintin/component/address-book/ExclusionsContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExclusionsTab",
  mixins: [KinvaultKintinDetailsCallScriptMixin],
  components: {
    CallScriptSection,
    ConfirmCheck,
    CallScriptSpecialistNotesSection,
    ExclusionsContainer,
  },
  emits: ["save-and-continue"],
  methods: {
    async saveAndContinueHandler() {
      this.$emit("save-and-continue");
    },
  },
});
</script>
