import { Api } from "@/service/api.service";
import {
  BrandedKinvault,
  DefaultBrandedKinvaultReferralCode,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadDefaultBrandedKinvaultReferralCodeMessage {
  query?: QueryMask<typeof DefaultBrandedKinvaultReferralCode>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCodes: Array<DefaultBrandedKinvaultReferralCode>;
  brandedKinvault: Array<BrandedKinvault>;
  profiles: Array<Profile>;
}

export const ReadDefaultBrandedKinvaultReferralCodeHandler = async (
  message: ReadDefaultBrandedKinvaultReferralCodeMessage,
): Promise<ReadDefaultBrandedKinvaultReferralCodeResponse> => {
  const request = Api.resource(
    "core",
    "/v2/core/select/default-branded-kinvault-referral-code",
  )
    .method("get")

    .paginate(message.pagination ?? false);

  request
    .buildQuery(DefaultBrandedKinvaultReferralCode)
    .where(message.query ?? {});

  const response = await request.result();

  return {
    defaultBrandedKinvaultReferralCodes:
      DefaultBrandedKinvaultReferralCode.$inflate(
        response.defaultBrandedKinvaultReferralCode,
        undefined,
        response.$rootIds,
      ),
    brandedKinvault: BrandedKinvault.$inflate(response.brandedKinvault),
    profiles: Profile.$inflate(response.profile),
  };
};
