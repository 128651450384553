import { User } from "@kinherit/sdk";

const UserBreadcrumb = {
  text: "Users",
  route: { name: "AdminUserMasterList" },
};

export const UserBreadCrumb = (params: Record<string, string>) => {
  const profile = User.$findOne(params.user ?? "")?.profile;

  return [
    UserBreadcrumb,
    {
      text: profile?.fullName ?? "",
      route: { name: "AdminUserDetails", params },
    },
  ];
};

export const UserDetailsUpdateProfileBreadCrumb = (
  params: Record<string, string>,
) => [
  ...UserBreadCrumb(params),
  {
    text: "Edit Profile",
    route: { name: "AdminUserDetailsUpdateProfile", params },
  },
];

export const UserDetailsCreditNotesBreadCrumb = (
  params: Record<string, string>,
) => [
  ...UserBreadCrumb(params),
  {
    text: "Credit Notes",
    route: { name: "AdminUserDetailsCreditNotes", params },
  },
];
