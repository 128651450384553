import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  AccountReferralCodeDetailsBreadCrumb,
  AccountReferralCodeMasterListBreadCrumb,
} from "../../breadcrumbs";
import {
  AccountReferralCodeDetailsSummaryRoute,
  Routes as SummaryRoutes,
} from "./summary";

export const AccountReferralCodeDetailsRoute = "AccountReferralCodeDetails";
export const AccountReferralCodeDetailsURI =
  "/admin/account-referral-code/:accountReferralCode";
export type AccountReferralCodeDetailsParams = RouteParamObject<
  typeof AccountReferralCodeDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: AccountReferralCodeDetailsRoute,
    path: AccountReferralCodeDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "AccountReferralCodeDetails" */ "./AccountReferralCodeDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        AccountReferralCodeMasterListBreadCrumb,
        AccountReferralCodeDetailsBreadCrumb(params),
      ],
    },
    children: [...SummaryRoutes],
    redirect: { name: AccountReferralCodeDetailsSummaryRoute },
  },
];
