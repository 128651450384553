import { Api } from "@/service/api.service";
import { BrandedKinvault } from "@kinherit/sdk";

export interface UpdateBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault;
}

interface UpdateBrandedKinvaultResponse {
  brandedKinvault: BrandedKinvault;
}

export const UpdateBrandedKinvaultHandler = async (
  message: UpdateBrandedKinvaultMessage,
): Promise<UpdateBrandedKinvaultResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/{brandedKinvault}",
    {
      brandedKinvault: message.brandedKinvault.id,
    },
  )
    .method("patch")
    .body({
      systemEmail: message.brandedKinvault.$data.systemEmail,
      type: message.brandedKinvault.$data.type,
      clientPoolType: message.brandedKinvault.$data.clientPoolType,
      frontendUrl: message.brandedKinvault.$data.frontendUrl,
      notes: message.brandedKinvault.$data.notes,
      siteMode: message.brandedKinvault.$data.siteMode,
      allowDefaultAssetAccess:
        message.brandedKinvault.$data.allowDefaultAssetAccess,
      useSetupWizard: message.brandedKinvault.$data.useSetupWizard,
      useBrandedKinvaultInvitations:
        message.brandedKinvault.$data.useBrandedKinvaultInvitations,
      allowPublicRegistration:
        message.brandedKinvault.$data.allowPublicRegistration,
      useUserInvitations: message.brandedKinvault.$data.useUserInvitations,
      restrictUserEmailDomain:
        message.brandedKinvault.$data.restrictUserEmailDomain,
      allowedEmailDomains: message.brandedKinvault.$data.allowedEmailDomains,
      xeroContact: message.brandedKinvault.$data.xeroContact,
      ownerCompanies: message.brandedKinvault.$data.ownerCompanies,
      memberCompanies: message.brandedKinvault.$data.memberCompanies,
    });
  const result = await request.result();

  const brandedKinvault = BrandedKinvault.$inflate(
    result.brandedKinvault,
  ).first();

  if (!brandedKinvault) {
    throw new Error("Failed to update branded kinvault");
  }

  return {
    brandedKinvault,
  };
};
