import { Api, BrandedKinvaultPricingSchema } from "@kinherit/sdk";

export interface CreateBrandedKinvaultPricingSchemaMessage {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

interface CreateBrandedKinvaultPricingSchemaResponse {
  brandedKinvaultPricingSchema: BrandedKinvaultPricingSchema;
}

export const CreateBrandedKinvaultPricingSchemaHandler = async (
  message: CreateBrandedKinvaultPricingSchemaMessage,
): Promise<CreateBrandedKinvaultPricingSchemaResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-kinvault-pricing-schema",
  )
    .method("post")
    .body({
      name: message.brandedKinvaultPricingSchema.name,
      schema: message.brandedKinvaultPricingSchema.schema,
      brandedKinvaults:
        message.brandedKinvaultPricingSchema.$data.brandedKinvaults,
      startDate: message.brandedKinvaultPricingSchema.startDate?.formatMachine,
      endDate: message.brandedKinvaultPricingSchema.endDate?.formatMachine,
      isDefault: message.brandedKinvaultPricingSchema.isDefault,
    });

  const response = await request.result();

  const brandedKinvaultPricingSchema = BrandedKinvaultPricingSchema.$inflate(
    response.brandedKinvaultPricingSchema,
    undefined,
    response.$rootIds,
  ).first();

  if (!brandedKinvaultPricingSchema) {
    throw new Error("Failed to create branded kinvault pricing schema");
  }

  message.brandedKinvaultPricingSchema.$delete();

  return {
    brandedKinvaultPricingSchema,
  };
};
