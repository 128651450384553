import { AdminBrandedKinvaultDetailsDefaultReferralBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsDefaultReferralRoute =
  "BrandedKinvaultDetailsDefaultReferral";
export const BrandedKinvaultDetailsDefaultReferralURI =
  "/branded-kinvaults/:brandedKinvault/default-referral-codes";
export type BrandedKinvaultDetailsDefaultReferralParams = RouteParamObject<
  typeof BrandedKinvaultDetailsDefaultReferralURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsDefaultReferralRoute,
    path: BrandedKinvaultDetailsDefaultReferralURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsDefaultReferral" */ "./BrandedKinvaultDetails.DefaultReferral.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsDefaultReferralBreadCrumb,
    },
  },
];
