import { KintinField } from "@/config/form.config";
import { CreateKintinAccess } from "@/config/model.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { KintinAccess } from "@kinherit/sdk";

const AccessTypeOptions = {
  owner: "Owner",
  edit: "Edit",
  readonly: "Read Only",
  adviser: "Adviser",
  none: "None",
};

export const UpdateKintinAccessForm = (
  data: KintinAccess = CreateKintinAccess(),
) =>
  defineForm({
    name: "update-kintin-access",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([
          ["kintin", "accessType"],
          ["allowAccess"],
          ["legal", "assets"],
          ["contacts", "admin"],
          ["care", "death"],
          ["misc", "settings"],
        ]),
        components: () => ({
          kintin: [
            KintinField({
              props: {
                label: "Kintin",
                vModel: "kintin",
              },
            }),
          ],
          allowAccess: [
            FormCheckboxField({
              props: {
                label: "Allow Access",
              },
              models: {
                value: "allowAccess",
              },
            }),
          ],
          accessType: [
            FormSelectField({
              reactive: true,
              props: {
                label: "Access Type",
                showClearButton: false,
                options: AccessTypeOptions,
              },
              models: {
                value: "accessType",
              },
            }),
          ],
          legal: [
            FormSelectField({
              props: {
                label: "Legal",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.legal,
              },
              models: {
                value: "legal",
              },
            }),
          ],
          assets: [
            FormSelectField({
              props: {
                label: "Assets",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.assets,
              },
              models: {
                value: "assets",
              },
            }),
          ],
          contacts: [
            FormSelectField({
              props: {
                label: "Contacts",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.contacts,
              },
              models: {
                value: "contacts",
              },
            }),
          ],
          admin: [
            FormSelectField({
              props: {
                label: "Admin",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.admin,
              },
              models: {
                value: "admin",
              },
            }),
          ],
          care: [
            FormSelectField({
              props: {
                label: "Care",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.care,
              },
              models: {
                value: "care",
              },
            }),
          ],
          death: [
            FormSelectField({
              props: {
                label: "Death",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.death,
              },
              models: {
                value: "death",
              },
            }),
          ],
          misc: [
            FormSelectField({
              props: {
                label: "Misc",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.misc,
              },
              models: {
                value: "misc",
              },
            }),
          ],
          settings: [
            FormSelectField({
              props: {
                label: "Settings",
                options: AccessTypeOptions,
                showClearButton: (data) => null !== data.$data.settings,
              },
              models: {
                value: "settings",
              },
            }),
          ],
        }),
      }),
    ],
  });
