import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface UpdateBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

interface UpdateBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

export const UpdateBrandedKinvaultSubscriptionReferralHandler = async (
  message: UpdateBrandedKinvaultSubscriptionReferralMessage,
): Promise<UpdateBrandedKinvaultSubscriptionReferralResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral/{brandedKinvaultSubscriptionReferral}",
    {
      brandedKinvaultSubscriptionReferral:
        message.brandedKinvaultSubscriptionReferral,
    },
  )
    .method("patch")
    .body({
      subscription:
        message.brandedKinvaultSubscriptionReferral.$data.subscription,
      subscriptionIntent:
        message.brandedKinvaultSubscriptionReferral.$data.subscriptionIntent,
      referralCode:
        message.brandedKinvaultSubscriptionReferral.referralCode?.id,
    });
  const result = await request.result();

  const brandedKinvaultSubscriptionReferral =
    BrandedKinvaultSubscriptionReferral.$inflate(
      result.brandedKinvaultSubscriptionReferral,
    ).first();

  if (!brandedKinvaultSubscriptionReferral) {
    throw new Error("Failed to update subscription");
  }

  return {
    brandedKinvaultSubscriptionReferral,
  };
};
