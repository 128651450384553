import { Api } from "@kinherit/sdk/api";

type AcuityReconcileMessage = {
  minDate: string;
  maxDate: string;
};

export const AcuityReconcileAction = async (
  message: AcuityReconcileMessage,
): Promise<void> => {
  await Api.resource("portal", "/v2/portal/appointment/reconcile")
    .method("put")
    .body({
      minDate: message.minDate,
      maxDate: message.maxDate,
    })
    .result();
};
