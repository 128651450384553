import {
  CreatedAtField,
  SharedFilterProps,
  UserField,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadActivityLogForm = (forUser = false) =>
  MasterListFiltersForm({
    name: "read-activity-log-form",
    data: () => ({
      subject: null as null | string,
      action: null as
        | null
        | "INSERT"
        | "SOFT_REMOVE"
        | "RECOVER"
        | "UPDATE"
        | "REMOVE",
      date: null as null | [number, number],
      route: null as string | null,
      origin: null as string | null,
      request: null as string | null,
      objectId: null as string | null,
      user: null as string | null,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      subject: [
        FormTextField({
          props: {
            reference: "subject",
            label: "Subject",
            placeholder: "Any",
          },
          models: {
            value: "subject",
          },
        }),
      ],
      action: [
        FormSelectField({
          props: {
            reference: "action",
            label: "Action",
            placeholder: "Any",
            options: {
              INSERT: "Insert",
              SOFT_REMOVE: "Soft Remove",
              RECOVER: "Recover",
              UPDATE: "Update",
              REMOVE: "Remove",
            },
          },
          models: {
            value: "action",
          },
        }),
      ],
      date: [
        CreatedAtField({
          props: {
            vModel: "date",
          },
          simplifyData: true,
        }),
      ],
      route: [
        FormTextField({
          props: {
            reference: "route",
            label: "Route",
            placeholder: "Any",
          },
          models: {
            value: "route",
          },
        }),
      ],
      user: [
        UserField({
          props: {
            vIf: !forUser,
            vModel: "user",
          },
          simplifyData: true,
          query: {},
        }),
      ],
    }),
    advancedComponents: () => ({
      origin: [
        FormTextField({
          props: {
            reference: "origin",
            label: "Origin",
            placeholder: "Any",
          },
          models: {
            value: "origin",
          },
        }),
      ],
      request: [
        FormTextField({
          props: {
            reference: "request",
            label: "Request",
            placeholder: "Any",
          },
          models: {
            value: "request",
          },
        }),
      ],
      objectId: [
        FormTextField({
          props: {
            reference: "objectId",
            label: "Object ID",
            placeholder: "Any",
          },
          models: {
            value: "objectId",
          },
        }),
      ],
    }),
  });
