import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsClientProgressRoute =
  "IntroducerContactDetailsClientProgress";
export const IntroducerContactDetailsClientProgressURI =
  "/introducer/contact/:introducerContact/client-progress";
export type IntroducerContactDetailsClientProgressParams = RouteParamObject<
  typeof IntroducerContactDetailsClientProgressURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsClientProgressRoute,
    path: IntroducerContactDetailsClientProgressURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsClientProgress" */ "./IntroducerContactDetails.ClientProgress.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "Client progress",
          route: {
            name: IntroducerContactDetailsClientProgressRoute,
            params,
          },
        },
      ],
    },
  },
];
