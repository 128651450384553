import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsNotesBreadCrumb } from "../../../breadcrumbs";

/** Notes */
export const KinvaultKintinDetailsNotesRoute = "KinvaultKintinDetailsNotes";
const KinvaultKintinDetailsNotesURI = "/kinvault/:kintin/notes";
export type KinvaultKintinDetailsNotesParams = RouteParamObject<
  typeof KinvaultKintinDetailsNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsNotesRoute,
    path: KinvaultKintinDetailsNotesURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsNotes" */ "./KintinDetails.Notes.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsNotesBreadCrumb,
    },
  },
];
