import { Api } from "@/service/api.service";
import { EmailTemplate } from "@kinherit/sdk";

export interface CreateEmailTemplateMessage {
  emailTemplate: EmailTemplate;
}

interface CreateEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export const CreateEmailTemplateHandler = async (
  message: CreateEmailTemplateMessage,
): Promise<CreateEmailTemplateResponse> => {
  const response = await Api.resource("portal", "/v2/portal/email-template")
    .method("post")
    .body({
      name: message.emailTemplate.$data.name,
      subject: message.emailTemplate.$data.subject,
      body: message.emailTemplate.$data.body,
      allowAttachments: message.emailTemplate.$data.allowAttachments || false,
      strictAttachments: message.emailTemplate.$data.strictAttachments || false,
      notes: message.emailTemplate.$data.notes,
      requiredAttachments: message.emailTemplate.$data.requiredAttachments,
      type: message.emailTemplate.$data.type,
      includeTo: message.emailTemplate.$data.includeTo,
      preloadedFiles: message.emailTemplate.$data.preloadedFiles,
    })

    .result();

  message.emailTemplate.$delete();

  return {
    emailTemplates: EmailTemplate.$inflate(
      response.emailTemplate,
      undefined,
      response.$rootIds,
    ),
  };
};
