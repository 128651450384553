import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsContractsRoute =
  "IntroducerCompanyDetailsContracts";
export const IntroducerCompanyDetailsContractsURI =
  "/introducer/company/:introducerCompany/contracts";
export type IntroducerCompanyDetailsContractsParams = RouteParamObject<
  typeof IntroducerCompanyDetailsContractsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsContractsRoute,
    path: IntroducerCompanyDetailsContractsURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsContracts" */ "./IntroducerCompanyDetails.Contracts.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Contracts",
          route: {
            name: IntroducerCompanyDetailsContractsRoute,
            params,
          },
        },
      ],
    },
  },
];
