import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsBreadCrumb } from "../../../breadcrumbs";
import { Routes as ClientSummaryRoutes } from "./client-summary";
import { Routes as FamilyGuardiansRoutes } from "./family-guardians";
import { Routes as FollowUpRoutes } from "./follow-up";
import { Routes as IntroRoutes } from "./intro";
import { Routes as KinvaultRoutes } from "./kinvault";
import { Routes as LPAOnlyRoutes } from "./lpa-only";
import { Routes as PropertyAssetsRoutes } from "./property-assets";
import { Routes as WhoDoesWhatRoutes } from "./who-does-what";
import { Routes as WhoGetsWhatRoutes } from "./who-gets-what";
import {
  KintinDetailsCallScriptWillReviewRoute,
  Routes as WillReviewRoutes,
} from "./will-review";

export const KintinDetailsCallScriptRoute = "KinvaultKintinDetailsCallScript";
export const KintinDetailsCallScriptURI = "/kinvault/:kintin/call-script";
export type KintinDetailsCallScriptParams = RouteParamObject<
  typeof KintinDetailsCallScriptURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptRoute,
    path: KintinDetailsCallScriptURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScript" */ "./KinvaultKintinDetails.CallScript.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptSidebar" */ "./KinvaultKintinDetails.CallScript.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsBreadCrumb(params),
        {
          text: "Call Script",
          route: { name: KintinDetailsCallScriptRoute, params },
        },
      ],
    },
    redirect: { name: KintinDetailsCallScriptWillReviewRoute },
    children: [
      ...WillReviewRoutes,
      ...IntroRoutes,
      ...FamilyGuardiansRoutes,
      ...PropertyAssetsRoutes,
      ...WhoGetsWhatRoutes,
      ...WhoDoesWhatRoutes,
      ...ClientSummaryRoutes,
      ...FollowUpRoutes,
      ...KinvaultRoutes,
      ...LPAOnlyRoutes,
    ],
  },
];
