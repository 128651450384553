<template>
  <span v-if="data">
    <strong> Gifts of Property </strong>
    <ol type="i">
      <li v-for="gift in data.propertyGifts" :key="gift.id">
        I GIVE free of inheritance tax all my share and interest not previously
        disposed of by this Will in
        <span v-html="helpers.var(helpers.formatAddress(gift.address))" />
        <span v-if="gift.asPrimaryResidence">
          or any other such property I may own as my principal residence
        </span>
        (the 'Property') at the time of my death to
        <span v-html="helpers.var(helpers.formatRecipient(gift))" />
        absolutely
        <span v-html="helpers.var(helpers.formatPredeceased(gift))" />
        <span v-if="gift.onSecondDeath">
          only if my
          <span
            v-if="data.person"
            v-html="
              helpers.var(
                helpers.formatRelationship(data.person, gift.forPerson),
              )
            "
          />
          has predeceased me
        </span>
        <br /><br />
        I DECLARE that all sums secured on the Property whether by way of
        mortgage or otherwise (save as discharged by any policy of life
        assurance given as security for this purpose) and all interest due at my
        death shall be paid and discharged out of my Estate as defined below in
        exoneration of the Property
        <br /><br />
        I DECLARE that if at the date of my death my share and interest in the
        Property shall be subject to a binding but uncompleted contract for sale
        this gift shall take effect as a gift of my share of the net proceeds of
        sale of the Property
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
