<template>
  <p>
    <strong>Shares to Beneficiaries</strong><br />
    I would wish my Trustees to utilise funds within the trust as follows:
  </p>
  <ol type="i">
    <li
      v-for="beneficiary in firstOrSecondLineBeneficiaries"
      :key="beneficiary.id"
    >
      <span
        v-html="
          helpers.calculateBeneficiaryShare(
            firstOrSecondLineBeneficiaries,
            beneficiary,
            false,
          )
        "
      />

      <span v-html="helpers.var(helpers.formatBeneficiary(beneficiary))" />
      <span
        v-html="helpers.var(helpers.beneficiaryTheirConcern(beneficiary))"
      />.
    </li>
  </ol>
  <p>
    Should any of the above shares fail in their entirety whilst assets remain
    in the trust, I would like the share that has failed to be divided between
    the shares that have not failed in proportion to that respective share.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    /*
    If there are both first and secondline beneficiaries, then this should only output second line beneficiaries.
    Otherwise should output first line beneficiaries.
    */
    firstOrSecondLineBeneficiaries() {
      if (!this.data) {
        return [];
      }

      return this.data.beneficiariesFirstLine.length > 0 &&
        this.data.beneficiariesSecondLine.length > 0
        ? this.data.beneficiariesSecondLine
        : this.data.beneficiariesFirstLine;
    },
  },
});
</script>
