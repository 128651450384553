<template>
  <div class="branded-kinvault-details-summary">
    <div class="columns">
      <div class="column">
        <DataListWidget title="Settings" :details="kinvaultSettings" />
      </div>
      <div class="column">
        <DataListWidget title="Features" :details="kinvaultFeatures" />
      </div>
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsSummaryWrapper",
  "route": "BrandedKinvaultDetailsSummary",
  "selector": ".branded-kinvault-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { BrandedKinvaultDetailsSummaryRoute } from ".";
import { BrandedKinvaultDetailsMixin } from "../../../mixin/branded-kinvault-details.mixin";

export default defineComponent({
  name: BrandedKinvaultDetailsSummaryRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: {
    DataListWidget,
  },
  computed: {
    kinvaultSettings(): Array<DataListWidgetDetail> {
      if (!this.brandedKinvault) {
        return [];
      }

      const xeroContact = this.brandedKinvault.xeroContact;

      return [
        {
          label: "Primary URL",
          value: {
            text: this.brandedKinvault.frontendUrl,
            href: "https://" + this.brandedKinvault.frontendUrl,
          },
        },
        {
          label: "Kinvault Type",
          value: this.brandedKinvault.type?.text,
        },
        {
          label: "Kinvault Pool Type",
          value: this.brandedKinvault.clientPoolType?.text,
        },
        {
          label: "Site Mode",
          value: this.brandedKinvault.siteMode?.text,
        },
        {
          label: "Xero Billing Contact ID",
          value: xeroContact
            ? `${xeroContact.contactName} (${xeroContact.xeroContactId})`
            : "None",
        },
        {
          label: "Notes",
          value: {
            html: this.brandedKinvault.notes ?? "None",
          },
        },
      ];
    },
    kinvaultFeatures(): Array<DataListWidgetDetail> {
      if (!this.brandedKinvault) {
        return [];
      }

      return [
        {
          label: "Allow Default Asset Register",
          value: this.brandedKinvault.allowDefaultAssetAccess ? "Yes" : "No",
        },
        {
          label: "Use Setup Wizard",
          value: this.brandedKinvault.useSetupWizard ? "Yes" : "No",
        },
        {
          label: "Use Branded Kinvault Invitations",
          value: this.brandedKinvault.useBrandedKinvaultInvitations
            ? "Yes"
            : "No",
        },
        {
          label: "Allow Public Registration",
          value: this.brandedKinvault.allowPublicRegistration ? "Yes" : "No",
        },
        {
          label: "Use User Invitations",
          value: this.brandedKinvault.useUserInvitations ? "Yes" : "No",
        },
        {
          label: "Restrict User Email Domain",
          value: this.brandedKinvault.restrictUserEmailDomain ? "Yes" : "No",
        },
        {
          label: "Adviser Email Domain Restrictions",
          value: {
            html: this.brandedKinvault.allowedEmailDomains
              ? this.brandedKinvault.allowedEmailDomains
                  ?.split(",")
                  ?.join("<br />")
              : "None",
          },
        },
      ];
    },
  },
});
</script>
