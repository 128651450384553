import { OptionsSelectField } from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerCpd } from "@kinherit/sdk";

export const UpdateCpdSessionIntroducersForm = (
  introducerCpd: IntroducerCpd,
) => {
  return defineForm({
    name: "update-cpd-session-introducers-form",
    data: () => introducerCpd,
    formAreas: (data) => [
      defineFormArea({
        name: "update-cpd-session-introducers-form-area",
        data,
        template: GridLayout(["status"]),
        components: () => ({
          status: [
            OptionsSelectField({
              group: "introducerCpdStatus",
              vModel: "status",
            }),
          ],
        }),
      }),
    ],
  });
};
