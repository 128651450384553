<template>
  <Card v-if="$data.form" class="tag-update">
    <FormControls
      :form="$data.form"
      :mode="tag ? 'update' : 'create'"
      :title="tag ? `Edit Tag` : `Create Tag`"
      :delete-button="false"
      @cancel="$router.back"
      @save="save"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
  <PageNotFound v-else-if="!tag" />
</template>

<cypress-wrapper lang="json">
{
  "name": "TagUpdateWrapper",
  "route": "TagUpdate",
  "selector": ".tag-update",
  "imports": {
    "UpdateTagFormWrapper": "@/module/admin.tag/form/update-tag.form.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateTagFormWrapper"
    },
    "save": {
      "type": "click",
      "selector": ".save-button"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateTag } from "@/config/model.config";
import { UpdateTagForm } from "@/module/admin.tag/form/update-tag.form";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import Card from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { Tag } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { TagUpdateRoute } from ".";
import { TagDetailsMixin } from "../mixin/tag.mixin";

export default defineComponent({
  name: TagUpdateRoute,
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
      permissions: ["tag:write"],
    }),
    TagDetailsMixin,
  ],
  components: {
    PageNotFound,
    FormControls,
    Card,
  },
  data: () => ({
    form: null as null | FormBuilder<() => Tag, string>,
  }),
  async mounted(): Promise<void> {
    this.form = UpdateTagForm(this.tag ?? CreateTag());
  },
  methods: {
    async save(data: Tag) {
      if (this.tag) {
        // await window.Kernel.ActionBus.execute("admin/tag/update", data);
        await window.Kernel.ActionBus.adminTag.update(data);
      } else {
        // await window.Kernel.ActionBus.execute("admin/tag/create", data);
        await window.Kernel.ActionBus.adminTag.create(data);
      }

      this.$router.back();
    },
    reset(): void {
      if (!this.form) {
        return;
      }

      if (this.tag) {
        this.form.localData.$restore?.();
      } else {
        this.form.resetData();
      }
    },
  },
});
</script>
