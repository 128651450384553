import { Api } from "@/service/api.service";
import { Equal } from "@kinherit/orm";
import { CpdSession, IntroducerCpd } from "@kinherit/sdk";

interface DownloadIntroducerCpdCsvMessage {
  session: CpdSession;
}

export const DownloadIntroducerCpdCsvHandler = async (
  message: DownloadIntroducerCpdCsvMessage,
): Promise<void> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/cpd-session/introducer-cpd/csv",
  )
    .method("get")

    .paginate({
      currentPage: 1,
      perPage: 1000,
    });

  request.buildQuery(IntroducerCpd).where({
    session: {
      id: Equal(message.session.id),
    },
  });

  await request.download({
    fileName: "introducer-cpd.csv",
    encoding: "text/csv",
  });
};
