import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface DownloadKintinSignDocDisclaimerMessage {
  signDoc: SignDoc;
  kintin: Kintin;
}

export interface DownloadKintinSignDocDisclaimerResponse {
  [key: string]: never;
}

export const DownloadKintinSignDocDisclaimerHandler = async (
  message: DownloadKintinSignDocDisclaimerMessage,
): Promise<DownloadKintinSignDocDisclaimerResponse> => {
  const { signDoc, kintin } = message;

  const primaryPerson = kintin.primaryPerson;
  const secondaryPerson = kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}/download",
    {
      kintin: kintin.id,
      signDoc: signDoc.id,
    },
  )
    .method("get")
    .download({
      encoding: "application/pdf",
      fileName: `${kintin.ref} Disclaimer - ${surname}`,
    });

  return {};
};
