import { Api, Property } from "@kinherit/sdk";

interface DeletePropertyMessage {
  kintin: string;
  property: Property;
}

interface DeletePropertyResponse {
  [key: string]: never;
}

export const DeletePropertyHandler = async (
  message: DeletePropertyMessage,
): Promise<DeletePropertyResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-property/{kintin}/properties/{property}",
    {
      kintin: message.kintin,
      property: message.property.$data.id,
    },
  )
    .method("delete")
    .result();

  message.property.$delete();

  return {};
};
