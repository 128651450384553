import { Api } from "@/service/api.service";
import { Model } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Person,
} from "@kinherit/sdk";

export const CreateEmailNamedAttachmentHandler = async (message: {
  owner: Model<{ id: string }>;
  emailLog: EmailLog;
  files: Record<string, File>;
}): Promise<{}> => {
  const routes = {
    [IntroducerCompany.$name]:
      "/v2/portal/introducer-company-email-log/{owner}/email-log/{emailLog}/named-attachment",
    [IntroducerContact.$name]:
      "/v2/portal/introducer-contact-email-log/{owner}/email-log/{emailLog}/named-attachment",
    [Kintin.$name]:
      "/v2/portal/kintin-email-log/{owner}/email-log/{emailLog}/named-attachment",
    [Lead.$name]:
      "/v2/portal/lead-email-log/{owner}/email-log/{emailLog}/named-attachment",
    [Person.$name]:
      "/v2/portal/officer-email-log/{owner}/email-log/{emailLog}/named-attachment",
    [BrandedKinvault.$name]:
      "/v2/portal/branded-kinvault-email-log/{owner}/email-log/{emailLog}/named-attachment",
  } as const;

  const route = routes[message.owner.$name];

  for (const name in message.files) {
    const file = message.files[name];

    await Api.resource("portal", route, {
      owner: message.owner,
      emailLog: message.emailLog,
    })
      .method("put")

      .files({
        uploadedFile: file,
        attachmentName: name,
      })
      .result();
  }

  return {};
};
