import { IntroducerFeePaymentRun } from "@kinherit/sdk/index";

export const IntroducerFeePaymentRunMasterListBreadCrumb = {
  text: "Introducer Fee Payment Run",
  route: { name: "IntroducerFeePaymentRunMasterList" },
};

export const IntroducerFeePaymentRunDetailsBreadCrumb = (
  params: Record<string, string>,
) => [
  IntroducerFeePaymentRunMasterListBreadCrumb,
  {
    text:
      IntroducerFeePaymentRun.$findOneBy({
        id: params.introducerFeePaymentRun,
      })?.name ?? "Unknown",
    route: { name: "IntroducerFeePaymentRunDetails", params },
  },
];

export const IntroducerFeePaymentRunDetailsItemsBreadCrumb = (
  params: Record<string, string>,
) => [
  ...IntroducerFeePaymentRunDetailsBreadCrumb(params),
  {
    text: "Items",
    route: { name: "IntroducerFeePaymentRunDetailsItems", params },
  },
];

export const IntroducerFeeMasterListBreadCrumb = {
  text: "Introducer Fee",
  route: { name: "IntroducerFeeMasterList" },
};
