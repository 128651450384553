<template>
  <div v-if="!loading" class="introducer-company-details-referrals">
    <Card title="Referral Codes" class="mt-4">
      <Table
        class="referral-codes-table"
        is-scrollable
        enable-per-page
        v-model:page="pagination.currentPage"
        @update:page="refresh"
        v-model:perPage="pagination.perPage"
        @update:perPage="refresh"
        :total-pages="pagination.lastPage"
        :total-records="pagination.count"
        :columns="computedReferralColumns"
        :rows="referralCodes"
        is-fullwidth
        is-narrow
        is-hoverable
        v-model:sortBy="sort.by"
        @update:sortBy="refresh"
        v-model:sortDirection="sort.direction"
        @update:sortDirection="refresh"
        :sort-by-options="{
          createdAt: 'Created',
        }"
      >
        <template #action="{ row }">
          <Button
            @click="() => deleteReferralCode(row)"
            :icon-left="StyleService.icon.delete.icon"
            size="is-small"
            color="is-plain"
            aria-label="Delete referral code"
            class="delete-referral"
            tooltip="Delete referral code"
          />
        </template>
      </Table>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsReferralCodesWrapper",
  "route": "IntroducerCompanyDetailsReferralCodes",
  "selector": ".introducer-company-details-referrals",
  "imports": {
    "TableWrapper": "@kinherit/framework/component.display/table/table.test"
  },
  "methods": {
    "referralCodesTable": {
      "type": "to-one",
      "selector": ".referral-codes-table",
      "wrapper": "TableWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Table } from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { AccountReferralCode, IAccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsReferralCodesParams,
  IntroducerCompanyDetailsReferralCodesRoute,
} from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsReferralCodesRoute,
  mixins: [IntroducerCompanyDetailsMixin, AuthService.mixin()],
  components: {
    Card,
    Table,
    Button,
  },
  data: () => ({
    StyleService,
    loading: false,
    referralColumns: [
      {
        title: "Code",
        sort: true,
        map: (referral: AccountReferralCode) => referral.code,
      },
      {
        title: "Category",
        sort: true,
        map: (v: AccountReferralCode) => v.category?.text ?? "No Category",
      },
      {
        title: "Created",
        sort: true,
        map: (referral: AccountReferralCode) => referral.createdAt.formatDate,
      },
      {
        title: "Notes",
        sort: true,
        map: (referral: AccountReferralCode) => referral.notes,
      },
      {
        title: "",
        slot: "action",
        permission: "introducer-company:write",
        blockClicks: true,
        style: {
          textAlign: "right",
        },
      },
    ] as Array<TableColumn>,
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferral,
      direction: "desc" as "desc" | "asc",
    },
    referralCodes: Array<AccountReferralCode>(),
  }),
  computed: {
    computedReferralColumns() {
      return this.referralColumns.filter(
        this.$auth.filterAllPermissions("permission"),
      );
    },
  },
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      this.loading = true;
      // const { referralCodes, pagination } =
      //   await window.Kernel.ActionBus.execute(
      //     "introducer/company/referral-codes/read",
      //     {
      //       introducerCompany: (
      //         this.$params as IntroducerCompanyDetailsReferralCodesParams
      //       ).introducerCompany,
      //       sort: this.sort,
      //       pagination: this.pagination,
      //     },
      //   );
      const { referralCodes, pagination } =
        await window.Kernel.ActionBus.introducerCompany.referralCodes.read({
          introducerCompany: (
            this.$params as IntroducerCompanyDetailsReferralCodesParams
          ).introducerCompany,
          sort: this.sort,
          pagination: this.pagination,
        });

      this.referralCodes = referralCodes;
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.lastPage = pagination.lastPage;
      this.pagination.count = pagination.count;
      this.loading = false;
    },
    async deleteReferralCode(referralCode: AccountReferralCode): Promise<void> {
      await DangerDialog({
        dialog: {
          title: `Delete "${referralCode.code}"`,
          message: `Are you sure you want to delete this referral code from ${this.introducerCompany?.profile.organisationName}?`,
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/account-referral-code/delete",
      //   {
      //     accountReferralCode: referralCode,
      //   },
      // );
      await window.Kernel.ActionBus.adminAccountReferralCode.delete({
        accountReferralCode: referralCode,
      });

      await this.refresh();
    },
  },
});
</script>
