import { Api } from "@/service/api.service";
import { WillRevision } from "@kinherit/sdk";

export interface RecordWillRevisionMessage {
  willRevision: string | WillRevision;
}

interface RecordWillRevisionResponse {
  willRevisions: Array<WillRevision>;
}

export const RecordWillRevisionHandler = async (
  message: RecordWillRevisionMessage,
): Promise<RecordWillRevisionResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/will-revision/{willRevision}",
    {
      willRevision: message.willRevision,
    },
  ).method("get");
  const response = await request.result();

  return {
    willRevisions: WillRevision.$inflate(response.willRevision),
  };
};
