import { AdminBrandedKinvaultDetailsUpdateProfileBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsUpdateProfileRoute =
  "BrandedKinvaultDetailsUpdateProfile";
export const BrandedKinvaultDetailsUpdateProfileURI =
  "/admin/branded-kinvault/:brandedKinvault/profile";
export type BrandedKinvaultDetailsUpdateProfileParams = RouteParamObject<
  typeof BrandedKinvaultDetailsUpdateProfileURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsUpdateProfileRoute,
    path: BrandedKinvaultDetailsUpdateProfileURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsUpdateProfile" */ "./BrandedKinvaultDetails.UpdateProfile.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsUpdateProfileBreadCrumb,
    },
  },
];
