import { IntroducerFeePaymentRunDetailsBreadCrumb } from "@/module/introducer.fees/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const IntroducerFeePaymentRunDetailsSummaryRoute =
  "IntroducerFeePaymentRunDetailsSummary";
const IntroducerFeePaymentRunDetailsSummaryUri =
  "/introducer/fees/payment-run/:introducerFeePaymentRun";
export type IntroducerFeePaymentRunDetailsSummaryParams = RouteParamObject<
  typeof IntroducerFeePaymentRunDetailsSummaryUri
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerFeePaymentRunDetailsSummaryRoute,
    path: IntroducerFeePaymentRunDetailsSummaryUri,
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeePaymentRunDetailsSummary" */ "./IntroducerFeePaymentRunDetails.Summary.vue"
      ),
    meta: {
      breadcrumbs: IntroducerFeePaymentRunDetailsBreadCrumb,
    },
  },
];
