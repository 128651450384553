import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  Api,
  IKnowledgeBaseDocument,
  KnowledgeBaseCategory,
  KnowledgeBaseDocument,
} from "@kinherit/sdk";

export interface ReadKnowledgeBaseDocumentMessage {
  name?: string;
  created?: null | [number, number];
  category?: KnowledgeBaseCategory | string;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKnowledgeBaseDocument;
    direction: "asc" | "desc";
  };
}

interface ReadKnowledgeBaseDocumentResponse {
  knowledgeBaseDocuments: Array<KnowledgeBaseDocument>;
  knowledgeBaseCategories: Array<KnowledgeBaseCategory>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKnowledgeBaseDocumentHandler = async (
  message: ReadKnowledgeBaseDocumentMessage,
): Promise<ReadKnowledgeBaseDocumentResponse> => {
  const request = Api.resource("portal", "/v2/portal/knowledge-base-document")
    .method("get")

    .sort({
      by: message.sort.by,
      direction: message.sort.direction,
    })
    .paginate({
      currentPage: message.pagination?.currentPage,
      perPage: message.pagination?.perPage,
    });

  request
    .buildQuery(KnowledgeBaseDocument)
    .where({
      title: Like(message.name),
      createdAt: Between(message.created),
      categories: {
        id: Equal(
          "string" === typeof message.category
            ? message.category
            : message.category?.id,
        ),
      },
    })
    .orWhere({
      content: Like(message.name),
      createdAt: Between(message.created),
      categories: {
        id: Equal(
          "string" === typeof message.category
            ? message.category
            : message.category?.id,
        ),
      },
    });

  const response = await request.result();

  return {
    knowledgeBaseDocuments: KnowledgeBaseDocument.$inflate(
      response.knowledgeBaseDocument,
      message.sort,
      response.$rootIds,
    ),
    knowledgeBaseCategories: KnowledgeBaseCategory.$inflate(
      response.knowledgeBaseCategory,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
