<template>
  <div v-if="data">
    <p v-for="gift in data.propertyGiftsMOW" :key="gift.id">
      <strong>
        Directions regarding
        <span v-html="helpers.var(helpers.formatAddress(gift.address))" />
      </strong>
      <br />

      Subject to the above
      <span v-if="gift.onSecondDeath">
        and only after the death of
        <span
          v-html="helpers.var(helpers.formatOfficer(data.partner, false))"
        />
      </span>
      I would ask my Trustees to consider utilising
      <span v-html="helpers.var(helpers.formatAddress(gift.address))" />
      <span v-if="gift.asPrimaryResidence">
        (or in the event of this property not being my main residence at the
        date of my death, the property which substituted it)
      </span>
      for the benefit of
      <span v-html="helpers.var(helpers.formatRecipient(gift))" />
      <span v-if="gift.ifPredeceased?.value === 'theirconcern'">
        but if <span v-html="helpers.var(helpers.heSheThey(gift.forPerson))" />
        shall die when assets remain in the trust leaving issue of
        <span v-html="helpers.var(helpers.hisHersTheir(gift.forPerson))" /> own
        then I would prefer that
        <span v-html="helpers.var(helpers.hisHersTheir(gift.forPerson))" />
        share be shared equally between their issue and thereafter similarly
        remoter issue in that family.
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
