import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { EmailCampaignDetailsParams } from "..";
import { EmailCampaignDetailsLogsBreadCrumb } from "../../../breadcrumbs";

/** Logs */
export const EmailCampaignDetailsLogsRoute = "EmailCampaignDetailsLogs";
const EmailCampaignDetailsLogsURI =
  "/admin.email-campaign/:emailCampaign/emails";
export type EmailCampaignDetailsLogsParams = RouteParamObject<
  typeof EmailCampaignDetailsLogsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: EmailCampaignDetailsLogsRoute,
    path: EmailCampaignDetailsLogsURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsLogs" */ "./EmailCampaignDetails.Logs.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        EmailCampaignDetailsLogsBreadCrumb(
          params as EmailCampaignDetailsParams,
        ),
    },
  },
];
