<template>
  <div class="introducer-company-master-list">
    <PageHeader htag="h1" :text="title">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="handleCreate"
        >
          Add Company
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :row-classes="rowClasses"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.organisationName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyMasterListWrapper",
  "route": "IntroducerCompanyMasterList",
  "selector": ".introducer-company-master-list",
  "imports": {
    "ReadIntroducerCompanyFormWrapper": "@/module/introducer.company/form/read-introducer-company.form.test",
    "IntroducerCompanyDetailsWrapper": "@/module/introducer.company/page/details/IntroducerCompanyDetails.test",
    "CreateIntroducerCompanyFormWrapper": "@/module/introducer.company/form/create-introducer-company.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerCompanyFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsWrapper"
    },
    "createIntroducerCompany": {
      "type": "click",
      "selector": ".create"
    },
    "createIntroducerCompanyForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateIntroducerCompanyFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { IntroducerCompanyTableColumns } from "@/config/table-columns.config";
import { ReadIntroducerCompanyForm } from "@/module/introducer.company/form/read-introducer-company.form";
import { AuthService } from "@/service/auth.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IIntroducerCompany, IntroducerCompany, Tag } from "@kinherit/sdk";
import { defineComponent, type PropType } from "vue";
import { IntroducerCompanyMasterListRoute } from ".";
import { CreateIntroducerCompanyForm } from "../form/create-introducer-company.form";
import {
  IntroducerCompanyDetailsParams,
  IntroducerCompanyDetailsRoute,
} from "./details";

export default defineComponent({
  name: IntroducerCompanyMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  props: {
    tag: {
      type: Object as PropType<Tag>,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filters: ReadIntroducerCompanyForm(),
    columns: IntroducerCompanyTableColumns,
    rows: Array<IntroducerCompany>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerCompany,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    title(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "Introducer Companies";
    },
    hasWritePermission(): boolean {
      return AuthService.hasPermission("introducer-company:write");
    },
  },
  async mounted(): Promise<void> {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
  },
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light": scope.row.status?.value === "dead",
        "has-background-warning-light": scope.row.status?.value === "dormant",
      };
    },
    handleClick(introducerCompany: IntroducerCompany, event: MouseEvent): void {
      const params: IntroducerCompanyDetailsParams = {
        introducerCompany: introducerCompany.id,
      };

      window.Kernel.visitRoute(
        {
          name: IntroducerCompanyDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadIntroducerCompanyForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "introducer/company/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );
      const data = await window.Kernel.ActionBus.introducerCompany.company.read(
        {
          ...formData,
          sort: this.sort,
          pagination: this.pagination,
        },
      );

      this.rows = data.introducerCompanies;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async handleCreate(): Promise<void> {
      const form = CreateIntroducerCompanyForm();

      try {
        const { company, contact } = await form.dialog({
          dialog: {
            title: "Add Introducer Company",
          },
          button: {
            ok: {
              text: "Add",
            },
          },
        });

        // const { introducerCompany } = await window.Kernel.ActionBus.execute(
        //   "introducer/company/create",
        //   {
        //     introducerCompany: company,
        //   },
        // );
        const { introducerCompany } =
          await window.Kernel.ActionBus.introducerCompany.company.create({
            introducerCompany: company,
          });

        contact.companies = [introducerCompany];

        // await window.Kernel.ActionBus.execute("introducer/contact/create", {
        //   introducerContact: contact,
        // });
        await window.Kernel.ActionBus.introducerContact.contact.create({
          introducerContact: contact,
        });

        this.refresh(this.filters.localData);
      } catch {
        form.localData.company.$delete({
          profile: {
            addresses: true,
            emails: true,
            phoneNumbers: true,
            websites: true,
          },
        });
        form.localData.contact.$delete({
          profile: {
            addresses: true,
            emails: true,
            phoneNumbers: true,
            websites: true,
          },
        });
        return;
      }
    },
  },
});
</script>
