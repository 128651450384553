<template>
  <div v-if="data">
    <span>
      <strong>Gift of Money to Charity</strong>
      <p>
        <span v-html="helpers.var(sectionPosition)" />.1 In this clause
        <span v-html="helpers.var(sectionPosition)" />
      </p>
      <ol type="a">
        <li>
          the following terms have the meanings given in Schedule 1A to the
          Inheritance Tax Act 1984 (<strong>Schedule 1A</strong>):
          <ol type="i">
            <li><strong>baseline amount</strong></li>
            <li><strong>estate</strong> and</li>
            <li><strong>general component</strong> and</li>
            <li><strong>settled property component</strong> and</li>
            <li><strong>survivorship component</strong></li>
          </ol>
        </li>
        <li>
          <strong>reserved benefit assets</strong> means all the assets that are
          treated as part of my estate under section 102(3) of the Finance Act
          1986;
        </li>
        <li>
          <strong>Specified Baseline Amount</strong> means the baseline amount
          for the general component and the survivorship component and the
          settled property component of my estate and reserved benefit assets if
          these were treated as a single component under paragraph 7(3) of
          Schedule 1A; and
        </li>
        <li>
          <strong>Charitable Amount</strong> means a sum of money equal to 10%
          of the Specified Baseline Amount
        </li>
      </ol>
      <p><span v-html="helpers.var(sectionPosition)" />.2</p>
      <span v-if="data.charityGifts.length === 1">
        <p>
          I give the Charitable Amount to
          <span
            v-html="
              helpers.var(helpers.formatRecipient(data.charityGifts[0], false))
            "
          />
        </p>
      </span>
      <span v-else>
        <p>I give the Charitable Amount to the following in equal shares:</p>
        <ol type="i">
          <li v-for="gift in data.charityGifts" :key="gift.id">
            <span v-html="helpers.var(helpers.formatRecipient(gift))" />
          </li>
        </ol>
      </span>
      <br /><br />
      <p>
        <span v-html="helpers.var(sectionPosition)" />.3 If at my death the
        charity named in clause <span v-html="helpers.var(sectionPosition)" />.2
        ("the Original Charity") no longer exists or is being wound up, my
        trustees must: If no part of the Specified Baseline Amount qualifies for
        the lower rate inheritance tax, the gift in this clause does not take
        effect if this is because no part of the Specified Baseline Amount is
        chargeable to inheritance tax at a rate other than nil per cent
      </p>
      <ol type="a">
        <li>
          If the Original Charity has merged, or is about to merge, with another
          charity ("the Recipient Charity") and the merger, when completed, is
          registered in the official register of mergers maintained by the
          Charity Commission, pay the Charitable Amount as being payable to the
          Original Charity to the Recipient Charity;
        </li>
        <li>
          If clause <span v-html="helpers.var(sectionPosition)" />.3(a) does not
          apply, pay the amount specified in clause
          <span v-html="helpers.var(sectionPosition)" />.1 as being payable to
          the Original Charity to another charity that has objects similar to
          the Original Charity.
        </li>
      </ol>
      <p>
        If no part of the Specified Baseline Amount qualifies for the lower rate
        inheritance tax, the gift in this clause does not take effect if this is
        because no part of the Specified Baseline Amount is chargeable to
        inheritance tax at a rate other than nil per cent
      </p>
    </span>
  </div>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    sectionPosition(): string {
      return this.helpers
        .getSectionPosition(WillBuilderSections.CharityGifts)
        .toString();
    },
  },
});
</script>
