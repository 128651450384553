<template>
  <CallScriptSection title="Property" v-if="kintin">
    <b>
      So, let’s have a look at your property and assets situation then, which is
      what we call your estate.
      <br /><br />
      Shall we start with property?
      <br /><br />
      You said you live at:
    </b>
    <ul>
      <li
        v-for="address in primaryPerson.profile.addresses.filter(
          (a) => a.primaryResidential === true,
        )"
        :key="address.id"
      >
        {{ address.summary }}
      </li>
    </ul>
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.0a"
      :kintin="kintin"
      is-reversed
      :on="{
        yes: {
          action: [
            'Specialist to sense check total estate value vs owned property value',
          ],
        },
        no: {
          action: ['Consider trust if elderly single in final home'],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.0b"
      :kintin="kintin"
      v-if="isJoint"
      is-reversed
      :yes-label="'Yes/Unsure'"
      :on="{
        yes: {
          ask: ['System will advise changing ownership to 50:50.'],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.0c"
      :kintin="kintin"
      is-reversed
      :on="{
        yes: {
          ask: [
            'System will prompt for us to check Inheritance Tax and Right to Reside',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.0d"
      :kintin="kintin"
      is-reversed
      :on="{
        yes: {
          action: ['Take details in note'],
        },
      }"
    />
    <br />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.0e"
      :kintin="kintin"
      is-reversed
      :on="{
        yes: {
          ask: [
            'It is typically people in their ~final~ home (ie over 60), with children, and single (ie unable to get TIC protection) who this could be most relevant for.',
          ],
        },
      }"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.2a"
      :kintin="kintin"
      is-reversed
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.2b"
      v-if="checkIsYes('3.0.2a')"
      :kintin="kintin"
      is-reversed
      :on="{
        yes: {
          say: [
            'You may want to just check what happens to property in those countries.',
          ],
        },
      }"
    />
    <br />
    <b v-if="checkIsYes('3.0.2a')">
      So thinking about your properties, have you an idea of what they're worth
      and what mortgages are against them?
    </b>
    <b v-if="checkIsNo('3.0.2a')">
      So thinking about your main residence, have you an idea of its value and
      any mortgage?
    </b>
    <br />
    <em>
      [For each property, take note of value + mortgage so you can check land
      registry and fill in asset register after call]
    </em>
    <CurrencyField
      label="Property"
      message="Approximate NET total value for planning purposes"
      v-model:value="values.advPropsValue"
    />
    <Button text="Save" @click="saveHandler" color="is-positive" class="mb-4" />
    <PropertiesContainer class="mt-4" :kintin="kintin" />
    <p>
      <b>
        So looking at your property value, does that sound about right? Or is
        there anything else?
      </b>
    </p>
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.1"
      :kintin="kintin"
    />
    <ConfirmCheck
      @update="updateCheckValue"
      :kintin-checks="kintinChecks"
      :checks="checks"
      step="3.0.2"
      :kintin="kintin"
    />
  </CallScriptSection>
</template>

<script lang="ts">
import PropertiesContainer from "@/module/kinvault.kintin/component/address-book/PropertiesContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { CurrencyField } from "@kinherit/framework/component.input/currency-field";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

import { Button } from "@kinherit/framework/component.input/button";
export default defineComponent({
  name: "PropertyTab",
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    CallScriptSection,
    ConfirmCheck,
    CurrencyField,
    PropertiesContainer,
    Button,
  },
  emits: ["save"],
  data: () => ({
    values: {
      advPropsValue: null as null | Currency,
    },
  }),
  computed: {},
  mounted(): void {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }

    this.values.advPropsValue = this.kintin.callScript?.advPropsValue ?? null;
  },
  methods: {
    async saveHandler() {
      if (!this.kintin) {
        return;
      }
      this.kintin.callScript.advPropsValue = this.values.advPropsValue;
      this.$emit("save");
    },
  },
});
</script>
