import {
  AccountReferralCodeField,
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  TagsField,
  UserField,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { IsNotNull } from "@kinherit/orm/index";

const dateRanges = [
  {
    label: "Today -> End of Week",
    value: "today_end-of-week",
  },
  {
    label: "Today -> Tomorrow",
    value: "today_tomorrow",
  },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Tomorrow",
    value: "tomorrow",
  },
  {
    label: "This Week",
    value: "this-week",
  },
  {
    label: "Next Week",
    value: "next-week",
  },
  {
    label: "Last Week",
    value: "last-week",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const ReadAppointmentForm = () =>
  MasterListFiltersForm({
    name: "filter-appointment-form",
    data: () => ({
      search: "",
      specialist: Array<string>(),
      dateRange: "today_end-of-week" as
        | "today_end-of-week"
        | "today_tomorrow"
        | "today"
        | "tomorrow"
        | "this-week"
        | "next-week"
        | "last-week"
        | "custom",
      customDateRange: undefined as undefined | [number, number],
      type: Array<string>(),
      tags: Array<string>(),
      cancelled: null as null | boolean,
      selected: null as string | null,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name",
          },
          models: {
            value: "search",
          },
        }),
      ],
      dateRange: [
        FormSelectField({
          reactive: true,
          props: {
            label: "Time frame",
            options: dateRanges,
            showClearButton: false,
            mapOptions: {
              label: "label",
              value: "value",
            },
            reference: "dateRange",
          },
          models: {
            value: {
              get: (data) =>
                dateRanges.findBy("value", data.dateRange) ??
                "today_end-of-week",
              set: (value, data, controls) => (
                (data.dateRange = value?.value ?? "today_end-of-week"),
                controls.rebuildForm()
              ),
            },
          },
        }),
        CreatedAtField({
          props: {
            vIf: (data) => data.dateRange === "custom",
            label: "Custom Date Range",
            vModel: "customDateRange",
          },
          simplifyData: true,
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "appointmentType",
          props: {
            placeholder: "Any",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      specialist: [
        UserField({
          props: {
            label: "Assigned to",
            vModel: "specialist",
            placeholder: "Any",
            isMultiSelect: true,
          },
          query: {
            acuityUserId: IsNotNull(),
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({
      cancelled: [
        FormSelectField({
          props: {
            label: "Cancelled",
            placeholder: "Any",
            options: {
              true: "Yes",
              false: "No",
            },
            reference: "cancelled",
          },
          models: {
            value: {
              get: (data) =>
                data.cancelled === null ? null : data.cancelled.toString(),
              set: (value, data) =>
                (data.cancelled = value === null ? null : value === "true"),
            },
          },
        }),
      ],
      outcome: [
        OptionsAutoCompleteField({
          vModel: "outcome",
          group: "appointmentOutcome",
          props: {
            placeholder: "Any",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      tags: [
        TagsField({
          props: {
            vModel: "tags",
            placeholder: "Any",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      referral: [
        AccountReferralCodeField({
          props: {
            label: "Referral code",
            vModel: "referral",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
    }),
  });
