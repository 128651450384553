<template>
  <div class="knowledge-base-category-details">
    <PageHeader htag="h1" text="Knowledge Base">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="create"
        >
          Create Category
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        createdAt: 'Created',
        title: 'Title',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KnowledgeBaseCategoryMasterListWrapper",
  "route": "KnowledgeBaseCategoryMasterList",
  "selector": ".knowledge-base-category-details",
  "imports": {
    "ReadKnowledgeBaseCategoryFormWrapper": "@/module/knowledge-base/form/read-knowledge-base-category.form.test",
    "UpdateKnowledgeBaseCategoryFormWrapper": "@/module/knowledge-base/form/update-knowledge-base-category.form.test",
    "KnowledgeBaseCategoryDetailsWrapper": "@/module/knowledge-base/page/KnowledgeBaseCategoryDetails.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKnowledgeBaseCategoryFormWrapper"
    },
    "create": {
      "type": "click",
      "selector": ".create"
    },
    "categoryForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateKnowledgeBaseCategoryFormWrapper"
    },
    "categoryDetails": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KnowledgeBaseCategoryDetailsWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateKnowledgeBaseCategory } from "@/config/model.config";
import { ReadKnowledgeBaseCategoryForm } from "@/module/knowledge-base/form/read-knowledge-base-category.form";
import { UpdateKnowledgeBaseCategoryForm } from "@/module/knowledge-base/form/update-knowledge-base-category.form";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IKnowledgeBaseCategory, KnowledgeBaseCategory } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  KnowledgeBaseCategoryDetailsParams,
  KnowledgeBaseCategoryDetailsRoute,
  KnowledgeBaseCategoryMasterListRoute,
} from ".";

export default defineComponent({
  name: KnowledgeBaseCategoryMasterListRoute,
  components: { MasterListPage, Button, PageHeader },
  mixins: [AuthService.mixin()],
  data: () => ({
    filters: ReadKnowledgeBaseCategoryForm(),
    columns: [
      {
        title: "Name",
        sort: true,
        map: (v: KnowledgeBaseCategory) => v.title,
      },
    ] as Array<TableColumn>,
    rows: Array<KnowledgeBaseCategory>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "title" as keyof IKnowledgeBaseCategory,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("knowledge-base-category:write");
    },
  },
  methods: {
    handleClick(
      knowledgebasecategory: KnowledgeBaseCategory,
      event: MouseEvent,
    ): void {
      const params: KnowledgeBaseCategoryDetailsParams = {
        category: knowledgebasecategory.id,
      };

      window.Kernel.visitRoute(
        {
          name: KnowledgeBaseCategoryDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadKnowledgeBaseCategoryForm>["localData"],
    ): Promise<void> {
      const data = await window.Kernel.ActionBus.knowledgeBase.category.read({
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.knowledgeBaseCategories;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      const knowledgeBaseCategory = await UpdateKnowledgeBaseCategoryForm(
        CreateKnowledgeBaseCategory(),
      ).dialog({
        dialog: {
          title: "Create Knowledge Base Category",
        },
      });

      knowledgeBaseCategory.$persist();

      await window.Kernel.ActionBus.knowledgeBase.category.create({
        knowledgeBaseCategory,
      });

      this.refresh(this.filters?.localData ?? {});
    },
  },
});
</script>
