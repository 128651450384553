import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, FilterModelInternals, In, Like } from "@kinherit/orm";
import {
  DeepPartialArrayApiQuery,
  IIntroducerOutsource,
  IntroducerCompany,
  IntroducerOutsource,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";

export interface ReadIntroducerOutsourceMessage {
  name?: string;
  type?: Array<string>;
  status?: Array<string>;
  created?: null | [number, number];
  dueBy?: null | "today" | "tomorrow" | "overdue";
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IIntroducerOutsource;
    direction: "asc" | "desc";
  };
}

interface ReadIntroducerOutsourceResponse {
  introducerOutsources: Array<IntroducerOutsource>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerOutsourceHandler = async (
  message: ReadIntroducerOutsourceMessage,
): Promise<ReadIntroducerOutsourceResponse> => {
  // NB Duplicate logic from lead
  const { dueBy } = message;

  const today = new DateTime();

  const todayDateRange: [number, number] = [
    today.setTime(0, 0, 0, 0).timestamp,
    today.setTime(23, 59, 59, 999).timestamp,
  ];

  const tomorrow = new DateTime().add(1, "day");

  const tomorrowDateRange: [number, number] = [
    tomorrow.setTime(0, 0, 0, 0).timestamp,
    tomorrow.setTime(23, 59, 59, 999).timestamp,
  ];
  const yesterday = new DateTime().sub(1, "day");

  const overdueDateRange: [number, number] = [
    0,
    yesterday.setTime(23, 59, 59, 999).timestamp,
  ];

  const nextActionAtDateRange =
    dueBy === "today"
      ? todayDateRange
      : dueBy === "tomorrow"
        ? tomorrowDateRange
        : dueBy === "overdue"
          ? overdueDateRange
          : null;

  const request = Api.resource("portal", "/v2/portal/introducer-outsource")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  const sharedConditions: QueryMask<typeof IntroducerOutsource> = {
    type: {
      id: In(message.type),
    },
    status: {
      id: In(message.status),
    },
    nextActionAt: Between(nextActionAtDateRange),
    createdAt: Between(message.created),
  };
  const uniqueConditions: DeepPartialArrayApiQuery<
    FilterModelInternals<IntroducerOutsource>
  > = [];

  if (message.name) {
    uniqueConditions.push({
      clientName: Like(message.name),
      ...sharedConditions,
    });

    uniqueConditions.push({
      company: {
        profile: {
          organisationName: Like(message.name),
        },
      },
      ...sharedConditions,
    });
  }

  const query = request.buildQuery(IntroducerOutsource);

  if (uniqueConditions.length > 0) {
    query.where(uniqueConditions);
  } else {
    query.where(sharedConditions);
  }

  const response = await request.result();

  return {
    introducerOutsources: IntroducerOutsource.$inflate(
      response.introducerOutsource,
      message.sort,
      response.$rootIds,
    ),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    profiles: Profile.$inflate(response.profile),
    users: User.$inflate(response.user),
    pagination: getPagination(response),
  };
};
