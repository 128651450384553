import { Api } from "@/service/api.service";
import {
  AccountReferral,
  AccountReferralCode,
  IAccountReferral,
  IntroducerContact,
  Lead,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadIntroducerContactReferralLeadsMessage {
  introducerContact: IntroducerContact | string;
  referralCode: AccountReferralCode | string;
  query?: QueryMask<typeof Lead>;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferral;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerContactReferralLeadsResponse {
  leads: Array<Lead>;
  profiles: Array<Profile>;
  referrals: Array<AccountReferral>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerContactReferralLeadsHandler = async (
  message: ReadIntroducerContactReferralLeadsMessage,
): Promise<ReadIntroducerContactReferralLeadsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-contact-referral/{introducerContact}/referrals/{referralCode}/leads",
    {
      introducerContact:
        "string" === typeof message.introducerContact
          ? message.introducerContact
          : message.introducerContact.id,
      referralCode:
        "string" === typeof message.referralCode
          ? message.referralCode
          : message.referralCode.id,
    },
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  if (message.query) {
    request.buildQuery(Lead).where(message.query);
  }

  const response = await request.result();

  return {
    leads: Lead.$inflate(response.lead, message.sort, response.$rootIds),
    profiles: Profile.$inflate(response.profile),
    referrals: AccountReferral.$inflate(response.accountReferral),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
