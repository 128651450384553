import { Api } from "@/service/api.service";
import { Tag } from "@kinherit/sdk";

export const UpdateTagAction = async (message: Tag): Promise<Tag> => {
  const data = await Api.resource("portal", "/v2/portal/tag/{tag}", {
    tag: message.id,
  })
    .method("patch")

    .body({
      name: message.name,
      description: message.description,
    })
    .result();

  const tag = Tag.$inflate(data.tag).first();

  if (!tag) {
    throw new Error("Failed to update tag");
  }

  return tag;
};
