<template>
  <div class="letterofwishes">
    <div class="center">
      <h1 class="sanserif strong">
        PERSONAL POSSESSIONS<br />
        LETTER OF WISHES
      </h1>

      <p class="mt-1 strong">
        To the Executors of my Will dated:_______________________(my Will)
      </p>
    </div>
    <p>
      In my will I have left you my personal possessions and ask that you
      distribute them according to the latest wishes I express in any note. This
      note sets out my wishes.
    </p>
    <p>
      I appreciate that my request is not binding on you, and is simply an
      expression of my wishes, but I ask that you follow my wishes wherever
      possible.
    </p>

    <p class="mt-2">&nbsp;</p>
    <p class="mt-3">&nbsp;</p>
    <p class="mt-3">&nbsp;</p>
    <p class="mt-3">&nbsp;</p>

    <p>
      Any of my personal possessions that you do not distribute in accordance
      with the above I would wish to form part of my residuary estate.
    </p>

    <p></p>
    <p>Signed by the Testator #UCASE(params.testator)#</p>
    <p>Signature __________________________________ Date ________________</p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
