import { WillBuilderHelpers } from "@/module/kinvault.kintin/service/will-builder.helpers";
import { reactive } from "vue";

export class WillBuilderNotices extends WillBuilderHelpers {
  static notices = reactive({
    warnings: [] as string[],
    errors: [] as string[],
  });

  static refreshWarnings(): void {
    if (!this.data) {
      throw new Error(`Data not loaded`);
    }

    if (!this.options.person) {
      throw new Error(`Person not set`);
    }

    if (!this.options.kintin) {
      throw new Error(`Kintin not set`);
    }

    if (!this.form) {
      throw new Error(`Form not set`);
    }

    const err: string[] = [];
    const warn: string[] = [];

    // Critical Errors
    if (
      this.options.person.profile.firstName === null ||
      this.options.person.profile.lastName === null
    ) {
      err.push("Settlor's name is required");
    }

    if (
      this.options.person.profile.addresses.findBy(
        "primaryResidential",
        true,
      ) === undefined
    ) {
      err.push("Settlor's main Address is empty");
    }

    if (this.options.person.dateOfBirth === null) {
      err.push("Settlor's Date of Birth is empty");
    }

    if (this.data.primaryExecutors.length === 0) {
      err.push("No Executors have been added");
    }
    if (this.data.beneficiariesFirstLine.length === 0) {
      err.push("No first line Beneficiaries have been added");
    }
    if (
      this.data.kintinType === "joint" &&
      ((this.options.partner?.profile.firstName ?? null) === null ||
        (this.options.partner?.profile.lastName ?? null) === null)
    ) {
      err.push("Partners name hasn't been set");
    }

    const personType = this.options.type.ucFirst() as "Primary" | "Secondary";

    const relationship =
      null !== personType
        ? this.data.partner?.[`relationTo${personType}Person`]
        : null;

    if (this.data.kintinType === "joint" && relationship === null) {
      err.push("Relationship to partner hasn't been set");
    }
    if (
      this.data.kintinType === "joint" &&
      this.form.hasIipTrust === true &&
      !["married", "civil"].includes(
        this.options.person.maritalStatus?.value as string,
      )
    ) {
      err.push(
        `You are adding an IIP trust for someone who isn't married? (or we don't know their marital status)`,
      );
    }

    if (
      this.form.hasBusinessTrust === true &&
      this.form.hasBusinessClause === true
    ) {
      err.push("You cannot have both a business clause and a business trust");
    }
    const checks = this.options.kintin.checks;

    if (
      this.checkIsYes("4.6.3b", checks) &&
      this.checkValueByStep("4.6.3c", checks) === null
    ) {
      err.push("Missing Robin Hood Clause option for Executors");
    }
    if (
      this.checkIsYes("4.4.2", checks) &&
      this.checkValueByStep("4.4.2a", checks) === null
    ) {
      err.push("Missing Robin Hood Clause option for Trustees");
    }

    this.notices.warnings = warn;
    this.notices.errors = err;
  }
}
