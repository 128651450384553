<template>
  <strong>Survivorship</strong>
  <p>
    Any Person who does not survive me by twenty eight days who would otherwise
    be a beneficiary under my Will shall be treated for the purposes of my Will
    as having died in my lifetime
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SurvivorshipOutput",
  mixins: [WillBuilderService.mixin],
});
</script>
