import {
  OptionsAutoCompleteField,
  OptionsSelectField,
} from "@/config/form.config";
import { CreateEmailTemplate } from "@/config/model.config";
import MarkDownEditor from "@/module/core/component/markdown-editor/MarkDownEditor.vue";
import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { EmailTemplate } from "@kinherit/sdk";
import { reactive } from "vue";

export const UpdateEmailTemplateForm = (
  emailTemplate: EmailTemplate = CreateEmailTemplate(),
) => {
  const localData = reactive({
    activeTab: 0,
  });

  const emailData = {
    leadfirstname: null,
    specialist: null,
    outreach: null,
    recipients: null,
    aboutkinherit: null,
    trname: null,
    outreachnumber: null,
    introducername: null,
    introducercompany: null,
    officername: null,
    introducerfirstname: null,
    ktref: null,
    leadname: null,
    testatorfullname: null,
    yourOrName: null,
    trref: null,
    trpin: null,
    primaryfirstname: null,
    kinvaultloginurl: null,
  };

  return defineForm({
    name: "update-email-template-form",
    data: () => emailTemplate,
    formAreas: (data) => [
      defineFormArea({
        name: "update-email-template-form-area",
        data,
        components: (data) => ({
          default: [
            FormTabs({
              props: {
                isLazy: true,
                config: [
                  {
                    label: "Settings",
                  },
                  {
                    label: "Content",
                  },
                ],
                reference: "tabs",
              },
              models: {
                tab: {
                  get: () => localData.activeTab,
                  set: (value: number) => (localData.activeTab = value),
                },
              },
              slots: {
                settings: {
                  template: GridLayout([
                    ["name", "type"],
                    ["includeTo"],
                    ["preloadedFiles"],
                    ["requiredAttachments"],
                    ["allowAttachments"],
                    ["strictAttachments"],
                    ["notes"],
                  ]),
                  components: {
                    name: [
                      FormTextField({
                        props: {
                          label: "Email Template Name",
                          validators: ["required"],
                        },
                        models: {
                          value: "name",
                        },
                      }),
                    ],
                    type: [
                      OptionsSelectField({
                        group: "emailTemplateType",
                        vModel: "type",
                        props: {
                          label: "Type",
                          placeholder: "Select a type",
                          validators: ["required"],
                        },
                      }),
                    ],
                    includeTo: [
                      OptionsSelectField({
                        group: "emailTemplateIncludeTo",
                        vModel: "includeTo",
                        props: {
                          label: "To Include",
                        },
                      }),
                    ],
                    preloadedFiles: [
                      OptionsAutoCompleteField({
                        group: "preloadedEmailAttachments",
                        vModel: "preloadedFiles",
                        props: {
                          label: "Preloaded Files",
                          isMultiSelect: true,
                        },
                      }),
                    ],
                    requiredAttachments: (
                      data.requiredAttachments as (string | null)[]
                    )
                      .concat(null)
                      .map((_, index) =>
                        FormTextField({
                          props: {
                            label:
                              index === 0 ? "Required Attachment" : undefined,
                            validators: data.requiredAttachments.outOfBounds(
                              index,
                            )
                              ? []
                              : ["required"],
                            placeholder: data.requiredAttachments.outOfBounds(
                              index,
                            )
                              ? "-- Enter a file name to add a required attachment --"
                              : undefined,
                            reference: `requiredAttachments.${index}`,
                          },
                          models: {
                            value: {
                              get: () => data.requiredAttachments[index],
                              set: (value: string, data, controls) => {
                                const requiredAttachments =
                                  data.requiredAttachments;

                                const exists =
                                  requiredAttachments.outOfBounds(index);

                                requiredAttachments[index] = value;

                                data.requiredAttachments = requiredAttachments;

                                if (!exists) {
                                  controls.rebuildForm();
                                }
                              },
                            },
                          },
                          emits: {
                            clear: (_, data, controls) => {
                              const requiredAttachments =
                                data.requiredAttachments;
                              requiredAttachments.splice(index, 1);
                              data.requiredAttachments = requiredAttachments;

                              controls.rebuildForm();
                            },
                          },
                          slots: {
                            right: [
                              FormButton({
                                props: {
                                  iconLeft: "Minus",
                                  vIf: !data.requiredAttachments.outOfBounds(
                                    index,
                                  ),
                                  reference: "removeAttachment",
                                },
                                emits: {
                                  click: (_, data, controls) => {
                                    const requiredAttachments =
                                      data.requiredAttachments;
                                    requiredAttachments.splice(index, 1);
                                    data.requiredAttachments =
                                      requiredAttachments;

                                    controls.rebuildForm();
                                  },
                                },
                              }),
                            ],
                          },
                        }),
                      ),
                    allowAttachments: [
                      FormCheckboxField({
                        props: {
                          label: "Allow Custom Attachments",
                        },
                        models: {
                          value: "allowAttachments",
                        },
                      }),
                    ],
                    strictAttachments: [
                      FormCheckboxField({
                        props: {
                          label:
                            "Force Attachments to include the subjects reference",
                        },
                        models: {
                          value: "strictAttachments",
                        },
                      }),
                    ],
                    notes: [
                      FormTextField({
                        props: {
                          label: "Notes",
                          isTextArea: true,
                        },
                        models: {
                          value: "notes",
                        },
                      }),
                    ],
                  },
                },
                content: [
                  FormComponentWrapper(MarkDownEditor)({
                    props: {
                      subjectTemplate: (data: EmailTemplate) => data.subject,
                      bodyTemplate: (data: EmailTemplate) => data.body,
                      params: emailData,
                    },
                    models: {
                      title: "subject",
                      body: "body",
                    },
                  }),
                ],
              },
            }),
          ],
        }),
      }),
    ],
  });
};
