import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { FormUrlField } from "@kinherit/framework/component.input/url-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadBrandedKinvaultForm = () =>
  MasterListFiltersForm({
    name: "filter-call-log-form",
    data: () => ({
      name: "",
      created: null as null | [number, number],
      type: Array<string>(),
      poolType: Array<string>(),
      mode: Array<string>(),
      frontendUrl: "",
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
          },
          models: {
            value: "name",
          },
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "organisationTypes",
          simplifyData: true,
          props: {
            label: "Type",
            isMultiSelect: true,
          },
        }),
      ],
      poolType: [
        OptionsAutoCompleteField({
          vModel: "poolType",
          group: "clientPoolTypes",
          simplifyData: true,
          props: {
            label: "Pool Type",
            isMultiSelect: true,
          },
        }),
      ],
      mode: [
        OptionsAutoCompleteField({
          vModel: "mode",
          group: "organisationSiteMode",
          simplifyData: true,
          props: {
            label: "Mode",
            isMultiSelect: true,
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({
      frontendUrl: [
        FormUrlField({
          props: {
            noProtocol: true,
            label: "Kinvault URL",
          },
          models: {
            value: "frontendUrl",
          },
        }),
      ],
    }),
  });
