import { Api } from "@/service/api.service";
import { IntroducerFeePaymentRun, QueryMask } from "@kinherit/sdk";

export interface ReadIntroducerFeePaymentRunMessage {
  query?: QueryMask<typeof IntroducerFeePaymentRun>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadIntroducerFeePaymentRunResponse {
  introducerFeePaymentRuns: Array<IntroducerFeePaymentRun>;
}

export const ReadIntroducerFeePaymentRunHandler = async (
  message: ReadIntroducerFeePaymentRunMessage,
): Promise<ReadIntroducerFeePaymentRunResponse> => {
  const request = Api.resource(
    "core",
    "/v2/core/select/introducer-fee-payment-run",
  )
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(IntroducerFeePaymentRun).where(message.query ?? {});

  const response = await request.result();

  return {
    introducerFeePaymentRuns: IntroducerFeePaymentRun.$inflate(
      response.introducerFeePaymentRun,
      undefined,
      response.$rootIds,
    ),
  };
};
