import { Api } from "@/service/api.service";
import { Property } from "@kinherit/sdk";

interface UpdatePropertyMessage {
  kintin: string;
  property: Property;
}

interface UpdatePropertyResponse {
  property: Property;
}

export const UpdatePropertyHandler = async (
  message: UpdatePropertyMessage,
): Promise<UpdatePropertyResponse> => {
  const { property: propertyData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-property/{kintin}/properties/{property}",
    {
      kintin: message.kintin,
      property: message.property.$data.id,
    },
  )
    .method("patch")

    .body({
      titleDeed: message.property.$data.titleDeed,
      price: message.property.$data.price,
      value: message.property.$data.value,
      lessMortgage: message.property.$data.lessMortgage,
      notes: message.property.$data.notes,
      address: message.property.$data.address,
      howOwned: message.property.$data.howOwned,
      ownership: message.property.$data.ownership,
      type: message.property.$data.type,
    })
    .result();

  const property = Property.$inflate(propertyData).first();

  if (!property) {
    throw new Error("Failed to update property");
  }

  return { property };
};
