import { RouteInterface } from "@kinherit/framework/core/route-config";

export const IntroducerBillingContactMasterListRoute =
  "IntroducerBillingContactMasterList";
// export const IntroducerBillingContactDetailsRoute = "IntroducerBillingContactDetails";
// export const IntroducerBillingContactDetailsURI = "/introducer/contract/:id";
// export type IntroducerBillingContactDetailsParams = RouteParamObject<
//   typeof IntroducerBillingContactDetailsURI
// >;

const IntroducerBillingContactMasterListBreadCrumb = {
  text: "Billing Contacts",
  route: { name: IntroducerBillingContactMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  /** Contract */
  {
    name: IntroducerBillingContactMasterListRoute,
    path: "/introducer/billing-contacts",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerBillingContactMasterList" */ "./IntroducerBillingContactMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerBillingContactMasterListBreadCrumb],
    },
  },
];
