<template>
  <Badge
    :class="computedStatus.class"
    :text="computedStatus.text"
    :icon="computedStatus.icon"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Badge from "@kinherit/framework/component.display/badge";
import { ThemeIconNameType } from "@kinherit/framework/theme/prop/icon";
export default defineComponent({
  name: "PermissionStatusBadge",
  components: {
    Badge,
  },
  props: {
    status: {
      type: [Boolean, String, Object] as PropType<
        boolean | "any" | "own" | "none" | undefined | null
      >,
      default: undefined,
      required: false,
    },
  },
  computed: {
    computedStatus(): {
      class: string;
      text: string;
      icon: ThemeIconNameType;
    } {
      if (this.status === true) {
        return {
          text: "Yes",
          class: "is-success is-light",
          icon: "Tick",
        };
      }
      if (this.status === false) {
        return {
          text: "No",
          class: "is-danger is-light",
          icon: "Cross",
        };
      }
      if (this.status === "any") {
        return {
          text: "Any",
          class: "is-success is-light",
          icon: "Tick",
        };
      }
      if (this.status === "own") {
        return {
          text: "Own",
          class: "is-warning is-light",
          icon: "Tick",
        };
      }
      if (this.status === "none") {
        return {
          text: "None",
          class: "is-danger is-light",
          icon: "Cross",
        };
      }
      return {
        text: "None",
        class: "is-danger is-light",
        icon: "Cross",
      };
    },
  },
});
</script>
