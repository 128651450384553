import { AdminBrandedKinvaultDetailsSummaryBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsSummaryRoute =
  "BrandedKinvaultDetailsSummary";
export const BrandedKinvaultDetailsSummaryURI =
  "/admin/branded-kinvault/:brandedKinvault";
export type BrandedKinvaultDetailsEventsParams = RouteParamObject<
  typeof BrandedKinvaultDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsSummaryRoute,
    path: BrandedKinvaultDetailsSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsSummary" */ "./BrandedKinvaultDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsSummaryBreadCrumb,
    },
  },
];
