import { Api } from "@/service/api.service";
import {
  AccountReferralCode,
  IntroducerCompany,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadAccountReferralCodeMessage {
  query?: QueryMask<typeof AccountReferralCode>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadAccountReferralCodeResponse {
  accountReferralCode: Array<AccountReferralCode>;
  introducerCompanies: Array<IntroducerCompany>;
  profiles: Array<Profile>;
}

export const ReadAccountReferralCodeHandler = async (
  message: ReadAccountReferralCodeMessage,
): Promise<ReadAccountReferralCodeResponse> => {
  const request = Api.resource("core", "/v2/core/select/account-referral-code")
    .method("get")

    .paginate(message.pagination ?? false);

  request.buildQuery(AccountReferralCode).where(message.query ?? {});

  const response = await request.result();

  return {
    accountReferralCode: AccountReferralCode.$inflate(
      response.accountReferralCode,

      undefined,
      response.$rootIds,
    ),
    introducerCompanies: IntroducerCompany.$inflate(response.introducerCompany),
    profiles: Profile.$inflate(response.profile),
  };
};
