<template>
  <div v-if="data">
    <span>
      <strong>Gifts of Money</strong>
      <p>
        I Give the following pecuniary legacies absolutely and free of
        inheritance tax:
      </p>
      <ol type="i">
        <li v-for="gift in data.cashGifts" :key="gift.id">
          to
          <span v-html="helpers.var(helpers.formatRecipient(gift, true))" /> the
          sum of
          <span v-html="helpers.var(gift.amount?.format)" />
          <span v-if="gift.onSecondDeath">
            only if my
            <span
              v-if="data.person"
              v-html="
                helpers.var(
                  helpers.formatRelationship(data.person, data.partner),
                )
              "
            />
            has predeceased me
          </span>
          <span v-html="helpers.var(helpers.formatPredeceased(gift))" />
        </li>
      </ol>
    </span>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
