import { Api } from "@/service/api.service";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface DeleteBrandedKinvaultInvitationMessage {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface DeleteBrandedKinvaultInvitationResponse {
  [key: string]: never;
}

export const DeleteBrandedKinvaultInvitationHandler = async (
  message: DeleteBrandedKinvaultInvitationMessage,
): Promise<DeleteBrandedKinvaultInvitationResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}",
    {
      brandedKinvaultInvitation: message.brandedKinvaultInvitation.id,
    },
  )
    .method("delete")
    .result();

  message.brandedKinvaultInvitation.$delete();

  return {};
};
