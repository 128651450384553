import { Api, Beneficiary, Kintin } from "@kinherit/sdk";

interface DeleteBeneficiaryMessage {
  kintin: string | Kintin;
  beneficiary: Beneficiary;
}

interface DeleteBeneficiaryResponse {
  [key: string]: never;
}

export const DeleteBeneficiaryHandler = async (
  message: DeleteBeneficiaryMessage,
): Promise<DeleteBeneficiaryResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-beneficiary/{kintin}/person/beneficiary/{beneficiary}",
    {
      kintin: message.kintin,
      beneficiary: message.beneficiary.$data.id,
    },
  )
    .method("delete")
    .result();

  message.beneficiary.$delete();

  return {};
};
