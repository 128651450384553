import { SharedFormProps, XeroContactField } from "@/config/form.config";

import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";

import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Currency } from "@kinherit/ts-common/index";

import { FormTitle } from "@kinherit/framework/component.display/title";
import { Equal } from "@kinherit/orm/index";

export const CreateXeroRepeatingInvoice = (data: {
  formTitle?: string;
  price: Currency;
  subscriptionId: string;
  xeroContactId?: string;
  xeroContactName?: string;
  quantity?: number;
  description?: string;
  accountCode?: string;
  searchTerm?: string;
  ref?: string;
  suggestedXeroContactId?: string;
}) => {
  return defineForm({
    name: "create-xero-repeating-form",
    props: SharedFormProps,
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-xero-repeating-form-area",
        data,
        template: GridLayout([
          ["title"],
          ["price", "quantity", "description"],
          [
            {
              is: "hr",
            },
          ],
          ["suggestedXeroContactId", "xeroContactId"],
        ]),
        components: () => ({
          title: [
            FormTitle({
              props: {
                text: data.formTitle,
                size: "is-5",
              },
            }),
          ],
          price: [
            FormCurrencyField({
              props: {
                label: "Price",
                validators: ["required"],
                message: "Value of the subscription",
                showClearButton: false,
              },
              models: {
                value: "price",
              },
            }),
          ],
          quantity: [
            FormNumberField({
              props: {
                label: "Quantity",
                message: "Should always be 1 for now",
              },
              models: {
                value: "quantity",
              },
            }),
          ],
          description: [
            FormTextField({
              props: {
                label: "Description",
                message: "Description of the lineitem",
              },
              models: {
                value: "description",
              },
            }),
          ],
          suggestedXeroContactId: [
            XeroContactField({
              props: {
                label: "Suggested Xero Contact",
                message: "Pre-filtered by KT REF",
                reference: "suggestedXeroContactId",
                vModel: {
                  get: (data) => data.suggestedXeroContactId,
                  set: (value, data, controls) => {
                    if (null === value) {
                      return;
                    }
                    data.suggestedXeroContactId = value.id;
                    data.xeroContactId = value.id;
                    controls.incrementFormRenderKey();
                  },
                },
              },
              query: {
                xeroAccountNumber: Equal(data.ref),
              },

              simplifyData: true,
            }),
          ],
          xeroContactId: [
            XeroContactField({
              props: {
                label: "All Xero Contacts",
                message: "All contacts",
                vModel: "xeroContactId",
              },
              simplifyData: true,
            }),
          ],
        }),
      }),
    ],
  });
};
