import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface UpdateKintinSignDocMessage {
  signDoc: SignDoc;
  kintin: Kintin | string;
}

export interface UpdateKintinSignDocResponse {
  signDoc: SignDoc;
}

export const UpdateKintinSignDocHandler = async (
  message: UpdateKintinSignDocMessage,
): Promise<UpdateKintinSignDocResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}",
    {
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
      signDoc: message.signDoc.id,
    },
  )
    .method("patch")
    .body({
      person: message.signDoc.$data.person,
      data: message.signDoc.$data.data,
      kintin: message.signDoc.$data.kintin,
      type: message.signDoc.$data.type,
      ipAddress: message.signDoc.$data.ipAddress,
      userAgent: message.signDoc.$data.userAgent,
      signedAt: message.signDoc.$data.signedAt,
      stripeSessions: message.signDoc.$data.stripeSessions,
      clauses: message.signDoc.$data.clauses,
      conditions: message.signDoc.$data.conditions,
      products: message.signDoc.$data.products,
    })
    .result();

  const signDoc = SignDoc.$inflate(response.signDoc).first();

  if (!signDoc) {
    throw new Error("SignDoc not found");
  }

  await Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}/disclaimer",
    {
      kintin: signDoc.$data.kintin,
      signDoc: signDoc.$data.id,
    },
  )
    .method("put")
    .result();

  return {
    signDoc,
  };
};
