import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadNotificationLogForm = (): FormBuilder<
  () => {
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-notification-log",
    data: () => ({
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
