import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsWillBreadCrumb } from "../../../breadcrumbs";

/** Will */
export const KinvaultKintinDetailsWillRoute = "KinvaultKintinDetailsWill";
const KinvaultKintinDetailsWillURI = "/kinvault/:kintin/will";
export type KinvaultKintinDetailsWillParams = RouteParamObject<
  typeof KinvaultKintinDetailsWillURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsWillRoute,
    path: KinvaultKintinDetailsWillURI,
    components: {
      sidebar: () =>
        import(
          /* webpackChunkName: "KintinDetailsWillSidebar" */ "./KintinDetails.Will.Sidebar.vue"
        ),
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsWill" */ "./KintinDetails.Will.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsWillBreadCrumb,
    },
  },
];
