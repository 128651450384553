import { ActiveSession } from "@/module/auth/model/active-session.model";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { OpenContextMenu } from "@kinherit/framework/global/context-menu";
import {
  OpenAutocompleteDialog,
  OpenDangerDialog,
} from "@kinherit/framework/global/dialog";
import { Api, Role, User } from "@kinherit/sdk";

export const DevConfig = async () => {
  if (window.Kernel.Mode !== KernelModes.Dev) {
    return;
  }

  const option = await OpenContextMenu({
    items: [
      {
        label: "Exception",
        message: "Test an API exception",
        action: async () => {
          const exception = await OpenAutocompleteDialog({
            dialog: {
              title: "Exception",
              message: "Select the exception to throw",
            },
            list: {
              options: () => [
                { label: "400" },
                { label: "400/validation" },
                { label: "401" },
                { label: "401/incorrect-credentials" },
                { label: "401/max-login-attempts-exceeded" },
                { label: "401/token-expired" },
                { label: "401/token-incorrect-level" },
                { label: "401/token-missing" },
                { label: "401/token-incomplete" },
                { label: "403/permission-denied" },
                { label: "404" },
                { label: "410/entity-not-found" },
                { label: "500" },
                { label: "500/error" },
                { label: "500/history" },
              ],
              mapOptions: {
                label: "label",
                value: "label",
              },
            },
          });

          await window.Kernel.Api.resource(
            "development",
            `/v2/development/exception/${exception.label}` as any,
          )
            .method("get")
            .result();
        },
      },
      {
        label: "Send notifications",
        message: "Send queued notifications",
        action: () =>
          Api.resource("external", "/v2/external/cron-job/notifications")
            .method("put")
            .result(),
      },
      {
        label: "Send email logs",
        message: "Send queued email logs",
        action: () =>
          Api.resource("external", "/v2/external/cron-job/email-log")
            .method("put")
            .result(),
      },
      {
        label: "Send email campaign logs",
        message: "Send queued email campaign logs",
        action: async () => {
          await Api.resource(
            "external",
            "/v2/external/cron-job/email-campaign-log",
          )
            .method("put")
            .result();

          await Api.resource("external", "/v2/external/cron-job/email-log")
            .method("put")
            .result();
        },
      },
      {
        label: "Roles",
        message: "Change the roles assigned to the current session",
        action: ChangeRoles,
      },
      {
        label: "Expire session",
        message: "Set the current session to expire",
        action: async () => {
          const activeSession = ActiveSession.$getInstance();

          if (!activeSession) {
            await OpenDangerDialog({
              dialog: {
                title: "No active session",
                message: "There is no active session to expire",
              },
            });

            return;
          }

          await window.Kernel.Api.resource(
            "development",
            "/v2/development/auth/token/invalidate",
            {
              jwtToken: activeSession.$data.token,
            },
          )
            .method("put")
            .result();
        },
      },
    ],
    titleField: "label",
    trackingField: "label",
    line1Field: "message",
  });

  option.action();
};

const ChangeRoles = async () => {
  const activeSession = ActiveSession.$getInstance();

  if (!activeSession) {
    throw new Error("No active session");
  }

  const user = User.$findOneOrThrow(activeSession.$data.user);

  const selectedRoles = (await OpenAutocompleteDialog({
    dialog: {
      title: "Roles",
      message: "Select the roles to available to the current session",
    },
    list: {
      options: () => Role.$all(),
      mapOptions: {
        value: "id",
        label: "name",
      },
      isMultiSelect: true,
    },
    value: user.roles,
  })) as Array<Role>;

  const currentRoles = user.roles.pluck("id").unique();

  for (const role of selectedRoles) {
    if (!currentRoles.includes(role.id)) {
      user.addRoles(role);
      await window.Kernel.Api.resource(
        "development",
        "/v2/development/auth/user/{user}/role/{role}",
        {
          user,
          role,
        },
      )
        .method("post")
        .result();

      currentRoles.push(role.role);
    }
  }

  for (const role of user.roles) {
    if (!selectedRoles.pluck("id").includes(role.id)) {
      await window.Kernel.Api.resource(
        "development",
        "/v2/development/auth/user/{user}/role/{role}",
        {
          user,
          role,
        },
      )
        .method("delete")
        .result();
      user.removeRoles(role);
    }
  }

  user.$persist();
  window.Kernel.ComponentOptions.App.callRefresh();
};
