import { AuthLoginRoute, AuthRequestMfRoute } from "@/module/auth/page";
import { Store } from "@kinherit/orm";
import { Api } from "@kinherit/sdk";
import { ActiveSession } from "../module/auth/model/active-session.model";
// import { LoginDialogService } from "./login-dialog.service";

Api.setup({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

Api.callback(async (request, axiosInstance) => {
  const headers: Record<string, string> = {};

  const activeSession = ActiveSession.$getInstance();

  if (null !== activeSession) {
    headers["Authorization"] = `Bearer ${activeSession.token}`;
  }

  headers["X-Client-Uri"] = window.location.href;

  const response = await axiosInstance.request({
    ...request,
    headers: {
      ...headers,
      ...(request.headers ?? {}),
    },
  });

  return response.data;
});

const LogoutHandler = async () => {
  Store.purge();
  window.Kernel.visitRoute({
    name: AuthLoginRoute,
  });

  return false;
};

Api.catch("TokenMissingException", LogoutHandler);

Api.catch("UserNotFoundException", LogoutHandler);

Api.catch("IncorrectTokenLevelException", async () => {
  throw new Error("Method not implemented.");
});

Api.catch("TokenIncompleteException", async () => {
  window.Kernel.visitRoute({
    name: AuthRequestMfRoute,
  });
  return true;
});

// Api.catch("TokenExpiredException", async () => {
//   if (window.Kernel.Mode === KernelModes.Test) {
//     // window.Kernel.ActionBus.execute("auth/session/logout", {});
//     window.Kernel.ActionBus.auth.session.logout({});
//     return false;
//   }

//   try {
//     await QueueAuthRequest();
//   } catch {
//     // window.Kernel.ActionBus.execute("auth/session/logout", {});
//     window.Kernel.ActionBus.auth.session.logout({});
//     return false;
//   }
//   return true;
// });

import { LoginDialogService } from "@kinherit/framework/api/login-dialog";

LoginDialogService.init({
  isMfaRequired: () => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      throw new Error("No active session found");
    }

    return activeSession.mfaRequired;
  },
  getMFAMethods: async () => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      throw new Error("No active session found");
    }

    return activeSession.mfMethods;
  },
  login: async (password) => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      throw new Error("No active session found");
    }

    try {
      await window.Kernel.ActionBus.auth.session.request({
        username: activeSession.username,
        password,
      });
      return true;
    } catch {
      return false;
    }
  },
  validateMfaCode: async (method, code, multiFactorSessionId) => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      throw new Error("No active session found");
    }

    try {
      await window.Kernel.ActionBus.auth.multiFactor.complete({
        multiFactorSessionId: multiFactorSessionId,
        activeSession: activeSession,
        code,
      });
      return true;
    } catch {
      return false;
    }
  },
  sendMfaCode: async (method) => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      throw new Error("No active session found");
    }

    const multifactorRequest =
      await window.Kernel.ActionBus.auth.multiFactor.request({
        methodId: method.id,
      });

    return {
      multiFactorSessionId: multifactorRequest.multiFactorSessionId,
      codeStart: multifactorRequest.code ?? null,
    };
  },
  getUsersName: async () => {
    const activeSession = ActiveSession.$getInstance();

    if (null === activeSession) {
      throw new Error("No active session found");
    }

    return activeSession.username;
  },
  logout: async () => {
    window.Kernel.ActionBus.auth.session.logout({});
  },
  passwordType: "password",
});

export { Api };

// const LoginQueue: Array<{
//   resolve: Function;
//   reject: Function;
// }> = [];

// const QueueAuthRequest = async () => {
//   const promise = new Promise((resolve, reject) => {
//     LoginQueue.push({
//       resolve,
//       reject,
//     });

//     if (LoginQueue.length === 1) {
//       new LoginDialogService()
//         .login()
//         .then(() => {
//           LoginQueue.forEach((item) => {
//             item.resolve();
//           });
//         })
//         .catch((e) => {
//           LoginQueue.forEach((item) => {
//             item.reject(e);
//           });
//         })
//         .finally(() => {
//           LoginQueue.splice(0, LoginQueue.length);
//         });
//     }
//   });
//   const result = await await promise;

//   return result;
// };
