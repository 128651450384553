import { OptionsSelectField, TagsField } from "@/config/form.config";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Appointment, Tag } from "@kinherit/sdk";

export const UpdateAppointmentOutcomeForm = (appointment: Appointment) =>
  defineForm({
    name: "update-appointment-outcome-form",
    data: () => appointment,
    formAreas: (data) => [
      defineFormArea({
        name: "update-appointment-outcome-form-area",
        data,
        template: GridLayout([["outcome", "tags"], ["notes"]]),
        components: () => ({
          outcome: [
            OptionsSelectField({
              props: {
                validators: ["required"],
              },
              group: "appointmentOutcome",
              vModel: "outcome",
            }),
          ],
          tags: [
            TagsField({
              props: {
                isMultiSelect: true,
                reference: "tags",
                vModel: {
                  get: (data) => data.tags,
                  set: (value: Array<Tag>, data) => {
                    value.forEach((tag) => {
                      if (!data.tags.pluck("id").includes(tag.id)) {
                        data.addTags(tag);
                      }
                    });

                    data.tags.forEach((tag) => {
                      if (!value.pluck("id").includes(tag.id)) {
                        data.removeTags(tag);
                      }
                    });
                  },
                },
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                label: "Notes",
              },
              models: {
                value: "notes",
              },
            }),
          ],
        }),
      }),
    ],
  });
