import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptFamilyGuardiansRoute =
  "KintinDetailsCallScriptFamilyGuardians";
export const KintinDetailsCallScriptFamilyGuardiansURI =
  "/kinvault/:kintin/call-script/family-guardians";
export type KintinDetailsCallScriptFamilyGuardiansParams = RouteParamObject<
  typeof KintinDetailsCallScriptFamilyGuardiansURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptFamilyGuardiansRoute,
    path: KintinDetailsCallScriptFamilyGuardiansURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptFamilyGuardians" */ "./KinvaultKintinDetails.CallScript.FamilyGuardians.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Family & Guardians",
          route: { name: KintinDetailsCallScriptFamilyGuardiansRoute, params },
        },
      ],
    },
  },
];
