import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerFeePayment } from "@kinherit/sdk/index";

export const DownloadRemittanceForm = (
  introducerFeePayment: IntroducerFeePayment,
) =>
  defineForm({
    name: "download-remittance",
    data: () => ({
      type: null as
        | null
        | "comp-adv-cli-pro"
        | "comp-adv-cli"
        | "comp-adv"
        | "comp"
        | "client-by-adviser"
        | "client-only"
        | "amount-only"
        | "flying-colours"
        | "sort-refer",
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "download-remittance",
        data,
        components: () => ({
          default: [
            FormSelectField({
              props: {
                style: {
                  margin: "auto",
                },
                isFullwidth: false,
                // vIf: data.canDownloadRemittance,
                options: {
                  "comp-adv-cli-pro": "Company > Adviser > Client > Product",
                  "comp-adv-cli": "Company > Adviser > Client",
                  "comp-adv": "Company > Adviser",
                  comp: "Company Only",
                  "flying-colours": "Adviser > Client > Product",
                  "client-by-adviser": "Adviser > Client",
                  "client-only": "Client details only",
                  "amount-only": "Amount only",
                  "sort-refer": "Custom: SortRefer",
                },
                validators: ["required"],
              },
              models: {
                value: "type",
              },
            }),
          ],
        }),
      }),
    ],
  });
