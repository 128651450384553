import { RouteInterface } from "@kinherit/framework/core/route-config";
import { IntroducerNetworkMasterListBreadCrumb } from "../breadcrumbs";

export const IntroducerNetworkMasterListRoute = "IntroducerNetworkMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerNetworkMasterListRoute,
    path: "/introducer/networks",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerNetworkMasterList" */ "./IntroducerNetworkMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerNetworkMasterListBreadCrumb],
    },
  },
];
