<template>
  <div class="kintin-details-address-book-companies">
    <PageHeader htag="h2" text="Companies" v-if="kintin">
      <template #buttons>
        <Button text="Add Company" @click="createPerson" class="add-button" />
      </template>
    </PageHeader>
    <div v-if="kintin">
      <Tabs
        :config="tabConfig"
        v-model:tab="computedTab"
        v-show="computedShowTabs"
        size="is-normal"
        :is-fullwidth="false"
        class="mb-4 person-tabs"
      >
      </Tabs>
      <PersonList
        :key="renderKey"
        :kintin="kintin"
        :people="$data.filtered.people"
        @reload="filter"
      />
      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "CompaniesContainerWrapper",
  "selector": ".kintin-details-address-book-companies",
  "imports": {
    "PersonListWrapper": "@/module/kinvault.kintin/component/lists/PersonList.test",
    "UpdatePersonFormWrapper": "@/module/kinvault.kintin/form/update-person.form.test"
  },
  "methods": {
    "tabs": {
      "type": "indexed-tabs",
      "selector": ".person-tabs"
    },
    "personList": {
      "type": "to-one",
      "selector": ".person-list",
      "wrapper": "PersonListWrapper"
    },
    "addPerson": {
      "type": "click",
      "selector": ".add-button"
    },
    "addPersonForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdatePersonFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreatePerson } from "@/config/model.config";
import PersonList from "@/module/kinvault.kintin/component/lists/PersonList.vue";
import { UpdatePersonForm } from "@/module/kinvault.kintin/form/update-person.form";
import { StyleService } from "@/service/style.service";
import Json from "@kinherit/framework/component.display/json";
import Tabs from "@kinherit/framework/component.display/tabs";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `CompaniesContainer`,
  components: {
    Tabs,
    Json,
    PersonList,
    Button,
    PageHeader,
  },
  emits: ["reload"],
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    filtered: {
      people: Array<Person>(),
    },
    selectedTab: 0,
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
    renderKey: 0,
  }),
  computed: {
    computedShowTabs(): boolean {
      return (
        "string" === typeof this.kintin?.$data.primaryPerson &&
        "string" === typeof this.kintin?.$data.secondaryPerson
      );
    },
    tabConfig(): Array<{ label: string; person?: Person }> {
      const names = [];

      if (this.kintin?.primaryPerson) {
        names.push(this.kintin?.primaryPerson?.profile.fullName ?? "Primary");
      }
      if (this.kintin?.secondaryPerson) {
        names.push(
          this.kintin?.secondaryPerson?.profile.fullName ?? "Secondary",
        );
      }

      if (
        this.kintin?.$data.primaryPerson &&
        this.kintin?.$data.secondaryPerson
      ) {
        names.push("People");
      }

      return names.map((p, index) => {
        let person: Person | undefined;

        if (index === 0) {
          person = this.kintin?.primaryPerson ?? undefined;
        } else if (index === 1) {
          person = this.kintin?.secondaryPerson ?? undefined;
        }

        return {
          label: p as string,
          icon: StyleService.icon.person.icon,
          person,
        };
      });
    },
    computedTab: {
      get(): number {
        return this.selectedTab;
      },
      set(value: number) {
        this.selectedTab = value;
        this.filter();
      },
    },
  },
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      this.filtered.people = Person.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
        type: "company",
      });

      this.filtered.people = (this.filtered.people as Array<Person>)
        .unique("id")
        .sortBy("profile.fullName");

      this.renderKey++;

      this.$emit("reload");
    },
    async createPerson() {
      if (!this.kintin) {
        return;
      }

      const person = CreatePerson({
        type: "company",
      });

      try {
        await UpdatePersonForm({
          person,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create person",
          },
        });
      } catch {
        person.$delete({
          profile: true,
          relationToPrimaryPerson: true,
          relationToSecondaryPerson: true,
        });
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/person/create", {
      //   person: person,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.person.create({
        person,
        kintin: this.kintin,
      });

      this.filter();
    },
  },
});
</script>
