import {
  CreatedAtField,
  SharedFilterProps,
  UserField,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { Equal, In } from "@kinherit/orm";

import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { granularityOptions, yearOptions } from "../data/filter-options";

// Sets the default year to the current year
const thisYear = new Date().getFullYear();

// Sets the default start and end dates to the first and last days of the current year
const defaultStartDate = new Date(thisYear, 0, 1).getTime();
const defaultEndDate = new Date(thisYear, 11, 31).getTime();

export const ReadProductsReportFilters = () =>
  MasterListFiltersForm({
    name: "read-products-report-filters",
    props: SharedFilterProps,
    data: () => ({
      year: thisYear.toString() as string,
      assignedUser: null as string | null,
      granularity: "month" as "week" | "month" | "quarter" | "year",
      compareTo: null as string | null,
      created: [defaultStartDate, defaultEndDate] as [number, number] | null,
    }),
  })({
    basicComponents: () => ({
      year: [
        FormSelectField({
          props: {
            label: "Year",
            options: yearOptions,
            size: "is-small",
            reference: "year",
            showClearButton: false,
          },
          models: {
            value: {
              get: (data) => data.year,
              set: (value, data, controls) => {
                data.year = value;
                controls.incrementFormRenderKey();
              },
            },
          },
        }),
        CreatedAtField({
          props: {
            vModel: "created",
            label: "Date Range",
            vIf: (data) => data.year === "custom",
            class: "mt-3",
          },
          simplifyData: true,
        }),
      ],
      granularity: [
        FormSelectField({
          props: {
            label: "Granularity",
            options: granularityOptions,
            size: "is-small",
            reference: "granularity",
            showClearButton: false,
          },
          models: {
            value: "granularity",
          },
        }),
      ],
      compareTo: [
        FormSelectField({
          props: {
            label: "Compare To",
            options: yearOptions,
            size: "is-small",
            placeholder: "No Comparison",
            reference: "compareTo",
          },
          models: {
            value: "compareTo",
          },
        }),
      ],
      assignedUser: [
        UserField({
          props: {
            label: "Assigned to",
            vModel: "assignedUser",
            isMultiSelect: true,
          },
          query: {
            roles: {
              role: In(["staff", "admin"]),
            },
            displayUserInPortal: Equal(true),
            isEstatePlanner: Equal(true),
          },
          simplifyData: true,
        }),
      ],
    }),
  });
