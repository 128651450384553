<template>
  <DataListWidget v-if="cpdSession" title="About" :details="details">
    <template #below>
      <p>
        <a :href="url" target="_blank" class="btn btn-primary">
          View CPD Sign Up Form
        </a>
      </p>
    </template>
  </DataListWidget>
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { CpdDetailsMixin } from "../../mixin/cpd-details.mixin";

export default defineComponent({
  components: {
    DataListWidget,
  },
  mixins: [CpdDetailsMixin],
  computed: {
    url(): string {
      if (!this.cpdSession) {
        return "";
      }

      if (!this.cpdSession.isPrivate) {
        return `https://sign.kinherit.co.uk/cpd/${this.cpdSession.type.value}`;
      }

      return `https://sign.kinherit.co.uk/cpd-private/${this.cpdSession.type.value}/${this.cpdSession.id}`;
    },
    details(): Array<DataListWidgetDetail> {
      if (!this.cpdSession) return [];
      return [
        {
          label: "Title",
          value: this.cpdSession.title,
        },
        {
          label: "Date",
          value: this.cpdSession.date.formatDateTime,
        },
        {
          label: "Duration",
          value: `${this.cpdSession.duration.toMinutes()} minutes`,
        },
        {
          label: "Private",
          value: this.cpdSession.isPrivate ? "Yes" : "No",
        },
      ];
    },
  },
});
</script>
