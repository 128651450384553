import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderStore } from "@/module/kinvault.kintin/service/will-builder.store";
import {
  Address,
  Beneficiary,
  Gift,
  Option,
  Person,
} from "@kinherit/sdk/index";
import { DateTime } from "@kinherit/ts-common/index";
import { CountryCode, WorldInfo } from "@kinherit/ts-common/service/world-info";

export type WillBuilderHelpersType = {
  heSheThey: (gender: Option | Person | null) => string;
  hisHersTheir: (gender: Option | Person | null) => string;
  formatRelationship: (person: Person, relatedPerson?: Person | null) => string;
  formatBeneficiary: (bene?: null | Gift | Beneficiary) => string;
  formatRecipient: (gift: null | Gift, includeAddress?: boolean) => string;
  formatSpecificGift: (gift: null | Gift) => string;
  formatPredeceased: (bene: Gift | Beneficiary | null) => string;
  formatAddress(address?: null | Address): string;
  formatOfficer: (person: null | Person, includeAddress?: boolean) => string;
  beneficiaryTheirConcern: (gift?: Gift | Beneficiary) => string;
  calculateBeneficiaryShare: (
    data: Beneficiary[],
    person: Beneficiary,
    isAbsolute?: boolean,
  ) => string;
  mapSubClause: (c: number) => "a" | "b" | "c" | string;
  textContainsPlaceholders: (text: string) => boolean;
  getSchedulePosition: (value: WillBuilderSections) => null | string;
  getSectionPosition: (value: WillBuilderSections) => number;
  getTrustsClauseRange: () => string;
  getRESTrustsClauseRange: () => string;
  getSchedules: () => any;
  optionIsEnabled: (
    option:
      | "businessclause"
      | "businesstrust"
      | "estateprotectiontrust"
      | "iiptrust",
  ) => boolean;
  var(text: string | number | null | undefined): string;
  formatDate(date: DateTime | null): string;
};

export class WillBuilderHelpers extends WillBuilderStore {
  static helpers: WillBuilderHelpersType = {
    heSheThey: (_gender: Option | Person | null): string => {
      const gender = !_gender
        ? _gender
        : "profile" in _gender
          ? _gender.gender
          : _gender;
      switch (gender?.value) {
        case "Male":
          return "he";
        case "Female":
          return "she";
        default:
          return "they";
      }
    },
    hisHersTheir: (_gender: Option | Person | null): string => {
      const gender = !_gender
        ? _gender
        : "profile" in _gender
          ? _gender.gender
          : _gender;
      switch (gender?.value) {
        case "Male":
          return "his";
        case "Female":
          return "her";
        default:
          return "their";
      }
    },
    formatDate: (date: DateTime | null): string => {
      if (!date) {
        return "[DATE NOT SET]";
      }
      return date.format("DD MMMM YYYY");
    },
    formatRelationship: (
      person: Person,
      relatedPerson?: Person | null,
    ): string => {
      if (!relatedPerson) {
        return `[RELATIONSHIP NOT FOUND]`;
      }

      const personType = person.primaryOwnerOfKintin
        ? "Primary"
        : person.secondaryOwnerOfKintin
          ? "Secondary"
          : null;

      if (!personType) {
        return `[INVALID RELATIONSHIP]`;
      }

      const relationship = relatedPerson[`relationTo${personType}Person`];

      return relationship?.text ?? "[RELATIONSHIP NOT SET]";
    },
    formatBeneficiary: (bene?: null | Beneficiary | Gift): string => {
      if (!bene) {
        return "[GIFT NOT SET]";
      }

      const person = bene instanceof Gift ? bene.forPerson : bene.person;

      if (!bene.classGiftType && person?.type !== "charity") {
        return this.helpers.formatOfficer(person, false);
      }

      if (!bene.classGiftType && person?.type === "charity") {
        let response = ` ${person.profile.organisationName?.toUpperCase()}`;

        if (person.profile.organisationNumber) {
          response += ` Registered Charity Number: ${person.profile.organisationNumber}`;
        }

        return response;
      }

      if (bene.classGiftType) {
        return bene.classGiftType.text.toLowerCase();
      }

      return "[GIFT INCORRECTLY CONFIGURED]";
    },
    formatRecipient: (gift: null | Gift, includeAddress = true): string => {
      if (null === gift) {
        return "[GIFT NOT SET]";
      }

      if (gift.classGiftType) {
        return gift.classGiftType.text.toLowerCase();
      }

      if (gift.forPerson) {
        return this.helpers.formatOfficer(gift.forPerson, includeAddress);
      }

      return "[GIFT INCORRECTLY CONFIGURED]";
    },
    formatSpecificGift: (gift: null | Gift): string => {
      if (!this.options.person) {
        throw new Error(`Person not set`);
      }

      if (null === gift) {
        return "[MISSING GIFT?]";
      }

      let response = this.helpers.formatRecipient(gift);
      response += ` ` + gift.notes;

      if (gift.ifPredeceased) {
        if (gift.onSecondDeath) {
          const relationship = this.helpers.formatRelationship(
            this.options.person,
            gift.forPerson,
          );

          response += ` only if my ${relationship} has predeceased me`;
        }

        if (gift.ifPredeceased.value === "theirconcern") {
          response +=
            " and in substitution to the issue and remoter issue of " +
            this.helpers.formatRecipient(gift, false);
        }
      }

      return response;
    },
    formatPredeceased: (bene: Beneficiary | Gift | null): string => {
      const isBeneficiary = bene instanceof Beneficiary;
      if (!this.options.person) {
        throw new Error(`Person not set`);
      }

      if (bene === null) {
        return "[PREDECEASED NOT SET]";
      }
      let rv = "";
      if (bene.ifPredeceased?.value === "theirconcern") {
        rv +=
          " Provided that if " +
          this.helpers.formatBeneficiary(bene) +
          " shall die without having attained a vested interest leaving issue who survive me then such issue who shall attain the age of majority shall take by substitution such failed share and if there shall be more than one of such issue they shall take in equal shares per stirpes but so that no issue shall take whose parent is alive and so capable of taking";
      }
      if (bene.ifPredeceased?.value === "other") {
        rv +=
          " Provided that if my said " +
          this.helpers.formatRelationship(
            this.options.person,
            isBeneficiary ? bene.person : bene.forPerson,
          ) +
          " shall die I wish their share to be distributed ";
        if (bene.reserveclassGiftType) {
          rv += ` between ${bene.reserveclassGiftType.text.toLowerCase()}`;
        }
        if (!bene.reserveclassGiftType && bene.reservePerson) {
          rv += " to " + this.helpers.formatOfficer(bene.reservePerson, false);
        }
      }
      return rv;
    },

    formatAddress(address?: null | Address): string {
      if (!address) {
        return "[ADDRESS NOT SET 1]";
      }

      // UK postcode formatting function
      function formatUKPostcode(postcode: string): string {
        postcode = postcode.toUpperCase(); // Postcodes are typically uppercase
        const postcodeRegex = /^([A-Z]{1,2}\d[A-Z\d]?)(\d[A-Z]{2})$/;
        const match = postcode.match(postcodeRegex);

        if (match) {
          return `${match[1]} ${match[2]}`; // Insert a space between the two postcode parts
        }

        return postcode; // Return the original postcode if it doesn't match the UK format
      }

      // Clean up country name by removing anything in brackets
      function cleanCountryName(countryCode?: string): string | null {
        if (!countryCode) return null;

        try {
          let country = WorldInfo.getCountry(countryCode as CountryCode)?.name;

          if (country) {
            // Strip out anything in brackets from the country name
            country = country.replace(/\(.*?\)/g, "").trim();
          }

          return country || null;
        } catch (error) {
          console.error("Error fetching country name:", error);
          return null;
        }
      }

      // Format address components, excluding any undefined or null values
      let formattedAddress = [
        address.line1,
        address.line2,
        address.city,
        address.state,
        address.postcode,
      ]
        .filter(Boolean) // Filter out falsy values
        .join(" "); // Join with spaces

      // Handle cases based on the country
      if (address.country === "GB" && address.postcode) {
        // If the country is GB, format the postcode according to UK standards
        const formattedPostcode = formatUKPostcode(address.postcode);
        formattedAddress = formattedAddress.replace(
          address.postcode,
          formattedPostcode,
        );
      }

      // Append country name if not GB
      if (address.country && address.country !== "GB") {
        const countryName = cleanCountryName(address.country);
        if (countryName) {
          formattedAddress = `${formattedAddress} ${countryName}`;
        }
      }

      return formattedAddress.trim() || "[ADDRESS NOT SET 2]";
    },
    formatOfficer: (person: null | Person, includeAddress = true): string => {
      if (null === person) {
        return "[OFFICER NOT SET]";
      }

      if (!this.data.person) {
        throw new Error("Data not loaded");
      }

      const relationship = this.helpers.formatRelationship(
        this.data.person,
        person,
      );

      const address = this.helpers.formatAddress(
        person.profile.addresses.findBy("primaryResidential", true),
      );

      let response = ``;

      if (person.type === "person") {
        response += ` my ${relationship} ${person.profile.fullName?.toUpperCase()}`;
      }

      if (person.type === "charity") {
        response += person.profile.organisationName?.toUpperCase();

        if (person.profile.organisationNumber) {
          response += ` Registered Charity Number: ${person.profile.organisationNumber}`;
        }
      }

      if (person.type === "company") {
        response += person.profile.organisationName?.toUpperCase();
      }

      if (includeAddress) {
        response += ` of ${address}`;
      }

      return response;
    },
    calculateBeneficiaryShare: (
      data: Beneficiary[],
      person: Beneficiary,
      isAbsolute = true,
    ): string => {
      if (data.length === 1 && isAbsolute) return "Upon trust absolutely for ";
      if (data.length === 1) return "Upon trust for ";
      if (person.sharePercentage) return person.sharePercentage + "% to ";
      if (person.shareFraction) return person.shareFraction + " to ";
      if (data.length === 3) return "1/3 for ";
      return 100 / data.length + "% to ";
    },
    beneficiaryTheirConcern: (gift?: Gift | Beneficiary): string => {
      let personOrClass = null;

      if (gift instanceof Beneficiary) {
        personOrClass = gift.person;
      }

      if (gift instanceof Gift) {
        personOrClass = gift.forPerson;
      }

      if (gift?.ifPredeceased?.value === "theirconcern") {
        const pronoun = this.helpers.hisHersTheir(personOrClass);

        return ` but if ${this.helpers.heSheThey(
          personOrClass,
        )} shall die when assets remain in the trust leaving issue of ${pronoun} own then I would prefer that ${pronoun} share is utilised equally between ${pronoun} issue and thereafter similarly remoter issue in that family`;
      }

      return ``;
    },
    mapSubClause: (c: number): "a" | "b" | "c" | string => {
      switch (c) {
        case 0:
          return "a";
        case 1:
          return "b";
        case 2:
          return "c";
        default:
          return `[UNKNOWN SUB CLAUSE ${c}]`;
      }
    },
    textContainsPlaceholders: (text: string): boolean => {
      return text.includes("[") || text.includes("]");
    },
    getSchedulePosition: (value: WillBuilderSections): null | string => {
      const list = this.scheduleSections ?? [];

      let result: number | null = null;

      switch (value) {
        case WillBuilderSections.ScheduleBusiness:
          result = list.indexOf("businesstrust") + 1;
          break;
        case WillBuilderSections.ScheduleRES:
          result = list.indexOf("estateprotectiontrust") + 1;
          break;
        case WillBuilderSections.ScheduleNRB:
          result = list.indexOf("estateprotectiontrust") + 1;
          break;
        case WillBuilderSections.ScheduleIIP:
          result = list.indexOf("iiptrust") + 1;
          break;
      }

      return result === null ? `[UNKNOWN SCHEDULE ${value}]` : `${result}`;
    },
    getTrustsClauseRange: (): string => {
      const start =
        this.helpers.getSectionPosition(WillBuilderSections.Trusts) + 1;
      const end =
        this.helpers.getSectionPosition(WillBuilderSections.StepPowers) - 1;
      return start + " - " + end;
    },
    getSectionPosition: (value: WillBuilderSections): number => {
      const orderedSections = this.sections.ordered;
      const enabledSections = this.sections.enabled;

      if (null === enabledSections) {
        throw new Error("Sections not set");
      }

      return (
        orderedSections
          .filter((section) => enabledSections[section])
          .indexOf(value) + 1
      );
    },
    getRESTrustsClauseRange: (): string => {
      const start =
        this.helpers.getSectionPosition(WillBuilderSections.ProvisionsRES) + 1;
      const end =
        this.helpers.getSectionPosition(WillBuilderSections.StepPowers) - 1;
      return start + " - " + end;
    },
    getSchedules: (): any => {
      return this.optionalSections.filter(
        (section) =>
          section === "businesstrust" ||
          section === "estateprotectiontrust" ||
          section === "iiptrust",
      );
    },
    optionIsEnabled: (
      option:
        | "businessclause"
        | "businesstrust"
        | "estateprotectiontrust"
        | "iiptrust",
    ): boolean => {
      return this.optionalSections.includes(option);
    },
    var: (text: string | number | null | undefined): string => {
      return `<span class="is-will-builder-inject">${text}</span>`;
    },
  };
}
