<template>
  <h1 v-if="data" class="double-box mb-3" style="text-align: center">
    THE LAST WILL &amp; TESTAMENT
    <br /><br />
    of
    <br /><br />
    <span
      v-if="data.person"
      v-html="helpers.var(data.person.profile.fullName)"
    />
    <br /><br />
    <small>(REFERENCE / <span v-html="helpers.var(data.ktref)" />)</small>
  </h1>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
