import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, Equal, Like } from "@kinherit/orm";
import {
  DefaultBrandedKinvaultReferralCode,
  IDefaultBrandedKinvaultReferralCode,
} from "@kinherit/sdk";

interface ReadDefaultBrandedKinvaultReferralCodeMessage {
  name?: string;
  code?: string;
  brandedKinvault: string;
  created?: null | [number, number];
  pagination?: {
    currentPage: number;
    perPage: number;
  };
  sort: {
    by: keyof IDefaultBrandedKinvaultReferralCode;
    direction: "asc" | "desc";
  };
}

interface ReadDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCodes: DefaultBrandedKinvaultReferralCode[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const GetDefaultBrandedKinvaultReferralCodeHandler = async (
  message: ReadDefaultBrandedKinvaultReferralCodeMessage,
): Promise<ReadDefaultBrandedKinvaultReferralCodeResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/referral/default-branded-kinvault-referral-code",
  )
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(DefaultBrandedKinvaultReferralCode).where({
    brandedKinvault: Equal(message.brandedKinvault),
    name: Like(message.name),
    code: Like(message.code),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  const defaultBrandedKinvaultReferralCodes =
    DefaultBrandedKinvaultReferralCode.$inflate(
      response.defaultBrandedKinvaultReferralCode,
      undefined,
      response.$rootIds,
    );

  return {
    defaultBrandedKinvaultReferralCodes,
    pagination: getPagination(response),
  };
};
