<template>
  <p v-if="data">
    <strong>Directions to Trustees regarding the age of Beneficiaries</strong
    ><br />
    I would ask my Trustees to only consider utilising funds for the
    Beneficiaries named in this trust once they have attained the age of
    <span v-html="helpers.var(data.beneficiaryAge)" /> years.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
