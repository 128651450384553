import { Adviser, Api, Kintin } from "@kinherit/sdk";

interface DeleteAdviserMessage {
  kintin: string | Kintin;
  adviser: Adviser;
}

interface DeleteAdviserResponse {
  [key: string]: never;
}

export const DeleteAdviserHandler = async (
  message: DeleteAdviserMessage,
): Promise<DeleteAdviserResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-adviser/{kintin}/person/adviser/{adviser}",
    {
      kintin: message.kintin,
      adviser: message.adviser.$data.id,
    },
  )
    .method("delete")
    .result();

  message.adviser.$delete();

  return {};
};
