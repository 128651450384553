import { IntroducerCompanyField } from "@/config/form.config";
import { FormElement } from "@kinherit/framework/component.form/element";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const TransferIntroducerContactForm = () => {
  return defineForm({
    name: "transfer-introducer-contact-form",
    data: () => {
      return {
        company: null,
      };
    },
    formAreas: (data) => [
      defineFormArea({
        name: "transfer-introducer-contact-form-area",
        data,
        template: GridLayout([["intro"], ["company"]]),
        components: () => ({
          intro: [
            FormElement({
              props: {
                html: `
                <p>
                  <strong class='is-block'>Transfer Contact</strong>
                  <ul>
                    <li>The existing company association will be replaced</li>
                    <li>CPD Session history, notes, clients stay with the contact</li>
                    <li>Consider what will happen to any referrals / clients / fees associated with this contact</li>
                    <li>Any Partner Portal permissions will be removed for this contact, however, if they've set a password already, that will remain the same. It may be their email address changes, in which case this will need to be updated manually by the DevTeam.</li>
                  </ul>
                </p>`,
              },
            }),
          ],
          company: [
            IntroducerCompanyField({
              props: {
                label: "Select Company to transfer contact to",
                message:
                  "Whilst we can technically assign this contact to multiple companies, for now, just select one.",
                vModel: "company",
                isMultiSelect: false,
              },
            }),
          ],
        }),
      }),
    ],
  });
};
