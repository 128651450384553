import { RouteInterface } from "@kinherit/framework/core/route-config";
import { EmailTemplateMasterListBreadcrumb } from "../breadcrumbs";
import { Routes as EmailTemplateDetailsRoutes } from "./details";

export const EmailTemplateMasterListRoute = "EmailTemplateMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: EmailTemplateMasterListRoute,
    path: "/admin/email-templates",
    component: () =>
      import(
        /* webpackChunkName: "EmailTemplateMasterList" */ "./EmailTemplateMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [EmailTemplateMasterListBreadcrumb],
    },
  },
  ...EmailTemplateDetailsRoutes,
];
