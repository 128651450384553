import { Api } from "@/service/api.service";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface UploadKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
  file: File;
}

interface UploadKintinStorageRequestResponse {
  subscription: Subscription;
  physicalStorageFiles: Array<PhysicalStorageFile>;
}

export const UploadKintinStorageRequestHandler = async (
  message: UploadKintinStorageRequestMessage,
): Promise<UploadKintinStorageRequestResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{storageRequest}",
    {
      kintin: message.kintin,
      subscription: message.subscription,
      storageRequest: message.storageRequest,
    },
  )
    .method("put")
    .files({
      uploadedFile: message.file,
    })

    .result();

  const subscription = Subscription.$inflate(response.subscription).first();

  if (!subscription) {
    throw new Error("Failed to create storage request");
  }

  return {
    subscription,
    physicalStorageFiles: PhysicalStorageFile.$inflate(
      response.physicalStorageFile,
    ),
  };
};
