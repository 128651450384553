import { Api } from "@/service/api.service";

interface ComputeDownloadTimeSinceLeadReportResponse {
  [key: string]: never;
}

// export class ComputeDownloadTimeSinceLeadReportHandler extends ActionBase {
//   constructor() {
//     super();
//   }

//   public async execute(): Promise<ComputeDownloadTimeSinceLeadReportResponse> {
export const ComputeDownloadTimeSinceLeadReportHandler =
  async (): Promise<ComputeDownloadTimeSinceLeadReportResponse> => {
    await Api.resource(
      "portal",
      "/v2/portal/report/download/time-since-lead-report",
    )
      .method("get")
      .download({
        encoding: "text/csv",
        fileName: `time_since_lead_report.csv`,
      });

    return {};
  };
