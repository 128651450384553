import { Api } from "@/service/api.service";
import {
  BrandedKinvault,
  ThemeCssVar,
  ThemeKinvault,
  ThemeKinvaultFooter,
  ThemeKinvaultHeader,
  ThemeKinvaultLogin,
} from "@kinherit/sdk";

export interface RecordThemeKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
  themeKinvault: ThemeKinvault | string;
}

interface RecordThemeKinvaultResponse {
  themeKinvault: ThemeKinvault;
  themeKinvaultLogin: ThemeKinvaultLogin;
  themeKinvaultFooter: ThemeKinvaultFooter;
  themeKinvaultHeader: ThemeKinvaultHeader;
  themeCssVars: Array<ThemeCssVar>;
}

export const RecordThemeKinvaultHandler = async (
  message: RecordThemeKinvaultMessage,
): Promise<RecordThemeKinvaultResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-theme/{brandedKinvault}/theme/{themeKinvault}",
    message,
  )
    .method("get")

    .result();

  const themeKinvault = ThemeKinvault.$inflate(response.themeKinvault).first(),
    themeKinvaultFooter = ThemeKinvaultFooter.$inflate(
      response.themeKinvaultFooter,
    ).first(),
    themeKinvaultHeader = ThemeKinvaultHeader.$inflate(
      response.themeKinvaultHeader,
    ).first(),
    themeKinvaultLogin = ThemeKinvaultLogin.$inflate(
      response.themeKinvaultLogin,
    ).first(),
    themeCssVars = ThemeCssVar.$inflate(response.themeCssVar);

  if (
    !themeKinvault ||
    !themeKinvaultFooter ||
    !themeKinvaultHeader ||
    !themeKinvaultLogin
  ) {
    throw new Error("Theme not found");
  }

  return {
    themeKinvault,
    themeKinvaultLogin,
    themeKinvaultFooter,
    themeKinvaultHeader,
    themeCssVars,
  };
};
