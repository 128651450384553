import { FormFileField } from "@kinherit/framework/component.input/file-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const CreateKnowledgeBaseFileLogForm = () =>
  defineForm({
    name: "create-knowledge-base-file-log-form",
    data: () => ({
      files: [] as File[],
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "file",
        data,
        components: () => ({
          default: [
            FormFileField({
              props: {
                label: "File",
                message: "Please select a file to upload",
                validators: ["required"],
                isSingle: true,
              },
              models: {
                value: "files",
              },
            }),
          ],
        }),
      }),
    ],
  });
