<template>
  <div class="introducercompany-accounting-page">
    <Tabs :is-fullwidth="false" :config="tabs" />
    <RouterChildView
      @create-billing-contact="$emit('create-billing-contact')"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsAccountingIndexWrapper",
  "route": "IntroducerCompanyDetailsAccountingIndex",
  "selector": ".introducercompany-accounting-page",
  "imports": {
    "IntroducerCompanyDetailsAccountingWrapper": "@/module/introducer.company/page/details/accounting/IntroducerCompanyDetails.Accounting.test",
    "IntroducerCompanyDetailsBillingWrapper": "@/module/introducer.company/page/details/accounting/IntroducerCompanyDetails.Billing.test",
    "IntroducerCompanyDetailsFeesWrapper": "@/module/introducer.company/page/details/accounting/IntroducerCompanyDetails.Fees.test"
  },
  "methods": {
    "tabs": {
      "type": "tabs",
      "selector": "",
      "items": ["Xero", "Billing Contacts", "Fees"]
    },
    "accounting": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsAccountingWrapper"
    },
    "billing": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsBillingWrapper"
    },
    "fees": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsFeesWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { StyleService } from "@/service/style.service";
import Tabs from "@kinherit/framework/component.display/tabs";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import {
  IntroducerCompanyDetailsAccountingIndexRoute,
  IntroducerCompanyDetailsAccountingRoute,
  IntroducerCompanyDetailsBillingRoute,
  IntroducerCompanyDetailsFeesRoute,
} from ".";

export default defineComponent({
  name: IntroducerCompanyDetailsAccountingIndexRoute,
  mixins: [StyleService.mixin],
  components: { Tabs, RouterChildView },
  data: () => ({
    tabs: [
      {
        label: `Xero`,
        icon: StyleService.icon.accounting.icon,
        route: { name: IntroducerCompanyDetailsAccountingRoute },
      },
      {
        label: `Billing Contacts`,
        icon: StyleService.icon.person.icon,
        route: { name: IntroducerCompanyDetailsBillingRoute },
      },
      {
        label: `Fees`,
        icon: StyleService.icon.accounting.icon,
        route: { name: IntroducerCompanyDetailsFeesRoute },
      },
    ],
  }),
});
</script>
