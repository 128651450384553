import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadSubscriptionReferralCodeForm = () =>
  MasterListFiltersForm({
    name: "read-subscription-referral-code",
    data: () => ({
      name: "",
      code: "",
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: `Name`,
          },
          models: {
            value: `name`,
          },
        }),
      ],
      code: [
        FormTextField({
          props: {
            label: `Code`,
          },
          models: {
            value: `code`,
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
