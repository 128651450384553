import { KnowledgeBaseCategoryMasterListBreadCrumb } from "@/module/knowledge-base/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KnowledgeBaseCategory, KnowledgeBaseDocument } from "@kinherit/sdk";
import {
  KnowledgeBaseDocumentDetailsContentRoute,
  Routes as KnowledgeBaseDocumentRoutes,
} from "./document/index";

/** Documents */
export const KnowledgeBaseDocumentDetailsRoute = "KnowledgeBaseDocumentDetails";
export const KnowledgeBaseDocumentDetailsURI =
  "/knowledge-base/category/:category/document/:document";
export type KnowledgeBaseDocumentDetailsParams = RouteParamObject<
  typeof KnowledgeBaseDocumentDetailsURI
>;

/** Categories */
export const KnowledgeBaseCategoryMasterListRoute =
  "KnowledgeBaseCategoryMasterList";

export const KnowledgeBaseCategoryDetailsRoute = "KnowledgeBaseCategoryDetails";
export const KnowledgeBaseCategoryDetailsURI =
  "/knowledge-base/category/:category";
export type KnowledgeBaseCategoryDetailsParams = RouteParamObject<
  typeof KnowledgeBaseCategoryDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KnowledgeBaseCategoryMasterListRoute,
    path: "/knowledge-base",
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseMasterList" */ "./KnowledgeBaseCategoryMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KnowledgeBaseCategoryMasterListBreadCrumb],
    },
  },
  {
    name: KnowledgeBaseCategoryDetailsRoute,
    path: KnowledgeBaseCategoryDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseDetails" */ "./KnowledgeBaseCategoryDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        {
          text: KnowledgeBaseCategory.$findOne(params.category)?.title ?? "",
          route: { name: KnowledgeBaseCategoryDetailsRoute, params },
        },
      ],
    },
  },
  {
    name: KnowledgeBaseDocumentDetailsRoute,
    path: KnowledgeBaseDocumentDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "KnowledgebaseDetails" */ "./KnowledgeBaseDocumentDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => [
        KnowledgeBaseCategoryMasterListBreadCrumb,
        {
          text: KnowledgeBaseCategory.$findOne(params.category)?.title ?? "",
          route: {
            name: KnowledgeBaseCategoryDetailsRoute,
            params: { category: params.category },
          },
        },
        {
          text: KnowledgeBaseDocument.$findOne(params.document)?.title ?? "",
          route: { name: KnowledgeBaseDocumentDetailsRoute, params },
        },
      ],
    },
    children: KnowledgeBaseDocumentRoutes,
    redirect: {
      name: KnowledgeBaseDocumentDetailsContentRoute,
    },
  },
];
