import { Api } from "@/service/api.service";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk/index";

interface RecordDefaultOrganisatioMessage {
  defaultBrandedKinvaultReferralCode:
    | DefaultBrandedKinvaultReferralCode
    | string;
}

interface RecordDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

export const RecordDefaultBrandedKinvaultReferralCodeHandler = async (
  message: RecordDefaultOrganisatioMessage,
): Promise<RecordDefaultBrandedKinvaultReferralCodeResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/referral/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
    {
      defaultBrandedKinvaultReferralCode:
        message.defaultBrandedKinvaultReferralCode,
    },
  )
    .method("get")

    .result();

  const defaultBrandedKinvaultReferralCode =
    DefaultBrandedKinvaultReferralCode.$inflate(
      repsonse.defaultBrandedKinvaultReferralCode,
      undefined,
      repsonse.$rootIds,
    ).first();

  if (!defaultBrandedKinvaultReferralCode) {
    throw new Error("Failed to record default referral code");
  }

  return {
    defaultBrandedKinvaultReferralCode,
  };
};
