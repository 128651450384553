import { Api } from "@/service/api.service";
import { PhysicalStorageFileLog } from "@kinherit/sdk";

export interface UpdateStorageRequestMessage {
  physicalStorageFileLog: PhysicalStorageFileLog;
}

interface UpdateStorageRequestResponse {
  physicalStorageFileLog: PhysicalStorageFileLog;
}

export const UpdateStorageRequestHandler = async (
  message: UpdateStorageRequestMessage,
): Promise<UpdateStorageRequestResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/storage-request/{physicalStorageFileLog}",
    {
      physicalStorageFileLog: message.physicalStorageFileLog,
    },
  )
    .method("patch")
    .body({
      completedAt: message.physicalStorageFileLog.$data.completedAt,
      confirmedBy: message.physicalStorageFileLog.$data.confirmedBy,
      createdBy: message.physicalStorageFileLog.$data.createdBy,
      file: message.physicalStorageFileLog.$data.file,
      logAction: message.physicalStorageFileLog.$data.logAction,
      notes: message.physicalStorageFileLog.$data.notes,
      requestedAt: message.physicalStorageFileLog.$data.requestedAt,
    })

    .result();

  const physicalStorageFileLog = PhysicalStorageFileLog.$inflate(
    response.physicalStorageFileLog,
  ).first();

  if (!physicalStorageFileLog) {
    throw new Error("Failed to update storage request");
  }

  return {
    physicalStorageFileLog,
  };
};
