import { RouteInterface } from "@kinherit/framework/core/route-config";
import { IntroducerOutsourceMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as IntroducerOutsourceDetailsRoutes } from "./details";

export const IntroducerOutsourceMasterListRoute =
  "IntroducerOutsourceMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerOutsourceMasterListRoute,
    path: "/introducer/outsources",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerOutsourceMasterList" */ "./IntroducerOutsourceMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerOutsourceMasterListBreadCrumb],
    },
  },
  ...IntroducerOutsourceDetailsRoutes,
];
