<template>
  <div class="adviser-list">
    <AdviserSummary
      v-for="role in advisers"
      :key="`adviser-${role.id}`"
      :kintin="kintin"
      :role="role"
      @edit="edit(role)"
      @delete="remove(role)"
    />
    <Card v-if="!advisers.length" class="is-normal">
      <p>No entries listed</p>
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AdviserListWrapper",
  "imports": {
    "AdviserSummaryWrapper": "@/module/kinvault.kintin/component/summary-cards/AdviserSummary.test"
  },
  "methods": {
    "advisers": {
      "type": "to-many",
      "selector": ".adviser-summary",
      "wrapper": "AdviserSummaryWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import AdviserSummary from "@/module/kinvault.kintin/component/summary-cards/AdviserSummary.vue";
import { UpdateAdviserForm } from "@/module/kinvault.kintin/form/update-adviser.form";
import Card from "@kinherit/framework/component.layout/card";
import { Adviser, Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdviserList",
  components: { AdviserSummary, Card },
  emits: ["reload"],
  props: {
    kintin: {
      type: Object as () => Kintin,
      required: true,
    },
    advisers: {
      type: Array as () => Adviser[],
      required: true,
    },
  },
  methods: {
    async edit(adviser: Adviser): Promise<void> {
      try {
        await UpdateAdviserForm({
          data: adviser,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Edit Adviser",
          },
        });
      } catch {
        adviser.$restore();
        return;
      }

      const kintin = this.kintin;

      // await window.Kernel.ActionBus.execute("kinvault/kintin/adviser/update", {
      //   adviser,
      //   kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.adviser.update({
        adviser,
        kintin,
      });

      this.$emit("reload");
    },
    async remove(adviser: Adviser): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Adviser",
          message: `Are you sure you want to remove this adviser?`,
        },
      });

      // await window.Kernel.ActionBus.execute("kinvault/kintin/adviser/delete", {
      //   adviser,
      //   kintin: this.kintin,
      // });
      await window.Kernel.ActionBus.kinvaultKintin.adviser.delete({
        adviser,
        kintin: this.kintin,
      });

      this.$emit("reload");
    },
  },
});
</script>
