import { RoleField } from "@/config/form.config";
import { FormEmailField } from "@kinherit/framework/component.input/email-field";
import { FormPhoneNumberField } from "@kinherit/framework/component.input/phone-number-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const CreateUserForm = () =>
  defineForm({
    name: "create-user-form",
    data: () => ({
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      roles: [] as Array<string>,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-user-form-area",
        data,
        template: GridLayout([
          ["firstName", "lastName"],
          ["email", "phoneNumber"],
          ["roles"],
        ]),
        components: () => ({
          firstName: [
            FormTextField({
              props: {
                label: "First Name",
                validators: ["required"],
              },
              models: {
                value: "firstName",
              },
            }),
          ],
          lastName: [
            FormTextField({
              props: {
                label: "Last Name",
                validators: ["required"],
              },
              models: {
                value: "lastName",
              },
            }),
          ],
          email: [
            FormEmailField({
              props: {
                label: "Email",
                validators: ["required"],
              },
              models: {
                value: "emailAddress",
              },
            }),
          ],
          phoneNumber: [
            FormPhoneNumberField({
              props: {
                label: "Phone number",
              },
              models: {
                value: "phoneNumber",
              },
            }),
          ],
          roles: [
            RoleField({
              props: {
                label: "Role",
                validators: ["required"],
                isMultiSelect: true,
                vModel: "roles",
              },
              simplifyData: true,
            }),
          ],
        }),
      }),
    ],
  });
