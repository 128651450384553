import { PersonField, SharedFormProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { OpenDangerDialog } from "@kinherit/framework/global/dialog";
import { In } from "@kinherit/orm/index";
import { Kintin, Person } from "@kinherit/sdk/index";

export const CreateLetterOfAuthorityForm = ({ kintin }: { kintin: Kintin }) =>
  defineForm({
    name: "create-letter-of-authority-form",
    props: SharedFormProps,
    validate: (data) => {
      if (!data.companyName && !data.address) {
        OpenDangerDialog({
          dialog: {
            title: "Company Name or Address is required",
            message: "Please provide either a company name or an address",
          },
        });
        return false;
      }

      return true;
    },
    data: () => ({
      person: null as null | Person,
      relationship: null as null | string,
      name: null as null | string,
      companyName: null as null | string,
      address: null as null | string,
    }),
    formAreas: (data) => [
      defineFormArea({
        name: "create-letter-of-authority-form-area",
        data,
        template: GridLayout([
          ["person"],
          ["relationship"],
          ["name"],
          ["companyName"],
          ["address"],
        ]),
        components: () => ({
          person: [
            PersonField({
              props: {
                label: "Person",
                validators: ["required"],
                vModel: "person",
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
          relationship: [
            FormTextField({
              props: {
                label: "Relationship",
                validators: ["required"],
              },
              models: {
                value: "relationship",
              },
            }),
          ],
          name: [
            FormTextField({
              props: {
                label: "Name",
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
          companyName: [
            FormTextField({
              props: {
                label: "Company Name",
              },
              models: {
                value: "companyName",
              },
            }),
          ],
          address: [
            FormTextField({
              props: {
                label: "Address",
              },
              models: {
                value: "address",
              },
            }),
          ],
        }),
      }),
    ],
  });
