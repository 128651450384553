import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsNotesBreadCrumb } from "../../../breadcrumbs";

/** Notes */
export const LeadDetailsNotesRoute = "LeadDetailsNotes";
const LeadDetailsNotesURI = "/lead/:lead/notes";
export type LeadDetailsNotesParams = RouteParamObject<
  typeof LeadDetailsNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsNotesRoute,
    path: LeadDetailsNotesURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsNotes" */ "./LeadDetails.Notes.vue"
        ),
    },
    meta: {
      breadcrumbs: LeadDetailsNotesBreadCrumb,
    },
  },
];
