<template>
  <div class="introducer-company-details-summary">
    <DashboardStatisticWidgetWrapper
      title="Lifetime Stats"
      class="dashboard-statistic-widget-wrapper"
      v-if="!loading.stats"
    >
      <DashboardStatisticWidgetGroup v-bind="lifeTimeLeadStats" />
      <DashboardStatisticWidgetGroup v-bind="lifeTimeContactStats" />
      <DashboardStatisticWidgetGroup v-bind="lifeTimeContractStats" />
    </DashboardStatisticWidgetWrapper>

    <Card v-if="!loading.stats">
      <Table :columns="columns" is-striped :rows="rows" is-narrow></Table>
      <p>
        <small>
          Uses lead creation date for lead + conversion % and order creation
          date for sales &amp; AVG Value
        </small>
      </p>
      <Button size="is-small" @click="downloadReferralReport"
        >Download Referral Report</Button
      >
    </Card>

    <Card v-if="notes.length > 0" title="Pinned Notes" class="pinned-notes">
      <NoteSummary
        v-for="note in $data.notes"
        :key="`introducer-note-${note.id}`"
        :note="note"
        is-plain
      />
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsSummaryWrapper",
  "route": "IntroducerCompanyDetailsSummary",
  "selector": ".introducer-company-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import NoteSummary from "@/module/core/component/note/NoteSummary.vue";
import DashboardStatisticWidgetGroup from "@/shared/component/widget/DashboardStatisticWidgetGroup.vue";
import DashboardStatisticWidgetWrapper from "@/shared/component/widget/DashboardStatisticWidgetWrapper.vue";
import { TDashboardStatisticWidgetGroupProps } from "@/shared/component/widget/dashboard-statistic-widget";
import Table from "@kinherit/framework/component.display/table";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { Equal } from "@kinherit/orm/index";
import { Note } from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsSummaryRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsSummaryRoute,
  mixins: [IntroducerCompanyDetailsMixin],
  components: {
    DashboardStatisticWidgetGroup,
    DashboardStatisticWidgetWrapper,
    Card,
    NoteSummary,
    Table,
    Button,
  },
  data: () => ({
    lifeTimeLeadStats: {} as TDashboardStatisticWidgetGroupProps,
    lifeTimeContactStats: {} as TDashboardStatisticWidgetGroupProps,
    lifeTimeContractStats: {} as TDashboardStatisticWidgetGroupProps,
    last12MonthStats: {} as TDashboardStatisticWidgetGroupProps,
    stats2: Array<{ title: string; message: string }>(),
    loading: {
      stats: true,
      notes: true,
    },
    notes: Array<Note>(),
    columns: [
      {
        field: "name",
      },
      {
        title: "Total Leads",
        field: "totalLeads",
      },
      {
        title: "Conversion%",
        field: "convertedToCall1",
      },
      {
        title: "Total Sales",
        field: "totalSales",
      },
      {
        title: "Avg Value",
        field: "averagePriceOfSale",
      },
    ] satisfies TableColumn[],
    rows: Array<{
      name:
        | "Q1"
        | "Q2"
        | "Q3"
        | "Q4"
        | "QTD"
        | "Last 12 Months"
        | `Q1 (${string})`
        | `Q2 (${string})`
        | `Q3 (${string})`
        | `Q4 (${string})`
        | `QTD (${string})`
        | `Last 12 Months (${string})`;
      year: string;
      totalLeads: number | string;
      convertedToCall1: number | string;
      totalSales: number | string;
      averagePriceOfSale: number | string;
    }>(),
  }),
  mounted(): void {
    this.loadStats();
    this.loadNotes();
  },
  methods: {
    async loadStats(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      // const data = await window.Kernel.ActionBus.execute(
      //   "introducer/company/compute/stats",
      //   {
      //     introducerCompany: this.introducerCompany,
      //   },
      // );
      const data = await window.Kernel.ActionBus.introducerCompany.computeStats(
        {
          introducerCompany: this.introducerCompany,
        },
      );

      this.lifeTimeLeadStats = {
        title: "Leads",
        details: [
          {
            label: "Total Leads",
            value: data.totalLeads.toString(),
          },
          {
            label: "Life Time Value",
            value: new Currency({
              amount: data.lifeTimeValue,
            }).format,
            subValue:
              "Avg: " +
              new Currency({
                amount: data.lifeTimeAvgValue,
              }).format,
          },
          {
            label: "Last Lead",
            value: data.lastLead
              ? DateTime.fromString(data.lastLead)?.formatDate
              : "N/A",
            subValue: data.lastLead
              ? DateTime.fromString(data.lastLead).since({
                  datetime: new DateTime(),
                  format: "day",
                })
              : "N/A",
          },
        ],
      };

      this.lifeTimeContactStats = {
        title: "Contacts",
        details: [
          {
            label: "Total Contacts",
            value: data.totalContacts.toString(),
          },
        ],
      };

      this.lifeTimeContractStats = {
        title: "Contracts",
        details: [
          {
            label: "Total Contracts",
            value: data.totalContracts.toString(),
          },
        ],
      };

      this.rows = data.quarters.map((quarter) => ({
        name: ["Q1", "Q2", "Q3", "Q4"].includes(quarter.name)
          ? `${quarter.name} (${quarter.year})`
          : quarter.name,
        year: quarter.year,
        totalLeads: quarter.totalLeads > 0 ? quarter.totalLeads : "-",
        convertedToCall1:
          quarter.convertedToCall1 > 0
            ? ((quarter.convertedToCall1 / quarter.totalLeads) * 100).toFixed(0)
            : "-",
        averagePriceOfSale: quarter.averagePriceOfSale
          ? new Currency({
              amount: quarter.averagePriceOfSale,
            }).format
          : "-",
        totalSales: quarter.totalSales > 0 ? quarter.totalSales : "-",
      }));
      this.loading.stats = false;
    },
    async loadNotes(): Promise<void> {
      if (!this.introducerCompany) {
        return;
      }

      // const { notes } = await window.Kernel.ActionBus.execute(
      //   "core/note/read",
      //   {
      //     introducerCompany: this.introducerCompany,
      //     query: {
      //       pinned: Equal(true),
      //     },
      //     sort: {
      //       by: "createdAt",
      //       direction: "desc",
      //     },
      //     pagination: {
      //       currentPage: 1,
      //       perPage: 3,
      //     },
      //   },
      // );
      const { notes } = await window.Kernel.ActionBus.core.note.read({
        introducerCompany: this.introducerCompany,
        query: {
          pinned: Equal(true),
        },
        sort: {
          by: "createdAt",
          direction: "desc",
        },
        pagination: {
          currentPage: 1,
          perPage: 3,
        },
      });

      this.notes = notes;

      this.loading.notes = false;
    },
    downloadReferralReport(): void {
      if (!this.introducerCompany) {
        return;
      }
      // window.Kernel.ActionBus.execute(
      //   "introducer/company/download/referral-report",
      //   {
      //     introducerCompany: this.introducerCompany,
      //   },
      // );

      window.Kernel.ActionBus.introducerCompany.downloadReferralReport({
        introducerCompany: this.introducerCompany,
      });
    },
  },
});
</script>

<style lang="scss">
.introducer-company-details-summary {
  .pinned-notes {
    .note-summary:not(:last-child) {
      border-bottom: var(--color-border) 1px solid;
    }
  }
}
</style>
