<template>
  <PageHeader htag="h1" text="Dashboard (LA)" />
  <MasterListFilters
    v-if="$data.filters"
    :filters="$data.filters"
    @submit="refresh"
  />

  <div v-if="$data.filters">
    <!--
    This whole section is aimed at establishing the existing workload, and not performance.
  -->
    <DashboardStatisticWidgetWrapper title="Current Workload">
      <!--
      The total number of leads in the system which aren't converted or QO.
      Of those leads, how many have a nextaction date of greater than today
    -->
      <DashboardStatisticWidgetGroup
        v-if="leadsInProgress"
        v-bind="leadsInProgress"
      />
      <!--
      The total number of accounts in the system to which they are assigned AND the stage 
      is one of E1 / F / G123, i.e post payment, kinvault, officer, OPG chasing
      Of those accounts, how many have a nextaction date greater than today
    -->
      <DashboardStatisticWidgetGroup
        v-if="accountsInProgress"
        v-bind="accountsInProgress"
      />
      <!--
      The accounts assigned to them, and in the appropriate status
    -->
      <DashboardStatisticWidgetGroup
        v-if="accountsBreakdown"
        v-bind="accountsBreakdown"
      />
    </DashboardStatisticWidgetWrapper>
    <!--
    This whole section is aimed at establishing historical performance. The date range defaults to 4wk rolling.
  -->
    <DashboardStatisticWidgetWrapper
      :title="'Performance ' + selectedTimeFrame"
    >
      <!--
      Call volume info, lifted directly from toll ring API report.  We could probably include average call length total time
    -->
      <DashboardStatisticWidgetGroup v-if="callVolumes" v-bind="callVolumes" />
      <!--
      Not sure if the outcome is useful here: whilst we might get a conversion from an officer call, but that's about it
    -->
      <DashboardStatisticWidgetGroup
        v-if="appointmentsOverview"
        v-bind="appointmentsOverview"
      />
      <!--
      Lead volume, and subsequent outcomes  Makes no sense to have this over all time. The idea is to assess performance ongoing.
    -->
      <DashboardStatisticWidgetGroup
        v-if="leadsCreated"
        v-bind="leadsCreated"
      />
    </DashboardStatisticWidgetWrapper>
  </div>
</template>

<script lang="ts">
import DashboardStatisticWidgetGroup from "@/shared/component/widget/DashboardStatisticWidgetGroup.vue";
import DashboardStatisticWidgetWrapper from "@/shared/component/widget/DashboardStatisticWidgetWrapper.vue";
import { TDashboardStatisticWidgetGroupProps } from "@/shared/component/widget/dashboard-statistic-widget";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import { DashboardLegalAdminRoute } from ".";

import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";

import { ReadDashboardStatisticForm } from "@/module/dashboard/form/read-dashboard-statistic.form";
import { ReadDashboardStatisticLAResponse } from "../../action/dashboard-statistic/read-dashboard-statistic-la.action";

export default defineComponent({
  name: DashboardLegalAdminRoute,
  components: {
    PageHeader,
    DashboardStatisticWidgetGroup,
    DashboardStatisticWidgetWrapper,
    MasterListFilters,
  },
  data: () => ({
    filters: ReadDashboardStatisticForm(),
    dashboardData: null as null | ReadDashboardStatisticLAResponse,
  }),
  computed: {
    selectedUserId() {
      return this.$data.filters?.localData?.selected?.id;
    },
    selectedUserName() {
      return this.$data.filters?.localData?.selected?.profile?.fullName;
    },
    selectedTimeFrame() {
      return this.$data.filters?.localData?.timeFrame;
    },
    leadsInProgress(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }
      return {
        title: "Leads in Progress",
        hint: "The total number of leads in the system which aren't converted or QO",
        details: [
          {
            label: "Current",
            value: this.dashboardData.leads.inProgress.currentCount,
          },
          {
            label: "Overdue",
            value: this.dashboardData.leads.inProgress.overdueCount,
            subValue: this.dashboardData.leads.inProgress.overduePercentage,
            cssClass: "text-danger",
          },
        ],
      };
    },
    accountsInProgress(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }
      return {
        title: "Accounts in Progress",
        details: [
          {
            label: "Current",
            value: this.dashboardData.accounts.inProgress.currentCount,
          },
          {
            label: "Overdue",
            value: this.dashboardData.accounts.inProgress.overdueCount,
            subValue: this.dashboardData.accounts.inProgress.overduePercentage,
            cssClass: "text-danger",
          },
        ],
      };
    },
    accountsBreakdown(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }
      return {
        title: "Accounts Breakdown",
        details: [
          {
            label: "e1",
            value: this.dashboardData.accounts.breakdown.e1,
          },
          {
            label: "f",
            value: this.dashboardData.accounts.breakdown.f,
          },
          {
            label: "g1",
            value: this.dashboardData.accounts.breakdown.g1,
          },
          {
            label: "g2",
            value: this.dashboardData.accounts.breakdown.g2,
          },
          {
            label: "g3",
            value: this.dashboardData.accounts.breakdown.g3,
          },
        ],
      };
    },
    callVolumes(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }
      return {
        title: "Call Volumes",
        details: [
          {
            label: "Total",
            value: this.dashboardData.calls.total,
          },
          {
            label: "Outgoing",
            value: this.dashboardData.calls.outgoing,
          },
          {
            label: "Answered",
            value: this.dashboardData.calls.answered,
          },
          {
            label: "Internal",
            value: this.dashboardData.calls.internal,
          },
        ],
      };
    },
    appointmentsOverview(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }
      return {
        title: "Appointments Overview",
        details: [
          {
            label: "Kinvault",
            value: this.dashboardData.appointments.kinvault,
          },
          {
            label: "Officer",
            value: this.dashboardData.appointments.officer,
          },
          {
            label: "Cancelled",
            value: this.dashboardData.appointments.cancelled,
          },
        ],
      };
    },
    leadsCreated(): TDashboardStatisticWidgetGroupProps | null {
      if (!this.dashboardData) {
        return null;
      }
      return {
        title: "Leads Created",
        details: [
          {
            label: "Volume",
            value: this.dashboardData.leads.created.volume,
          },
          {
            label: "Converted",
            subValue: 65,
            value: this.dashboardData.leads.created.converted,
          },
          {
            label: "Nurture",
            subValue: 65,
            value: this.dashboardData.leads.created.nurture,
          },
          {
            label: "Qualified Out",
            subValue: 65,
            value: this.dashboardData.leads.created.qualifiedOut,
          },
        ],
      };
    },
  },
  methods: {
    async refresh(): Promise<void> {
      const formData = this.$data.filters?.localData;
      const { selected, timeFrame } = formData;
      if (!selected) {
        return;
      }
      // const data = await window.Kernel.ActionBus.execute(
      //   "dashboard/dashboard-statistic-la/read",
      //   {
      //     selected: selected,
      //     timeFrame: timeFrame,
      //   },
      // );

      const data =
        await window.Kernel.ActionBus.dashboard.dashboardStatistic.la.read({
          selected: selected,
          timeFrame: timeFrame,
        });

      this.dashboardData = data;
    },
  },
});
</script>
