<template>
  <p>
    <strong>Appointment of additional Trustee(s)</strong><br />
    I wish that the Trustees would consider appointing
  </p>
  <ol type="i">
    <li v-for="trustee in data?.additionalTrustees" :key="trustee.id">
      <span v-html="helpers.var(helpers.formatOfficer(trustee.person))" />
      upon attaining the age of
      <span v-html="helpers.var(trustee.attainmentAge)" />
    </li>
  </ol>
  <p>to act as Trustee along with the existing Trustee(s).</p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
