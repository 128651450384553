import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadStorageRequestHandler } from "./read-storage-request.action";
import { UpdateStorageRequestHandler } from "./update-storage-request.action";

export const Actions = {
  "kinvault/storage-request/read": ReadStorageRequestHandler,
  "kinvault/storage-request/update": UpdateStorageRequestHandler,
};

export const KinvaultStorageRequestActionBus = DefineActionBus({
  name: "Kinvault.StorageRequest",
  actions: {
    read: ReadStorageRequestHandler,
    update: UpdateStorageRequestHandler,
  },
});
