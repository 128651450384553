import {
  AccountReferralCodeField,
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  UserField,
} from "@/config/form.config";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { In } from "@kinherit/orm";

const orderTypes = [
  {
    id: "kintin",
    text: "Kinherit",
  },
  {
    id: "trustReg",
    text: "Trust Reg",
  },
];

export const ReadOrderForm = () => {
  return MasterListFiltersForm({
    name: "filter-order-form",
    data: () => ({
      search: "",
      type: "kintin" as "kintin" | "trustReg",
      paymentType: Array<string>(),
      referral: Array<string>(),
      status: Array<string>(),
      assignedUser: Array<string>(),
      created: null as [number, number] | null,
      paidWithin: null as [number, number] | null,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name / Ref",
            reference: "search",
          },
          models: {
            value: {
              get: (data) => data.search ?? null,
              set: (value, data) => (data.search = value ?? undefined),
            },
          },
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "orderStatus",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      assignedUser: [
        UserField({
          props: {
            label: "Assigned to",
            vModel: "assignedUser",
            isMultiSelect: true,
          },
          query: {
            roles: {
              role: In(["staff", "admin"]),
            },
            displayUserInPortal: true,
          },
          simplifyData: true,
        }),
      ],
      referral: [
        AccountReferralCodeField({
          props: {
            vModel: "referral",
            isMultiSelect: true,
          },
          query: {},
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
      paidWithin: [
        CreatedAtField({
          props: {
            vModel: "paidWithin",
            label: "Paid within",
          },
          simplifyData: true,
        }),
      ],
    }),
    advancedComponents: () => ({
      type: [
        FormAutoCompleteField({
          props: {
            label: "Order Type",
            options: orderTypes,
            size: "is-small",
            placeholder: "Any",
            mapOptions: {
              value: "id",
              label: "text",
            },
            isScrollable: true,
            reference: "type",
          },
          models: {
            value: {
              get: (data) =>
                data.type
                  ? orderTypes.find((item) => item.id === data.type)
                  : null,
              set: (value, data) => (data.type = value?.id),
            },
          },
        }),
      ],
      paymentType: [
        OptionsAutoCompleteField({
          vModel: "paymentType",
          group: "paymentType",
          props: {
            label: "Payment Method",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
