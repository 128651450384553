import { Api } from "@/service/api.service";
import {
  Address,
  EmailAddress,
  Kintin,
  Person,
  PhoneNumber,
  Profile,
  SignDoc,
} from "@kinherit/sdk";

export interface RecordKintinSignDocsMessage {
  kintin: string | Kintin;
  signDoc: string | SignDoc;
}

export interface RecordKintinSignDocsResponse {
  signDocs: Array<SignDoc>;
  people: Array<Person>;
  profiles: Array<Profile>;
  addresses: Array<Address>;
  phoneNumbers: Array<PhoneNumber>;
  emails: Array<EmailAddress>;
  kintins: Array<Kintin>;
}

export const RecordKintinSignDocsHandler = async (
  message: RecordKintinSignDocsMessage,
): Promise<RecordKintinSignDocsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}/contacts",
    {
      kintin: message.kintin,
      signDoc: message.signDoc,
    },
  ).method("get");
  const response = await request.result();

  return {
    signDocs: SignDoc.$inflate(response.signDoc),
    people: Person.$inflate(response.person),
    profiles: Profile.$inflate(response.profile),
    addresses: Address.$inflate(response.address),
    phoneNumbers: PhoneNumber.$inflate(response.phoneNumber),
    emails: EmailAddress.$inflate(response.emailAddress),
    kintins: Kintin.$inflate(response.kintin),
  };
};
