import { Api } from "@kinherit/sdk/api";

export const GetSignAppUrlHandler = async (): Promise<{
  url: string;
}> => {
  const url = await Api.resource("core", "/v2/core/environment/sign-url")
    .method("get")
    .result();

  return {
    url,
  };
};
