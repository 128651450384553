<template>
  <Message v-if="null !== content" :color="color" :text="content" />
</template>

<script lang="ts">
import Message from "@kinherit/framework/component.display/message";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "CallScriptTickCross",
  props: {
    value: {
      type: Boolean as PropType<boolean | null>,
      default: null,
    },
    question: {
      type: String as PropType<string>,
      default: null,
    },
    yes: {
      type: String as PropType<string>,
      default: null,
    },
    no: {
      type: String as PropType<string>,
      default: null,
    },
  },
  computed: {
    color(): "is-success" | "is-danger" | "is-warning" {
      if (this.value === null) {
        return "is-warning";
      }
      return this.value ? "is-success" : "is-danger";
    },
    content(): string | null {
      if (this.value === null) {
        return this.question;
      }
      return this.value ? this.yes : this.no;
    },
  },
  components: { Message },
});
</script>
