import { Api } from "@/service/api.service";
import { AuthService } from "@/service/auth.service";
import { IntroducerOutsource } from "@kinherit/sdk";

interface CreateIntroducerOutsourceMessage {
  introducerOutsource: IntroducerOutsource;
}

interface CreateIntroducerOutsourceResponse {
  introducerOutsource: IntroducerOutsource;
}

export const CreateIntroducerOutsourceHandler = async (
  message: CreateIntroducerOutsourceMessage,
): Promise<CreateIntroducerOutsourceResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-outsource",
  )
    .method("post")

    .body({
      clientName: message.introducerOutsource.$data.clientName,
      status: message.introducerOutsource.$data.status,
      type: message.introducerOutsource.$data.type,
      company: message.introducerOutsource.$data.company,
      expectedValue: message.introducerOutsource.$data.expectedValue,
      paidValue: message.introducerOutsource.$data.paidValue,
      paidAt: message.introducerOutsource.$data.paidAt,
      nextActionAt: message.introducerOutsource.$data.nextActionAt,
      createdBy: AuthService.loggedInUser?.$data.id,
      assignedTo: message.introducerOutsource.$data.assignedTo,
      datedAt: message.introducerOutsource.$data.datedAt,
    })
    .result();

  const introducerOutsource = IntroducerOutsource.$inflate(
    response.introducerOutsource,
  ).first();

  if (!introducerOutsource) {
    throw new Error("Failed to create Introducer Outsource");
  }

  message.introducerOutsource.$delete();

  return {
    introducerOutsource,
  };
};
