import { FormTitle } from "@kinherit/framework/component.display/title";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";

export const ReadKinvaultProfileComponent = () =>
  FormElement({
    props: {
      is: "div",
      style: {
        display: "inline-block",
      },
      class: ["mr-5"],
      reference: "",
    },
    slots: {
      default: {
        template: GridLayout([["title"], ["noRecentLogins"]]),
        components: {
          title: [
            FormTitle({
              props: {
                text: "Kinvaults",
                size: "is-4",
              },
            }),
          ],
          noRecentLogins: [
            FormCheckboxField({
              props: {
                label: "No recent login",
              },
              models: {
                value: "kinvaultNoRecentLogins",
              },
            }),
          ],
        },
      },
    },
  });
