import { Api } from "@/service/api.service";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface CreateKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
}

interface CreateKintinStorageRequestResponse {
  physicalStorageFile: PhysicalStorageFile;
}

export const CreateKintinStorageRequestHandler = async (
  message: CreateKintinStorageRequestMessage,
): Promise<CreateKintinStorageRequestResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request",
    {
      kintin: message.kintin,
      subscription: message.subscription,
    },
  )
    .method("post")
    .body({
      length: message.storageRequest.$data.length,
      notes: message.storageRequest.$data.notes,
      subscription: message.subscription.id,
      title: message.storageRequest.$data.title,
      kintin: message.kintin.toString(),
    })

    .result();

  const physicalStorageFile = PhysicalStorageFile.$inflate(
    response.physicalStorageFile,
  ).first();

  if (!physicalStorageFile) {
    throw new Error("Failed to Create storage request");
  }

  message.storageRequest.$delete();

  return {
    physicalStorageFile,
  };
};
