<template>
  <DataListWidget
    v-if="introducerContact"
    :details="introducerContactDetails"
    title="About"
  >
    <template #below>
      <p class="has-text-right has-text-grey">
        Joined {{ introducerContact.createdAt.formatDateTime }}
      </p>
    </template>
  </DataListWidget>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsSidebarWrapper",
  "imports": {
    "DataListWidgetWrapper": "@/shared/component/widget/DataListWidget.test"
  },
  "methods": {
    "about": {
      "type": "to-one",
      "selector": "",
      "wrapper": "DataListWidgetWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { IntroducerCompanyDetailsRoute } from "@/module/introducer.company/page/details";
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { defineComponent } from "vue";
import { IntroducerContactDetailsMixin } from "../../mixin/introducer-contact-details.mixin";
export default defineComponent({
  name: "IntroducerContactDetailsSidebar",
  mixins: [IntroducerContactDetailsMixin],
  components: {
    DataListWidget,
  },
  computed: {
    introducerContactDetails() {
      if (!this.introducerContact) {
        return [];
      }

      const companies = this.introducerContact.companies;

      return [
        {
          label: "Updated",
          value: this.introducerContact.updatedAt?.formatDate,
        },
        {
          label: "Known As",
          value:
            this.introducerContact.profile.knownAs ??
            this.introducerContact.profile.fullName,
        },
        ...companies.map((company) => ({
          label: "Company",
          value: {
            text:
              company.profile?.organisationName ??
              company.profile?.fullName ??
              "None",
            to: {
              name: IntroducerCompanyDetailsRoute,
              title: "View Company",
              params: {
                introducerCompany: company.id,
              },
            },
          },
        })),
        {
          label: "Job Title",
          value: this.introducerContact.profile.jobTitle,
          hide: !this.introducerContact.profile.jobTitle,
        },
        {
          label: "Newsletter",
          value:
            null !== this.introducerContact.newsLetter
              ? this.introducerContact.newsLetter
                ? "Subscribed"
                : "Opted Out"
              : "Not Set",
        },
        {
          break: true,
        },
      ];
    },
  },
});
</script>
