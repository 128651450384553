import { Api } from "@/service/api.service";
import { BrandedKinvault } from "@kinherit/sdk";

export interface DeleteBrandedKinvaultMessage {
  brandedKinvault: BrandedKinvault | string;
}

interface DeleteBrandedKinvaultResponse {
  [key: string]: never;
}

export const DeleteBrandedKinvaultHandler = async (
  message: DeleteBrandedKinvaultMessage,
): Promise<DeleteBrandedKinvaultResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/{brandedKinvault}",
    message,
  ).method("delete");

  await request.result();

  return {};
};
