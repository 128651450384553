import { Api } from "@/service/api.service";
import { Guardian, Kintin } from "@kinherit/sdk";

interface DeleteGuardianMessage {
  kintin: string | Kintin;
  guardian: Guardian;
}

interface DeleteGuardianResponse {
  [key: string]: never;
}

export const DeleteGuardianHandler = async (
  message: DeleteGuardianMessage,
): Promise<DeleteGuardianResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-guardian/{kintin}/person/guardian/{guardian}",
    {
      kintin: message.kintin,
      guardian: message.guardian.$data.id,
    },
  )
    .method("delete")
    .result();

  message.guardian.$delete();

  return {};
};
