import { Api } from "@/service/api.service";
import { IntroducerCompany } from "@kinherit/sdk";

interface UpdateIntroducerCompanyMessage {
  introducerCompany: IntroducerCompany;
}

interface UpdateIntroducerCompanyResponse {
  introducerCompany: IntroducerCompany;
}

export const UpdateIntroducerCompanyHandler = async (
  message: UpdateIntroducerCompanyMessage,
): Promise<UpdateIntroducerCompanyResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-company/{introducerCompany}",
    {
      introducerCompany: message.introducerCompany,
    },
  )
    .method("patch")
    .body({
      network: message.introducerCompany.$data.network,
      kinvaultStatus: message.introducerCompany.$data.kinvaultStatus,
      description: message.introducerCompany.$data.description,
      lastConversation: message.introducerCompany.$data.lastConversation,
      trustRegXeroContactId:
        message.introducerCompany.$data.trustRegXeroContactId,
      kintinCount: message.introducerCompany.$data.kintinCount,
      assignedTo: message.introducerCompany.$data.assignedTo,
      defaultLegalAssistant:
        message.introducerCompany.$data.defaultLegalAssistant,
      type: message.introducerCompany.$data.type,
      stage: message.introducerCompany.$data.stage,
      status: message.introducerCompany.$data.status,
      seniorAdviser: message.introducerCompany.$data.seniorAdviser,
      introducedBy: message.introducerCompany.$data.introducedBy,
      tags: message.introducerCompany.$data.tags,
      contractNotes: message.introducerCompany.$data.contractNotes,
      xeroContact: message.introducerCompany.$data.xeroContact,
    })
    .result();

  const introducerCompany = IntroducerCompany.$inflate(
    response.introducerCompany,
  ).first();

  if (!introducerCompany) {
    throw new Error("Failed to update introducer company");
  }

  return {
    introducerCompany,
  };
};
