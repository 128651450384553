import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const AuthLoginRoute = "AuthLogin";

export const AuthRequestMfRoute = "AuthRequestMf";
const AuthRequestURI = "/multi-factor/:username";
export type AuthRequestMfParams = RouteParamObject<typeof AuthRequestURI>;

export const AuthResetPasswordRoute = "AuthResetPassword";
const AuthResetPasswordURI = "/reset-password/:token";
export type AuthResetPasswordParams = RouteParamObject<
  typeof AuthResetPasswordURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: AuthLoginRoute,
    path: "/",
    component: () => import("./Login.vue"),
  },
  {
    name: AuthRequestMfRoute,
    path: AuthRequestURI,
    component: () => import("./RequestMf.vue"),
  },
  {
    name: AuthResetPasswordRoute,
    path: AuthResetPasswordURI,
    component: () => import("./ResetPassword.vue"),
  },
];
