import { Api } from "@/service/api.service";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

interface UpdateDefaultBrandedKinvaultReferralCodeMessage {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

interface UpdateDefaultBrandedKinvaultReferralCodeResponse {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

export const UpdateDefaultBrandedKinvaultReferralCodeHandler = async (
  message: UpdateDefaultBrandedKinvaultReferralCodeMessage,
): Promise<UpdateDefaultBrandedKinvaultReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
    {
      defaultBrandedKinvaultReferralCode:
        message.defaultBrandedKinvaultReferralCode,
    },
  )
    .method("patch")

    .body({
      code: message.defaultBrandedKinvaultReferralCode.$data.code,
      name: message.defaultBrandedKinvaultReferralCode.$data.name,
    })
    .result();

  const defaultBrandedKinvaultReferralCode =
    DefaultBrandedKinvaultReferralCode.$inflate(
      response.defaultBrandedKinvaultReferralCode,
      undefined,
      response.$rootIds,
    ).first();

  if (!defaultBrandedKinvaultReferralCode) {
    throw new Error("Failed to create subscription referral code");
  }

  return {
    defaultBrandedKinvaultReferralCode,
  };
};
