import { PersonField } from "@/config/form.config";
import { CreatePerson } from "@/config/model.config";
import { UpdateAddressForm } from "@/shared/form/address/update-address.form";
import { FormButton } from "@kinherit/framework/component.input/button";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import { defineFormArea } from "@kinherit/framework/form-builder/define-form";
import { DashLoader } from "@kinherit/framework/service/dash-loader";
import { In } from "@kinherit/orm";
import { Address, Kintin, Person } from "@kinherit/sdk";
import { DateTime, Uuid } from "@kinherit/ts-common/index";
import { reactive } from "vue";
import { UpdatePersonForm } from "./update-person.form";

export const AddKintinPersonButton = ({
  kintin,
  targetProperty,
}: {
  kintin: Kintin;
  targetProperty: string;
}) =>
  FormButton<any>({
    props: {
      text: "Add Person",
      color: "is-success",
      reference: "addPerson",
    },
    emits: {
      click: async (_value, data, controls) => {
        const person = CreatePerson({
          kintin: kintin.id,
        });

        try {
          await UpdatePersonForm({
            person,
            kintin,
          }).dialog({
            dialog: {
              title: "Add Person",
              type: "fullscreen",
            },
          });
        } catch {
          person.$delete({
            profile: true,
            relationToPrimaryPerson: true,
            relationToSecondaryPerson: true,
          });
          return;
        }

        // const { person: newPerson } = await window.Kernel.ActionBus.execute(
        //   "kinvault/kintin/person/create",
        //   {
        //     person: person,
        //     kintin: kintin,
        //   },
        // );
        const { person: newPerson } =
          await window.Kernel.ActionBus.kinvaultKintin.person.create({
            person: person,
            kintin: kintin,
          });

        DashLoader.set(data, targetProperty.split("."), newPerson);

        controls.rebuildForm();
      },
    },
  });

export const AddAddressButton = ({
  kintin,
  targetProperty,
  unique,
}: {
  kintin: Kintin;
  targetProperty: string;
  unique?: boolean;
}) => {
  const localData = reactive({
    person: null as null | Person,
  });

  const form = (address: Address) => {
    localData.person = null;
    const _form = UpdateAddressForm({
      data: address,
      kintin,
      unique,
    });

    return _form.addFormArea("before", "address-form-area", (data) =>
      defineFormArea({
        name: "select-person",
        data,
        template: GridLayout([["selectPerson"]]),
        components: () => ({
          selectPerson: [
            PersonField({
              props: {
                label: "Who does this address belong to?",
                reference: "person",
                vModel: {
                  get: () => localData.person,
                  set: (value) => (localData.person = value),
                },
                validators: ["required"],
              },
              query: {
                id: In([
                  kintin.$data.primaryPerson,
                  kintin.$data.secondaryPerson,
                ]),
              },
            }),
          ],
        }),
      }),
    );
  };

  return FormButton<any>({
    props: {
      text: "Add Address",
      color: "is-success",
      reference: "addAddress",
    },
    emits: {
      click: async (_value, data, controls) => {
        const address = new Address({
          id: Uuid.generate(),
          line1: null,
          line2: null,
          city: null,
          state: null,
          postcode: null,
          country: null,
          primaryResidential: false,
          primaryMailing: false,
          createdAt: new DateTime().formatMachine,
        });

        try {
          await form(address).dialog({
            dialog: {
              title: "Add Address",
            },
          });
        } catch {
          address.$delete();
          return;
        }

        const { person } = localData as { person: Person };

        address.profile = person.profile;

        person.profile.addAddresses(address);
        // person.profile.$persist();

        // const { address: newAddress } = await window.Kernel.ActionBus.execute(
        //   "kinvault/kintin/address/create",
        //   {
        //     address: address,
        //   },
        // );

        const { address: newAddress } =
          await window.Kernel.ActionBus.kinvaultKintin.address.create({
            address: address,
          });

        DashLoader.set(data, targetProperty.split("."), newAddress);

        controls.rebuildForm();
      },
    },
  });
};
