import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface RecordBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode:
    | BrandedKinvaultSubscriptionReferralCode
    | string;
}

interface RecordBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

export const RecordBrandedKinvaultSubscriptionReferralCodeHandler = async (
  message: RecordBrandedKinvaultSubscriptionReferralCodeMessage,
): Promise<RecordBrandedKinvaultSubscriptionReferralCodeResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral-code/{brandedKinvaultSubscriptionReferralCode}",
    {
      brandedKinvaultSubscriptionReferralCode:
        message.brandedKinvaultSubscriptionReferralCode,
    },
  )
    .method("get")

    .result();

  const brandedKinvaultSubscriptionReferralCode =
    BrandedKinvaultSubscriptionReferralCode.$inflate(
      repsonse.brandedKinvaultSubscriptionReferralCode,
      undefined,
      repsonse.$rootIds,
    ).first();

  if (!brandedKinvaultSubscriptionReferralCode) {
    throw new Error("Failed to record subscription referral code");
  }

  return {
    brandedKinvaultSubscriptionReferralCode,
  };
};
