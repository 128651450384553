import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, Equal, Like } from "@kinherit/orm/index";
import {
  Api,
  IKintinAccess,
  IntroducerContact,
  Kintin,
  KintinAccess,
} from "@kinherit/sdk";

export interface ReadKintinAccessMessage {
  introducerContact: IntroducerContact;
  search?: string;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof IKintinAccess;
    direction: "asc" | "desc";
  };
}

interface ReadKintinAccessResponse {
  kintinAccess: Array<KintinAccess>;
  kintin: Array<Kintin>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKintinAccessHandler = async (
  message: ReadKintinAccessMessage,
): Promise<ReadKintinAccessResponse> => {
  const request = Api.resource("portal", "/v2/portal/kintin-user-access")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(KintinAccess).where({
    introducerContact: {
      id: Equal(message.introducerContact.$id),
    },
    kintin: {
      friendlyName: Like(message.search),
    },
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    kintinAccess: KintinAccess.$inflate(
      response.kintinAccess,
      message.sort,
      response.$rootIds,
    ),
    kintin: Kintin.$inflate(response.kintin),
    pagination: getPagination(response),
  };
};
