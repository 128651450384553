<template>
  <ol type="a">
    <li
      v-for="(beneficiaries, index) in subSections"
      :key="'beneficiary-subsection' + index"
    >
      <span v-if="beneficiaries.length === 1">
        Upon trust absolutely for
        <span
          v-html="helpers.var(helpers.formatBeneficiary(beneficiaries[0]))"
        />
        <span v-if="helpers.mapSubClause(index) !== 'a'">
          <span
            v-html="helpers.var(helpers.formatPredeceased(beneficiaries[0]))"
          />
        </span>
      </span>

      <span v-if="beneficiaries.length > 1">
        Upon trust in the following shares:

        <ol type="i">
          <li v-for="beneficiary in beneficiaries" :key="beneficiary.id">
            <span
              v-html="
                helpers.var(
                  helpers.calculateBeneficiaryShare(beneficiaries, beneficiary),
                )
              "
            />
            <span
              v-html="helpers.var(helpers.formatBeneficiary(beneficiary))"
            />
            <span
              v-html="helpers.var(helpers.formatPredeceased(beneficiary))"
            />
          </li>
        </ol>

        <!--span v-if="helpers.mapSubClause(index) !== 'a'"-->
        <br />
        If any of the share or shares under this sub clause
        <span v-html="helpers.var(helpers.mapSubClause(index))" />
        shall fail in their entirety that share or those shares shall be added
        proportionally to the other shares that have not failed (and this
        provision shall apply to both an original share and an augmented share)
        <!--/span-->
      </span>
      <span v-if="index + 1 !== subSections.length">
        <br />
        <br />
        Provided that if the trusts declared by this sub clause
        <span v-html="helpers.var(helpers.mapSubClause(index))" />
        of this my Will shall fail entirely and subject thereto
        <br />
        <br />
      </span>
    </li>
  </ol>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DistributionOutput",
  mixins: [WillBuilderService.mixin],
  computed: {
    subSections() {
      if (!this.data) return [];
      const result = [];
      if (this.data.beneficiariesFirstLine.length > 0) {
        result.push(this.data.beneficiariesFirstLine);
      }
      if (this.data.beneficiariesSecondLine.length > 0) {
        result.push(this.data.beneficiariesSecondLine);
      }
      if (this.data.reserveBeneficiaries.length > 0) {
        result.push(this.data.reserveBeneficiaries);
      }
      return result;
    },
  },
});
</script>
