import { Api } from "@/service/api.service";
import { BrandedKinvaultMembership } from "@kinherit/sdk";

interface UpdateBrandedKinvaultMembershipMessage {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

interface UpdateBrandedKinvaultMembershipResponse {
  brandedKinvaultMembership: BrandedKinvaultMembership;
}

export const UpdateBrandedKinvaultMembershipHandler = async (
  message: UpdateBrandedKinvaultMembershipMessage,
): Promise<UpdateBrandedKinvaultMembershipResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault-membership/{brandedKinvaultMembership}",
    message,
  ).method("patch");
  request.body({
    allowAccess: message.brandedKinvaultMembership.$data.allowAccess,
    brandedKinvault: message.brandedKinvaultMembership.$data.brandedKinvault,
    user: message.brandedKinvaultMembership.$data.user,
  });

  const result = await request.result();

  const brandedKinvaultMembership = BrandedKinvaultMembership.$inflate(
    result.brandedKinvaultMembership,
  ).first();

  if (!brandedKinvaultMembership) {
    throw new Error("Failed to update branded kinvault membership");
  }

  return {
    brandedKinvaultMembership,
  };
};
