import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { ReadIntroducerContractHandler } from "./read-introducer-contract.action";

export const Actions = {
  "introducer/contract/read": ReadIntroducerContractHandler,
};

export const IntroducerContractActionBus = DefineActionBus({
  name: "Introducer.Contract",
  actions: {
    read: ReadIntroducerContractHandler,
  },
});
