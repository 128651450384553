import { Api } from "@/service/api.service";
import { AccountReferral } from "@kinherit/sdk";

interface RecordAccountReferralMessage {
  referral: string;
}

interface RecordAccountReferralResponse {
  referrals: Array<AccountReferral>;
}

export const RecordAccountReferralHandler = async (
  message: RecordAccountReferralMessage,
): Promise<RecordAccountReferralResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/account-referral/{accountReferral}",
    {
      accountReferral: message.referral,
    },
  ).method("get");
  const result = await request.result();

  return {
    referrals: AccountReferral.$inflate(
      result.accountReferral,
      undefined,
      result.$rootIds,
    ),
  };
};
