import {
  Between,
  In,
  LessThanOrEqual,
  Like,
  MoreThanOrEqual,
} from "@kinherit/orm/index";
import { Api, CallLog, ICallLog, Kintin, Person, Profile } from "@kinherit/sdk";

export interface ReadCallLogMessage {
  username?: string;
  direction?: Array<string>;
  minDuration?: number | null;
  maxDuration?: number | null;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof ICallLog;
    direction: "asc" | "desc";
  };
}

interface ReadCallLogResponse {
  callLogs: Array<CallLog>;
  people: Array<Person>;
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadCallLogHandler = async (
  message: ReadCallLogMessage,
): Promise<ReadCallLogResponse> => {
  const request = Api.resource("portal", "/v2/portal/call-log")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(CallLog).where({
    username: Like(message.username),
    duration:
      message.minDuration && message.maxDuration
        ? Between([message.minDuration, message.maxDuration])
        : message.minDuration
          ? LessThanOrEqual(message.minDuration)
          : message.maxDuration
            ? MoreThanOrEqual(message.maxDuration)
            : undefined,
    direction: {
      id: In(message.direction),
    },
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    callLogs: CallLog.$inflate(
      response.callLog,
      message.sort,
      response.$rootIds,
    ),
    people: Person.$inflate(response.person),
    kintins: Kintin.$inflate(response.kintin),
    profiles: Profile.$inflate(response.profile),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
