import { DangerDialog } from "@/config/dialog.config";
import {
  OptionsAutoCompleteField,
  OptionsSelectField,
  ProductField,
  SharedFormProps,
  UserField,
} from "@/config/form.config";
import { OrderItemTableColumns } from "@/config/table-columns.config";
import { StyleService } from "@/service/style.service";
import { FormMessage } from "@kinherit/framework/component.display/message";
import { FormTable } from "@kinherit/framework/component.display/table";
import { FormTabs } from "@kinherit/framework/component.display/tabs";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormCurrencyField } from "@kinherit/framework/component.input/currency-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormNumberField } from "@kinherit/framework/component.input/number-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, In } from "@kinherit/orm";
import { AccountReferralCode, Order, OrderItem, Product } from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common";
import { OrderCalculator } from "@kinherit/ts-common/service/order-calculator";
import { Uuid } from "@kinherit/ts-common/service/uuid";
import { reactive } from "vue";

export const UpdateOrderForm = ({
  data,
  referralCode,
}: {
  data: Order;
  referralCode?: AccountReferralCode;
}) => {
  const localData = reactive({
    selectedProduct: null as null | Product,
    discountType: "None" as "None" | "Percentage" | "Absolute",
    selectedTab: 0,
  });

  if (data.discountValue?.amount !== 0 && data.discountValue?.amount !== null) {
    localData.discountType = "Absolute";
  } else if (
    data.discountPercentage !== 0 &&
    data.discountPercentage !== null
  ) {
    localData.discountType = "Percentage";
  }

  let ProductTypes: null | "joint" | "single" | "trustReg" = null;

  if (data.kintin && data.kintin.type.value === "joint") {
    ProductTypes = "joint";
  } else if (data.kintin && data.kintin.type.value === "single") {
    ProductTypes = "single";
  }

  const referralMessage: string[] = [];
  if (referralCode?.isFeeSacrifice) {
    referralMessage.push(
      "Introducer Sacrificing Fee: Ensure appropriate discount reason is applied",
    );
  }
  if (referralCode?.isSalarySacrifice) {
    referralMessage.push(
      "Salary Sacrifice is available for this client. Ensure employer is invoiced if taken.",
    );
  }
  if (referralCode?.isFriendsAndFamily) {
    referralMessage.push(
      "Allow for 30% discount: ensure correct discount code is used. No introducer fees payable",
    );
  }
  if (referralCode?.notes) {
    referralMessage.push(referralCode?.notes);
  }
  return defineForm({
    name: "update-order-form",
    props: SharedFormProps,
    data: () => data as Order,
    validate: (data) => {
      const discountValidation = [
        localData.discountType === "Absolute"
          ? (data.discountValue?.amount ?? 0) <=
            OrderCalculator.getOrderValues(data).total.price.amount
          : true,
        localData.discountType === "Absolute"
          ? data.discountValue?.amount !== 0
          : true,
        localData.discountType !== "None" ? null !== data.discountReason : true,
      ].onlyIncludes(true);

      const orderItemsValid = data.orderItems.length > 0;

      return [discountValidation, orderItemsValid].onlyIncludes(true);
    },
    formAreas: (data) => [
      defineFormArea({
        name: "update-order-form-area",
        data,
        template: {
          children: referralCode
            ? [{ slot: "notes" }, { is: "hr" }, { slot: "tabs" }]
            : [{ slot: "tabs" }],
        },
        components: () => ({
          notes: [
            FormMessage({
              props: {
                color: "is-warning",
                title: "Referral Notes",
                text: referralMessage.join("\n"),
              },
            }),
          ],
          tabs: [
            FormTabs({
              props: {
                isLazy: true,
                size: "is-small",
                style: {
                  // marginTop: "-1.5em",
                },
                isFullwidth: false,
                config: () => {
                  const additionsValid = [
                    localData.discountType === "Absolute"
                      ? (data.discountValue?.amount ?? 0) <=
                        OrderCalculator.getOrderValues(data).total.price.amount
                      : true,
                    localData.discountType === "Absolute"
                      ? data.discountValue?.amount !== 0
                      : true,
                    localData.discountType !== "None"
                      ? null !== data.discountReason
                      : true,
                  ].onlyIncludes(true);

                  const statusValid = [
                    data.status?.value === "paid" ? null !== data.paidAt : true,
                    data.status?.value === "paid"
                      ? null !== data.paymentType
                      : true,
                  ].onlyIncludes(true);

                  const introducerFeesValid = true;

                  const lineItemsValid = data.orderItems.length > 0;
                  // const introducerFeesValid = [
                  //   (data.feesPayOutIntro?.amount ?? 0) > 0
                  //     ? null !== data.feesPayOutIntroDate
                  //     : true,
                  //   (data.feesPayoutAgent?.amount ?? 0) > 0
                  //     ? null !== data.feesPayOutAgentDate
                  //     : true,
                  //   (data.feesPayOutParentAgent?.amount ?? 0) > 0
                  //     ? null !== data.feesPayOutParentAgentDate
                  //     : true,
                  // ].onlyIncludes(true);

                  return [
                    {
                      label: "Products",
                      fontColor: lineItemsValid ? undefined : "has-text-danger",
                    },
                    {
                      label: "Additions",
                      fontColor: additionsValid ? undefined : "has-text-danger",
                    },
                    {
                      label: "Status",
                      fontColor: statusValid ? undefined : "has-text-danger",
                    },
                    {
                      label: "Introducer Fees",
                      fontColor: introducerFeesValid
                        ? undefined
                        : "has-text-danger",
                    },
                    {
                      label: "Options",
                    },
                    {
                      label: "Billing Information",
                    },
                  ];
                },
                reference: "update-order-form-area-tabs",
              },
              models: {
                tab: {
                  get: () => localData.selectedTab,
                  set: (value) => (localData.selectedTab = value as number),
                },
              },
              slots: {
                products: {
                  template: GridLayout([
                    ["selectProduct"],
                    ["productTable"],
                    ["summaryTable1"],
                  ]),
                  components: {
                    selectProduct: [
                      ProductField({
                        selectField: true,
                        props: {
                          reference: "selectedProduct",
                          vModel: {
                            get: () => localData.selectedProduct,
                            set: (value) => {
                              localData.selectedProduct = value;
                            },
                          },
                        },
                        query: {
                          type: Equal(ProductTypes),
                        },
                        slots: {
                          right: [
                            FormButton({
                              props: {
                                text: "Add",
                                color: "is-positive",
                                size: "is-normal",
                                reference: "addProduct",
                              },
                              emits: {
                                click: (value, data, controls) => {
                                  if (localData.selectedProduct) {
                                    data.addOrderItems(
                                      OrderItem.$create({
                                        id: Uuid.generate(),
                                        order: data.id,
                                        createdAt: new DateTime().formatMachine,
                                        product: localData.selectedProduct.id,
                                        price: localData.selectedProduct.price,
                                        quantity: 1,
                                        recurring: false, // @todo
                                        requiresDd: false, // @todo
                                        text: localData.selectedProduct.text,
                                        value: localData.selectedProduct.value,
                                        vat: 20,
                                      }),
                                      // .$persist()
                                    );
                                    localData.selectedProduct = null;
                                    controls.incrementFormRenderKey();
                                  }
                                },
                              },
                            }),
                          ],
                        },
                      }),
                    ],
                    productTable: [
                      FormTable({
                        props: {
                          size: "is-small",
                          isNarrow: true,
                          columns: OrderItemTableColumns({ actions: true }),
                          rows: (data) => data.orderItems,
                          reference: "productTable",
                          emptyMessage:
                            "No line items found: at least one is required",
                        },
                        slots: {
                          quantity: [
                            FormNumberField({
                              props: {
                                min: 1,
                                max: 999,
                                reference: "productQuantity",
                                size: "is-small",
                              },
                              models: {
                                value: {
                                  get: (_data, controls) =>
                                    controls.templateScope.row?.quantity,
                                  set: (value, _data, controls) => {
                                    controls.templateScope.row.quantity = value;
                                    controls.incrementFormRenderKey();
                                  },
                                },
                              },
                            }),
                          ],
                          actions: [
                            FormButton({
                              props: {
                                color: "is-plain",
                                size: "is-small",
                                iconRight: StyleService.icon.delete.icon,
                                reference: "removeProduct",
                                ariaLabel: (_, controls) =>
                                  `Remove ${controls.templateScope.row.text}`,
                              },
                              emits: {
                                click: async (_value, data, controls) => {
                                  await DangerDialog({
                                    dialog: {
                                      title: "Delete Order",
                                      message: `Are you sure you want to delete '${controls.templateScope.row.text}' from this order?`,
                                    },
                                    button: {
                                      ok: {
                                        text: "Delete",
                                      },
                                      cancel: {
                                        text: "Cancel",
                                      },
                                    },
                                  });

                                  data.removeOrderItems(
                                    controls.templateScope.row,
                                  );
                                  controls.templateScope.row.$delete();
                                  controls.incrementFormRenderKey();
                                },
                              },
                            }),
                          ],
                        },
                      }),
                    ],
                    summaryTable1: [
                      FormTable({
                        props: {
                          class: "mt-4",
                          isMobile: true,
                          columns: [
                            {
                              title: "List Price",
                              field: "listPrice",
                              class: "has-text-weight-bold",
                            },
                            {
                              title: "Extras",
                              field: "extras",
                              class: "has-text-weight-bold",
                            },
                            {
                              title: "Discount Applied",
                              field: "discount",
                              class: "has-text-weight-bold has-text-danger",
                            },
                            {
                              title: "Final Total Ex VAT",
                              field: "exVat",
                            },
                            {
                              title: "Final Total VAT",
                              field: "vat",
                            },
                            {
                              title: "Final Total",
                              field: "price",
                              class: "has-text-weight-bold",
                            },
                          ],
                          rows: (data) => {
                            const orderValues =
                              OrderCalculator.getOrderValues(data);

                            return [
                              {
                                listPrice: orderValues.total.price.format,
                                extras: data.extrasPrice?.format,
                                discount: orderValues.discount.format,
                                exVat: orderValues.modified.exVat.format,
                                vat: orderValues.modified.vat.format,
                                price: orderValues.modified.price.format,
                              },
                            ];
                          },
                          reference: "summaryTable2",
                        },
                      }),
                    ],
                  },
                },
                additions: {
                  template: GridLayout([
                    ["discountType", "discountValue", "discountReason"],
                    ["extras"],
                    ["summaryTable2"],
                  ]),
                  components: {
                    extras: [
                      FormCurrencyField({
                        props: {
                          label: "Extras",
                          reference: "extras",
                          message: "Any additional costs",
                        },
                        models: {
                          value: {
                            get: (data) => data.extrasPrice,
                            set: (value, data, controls) => {
                              data.extrasPrice = value;
                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                    ],
                    discountType: [
                      FormSelectField({
                        props: {
                          label: "Discount Type",
                          options: {
                            None: "None",
                            Percentage: "Percentage",
                            Absolute: "Absolute",
                          },
                          reference: "discountType",
                          showClearButton: false,
                        },
                        models: {
                          value: {
                            get: () => localData.discountType,
                            set: (value, data, controls) => {
                              localData.discountType = value;

                              if (value === "None") {
                                data.discountPercentage = 0;
                                data.discountValue = new Currency({
                                  amount: 0,
                                  type: "GBP",
                                });
                              } else if (value === "Percentage") {
                                data.discountValue = new Currency({
                                  amount: 0,
                                  type: "GBP",
                                });
                              } else if (value === "Absolute") {
                                data.discountPercentage = 0;
                              }

                              controls.rebuildForm();
                            },
                          },
                        },
                      }),
                    ],
                    discountValue: [
                      FormCurrencyField({
                        props: {
                          vIf: () => localData.discountType === "Absolute",
                          label: "Absolute Discount",
                          reference: "discountValue",
                          validators: ["required"],
                          messageColor: (data) => {
                            if (
                              OrderCalculator.getOrderValues(data).total.price
                                .amount < (data.discountValue?.amount ?? 0)
                            ) {
                              return "has-text-danger";
                            }

                            if (data.discountValue?.amount === 0) {
                              return "has-text-danger";
                            }
                          },
                          message: (data) => {
                            if (
                              OrderCalculator.getOrderValues(data).total.price
                                .amount < (data.discountValue?.amount ?? 0)
                            ) {
                              return "Discount exceeds order value";
                            }

                            if (data.discountValue?.amount === 0) {
                              return "Discount must be greater than 0";
                            }

                            return "Discount applied";
                          },
                          color: (data) => {
                            if (
                              OrderCalculator.getOrderValues(data).total.price
                                .amount < (data.discountValue?.amount ?? 0)
                            ) {
                              return "is-danger";
                            }

                            if (data.discountValue?.amount === 0) {
                              return "is-danger";
                            }
                          },
                        },
                        models: {
                          value: {
                            get: (data) => data.discountValue,
                            set: (value, data, controls) => {
                              data.discountValue = value;
                              data.discountPercentage = 0;
                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                      FormNumberField({
                        props: {
                          vIf: () => localData.discountType === "Percentage",
                          label: "% Discount",
                          validators: ["required"],
                          max: 100,
                          message: (data) =>
                            data.discountPercentage !== 0
                              ? "Discount applied"
                              : undefined,
                          reference: "discountPercentage",
                        },
                        models: {
                          value: {
                            get: (data) => data.discountPercentage,
                            set: (value, data, controls) => {
                              data.discountPercentage = value;
                              data.discountValue = new Currency({
                                amount: 0,
                                type: "GBP",
                              });
                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                    ],
                    discountReason: [
                      OptionsAutoCompleteField({
                        group: "orderDiscountReason",
                        vModel: "discountReason",
                        props: {
                          vIf: () => "None" !== localData.discountType,
                          reference: "discountReason",
                          validators: ["required"],
                        },
                      }),
                    ],
                    summaryTable2: [
                      FormTable({
                        props: {
                          class: "mt-4",
                          columns: [
                            {
                              title: "List Price",
                              field: "listPrice",
                              class: "has-text-weight-bold",
                            },
                            {
                              title: "Extras",
                              field: "extras",
                              class: "has-text-weight-bold",
                            },
                            {
                              title: "Discount Applied",
                              field: "discount",
                              class: "has-text-weight-bold has-text-danger",
                            },
                            {
                              title: "Final Total Ex VAT",
                              field: "exVat",
                            },
                            {
                              title: "Final Total VAT",
                              field: "vat",
                            },
                            {
                              title: "Final Total",
                              field: "price",
                              class: "has-text-weight-bold",
                            },
                          ],
                          rows: (data) => {
                            const orderValues =
                              OrderCalculator.getOrderValues(data);

                            return [
                              {
                                listPrice: orderValues.total.price.format,
                                extras: data.extrasPrice?.format,
                                discount: orderValues.discount.format,
                                exVat: orderValues.modified.exVat.format,
                                vat: orderValues.modified.vat.format,
                                price: orderValues.modified.price.format,
                              },
                            ];
                          },
                          reference: "summaryTable2",
                        },
                      }),
                    ],
                  },
                },
                status: {
                  template: GridLayout([
                    ["invoiceNo", "internalValue"],
                    ["status", "paidOn", "paymentMethods"],
                    ["notes"],
                  ]),
                  components: {
                    status: [
                      OptionsSelectField({
                        group: "orderStatus",
                        vModel: {
                          get: (data) => data.status,
                          set: (value, data, controls) => {
                            data.status = value;

                            controls.rebuildForm();
                          },
                        },
                        props: {
                          reference: "status",
                          validators: ["required"],
                          showClearButton: false,
                        },
                      }),
                    ],
                    paymentMethods: [
                      OptionsSelectField({
                        group: "paymentType",
                        vModel: "paymentType",
                        props: {
                          reference: "paymentType",
                          validators: ["required"],
                          vIf: (data) => data.status?.value === "paid",
                        },
                      }),
                    ],
                    paidOn: [
                      FormDateField({
                        props: {
                          label: "Paid On",
                          reference: "paidOn",
                          validators: ["required"],
                          vIf: (data) => data.status?.value === "paid",
                        },
                        models: {
                          value: "paidAt",
                        },
                      }),
                    ],
                    invoiceNo: [
                      FormTextField({
                        props: {
                          label: "Invoice Number",
                          reference: "invoiceNo",
                        },
                        models: {
                          value: "invoiceNumber",
                        },
                      }),
                    ],
                    internalValue: [
                      FormCurrencyField({
                        props: {
                          label: "Internal Value",
                          reference: "internalValue",
                          message:
                            "Override portal reporting value, i.e Excellect",
                        },
                        models: {
                          value: "overrideListPrice",
                        },
                      }),
                    ],
                    notes: [
                      FormRichTextField({
                        props: {
                          label: "Notes",
                          reference: "notes",
                        },
                        models: {
                          value: "notes",
                        },
                      }),
                    ],
                  },
                },
                introducerFees: {
                  template: GridLayout([
                    ["introducerFeePaid", "introducerFeePaidOn"],
                    ["agentFeePaid", "agentFeePaidOn"],
                    ["parentFeePaid", "parentFeePaidOn"],
                    ["feesConfirmed"],
                  ]),
                  components: {
                    introducerFeePaid: [
                      FormCurrencyField({
                        props: {
                          label: "Introducer Fee Paid",
                          reference: "introducerFeePaid",
                        },
                        models: {
                          value: {
                            get: (data) => data.feesPayOutIntro,
                            set: (value, data, controls) => {
                              data.feesPayOutIntro = value;

                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                    ],
                    introducerFeePaidOn: [
                      FormDateField({
                        props: {
                          label: "Introducer Fee Paid On",
                          reference: "introducerFeePaidOn",
                          // validators: ["required"],
                          vIf: (data) =>
                            null !== data.feesPayOutIntro &&
                            0 !== data.feesPayOutIntro.amount,
                        },
                        models: {
                          value: "feesPayOutIntroDate",
                        },
                      }),
                    ],
                    agentFeePaid: [
                      FormCurrencyField({
                        props: {
                          label: "Agent Fee Paid",
                          reference: "agentFeePaid",
                        },
                        models: {
                          value: {
                            get: (data) => data.feesPayoutAgent,
                            set: (value, data, controls) => {
                              data.feesPayoutAgent = value;

                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                    ],
                    agentFeePaidOn: [
                      FormDateField({
                        props: {
                          label: "Agent Fee Paid On",
                          reference: "agentFeePaidOn",
                          // validators: ["required"],
                          vIf: (data) =>
                            null !== data.feesPayoutAgent &&
                            0 !== data.feesPayoutAgent.amount,
                        },
                        models: {
                          value: "feesPayOutAgentDate",
                        },
                      }),
                    ],
                    parentFeePaid: [
                      FormCurrencyField({
                        props: {
                          label: "Parent Fee Paid",
                          reference: "parentFeePaid",
                        },
                        models: {
                          value: {
                            get: (data) => data.feesPayOutParentAgent,
                            set: (value, data, controls) => {
                              data.feesPayOutParentAgent = value;

                              controls.rebuildTemplateBindings();
                            },
                          },
                        },
                      }),
                    ],
                    parentFeePaidOn: [
                      FormDateField({
                        props: {
                          label: "Parent Fee Paid On",
                          reference: "feesPayOutParentAgentDate",
                          // validators: ["required"],
                          vIf: (data) =>
                            null !== data.feesPayOutParentAgent &&
                            0 !== data.feesPayOutParentAgent.amount,
                        },
                        models: {
                          value: "feesPayOutParentAgentDate",
                        },
                      }),
                    ],
                    feesConfirmed: [
                      FormCheckboxField({
                        props: {
                          label: "Fees Confirmed",
                          reference: "feesConfirmed",
                        },
                        models: {
                          value: "feesConfirmed",
                        },
                      }),
                    ],
                  },
                },
                options: {
                  template: GridLayout([["left", "right"]]),
                  components: {
                    left: [
                      FormCheckboxField({
                        props: {
                          label: "Waive 14 Day Cooling Off",
                          reference: "waive14DayCoolingOff",
                          class: "mb-5 pb-3",
                        },
                        models: {
                          value: "coolingOffPeriod",
                        },
                      }),
                    ],
                    right: [
                      UserField({
                        props: {
                          label: "Order Created By",
                          vModel: "createdBy",
                          reference: "createdBy",
                          message: "Who this order is attributable to",
                          forceDialog: true,
                        },
                        query: {
                          roles: {
                            role: In(["staff", "admin"]),
                          },
                          displayUserInPortal: true,
                        },
                      }),
                    ],
                  },
                },
                billingInformation: {
                  template: GridLayout([
                    ["name"],
                    ["address1", "address2"],
                    ["city", "region", "postcode"],
                  ]),
                  components: {
                    name: [
                      FormTextField({
                        props: {
                          label: "Name",
                          reference: "billingName",
                        },
                        models: {
                          value: "billingName",
                        },
                      }),
                    ],
                    address1: [
                      FormTextField({
                        props: {
                          label: "Address 1",
                          reference: "billingAddress1",
                        },
                        models: {
                          value: "billingAddress1",
                        },
                      }),
                    ],
                    address2: [
                      FormTextField({
                        props: {
                          label: "Address 2",
                          reference: "billingAddress2",
                        },
                        models: {
                          value: "billingAddress2",
                        },
                      }),
                    ],
                    city: [
                      FormTextField({
                        props: {
                          label: "City",
                          reference: "billingCity",
                        },
                        models: {
                          value: "billingCity",
                        },
                      }),
                    ],
                    region: [
                      FormTextField({
                        props: {
                          label: "Region",
                          reference: "billingRegion",
                        },
                        models: {
                          value: "billingRegion",
                        },
                      }),
                    ],
                    postcode: [
                      FormTextField({
                        props: {
                          label: "Postcode",
                          reference: "billingPostcode",
                        },
                        models: {
                          value: "billingPostalCode",
                        },
                      }),
                    ],
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
