import { Api } from "@/service/api.service";
import { KintinCheck } from "@kinherit/sdk";

interface DeleteKintinCheckMessage {
  kintin: string;
  kintinCheck: KintinCheck;
}

interface DeleteKintinCheckResponse {
  [key: string]: never;
}

export const DeleteKintinCheckHandler = async (
  message: DeleteKintinCheckMessage,
): Promise<DeleteKintinCheckResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-check/{kintin}/checks/{kintinCheck}",
    {
      kintin: message.kintin,
      kintinCheck: message.kintinCheck,
    },
  )
    .method("delete")
    .result();

  message.kintinCheck.$delete();

  return {};
};
