<template>
  <div v-if="kintin">
    <CallScriptSecuritySection
      start="Great. So before we start, I need to do a quick security check if that’s OK?"
      :kintin="kintin"
      step="2.1.1"
      :checks="checks"
      :kintinChecks="kintinChecks"
    />
    <SalutationSection
      :kintin="kintin"
      :checks="checks"
      :kintinChecks="kintinChecks"
    />
    <CallScriptReferralSection
      :checks="checks"
      :kintinChecks="kintinChecks"
      :kintin="kintin"
      step="2.3.1"
      :middle="`<br />
                      <b>
                      Can I ask, was there anything in particular that made you to think about your Planning?
                      </b>
                      <br />
                      <br />
                      <b>It is great to understand what made you have this call … I know it can be easy to put off writing a Will but the hardest step really is making the first call.
                      </b>
                      <br /><br />`"
      :end="`<br />
                      <b>
                      Just to reconfirm, my name is ${$auth.loggedInUser?.profile.firstName} and I’m a qualified Estate Planner - so I am a specialist in Wills, Trusts, Lasting Powers of Attorney and that sort of thing. So if you have questions as we go through - do stop me - I want to try to answer them.
                      </b>`"
    />
  </div>
</template>

<script lang="ts">
import CallScriptReferralSection from "@/module/kinvault.kintin/component/call-script/shared/ReferralSection.vue";
import SalutationSection from "@/module/kinvault.kintin/component/call-script/shared/SalutationSection.vue";
import CallScriptSecuritySection from "@/module/kinvault.kintin/component/call-script/shared/SecuritySection.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SecurityTab",
  components: {
    CallScriptSecuritySection,
    SalutationSection,
    CallScriptReferralSection,
  },
  mixins: [AuthService.mixin(), KinvaultKintinDetailsCallScriptMixin],
});
</script>
