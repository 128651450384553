import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { NotIn } from "@kinherit/orm/index";
import { Option } from "@kinherit/sdk/index";

export const ReadIntroducerOutsourceForm = () => {
  const dueByFilteredStatuses = Option.$findBy({
    group: "introducerOutsourceStatuses",
    value: NotIn(["qualifiedout", "completed"]),
  }).pluck("id");

  return MasterListFiltersForm({
    name: "filter-introducer-outsource-form",
    data: () => ({
      name: "",
      type: Array<string>(),
      status: Array<string>(),
      created: null as null | [number, number],
      dueBy: null as null | "today" | "tomorrow" | "overdue",
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Client or Company",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      type: [
        OptionsAutoCompleteField({
          vModel: "type",
          group: "introducerOutsourceTypes",
          props: {
            label: "Type",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "introducerOutsourceStatuses",
          props: {
            label: "Status",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],

      dueBy: [
        FormSelectField({
          props: {
            label: "Due by",
            options: {
              today: "Today",
              tomorrow: "Tomorrow",
              overdue: "Overdue",
            },
            reference: "dueBy",
          },
          models: {
            value: {
              get: (data) => data.dueBy,
              set: (value, data, controls) => {
                data.dueBy = value;
                data.status = dueByFilteredStatuses;
                controls.incrementFormRenderKey();
              },
            },
          },
        }),
      ],
    }),
    advancedComponents: () => ({
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
