<template>
  <strong>For the Avoidance of doubt</strong>
  <p>I Declare that</p>
  <ol type="a">
    <li>
      Clause headings are included for reference purposes only and do not affect
      the interpretation of my Will
    </li>
    <li>
      Words denoting the singular shall include the plural and vice versa and
      the masculine includes the feminine and vice versa
    </li>
    <li>
      Person shall include a body of persons either corporate or incorporate
    </li>
    <li>
      References to any statutory provision shall include any statutory
      modification or re-enactment of such provisions
    </li>
    <li>
      Due to printing limitations the reverse side of every page of my will may
      be left blank intentionally in line with the STEP Powers preceding this
      clause
    </li>
    <li>
      This is to be the last clause of my Will and shall be followed only by the
      attestation clause
    </li>
  </ol>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
