import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptLPAOnlyRoute =
  "KintinDetailsCallScriptLPAOnly";
export const KintinDetailsCallScriptLPAOnlyURI =
  "/kinvault/:kintin/call-script/lpa-only";
export type KintinDetailsCallScriptLPAOnlyParams = RouteParamObject<
  typeof KintinDetailsCallScriptLPAOnlyURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptLPAOnlyRoute,
    path: KintinDetailsCallScriptLPAOnlyURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptLPAOnly" */ "./KinvaultKintinDetails.CallScript.LPAOnly.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Who Does What",
          route: { name: KintinDetailsCallScriptLPAOnlyRoute, params },
        },
      ],
    },
  },
];
