import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
  TagsField,
} from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadIntroducerContactForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-contact-form",
    data: () => ({
      search: "",
      tags: Array<string>(),
      status: Array<string>(),
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Search",
            placeholder: "Name / Email",
          },
          models: {
            value: "search",
          },
        }),
      ],
      tags: [
        TagsField({
          props: {
            vModel: "tags",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "introducerContactStatus",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
