import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { Order } from "@kinherit/sdk";

export const IntroducerFeeKintinMasterListRoute =
  "IntroducerFeeKintinMasterList";
export const IntroducerFeeKintinDetailsRoute = "IntroducerFeeKintinDetails";
export const IntroducerFeeKintinDetailsURI = "/introducer/fee/kintin/:id";
export type IntroducerFeeKintinDetailsParams = RouteParamObject<
  typeof IntroducerFeeKintinDetailsURI
>;

const IntroducerFeeKintinMasterListBreadCrumb = {
  text: "Introducer Fees",
  route: { name: IntroducerFeeKintinMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerFeeKintinMasterListRoute,
    path: "/introducer/fees/kintin",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeeKintinMasterList" */ "./IntroducerFeeKintinMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerFeeKintinMasterListBreadCrumb],
    },
  },
  {
    name: IntroducerFeeKintinDetailsRoute,
    path: IntroducerFeeKintinDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "IntroducerFeeKintinDetails" */ "./IntroducerFeeKintinDetails.vue"
      ),
    meta: {
      breadcrumbs: (params) => {
        const text = Order.$findOne(params.id)?.kintin?.friendlyName;
        return [
          IntroducerFeeKintinMasterListBreadCrumb,
          {
            text: text ?? "",
            route: { name: IntroducerFeeKintinDetailsRoute },
          },
        ];
      },
    },
  },
];
