<template>
  <div v-if="appointment">
    {{ appointment }}
  </div>
  <PageNotFound v-else />
</template>

<script lang="ts">
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { Appointment } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { AppointmentDetailsParams, AppointmentDetailsRoute } from ".";

export default defineComponent({
  name: AppointmentDetailsRoute,
  components: { PageNotFound },
  computed: {
    appointment(): Appointment | null {
      const params: AppointmentDetailsParams = this.$route.params as any;

      const appointment = Appointment.$findOne(params.id as string);

      return appointment;
    },
  },
});
</script>
