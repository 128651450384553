<template>
  <div v-if="callLog">
    {{ callLog }}
  </div>
  <PageNotFound v-else />
</template>

<script lang="ts">
import { PageNotFound } from "@kinherit/framework/component.display/page-not-found";
import { CallLog } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { CallLogDetailsParams, CallLogDetailsRoute } from ".";

export default defineComponent({
  name: CallLogDetailsRoute,
  components: { PageNotFound },
  computed: {
    callLog(): CallLog | null {
      const params: CallLogDetailsParams = this.$route.params as any;

      const calllog = CallLog.$findOne(params.id as string);

      return calllog;
    },
  },
});
</script>
