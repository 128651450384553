import { Api } from "@kinherit/sdk/api";

export interface ReadInvoicesMessage {
  type: "KINHERIT" | "TRUREG";
  modifiedSince?: string;
  where?: string;
  order?: string;
  invoiceIds?: string[];
  invoiceNumbers?: string[];
  contactIds?: string[];
  statuses?: string[];
  page?: number;
  includeArchived?: boolean;
  createdByMyApp?: boolean;
  unitdp?: number;
  summaryOnly?: boolean;
}

export const ReadInvoicesHandler = async (message: ReadInvoicesMessage) => {
  return await Api.resource("external", "/v2/external/xero/invoices")
    .method("get")
    .params(message)
    .result();
};
