<template>
  <span>
    <strong>Overriding Powers</strong>
    <p>
      The trustees and to include any trustee appointed thereafter shall have
      the following powers
    </p>

    <ol type="a">
      <li>
        Power of Appointment
        <ol type="i">
          <li>
            The trustees may appoint that they shall hold any trust property for
            the benefit of any of the beneficiaries on such terms as the
            trustees think fit
          </li>
          <li>
            An appointment may create any provisions and in particular
            <br />
            <br />1) discretionary trusts <br />2) dispositive or administrative
            powers exercisable by any person
          </li>
          <li>
            An appointment shall be made by deed and may be revocable or
            irrevocable
          </li>
        </ol>
      </li>
      <li>
        To transfer the trust property to other trust(s)
        <ol type="i">
          <li>
            The trustees may by deed declare that they hold any trust property
            on trust to transfer it to trustees of another trust wherever
            established to hold on the terms of that trust freed and released
            from the terms of the previous Trust
          </li>
          <li>
            The trustees shall only exercise this power if
            <br />
            <br />1) every person who may benefit is (or would if living be) a
            beneficiary or <br />2) with the consent in writing of two
            beneficiaries
          </li>
        </ol>
      </li>
      <li>
        Power of advancement
        <ol type="i">
          <li>
            The trustees may pay or apply any trust property for the advancement
            or benefit of any of the beneficiaries
          </li>
        </ol>
      </li>
      <li>
        The overriding powers shall be exercisable only

        <ol type="i">
          <li>during the Trust Periods and</li>
          <li>
            at a time when there are at least two trustees, or the trustee is a
            company carrying on a business which consists of or includes the
            management of trusts, or when the power to appoint additional
            trustees cannot be exercised
          </li>
        </ol>
      </li>
    </ol>
    <p>
      The Trusts shall be governed by and subject to the law of England and
      Wales unless my address is in Scotland in which case the Trusts shall be
      governed by the law of Scotland or my address is in Northern Ireland in
      which case the Trusts shall be governed by the law of Northern Ireland
    </p>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
