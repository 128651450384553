import { Api } from "@/service/api.service";
import { IntroducerFeePaymentRun } from "@kinherit/sdk";

export interface RecordIntroducerFeePaymentRunMessage {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
}

export interface RecordIntroducerFeePaymentRunResponse {
  introducerFeePaymentRun: IntroducerFeePaymentRun;
}

export const RecordIntroducerFeePaymentRunHandler = async (
  message: RecordIntroducerFeePaymentRunMessage,
): Promise<RecordIntroducerFeePaymentRunResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-run/{introducerFeePaymentRun}",
    message,
  ).method("get");

  const response = await request.result();

  const introducerFeePaymentRun = IntroducerFeePaymentRun.$inflate(
    response.introducerFeePaymentRun,
  ).first();

  if (!introducerFeePaymentRun) {
    throw new Error("Failed to find introducerFeePaymentRun");
  }

  return {
    introducerFeePaymentRun,
  };
};
