import { KinvaultKintinDetailsUploadFormsBreadCrumb } from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const KintinDetailsUploadFormRoute = "KintinDetailsUploadForm";
export const KintinDetailsUploadFormURI =
  "/kinvault/:kintin/documents/upload-forms";
export type KintinDetailsUploadFormParams = RouteParamObject<
  typeof KintinDetailsUploadFormURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsUploadFormRoute,
    path: KintinDetailsUploadFormURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsUploadForm" */ "./KintinDetails.UploadForms.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsUploadFormsBreadCrumb,
    },
  },
];
