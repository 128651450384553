import { Api } from "@/service/api.service";
import { CreditNote, Kintin, Note, Order, Profile, User } from "@kinherit/sdk";

export interface RecordCreditNoteMessage {
  creditNote: CreditNote | string;
}

export interface RecordCreditNoteResponse {
  creditNote: CreditNote;
  notes: Array<Note>;
  users: Array<User>;
  profiles: Array<Profile>;
  orders: Array<Order>;
  kintins: Array<Kintin>;
}

export const RecordCreditNoteHandler = async (
  message: RecordCreditNoteMessage,
): Promise<RecordCreditNoteResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/credit-note/{creditNote}",
    {
      creditNote: message.creditNote,
    },
  ).method("get");
  const response = await request.result();

  const creditNote = CreditNote.$inflate(response.creditNote).first();

  if (!creditNote) {
    throw new Error("Failed to read credit note");
  }

  return {
    creditNote,
    notes: Note.$inflate(response.note),
    users: User.$inflate(response.user),
    profiles: Profile.$inflate(response.profile),
    orders: Order.$inflate(response.order),
    kintins: Kintin.$inflate(response.kintin),
  };
};
