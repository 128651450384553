import { Api } from "@kinherit/sdk/api";

type TReconcileAppointmentTypesResult = {
  id: number;
  name: string;
  active: string;
  matchedId: boolean;
  matchedGroup: string | null;
}[];

export const AcuityReconcileTypesAction =
  async (): Promise<TReconcileAppointmentTypesResult> => {
    const result = await Api.resource(
      "portal",
      "/v2/portal/appointment/reconcile-types",
    )
      .method("get")
      .result();
    return result;
  };
