import { Api, Kintin } from "@kinherit/sdk/index";

// export class ChangeKintinTypeHandler extends ActionBase {
//   constructor(private data: { kintin: Kintin; type: "single" | "joint" }) {
//     super();
//   }

//   async execute() {
export const ChangeKintinTypeHandler = async (data: {
  kintin: Kintin;
  type: "single" | "joint";
}): Promise<void> => {
  await Api.resource("portal", "/v2/portal/kintin/change-type")
    .method("put")
    .body({
      kintin: data.kintin.id,
      type: data.type,
    })
    .result();
};
