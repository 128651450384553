import { Api } from "@/service/api.service";
import { IntroducerFeePaymentItem } from "@kinherit/sdk";

export interface PatchIntroducerFeePaymentItemMessage {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export interface PatchIntroducerFeePaymentItemResponse {
  introducerFeePaymentItem: IntroducerFeePaymentItem;
}

export const PatchIntroducerFeePaymentItemHandler = async (
  message: PatchIntroducerFeePaymentItemMessage,
): Promise<PatchIntroducerFeePaymentItemResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment-item/{introducerFeePaymentItem}",
    message,
  )
    .method("patch")
    .body({
      amount: message.introducerFeePaymentItem.amount,
      name: message.introducerFeePaymentItem.name,
    })
    .result();

  const introducerFeePaymentItem = IntroducerFeePaymentItem.$inflate(
    repsonse.introducerFeePaymentItem,
  ).first();

  if (!introducerFeePaymentItem) {
    throw new Error("Failed to Patch introducerfeepaymentitem");
  }

  return {
    introducerFeePaymentItem: introducerFeePaymentItem,
  };
};
