import { Api } from "@/service/api.service";
import { IntroducerContact } from "@kinherit/sdk";

interface UpdateIntroducerContactMessage {
  introducerContact: IntroducerContact;
}

interface UpdateIntroducerContactResponse {
  introducerContact: IntroducerContact;
}

export const UpdateIntroducerContactHandler = async (
  message: UpdateIntroducerContactMessage,
): Promise<UpdateIntroducerContactResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/introducer-contact/{introducerContact}",
    {
      introducerContact: message.introducerContact,
    },
  )
    .method("patch")
    .body({
      newsLetter: message.introducerContact.$data.newsLetter,
      status: message.introducerContact.$data.status,
      callStatus: message.introducerContact.$data.callStatus,
    })

    .result();

  const introducerContact = IntroducerContact.$inflate(
    response.introducerContact,
  ).first();

  if (!introducerContact) {
    throw new Error("Failed to update contact");
  }

  return {
    introducerContact,
  };
};
