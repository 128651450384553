<template>
  <div v-if="kintin">
    <CallScriptSection title="Trustees">
      <b>
        OK, good, that’s Executors done.
        <br /><br />
        <span v-if="hasChildrenOrDependents">
          So, because you have dependents under 18, you’ll need trustees … and
          if you DID decide to go with the Estate Protection Trust which I
          mentioned before, you’ll need trustees for this as well.
        </span>
        <span v-else>
          You would need trustees if you have an Estate Protection trust… it may
          not be for you, but it’s important we lay out all the options and we
          can go through the benefits next time.
        </span>
        <br /><br />
        It usually starts with the same people you chose as Executors. Shall I
        put them down and we can adjust later if necessary?
      </b>
      <br /><br />
      <em>[if pushback to idea]</em>
      <b>
        <span v-if="hasChildrenOrDependents">
          <br />A trust is required if you are leaving to children under 18, and
          if trustees are not picked it will default to executors.
        </span>
        <span v-else>
          A trust is a really powerful way to protect money you leave, across
          generations. It may not be for you, but we think its important people
          understand the options.
        </span>
      </b>
      <br />
      <br /><em>[If they ask how much a trust is give this answer ]</em>
      <b>
        <br />It is more expensive - £1100 for one person and £1500 for a couple
        - but then it could save your family many times that in the long run.
        I’ll lay out pricing in the summary I send across after this call, so
        you see it properly.
      </b>
      <br /><br />
      <TrusteesContainer :kintin="kintin" />
      <b>
        I will explain this in more detail in the Report that follows this call,
        but we we use a similar concept for professional support that we do with
        executors, of giving your trusted people options but without restricting
        them from doing what they think is right.
      </b>
      <br /><br />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.4.2"
        :kintin="kintin"
        is-reversed
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.4.2a"
        :kintin="kintin"
        yes-label="Encouraged"
        no-label="Considered"
        maybe-label="Required"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.4.1"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advTrusteenNotes"
      :has-continue="false"
    />
  </div>
</template>

<script lang="ts">
import TrusteesContainer from "@/module/kinvault.kintin/component/address-book/TrusteesContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "TrusteesTab",
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    ConfirmCheck,
    CallScriptSection,
    CallScriptSpecialistNotesSection,
    TrusteesContainer,
  },
});
</script>
