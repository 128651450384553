import { RouteInterface } from "@kinherit/framework/core/route-config";
import { BrandedKinvaultMasterListBreadCrumb } from "../breadcrumbs";

import { Routes as BrandedKinvaultDetailsRoutes } from "./details";

export const BrandedKinvaultMasterListRoute = "BrandedKinvaultMasterList";
export const BrandedKinvaultPricingSchemaMasterListRoute =
  "BrandedKinvaultPricingSchemaMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultMasterListRoute,
    path: "/admin/branded-kinvaults",
    component: () =>
      import(
        /* webpackChunkName: "BrandedKinvaultMasterList" */ "./BrandedKinvaultMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [BrandedKinvaultMasterListBreadCrumb],
    },
  },
  {
    name: BrandedKinvaultPricingSchemaMasterListRoute,
    path: "/admin/branded-kinvaults/kinvault-pricing-schema",
    component: () =>
      import(
        /* webpackChunkName: "BrandedKinvaultPricingSchemaMasterList" */ "./BrandedKinvaultPricingSchemaMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [BrandedKinvaultMasterListBreadCrumb],
    },
  },
  ...BrandedKinvaultDetailsRoutes,
];
