import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsBreadCrumb } from "../../../breadcrumbs";

export const LeadDetailsCallScriptRoute = "LeadDetailsCallScript";
export const LeadDetailsCallScriptURI = "/lead/:lead/callscript";
export type LeadDetailsCallScriptParams = RouteParamObject<
  typeof LeadDetailsCallScriptURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsCallScriptRoute,
    path: LeadDetailsCallScriptURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsCallScript" */ "./LeadDetails.CallScript.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...LeadDetailsBreadCrumb(params),
        {
          text: "CallScript",
          route: { name: LeadDetailsCallScriptRoute, params },
        },
      ],
    },
  },
];
