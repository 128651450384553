import { Api } from "@/service/api.service";
import { EmailCampaignLog, EmailTemplate, FileLog } from "@kinherit/sdk";

export interface RecordEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog | string;
}

export interface RecordEmailCampaignLogResponse {
  emailCampaignLog: Array<EmailCampaignLog>;
  emailTemplate: Array<EmailTemplate>;
  fileLogs: Array<FileLog>;
}

export const RecordEmailCampaignLogHandler = async (
  message: RecordEmailCampaignLogMessage,
): Promise<RecordEmailCampaignLogResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/email-campaign-log/{emailCampaignLog}",
    message,
  ).method("get");
  const response = await request.result();

  return {
    emailCampaignLog: EmailCampaignLog.$inflate(
      response.emailCampaignLog,
      undefined,
      response.$rootIds,
    ),
    emailTemplate: EmailTemplate.$inflate(response.emailTemplate),
    fileLogs: FileLog.$inflate(response.fileLog),
  };
};
