import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { CpdDetailsParams } from "..";
import { CpdDetailsSummaryBreadCrumb } from "../../../breadcrumbs";

/** Summary */
export const CpdDetailsSummaryRoute = "CpdDetailsSummary";
const CpdDetailsSummaryURI = "/cpd/:cpdSession";
export type CpdDetailsSummaryParams = RouteParamObject<
  typeof CpdDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: CpdDetailsSummaryRoute,
    path: CpdDetailsSummaryURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsSummary" */ "./CpdDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        CpdDetailsSummaryBreadCrumb(params as CpdDetailsParams),
    },
  },
];
