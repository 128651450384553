import { Between, In, IsNotNull, Like } from "@kinherit/orm/index";
import {
  Api,
  ISubscription,
  Kintin,
  Product,
  QueryMask,
  Subscription,
} from "@kinherit/sdk";

export interface ReadSubscriptionMessage {
  search?: string;
  status?: Array<string>;
  created?: null | [number, number];
  hasFiles?: boolean | null;
  query?: QueryMask<typeof Subscription>;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof ISubscription;
    direction: "asc" | "desc";
  };
}

interface ReadSubscriptionResponse {
  subscriptions: Array<Subscription>;
  kintins: Array<Kintin>;
  products: Array<Product>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadSubscriptionHandler = async (
  message: ReadSubscriptionMessage,
): Promise<ReadSubscriptionResponse> => {
  const request = Api.resource("portal", "/v2/portal/subscription")
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(Subscription).where({
    kintin: {
      friendlyName: Like(message.search),
    },
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    files: {
      id: message.hasFiles ? IsNotNull() : undefined,
    },
    ...(message.query ?? {}),
  });

  const response = await request.result();

  return {
    subscriptions: Subscription.$inflate(
      response.subscription,
      message.sort,
      response.$rootIds,
    ),
    kintins: Kintin.$inflate(response.kintin),
    products: Product.$inflate(response.product),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
