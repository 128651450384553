import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsContactsRoute =
  "IntroducerCompanyDetailsContacts";
export const IntroducerCompanyDetailsContactsURI =
  "/introducer/company/:introducerCompany/contacts";
export type IntroducerCompanyDetailsContactsParams = RouteParamObject<
  typeof IntroducerCompanyDetailsContactsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsContactsRoute,
    path: IntroducerCompanyDetailsContactsURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsContacts" */ "./IntroducerCompanyDetails.Contacts.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Contacts",
          route: {
            name: IntroducerCompanyDetailsContactsRoute,
            params,
          },
        },
      ],
    },
  },
];
