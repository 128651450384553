import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormRichTextField } from "@kinherit/framework/component.input/rich-text-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Check, KintinCheck, KintinCheckFollowUp } from "@kinherit/sdk";

export const UpdateKintinCheckFollowUpForm = (data: KintinCheckFollowUp) =>
  defineForm({
    name: "update-follow-up-item-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-follow-up-item-form-area",
        data,
        template: GridLayout([["name", "section"], ["notes"], ["dueOn"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: "Name",
              },
              models: {
                value: "note.name",
              },
            }),
          ],
          section: [
            FormAutoCompleteField({
              props: {
                label: "Section",
                options: KintinCheck.$all(),
                mapOptions: {
                  value: "id",
                  label: "name",
                },
                reference: "section",
              },
              models: {
                value: {
                  get: (data) => Check.$findOneBy({ step: data.step }),
                  set: (value: Check, data) => {
                    data.step = value.step;
                  },
                },
              },
            }),
          ],
          notes: [
            FormRichTextField({
              props: {
                label: "Notes",
              },
              models: {
                value: "note.notes",
              },
            }),
          ],
          dueOn: [
            FormDateField({
              props: {
                label: "Due On",
              },
              models: {
                value: "note.dueAt",
              },
            }),
          ],
        }),
      }),
    ],
  });
