import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormCheckboxField } from "@kinherit/framework/component.input/checkbox-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadIntroducerCompanyFeesForm = () =>
  MasterListFiltersForm({
    name: "filter-introducer-company-fees-form",
    data: () => ({
      created: null as null | [number, number],
      feeTypeId: null as null | "introducerFee" | "agentFee" | "parentAgentFee",
      thisCompanyOnly: false,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      feeTypeId: [
        FormSelectField({
          props: {
            label: "Fee Type",
            options: {
              any: "Any",
              introducerFee: "Introducer Fee",
              agentFee: "Agent Fee",
              parentAgentFee: "Parent Agent Fee",
            },
            reference: "feeTypeId",
          },
          models: {
            value: {
              get: (data) => data.feeTypeId || "any",
              set: (value, data) => {
                data.feeTypeId = value === "any" ? null : value;
              },
            },
          },
        }),
      ],
      thisCompanyOnly: [
        FormCheckboxField({
          props: {
            label: "This Company Only",
          },
          models: {
            value: "thisCompanyOnly",
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
