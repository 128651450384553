<template>
  <div class="kintin-details-address-book-charities">
    <PageHeader htag="h2" text="Properties">
      <template #buttons>
        <Button
          text="Add Property"
          @click="createProperty"
          class="add-button"
        />
      </template>
    </PageHeader>
    <div>
      <PropertyList
        :kintin="kintin"
        :properties="$data.filtered.properties"
        @reload="filter"
      />

      <Json v-if="isDevMode" :data="filtered" />
    </div>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "PropertiesContainerWrapper",
  "selector": ".kintin-details-address-book-properties",
  "imports": {
    "PropertyListWrapper": "@/module/kinvault.kintin/component/lists/PropertyList.test",
    "UpdatePropertyFormWrapper": "@/module/kinvault.kintin/form/update-property.form.test"
  },
  "methods": {
    "propertyList": {
      "type": "to-one",
      "selector": ".property-list",
      "wrapper": "PropertyListWrapper"
    },
    "addProperty": {
      "type": "click",
      "selector": ".add-button"
    },
    "addPropertyForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdatePropertyFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateProperty } from "@/config/model.config";
import { Json } from "@kinherit/framework/component.display/json";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { Kintin, Property } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { UpdatePropertyForm } from "../../form/update-property.form";
import PropertyList from "../lists/PropertyList.vue";

export default defineComponent({
  name: "PropertiesContainer",
  components: {
    Json,
    Button,
    PageHeader,
    PropertyList,
  },
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  emits: ["reload", "update:adv-props-value"],
  data: () => ({
    filtered: {
      properties: [] as Property[],
    },
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  mounted(): void {
    this.filter();
  },
  methods: {
    filter(): void {
      Object.keys(this.filtered).forEach((key) => {
        (this.filtered as any)[key] = [];
      });

      this.filtered.properties = Property.$findBy({
        kintin: {
          id: this.kintin?.id,
        },
      });

      this.$emit("reload");
    },
    async createProperty() {
      if (!this.kintin) {
        return;
      }

      const property = CreateProperty();

      try {
        await UpdatePropertyForm({
          property,
          kintin: this.kintin,
        }).dialog({
          dialog: {
            title: "Create Property",
          },
        });
      } catch {
        property.$delete();
        return;
      }

      // await window.Kernel.ActionBus.execute("kinvault/kintin/property/create", {
      //   property,
      //   kintin: this.kintin.id,
      // });

      await window.Kernel.ActionBus.kinvaultKintin.property.create({
        property,
        kintin: this.kintin,
      });

      this.filter();
    },
  },
});
</script>
