import { Api } from "@/service/api.service";
import { BrandedKinvaultInvitation, Credential } from "@kinherit/sdk";
import { Uuid } from "@kinherit/ts-common/index";

interface CreateBrandedKinvaultInvitationMessage {
  firstName: string;
  lastName: string;
  emailAddress: string;
  reference: null | string;
  referralCode: null | string;
  adviser: null | string;
  sendInvitationEmail: null | boolean;
  createdBy: Credential;
  brandedKinvaultOverride: null | string; // @todo
  brandedKinvault: string;
}

interface CreateBrandedKinvaultInvitationResponse {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export const CreateBrandedKinvaultInvitationHandler = async (
  message: CreateBrandedKinvaultInvitationMessage,
): Promise<CreateBrandedKinvaultInvitationResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/invitation",
  )
    .method("post")

    .body({
      invite: {
        profile: {
          firstName: message.firstName,
          lastName: message.lastName,
          emails: [{ email: message.emailAddress, primary: true }],
        },
        signUpToken: Uuid.generate(),
        status: "notsent",
        brandedKinvault: message.brandedKinvault,
        createdBy: message.createdBy.id,
        introducerContact: message.adviser,
        externalRef: message.reference,
      },
      referralCode: message.referralCode,
    })
    .result();

  const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
    response.brandedKinvaultInvitation,
  ).first();

  if (!brandedKinvaultInvitation) {
    throw new Error("BrandedKinvaultInvitation not found");
  }

  if (message.sendInvitationEmail) {
    await Api.resource(
      "portal",
      `/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}`,
      {
        brandedKinvaultInvitation: brandedKinvaultInvitation,
        invitationType: "initial",
      },
    )
      .method("put")

      .result();
  }

  return {
    brandedKinvaultInvitation,
  };
};
