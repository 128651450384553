import { Api } from "@/service/api.service";
import { CpdSessionRegistration } from "@kinherit/sdk";

interface UpdateCpdSessionRegistrationMessage {
  cpdSessionRegistration: CpdSessionRegistration;
}

interface UpdateCpdSessionRegistrationResponse {
  cpdSessionRegistration: CpdSessionRegistration;
}

export const UpdateCpdSessionRegistrationHandler = async (
  message: UpdateCpdSessionRegistrationMessage,
): Promise<UpdateCpdSessionRegistrationResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/cpd-session/cpd-session-registration/{cpdSessionRegistration}",
    {
      cpdSessionRegistration: message.cpdSessionRegistration,
    },
  )
    .method("patch")

    .body({
      isMatched: message.cpdSessionRegistration.$data.isMatched,
      session: message.cpdSessionRegistration.$data.session,
    })
    .result();

  const cpdSessionRegistration = CpdSessionRegistration.$inflate(
    response.cpdSessionRegistration,
  ).first();

  if (!cpdSessionRegistration) {
    throw new Error("Failed to update cpd session registration");
  }

  return { cpdSessionRegistration };
};
