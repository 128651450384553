import { DangerDialog } from "@/config/dialog.config";
import {
  EmailAddressField,
  EmailTemplateField,
  OptionsAutoCompleteField,
} from "@/config/form.config";
import MarkDownEditor from "@/module/core/component/markdown-editor/MarkDownEditor.vue";
import { AuthService } from "@/service/auth.service";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormFileField } from "@kinherit/framework/component.input/file-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import { FormComponentWrapper } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal, JsonIn } from "@kinherit/orm";
import {
  BrandedKinvault,
  EmailAddress,
  EmailLog,
  EmailTemplate,
  FileLog,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Lead,
  Option,
  Person,
} from "@kinherit/sdk";

type Data = {
  emailLog: EmailLog;
  files: Array<File>;
  requiredFiles: Record<string, File>;
};

const kintinToLine = (
  emailAddress: EmailAddress,
  kintin: Kintin,
  line: 1 | 2,
) => {
  const person = emailAddress.profile?.person;

  if (!person) {
    return undefined;
  }

  const info: Array<string> = [];

  const targetPerson =
    line === 1 ? kintin.$data.primaryPerson : kintin.$data.secondaryPerson;

  if (line === 1 && person.relationToPrimaryPerson !== null) {
    info.push(person.relationToPrimaryPerson.text);
  } else if (line === 2 && person.relationToSecondaryPerson !== null) {
    info.push(person.relationToSecondaryPerson.text);
  }

  person.trusteeFor.forEach((role) => {
    if (role.forPerson.id !== targetPerson) {
      return;
    }

    info.push(role.$name);
  });
  person.executorFor.forEach((role) => {
    if (role.forPerson?.id !== targetPerson) {
      return;
    }

    info.push(role.$name);
  });
  person.guardianFor.forEach((role) => {
    if (role.forPerson.id !== targetPerson) {
      return;
    }

    info.push(role.$name);
  });
  person.beneficiaryFor.forEach((role) => {
    if (role.forPerson.id !== targetPerson) {
      return;
    }

    info.push(role.$name);
  });
  person.attorneyFor.forEach((role) => {
    if (role.forPerson.id !== targetPerson) {
      return;
    }

    info.push(role.$name);
  });
  person.adviserFor.forEach((role) => {
    if (role.forPerson.id !== targetPerson) {
      return;
    }

    info.push(role.$name);
  });

  if (info) {
    return info.join(", ");
  }

  return undefined;
};

interface EmailLogData {
  leadfirstname: null | string;
  estateplanner: null | string;
  outreach: null | string;
  recipients: null | string;
  aboutkinherit: null | string;
  trname: null | string;
  outreachnumber: null | string;
  introducername: null | string;
  introducercompany: null | string;
  officername: null | string;
  introducerfirstname: null | string;
  ktref: null | string;
  leadname: null | string;
  testatorfullname: null | string;
  testatorfirstname: null | string;
  yourOrName: null | string;
  trref: null | string;
  trpin: null | string;
  primaryfirstname: null | string;
  primaryfullname: null | string;
  secondaryfirstname: null | string;
  secondaryfullname: null | string;
  currentuser: null | string;
  estateplannerfirstname: null | string;
  kinvaultloginurl: null | string;
}

export const UpdateEmailLogForm = async ({
  emailAddresses,
  emailLog,
  owner,
  isNew,
}: {
  emailAddresses: Array<EmailAddress>;
  emailLog: EmailLog;
  owner:
    | Kintin
    | Person
    | IntroducerCompany
    | IntroducerContact
    | BrandedKinvault
    | Lead;
  isNew: boolean;
}) => {
  emailAddresses = emailAddresses.clone();
  const user = AuthService.loggedInUser;

  const isKinvault = owner instanceof Kintin;
  const isLeads = owner instanceof Lead;
  const isOfficer = owner instanceof Person;
  const isIntroducers =
    owner instanceof IntroducerCompany || owner instanceof IntroducerContact;
  const isBrandedKinvault = owner instanceof BrandedKinvault;

  const templateGroups = Array<string>();

  switch (true) {
    case isKinvault:
      templateGroups.push("kinvault");
      templateGroups.push("officer");
      break;
    case isLeads:
      templateGroups.push("leads");
      break;
    case isOfficer:
      templateGroups.push("officer");
      break;
    case isIntroducers:
      templateGroups.push("introducers");
      break;
    case isBrandedKinvault:
      templateGroups.push("organisation");
      break;
  }

  const isDraft = emailLog.status === "draft";

  const localData = isNew
    ? {
        leadfirstname: null as null | string,
        estateplanner: null as null | string,
        outreach: null as null | string,
        recipients: null as null | string,
        aboutkinherit: null as null | string,
        trname: null as null | string,
        outreachnumber: null as null | string,
        introducername: null as null | string,
        introducercompany: null as null | string,
        officername: null as null | string,
        introducerfirstname: null as null | string,
        ktref: null as null | string,
        leadname: null as null | string,
        testatorfullname: null as null | string,
        testatorfirstname: null as null | string,
        yourOrName: null as null | string,
        trref: null as null | string,
        trpin: null as null | string,
        primaryfirstname: null as null | string,
        primaryfullname: null as null | string,
        secondaryfirstname: null as null | string,
        secondaryfullname: null as null | string,
        currentuser: user?.profile.fullName ?? null,
        estateplannerfirstname: null as null | string,
        kinvaultloginurl: null as null | string,
      }
    : (emailLog.data as never as EmailLogData);

  let toEmailAddresses = emailAddresses;

  // Populate the local data with the owner's details if the email log is new

  // If LEAD
  if ((isNew || isDraft) && owner instanceof Lead) {
    localData.recipients = owner.profile.fullName;
    localData.leadfirstname = owner.profile.knownAs ?? owner.profile.firstName;
    if (owner.ownedBy !== null) {
      localData.estateplanner = owner.ownedBy.profile.fullName;
      localData.estateplannerfirstname = owner.ownedBy.profile.firstName;
    }
    localData.leadname = owner.profile.fullName;

    localData.introducercompany =
      owner.referral?.referralCode?.company?.profile?.organisationName ?? null;
    localData.introducername =
      owner.referral?.contact?.profile.fullName ?? null;
    localData.introducerfirstname =
      owner.referral?.contact?.profile.firstName ?? null;
  } else if ((isNew || isDraft) && owner instanceof Kintin) {
    localData.introducercompany =
      owner.referral?.referralCode?.company?.profile?.organisationName ?? null;
    localData.introducername =
      owner.referral?.contact?.profile.fullName ?? null;
    localData.introducerfirstname =
      owner.referral?.contact?.profile.firstName ?? null;

    const sort = (a: EmailAddress, b: EmailAddress) => {
      const aPerson = a.profile?.person;
      const bPerson = b.profile?.person;

      if (!aPerson || !bPerson) {
        return 0;
      }

      const aPrimary = aPerson.id === owner.$data.primaryPerson;
      const bPrimary = bPerson.id === owner.$data.primaryPerson;

      if (aPrimary && !bPrimary) {
        return -1;
      }

      if (!aPrimary && bPrimary) {
        return 1;
      }

      const aSecondary = aPerson.id === owner.$data.secondaryPerson;
      const bSecondary = bPerson.id === owner.$data.secondaryPerson;

      if (aSecondary && !bSecondary) {
        return -1;
      }

      if (!aSecondary && bSecondary) {
        return 1;
      }

      return 0;
    };

    emailAddresses = emailAddresses.sort(sort);

    toEmailAddresses = emailAddresses.filter((emailAddress) => {
      const person = emailAddress.profile?.person;

      if (person?.id === owner.$data.primaryPerson) {
        return true;
      }

      if (person?.id === owner.$data.secondaryPerson) {
        return true;
      }

      if (emailAddress.profile?.introducerContact) {
        return true;
      }

      return false;
    });

    toEmailAddresses = toEmailAddresses.sort(sort);

    if (owner.estatePlanners.length > 0) {
      localData.estateplanner = owner.estatePlanners
        .map((ep) => ep.profile.fullName)
        .join(", ", " or ");
      localData.estateplannerfirstname = owner.estatePlanners
        .map((ep) => ep.profile.firstName)
        .join(", ", " or ");
    }

    if (owner.type.value === "joint") {
      localData.recipients = `${
        owner.primaryPerson.profile.knownAs ??
        owner.primaryPerson.profile.firstName
      } and ${
        owner.secondaryPerson?.profile.knownAs ??
        owner.secondaryPerson?.profile.firstName
      }`;
    } else {
      localData.recipients =
        owner.primaryPerson.profile.knownAs ??
        owner.primaryPerson.profile.firstName;
    }

    localData.ktref = owner.ref;
    localData.primaryfirstname = owner.primaryPerson?.profile?.firstName;
    localData.primaryfullname = owner.primaryPerson?.profile?.fullName;
    localData.secondaryfirstname =
      owner.secondaryPerson?.profile?.firstName ?? null;
    localData.secondaryfullname =
      owner.secondaryPerson?.profile?.fullName ?? null;

    localData.testatorfullname = owner.friendlyName ?? null;

    if (owner.type.value === "joint") {
      localData.testatorfirstname = `${
        owner.primaryPerson.profile.knownAs ??
        owner.primaryPerson.profile.firstName
      } and ${
        owner.secondaryPerson?.profile.knownAs ??
        owner.secondaryPerson?.profile.firstName
      }`;
    } else {
      localData.testatorfirstname =
        owner.primaryPerson.profile.knownAs ??
        owner.primaryPerson.profile.firstName;
    }

    const code = owner.referral?.referralCode?.code;
    let preferredReferralCode = null;
    if (code) {
      // const {
      //   defaultBrandedKinvaultReferralCodes: defaultOrgnaisationReferralCodes,
      // } = await window.Kernel.ActionBus.execute(
      //   "core/select/default-branded-kinvault-referral-codes/read",
      //   {
      //     query: {
      //       code: Equal(code),
      //     },
      //   },
      // );

      const {
        defaultBrandedKinvaultReferralCodes: defaultOrgnaisationReferralCodes,
      } =
        await window.Kernel.ActionBus.core.select.defaultBrandedKinvaultReferralCodes.read(
          {
            query: {
              code: Equal(code),
            },
          },
        );

      preferredReferralCode = defaultOrgnaisationReferralCodes.first() ?? null;
    }
    localData.kinvaultloginurl = preferredReferralCode?.brandedKinvault
      ?.frontendUrl
      ? "https://" + preferredReferralCode?.brandedKinvault?.frontendUrl
      : "https://app.kinvault.com";
  } else if ((isNew || isDraft) && owner instanceof IntroducerCompany) {
    localData.introducername =
      owner.profile.fullName ?? owner.profile.organisationName;
    localData.introducercompany =
      owner.profile.organisationName ?? owner.profile.fullName;
  } else if ((isNew || isDraft) && owner instanceof IntroducerContact) {
    localData.introducername =
      owner.profile.fullName ?? owner.profile.organisationName;
    localData.introducercompany =
      owner.companies?.first()?.profile.organisationName ??
      owner.companies?.first()?.profile.fullName ??
      null;
    localData.introducerfirstname = owner.profile.firstName;
  } else if ((isNew || isDraft) && owner instanceof Person) {
    localData.officername = owner.profile.fullName;
    localData.recipients = owner.profile.knownAs ?? owner.profile.fullName;
    localData.testatorfullname = owner.kintin?.friendlyName ?? null;

    if (owner.kintin?.type.value === "joint") {
      localData.testatorfirstname = `${
        owner.kintin.primaryPerson.profile.knownAs ??
        owner.kintin.primaryPerson.profile.firstName
      } and ${
        owner.kintin.secondaryPerson?.profile.knownAs ??
        owner.kintin.secondaryPerson?.profile.firstName
      }`;
    } else {
      localData.testatorfirstname =
        owner.kintin?.primaryPerson.profile.knownAs ??
        owner.kintin?.primaryPerson.profile.firstName ??
        "";
    }
  }

  // Set the from email address to the user's primary email address
  if (isNew) {
    const from = user?.profile.emails.find(
      (emailAddress) => emailAddress.primary,
    );

    if (from) {
      emailLog.from = from;
    }
    emailLog.status = "draft";
    emailLog.data = localData as never as Record<string, unknown>;
  }

  const activeUserEmailAddress = user?.profile.primaryEmailAddress;

  if (activeUserEmailAddress) {
    emailAddresses.push(activeUserEmailAddress);
  }

  return defineForm({
    name: "create-email-log-form",
    props: {
      state: emailLog.status === "draft" ? undefined : "disabled",
    },
    data: () =>
      ({
        emailLog: emailLog as EmailLog,
        files: Array<File>(),
        requiredFiles: {} as Record<string, File>,
      }) as Data,
    formAreas: (data) => [
      defineFormArea({
        name: "create-email-log-form-area",
        data,
        template: GridLayout([
          ["from"],
          ["to"],
          ["cc", "bcc"],
          ["template"],
          {
            is: "hr",
          },
          ["badges"],
          ["preloadedFiles", "attachments"],
          ["requiredFields"],
          ["subject"],
          ["body"],
        ]),
        components: (data) => ({
          from: [
            EmailAddressField({
              props: {
                label: "From",
                validators: ["required"],
                isMultiSelect: false,
                forceDialog: false,
                reference: "emailAddress",
                vModel: {
                  get: (data) => data.emailLog.from,
                  set: (value: EmailAddress | null, data, controls) => {
                    data.emailLog.from = value as EmailAddress;

                    controls.incrementFormRenderKey();
                  },
                },
              },
              query: {
                profile: {
                  user: {
                    displayUserInPortal: Equal(true),
                  },
                },
              },
            }),
          ],
          to: [
            EmailAddressField({
              props: {
                label: "To",
                isMultiSelect: true,
                validators: ["required"],
                reference: "toEmailAddresses",
                vModel: {
                  get: (data) => data.emailLog.to,
                  set: (value: Array<EmailAddress>, data, controls) => {
                    data.emailLog.to = value;
                    localData.recipients = value
                      .map(
                        (emailAddress) =>
                          emailAddress.profile?.knownAs ??
                          emailAddress.profile?.firstName,
                      )
                      .join(", ", " and ");

                    if (owner instanceof Person) {
                      localData.recipients = value
                        .map((emailAddress) => emailAddress.profile?.fullName)
                        .join(", ", " and ");
                    }

                    controls.rebuildForm();
                  },
                },
                sort: () => (a: EmailAddress, b: EmailAddress) => {
                  const isPrimaryA =
                    isKinvault &&
                    a.profile?.person?.id === owner.$data.primaryPerson;
                  const isPrimaryB =
                    isKinvault &&
                    b.profile?.person?.id === owner.$data.primaryPerson;

                  if (isPrimaryA && !isPrimaryB) {
                    return -1;
                  }

                  if (!isPrimaryA && isPrimaryB) {
                    return 1;
                  }

                  const isSecondaryA =
                    isKinvault &&
                    a.profile?.person?.id === owner.$data.secondaryPerson;
                  const isSecondaryB =
                    isKinvault &&
                    b.profile?.person?.id === owner.$data.secondaryPerson;

                  if (isSecondaryA && !isSecondaryB) {
                    return -1;
                  }

                  if (!isSecondaryA && isSecondaryB) {
                    return 1;
                  }

                  return 0;
                },
              },
              optionLine1Field: (value: any) => {
                if (isKinvault) {
                  const kintin = owner as Kintin;
                  const person = (value as EmailAddress).profile?.person;

                  if ((value as EmailAddress).profile?.introducerContact) {
                    return "Introducer Contact";
                  }

                  if (person?.id === kintin.$data.primaryPerson) {
                    return "Primary Person";
                  }

                  if (person?.id === kintin.$data.secondaryPerson) {
                    return "Secondary Person";
                  }

                  const result = kintinToLine(
                    value as EmailAddress,
                    owner as Kintin,
                    1,
                  );

                  if (result) {
                    return `Primary: ${result}`;
                  }
                }

                if (isLeads) {
                  const profile = (value as EmailAddress).profile;
                  const lead = owner as Lead;

                  if (null !== (profile?.introducerContact ?? null)) {
                    return `Introducer Contact`;
                  }

                  if (null !== (profile?.introducerCompany ?? null)) {
                    return `Introducer Company`;
                  }

                  if (lead.assignedTo?.$data.profile === profile?.id) {
                    return "Estate Planner";
                  }

                  if (lead.profile?.id === profile?.id) {
                    return "Lead";
                  }

                  return "Unknown";
                }
              },
              optionLine2Field: (value: any) => {
                if (isKinvault) {
                  const result = kintinToLine(
                    value as EmailAddress,
                    owner as Kintin,
                    2,
                  );

                  if (result) {
                    return `Secondary: ${result}`;
                  }
                }
              },
              options: toEmailAddresses,
            }),
          ],
          cc: [
            EmailAddressField({
              props: {
                isMultiSelect: true,
                label: "Cc",
                vModel: "emailLog.cc",
              },
              options: emailAddresses,
            }),
          ],
          bcc: [
            EmailAddressField({
              props: {
                isMultiSelect: true,
                label: "Bcc",
                vModel: "emailLog.bcc",
              },
              options: emailAddresses,
            }),
          ],
          template: [
            EmailTemplateField({
              props: {
                label: "Template",
                validators: ["required"],
                showClearButton: false,
                reference: "template",
                vModel: {
                  get: (data) => data.emailLog.template,
                  set: (value: EmailTemplate, data, controls) => {
                    data.emailLog.template = value;

                    data.emailLog.subject = null;
                    data.emailLog.title = value.name;
                    data.emailLog.markdown = value.body;

                    data.emailLog.preloadedFiles.forEach(
                      (preloadedEmailAttachment) =>
                        data.emailLog.removePreloadedFiles(
                          preloadedEmailAttachment,
                        ),
                    );
                    value.preloadedFiles.forEach((preloadedEmailAttachment) =>
                      data.emailLog.addPreloadedFiles(preloadedEmailAttachment),
                    );

                    data.emailLog.attachments.forEach((attachment) =>
                      data.emailLog.removeAttachments(attachment),
                    );

                    controls.rebuildForm();
                    controls.incrementFormRenderKey();
                  },
                },
              },
              query: {
                type: {
                  data: JsonIn({
                    search: templateGroups,
                    path: "module",
                  }),
                },
              },
              limit: null,
            }),
          ],
          badges: [
            FormElement({
              props: {
                vIf: (data) => !!data.emailLog.template,
                html: (data) => `
                <div class="badge is-outlined is-small is-${
                  data.emailLog.template?.allowAttachments
                    ? "success"
                    : "danger"
                }">
                  Attachments: ${
                    data.emailLog.template?.allowAttachments
                      ? "Enabled"
                      : "Disabled"
                  }
                </div>
                <div class="badge is-outlined is-small is-${
                  data.emailLog.template?.strictAttachments
                    ? "success"
                    : "danger"
                }">
                    Strict Attachments:
                    ${
                      data.emailLog.template?.strictAttachments
                        ? "Enabled"
                        : "Disabled"
                    }
                  </div>
                ${
                  data.emailLog.template?.requiredAttachments.isNotEmpty()
                    ? `<div class="badge is-outlined is-small is-success">
                    Required Attachments: ${data.emailLog.template?.requiredAttachments.length}
                  </div>`
                    : ""
                }
                ${
                  data.emailLog.template?.preloadedFiles.isNotEmpty()
                    ? `<div class="badge is-outlined is-small is-success">
                    Preloaded Attachments: ${data.emailLog.template?.preloadedFiles.length}
                  </div>`
                    : ""
                }
                `,
              },
            }),
          ],
          preloadedFiles: [
            OptionsAutoCompleteField({
              group: "preloadedEmailAttachments",
              vModel: {
                get: (data) => data.emailLog.preloadedFiles,
                set: (value: Array<Option>, data, controls) => {
                  value?.forEach((preloadedEmailAttachment) => {
                    if (
                      !data.emailLog.$data.preloadedFiles.includes(
                        preloadedEmailAttachment.id,
                      )
                    ) {
                      data.emailLog.addPreloadedFiles(preloadedEmailAttachment);
                    }
                  });

                  data.emailLog.preloadedFiles.forEach(
                    (preloadedEmailAttachment) => {
                      if (
                        !value.pluck("id").includes(preloadedEmailAttachment.id)
                      ) {
                        data.emailLog.removePreloadedFiles(
                          preloadedEmailAttachment,
                        );
                      }
                    },
                  );

                  controls.rebuildForm();
                },
              },
              props: {
                vIf: (data) => !!data.emailLog.template,
                isMultiSelect: true,
                reference: "preloadedFiles",
              },
            }),
          ],
          attachments: [
            FormFileField({
              props: {
                vIf: (data) =>
                  null !== data.emailLog.template &&
                  data.emailLog.template.allowAttachments,
                placeholder: `${
                  data.emailLog.template?.requiredAttachments.length !== 0
                    ? "Optional: Additional"
                    : "Add"
                } Attachments`,
                reference: "attachments",
                isCompact: true,
              },
              models: {
                value: {
                  get: (data) => {
                    return [
                      ...emailLog.attachments.map((attachment) =>
                        attachment.toBrowserFile(),
                      ),
                      ...data.files,
                    ];
                  },
                  set: async (value: Array<File>, data) => {
                    const missing = Array<FileLog>();
                    const providedFiles = Array<FileLog>();

                    for (const file of value) {
                      const fileLog = await FileLog.fromBrowserFile(file);

                      if (fileLog) {
                        providedFiles.push(fileLog);
                      }
                    }

                    for (const fileLog of data.emailLog.attachments) {
                      if (!providedFiles.pluck("id").includes(fileLog.id)) {
                        missing.push(fileLog);
                      }
                    }

                    missing.forEach((fileLog) =>
                      data.emailLog.removeAttachments(fileLog),
                    );

                    data.files = await value.filterAsync(
                      async (file) =>
                        (await FileLog.fromBrowserFile(file)) === null,
                    );
                  },
                },
              },
            }),
            ...(data.emailLog.template?.requiredAttachments.map(
              (requiredAttachments) =>
                FormFileField<Data>({
                  props: {
                    placeholder: `Required: ${requiredAttachments}`,
                    validators: [
                      "required",
                      [
                        "file-limit",
                        {
                          numberOfFiles: 1,
                        },
                      ],
                    ],
                    isCompact: true,
                    isSingle: true,
                    class: "mt-1",
                    reference: "requiredFiles",
                  },
                  models: {
                    value: {
                      get: (data) => {
                        const namedAttachment =
                          data.emailLog.namedAttachments.find(
                            (namedAttachment) =>
                              namedAttachment.name === requiredAttachments,
                          );

                        if (namedAttachment) {
                          return [namedAttachment.file.toBrowserFile()];
                        }

                        return data.requiredFiles[requiredAttachments]
                          ? [data.requiredFiles[requiredAttachments]]
                          : [];
                      },
                      set: (value: Array<File>, data, controls) => {
                        const file = value.first();

                        if (!file) {
                          delete data.requiredFiles[requiredAttachments];

                          const namedAttachment =
                            data.emailLog.namedAttachments.find(
                              (namedAttachment) =>
                                namedAttachment.name === requiredAttachments,
                            );

                          if (namedAttachment) {
                            data.emailLog.removeNamedAttachments(
                              namedAttachment,
                            );
                            namedAttachment.file.$delete();
                            namedAttachment.$delete();
                          }

                          return;
                        } else if (
                          data.emailLog.template?.strictAttachments &&
                          "ref" in owner &&
                          !file.name
                            .toLowerCase()
                            .includes(owner.ref.toLowerCase())
                        ) {
                          DangerDialog({
                            dialog: {
                              title: "Strict Attachments",
                              message: `Strict attachments are enabled for this email template. Only files with the reference "${owner.ref}" in the name can be attached to this email.`,
                            },
                            button: {
                              cancel: {
                                show: false,
                              },
                            },
                          });

                          controls.incrementFormRenderKey();

                          return;
                        } else if (FileLog.isFromFileLog(file)) {
                          console.log(file);
                          return;
                        }

                        data.requiredFiles[requiredAttachments] = file;
                      },
                    },
                  },
                }),
            ) ?? []),
          ],
          body: [
            FormComponentWrapper(MarkDownEditor)({
              props: {
                vIf: (data) => !!data?.emailLog.template,
                subjectTemplate: (data: Data) =>
                  data.emailLog.subject ?? data.emailLog.template?.subject,
                bodyTemplate: (data: Data) =>
                  data.emailLog.markdown ?? data.emailLog.template?.body,
                params: () => localData as any,
              },
              models: {
                title: "emailLog.subject",
                body: "emailLog.markdown",
              },
            }),
            FormElement({
              props: {
                vIf: (data) => !data.emailLog.template && !!data.emailLog.html,
                html: (data) => data.emailLog.html,
              },
            }),
          ],
        }),
      }),
    ],
  });
};
