import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, In, IsNotNull, IsNull, Like } from "@kinherit/orm/index";
import {
  Api,
  IPhysicalStorageFileLog,
  Kintin,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Subscription,
} from "@kinherit/sdk";

export interface ReadStorageRequestMessage {
  name?: string;
  complete?: boolean | null;
  logAction: string[];
  created?: [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IPhysicalStorageFileLog;
    direction: "asc" | "desc";
  };
}

interface ReadStorageRequestResponse {
  physicalStorageFiles: Array<PhysicalStorageFile>;
  subscriptions: Array<Subscription>;
  kintins: Array<Kintin>;
  physicalStorageFileLogs: Array<PhysicalStorageFileLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadStorageRequestHandler = async (
  message: ReadStorageRequestMessage,
): Promise<ReadStorageRequestResponse> => {
  const request = Api.resource("portal", "/v2/portal/storage-request")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(PhysicalStorageFileLog).where({
    logAction: {
      id: In(message.logAction),
    },
    file: {
      subscription: {
        kintin: {
          id: IsNotNull(),
          friendlyName: Like(message.name),
        },
      },
    },
    completedAt:
      null === message.complete || undefined === message.complete
        ? undefined
        : true === message.complete
          ? IsNotNull()
          : IsNull(),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    physicalStorageFiles: PhysicalStorageFile.$inflate(
      response.physicalStorageFile,
    ),
    subscriptions: Subscription.$inflate(response.subscription),
    kintins: Kintin.$inflate(response.kintin),
    physicalStorageFileLogs: PhysicalStorageFileLog.$inflate(
      response.physicalStorageFileLog,
      message.sort,
      response.$rootIds,
    ),
    pagination: getPagination(response),
  };
};
