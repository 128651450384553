import { BreadCrumbConfig } from "@kinherit/framework/component.layout/breadcrumbs/types";
import { Order } from "@kinherit/sdk";
import { OrderDetailsParams } from "./page/details";

export const OrderMasterListBreadCrumb = {
  text: "Orders",
  route: { name: "OrderMasterList" },
};

export const OrderDetailsBreadCrumb = (params: OrderDetailsParams) => {
  const order = Order.$findOne(params.order);
  const kintin = order?.kintin;

  const crumbs: Array<BreadCrumbConfig> = [OrderMasterListBreadCrumb];

  if (kintin) {
    crumbs.push(
      {
        text: kintin.friendlyName as string,
        route: { name: "KinvaultKintinDetails", params: { kintin: kintin.id } },
      },
      {
        text: "Orders",
        route: { name: "KintinDetailsOrder", params: { kintin: kintin.id } },
      },
    );
  }

  crumbs.push({
    text: order?.id as string,
    route: { name: "OrderDetails", params },
  });

  return crumbs;
};
