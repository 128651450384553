import { Between, In, Like } from "@kinherit/orm/index";
import { Api, EmailTemplate, IEmailTemplate } from "@kinherit/sdk";

export interface ReadEmailTemplateMessage {
  search?: string;
  type?: Array<string>;
  created?: [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort?: {
    by: keyof IEmailTemplate;
    direction: "asc" | "desc";
  };
}

interface ReadEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadEmailTemplateHandler = async (
  message: ReadEmailTemplateMessage,
): Promise<ReadEmailTemplateResponse> => {
  const request = Api.resource("portal", "/v2/portal/email-template")
    .method("get")

    .paginate(message.pagination ?? false)
    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "desc",
    });

  request.buildQuery(EmailTemplate).where([
    {
      name: Like(message.search),
      type: {
        id: In(message.type),
      },
      createdAt: Between(message.created),
    },
    {
      subject: Like(message.search),
      type: {
        id: In(message.type),
      },
      createdAt: Between(message.created),
    },
    {
      body: Like(message.search),
      type: {
        id: In(message.type),
      },
      createdAt: Between(message.created),
    },
  ]);

  const response = await request.result();

  return {
    emailTemplates: EmailTemplate.$inflate(
      response.emailTemplate,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
