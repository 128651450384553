import { Api } from "@/service/api.service";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { FileLog, Kintin } from "@kinherit/sdk";

type DownloadKintinFileMessage = {
  file: FileLog | string;
  kintin: Kintin | string;
};

interface DownloadKintinFileResponse {
  [key: string]: never;
}

export const DownloadKintinFileHandler = async (
  message: DownloadKintinFileMessage,
): Promise<DownloadKintinFileResponse> => {
  const { url } = await Api.resource(
    "portal",
    "/v2/portal/kintin-file/{kintin}/files/{file}/download",
    {
      kintin: message.kintin,
      file: message.file,
    },
  )
    .method("get")

    .result();

  OpenAlertDialog({
    dialog: {
      title: "Download Ready",
      html: `
            Click <a href="${url}" target="_blank">here</a> to download the file.
          `,
    },
    button: {
      ok: {
        text: "Close",
      },
      cancel: {
        show: false,
      },
    },
  });

  return {};
};
