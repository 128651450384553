<template>
  <DataListWidget v-if="emailCampaign" title="About" :details="details">
  </DataListWidget>
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import { defineComponent } from "vue";
import { EmailCampaignDetailsMixin } from "../../mixin/email-campaign-details.mixin";

export default defineComponent({
  components: {
    DataListWidget,
  },
  mixins: [EmailCampaignDetailsMixin],
  computed: {
    details(): Array<DataListWidgetDetail> {
      return [
        {
          label: "Created",
          value: this.emailCampaign?.createdAt.formatDate,
        },
      ];
    },
  },
});
</script>
