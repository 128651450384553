import { Api } from "@/service/api.service";
import { KintinAccess } from "@kinherit/sdk";

interface UpdateUserKintinAccessMessage {
  kintinAccess: KintinAccess;
}

interface UpdateUserKintinAccessResponse {
  kintinAccess: KintinAccess;
}

export const UpdateUserKintinAccessHandler = async (
  message: UpdateUserKintinAccessMessage,
): Promise<UpdateUserKintinAccessResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kintin-user-access/{kintinAccess}",
    {
      kintinAccess: message.kintinAccess,
    },
  ).method("patch");
  request.body({
    accessType: message.kintinAccess.accessType,
    kintin: message.kintinAccess.kintin?.id,
  });

  const result = await request.result();

  const kintinAccess = KintinAccess.$inflate(result.kintinAccess).first();

  if (!kintinAccess) {
    throw new Error("Failed to update kintin user access");
  }

  return {
    kintinAccess,
  };
};
