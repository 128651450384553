import { Api } from "@/service/api.service";
import { IntroducerCpd } from "@kinherit/sdk";

interface DeleteIntroducerCpdMessage {
  introducerCpd: IntroducerCpd;
}

interface DeleteIntroducerCpdResponse {
  [key: string]: never;
}

export const DeleteIntroducerCpdHandler = async (
  message: DeleteIntroducerCpdMessage,
): Promise<DeleteIntroducerCpdResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/cpd-session/introducer-cpd/{introducerCpd}",
    {
      introducerCpd: message.introducerCpd,
    },
  )
    .method("delete")

    .result();

  message.introducerCpd.$delete();

  return {};
};
