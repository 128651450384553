import { Api } from "@/service/api.service";
import { IntroducerFeePayment } from "@kinherit/sdk";

export interface CreateIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment;
}

export interface CreateIntroducerFeePaymentResponse {
  introducerFeePayment: IntroducerFeePayment;
}

export const CreateIntroducerFeePaymentHandler = async (
  message: CreateIntroducerFeePaymentMessage,
): Promise<CreateIntroducerFeePaymentResponse> => {
  const repsonse = await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment",
  )
    .method("post")
    .body({
      completedAt: message.introducerFeePayment.$data.completedAt,
      status: message.introducerFeePayment.$data.status,
      xeroContact: message.introducerFeePayment.$data.xeroContact,
      introducerFeePaymentRun:
        message.introducerFeePayment.$data.introducerFeePaymentRun,
    })
    .result();

  const introducerFeePayment = IntroducerFeePayment.$inflate(
    repsonse.introducerFeePayment,
  ).first();

  if (!introducerFeePayment) {
    throw new Error("Failed to create introducerfeepayment");
  }

  message.introducerFeePayment.$delete();

  return {
    introducerFeePayment: introducerFeePayment,
  };
};
