import { Api, Tag } from "@kinherit/sdk";
export const DeleteTagAction = async (message: Tag): Promise<{}> => {
  await Api.resource("portal", "/v2/portal/tag/{tag}", {
    tag: message.id,
  })
    .method("delete")
    .result();

  message.$delete();

  return {};
};
