import { EmailTemplate } from "@kinherit/sdk";

export const EmailTemplateMasterListBreadcrumb = {
  text: "Email Templates",
  route: { name: "EmailTemplateMasterList" },
};

export const EmailTemplateDetailsBreadcrumbs = (
  params: Record<string, string>,
) => [
  EmailTemplateMasterListBreadcrumb,
  {
    text: EmailTemplate.$findOne(params.emailTemplate)?.name ?? "Template",
    route: { name: "EmailTemplateDetails", params },
  },
];
