import { FormFileField } from "@kinherit/framework/component.input/file-field";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import {
  AlertDialogRequest,
  OpenAlertDialog,
} from "@kinherit/framework/global/dialog";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { DeepPartial } from "@kinherit/ts-common";

export const DangerDialog = (request: DeepPartial<AlertDialogRequest> = {}) => {
  let message: string | undefined = undefined;

  if (!request.dialog?.message && !request.dialog?.html) {
    message = "Are you sure you want to continue?";
  }

  return OpenAlertDialog({
    ...request,
    dialog: {
      title: "Danger",
      icon: ThemeIconName.Cross,
      message,
      ...((request.dialog as any) ?? {}),
    },
    button: {
      ok: {
        text: "OK",
        color: "is-danger",
        ...(request.button?.ok ?? {}),
      },
      cancel: {
        text: "Cancel",
        ...(request.button?.cancel ?? {}),
      },
    },
  });
};

export const OpenFileDialog = () =>
  defineForm({
    name: `open-file`,
    data: () => ({
      file: [] as File[],
    }),
    formAreas: (data) => [
      defineFormArea({
        name: `open-file`,
        data,
        components: () => ({
          default: [
            FormFileField({
              props: {
                validators: [
                  [
                    "file-limit",
                    {
                      numberOfFiles: 1,
                    },
                  ],
                  "required",
                ],
              },
              models: {
                value: "file",
              },
            }),
          ],
        }),
      }),
    ],
  })
    .dialog({
      dialog: {
        title: "Select a file to upload",
      },
    })
    .then((data) => data.file[0]);
