import { Api, Executor, Kintin } from "@kinherit/sdk";

interface DeleteExecutorMessage {
  kintin: string | Kintin;
  executor: Executor;
}

interface DeleteExecutorResponse {
  [key: string]: never;
}

export const DeleteExecutorHandler = async (
  message: DeleteExecutorMessage,
): Promise<DeleteExecutorResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-executor/{kintin}/person/executor/{executor}",
    {
      kintin: message.kintin,
      executor: message.executor.$data.id,
    },
  )
    .method("delete")
    .result();

  message.executor.$delete();

  return {};
};
