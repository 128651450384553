<template>
  <CallScriptSection title="Who Does What?">
    <b>
      OK so now we move on to who does what and thinking about how your wishes
      happen in practice.
      <br /><br />
      Can I just ask, have you watched our Kinvault video at all?
    </b>
    <br /><em>[pause]</em> <br /><br />
    [if yes]<br />
    <b>
      Great, so you’ve got an idea of what it is – it’s basically there to back
      up your Will so that the people you appoint to sort out probate for you
      will find it all much easier to do.
    </b>
    <br /><br />
    [If no]<br />
    <b>
      OK, so it’s worth explaining here that all our Wills come with something
      called a Kinvault, which is basically an online vault to back up your
      Will. It’s the equivalent of the box under the bed if you like, but it’s
      digital.
    </b>
    <br /><br />
    <b>
      Many of the issues that crop up in probate are to do with lack of
      organisation – so not knowing who to contact, or where to find things, but
      the Kinvault keeps everything organised, so that things don’t get lost and
      the people you appoint to sort probate out for you will find it all much
      easier to do.
    </b>
    <br /><br />
    <b>
      Just to check - do you understand what probate is, or the role of
      Executor?
    </b>
    <br /><em>[pause]</em> <br /><br />
    <b>
      So simply put, probate is the legal process of sorting out your estate,
      and it’s managed by someone called an Executor.
    </b>
  </CallScriptSection>
</template>

<script lang="ts">
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExecutorsTab",
  components: {
    CallScriptSection,
  },
});
</script>
