import { Api } from "@/service/api.service";
import { IntroducerCpd } from "@kinherit/sdk";

interface UpdateIntroducerCpdMessage {
  introducerCpd: IntroducerCpd;
}

interface UpdateIntroducerCpdResponse {
  introducerCpd: IntroducerCpd;
}

export const UpdateIntroducerCpdHandler = async (
  message: UpdateIntroducerCpdMessage,
): Promise<UpdateIntroducerCpdResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/cpd-session/introducer-cpd/{introducerCpd}",
    {
      introducerCpd: message.introducerCpd,
    },
  )
    .method("patch")

    .body({
      contact: message.introducerCpd.$data.contact,
      session: message.introducerCpd.$data.session,
      status: message.introducerCpd.$data.status,
      type: message.introducerCpd.$data.type,
    })
    .result();

  const introducerCpd = IntroducerCpd.$inflate(response.introducerCpd).first();

  if (!introducerCpd) {
    throw new Error("Failed to update Introducer Cpd");
  }

  return { introducerCpd };
};
