import { AdminBrandedKinvaultDetailsMembersBreadCrumb } from "@/module/admin.branded-kinvault/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const BrandedKinvaultDetailsMembersRoute =
  "BrandedKinvaultDetailsMembers";
export const BrandedKinvaultDetailsMembersURI =
  "/branded-kinvaults/:brandedKinvault/members";
export type BrandedKinvaultDetailsMembersParams = RouteParamObject<
  typeof BrandedKinvaultDetailsMembersURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: BrandedKinvaultDetailsMembersRoute,
    path: BrandedKinvaultDetailsMembersURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "BrandedKinvaultDetailsMembers" */ "./BrandedKinvaultDetails.Members.vue"
        ),
    },
    meta: {
      breadcrumbs: AdminBrandedKinvaultDetailsMembersBreadCrumb,
    },
  },
];
