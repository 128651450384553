import { Api } from "@/service/api.service";
import { EmailCampaignLog } from "@kinherit/sdk";

export interface DeleteEmailCampaignLogMessage {
  emailCampaignLog: EmailCampaignLog;
}

export interface DeleteEmailCampaignLogResponse {
  [key: string]: never;
}

export const DeleteEmailCampaignLogHandler = async (
  message: DeleteEmailCampaignLogMessage,
): Promise<DeleteEmailCampaignLogResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/email-campaign-log/{emailCampaignLog}",
    message,
  )
    .method("delete")

    .result();

  message.emailCampaignLog.$delete();

  return {};
};
