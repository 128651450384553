import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsSummaryRoute =
  "IntroducerCompanyDetailsSummary";
export const IntroducerCompanyDetailsSummaryURI =
  "/introducer/company/:introducerCompany";
export type IntroducerCompanyDetailsSummaryParams = RouteParamObject<
  typeof IntroducerCompanyDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsSummaryRoute,
    path: IntroducerCompanyDetailsSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsSummary" */ "./IntroducerCompanyDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
      ],
    },
  },
];
