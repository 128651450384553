<template>
  <strong>STEP Powers</strong>
  <p>
    In this my Will where the context so admits any reference the STEP Powers
    shall mean the Standard Provisions and all of the Special Provisions (with
    the exception of 18.2) of the Society of Trust and Estate Practitioners (3rd
    edition) shall apply
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
