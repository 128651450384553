import { Api } from "@/service/api.service";
import { Kintin, PhysicalStorageFile, Subscription } from "@kinherit/sdk";

interface DeleteKintinStorageRequestMessage {
  kintin: Kintin;
  subscription: Subscription;
  storageRequest: PhysicalStorageFile;
}

interface DeleteKintinStorageRequestResponse {
  [key: string]: never;
}

export const DeleteKintinStorageRequestHandler = async (
  message: DeleteKintinStorageRequestMessage,
): Promise<DeleteKintinStorageRequestResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-subscription/{kintin}/subscriptions/{subscription}/storage-request/{physicalStorageFile}",
    {
      kintin: message.kintin,
      subscription: message.subscription,
      physicalStorageFile: message.storageRequest,
    },
  )
    .method("delete")

    .result();

  message.storageRequest.$delete();

  return {};
};
