import { Api, Order, OrderItem } from "@kinherit/sdk";
export interface DeleteOrderItemMessage {
  order: Order;
  orderItem: OrderItem | string;
}

export interface DeleteOrderItemResponse {
  [key: string]: never;
}

export const DeleteOrderItemHandler = async (
  message: DeleteOrderItemMessage,
): Promise<DeleteOrderItemResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/order/{order}/order-items/{orderItem}",
    {
      order: message.order.id,
      orderItem:
        "string" === typeof message.orderItem
          ? message.orderItem
          : message.orderItem.id,
    },
  )
    .method("delete")
    .result();

  if ("string" !== typeof message.orderItem) {
    message.orderItem.$delete();
  }

  return {};
};
