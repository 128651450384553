<template>
  <div v-if="kintin">
    <CallScriptSection title="Executors">
      <b>
        We say there are two types of executor role – personal and professional.
        <br /><br />
        Thinking of the personal side first – can you think of someone you trust
        to manage the practical things relating to … your funeral, your home,
        your friends and family… that sort of thing?
      </b>
      <span v-if="isJoint">
        <br />
        <br />
        <em>[JOINT] COUNT TO 5, if no answer or they ask for help say –</em>
        <br />
        <b>
          ...Well most people choose their partner as the first option as they
          know them really well. Does that sound good for both of you?
        </b>
        <br /><em>[LET THEM ANSWER]</em>
      </span>
      <br />
      <br />
      <b>
        And to give your Will flexibility, we strongly encourage listing a
        couple of other people as reserves… it doesn’t cost anything, but just
        gives you more protection. Is there anyone else - say family or friends
        – who comes to mind?
      </b>
      <br /><em>[LET THEM ANSWER / target minimum 2 reserves]</em>
      <br />
      <br />
      <ExecutorsContainer :kintin="kintin" />
      <br />
      <b>
        Great, OK, so it can also really help to have someone who knows what
        they are doing on the legal side – this is often called the professional
        executor role.
        <br />
        <br />
        Most Wills include a named professional executor, but the issue with
        this approach is that we won’t know what costs or quality will be at the
        time they are needed. (We also think it’s a massive conflict of
        interest)
        <br />
        <br />
        So we suggest we write the Will in a way that helps your personal
        executors to decide who is best placed, based on the circumstances at
        the time, or equally if they decide it is best to not have a
        professional then that is also fine.
        <br />
        <br />
        This way, we can give them some options that we can vouch for, but
        without restricting their decision making.
        <br /><br />
      </b>
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.3b"
        :kintin="kintin"
        is-reversed
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.3c"
        :kintin="kintin"
        v-if="checkIsYes('4.6.3b')"
        yes-label="Encouraged"
        no-label="Considered"
        maybe-label="Required"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.1"
        :kintin="kintin"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.2"
        :kintin="kintin"
      />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="4.6.3"
        :kintin="kintin"
      />
    </CallScriptSection>
    <CallScriptSpecialistNotesSection
      :kintin="kintin"
      v-model:value="callScript.advExecutorNotes"
      :has-continue="false"
    />
  </div>
</template>

<script lang="ts">
import ExecutorsContainer from "@/module/kinvault.kintin/component/address-book/ExecutorsContainer.vue";
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import CallScriptSpecialistNotesSection from "@/module/kinvault.kintin/component/call-script/shared/SectionNotes.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "ExecutorsTab",
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    ConfirmCheck,
    CallScriptSection,
    CallScriptSpecialistNotesSection,
    ExecutorsContainer,
  },
});
</script>
