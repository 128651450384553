import { Api } from "@/service/api.service";
import {
  ISubscription,
  Kintin,
  PhysicalStorageFile,
  PhysicalStorageFileLog,
  Product,
  Subscription,
} from "@kinherit/sdk";

export interface ReadKintinSubscriptionsMessage {
  kintin: string | Kintin;
  pinned?: boolean;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof ISubscription;
    direction: "asc" | "desc";
  };
}

export interface ReadKintinSubscriptionsResponse {
  subscriptions: Array<Subscription>;
  physicalStorageFiles: Array<PhysicalStorageFile>;
  physicalStorageFileLogs: Array<PhysicalStorageFileLog>;
  products: Array<Product>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKintinSubscriptionsHandler = async (
  message: ReadKintinSubscriptionsMessage,
): Promise<ReadKintinSubscriptionsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/kintin-subscription/{kintin}/subscriptions",
    {
      kintin: message.kintin,
    },
  )
    .method("get")

    .paginate({
      currentPage: message.pagination?.currentPage ?? 1,
      perPage: message.pagination?.perPage ?? 10,
    })
    .sort(
      message.sort
        ? {
            by: message.sort.by,
            direction: message.sort.direction,
          }
        : undefined,
    );

  const response = await request.result();

  return {
    subscriptions: Subscription.$inflate(
      response.subscription,
      message.sort,
      response.$rootIds,
    ),
    physicalStorageFiles: PhysicalStorageFile.$inflate(
      response.physicalStorageFile,
    ),
    physicalStorageFileLogs: PhysicalStorageFileLog.$inflate(
      response.physicalStorageFileLog,
    ),
    products: Product.$inflate(response.product),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
