<template>
  <Card
    v-if="user"
    title="Kinvaults"
    subtitle="Manage which Kinvaults this user account can access"
    class="user-details-kintins"
  >
    <Table
      :key="renderKey"
      class="user-kintin-access-table"
      is-narrow
      :columns="columns"
      :rows="assignedKintins"
    >
      <template #kinvault="{ row }">
        <div class="is-clickable" @click="visitKintin(row, $event)">
          <strong>{{ row.kintin.friendlyName }}</strong
          ><br />
          <small class="is-family-monospace">{{ row.kintin.ref }}</small>
        </div>
      </template>
      <template #actions="{ row }">
        <Button
          v-if="hasWriteAccess"
          @click="editKintinAccess(row, $event)"
          class="edit-button"
          :icon-left="$style.icon.edit.icon"
          ariaLabel="Edit Kinvault Access Type"
          size="is-small"
          color="is-plain"
        />
      </template>
    </Table>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsKintinsWrapper",
  "route": "UserDetailsKintins",
  "selector": ".user-details-kintins"
}
</cypress-wrapper>

<script lang="ts">
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import {
  KinvaultKintinDetailsSummaryParams,
  KinvaultKintinDetailsSummaryRoute,
} from "@/module/kinvault.kintin/page/details/summary";
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import { Table } from "@kinherit/framework/component.display/table";
import { Button } from "@kinherit/framework/component.input/button";
import { Card } from "@kinherit/framework/component.layout/card";
import { KintinAccess } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  AdminUserDetailsAssignKintinAccessParams,
  AdminUserDetailsAssignKintinAccessRoute,
  UserDetailsKintinsRoute,
} from ".";

export default defineComponent({
  name: UserDetailsKintinsRoute,
  mixins: [
    AuthService.mixin({ sessionRequired: true }),
    UserDetailsMixin,
    StyleService.mixin,
  ],
  components: {
    Card,
    Table,
    Button,
  },
  data: () => ({
    columns: [
      {
        title: "kinvault",
        slot: "kinvault",
      },
      {
        title: "Type",
        map: (v: KintinAccess) => v.accessType.ucFirst(),
        sortable: true,
      },
      {
        title: "Assigned",
        map: (v: KintinAccess) => v.createdAt.formatDate,
        sortable: true,
      },
      {
        slot: "actions",
        style: {
          padding: "2px",
          width: "1%",
        },
      },
    ],
    renderKey: 0,
    assignedKintins: Array<KintinAccess>(),
  }),
  mounted(): void {
    this.loadKintinAccess();
  },
  methods: {
    visitKintin(kintinAccess: KintinAccess, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsSummaryRoute,
          params: {
            kintin: kintinAccess.kintin.id,
          } satisfies KinvaultKintinDetailsSummaryParams,
        },
        event.ctrlKey,
      );
    },
    async loadKintinAccess(): Promise<void> {
      if (!this.user) {
        return;
      }

      // const response = await window.Kernel.ActionBus.execute(
      //   "admin/user/assigned-kintins/read",
      //   {
      //     user: this.user,
      //   },
      // );
      const response =
        await window.Kernel.ActionBus.adminUser.assignedKintins.read({
          user: this.user,
        });

      this.assignedKintins = response.kintinAccess;
      this.renderKey++;
    },
    editKintinAccess(kintinAccess: KintinAccess, event: MouseEvent): void {
      if (
        !this.$auth.hasPermission("user:write") &&
        !this.$auth.hasPermission("kintin:write")
      ) {
        return;
      }

      const params: AdminUserDetailsAssignKintinAccessParams = {
        user: this.$params.user,
        kintinAccess: kintinAccess.id,
      };

      window.Kernel.visitRoute(
        {
          name: AdminUserDetailsAssignKintinAccessRoute,
          params,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>
