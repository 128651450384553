import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateIntroducerNetworkHandler } from "./create-introducer-network.action";
import { DeleteIntroducerNetworkHandler } from "./delete-introducer-network.action";
import { ReadIntroducerNetworkHandler } from "./read-introducer-network.action";
import { UpdateIntroducerNetworkHandler } from "./update-introducer-network.action";

export const Actions = {
  "introducer/network/read": ReadIntroducerNetworkHandler,
  "introducer/network/create": CreateIntroducerNetworkHandler,
  "introducer/network/update": UpdateIntroducerNetworkHandler,
  "introducer/network/delete": DeleteIntroducerNetworkHandler,
};

export const IntroducerNetworkActionBus = DefineActionBus({
  name: "Introducer.Network",
  actions: {
    read: ReadIntroducerNetworkHandler,
    create: CreateIntroducerNetworkHandler,
    update: UpdateIntroducerNetworkHandler,
    delete: DeleteIntroducerNetworkHandler,
  },
});
