import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadEmailCampaignForm = () => {
  return MasterListFiltersForm({
    name: "filter-email-campaign-form",
    data: () => ({
      name: null as null | string,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: `Name`,
          },
          models: {
            value: `name`,
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            label: `Created`,
            vModel: `created`,
          },
        }),
      ],
    }),
  });
};
