<template>
  <Form
    v-if="$data.documentsForm && options.editing"
    class="mt-4"
    :config="$data.documentsForm"
    @update:data="refreshEnabledSections"
    is-borderless
  />
</template>

<script lang="ts">
import { WillBuilderOptionalDocumentsForm } from "@/module/kinvault.kintin/form/will-builder/will-builder-optional-documents.form";
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import Form from "@kinherit/framework/component.form/form";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentSettingsForm",
  mixins: [KinvaultKintinDetailsMixin, WillBuilderService.mixin],
  components: {
    Form,
  },
  data: () => ({
    documentsForm: null as null | ReturnType<
      typeof WillBuilderOptionalDocumentsForm
    >,
  }),
  mounted() {
    this.documentsForm = WillBuilderOptionalDocumentsForm();
  },
});
</script>
