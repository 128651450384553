import { Api, Kintin, Person } from "@kinherit/sdk";

export interface DownloadLetterOfAuthorityMessage {
  kintin: Kintin;
  person: Person;
  relationship: string;
  name: string;
  companyName?: string;
  address?: string;
}

export const DownloadLetterOfAuthorityHandler = async (
  message: DownloadLetterOfAuthorityMessage,
) => {
  await Api.resource("portal", "/v2/portal/kintin-process/letter-of-authority")
    .method("post")
    .body({
      kintin: message.kintin.id,
      person: message.person.id,
      relationship: message.relationship,
      name: message.name,
      companyName: message.companyName,
      address: message.address,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${message.kintin.ref} - Letter of Authority`,
    });
};
