import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { OrderDetailsBreadCrumb } from "../../../breadcrumbs";

export const OrderDetailsCreditNotesRoute = "OrderDetailsCreditNotes";
export const OrderDetailsCreditNotesURI = "/order/:order/credit-notes";
export type OrderDetailsCreditNotesParams = RouteParamObject<
  typeof OrderDetailsCreditNotesURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: OrderDetailsCreditNotesRoute,
    path: OrderDetailsCreditNotesURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "OrderDetailsCreditNotes" */ "./OrderDetails.CreditNotes.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...OrderDetailsBreadCrumb(params as OrderDetailsCreditNotesParams),
        {
          text: "Credit Notes",
          route: { name: OrderDetailsCreditNotesRoute, params },
        },
      ],
    },
  },
];
