import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { EmailTemplateDetailsBreadcrumbs } from "../../breadcrumbs";
import {
  EmailTemplateDetailsSummaryRoute,
  Routes as SummaryRoutes,
} from "./summary";

export const EmailTemplateDetailsRoute = "EmailTemplateDetails";
export const EmailTemplateDetailsURI = "/admin/email-template/:emailTemplate";
export type EmailTemplateDetailsParams = RouteParamObject<
  typeof EmailTemplateDetailsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: EmailTemplateDetailsRoute,
    path: EmailTemplateDetailsURI,
    component: () =>
      import(
        /* webpackChunkName: "EmailTemplateDetails" */ "./EmailTemplateDetails.vue"
      ),
    redirect: { name: EmailTemplateDetailsSummaryRoute },
    children: [...SummaryRoutes],
    meta: {
      breadcrumbs: (params) => EmailTemplateDetailsBreadcrumbs(params),
    },
  },
];
