import { Api } from "@/service/api.service";
import { EmailCampaignLog } from "@kinherit/sdk";

// type Message = {
//   emailCampaignLog: EmailCampaignLog;
//   files: Array<File>;
// };

// type Response = {};

// export class UploadEmailCampaignLogAttachmentHandler<
//   M extends Message,
//   R extends Response,
// > extends ActionBase {
//   constructor(private message: M) {
//     super();
//   }

//   public async execute(): Promise<R> {
export const UploadEmailCampaignLogAttachmentHandler = async (message: {
  emailCampaignLog: EmailCampaignLog;
  files: Array<File>;
}): Promise<{}> => {
  for (const file of message.files) {
    await Api.resource(
      "portal",
      "/v2/portal/email-campaign-log/{emailCampaignLog}/attachment",
      {
        emailCampaignLog: message.emailCampaignLog,
      },
    )
      .method("put")

      .files({
        uploadedFile: file,
      })
      .result();
  }

  return {};
};
