import { RouteInterface } from "@kinherit/framework/core/route-config";
import { IntroducerCompanyMasterListBreadCrumb } from "../breadcrumbs";

export const IntroducerCompanyMasterListRoute = "IntroducerCompanyMasterList";

import { Routes as IntroducerCompanyDetailsRoutes } from "./details";

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyMasterListRoute,
    path: "/introducers",
    component: () =>
      import(
        /* webpackChunkName: "IntroducerCompanyMasterList" */ "./IntroducerCompanyMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [IntroducerCompanyMasterListBreadCrumb],
    },
  },
  ...IntroducerCompanyDetailsRoutes,
];
