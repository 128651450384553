<template>
  <div v-if="data">
    <span>
      <strong class="sanserif"> This is the last Will and Testament </strong>
      <br />- of -
      <span class="ucase"
        ><span
          v-if="data.person"
          v-html="helpers.var(data.person.profile.fullName)"
      /></span>
      <br />of
      <span v-if="address?.line1"
        ><span v-html="helpers.var(address.line1)"
      /></span>
      <span v-if="address?.line2"
        ><span v-html="helpers.var(address.line2)"
      /></span>
      <span v-if="address?.city"
        ><span v-html="helpers.var(address.city)"
      /></span>
      <br />
      <span v-if="address?.state"
        ><span v-html="helpers.var(address.state)"
      /></span>
      <span v-if="address?.postcode"
        ><span v-html="helpers.var(address.postcode)"
      /></span>
    </span>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeclarationSection",
  mixins: [WillBuilderService.mixin],
  computed: {
    address() {
      return this.data?.person?.profile.addresses.findBy(
        "primaryResidential",
        true,
      );
    },
  },
});
</script>
