import { RouteInterface } from "@kinherit/framework/core/route-config";

export const KinvaultSubscriptionMasterListRoute =
  "KinvaultSubscriptionMasterList";

const KinvaultSubscriptionMasterListBreadCrumb = {
  text: "Subscriptions",
  route: { name: KinvaultSubscriptionMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultSubscriptionMasterListRoute,
    path: "/subscriptions",
    component: () =>
      import(
        /* webpackChunkName: "SubscriptionMasterList" */ "./SubscriptionMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KinvaultSubscriptionMasterListBreadCrumb],
    },
  },
];
