import { Api, Kintin } from "@kinherit/sdk/index";

// export class SwitchAccountHoldersHandler extends ActionBase {
//   constructor(
//     private data: {
//       kintin: Kintin;
//     },
//   ) {
//     super();
//   }

//   async execute() {
export const SwitchAccountHoldersHandler = async (data: {
  kintin: Kintin;
}): Promise<void> => {
  await Api.resource("portal", "/v2/portal/kintin/switch-account-holders")
    .method("put")
    .body({
      kintin: data.kintin.id,
    })
    .result();
};
