import { Api } from "@/service/api.service";
import { Address } from "@kinherit/sdk";

interface CreateAddressMessage {
  address: Address;
}

interface CreateAddressResponse {
  address: Address;
}

export const CreateAddressHandler = async (
  message: CreateAddressMessage,
): Promise<CreateAddressResponse> => {
  const { address: addressData } = await Api.resource(
    "portal",
    "/v2/portal/address",
  )
    .method("post")

    .body({
      city: message.address.city,
      country: message.address.country,
      line1: message.address.line1,
      line2: message.address.line2,
      postcode: message.address.postcode,
      primaryMailing: message.address.primaryMailing,
      primaryResidential: message.address.primaryResidential,
      state: message.address.state,
      profile: message.address.profile?.id,
    })
    .result();

  const address = Address.$inflate(addressData).first();

  if (!address) {
    throw new Error("Failed to create address");
  }

  message.address.$delete();

  return { address };
};
