<template>
  <div v-if="!loading">
    <DataListWidget :title="title" :details="referralForm" is-plain>
      <template #below>
        <p v-if="introducerContactId">
          Any submissions via this form will be attributed to both the referral
          code and the introducer contact.
        </p>
        <p v-else>
          Any submissions via this form will be attributed to the referral code
          only, and not to any introducer contact.
        </p>
        <QRCode
          v-bind="bindings"
          imgclass=""
          myclass=""
          downloadButton="button is-themed is-light-grey is-small mr-1"
          :download="true"
          :downloadOptions="{
            name: fileName,
            extension: 'png',
          }"
          -button-name=""
        />
      </template>
    </DataListWidget>
  </div>
</template>

<script lang="ts">
import DataListWidget from "@/shared/component/widget/DataListWidget.vue";
import { DataListWidgetDetail } from "@/shared/component/widget/data-list-widget";
import QRCode from "qrcode-vue3";
import type { Options } from "qrcode-vue3/src/core/QROptions";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReferralQRCode",
  props: {
    code: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Referral Form",
    },
    introducerContactId: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: "referral",
    },
  },
  components: {
    QRCode,
    DataListWidget,
  },
  data: () => ({
    loading: true,
    referralFormUrl: "",
  }),
  async mounted() {
    // const { url } = await window.Kernel.ActionBus.execute(
    //   "core/environment/get-sign-app-url",
    //   {},
    // );
    const { url } =
      await window.Kernel.ActionBus.core.environment.getSignAppUrl();

    this.referralFormUrl = `${url}/partners/${this.code}`;
    if (this.introducerContactId) {
      this.referralFormUrl = this.referralFormUrl +=
        "/" + this.introducerContactId;
    }
    this.loading = false;
  },
  computed: {
    referralForm(): DataListWidgetDetail[] {
      if (!this.code) return [];
      return [
        {
          label: "Referral Form Link",
          value: {
            text: this.referralFormUrl,
            href: this.referralFormUrl,
          },
        },
      ];
    },
    bindings(): Omit<Options, "data"> & { value: string } {
      return {
        value: this.referralFormUrl,
        width: 200,
        height: 200,
        margin: 5,
        qrOptions: {},
        imageOptions: {},
        dotsOptions: {
          color: "#00a19b",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#00a19b" },
              { offset: 1, color: "#00a19b" },
            ],
          },
          type: "classy",
        },
        backgroundOptions: {},
        cornersDotOptions: {
          color: "#002A3A",
          gradient: {
            type: "radial",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#002A3A" },
              { offset: 1, color: "#002A3A" },
            ],
          },
          type: "dot",
        },
        cornersSquareOptions: {
          color: "#002A3A",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#002A3A" },
              { offset: 1, color: "#002A3A" },
            ],
          },
          type: "dot",
        },
      };
    },
  },
});
</script>
