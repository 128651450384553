import {
  CreatedAtField,
  OptionsAutoCompleteField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadSubscriptionForm = () => {
  return MasterListFiltersForm({
    name: "filter-subscription-form",
    data: () => ({
      search: "",
      status: Array<string>(),
      created: null as null | [number, number],
      hasFiles: null as null | boolean,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
          },
          models: {
            value: "search",
          },
        }),
      ],
      status: [
        OptionsAutoCompleteField({
          vModel: "status",
          group: "subscriptionStatus",
          props: {
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
      hasFiles: [
        FormSelectField({
          props: {
            label: "Has Files",
            placeholder: "Any",
            options: {
              true: "Yes",
              false: "No",
            },
          },
          models: {
            value: "hasFiles",
          },
        }),
      ],
    }),
  });
};
