import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KinvaultOfficerDetailsBreadCrumb,
  KinvaultOfficerMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const KinvaultOfficerDetailsAnswerphoneMessageScriptRoute =
  "KinvaultOfficerDetailsAnswerphoneMessageScript";
export const KinvaultOfficerDetailsAnswerphoneMessageScriptURI =
  "/officer/:officer/answerphone-message-script";
export type KinvaultOfficerDetailsAnswerphoneMessageScriptParams =
  RouteParamObject<typeof KinvaultOfficerDetailsAnswerphoneMessageScriptURI>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultOfficerDetailsAnswerphoneMessageScriptRoute,
    path: KinvaultOfficerDetailsAnswerphoneMessageScriptURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultOfficerDetailsAnswerphoneMessageScript" */ "./KinvaultOfficerDetails.AnswerphoneMessageScript.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        KinvaultOfficerMasterListBreadCrumb,
        KinvaultOfficerDetailsBreadCrumb(params),
        {
          text: "Answerphone Message Script",
          route: {
            name: KinvaultOfficerDetailsAnswerphoneMessageScriptRoute,
            params,
          },
        },
      ],
    },
  },
];
