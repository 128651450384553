import { Api, Kintin, Property, User } from "@kinherit/sdk";

export interface DownloadLRSeveranceMessage {
  kintin: Kintin;
  property: Property;
  user: User;
}

export const DownloadLRSeveranceHandler = async (
  message: DownloadLRSeveranceMessage,
) => {
  const { kintin, property, user } = message;

  const primaryPerson = kintin.primaryPerson;
  const secondaryPerson = kintin.secondaryPerson;

  const surname = [
    primaryPerson.profile.lastName,
    secondaryPerson?.profile.lastName,
  ]
    .filter(Boolean)
    .unique()
    .join(" & ");

  await Api.resource("portal", "/v2/portal/kintin-process/lr-severance-form")
    .method("get")
    .params({
      kintin: kintin.id,
      property: property.id,
      user: user.id,
    })
    .download({
      encoding: "application/pdf",
      fileName: `${kintin.ref} LR Severance Form - ${surname}`,
    });
};
