import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface CreateKintinSignDocMessage {
  signDoc: SignDoc;
  kintin: Kintin | string;
}

export interface CreateKintinSignDocResponse {
  signDoc: SignDoc;
}

export const CreateKintinSignDocHandler = async (
  message: CreateKintinSignDocMessage,
): Promise<CreateKintinSignDocResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs",
    {
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
    },
  )
    .method("post")
    .body({
      person: message.signDoc.$data.person,
      data: message.signDoc.$data.data,
      kintin: message.signDoc.$data.kintin,
      type: message.signDoc.$data.type,
      ipAddress: message.signDoc.$data.ipAddress,
      userAgent: message.signDoc.$data.userAgent,
      signedAt: message.signDoc.$data.signedAt,
      stripeSessions: message.signDoc.$data.stripeSessions,
      clauses: message.signDoc.$data.clauses,
      conditions: message.signDoc.$data.conditions,
      products: message.signDoc.$data.products,
    })
    .result();

  const signDoc = SignDoc.$inflate(response.signDoc).first();

  if (!signDoc) {
    throw new Error("SignDoc not found");
  }

  await Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}/disclaimer",
    {
      kintin: signDoc.$data.kintin,
      signDoc: signDoc.$data.id,
    },
  )
    .method("put")
    .result();

  message.signDoc.$delete();

  return {
    signDoc,
  };
};
