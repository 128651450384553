<template>
  <span>
    <strong>Personal Possessions Letter of Wishes</strong>
    <p>
      I Give all my personal chattels not otherwise specifically gifted by my
      Will or any Codicil free of all taxes and death duties to my Executors as
      beneficial legatees and I request my Executors within two years of my
      death but without imposing any binding trust or legal obligation and
      without conferring any interest on any other person:
    </p>
    <ol type="i">
      <li>
        to dispose of the same in accordance with any Memorandum for my Personal
        Chattels which may come to my Executors' attention within three months
        of my death
      </li>
      <li>
        to the extent that there are personal chattels not disposed of by my
        Executors under the terms of this clause they shall fall into and form
        part of my Estate
      </li>
      <li>
        to treat the expression "personal chattels" as having the meaning given
        by section 55(1)(x) of the Administration of Estates Act 1925
      </li>
    </ol>
  </span>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
