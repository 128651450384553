import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface DeleteUserMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface DeleteUserMfAuthMethodResponse {
  [key: string]: never;
}

export const DeleteUserMfAuthMethodHandler = async (
  message: DeleteUserMfAuthMethodMessage,
): Promise<DeleteUserMfAuthMethodResponse> => {
  const credential = message.mfAuthMethod.credential;

  await Api.resource(
    "portal",
    "/v2/portal/user/{credential}/mf-auth-methods/{mfAuthMethod}",
    {
      credential,
      mfAuthMethod: message.mfAuthMethod.id,
    },
  )
    .method("delete")
    .result();

  message.mfAuthMethod.$delete();

  return {};
};
