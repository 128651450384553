import { Tag } from "@kinherit/sdk";

export const TagMasterListBreadCrumb = {
  text: "Tags",
  route: { name: "TagMasterList" },
};

export const TagDetailsBreadCrumb = (params: any) => [
  TagMasterListBreadCrumb,
  {
    text: Tag.$findOne(params.tag)?.name ?? "",
    route: { name: "TagDetails", params },
  },
];

export const TagDetailsUpdateBreadCrumb = (params: any) => [
  ...TagDetailsBreadCrumb(params),
  {
    text: "Edit",
    route: { name: "TagUpdate", params },
  },
];

export const TagDetailsCreateBreadCrumb = [
  TagMasterListBreadCrumb,
  {
    text: "Create",
    route: { name: "TagCreate" },
  },
];
