import {
  AcuityBreadcrumb,
  DashboardBreadcrumb,
  PdfOptionsBreadcrumb,
  PdfUtilsBreadcrumb,
  SearchBreadcrumb,
} from "@/module/dashboard/breadcrumbs";
import { RouteInterface } from "@kinherit/framework/core/route-config";
import { Routes as DashboardStatisticRoutes } from "./dashboard-statistic";

export const DashboardIndexRoute = "DashboardIndex";
export const DashboardIndexUri = "/dashboard";

export const DashboardOverviewRoute = "DashboardOverview";
export const DashboardOverviewUri = "/dashboard/overview";

export const DashboardOverviewTodayRoute = "DashboardOverviewToday";
export const DashboardOverviewTodayUri = "/dashboard/overview/today";

export const DashboardOverviewTomorrowRoute = "DashboardOverviewTomorrow";
export const DashboardOverviewTomorrowUri = "/dashboard/overview/tomorrow";

export const DashboardOverviewOverdueRoute = "DashboardOverviewOverdue";
export const DashboardOverviewOverdueUri = "/dashboard/overview/overdue";

export const DashboardSearchRoute = "DashboardSearch";
export const DashboardSearchUri = "/dashboard/search";

export const DashboardAcuityRoute = "DashboardAcuity";
export const DashboardAcuityUri = "/dashboard/acuity";

export const PdfUtilsRoute = "PdfUtilsRoute";
export const PdfUtilsUri = "/dashboard/pdf-utils";

export const PdfOptionsRoute = "PdfOptionsRoute";
export const PdfOptionsUri = "/dashboard/pdf-options";

export const Routes: Array<RouteInterface> = [
  {
    name: DashboardIndexRoute,
    path: DashboardIndexUri,
    component: () => import("./Dashboard.Index.vue"),
    meta: {
      breadcrumbs: () => DashboardBreadcrumb,
    },
    redirect: {
      name: DashboardOverviewRoute,
      params: { tab: "today" },
    },
    children: [
      {
        name: DashboardOverviewRoute,
        path: DashboardOverviewUri,
        component: () => import("./Dashboard.Overview.vue"),
        meta: {
          breadcrumbs: () => DashboardBreadcrumb,
        },
      },
      {
        name: DashboardSearchRoute,
        path: DashboardSearchUri,
        component: () => import("./Dashboard.Search.vue"),
        meta: {
          breadcrumbs: () => SearchBreadcrumb,
        },
      },
      {
        name: DashboardAcuityRoute,
        path: DashboardAcuityUri,
        component: () => import("./Dashboard.Acuity.vue"),
        meta: {
          breadcrumbs: () => AcuityBreadcrumb,
        },
      },
      ...DashboardStatisticRoutes,
      {
        name: PdfUtilsRoute,
        path: PdfUtilsUri,
        component: () => import("./Dashboard.PdfUtils.vue"),
        meta: {
          breadcrumbs: () => PdfUtilsBreadcrumb,
        },
      },
      {
        name: PdfOptionsRoute,
        path: PdfOptionsUri,
        component: () => import("./Dashboard.PdfOptions.vue"),
        meta: {
          breadcrumbs: () => PdfOptionsBreadcrumb,
        },
      },
    ],
  },
];
