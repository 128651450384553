import {
  IntroducerContactField,
  OptionsSelectField,
} from "@/config/form.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { IntroducerCpd } from "@kinherit/sdk";

// export interface IIntroducerCpd {
//   status: string;
//   type: string;
//   contact: string;
//   session: string;
// }

export const CreateCpdSessionIntroducersForm = (
  introducerCpd: IntroducerCpd,
) => {
  return defineForm({
    name: "create-cpd-session-introducers-form",
    data: () => introducerCpd,
    formAreas: (data) => [
      defineFormArea({
        name: "create-cpd-session-introducers-form-area",
        data,
        template: GridLayout([["contact"], ["status"]]),
        components: () => ({
          contact: [
            IntroducerContactField({
              props: {
                vModel: "contact",
              },
            }),
          ],
          status: [
            OptionsSelectField({
              group: "introducerCpdStatus",
              vModel: "status",
            }),
          ],
        }),
      }),
    ],
  });
};
