<template>
  <div class="branded-kinvault-pricing-schema-master-list">
    <PageHeader htag="h1" text="Kinvault Pricing Schema">
      <template #buttons>
        <Button
          class="create"
          size="is-normal"
          @click="createBrandedKinvaultPricingSchema"
        >
          Add Branded Kinvault Price Schema
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.organisationName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #isDefault="{ row }">
        <Icon v-if="row.isDefault" icon="Tick" />
      </template>
      <template #actions="{ row }">
        <div class="buttons">
          <Button
            size="is-small"
            @click="handleEdit(row)"
            class="is-primary"
            icon-left="Edit"
            aria-label="Edit"
          />
          <Button
            size="is-small"
            @click="handleDelete(row)"
            class="is-danger"
            icon-left="Trash"
            aria-label="Delete"
          />
        </div>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultPricingSchemaMasterListWrapper",
  "route": "BrandedKinvaultPricingSchemaMasterList",
  "selector": ".branded-kinvault-pricing-schema-master-list",
  "imports": {
    "ReadBrandedKinvaultPricingSchemaFormWrapper": "@/module/admin.branded-kinvault/form/read-branded-kinvault-pricing-schema.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadBrandedKinvaultPricingSchemaFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadBrandedKinvaultPricingSchemaForm } from "@/module/admin.branded-kinvault/form/read-branded-kinvault-pricing-schema.form";
import { Icon } from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import {
  BrandedKinvaultPricingSchema,
  IBrandedKinvaultPricingSchema,
} from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultPricingSchemaMasterListRoute } from ".";
import { UpdateBrandedKinvaultPricingSchemaForm } from "../form/update-branded-kinvault-pricing-schema.form";

export default defineComponent({
  name: BrandedKinvaultPricingSchemaMasterListRoute,
  components: { MasterListPage, PageHeader, Button, Icon },
  data: () => ({
    filters: ReadBrandedKinvaultPricingSchemaForm(),
    columns: [
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Default",
        slot: "isDefault",
      },
      {
        title: "Start",
        map: (x) => x.startDate?.formatDate,
      },
      {
        title: "End",
        map: (x) => x.endDate?.formatDate,
      },
      {
        title: "Branded Kinvaults",
        map: (x) => x.$data.brandedKinvaults.length,
      },
      {
        title: "Created",
        map: (x) => x.createdAt.formatDateTime,
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<BrandedKinvaultPricingSchema>>,
    rows: Array<BrandedKinvaultPricingSchema>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvaultPricingSchema,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    async handleEdit(entry: BrandedKinvaultPricingSchema): Promise<void> {
      const form = UpdateBrandedKinvaultPricingSchemaForm(entry);

      try {
        await form.dialog({
          dialog: {
            title: "Edit Branded Kinvault Price Schema",
          },
        });
      } catch (error) {
        form.localData.$restore();
        throw error;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-pricing-schema/update",
      //   {
      //     brandedKinvaultPricingSchema: form.localData,
      //   },
      // );

      await window.Kernel.ActionBus.adminBrandedKinvault.kinvaultPricingSchema.update(
        {
          brandedKinvaultPricingSchema: form.localData,
        },
      );

      await this.refresh(this.filters.localData);
    },
    async handleDelete(entry: BrandedKinvaultPricingSchema): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Branded Kinvault Price Schema",
          message:
            "Are you sure you want to delete this Branded Kinvault Price Schema?",
        },
      });

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-pricing-schema/delete",
      //   {
      //     brandedKinvaultPricingSchema: entry,
      //   },
      // );

      await window.Kernel.ActionBus.adminBrandedKinvault.kinvaultPricingSchema.delete(
        {
          brandedKinvaultPricingSchema: entry,
        },
      );

      await this.refresh(this.filters.localData);
    },
    async refresh(
      formData: ReturnType<
        typeof ReadBrandedKinvaultPricingSchemaForm
      >["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-pricing-schema/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );

      const data =
        await window.Kernel.ActionBus.adminBrandedKinvault.kinvaultPricingSchema.read(
          {
            ...formData,
            sort: this.sort,
            pagination: this.pagination,
          },
        );

      this.rows = data.brandedKinvaultPricingSchemas;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async createBrandedKinvaultPricingSchema(): Promise<void> {
      const form = UpdateBrandedKinvaultPricingSchemaForm();

      try {
        await form.dialog({
          dialog: {
            title: "Add Branded Kinvault Price Schema",
          },
        });
      } catch (error) {
        form.localData.$delete();
        throw error;
      }

      // await window.Kernel.ActionBus.execute(
      //   "admin/branded-kinvault/kinvault-pricing-schema/create",
      //   {
      //     brandedKinvaultPricingSchema: form.localData,
      //   },
      // );

      await window.Kernel.ActionBus.adminBrandedKinvault.kinvaultPricingSchema.create(
        {
          brandedKinvaultPricingSchema: form.localData,
        },
      );

      await this.refresh(this.filters.localData);
    },
  },
});
</script>
