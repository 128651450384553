import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, Equal, In, Like } from "@kinherit/orm/index";
import {
  AccountReferralCode,
  Api,
  BrandedKinvaultInvitation,
  BrandedKinvaultInviteReferral,
  EmailAddress,
  IBrandedKinvaultInvitation,
  IntroducerContact,
  Profile,
  QueryMask,
} from "@kinherit/sdk";

export interface ReadBrandedKinvaultInvitationMessage {
  search?: string;
  status?: string[];
  created?: null | [number, number];
  brandedKinvault: string;
  query?: QueryMask<typeof BrandedKinvaultInvitation>;
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort?: {
    by: keyof IBrandedKinvaultInvitation;
    direction: "asc" | "desc";
  };
}

interface ReadBrandedKinvaultInvitationResponse {
  brandedKinvaultInvitations: Array<BrandedKinvaultInvitation>;
  accountReferralCode: Array<AccountReferralCode>;
  brandedKinvaultInviteReferral: Array<BrandedKinvaultInviteReferral>;
  profile: Array<Profile>;
  introducerContact: Array<IntroducerContact>;
  emailAddresses: EmailAddress[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadBrandedKinvaultInvitationHandler = async (
  message: ReadBrandedKinvaultInvitationMessage,
): Promise<ReadBrandedKinvaultInvitationResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/invitation",
  )
    .method("get")

    .sort(message.sort)
    .paginate(message.pagination);

  request.buildQuery(BrandedKinvaultInvitation).where({
    brandedKinvault: Equal(message.brandedKinvault),
    status: In(message.status),
    profile: {
      fullName: Like(message.search),
    },
    createdAt: Between(message.created),
    ...(message.query ?? {}),
  });

  const response = await request.result();

  return {
    brandedKinvaultInvitations: BrandedKinvaultInvitation.$inflate(
      response.brandedKinvaultInvitation,
      message.sort,
      response.$rootIds,
    ),
    accountReferralCode: AccountReferralCode.$inflate(
      response.accountReferralCode,
    ),
    brandedKinvaultInviteReferral: BrandedKinvaultInviteReferral.$inflate(
      response.brandedKinvaultInviteReferral,
    ),
    profile: Profile.$inflate(response.profile),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    introducerContact: IntroducerContact.$inflate(response.introducerContact),
    pagination: getPagination(response),
  };
};
