import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KinvaultOfficerDetailsBreadCrumb,
  KinvaultOfficerMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const KinvaultOfficerDetailsUpdateProfileRoute =
  "KinvaultOfficerDetailsUpdateProfile";
export const KinvaultOfficerDetailsUpdateProfileURI =
  "/officer/:officer/profile";
export type KinvaultOfficerDetailsUpdateProfileParams = RouteParamObject<
  typeof KinvaultOfficerDetailsUpdateProfileURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultOfficerDetailsUpdateProfileRoute,
    path: KinvaultOfficerDetailsUpdateProfileURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultOfficerDetailsUpdateProfile" */ "./KinvaultOfficerDetails.UpdateProfile.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        KinvaultOfficerMasterListBreadCrumb,
        KinvaultOfficerDetailsBreadCrumb(params),
        {
          text: "Profile",
          route: { name: KinvaultOfficerDetailsUpdateProfileRoute, params },
        },
      ],
    },
  },
];
