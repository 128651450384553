import { EmailTemplateDetailsBreadcrumbs } from "@/module/admin.email-template/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const EmailTemplateDetailsSummaryRoute = "EmailTemplateDetailsSummary";
export const EmailTemplateDetailsSummaryURI =
  "/admin/email-template/:emailTemplate";
export type EmailTemplateDetailsSummaryParams = RouteParamObject<
  typeof EmailTemplateDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: EmailTemplateDetailsSummaryRoute,
    path: EmailTemplateDetailsSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "EmailTemplateDetailsSummary" */ "./EmailTemplateDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => EmailTemplateDetailsBreadcrumbs(params),
    },
  },
];
