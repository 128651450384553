import { Api } from "@/service/api.service";
import { UploadForm } from "@kinherit/sdk";

interface UpdateUploadFormMessage {
  uploadForm: UploadForm;
}

interface UpdateUploadFormResponse {
  uploadForm: UploadForm;
}

export const UpdateUploadFormHandler = async (
  message: UpdateUploadFormMessage,
): Promise<UpdateUploadFormResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/upload-form/upload-forms/{uploadForm}",
    {
      uploadForm: message.uploadForm.id,
    },
  )
    .method("patch")
    .body({
      name: message.uploadForm.$data.name,
      accountReferral: message.uploadForm.$data.accountReferral,
      validTill: message.uploadForm.$data.validTill,
    })

    .result();

  const uploadForm = UploadForm.$inflate(response.uploadForm).first();

  if (!uploadForm) {
    throw new Error("Failed to Update upload form");
  }

  return {
    uploadForm,
  };
};
