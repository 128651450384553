import {
  Api,
  EmailAddress,
  IntroducerFeePaymentRun,
} from "@kinherit/sdk/index";

// export class SendIntroducerFeePaymentRunRemittanceHandler extends ActionBase {
//   constructor(
//     private data: {
//       introducerFeePaymentRun: IntroducerFeePaymentRun | string;
//     },
//   ) {
//     super();
//   }

//   async execute() {
export const SendIntroducerFeePaymentRunRemittanceHandler = async (data: {
  introducerFeePaymentRun: IntroducerFeePaymentRun | string;
  overrideEmailAddress?: EmailAddress | null;
}): Promise<void> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment/remittance/send",
  )
    .method("put")
    .body({
      introducerFeePaymentRun: data.introducerFeePaymentRun.toString(),
      overrideEmailAddress: data.overrideEmailAddress?.id,
    })
    .result();
};
