import { Api } from "@/service/api.service";
import { IntroducerContract } from "@kinherit/sdk";

interface DeleteIntroducerCompanyIntroducerContractMessage {
  introducerContract: IntroducerContract;
}

interface DeleteIntroducerCompanyIntroducerContractResponse {
  [key: string]: never;
}

export const DeleteIntroducerCompanyIntroducerContractHandler = async (
  message: DeleteIntroducerCompanyIntroducerContractMessage,
): Promise<DeleteIntroducerCompanyIntroducerContractResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-company-introducer-contract/{introducerCompany}/contracts/{introducerContract}",
    {
      introducerCompany: message.introducerContract.$data.company,
      introducerContract: message.introducerContract.$data.id,
    },
  )
    .method("delete")

    .result();

  message.introducerContract.$delete();

  return {};
};
