import { Api } from "@/service/api.service";
import { DefaultBrandedKinvaultReferralCode } from "@kinherit/sdk";

interface DeleteDefaultBrandedKinvaultReferralCodeMessage {
  defaultBrandedKinvaultReferralCode: DefaultBrandedKinvaultReferralCode;
}

interface DeleteDefaultBrandedKinvaultReferralCodeResponse {
  [key: string]: never;
}

export const DeleteDefaultBrandedKinvaultReferralCodeHandler = async (
  message: DeleteDefaultBrandedKinvaultReferralCodeMessage,
): Promise<DeleteDefaultBrandedKinvaultReferralCodeResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/referral/default-branded-kinvault-referral-code/{defaultBrandedKinvaultReferralCode}",
    {
      defaultBrandedKinvaultReferralCode:
        message.defaultBrandedKinvaultReferralCode,
    },
  )
    .method("delete")

    .result();

  message.defaultBrandedKinvaultReferralCode.$delete();

  return {};
};
