import { Api } from "@/service/api.service";
import { Order, OrderItem } from "@kinherit/sdk";

export interface CreateOrderItemMessage {
  order: Order;
  orderItem: OrderItem;
}

export interface CreateOrderItemResponse {
  orderItems: Array<OrderItem>;
}

export const CreateOrderItemHandler = async (
  message: CreateOrderItemMessage,
): Promise<CreateOrderItemResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/order/{order}/order-items",
    {
      order: message.order.id,
    },
  )
    .method("post")

    .body({
      price: message.orderItem.$data.price,
      quantity: message.orderItem.$data.quantity,
      recurring: message.orderItem.$data.recurring,
      requiresDd: message.orderItem.$data.requiresDd,
      text: message.orderItem.$data.text,
      value: message.orderItem.$data.value,
      order: message.order.$data.id,
      product: message.orderItem.$data.product,
    });

  const response = await request.result();

  message.orderItem.$delete();

  return {
    orderItems: OrderItem.$inflate(
      response.orderItem,
      undefined,
      response.$rootIds,
    ),
  };
};
