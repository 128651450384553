import { Api } from "@/service/api.service";
import { FileLog } from "@kinherit/sdk";

interface UpdateKintinFileMessage {
  fileLog: FileLog;
  uploadedFile: File | null;
}

interface UpdateKintinFileResponse {
  fileLog: FileLog;
}

export const UpdateKintinFileHandler = async (
  message: UpdateKintinFileMessage,
): Promise<UpdateKintinFileResponse> => {
  const kintin = message.fileLog.$data.kintin;

  if (!kintin) {
    throw new Error("Kintin is required");
  }

  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-file/{kintin}/files/{fileLog}",
    {
      kintin,
      fileLog: message.fileLog,
    },
  )
    .method("patch")

    .body({
      kintin: message.fileLog.$data.kintin,
      type: message.fileLog.$data.type,
      subType: message.fileLog.$data.subType,
      tab: message.fileLog.$data.tab,
      fileName: message.fileLog.$data.fileName,
      fileExtension: message.fileLog.$data.fileExtension,
      fileSize: message.fileLog.$data.fileSize,
      notes: message.fileLog.$data.notes,
    })
    .result();

  const fileLog = FileLog.$inflate(response.fileLog).first();

  if (!fileLog) {
    throw new Error("Failed to update file");
  }

  if (message.uploadedFile) {
    await Api.resource(
      "portal",
      "/v2/portal/kintin-file/{kintin}/files/{file}/upload",
      {
        kintin,
        file: fileLog.$data.id,
      },
    )
      .method("put")

      .files({
        uploadedFile: message.uploadedFile,
      })
      .result();
  }

  return { fileLog };
};
