import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { AuthService } from "@/service/auth.service";
import { Kintin } from "@kinherit/sdk";
import { defineComponent } from "vue";

export const KinvaultKintinDetailsMixin = defineComponent({
  name: "kinvault-kintin-details-mixin",
  computed: {
    $params(): KinvaultKintinDetailsParams {
      return this.$route.params as KinvaultKintinDetailsParams;
    },
    kintin(): Kintin | null {
      return Kintin.$findOne(this.$params.kintin);
    },
    hasWriteAccess(): boolean {
      return AuthService.hasPermission("kintin:write");
    },
  },
  methods: {
    visitKintinDetails(): void {
      window.Kernel.Router.push({
        name: KinvaultKintinDetailsRoute,
        params: { kintin: this.kintin?.id },
      });
    },
  },
});
