import { Api } from "@/service/api.service";
import { EmailAddress, Profile, QueryMask } from "@kinherit/sdk";

export interface ReadEmailAddressMessage {
  query?: QueryMask<typeof EmailAddress>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
}

interface ReadEmailAddressResponse {
  emailAddresses: Array<EmailAddress>;
  profiles: Array<Profile>;
}

export const ReadEmailAddressHandler = async (
  message: ReadEmailAddressMessage,
): Promise<ReadEmailAddressResponse> => {
  const request = Api.resource("core", "/v2/core/select/email-address")
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(EmailAddress).where(message.query ?? {});

  const response = await request.result();

  return {
    emailAddresses: EmailAddress.$inflate(
      response.emailAddress,
      undefined,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
  };
};
