import { Api } from "@/service/api.service";
import { EmailTemplate } from "@kinherit/sdk";

export interface DeleteEmailTemplateMessage {
  emailTemplate: EmailTemplate | string;
}

interface DeleteEmailTemplateResponse {
  [key: string]: never;
}

export const DeleteEmailTemplateHandler = async (
  message: DeleteEmailTemplateMessage,
): Promise<DeleteEmailTemplateResponse> => {
  await Api.resource("portal", "/v2/portal/email-template/{emailTemplate}", {
    emailTemplate: message.emailTemplate,
  })
    .method("delete")

    .result();

  if (message.emailTemplate instanceof EmailTemplate) {
    message.emailTemplate.$delete();
  } else {
    EmailTemplate.$findOne(message.emailTemplate)?.$delete();
  }

  return {};
};
