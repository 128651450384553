import { Api } from "@/service/api.service";
import { Kintin, Trustee } from "@kinherit/sdk";

interface UpdateTrusteeMessage {
  kintin: string | Kintin;
  trustee: Trustee;
}

interface UpdateTrusteeResponse {
  trustee: Trustee;
}

export const UpdateTrusteeHandler = async (
  message: UpdateTrusteeMessage,
): Promise<UpdateTrusteeResponse> => {
  const { trustee: trusteeData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-trustee/{kintin}/person/trustee/{trustee}",
    {
      kintin: message.kintin,
      trustee: message.trustee.$data.id,
    },
  )
    .method("patch")

    .body({
      forPerson: message.trustee.$data.forPerson,
      person: message.trustee.$data.person,
      isReserve: message.trustee.$data.isReserve,
      notes: message.trustee.$data.notes,
      sortOrder: message.trustee.$data.sortOrder,
      attainmentAge: message.trustee.$data.attainmentAge,
    })
    .result();

  const trustee = Trustee.$inflate(trusteeData).first();

  if (!trustee) {
    throw new Error("Failed to update trustee");
  }

  return { trustee };
};
