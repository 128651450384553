<template>
  <strong>Distribution of the Residue</strong><br />
  <p>
    SUBJECT to the trusts DECLARED above my Executors SHALL HOLD my Estate upon
    trust for the trustees of the trust as defined in
    <strong>Schedule <span v-html="helpers.var(position)" /></strong>
    of this my Will to hold under the terms of the said trust
  </p>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    position() {
      return this.helpers.getSchedulePosition(WillBuilderSections.ScheduleRES);
    },
  },
});
</script>
