import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptKinvaultRoute =
  "KintinDetailsCallScriptKinvault";
export const KintinDetailsCallScriptKinvaultURI =
  "/kinvault/:kintin/call-script/kinvault";
export type KintinDetailsCallScriptKinvaultParams = RouteParamObject<
  typeof KintinDetailsCallScriptKinvaultURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptKinvaultRoute,
    path: KintinDetailsCallScriptKinvaultURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptKinvault" */ "./KinvaultKintinDetails.CallScript.Kinvault.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Kinvault",
          route: { name: KintinDetailsCallScriptKinvaultRoute, params },
        },
      ],
    },
  },
];
