import { Between, Like } from "@kinherit/orm/index";
import { Api, ITag, Tag } from "@kinherit/sdk";

export interface ReadTagMessage {
  name?: string;
  created?: null | [number, number];
  pagination?: {
    currentPage?: number;
    perPage?: number;
  };
  sort: {
    by: keyof ITag;
    direction: "asc" | "desc";
  };
}

interface ReadTagResponse {
  tags: Array<Tag>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadTagHandler = async (
  message: ReadTagMessage,
): Promise<ReadTagResponse> => {
  const request = Api.resource("portal", "/v2/portal/tag")
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(Tag).where({
    name: Like(message.name),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    tags: Tag.$inflate(response.tag, message.sort, response.$rootIds),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
