import { Api } from "@/service/api.service";
import { MfAuthMethod } from "@kinherit/sdk";

interface ResendUserMfAuthMethodMessage {
  mfAuthMethod: MfAuthMethod;
}

interface ResendUserMfAuthMethodResponse {}

export const ResendUserMfAuthMethodHandler = async (
  message: ResendUserMfAuthMethodMessage,
): Promise<ResendUserMfAuthMethodResponse> => {
  await Api.resource("portal", "/v2/portal/user/{mfAuthMethod}/resend", {
    mfAuthMethod: message.mfAuthMethod,
  })
    .method("put")

    .result();

  return {};
};
