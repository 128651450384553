import { Api, Kintin, SignDoc } from "@kinherit/sdk";

export interface DeleteKintinSignDocMessage {
  signDoc: SignDoc | string;
  kintin: Kintin | string;
}

export interface DeleteKintinSignDocResponse {
  [key: string]: NodeEventEmitter;
}

export const DeleteKintinSignDocHandler = async (
  message: DeleteKintinSignDocMessage,
): Promise<DeleteKintinSignDocResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/kintin-sign-doc/{kintin}/sign-docs/{signDoc}",
    {
      kintin:
        "string" === typeof message.kintin ? message.kintin : message.kintin.id,
      signDoc:
        "string" === typeof message.signDoc
          ? message.signDoc
          : message.signDoc.id,
    },
  )
    .method("delete")
    .result();

  const signDoc =
    "string" === typeof message.signDoc
      ? SignDoc.$findOne(message.signDoc)
      : message.signDoc;

  signDoc?.$delete();

  return {};
};
