import { Api } from "@/service/api.service";
import {
  AccountReferral,
  AccountReferralCode,
  IAccountReferral,
  IntroducerCompany,
  IntroducerContact,
  Kintin,
  Profile,
} from "@kinherit/sdk";

export interface ReadIntroducerCompanyAccountReferralKintinsMessage {
  introducerCompany: IntroducerCompany | string;
  referralCode: AccountReferralCode | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IAccountReferral;
    direction: "asc" | "desc";
  };
}

export interface ReadIntroducerCompanyAccountReferralKintinsResponse {
  kintins: Array<Kintin>;
  introducerContacts: Array<IntroducerContact>;
  profiles: Array<Profile>;
  referrals: Array<AccountReferral>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadIntroducerCompanyAccountReferralKintinsHandler = async (
  message: ReadIntroducerCompanyAccountReferralKintinsMessage,
): Promise<ReadIntroducerCompanyAccountReferralKintinsResponse> => {
  const request = Api.resource(
    "portal",
    "/v2/portal/introducer-company-referral/{introducerCompany}/referrals/{referralCode}/kintins",
    {
      introducerCompany:
        "string" === typeof message.introducerCompany
          ? message.introducerCompany
          : message.introducerCompany.id,
      referralCode:
        "string" === typeof message.referralCode
          ? message.referralCode
          : message.referralCode.id,
    },
  )
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  const response = await request.result();

  return {
    kintins: Kintin.$inflate(response.kintin, message.sort, response.$rootIds),
    introducerContacts: IntroducerContact.$inflate(response.introducerContact),
    profiles: Profile.$inflate(response.profile),
    referrals: AccountReferral.$inflate(response.accountReferral),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
