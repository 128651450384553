<template>
  <Tabs :config="documentTabs" v-model:tab="computedTab" />
</template>

<script lang="ts">
import { KinvaultKintinDetailsMixin } from "@/module/kinvault.kintin/mixin/kintin-details.mixin";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import Tabs, { TabOptions } from "@kinherit/framework/component.display/tabs";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WillBuilderDocumentTabs",
  mixins: [KinvaultKintinDetailsMixin, WillBuilderService.mixin],
  components: {
    Tabs,
  },
  computed: {
    computedTab: {
      get(): number {
        return this.documentTabs.findIndex(
          (tab) => tab.value === this.options.document,
        );
      },
      set(value: number) {
        this.options.document = this.documentTabs[value].value;
      },
    },
    documentTabs(): (TabOptions & {
      value: "will" | "mow-business" | "mow-nrb" | "mow-res" | "mow-iip";
    })[] {
      const result: (TabOptions & {
        value: "will" | "mow-business" | "mow-nrb" | "mow-res" | "mow-iip";
      })[] = [{ label: "Will", value: "will" }];

      if (this.form?.hasBusinessTrust) {
        result.push({ label: "MOW Business", value: "mow-business" });
      }

      if (this.form?.hasEstateProtectionTrust && this.form?.hasIipTrust) {
        result.push({ label: "MOW NRB", value: "mow-nrb" });
      }

      if (this.form?.hasEstateProtectionTrust && !this.form?.hasIipTrust) {
        result.push({ label: "MOW RES", value: "mow-res" });
      }

      if (this.form?.hasIipTrust) {
        result.push({ label: "MOW IIP", value: "mow-iip" });
      }

      return result;
    },
  },
});
</script>
