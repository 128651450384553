import { Api } from "@/service/api.service";
import { BrandedKinvaultInvitation } from "@kinherit/sdk";

interface SendBrandedKinvaultInvitationEmailMessage {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

interface SendBrandedKinvaultInvitationEmailResponse {
  brandedKinvaultInvitation: BrandedKinvaultInvitation;
}

export const SendBrandedKinvaultInvitationEmailHandler = async (
  message: SendBrandedKinvaultInvitationEmailMessage,
): Promise<SendBrandedKinvaultInvitationEmailResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/branded-kinvault/invitation/{brandedKinvaultInvitation}/send/{invitationType}",
    {
      brandedKinvaultInvitation: message.brandedKinvaultInvitation,
      invitationType: "initial",
    },
  )
    .method("put")
    .result();

  const brandedKinvaultInvitation = BrandedKinvaultInvitation.$inflate(
    response.brandedKinvaultInvitation,
  ).first();

  if (!brandedKinvaultInvitation) {
    throw new Error("Failed to send branded kinvault invitation email");
  }

  return {
    brandedKinvaultInvitation,
  };
};
