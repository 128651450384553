import { Api } from "@/service/api.service";
import { CallScript, Kintin } from "@kinherit/sdk";

interface UpdateKintinCallScriptMessage {
  kintin: Kintin;
}

interface UpdateKintinCallScriptResponse {
  callScript: CallScript;
}

export const UpdateKintinCallScriptHandler = async (
  message: UpdateKintinCallScriptMessage,
): Promise<UpdateKintinCallScriptResponse> => {
  const localCallScript = message.kintin.callScript;
  console.log(localCallScript);

  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-call-script/{kintin}/call-script/{callScript}",
    {
      kintin: message.kintin,
      callScript: localCallScript,
    },
  )
    .method("patch")

    .body({
      person2Present: localCallScript.$data.person2Present,
      advSavingsValue: localCallScript.$data.advSavingsValue,
      advDebtValue: localCallScript.$data.advDebtValue,
      advCashValue: localCallScript.$data.advCashValue,
      advPolicyValue: localCallScript.$data.advPolicyValue,
      advPensionValue: localCallScript.$data.advPensionValue,
      advOtherAssetValue: localCallScript.$data.advOtherAssetValue,
      advBusinessAssetValue: localCallScript.$data.advBusinessAssetValue,
      advPotentialValue: localCallScript.$data.advPotentialValue,
      advPropsValue: localCallScript.$data.advPropsValue,
      requiresDeedOfSev: localCallScript.$data.requiresDeedOfSev,
      requiresPoa: localCallScript.$data.requiresPoa,
      requiresAssetProtection: localCallScript.$data.requiresAssetProtection,
      beneficiaryAge: localCallScript.$data.beneficiaryAge,
      mowGuardians: localCallScript.$data.mowGuardians,
      advGuardianNotes: localCallScript.$data.advGuardianNotes,
      advBeneficiaryNotes: localCallScript.$data.advBeneficiaryNotes,
      advExclusionNotes: localCallScript.$data.advExclusionNotes,
      advIfaNotes: localCallScript.$data.advIfaNotes,
      advGiftNotes: localCallScript.$data.advGiftNotes,
      advExecutorNotes: localCallScript.$data.advExecutorNotes,
      advTrusteenNotes: localCallScript.$data.advTrusteenNotes,
      advAttorneyenNotes: localCallScript.$data.advAttorneyenNotes,
      advHteStateBene: localCallScript.$data.advHteStateBene,
      advHteStateValue: localCallScript.$data.advHteStateValue,
      advR2RNotes: localCallScript.$data.advR2RNotes,
      advBusinessNotes: localCallScript.$data.advBusinessNotes,
      advIhteStateSitu: localCallScript.$data.advIhteStateSitu,
    })
    .result();

  const callScript = CallScript.$inflate(response.callScript).first();

  if (!callScript) {
    throw new Error("Failed to update call script");
  }

  return {
    callScript: callScript,
  };
};
