<template>
  <div class="introducer-contact-details-summary">
    <DashboardStatisticWidgetWrapper
      title="Contact Stats"
      class="dashboard-statistic-widget-wrapper"
      v-if="!loading.stats"
    >
      <DashboardStatisticWidgetGroup v-bind="leadStats" />
      <DashboardStatisticWidgetGroup v-bind="cpdStats" />
    </DashboardStatisticWidgetWrapper>

    <Card v-if="notes.length > 0" title="Pinned Notes" class="pinned-notes">
      <NoteSummary
        v-for="note in $data.notes"
        :key="`introducer-note-${note.id}`"
        :note="note"
        is-plain
      />
    </Card>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerContactDetailsSummaryWrapper",
  "route": "IntroducerContactDetailsSummary",
  "selector": ".introducer-contact-details-summary"
}
</cypress-wrapper>

<script lang="ts">
import NoteSummary from "@/module/core/component/note/NoteSummary.vue";
import DashboardStatisticWidgetGroup from "@/shared/component/widget/DashboardStatisticWidgetGroup.vue";
import DashboardStatisticWidgetWrapper from "@/shared/component/widget/DashboardStatisticWidgetWrapper.vue";
import { TDashboardStatisticWidgetGroupProps } from "@/shared/component/widget/dashboard-statistic-widget";
import Card from "@kinherit/framework/component.layout/card";
import { Equal } from "@kinherit/orm/index";
import { Note } from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import { IntroducerContactDetailsSummaryRoute } from ".";
import { IntroducerContactDetailsMixin } from "../../../mixin/introducer-contact-details.mixin";

export default defineComponent({
  name: IntroducerContactDetailsSummaryRoute,
  mixins: [IntroducerContactDetailsMixin],
  components: {
    Card,
    NoteSummary,
    DashboardStatisticWidgetGroup,
    DashboardStatisticWidgetWrapper,
  },
  data: () => ({
    leadStats: {} as TDashboardStatisticWidgetGroupProps,
    cpdStats: {} as TDashboardStatisticWidgetGroupProps,
    loading: {
      stats: true,
      notes: true,
    },
    notes: Array<Note>(),
  }),
  mounted(): void {
    this.loadStats();
    this.loadNotes();
  },
  methods: {
    async loadStats(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      // const data = await window.Kernel.ActionBus.execute(
      //   "introducer/contact/compute/stats",
      //   {
      //     introducerContact: this.introducerContact,
      //   },
      // );

      const data = await window.Kernel.ActionBus.introducerContact.computeStats(
        {
          introducerContact: this.introducerContact,
        },
      );

      this.leadStats = {
        title: "Leads",
        details: [
          {
            label: "Total Leads",
            value: data.totalLeads.toString(),
          },
          {
            label: "Life Time Value",
            value: new Currency({
              amount: data.lifeTimeValue,
            }).format,
          },
          {
            label: "Last Lead",
            value: data.lastLead?.formatDate ?? "N/A",
            subValue:
              data.lastLead?.since({
                datetime: new DateTime(),
                format: "day",
              }) ?? "N/A",
          },
        ],
      };

      this.cpdStats = {
        title: "CPD",
        details: [
          {
            label: "Last CPD Attendance",
            value: data.lastCpdAttendance?.formatDate ?? "N/A",
            subValue:
              data.lastCpdAttendance?.since({
                datetime: new DateTime(),
                format: "day",
              }) ?? "N/A",
          },
        ],
      };

      this.loading.stats = false;
    },
    async loadNotes(): Promise<void> {
      if (!this.introducerContact) {
        return;
      }

      // const { notes } = await window.Kernel.ActionBus.execute(
      //   "core/note/read",
      //   {
      //     introducerContact: this.introducerContact,
      //     query: {
      //       pinned: Equal(true),
      //     },
      //     sort: {
      //       by: "createdAt",
      //       direction: "desc",
      //     },
      //     pagination: {
      //       currentPage: 1,
      //       perPage: 3,
      //     },
      //   },
      // );

      const { notes } = await window.Kernel.ActionBus.core.note.read({
        introducerContact: this.introducerContact,
        query: {
          pinned: Equal(true),
        },
        sort: {
          by: "createdAt",
          direction: "desc",
        },
        pagination: {
          currentPage: 1,
          perPage: 3,
        },
      });

      this.notes = notes;

      this.loading.notes = false;
    },
  },
});
</script>

<style lang="scss">
.introducer-contact-details-summary {
  .pinned-notes {
    .note-summary:not(:last-child) {
      border-bottom: var(--color-border) 1px solid;
    }
  }
}
</style>
