import { Api } from "@/service/api.service";
import { IntroducerFeePayment } from "@kinherit/sdk";

export interface DeleteIntroducerFeePaymentMessage {
  introducerFeePayment: IntroducerFeePayment | string;
}

export interface DeleteIntroducerFeePaymentResponse {}

export const DeleteIntroducerFeePaymentHandler = async (
  message: DeleteIntroducerFeePaymentMessage,
): Promise<DeleteIntroducerFeePaymentResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/introducer-fee-payment/{introducerFeePayment}",
    message,
  )
    .method("delete")
    .result();

  IntroducerFeePayment.$delete(message.introducerFeePayment);

  return {};
};
