import { Api } from "@/service/api.service";
import { IntroducerCompany, Profile } from "@kinherit/sdk";

interface CreateIntroducerCompanyMessage {
  introducerCompany: IntroducerCompany;
}

interface CreateIntroducerCompanyResponse {
  introducerCompany: IntroducerCompany;
  profiles: Array<Profile>;
}

export const CreateIntroducerCompanyHandler = async (
  message: CreateIntroducerCompanyMessage,
): Promise<CreateIntroducerCompanyResponse> => {
  const response = await Api.resource("portal", "/v2/portal/introducer-company")
    .method("post")

    .body({
      assignedTo: message.introducerCompany.$data.assignedTo,
      defaultLegalAssistant:
        message.introducerCompany.$data.defaultLegalAssistant,
      seniorAdviser: message.introducerCompany.$data.seniorAdviser,
      description: message.introducerCompany.$data.description,
      status: message.introducerCompany.$data.status,
      stage: message.introducerCompany.$data.stage,
      type: message.introducerCompany.$data.type,
      introducedBy: message.introducerCompany.$data.introducedBy,
      profile: {
        organisationName:
          message.introducerCompany.profile.$data.organisationName,
        organisationNumber:
          message.introducerCompany.profile.$data.organisationNumber,
        phoneNumbers: message.introducerCompany.profile.phoneNumbers.map(
          (phoneNumber) => ({
            tel: phoneNumber.tel,
          }),
        ),
        emails: message.introducerCompany.profile.emails.map((email) => ({
          email: email.email,
        })),
      },
    })
    .result();

  const introducerCompany = IntroducerCompany.$inflate(
    response.introducerCompany,
  ).first();

  if (!introducerCompany) {
    throw new Error("Failed to create introducercompany");
  }

  message.introducerCompany.$delete({
    profile: {
      addresses: true,
      phoneNumbers: true,
      emails: true,
      websites: true,
    },
  });

  return {
    introducerCompany,
    profiles: Profile.$inflate(response.profile),
  };
};
