import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerContactDetailsBreadCrumb,
  IntroducerContactMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerContactDetailsCpdSessionRoute =
  "IntroducerContactDetailsCpdSession";
export const IntroducerContactDetailsCpdSessionURI =
  "/introducer/contact/:introducerContact/cpd-session";
export type IntroducerContactDetailsCpdSessionParams = RouteParamObject<
  typeof IntroducerContactDetailsCpdSessionURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerContactDetailsCpdSessionRoute,
    path: IntroducerContactDetailsCpdSessionURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerContactDetailsCpdSession" */ "./IntroducerContactDetails.CpdSession.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerContactMasterListBreadCrumb,
        IntroducerContactDetailsBreadCrumb(params),
        {
          text: "CPD Session Attendance",
          route: {
            name: IntroducerContactDetailsCpdSessionRoute,
            params,
          },
        },
      ],
    },
  },
];
