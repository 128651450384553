import { Api } from "@/service/api.service";
import { Address, CpdSession, Website } from "@kinherit/sdk";

export interface CreateCpdSessionMessage {
  cpdSession: CpdSession;
}

export interface CreateCpdSessionResponse {
  cpdSessions: Array<CpdSession>;
  addresses: Array<Address>;
  websites: Array<Website>;
}

export const CreateCpdSessionHandler = async (
  message: CreateCpdSessionMessage,
): Promise<CreateCpdSessionResponse> => {
  const request = Api.resource("portal", "/v2/portal/cpd-session", {})
    .method("post")

    .body({
      address:
        null === message.cpdSession.address
          ? null
          : {
              line1: message.cpdSession.address.$data.line1,
              line2: message.cpdSession.address.$data.line2,
              city: message.cpdSession.address.$data.city,
              country: message.cpdSession.address.$data.country,
              postcode: message.cpdSession.address.$data.postcode,
              state: message.cpdSession.address.$data.state,
            },
      allowBookings: message.cpdSession.$data.allowBookings,
      date: message.cpdSession.$data.date,
      duration: message.cpdSession.$data.duration,
      outcomes: message.cpdSession.$data.outcomes,
      title: message.cpdSession.$data.title,
      type: message.cpdSession.$data.type,
      websites: message.cpdSession.websites.map((website) => ({
        url: website.$data.url,
      })),
      isPrivate: message.cpdSession.$data.isPrivate,
    });

  const response = await request.result();

  message.cpdSession.$delete({
    address: true,
  });

  message.cpdSession.$delete();

  return {
    cpdSessions: CpdSession.$inflate(
      response.cpdSession,
      undefined,
      response.$rootIds,
    ),
    addresses: Address.$inflate(response.address, undefined, response.$rootIds),
    websites: Website.$inflate(response.website, undefined, response.$rootIds),
  };
};
