import { Api } from "@/service/api.service";
import { Between, Equal, In, Like } from "@kinherit/orm";
import {
  Attorney,
  Executor,
  Guardian,
  IPerson,
  Kintin,
  Person,
  Profile,
  Trustee,
} from "@kinherit/sdk";

export interface ReadOfficerMessage {
  customer?: string;
  status?: Array<string>;
  assignedTo?: null | string;
  created?: null | [number, number];
  pagination:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IPerson;
    direction: "asc" | "desc";
  };
}

interface ReadOfficerResponse {
  kintins: Array<Kintin>;
  profiles: Array<Profile>;
  people: Array<Person>;
  trustees: Array<Trustee>;
  attorneys: Array<Attorney>;
  executors: Array<Executor>;
  guardians: Array<Guardian>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadOfficerHandler = async (
  message: ReadOfficerMessage,
): Promise<ReadOfficerResponse> => {
  const request = Api.resource("portal", "/v2/portal/officer")
    .method("get")

    .paginate(message.pagination)
    .sort({
      sortBy: message.sort.by,
      sortDirection: message.sort.direction,
    });

  request.buildQuery(Kintin).where({
    friendlyName: Like(message.customer),
    people: {
      officerCallStatus: {
        id: In(message.status),
      },
    },
    estatePlanners: {
      id: Equal(message.assignedTo),
    },
    createdAt: Between(message.created),
  });

  const result = await request.result();

  return {
    kintins: Kintin.$inflate(result.kintin, message.sort, result.$rootIds),
    profiles: Profile.$inflate(result.profile),
    people: Person.$inflate(result.person),
    trustees: Trustee.$inflate(result.trustee),
    attorneys: Attorney.$inflate(result.attorney),
    executors: Executor.$inflate(result.executor),
    guardians: Guardian.$inflate(result.guardian),
    pagination: {
      currentPage: Number.parseInt(
        (result.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((result.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((result.$pagination?.count as any) ?? "0"),
    },
  };
};
