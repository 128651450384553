import { CreateUploadFormHandler } from "@/shared/action/upload-form/create-upload-form.action";
import { DeleteUploadFormHandler } from "@/shared/action/upload-form/delete-upload-form.action";
import { DownloadUploadFormSubmissionHandler } from "@/shared/action/upload-form/download-upload-form-submission.action";
import { ReadUploadFormsHandler } from "@/shared/action/upload-form/read-upload-forms.action";
import { UpdateUploadFormHandler } from "@/shared/action/upload-form/update-upload-form.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Actions = {
  "upload-form/delete": DeleteUploadFormHandler,
  "upload-form/create": CreateUploadFormHandler,
  "upload-form/update": UpdateUploadFormHandler,
  "upload-form/read": ReadUploadFormsHandler,
  "upload-form/download-submission": DownloadUploadFormSubmissionHandler,
};

export const SharedActionBus = DefineActionBus({
  name: "Shared",
  actions: {
    uploadForm: DefineActionBus({
      name: "UploadForm",
      actions: {
        delete: DeleteUploadFormHandler,
        create: CreateUploadFormHandler,
        update: UpdateUploadFormHandler,
        read: ReadUploadFormsHandler,
        downloadSubmission: DownloadUploadFormSubmissionHandler,
      },
    }),
  },
});
