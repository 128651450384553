import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  AccountReferralCodeDetailsBreadCrumb,
  AccountReferralCodeMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const AccountReferralCodeDetailsSummaryRoute =
  "AccountReferralCodeDetailsSummary";
export const AccountReferralCodeDetailsSummaryURI =
  "/admin/referral-code/:accountReferralCode";
export type AccountReferralCodeDetailsSummaryParams = RouteParamObject<
  typeof AccountReferralCodeDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: AccountReferralCodeDetailsSummaryRoute,
    path: AccountReferralCodeDetailsSummaryURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "AccountReferralCodeDetailsSummary" */ "./AccountReferralCode.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        AccountReferralCodeMasterListBreadCrumb,
        AccountReferralCodeDetailsBreadCrumb(params),
      ],
    },
  },
];
