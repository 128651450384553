<template>
  <span>
    <strong>Legacy of Business Property</strong><br />
    <p>
      I give free of tax the maximum amount of my Relievable Business Property
      which could be given to them by this my Will without inheritance tax
      becoming payable in respect of my Estate upon trust for the trustees of
      the trust as defined in
      <strong>
        Schedule
        <span v-html="helpers.var(position)" />
      </strong>
      of this my Will to hold under the terms of the said trust
      <br /><br />
      For the purposes of this clause:
    </p>
    <ol type="i">
      <li>
        <strong>'Business Property'</strong> shall mean relevant business
        property for the purposes of s 105 of the Inheritance Tax Act 1984 and
        business property relief shall mean the relief given by that section
      </li>
      <li>
        <strong>'my Relievable Business Property'</strong> shall mean my
        Business Property which qualifies for business relief and no part of the
        value of which is attributed to excepted assets for the purpose of s 112
        of the Inheritance Tax Act 1984
      </li>
    </ol>
    <p>
      I declare that for the avoidance of doubt where any liability of mine is
      secured on property which is not Relievable Business Property shall not be
      discharged out of my Relievable Business Property
    </p>
  </span>
</template>

<script lang="ts">
import { WillBuilderSections } from "@/module/kinvault.kintin/data/will-builder.data";
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
  computed: {
    position() {
      return this.helpers.getSchedulePosition(
        WillBuilderSections.ScheduleBusiness,
      );
    },
  },
});
</script>
