<template>
  <div v-if="data">
    <strong>Anticipation of Marriage</strong>
    <p>
      At the time of making this my Will I expect to marry
      <span
        class="ucase"
        v-html="helpers.var(data.partner?.profile.fullName).toUpperCase()"
      />
      and intend that this my Will shall not be revoked by my marriage to the
      said
      <span
        class="ucase"
        v-html="helpers.var(data.partner?.profile.fullName).toUpperCase()"
      />
    </p>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
