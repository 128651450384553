<template>
  <p v-if="data">
    <strong>Financial Advice</strong><br />
    It is my express wish that my Trustees should continue to use
    <span v-for="(adviser, index) in data.advisers" :key="adviser.id">
      <span v-html="helpers.var(adviser.person.profile.firstName)" />&nbsp;
      <span v-html="helpers.var(adviser.person.profile.lastName)" /> of
      <span v-html="helpers.var(adviser.person.profile.organisationName)" />
      <span v-if="index === data.advisers.length - 2"> and </span>
      <span v-else-if="index !== data.advisers.length - 1">, </span>
    </span>
    for all Financial Advice associated with the trusts. They were originally
    involved in assisting to establish the trusts and understand the concepts
    important to me and in particular how to ensure the assets remain within the
    security of the trust and hence be available for use by my beneficiaries.
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
