import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { LeadDetailsBreadCrumb } from "../../../breadcrumbs";

export const LeadDetailsUpdateProfileRoute = "LeadDetailsUpdateProfile";
export const LeadDetailsUpdateProfileURI = "/lead/:lead/profile";
export type LeadDetailsUpdateProfileParams = RouteParamObject<
  typeof LeadDetailsUpdateProfileURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: LeadDetailsUpdateProfileRoute,
    path: LeadDetailsUpdateProfileURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "LeadDetailsUpdateProfile" */ "./LeadDetails.UpdateProfile.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...LeadDetailsBreadCrumb(params),
        {
          text: "Profile",
          route: { name: LeadDetailsUpdateProfileRoute, params },
        },
      ],
    },
  },
];
