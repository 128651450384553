import { SharedFilterProps, UserField } from "@/config/form.config";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { Equal, In, IsNotNull } from "@kinherit/orm";
import { User } from "@kinherit/sdk";
export const ReadAcuityForm = (
  data: { selected: User | null } = {
    selected: null,
  },
) =>
  MasterListFiltersForm({
    name: "read-acuity-form",
    data: () => data,
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      selected: [
        UserField({
          props: {
            label: "Select User",
            vModel: "selected",
          },
          query: {
            roles: {
              role: In(["staff", "admin"]),
            },
            displayUserInPortal: Equal(true),
            acuityUserId: IsNotNull(),
          },
        }),
      ],
    }),
  });
