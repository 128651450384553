import { Api } from "@/service/api.service";
import { EmailCampaign } from "@kinherit/sdk";

export interface CreateEmailCampaignMessage {
  emailCampaign: EmailCampaign;
}

export interface CreateEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
}

export const CreateEmailCampaignHandler = async (
  message: CreateEmailCampaignMessage,
): Promise<CreateEmailCampaignResponse> => {
  const request = Api.resource("portal", "/v2/portal/email-campaign", {})
    .method("post")

    .body({
      description: message.emailCampaign.$data.description,
      name: message.emailCampaign.$data.name,
    });

  const response = await request.result();

  message.emailCampaign.$delete();

  return {
    emailCampaign: EmailCampaign.$inflate(
      response.emailCampaign,
      undefined,
      response.$rootIds,
    ),
  };
};
