import { Api, Kintin } from "@kinherit/sdk/index";

// export class DuplicateKintinHandler extends ActionBase {
//   constructor(private message: { kintin: Kintin }) {
//     super();
//   }

//   public async execute(): Promise<{
//     kintin: string;
//   }> {
export const DuplicateKintinHandler = async (message: {
  kintin: Kintin;
}): Promise<{
  kintin: string;
}> => {
  return await Api.resource("portal", "/v2/portal/kintin/duplicate")
    .method("put")
    .body({
      kintin: message.kintin.id,
    })
    .result();
};
