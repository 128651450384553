import { Kintin, KintinCheck } from "@kinherit/sdk";
import { defineComponent } from "vue";

export class KintinCheckService {
  public static get mixin() {
    return defineComponent({
      computed: {
        $kintinCheck(): Omit<typeof KintinCheckService, "mixin"> {
          return KintinCheckService;
        },
      },
    });
  }

  private static confirmCheckValue(
    kintin: Kintin,
    step: `${string}.${string}.${string}`,
    value: number | undefined,
  ): boolean {
    return (
      KintinCheck.$findOneBy({
        kintin: {
          id: kintin.id,
        },
        step,
      })?.checkValue === value
    );
  }

  public static checkIsYes(
    kintin: Kintin,
    step: `${string}.${string}.${string}`,
  ): boolean {
    return this.confirmCheckValue(kintin, step, 1);
  }

  public static checkIsNo(
    kintin: Kintin,
    step: `${string}.${string}.${string}`,
  ): boolean {
    return this.confirmCheckValue(kintin, step, 0);
  }

  public static checkIsUnknown(
    kintin: Kintin,
    step: `${string}.${string}.${string}`,
  ): boolean {
    return this.confirmCheckValue(kintin, step, undefined);
  }

  public static checkIsMaybe(
    kintin: Kintin,
    step: `${string}.${string}.${string}`,
  ): boolean {
    return this.confirmCheckValue(kintin, step, 2);
  }

  public static checkState(
    kintin: Kintin,
    step: `${string}.${string}.${string}`,
  ): "yes" | "no" | "unknown" | "maybe" {
    const check = KintinCheck.$findOneBy({
      kintin: {
        id: kintin.id,
      },
      step,
    });

    switch (check?.checkValue) {
      case 0:
        return "no";
      case 1:
        return "yes";
      case 2:
        return "maybe";
      default:
        return "unknown";
    }
  }

  public static instance(kintin: Kintin) {
    return new KintinCheckService(
      KintinCheck.$findBy({ kintin: { id: kintin.id } }),
    );
  }

  private constructor(private checks: KintinCheck[]) {}

  public checkState(
    step: `${string}.${string}.${string}`,
  ): "yes" | "no" | "unknown" | "maybe" {
    const check = this.checks.find((check) => check.step === step);

    switch (check?.checkValue) {
      case 0:
        return "no";
      case 1:
        return "yes";
      case 2:
        return "maybe";
      default:
        return "unknown";
    }
  }

  public checkIsYes(step: `${string}.${string}.${string}`): boolean {
    return this.checkState(step) === "yes";
  }

  public checkIsNo(step: `${string}.${string}.${string}`): boolean {
    return this.checkState(step) === "no";
  }

  public checkIsUnknown(step: `${string}.${string}.${string}`): boolean {
    return this.checkState(step) === "unknown";
  }

  public checkIsMaybe(step: `${string}.${string}.${string}`): boolean {
    return this.checkState(step) === "maybe";
  }
}
