import { Api } from "@/service/api.service";
import { FileLog, IFileLog, Kintin } from "@kinherit/sdk";

interface ReadKintinFileMessage {
  kintin: Kintin | string;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
  sort?: {
    by: keyof IFileLog;
    direction: "asc" | "desc";
  };
}

interface ReadKintinFileResponse {
  fileLogs: Array<FileLog>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadKintinFileHandler = async (
  message: ReadKintinFileMessage,
): Promise<ReadKintinFileResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-file/{kintin}/files",
    {
      kintin:
        "string" === typeof message.kintin
          ? message.kintin
          : message.kintin.$data.id,
    },
  )
    .method("get")
    .sort(message.sort)
    .paginate(message.pagination)

    .result();

  return {
    fileLogs: FileLog.$inflate(
      response.fileLog,
      message.sort,
      response.$rootIds,
    ),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
