import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferral } from "@kinherit/sdk";

export interface CreateBrandedKinvaultSubscriptionReferralMessage {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

interface CreateBrandedKinvaultSubscriptionReferralResponse {
  brandedKinvaultSubscriptionReferral: BrandedKinvaultSubscriptionReferral;
}

export const CreateBrandedKinvaultSubscriptionReferralHandler = async (
  message: CreateBrandedKinvaultSubscriptionReferralMessage,
): Promise<CreateBrandedKinvaultSubscriptionReferralResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral",
  )
    .method("post")
    .body({
      subscription:
        message.brandedKinvaultSubscriptionReferral.$data.subscription,
      subscriptionIntent:
        message.brandedKinvaultSubscriptionReferral.$data.subscriptionIntent,
      referralCode:
        message.brandedKinvaultSubscriptionReferral.referralCode?.id,
    })
    .result();

  const brandedKinvaultSubscriptionReferral =
    BrandedKinvaultSubscriptionReferral.$inflate(
      response.brandedKinvaultSubscriptionReferral,
    ).first();

  if (!brandedKinvaultSubscriptionReferral) {
    throw new Error("Failed to create subscription");
  }

  message.brandedKinvaultSubscriptionReferral.$delete();

  return {
    brandedKinvaultSubscriptionReferral,
  };
};
