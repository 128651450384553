import { Api } from "@/service/api.service";
import { User } from "@kinherit/sdk";

export interface UpdateUserMessage {
  user: User;
}

interface UpdateUserResponse {
  user: User;
}

export const UpdateUserHandler = async (
  message: UpdateUserMessage,
): Promise<UpdateUserResponse> => {
  const response = await Api.resource("portal", "/v2/portal/user/{user}", {
    user: message.user,
  })
    .method("patch")

    .body({
      acuityUserId: message.user.$data.acuityUserId,
      roles: message.user.$data.roles,
      credential: {
        username: message.user.credential.$data.username,
      },
      displayUserInPortal: message.user.$data.displayUserInPortal,
      isEstatePlanner: message.user.$data.isEstatePlanner,
      isLegalAssistant: message.user.$data.isLegalAssistant,
      status: message.user.$data.status,
      sendEmailNotifications: message.user.$data.sendEmailNotifications,
      sendSmsNotifications: message.user.$data.sendSmsNotifications,
    })
    .result();

  const user = User.$inflate(response.user).first();

  if (!user) {
    throw new Error("Failed to update user");
  }

  return {
    user,
  };
};
