import { Api } from "@/service/api.service";
import { AccountReferral, Lead, Profile } from "@kinherit/sdk";

interface CreateLeadMessage {
  lead: Lead;
}

interface CreateLeadResponse {
  lead: Lead;
  referrals: Array<AccountReferral>;
  profiles: Array<Profile>;
}

export const CreateLeadHandler = async (
  message: CreateLeadMessage,
): Promise<CreateLeadResponse> => {
  const response = await Api.resource("portal", "/v2/portal/lead")
    .method("post")

    .body({
      assignedTo: message.lead.$data.assignedTo,
      description: message.lead.$data.description,
      ownedBy: message.lead.$data.ownedBy,
      status: message.lead.$data.status,
      profile: {
        firstName: message.lead.profile.$data.firstName,
        lastName: message.lead.profile.$data.lastName,
        phoneNumbers: message.lead.profile.phoneNumbers.map((phoneNumber) => ({
          tel: phoneNumber.tel,
        })),
        emails: message.lead.profile.emails.map((email) => ({
          email: email.email,
          primary: email.primary,
        })),
      },
      referral: {
        contact: message.lead.referral?.contact?.$data.id,
        referralCode: message.lead.referral?.referralCode?.$data.id,
      },
      externalLeadId: message.lead.$data.externalLeadId,
    })
    .result();

  const lead = Lead.$inflate(response.lead).first();

  if (!lead) {
    throw new Error("Failed to create lead");
  }

  message.lead.$delete({
    profile: {
      emails: true,
      phoneNumbers: true,
    },
    referral: true,
  });

  return {
    lead,
    referrals: AccountReferral.$inflate(response.accountReferral),
    profiles: Profile.$inflate(response.profile),
  };
};
