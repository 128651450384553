import { Between, Equal, In, IsNotNull, Like } from "@kinherit/orm/index";
import {
  AccountReferral,
  AccountReferralCode,
  Api,
  IOrder,
  Kintin,
  Order,
  Profile,
  QueryMask,
  User,
} from "@kinherit/sdk";

export interface ReadOrderMessage {
  search?: string;
  type?: "kintin" | "trustReg";
  query?: QueryMask<typeof Order>;
  paymentType: Array<string>;
  referral: Array<string>;
  status: Array<string>;
  assignedUser: Array<string>;
  created: null | [number, number];
  paidWithin: null | [number, number];
  kintin?: string | Kintin;
  pagination:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IOrder;
    direction: "asc" | "desc";
  };
}

interface ReadOrderResponse {
  orders: Array<Order>;
  kintins: Array<Kintin>;
  referrals: Array<AccountReferral>;
  profiles: Array<Profile>;
  referralCodes: Array<AccountReferralCode>;
  users: Array<User>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

const getKintinParams = (
  message: ReadOrderMessage,
): Array<QueryMask<typeof Order>> => {
  const params1: QueryMask<typeof Order> = {
    kintin: {
      ref: Like(message.search),
      id: IsNotNull(),
      referral: {
        referralCode: {
          id: In(message.referral),
        },
      },
    },
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    paidAt: Between(message.paidWithin),
    paymentType: { id: In(message.paymentType) },
    createdBy: { id: In(message.assignedUser) },
  };

  const params2: QueryMask<typeof Order> = {
    kintin: {
      friendlyName: Like(message.search),
      id: IsNotNull(),
      referral: { id: In(message.referral) },
    },
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    paidAt: Between(message.paidWithin),
    paymentType: { id: In(message.paymentType) },
    createdBy: { id: In(message.assignedUser) },
  };

  if (message.search) {
    return [params1, params2];
  }

  return [params1];
};

const getTrustRegParams = (
  message: ReadOrderMessage,
): Array<QueryMask<typeof Order>> => {
  const params1: QueryMask<typeof Order> = {
    isTrustReg: Equal(true),
    status: {
      id: In(message.status),
    },
    createdAt: Between(message.created),
    paidAt: Between(message.paidWithin),
    paymentType: { id: In(message.paymentType) },
  };

  return [params1];
};

export const ReadOrderHandler = async (
  message: ReadOrderMessage,
): Promise<ReadOrderResponse> => {
  if (undefined === message.type) {
    message.type = "kintin";
  }

  const request = Api.resource("portal", "/v2/portal/order")
    .method("get")

    .sort({
      sortBy: message.sort.by,
      sortDirection: message.sort.direction,
    })
    .paginate(message.pagination);

  const queryBuilder = request.buildQuery(Order);

  if (message.query) {
    queryBuilder.where(message.query);
  } else if (message.kintin) {
    queryBuilder.where({
      kintin: {
        id: Equal(
          "string" === typeof message.kintin
            ? message.kintin
            : message.kintin.id,
        ),
      },
    });
  } else {
    if (message.type === "kintin") {
      getKintinParams(message).forEach((query) => queryBuilder.where(query));
    } else {
      getTrustRegParams(message).forEach((query) => queryBuilder.where(query));
    }
  }

  const response = await request.result();

  return {
    orders: Order.$inflate(response.order, message.sort, response.$rootIds),
    kintins: Kintin.$inflate(response.kintin),
    referrals: AccountReferral.$inflate(response.accountReferral),
    profiles: Profile.$inflate(response.profile),
    referralCodes: AccountReferralCode.$inflate(response.accountReferralCode),
    users: User.$inflate(response.user),
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
