import { Api } from "@/service/api.service";
import { getPagination } from "@kinherit/framework/action-bus/helper";
import { Between, Like } from "@kinherit/orm";
import {
  EmailAddress,
  EmailCampaign,
  IEmailCampaign,
  Profile,
} from "@kinherit/sdk";

export interface ReadEmailCampaignMessage {
  name?: null | string;
  created?: null | [number, number];
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  sort: {
    by: keyof IEmailCampaign;
    direction: "asc" | "desc";
  };
}

export interface ReadEmailCampaignResponse {
  emailCampaign: Array<EmailCampaign>;
  profiles: Profile[];
  emailAddresses: EmailAddress[];
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadEmailCampaignHandler = async (
  message: ReadEmailCampaignMessage,
): Promise<ReadEmailCampaignResponse> => {
  const request = Api.resource("portal", "/v2/portal/email-campaign", message)
    .method("get")

    .paginate(message.pagination)
    .sort(message.sort);

  request.buildQuery(EmailCampaign).where({
    name: Like(message.name),
    createdAt: Between(message.created),
  });

  const response = await request.result();

  return {
    emailCampaign: EmailCampaign.$inflate(
      response.emailCampaign,
      undefined,
      response.$rootIds,
    ),
    profiles: Profile.$inflate(response.profile),
    emailAddresses: EmailAddress.$inflate(response.emailAddress),
    pagination: getPagination(response),
  };
};
