import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsCallScriptBreadCrumb } from "../../../../breadcrumbs";

export const KintinDetailsCallScriptWhoDoesWhatRoute =
  "KintinDetailsCallScriptWhoDoesWhat";
export const KintinDetailsCallScriptWhoDoesWhatURI =
  "/kinvault/:kintin/call-script/who-does-what";
export type KintinDetailsCallScriptWhoDoesWhatParams = RouteParamObject<
  typeof KintinDetailsCallScriptWhoDoesWhatURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsCallScriptWhoDoesWhatRoute,
    path: KintinDetailsCallScriptWhoDoesWhatURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultKintinDetailsCallScriptWhoDoesWhat" */ "./KinvaultKintinDetails.CallScript.WhoDoesWhat.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        ...KinvaultKintinDetailsCallScriptBreadCrumb(params),
        {
          text: "Who Does What",
          route: { name: KintinDetailsCallScriptWhoDoesWhatRoute, params },
        },
      ],
    },
  },
];
