import {
  AccountReferralCodeField,
  IntroducerContactField,
} from "@/config/form.config";
import { CreateAccountReferral } from "@/config/model.config";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { AccountReferral, AccountReferralCode } from "@kinherit/sdk";
import { reactive } from "vue";

export const UpdateReferralForm = (
  { data = CreateAccountReferral() }: { data: AccountReferral } = {
    data: CreateAccountReferral(),
  },
) => {
  const localData = reactive({
    referralCode: data.referralCode as AccountReferralCode | null,
  });

  return defineForm({
    name: "update-referral-form",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "update-referral-form-area",
        template: GridLayout([["code"], ["contact"]]),
        data,
        components: () => ({
          code: [
            AccountReferralCodeField({
              props: {
                reference: "referralCode",
                vModel: {
                  get: (data) => data.referralCode,
                  set: (value, data, controls) => {
                    data.referralCode = value;
                    localData.referralCode = value;

                    controls.rebuildForm();
                    controls.forceUpdate();
                  },
                },
              },
              query: {},
            }),
          ],
          contact: [
            IntroducerContactField({
              props: {
                vModel: "contact",
              },
              query: {
                companies: {
                  referralCodes: {
                    id: Equal(localData.referralCode?.id),
                  },
                },
              },
            }),
          ],
        }),
      }),
    ],
  });
};
