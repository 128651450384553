import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KinvaultOfficerDetailsBreadCrumb,
  KinvaultOfficerMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const KinvaultOfficerDetailsNoteRoute = "KinvaultOfficerDetailsNote";
export const KinvaultOfficerDetailsNoteURI = "/officer/:officer/notes";
export type KinvaultOfficerDetailsNoteParams = RouteParamObject<
  typeof KinvaultOfficerDetailsNoteURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultOfficerDetailsNoteRoute,
    path: KinvaultOfficerDetailsNoteURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KinvaultOfficerDetailsNote" */ "./KinvaultOfficerDetails.Note.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        KinvaultOfficerMasterListBreadCrumb,
        KinvaultOfficerDetailsBreadCrumb(params),
        {
          text: "Notes",
          route: { name: KinvaultOfficerDetailsNoteRoute, params },
        },
      ],
    },
  },
];
