import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { KinvaultKintinDetailsDocumentsBreadCrumb } from "../../../breadcrumbs";

import { Routes as CreditNotesRoutes } from "./credit-notes";
import { Routes as FileVaultRoutes } from "./file-vault";
import { Routes as OrderRoutes } from "./orders";
import { Routes as PostPaymentRoutes } from "./post-payment";
import { KintinDetailsProcessRoute, Routes as ProcessRoutes } from "./process";
import { Routes as SubscriptionRoutes } from "./subscriptions";
import { Routes as UploadFormRoutes } from "./upload-forms";

/** Documents */
export const KinvaultKintinDetailsDocumentsRoute =
  "KinvaultKintinDetailsDocuments";
const KinvaultKintinDetailsDocumentsURI = "/kinvault/:kintin/documents";
export type KinvaultKintinDetailsDocumentsParams = RouteParamObject<
  typeof KinvaultKintinDetailsDocumentsURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultKintinDetailsDocumentsRoute,
    path: KinvaultKintinDetailsDocumentsURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsDocuments" */ "./KintinDetails.Documents.vue"
        ),
      sidebar: () =>
        import(
          /* webpackChunkName: "KintinDetailsDocuments" */ "./KintinDetails.Documents.Sidebar.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsDocumentsBreadCrumb,
    },
    redirect: {
      name: KintinDetailsProcessRoute,
    },
    children: [
      ...FileVaultRoutes,
      ...SubscriptionRoutes,
      ...OrderRoutes,
      ...UploadFormRoutes,
      ...PostPaymentRoutes,
      ...ProcessRoutes,
      ...CreditNotesRoutes,
    ],
  },
];
