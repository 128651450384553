import { RouteInterface } from "@kinherit/framework/core/route-config";
import { LeadMasterListBreadCrumb } from "../breadcrumbs";
import { Routes as LeadDetailsRoutes } from "./details";

export const LeadMasterListRoute = "LeadMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: LeadMasterListRoute,
    path: "/leads",
    component: () =>
      import(/* webpackChunkName: "LeadMasterList" */ "./LeadMasterList.vue"),
    meta: {
      breadcrumbs: () => [LeadMasterListBreadCrumb],
    },
  },
  ...LeadDetailsRoutes,
];
