import { Api } from "@/service/api.service";
import { EmailCampaign, Profile } from "@kinherit/sdk";

export interface RemoveEmailCampaignProfileMessage {
  emailCampaign: EmailCampaign;
  profile: Profile;
}

export interface RemoveEmailCampaignProfileResponse {
  [key: string]: never;
}

export const RemoveEmailCampaignProfileHandler = async (
  message: RemoveEmailCampaignProfileMessage,
): Promise<RemoveEmailCampaignProfileResponse> => {
  await Api.resource(
    "portal",
    "/v2/portal/email-campaign/profile/{emailCampaign}/profiles/{profile}/remove",
    message,
  )
    .method("delete")

    .result();

  return {};
};
