<template>
  <SidebarPage title="Logs">
    <template #sidebar>
      <AdminLogSidebar />
    </template>

    <template #default>
      <RouterChildView />
    </template>
  </SidebarPage>
</template>

<script lang="ts">
import SidebarPage from "@/shared/component/misc/SidebarPage.vue";
import RouterChildView from "@kinherit/framework/component.misc/router-child-view";
import { defineComponent } from "vue";
import { AdminLogIndexRoute } from ".";
import AdminLogSidebar from "./AdminLog.Sidebar.vue";

export default defineComponent({
  name: AdminLogIndexRoute,
  components: {
    AdminLogSidebar,
    SidebarPage,
    RouterChildView,
  },
});
</script>
