import { Api } from "@/service/api.service";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { FileLog } from "@kinherit/sdk";

type DownloadUploadFormSubmissionMessage = {
  submission: FileLog | string;
};

interface DownloadUploadFormSubmissionResponse {
  [key: string]: never;
}

export const DownloadUploadFormSubmissionHandler = async (
  message: DownloadUploadFormSubmissionMessage,
): Promise<DownloadUploadFormSubmissionResponse> => {
  const { url } = await Api.resource(
    "portal",
    "/v2/portal/upload-form/submission/{submission}/download",
    {
      submission: message.submission,
    },
  )
    .method("get")

    .result();

  OpenAlertDialog({
    dialog: {
      title: "Download Ready",
      html: `
            Click <a href="${url}" target="_blank">here</a> to download the file.
          `,
    },
    button: {
      ok: {
        text: "Close",
      },
      cancel: {
        show: false,
      },
    },
  });

  return {};
};
