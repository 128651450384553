import { EmailCampaign } from "@kinherit/sdk";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";

export const UpdateEmailCampaignForm = (emailCampaign: EmailCampaign) => {
  return defineForm({
    name: "update-email-campaign-form",
    data: () => emailCampaign,
    formAreas: (data) => [
      defineFormArea({
        name: `update-email-campaign-form-area`,
        data,
        template: GridLayout([["name"], ["description"]]),
        components: () => ({
          name: [
            FormTextField({
              props: {
                label: `Name`,
                validators: ["required"],
              },
              models: {
                value: "name",
              },
            }),
          ],
          description: [
            FormTextField({
              props: {
                label: `Description`,
              },
              models: {
                value: "description",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
