import { Api } from "@/service/api.service";
import { KintinCheck, Note } from "@kinherit/sdk";

interface CreateKintinCheckMessage {
  kintin: string;
  kintinCheck: KintinCheck;
  replace: boolean;
}

interface CreateKintinCheckResponse {
  kintinChecks: Array<KintinCheck>;
  notes: Array<Note>;
}

export const CreateKintinCheckHandler = async (
  message: CreateKintinCheckMessage,
): Promise<CreateKintinCheckResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/kintin-check/{kintin}/checks",
    {
      kintin: message.kintin,
    },
  )
    .method("post")
    .body({
      checkType: message.kintinCheck.$data.checkType,
      checkValue: message.kintinCheck.$data.checkValue,
      note: {
        completedAt: message.kintinCheck.note.$data.completedAt,
        kintin: message.kintin,
        name: message.kintinCheck.note.$data.name,
        notes: message.kintinCheck.note.$data.notes,
        dueAt: message.kintinCheck.note.$data.dueAt,
        pinned: message.kintinCheck.note.$data.pinned,
        type: "kintinCheck",
      },
      kintin: message.kintin,
      step: message.kintinCheck.$data.step,
    })

    .result();

  const kintinChecks = KintinCheck.$inflate(response.kintinCheck);
  const notes = Note.$inflate(response.note);

  message.kintinCheck.$delete();

  if (message.replace) {
    const replaced = kintinChecks.find(
      (kintinCheck) => kintinCheck.step === message.kintinCheck.$data.step,
    );

    if (!replaced) {
      throw new Error("Failed to create kintin check");
    }

    message.kintinCheck.$sync(replaced);
  }

  return {
    kintinChecks,
    notes,
  };
};
