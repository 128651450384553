import { Api } from "@/service/api.service";
import { Exclusion, Kintin } from "@kinherit/sdk";

interface CreateKintinExclusionMessage {
  exclusion: Exclusion;
  kintin: Kintin;
}

interface CreateKintinExclusionResponse {
  exclusion: Exclusion;
}

export const CreateKintinExclusionHandler = async (
  message: CreateKintinExclusionMessage,
): Promise<CreateKintinExclusionResponse> => {
  const { exclusion: exclusionData } = await Api.resource(
    "portal",
    "/v2/portal/kintin-exclusion/{kintin}/exclusion",
    {
      kintin: message.kintin.id,
    },
  )
    .method("post")

    .body({
      kintin: message.kintin.id,
      excludedBy: message.exclusion.$data.excludedBy,
      relationship: message.exclusion.$data.relationship,
      who: message.exclusion.$data.who,
      why: message.exclusion.$data.why,
    })
    .result();

  const exclusion = Exclusion.$inflate(exclusionData).first();

  if (!exclusion) {
    throw new Error("Failed to create exclusion");
  }

  message.exclusion.$delete();

  return { exclusion };
};
