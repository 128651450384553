<template>
  <Card v-if="knowledgeBaseDocument">
    <div v-html="knowledgeBaseDocument.content"></div>
  </Card>
</template>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { KnowledgeBaseDocumentDetailsContentRoute } from ".";
import { KnowledgeBaseDocumentMixin } from "../../mixin/knowledge-base-document.mixin";

export default defineComponent({
  name: KnowledgeBaseDocumentDetailsContentRoute,
  components: { Card },
  mixins: [AuthService.mixin(), KnowledgeBaseDocumentMixin()],
});
</script>
