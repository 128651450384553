<template>
  <strong>Power to Remove Beneficiaries</strong>
  <p>
    The trustees of the Trusts shall have the power to remove any of the
    beneficiaries of the said Trusts for which they are trustees
  </p>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
