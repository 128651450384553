<template>
  <div v-if="kintin">
    <CallScriptSection title="Business Assets / Agricultural Assets">
      <b>
        So, just to finish on your assets, I need to check, do you have any
        business assets…?
      </b>
      <br /><em>[Pause]</em> <br /><br />
      <b>
        When I say business assets, this covers quite a lot of things - so an
        owned business, but also most unlisted shares, and even some qualifying
        listed shares and agricultural assets.
      </b>
      <br /><br />
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.8a"
        :kintin="kintin"
        is-reversed
      />
      <b class="mb-4"
        ><br />We ask about it, because it can be very tax-efficient to deal
        with qualifying business assets separately in a Will.</b
      >
      <ConfirmCheck
        @update="updateCheckValue"
        :kintin-checks="kintinChecks"
        :checks="checks"
        step="3.0.8b"
        :kintin="kintin"
        is-reversed
        v-if="checkIsYes('3.0.8a')"
        :on="{
          yes: {
            action: [
              `Specialist to explore if the business would be continued, and any handover planning.`,
            ],
            say: [
              `You probably know what key person insurance is … its beyond the scope for today, but we can come back to this at the end if helpful? (IFYES – write note to follow-up/pass on to BM at end.)`,
            ],
          },
        }"
      />
      <br />
      <b v-if="checkIsYes('3.0.8a')">What do you think the rough value is?</b>
      <CurrencyField
        v-if="checkIsYes('3.0.8a')"
        label="Business"
        class="mb-4"
        v-model:value="values.advBusinessAssetValue"
      />
      <Button
        text="Save"
        v-if="checkIsYes('3.0.8a')"
        @click="saveHandler"
        color="is-positive"
        class="is-pulled-right"
      />
    </CallScriptSection>
    <CallScriptSection title="Establish Asset Range">
      <b>
        So, looking at the asset figures you’ve given me today, your estate is
        worth approximately £{{ assetNetTotal }}. <br /><br />
        That’s excluding pensions, life policies and anything that could happen
        in the future, but otherwise does that sound about right?
      </b>
      <br /><em>[Pause]</em> <br /><br />
      <b>
        So, thinking about inheritance tax, the basic tax-free threshold is
        £325,000 per person, and for people who qualify, this can rise to
        £500,000 per person
        <br /><br />
      </b>
      <em>
        [Specialist note: RNRB
        <strong
          v-if="!checkIsYes('2.5.0a') || !checkIsYes('3.0.0a')"
          class="text-danger"
          >Does not apply</strong
        >
        <strong v-else class="text-success">Should apply</strong> in this case:]
      </em>
      <TickCross
        :value="checkIsYes('2.5.0a')"
        question="Are there children?"
        yes="There are children"
        no="No children or dependents under 18 or requiring special care"
      />
      <TickCross
        :value="checkIsYes('3.0.0a')"
        question="Owns Main Property?"
        yes="Owns Main Property"
        no="Does not own primary residence"
      />
      <br />
      <b>So for you…</b>
      <br /><em>[specialist pick one of the below &amp; say …]</em>
      <RadioField
        direction="is-vertical"
        :options="{
          1: `[under 250 per person] ...it sounds like inheritance tax shouldn’t affect your planning. But you still don’t want your assets to get lost on death, so it’s important we get the practical planning side right as well.`,
          2: `[250 - 500 pp] ...it sounds like inheritance tax may be an issue. We’ll do all we can with your Will to maximise your allowances, and help with wealth transfer, and then once that’s all sorted, we’ll give you a reminder that you may benefit from some specific inheritance tax planning steps.`,
          3: `[over 500 pp]  ...clearly inheritance tax is a consideration. We’ll do all we can with your Will to maximise your allowances and help with wealth transfer, and then once that’s all sorted, we’ll give you a reminder that you may benefit from some specific inheritance tax planning steps.`,
        }"
        v-model:data="callScript.advIhteStateSitu"
      />
      <br />
      <em>[Specialist: IF c. >£500 pp AND kids AND property… say:]</em>
      <br />
      <b>
        And one thing I will flag in particular is that the additional tax-free
        band tapers down above £2 million in wealth… so it can definitely pay to
        do some work around that as there is £70k to save.
      </b>
      <br /><br />
      <b>Great thanks that is all clear.</b>
      <br /><br />
      <em>[Go to Next page if happy, otherwise tweak numbers below]</em>
    </CallScriptSection>
  </div>
</template>

<script lang="ts">
import ConfirmCheck from "@/module/kinvault.kintin/component/call-script/shared/ConfirmCheck.vue";
import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";
import TickCross from "@/module/kinvault.kintin/component/call-script/shared/TickCross.vue";
import { KinvaultKintinDetailsCallScriptMixin } from "@/module/kinvault.kintin/mixin/call-script.mixin";
import { AuthService } from "@/service/auth.service";
import { SnackBarService } from "@/service/snack-bar.service";
import { Button } from "@kinherit/framework/component.input/button";
import CurrencyField from "@kinherit/framework/component.input/currency-field";
import RadioField from "@kinherit/framework/component.input/radio-field";
import { IntroducerCompany, IntroducerContact } from "@kinherit/sdk";
import { Currency } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import { KinvaultKintinDetailsMixin } from "../../../../mixin/kintin-details.mixin";

export default defineComponent({
  name: "BusinessTab",
  mixins: [
    KinvaultKintinDetailsMixin,
    AuthService.mixin(),
    SnackBarService.mixin,
    KinvaultKintinDetailsCallScriptMixin,
  ],
  components: {
    Button,
    CallScriptSection,
    CurrencyField,
    RadioField,
    TickCross,
    ConfirmCheck,
  },
  data: () => ({
    values: {
      advSavingsValue: null as null | Currency,
      advPropsValue: null as null | Currency,
      advPolicyValue: null as null | Currency,
      advPensionValue: null as null | Currency,
      advOtherAssetValue: null as null | Currency,
      advBusinessAssetValue: null as null | Currency,
      advPotentialValue: null as null | Currency,
      advDebtValue: null as null | Currency,
      advHteStateValue: null as null | Currency,
    },
  }),
  computed: {
    referralContact(): IntroducerContact | null {
      return this.kintin?.referral?.contact ?? null;
    },
    referralCompany(): IntroducerCompany | null {
      return this.kintin?.referral?.referralCode?.company ?? null;
    },
    assetNetTotal(): number {
      return (
        (this.values.advSavingsValue?.amount ?? 0) +
        (this.values.advPropsValue?.amount ?? 0) +
        (this.values.advPolicyValue?.amount ?? 0) +
        (this.values.advPensionValue?.amount ?? 0) +
        (this.values.advOtherAssetValue?.amount ?? 0) +
        (this.values.advBusinessAssetValue?.amount ?? 0) +
        (this.values.advPotentialValue?.amount ?? 0) +
        (this.values.advDebtValue?.amount ?? 0)
      );
    },
  },
  mounted(): void {
    if (!this.kintin || !this.$auth.loggedInUser) {
      return;
    }

    this.values.advSavingsValue =
      this.kintin.callScript?.advSavingsValue ?? null;
    this.values.advPropsValue = this.kintin.callScript?.advPropsValue ?? null;
    this.values.advPolicyValue = this.kintin.callScript?.advPolicyValue ?? null;
    this.values.advPensionValue =
      this.kintin.callScript?.advPensionValue ?? null;
    this.values.advOtherAssetValue =
      this.kintin.callScript?.advOtherAssetValue ?? null;
    this.values.advBusinessAssetValue =
      this.kintin.callScript?.advBusinessAssetValue ?? null;
    this.values.advPotentialValue =
      this.kintin.callScript?.advPotentialValue ?? null;
    this.values.advDebtValue = this.kintin.callScript?.advDebtValue ?? null;
    this.values.advHteStateValue =
      this.kintin.callScript?.advHteStateValue ?? null;
  },
  methods: {
    async saveHandler() {
      this.$emit("save");
    },
  },
});
</script>
