import { Api } from "@/service/api.service";
import { BrandedKinvaultSubscriptionReferralCode } from "@kinherit/sdk";

interface CreateBrandedKinvaultSubscriptionReferralCodeMessage {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

interface CreateBrandedKinvaultSubscriptionReferralCodeResponse {
  brandedKinvaultSubscriptionReferralCode: BrandedKinvaultSubscriptionReferralCode;
}

export const CreateBrandedKinvaultSubscriptionReferralCodeHandler = async (
  message: CreateBrandedKinvaultSubscriptionReferralCodeMessage,
): Promise<CreateBrandedKinvaultSubscriptionReferralCodeResponse> => {
  const response = await Api.resource(
    "portal",
    "/v2/portal/referral/branded-kinvault-subscription-referral-code",
  )
    .method("post")

    .body({
      code: message.brandedKinvaultSubscriptionReferralCode.$data.code,
      name: message.brandedKinvaultSubscriptionReferralCode.$data.name,
      brandedKinvault:
        message.brandedKinvaultSubscriptionReferralCode.$data.brandedKinvault,
    })
    .result();

  const brandedKinvaultSubscriptionReferralCode =
    BrandedKinvaultSubscriptionReferralCode.$inflate(
      response.brandedKinvaultSubscriptionReferralCode,
      undefined,
      response.$rootIds,
    ).first();

  if (!brandedKinvaultSubscriptionReferralCode) {
    throw new Error("Failed to create account referral code");
  }

  message.brandedKinvaultSubscriptionReferralCode.$delete();

  return {
    brandedKinvaultSubscriptionReferralCode,
  };
};
