<template>
  <div v-if="data">
    <p>
      <strong>Legacy - Shares</strong><br />
      I would ask my Trustees to consider utilising:
    </p>
    <ol type="i">
      <li v-for="gift in data.sharesGifts" :key="gift.id">
        <span v-html="helpers.var(gift.notes)" /> to
        <span v-html="helpers.var(helpers.formatRecipient(gift, false))" />
        <span v-html="helpers.var(helpers.beneficiaryTheirConcern(gift))" />
        <span v-if="gift.onSecondDeath">
          , only if my
          <span
            v-html="helpers.var(helpers.formatOfficer(data.partner, false))"
          />
          has predeceased me.
        </span>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { WillBuilderService } from "@/module/kinvault.kintin/service/will-builder.service";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [WillBuilderService.mixin],
});
</script>
