import { RouteInterface } from "@kinherit/framework/core/route-config";

export const KinvaultWillRevisionMasterListRoute =
  "KinvaultWillRevisionMasterList";

const KinvaultWillRevisionMasterListBreadCrumb = {
  text: "Will Revisions",
  route: { name: KinvaultWillRevisionMasterListRoute },
};

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultWillRevisionMasterListRoute,
    path: "/will-revisions",
    component: () =>
      import(
        /* webpackChunkName: "WillRevisionMasterList" */ "./WillRevisionMasterList.vue"
      ),
    meta: {
      breadcrumbs: () => [KinvaultWillRevisionMasterListBreadCrumb],
    },
  },
];
