import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsReferralsRoute =
  "IntroducerCompanyDetailsReferrals";
export const IntroducerCompanyDetailsReferralsURI =
  "/introducer/company/:introducerCompany/referrals/clients";
export type IntroducerCompanyDetailsReferralsParams = RouteParamObject<
  typeof IntroducerCompanyDetailsReferralsURI
>;

export const IntroducerCompanyDetailsReferralCodesRoute =
  "IntroducerCompanyDetailsReferralCodes";
export const IntroducerCompanyDetailsReferralCodesURI =
  "/introducer/company/:introducerCompany/referrals/codes";
export type IntroducerCompanyDetailsReferralCodesParams = RouteParamObject<
  typeof IntroducerCompanyDetailsReferralsURI
>;

export const IntroducerCompanyDetailsReferralFormRoute =
  "IntroducerCompanyDetailsReferralForm";
export const IntroducerCompanyDetailsReferralFormURI =
  "/introducer/Company/:introducerCompany/referrals/forms";
export type IntroducerCompanyDetailsReferralFormParams = RouteParamObject<
  typeof IntroducerCompanyDetailsReferralFormURI
>;

export const IntroducerCompanyDetailsReferralsIndexRoute =
  "IntroducerCompanyDetailsReferralsRoute";
export const IntroducerCompanyDetailsReferralsIndexURI =
  "/introducer/company/:introducerCompany/referrals";
export type IntroducerCompanyDetailsReferralsIndexParams = RouteParamObject<
  typeof IntroducerCompanyDetailsReferralsIndexURI
>;

export const IntroducerCompanyDetailsReferralBreadCrumb = (params: any) => ({
  text: "Referrals",
  route: { name: IntroducerCompanyDetailsReferralsIndexRoute, params },
});

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsReferralsIndexRoute,
    path: IntroducerCompanyDetailsReferralsIndexURI,
    component: () => import("./Index.vue"),
    redirect: {
      name: IntroducerCompanyDetailsReferralsRoute,
    },
    children: [
      {
        name: IntroducerCompanyDetailsReferralsRoute,
        path: IntroducerCompanyDetailsReferralsURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "IntroducerCompanyDetailsReferrals" */ "./IntroducerCompanyDetails.Referrals.vue"
            ),
        },
        meta: {
          breadcrumbs: (params) => [
            IntroducerCompanyMasterListBreadCrumb,
            IntroducerCompanyDetailsBreadCrumb(params),
            IntroducerCompanyDetailsReferralBreadCrumb(params),
            {
              text: "Clients",
              route: {
                name: IntroducerCompanyDetailsReferralsRoute,
                params,
              },
            },
          ],
        },
      },
      {
        name: IntroducerCompanyDetailsReferralCodesRoute,
        path: IntroducerCompanyDetailsReferralCodesURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "IntroducerCompanyDetailsReferrals" */ "./IntroducerCompanyDetails.ReferralCodes.vue"
            ),
        },
        meta: {
          breadcrumbs: (params) => [
            IntroducerCompanyMasterListBreadCrumb,
            IntroducerCompanyDetailsBreadCrumb(params),
            IntroducerCompanyDetailsReferralBreadCrumb(params),
            {
              text: "Referral Codes",
              route: {
                name: IntroducerCompanyDetailsReferralCodesRoute,
                params,
              },
            },
          ],
        },
      },
      {
        name: IntroducerCompanyDetailsReferralFormRoute,
        path: IntroducerCompanyDetailsReferralFormURI,
        components: {
          default: () =>
            import(
              /* webpackChunkName: "IntroducerCompanyDetailsReferrals" */ "./IntroducerCompanyDetails.ReferralForm.vue"
            ),
        },
        meta: {
          breadcrumbs: (params) => [
            IntroducerCompanyMasterListBreadCrumb,
            IntroducerCompanyDetailsBreadCrumb(params),
            IntroducerCompanyDetailsReferralBreadCrumb(params),
            {
              text: "Forms",
              route: {
                name: IntroducerCompanyDetailsReferralFormRoute,
                params,
              },
            },
          ],
        },
      },
    ],
  },
];
