import { KinvaultKintinDetailsSubscriptionsBreadCrumb } from "@/module/kinvault.kintin/breadcrumbs";
import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const KintinDetailsSubscriptionRoute = "KintinDetailsSubscription";
export const KintinDetailsSubscriptionURI =
  "/kinvault/:kintin/documents/subscriptions/:subscription?";
export type KintinDetailsSubscriptionParams = RouteParamObject<
  typeof KintinDetailsSubscriptionURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: KintinDetailsSubscriptionRoute,
    path: KintinDetailsSubscriptionURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsSubscription" */ "./KintinDetails.Subscriptions.vue"
        ),
    },
    meta: {
      breadcrumbs: KinvaultKintinDetailsSubscriptionsBreadCrumb,
    },
  },
];
