import { Api } from "@/service/api.service";
import { EmailTemplate, QueryMask } from "@kinherit/sdk";

export interface ReadEmailTemplateMessage {
  query?: QueryMask<typeof EmailTemplate>;
  pagination?:
    | {
        currentPage?: number;
        perPage?: number;
      }
    | false;
}

interface ReadEmailTemplateResponse {
  emailTemplates: Array<EmailTemplate>;
}

export const ReadEmailTemplateHandler = async (
  message: ReadEmailTemplateMessage,
): Promise<ReadEmailTemplateResponse> => {
  const request = Api.resource("core", "/v2/core/select/email-template")
    .method("get")

    .paginate(message.pagination ?? false);
  request.buildQuery(EmailTemplate).where(message.query ?? {});

  const response = await request.result();

  return {
    emailTemplates: EmailTemplate.$inflate(
      response.emailTemplate,
      undefined,
      response.$rootIds,
    ),
  };
};
