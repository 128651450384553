<template>
  <SidebarPage
    class="profile-summary"
    :has-context-menu="hasContextMenu"
    :name="profile.fullName"
    :title="title"
    @context-menu="$emit('context-menu')"
    :tabs="tabs"
    v-model:tab="computedTab"
  >
    <template v-if="showSidebar" #sidebar>
      <slot name="sidebar-above" />
      <ContactDetailsWidget
        v-if="widgets.contactDetails"
        :profile-id="profileId"
        class="is-fullwidth mr-0"
        custom-title="Contact"
      />
      <slot name="sidebar-below" />
    </template>
    <template #default>
      <slot name="default" />
    </template>
  </SidebarPage>
</template>

<script lang="ts">
import { Profile } from "@kinherit/sdk";
import { TabOptions } from "@kinherit/framework/component.display/tabs";
import { defineComponent, type PropType } from "vue";
import SidebarPage from "../misc/SidebarPage.vue";
import ContactDetailsWidget from "./ContactDetailsWidget.vue";

export default defineComponent({
  name: "ProfileSummary",
  components: {
    ContactDetailsWidget,
    SidebarPage,
  },
  props: {
    profileId: {
      type: String,
      required: true,
    },
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    widgets: {
      type: Object as PropType<{
        contactDetails: boolean;
      }>,
      default: () => ({
        contactDetails: true,
      }),
    },
    tabs: {
      type: Array as PropType<Array<TabOptions>>,
      default: () => [],
    },
    tab: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  emits: ["context-menu", "update:tab"],
  computed: {
    computedTab: {
      get(): number | undefined {
        return this.tab ?? undefined;
      },
      set(value: number | null): void {
        this.$emit("update:tab", value);
      },
    },
    profile(): Profile {
      return Profile.$findOneOrThrow(this.profileId);
    },
    title(): string | null {
      const hasName = ![
        this.profile.firstName,
        this.profile.middleNames,
        this.profile.lastName,
      ].onlyIncludes(null);

      if (hasName && null !== this.profile.organisationName) {
        return this.profile.organisationName;
      }

      return null;
    },
    showSidebar(): boolean {
      return !!this.$slots.left || Object.values(this.widgets).some(Boolean);
    },
  },
});
</script>
