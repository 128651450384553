import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadIntroducerFeePaymentRunForm = () => {
  return MasterListFiltersForm({
    name: "filter-introducer-fee-payment-run-form",
    data: () => ({
      name: "",
      createdAt: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      name: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
            size: "is-small",
          },
          models: {
            value: "name",
          },
        }),
      ],
      createdAt: [
        CreatedAtField({
          props: {
            vModel: "createdAt",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
