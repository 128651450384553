import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  KinvaultOfficerDetailsBreadCrumb,
  KinvaultOfficerMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const OfficerDetailsEmailLogRoute = "OfficerDetailsEmailLog";
export const OfficerDetailsEmailLogURI = "/officer/:officer/email-log";
export type OfficerDetailsEmailLogParams = RouteParamObject<
  typeof OfficerDetailsEmailLogURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: OfficerDetailsEmailLogRoute,
    path: OfficerDetailsEmailLogURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "OfficerDetailsEmailLog" */ "./OfficerDetails.EmailLog.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        KinvaultOfficerMasterListBreadCrumb,
        KinvaultOfficerDetailsBreadCrumb(params),
        {
          text: "Email Log",
          route: {
            name: OfficerDetailsEmailLogRoute,
            params,
          },
        },
      ],
    },
  },
];
