import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import { EmailCampaignDetailsParams } from "..";
import { EmailCampaignDetailsSummaryBreadCrumb } from "../../../breadcrumbs";

/** Summary */
export const EmailCampaignDetailsSummaryRoute = "EmailCampaignDetailsSummary";
const EmailCampaignDetailsSummaryURI = "/admin.email-campaign/:emailCampaign";
export type EmailCampaignDetailsSummaryParams = RouteParamObject<
  typeof EmailCampaignDetailsSummaryURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: EmailCampaignDetailsSummaryRoute,
    path: EmailCampaignDetailsSummaryURI,
    // @ts-ignore
    components: {
      default: () =>
        import(
          /* webpackChunkName: "KintinDetailsSummary" */ "./EmailCampaignDetails.Summary.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) =>
        EmailCampaignDetailsSummaryBreadCrumb(
          params as EmailCampaignDetailsParams,
        ),
    },
  },
];
