import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";
import {
  IntroducerCompanyDetailsBreadCrumb,
  IntroducerCompanyMasterListBreadCrumb,
} from "../../../breadcrumbs";

export const IntroducerCompanyDetailsUpdateProfileRoute =
  "IntroducerCompanyDetailsUpdateProfile";
export const IntroducerCompanyDetailsUpdateProfileURI =
  "/introducer/company/:introducerCompany/profile";
export type IntroducerCompanyDetailsUpdateProfileParams = RouteParamObject<
  typeof IntroducerCompanyDetailsUpdateProfileURI
>;

export const Routes: Array<RouteInterface> = [
  {
    name: IntroducerCompanyDetailsUpdateProfileRoute,
    path: IntroducerCompanyDetailsUpdateProfileURI,
    components: {
      default: () =>
        import(
          /* webpackChunkName: "IntroducerCompanyDetailsUpdateProfile" */ "./IntroducerCompanyDetails.UpdateProfile.vue"
        ),
    },
    meta: {
      breadcrumbs: (params) => [
        IntroducerCompanyMasterListBreadCrumb,
        IntroducerCompanyDetailsBreadCrumb(params),
        {
          text: "Profile",
          route: { name: IntroducerCompanyDetailsUpdateProfileRoute, params },
        },
      ],
    },
  },
];
